import { OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { Observable, ReplaySubject, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { day } from 'minga/libraries/day';
import { HallPassStatusEnum } from 'minga/libraries/domain';

import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';

import { HpmDashboardTableItem } from '../types';

@Pipe({ name: 'countdown' })
export class HpmDashboardCountDownPipe implements PipeTransform, OnDestroy {
  /** Pass */
  private _pass: HpmDashboardTableItem;
  // initial state required in order to set the elapsed time correctly
  private _initialState: HallPassStatusEnum;

  private _recentlyEnded: number;

  private _manuallyEnded: boolean;

  /** Time / Seconds */
  private _unix = {
    remaining: 0,
    elapsed: 0,
    end: 0,
  };
  private _time = {
    years: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  private _destroyed$ = new ReplaySubject<void>(1);

  get isActive() {
    return this._pass.status.state === HallPassStatusEnum.ACTIVE;
  }
  get isEnded() {
    return this._pass.status.state === HallPassStatusEnum.ENDED;
  }
  get isPendingApproval() {
    return this._pass.status.state === HallPassStatusEnum.PENDING_APPROVAL;
  }

  constructor(private _hallPassActions: HallPassActionsService) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  transform(
    pass: HpmDashboardTableItem,
    recentlyEndedSetting: number,
    manuallyEndedSetting: boolean,
    timeTicker$: Observable<number>,
    manuallyEndPassType: boolean | undefined,
  ): any {
    const shouldntTick = ![
      HallPassStatusEnum.ACTIVE,
      HallPassStatusEnum.OVERDUE,
      HallPassStatusEnum.PENDING_APPROVAL,
    ].includes(pass.status.state);
    if (shouldntTick) return of('DISABLED');
    this._recentlyEnded = recentlyEndedSetting;
    this._manuallyEnded = manuallyEndPassType ?? manuallyEndedSetting;
    this._init(pass);
    return timeTicker$.pipe(
      takeUntil(this._destroyed$),
      tap(v => this._calculateTime(v)),
      map(() => this._format()),
    );
  }

  private _format() {
    const largeNum = this._time.years || this._time.days;
    const formattedSeconds = this._time.seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const smallNum = `${
      this._time.hours
        ? `${this._time.hours}:${this._time.minutes}:`
        : `${this._time.minutes}`
        ? `${this._time.minutes}:`
        : ''
    }${formattedSeconds}`;
    const tense = largeNum ? (this.isActive ? 'left' : 'ago') : undefined;
    const overdueIndicator = this.isActive || this.isPendingApproval ? '' : '+';
    if (this.isEnded) return 'DISABLED';
    return (
      overdueIndicator +
      [largeNum ? this._getLargeTime() : smallNum, tense].join(' ')
    );
  }

  private _getLargeTime() {
    if (this._time.years) {
      return `${this._time.years} year${this._time.years > 1 ? 's' : ''}`;
    }
    return `${this._time.days} day${this._time.days > 1 ? 's' : ''}`;
  }

  private _init(pass: HpmDashboardTableItem) {
    this._pass = pass;
    this._initialState = this._pass.status.state;
    const now = day();
    const _end = day(this._pass.status.end);
    const _expire = day(this._pass.status.expire);
    if (this._pass.status.state === HallPassStatusEnum.ACTIVE) {
      // set remaining time
      this._unix.remaining = _expire.unix() - now.unix();
      this._unix.elapsed = this._unix.remaining; // seconds elapsed when pass expires
      this._unix.end = _end.unix() - _expire.unix(); // seconds from expire to end
    } else if (
      this._pass.status.state === HallPassStatusEnum.PENDING_APPROVAL
    ) {
      this._unix.remaining = _end.unix() - now.unix();
      this._unix.elapsed = this._unix.remaining;
      this._unix.end = _end.unix() - _end.unix();
    } else {
      this._unix.elapsed = now.unix() - _expire.unix(); // seconds since pass expired
      this._unix.end = _end.unix() - _expire.unix(); // seconds from expiry to ended
    }
  }

  private _calculateTime(v: number) {
    let totalSeconds = 0;
    if (
      [HallPassStatusEnum.ACTIVE, HallPassStatusEnum.PENDING_APPROVAL].includes(
        this._pass.status.state,
      )
    ) {
      totalSeconds = this._unix.remaining - v;
    } else {
      // timer needs to be adjusted after expiry. It needs to start counting
      // up instead of down. Adjustment is different depending on if hall pass
      // item expired while on dashboard or if expired before being loaded
      // onto dashboard
      totalSeconds =
        this._initialState === HallPassStatusEnum.ACTIVE
          ? Math.abs(v - this._unix.elapsed)
          : v + this._unix.elapsed;
    }

    this._setHallPassState(totalSeconds);

    this._time.hours = Math.floor(totalSeconds / 3600);
    this._time.days = Math.floor(this._time.hours / 24);
    this._time.years = Math.floor(this._time.days / 365);
    totalSeconds %= 3600;
    this._time.minutes = Math.floor(totalSeconds / 60);
    this._time.seconds = totalSeconds % 60;
  }

  private _setHallPassState(secondsElapsed: number) {
    if (
      secondsElapsed < 1 &&
      this._pass.status.state !== HallPassStatusEnum.ENDED
    ) {
      if (this.isPendingApproval) {
        this._removePass(this._pass.id);
        return;
      }
      this._pass.status.state = this._manuallyEnded
        ? HallPassStatusEnum.OVERDUE
        : HallPassStatusEnum.ENDED;
      this._updatePass(this._pass.status.state);
    } else if (
      this._pass.status.state === HallPassStatusEnum.OVERDUE &&
      secondsElapsed >= this._unix.end
    ) {
      this._pass.status.state = HallPassStatusEnum.ENDED;
      this._cleanUpRecentlyEnded();
      this._updatePass(this._pass.status.state);
    } else if (this._pass.status.state === HallPassStatusEnum.ENDED) {
      this._cleanUpRecentlyEnded();
    }
  }

  private _cleanUpRecentlyEnded() {
    if (this._recentlyEnded) {
      setTimeout(
        () => this._removePass(this._pass.id),
        this._recentlyEnded * 60 * 1000,
      );
    } else this._removePass(this._pass.id);
  }

  private _updatePass(state: HallPassStatusEnum) {
    this._hallPassActions.onPassCountdownChange({
      type: 'update',
      id: this._pass.id,
      state,
    });
  }

  private _removePass(id: number) {
    this._hallPassActions.onPassCountdownChange({
      type: 'remove',
      id,
    });
  }
}
