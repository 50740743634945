"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const minga_pb_1 = require("minga/proto/gateway/minga_pb");
const _1 = require("minga/libraries/shared-grpc/sis/index");
exports.toProto = (item, utcOffset) => {
    const msg = new minga_pb_1.sisUnifiedSyncLog();
    msg.setSummary(_1.SisSyncLogMapper.toProto(item, utcOffset));
    if (item.studentTotals) {
        msg.setStudentTotals(_1.SisSyncLogTotalsMapper.toProto(item.studentTotals));
    }
    if (item.teacherTotals) {
        msg.setTeacherTotals(_1.SisSyncLogTotalsMapper.toProto(item.teacherTotals));
    }
    if (item.staffTotals) {
        msg.setStaffTotals(_1.SisSyncLogTotalsMapper.toProto(item.staffTotals));
    }
    if (item.termTotals) {
        msg.setTermTotals(_1.SisSyncLogTotalsMapper.toProto(item.termTotals));
    }
    if (item.sectionTotals) {
        msg.setSectionTotals(_1.SisSyncLogTotalsMapper.toProto(item.sectionTotals));
    }
    if (item.termFileLink) {
        msg.setTermFileLink(item.termFileLink);
    }
    if (item.sectionFileLink) {
        msg.setSectionFileLink(item.sectionFileLink);
    }
    if (item.studentFileLink) {
        msg.setStudentFileLink(item.studentFileLink);
    }
    if (item.teacherFileLink) {
        msg.setTeacherFileLink(item.teacherFileLink);
    }
    if (item.staffFileLink) {
        msg.setStaffFileLink(item.staffFileLink);
    }
    return msg;
};
exports.fromProto = (msg) => {
    const summary = _1.SisSyncLogMapper.fromProto(msg.getSummary());
    const log = {
        numCreated: summary.numCreated,
        numUpdated: summary.numUpdated,
        numIgnored: summary.numIgnored,
        numArchived: summary.numArchived,
        numRestored: summary.numRestored,
        numErrors: summary.numErrors,
        fileLink: summary.fileLink,
        updatedAt: summary.updatedAt,
        error: summary.error,
        type: summary.type,
        studentTotals: _1.SisSyncLogTotalsMapper.fromProto(msg.getStudentTotals()),
        teacherTotals: _1.SisSyncLogTotalsMapper.fromProto(msg.getTeacherTotals()),
        staffTotals: _1.SisSyncLogTotalsMapper.fromProto(msg.getStaffTotals()),
        termTotals: _1.SisSyncLogTotalsMapper.fromProto(msg.getTermTotals()),
        sectionTotals: _1.SisSyncLogTotalsMapper.fromProto(msg.getSectionTotals()),
        termFileLink: msg.getTermFileLink(),
        sectionFileLink: msg.getSectionFileLink(),
        studentFileLink: msg.getStudentFileLink(),
        teacherFileLink: msg.getTeacherFileLink(),
        staffFileLink: msg.getStaffFileLink(),
    };
    return log;
};