import { Pipe, PipeTransform } from '@angular/core';

import { IPbisType } from 'minga/libraries/domain';
import { PbisCategory } from 'minga/libraries/shared';

import { ICON_CONFIG_DEFAULTS } from '../components/bm-types-behavior-edit/bm-types-behavior-edit.constants';

@Pipe({ name: 'getAutomationGroupTriggers' })
export class GetAutomationGroupTriggersPipe implements PipeTransform {
  transform(
    behaviorTypeIds: { id: number }[],
    hashMap: Record<number, IPbisType>,
  ): {
    color: string | undefined;
    icon: string | undefined;
    label: string;
  }[] {
    if (!hashMap) return [];
    return behaviorTypeIds.map(t => {
      const type = hashMap[t?.id];
      const isPraise = type.categoryId === 0;
      const defaultIcon =
        ICON_CONFIG_DEFAULTS[
          isPraise ? PbisCategory.PRAISE : PbisCategory.GUIDANCE
        ];
      return {
        icon: type.iconType || defaultIcon.icon,
        color: type.iconColor || defaultIcon.color,
        label: type.name,
      };
    });
  }
}
