import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  EventContentService,
  eventErrorOutput,
} from 'minga/app/src/app/minimal/services/EventContent';
import { EventStatus, IEventTicketGuest } from 'minga/libraries/domain';

import { ValidationErrorDialogService } from '../dialog/ValidationErrorDialog';
import {
  IEventConfirmationDialogData,
  IEventGuestStatus,
  IEventIdScannerDialogResult,
} from './types';

/**
 * The required display data for the dialog.
 */
interface IStudentIdScannerStudentDisplayInfo {
  displayName: string;
  ticket: boolean;
}

/**
 * Dialog component for scanning student IDs for event. May only be used in
 * `EventConfirmationDialogService`.
 *
 * This dialog is used for checkin AND checkout.
 */
@Component({
  templateUrl: './EventConfirmationDialog.component.html',
  styleUrls: ['./EventConfirmationDialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventConfirmationDialogComponent implements OnInit {
  /*********************************
   * properties for single person case
   *********************************/
  personChecked = true;
  personUpdatable = true;
  guests: IEventGuestStatus[] = [];
  displayData: IStudentIdScannerStudentDisplayInfo | null = null;

  /*********************************
   * properties for both
   *********************************/
  requiresTicket = false;
  status: EventStatus;

  // Current person hash that is being confirmed
  confirmingPersonHashes: string[] = [];
  state: 'loading' | 'unknown-student' | 'known-student' | 'error' = 'loading';
  unknownStudentId = '';
  private _result: IEventIdScannerDialogResult = {
    personHashList: [],
    close: true,
  };

  /*********************************
   * properties for multi-person case
   *********************************/

  totalPeopleCount = 0;
  totalGuestCount = 0;
  totalTicketCount = 0;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _eventService: EventContentService,

    private _eventValidationErrorDialog: ValidationErrorDialogService,
    private _matDialogRef: MatDialogRef<
      EventConfirmationDialogComponent,
      IEventIdScannerDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) public data: IEventConfirmationDialogData,
  ) {
    this._matDialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.status = data.status || EventStatus.CHECKED_IN;
  }

  get selectedCount() {
    let count = 0;

    if (this.personChecked) count++;

    for (const guest of this.guests) {
      if (guest.selected) {
        count++;
      }
    }
    return count;
  }

  get headerText() {
    if (this._missingTickets) {
      return 'MISSING TICKETS';
    } else {
      return `${this.totalTicketCount} TICKETS`;
    }
  }

  get buttonText() {
    let prefix = '';
    if (this._missingTickets) prefix = 'Add Ticket & ';
    return `${prefix}${this.data.confirmText || 'Confirm'}`;
  }

  get _missingTickets() {
    return (
      this.totalPeopleCount !== this.totalTicketCount - this.totalGuestCount
    );
  }

  ngOnInit() {
    this.getUserInfo();
  }

  close(scanAgain?: boolean) {
    if (scanAgain) {
      this._result.close = false;
    }
    this._matDialogRef.close(this._result);
  }

  async confirm() {
    if (this.confirmingPersonHashes.length > 1) {
      this._result.personHashList = this.confirmingPersonHashes;
    }
    if (this.data.onSave) {
      this.state = 'loading';
      this._cdr.markForCheck();
      // if there is just one person, we want to make sure they are
      // checked before including them in the checkin.
      if (this.confirmingPersonHashes.length === 1) {
        if (!this.personChecked && this.requiresTicket) {
          this.confirmingPersonHashes = [];
        }
      }
      this.close(true);
      await this.data.onSave(this.confirmingPersonHashes, this.guests);
    }
  }

  async getUserInfo() {
    if (
      (!this.data.selectedStudentIds ||
        this.data.selectedStudentIds.length === 0) &&
      (!this.data.selectedPersonhashes ||
        this.data.selectedPersonhashes.length === 0)
    ) {
      this.state = 'error';
      this._cdr.markForCheck();
      throw new Error('No people selected');
    }
    try {
      await this._fetchStudentInfo();
    } catch (err) {
      this.state = 'error';
      this._cdr.markForCheck();
      throw err;
    }
  }

  private async _fetchStudentInfo() {
    const contextHash = this.data.eventContextHash;
    const studentIds = this.data.selectedStudentIds || [];
    const personHashes = this.data.selectedPersonhashes || [];

    const response = await this._eventService.getEventTicketsForStudentId(
      contextHash,
      studentIds,
      personHashes,
    );

    if (!response) {
      throw new Error('Error getting checkin info');
    }
    this.requiresTicket = response.requiresTicket;
    const people = response.people;
    const singleCheckinOnly = response.singleCheckinOnly;
    if (response && people.length > 0) {
      if (people.length === 1) {
        const person = people[0];
        const displayName = person.displayName;
        this.confirmingPersonHashes.push(person.personHash);

        this.displayData = { displayName, ticket: false };
        if (this.data.status === EventStatus.CHECKED_IN) {
          if (person.checkInTime) {
            this.personUpdatable = false;
            this.personChecked = false;
          }
        } else if (this.data.status === EventStatus.CHECKED_OUT) {
          if (person.checkOutTime) {
            this.personUpdatable = false;
            this.personChecked = false;
          }
        }

        const ticket = person.ticket;
        if (ticket) {
          if (!ticket.guests.length) {
            // if this event only allows single checkins, dont go straight to confirm.
            if (singleCheckinOnly && person.checkInTime) {
              const _dialog = this._eventValidationErrorDialog.open({
                data: {
                  title: 'Already checked in',
                  message: 'This person was already checked in',
                  people: { name: person.displayName },
                },
              });

              const value = await _dialog.afterClosed().toPromise();
              if (value === eventErrorOutput.ALLOW_BLOCKED) {
                await this.confirm();
              } else {
                this.close();
              }
            } else {
              await this.confirm();
            }
            return;
          } else {
            this.displayData.ticket = true;
            this.guests = ticket.guests.map(guest => this._mapGuest(guest));
            this.totalTicketCount = 1 + this.guests.length;
          }
        }
      } else {
        this.confirmingPersonHashes = people.map(person => person.personHash);
        const peopleWithTickets = people.filter(person => !!person.ticket);
        const peopleWithGuests = peopleWithTickets.filter(
          person => !!person.ticket?.guests,
        );
        const guests: IEventTicketGuest[] = [];
        for (const person of peopleWithGuests) {
          if (person.ticket) {
            if (person.ticket.guests) {
              for (const guest of person.ticket.guests) {
                guests.push(guest);
              }
            }
          }
        }
        this.guests = guests.map(guest => this._mapGuest(guest));
        this.totalTicketCount = peopleWithTickets.length + guests.length;
      }
      this.totalPeopleCount = people.length;
      this.totalGuestCount = this.guests.length;

      this.state = 'known-student';
    } else {
      this.unknownStudentId = studentIds[0];
      this.state = 'unknown-student';
    }

    this._cdr.markForCheck();
  }

  isSelected(guest: IEventGuestStatus): boolean {
    const find = this.guests.find(item => guest.guestId === item.guestId);
    if (!find) {
      return false;
    }

    return find.selected;
  }

  toggleGuestSelected(guest: IEventGuestStatus) {
    guest.selected = !guest.selected;
  }

  get canStillUpdate(): boolean {
    const ableToUpdate = this.guests.findIndex(guest => guest.canUpdate);
    return ableToUpdate > -1 || this.personUpdatable;
  }

  get showUpdateButton(): boolean {
    return this.selectedCount > 0 && this.canStillUpdate;
  }

  _mapGuest(item: IEventTicketGuest): IEventGuestStatus {
    let status = EventStatus.NONE;
    let canUpdate = true;
    if (item.checkOutTime) {
      status = EventStatus.CHECKED_OUT;
    } else if (item.checkInTime) {
      status = EventStatus.CHECKED_IN;
    }
    if (this.data.canUpdate) {
      canUpdate = this.data.canUpdate(item);
    }
    const guest: IEventGuestStatus = {
      guestId: item.guestId || 0,
      name: item.guestName,
      selected: false,
      // can't update if they are already checked in.
      canUpdate,
      status,
    };
    // if they can be updated, default to selected.
    if (canUpdate) {
      guest.selected = true;
    }
    return guest;
  }
}
