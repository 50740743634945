import Fuse from 'fuse.js';

export type SelectGroupOption<Value = number | string, Data = any> = {
  value: Value;
  label: string;
  color?: string;
  icon?: string;
  iconNamespace?: string;
  data?: Data;
};

export type SelectGroupCategory<
  CategoryValueType = number | string,
  ItemValueType = number,
  ItemData = any,
> = {
  value: CategoryValueType;
  label: string;
  icon?: string;
  iconNamespace?: string;
  items: SelectGroupOption<ItemValueType, ItemData>[];
  lazyLoad?: boolean;
  fetch?: () => Promise<SelectGroupOption<ItemValueType, ItemData>[]>;

  /**
   * Flag which fetcher we use for user search since no data
   * is potentially loaded when user starts typing with lazy loading
   */
  usedForUserSearch?: boolean;
  /**
   * On picker open this will show the category open by default
   */
  openByDefault?: boolean;
};

export type OptionItem<Value = number, Data = any> = SelectGroupOption<
  Value,
  Data
> & {
  category: {
    value: any;
    label: string;
  };
};

export type CategoryItem<
  CategoryValueType = number | string,
  ItemValueType = number,
  ItemData = any,
> = Omit<
  SelectGroupCategory<CategoryValueType, ItemValueType, ItemData>,
  'items'
>;

export enum SelectType {
  CATEGORY = 'category',
  OPTION = 'list',
}

export type SearchAdapter = Fuse<OptionItem>;
