<!-- Bottom sheet presentional container -->
<mg-bottom-sheet
  [title]="title$ | async"
  [showBackBtn]="(activeField$ | async) !== null"
  [fullHeight]="(activeField$ | async) !== null"
  (onEsc)="onEsc()"
  (onClose)="onClose()"
  (onBack)="clearActiveField()">
  <!-- Main content -->
  <div class="main-content">
    <ng-container
      *ngIf="(activeField$ | async) === null; else fieldControlTemplate"
      [ngTemplateOutlet]="fieldSummaryTemplate">
    </ng-container>
  </div>
  <!-- Footer -->
  <ng-template #actions>
    <ng-container
      *ngIf="(activeField$ | async) === null; else fieldActionTemplate"
      [ngTemplateOutlet]="fieldSummaryActionTemplate">
    </ng-container>
  </ng-template>
</mg-bottom-sheet>

<!-- Field summary action template -->
<ng-template #fieldSummaryActionTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      variant="text"
      (pressed)="clear()">
      {{ MESSAGE.BUTTON_RESET_FILTERS }}
    </mg-btn>
    <mg-btn
      variant="filled"
      (pressed)="submit()">
      {{ MESSAGE.BUTTON_APPLY_FILTERS }}
    </mg-btn>
  </div>
</ng-template>

<ng-template #fieldActionTemplate>
  <div
    fxLayout="row"
    [fxLayoutAlign]="
      (showUnselectAllButton$ | async) ? 'space-between center' : 'end center'
    ">
    <mg-btn
      *ngIf="showUnselectAllButton$ | async"
      variant="text"
      (pressed)="unSelectAllFromActiveField()">
      {{
        (activeFieldType$ | async) === 'single-select'
          ? MESSAGE.BUTTON_UNSELECT
          : MESSAGE.BUTTON_UNSELECT_ALL
      }}
    </mg-btn>
    <mg-btn
      variant="filled"
      (pressed)="submitFieldChanges()">
      {{ MESSAGE.BUTTON_DONE }}
    </mg-btn>
  </div>
</ng-template>

<!-- Field summary template -->
<ng-template #fieldSummaryTemplate>
  <ng-container *ngFor="let field of data.fields">
    <ng-container
      *ngIf="
        data.data
          | checkForFieldCallback: field:draftFormData$
          | async
          | async as fieldData
      ">
      <!-- Switch between different input types -->
      <ng-container [ngSwitch]="fieldData.type">
        <!-- Button group input -->
        <mat-button-toggle-group
          *ngSwitchCase="'toggle-group'"
          class="filter-toggle-group"
          [name]="field"
          [multiple]="false"
          [value]="data.formGroup.get(field).value"
          (change)="setToggleGroupValue(field, $event)">
          <mat-button-toggle
            *ngFor="let option of $any(data.data[field]).options"
            [value]="option.value">
            <mg-text variant="body-sm-secondary">
              {{ option.label }}
            </mg-text>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <!-- Field button -->
        <button
          *ngSwitchDefault
          class="field-button"
          matRipple
          matRippleColor="rgba(0, 0, 0, 0.02)"
          [disabled]="fieldData?.disabled || false"
          [matRippleDisabled]="fieldData?.disabled || false"
          (click)="setActiveFilter(field)">
          <!-- Label & value -->
          <div class="field-button-content">
            <mg-text variant="body-md-secondary">
              {{ fieldData?.label }}
            </mg-text>
            <!-- Tag values -->
            <ng-container *ngIf="(draftFormData$ | async)[field] as value">
              <div class="field-button-content-values">
                <ng-container [ngSwitch]="fieldData?.type">
                  <!-- Single select value -->
                  <ng-container *ngSwitchCase="FIELD_TYPE.SINGLE_SELECT">
                    <mg-tag>
                      {{
                        ($any(data.data[field]).options | find: 'value':value)
                          ?.label
                      }}
                    </mg-tag>
                  </ng-container>
                  <!-- Multi select value -->
                  <ng-container *ngSwitchCase="FIELD_TYPE.MULTI_SELECT">
                    <mg-tag
                      *ngFor="
                        let value of $any(fieldData)?.options
                          | getLabelsFromOptions: value:false
                      ">
                      {{ value }}
                    </mg-tag>
                  </ng-container>
                  <!-- User list value -->
                  <ng-container *ngSwitchCase="FIELD_TYPE.USER_LIST">
                    <mg-tag
                      *ngFor="
                        let label of value | getUserListLabelsFromIds | async
                      ">
                      {{ label }}
                    </mg-tag>
                  </ng-container>
                  <!-- People search value -->
                  <ng-container *ngSwitchCase="FIELD_TYPE.PEOPLE_SEARCH">
                    <mg-tag *ngIf="value.length">
                      {{ value | getPeopleNameLabels | join: ', ' }}
                    </mg-tag>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <!-- Icon -->
          <mg-icon
            class="field-button-icon"
            iconName="mg-chevron-right">
          </mg-icon>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Field control template -->
<ng-template #fieldControlTemplate>
  <ng-container *ngIf="activeField$ | async as activeField">
    <ng-container
      *ngIf="
        data.data
          | checkForFieldCallback: activeField:draftFormData$
          | async
          | async as fieldData
      ">
      <ng-container [ngSwitch]="fieldData?.type">
        <!-- Person search -->
        <ng-container *ngSwitchCase="FIELD_TYPE.PEOPLE_SEARCH">
          <mg-filters-form-person-search
            [label]="fieldData?.label"
            [multiple]="$any(fieldData)?.multiple"
            [done]="submitFieldChangesEvent$"
            [id]="fieldData?.id"
            [clearSelection]="clearSelectionEvent$"
            [selected]="(draftFormData$ | async)[activeField] || []"
            (selectionChange)="setSelectValue(activeField, $event)">
          </mg-filters-form-person-search>
        </ng-container>

        <!-- User List -->
        <ng-container *ngSwitchCase="FIELD_TYPE.USER_LIST">
          <mg-user-list-filter
            returnMode="full"
            [id]="fieldData?.id"
            [placeholder]="fieldData?.label"
            [layout]="'in-bottom-sheet'"
            [commitChanges$]="submitFieldChangesEvent$"
            [multiple]="$any(fieldData)?.multiple || true"
            [value]="(draftFormData$ | async)[activeField] || []"
            [clearSelection]="clearSelectionEvent$"
            (selectChanged)="setUserListValue(activeField, $event)"
            (commitChanges)="setUserListValue(activeField, $event)">
          </mg-user-list-filter>
        </ng-container>

        <!-- Single select -->
        <mg-select-element
          *ngSwitchCase="FIELD_TYPE.SINGLE_SELECT"
          selectedValueType="value"
          [options]="$any(fieldData)?.options || []"
          [enableSearch]="
            $any(fieldData)?.enableSearchOnMobile != null
              ? $any(fieldData)?.enableSearchOnMobile
              : true
          "
          [clearSelection]="clearSelectionEvent$"
          [multiple]="false"
          [done]="submitFieldChangesEvent$"
          [selected]="[(draftFormData$ | async)[activeField] || []]"
          (selectionChange)="setSelectValue(activeField, $event)">
        </mg-select-element>

        <!-- Multi select -->
        <mg-select-element
          *ngSwitchCase="FIELD_TYPE.MULTI_SELECT"
          selectedValueType="value"
          [options]="$any(fieldData)?.options || []"
          [enableSearch]="
            $any(fieldData)?.enableSearchOnMobile != null
              ? $any(fieldData)?.enableSearchOnMobile
              : true
          "
          [multiple]="true"
          [done]="submitFieldChangesEvent$"
          [clearSelection]="clearSelectionEvent$"
          [selected]="(draftFormData$ | async)[activeField] || []"
          (selectionChange)="setSelectValue(activeField, $event)">
        </mg-select-element>

        <!-- Text input -->
        <mg-form-text-input
          #formTextInputComponent
          *ngSwitchCase="FIELD_TYPE.TEXT"
          widthSize="large"
          [id]="fieldData?.id || activeField"
          [isDisabled]="fieldData?.disabled"
          [label]="fieldData?.label"
          [control]="data.formGroup.get(activeField)">
        </mg-form-text-input>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
