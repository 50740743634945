<!-- Wrapper -->
<div class="wrapper">
  <!-- Search input -->
  <mg-form-text-input
    placeholder="Search"
    iconRight="mg-search"
    [isClearable]="true"
    [control]="searchControl">
  </mg-form-text-input>

  <!-- Virtual scroll area -->
  <div class="viewport-container">
    <!-- Virtual scroller -->
    <cdk-virtual-scroll-viewport
      *ngIf="searchResults$ | async as results"
      itemSize="50"
      class="option-viewport">
      <button
        *cdkVirtualFor="let option of getDisplayedOptions(results)"
        matRipple
        class="option"
        [class.checkbox]="multiple"
        [class.radio]="!multiple"
        [matRippleColor]="'rgba(0, 0, 0, 0.02)'"
        [matRippleDisabled]="option?.disabled"
        [disabled]="option?.disabled"
        (click)="select(option)">
        <!-- Multiple selection -->
        <mg-form-checkbox
          *ngIf="multiple; else radioTemplate"
          [checked]="selection.isSelected(option)"
          [disabled]="option?.disabled">
          <mg-text
            variant="body-md"
            [numberOfLines]="2">
            {{ option.label }}
          </mg-text>
        </mg-form-checkbox>
        <!-- Single selection -->
        <ng-template #radioTemplate>
          <mat-radio-button [value]="option.value">
            {{ option.label }}
          </mat-radio-button>
        </ng-template>
      </button>
    </cdk-virtual-scroll-viewport>
    <!-- Empty state -->
  </div>
</div>
