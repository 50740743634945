"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const restriction_pb = require("minga/proto/restriction/restriction_pb");
const domain_1 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const membership_list_1 = require("minga/libraries/shared-grpc/membership_list/index");
exports.toProto = (restriction) => {
    const message = new restriction_pb.Restriction();
    if (restriction.id)
        message.setId(restriction.id);
    if (restriction.roles)
        message.setRolesList(restriction.roles);
    if (restriction.userLists)
        message.setUserListsList(restriction.userLists);
    if (restriction.grades)
        message.setGradesList(restriction.grades);
    if (restriction.groupHashes)
        message.setMingaGroupHashesList(restriction.groupHashes);
    if (restriction.stickerIds)
        message.setStickerIdsList(restriction.stickerIds);
    if (restriction.restrictedCheckinReasonId)
        message.setRestrictedCheckinReasonId(restriction.restrictedCheckinReasonId);
    if (restriction.membershipList)
        message.setMembershipList(membership_list_1.MembershipListMapper.toProto(restriction.membershipList));
    if (restriction.membershipListId)
        message.setMembershipListId(restriction.membershipListId);
    return message;
};
exports.fromProto = (msg) => {
    var _a;
    const rolesStrings = msg.getRolesList();
    const roles = rolesStrings.map(r => ts_enum_util_1.$enum(domain_1.MingaRoleType).asValueOrThrow(r));
    const gradeStrings = msg.getGradesList();
    const grades = gradeStrings.map(r => ts_enum_util_1.$enum(util_1.Grade).asValueOrThrow(r));
    return {
        id: (_a = msg.getId()) !== null && _a !== void 0 ? _a : undefined,
        roles,
        userLists: msg.getUserListsList(),
        grades,
        groupHashes: msg.getMingaGroupHashesList(),
        stickerIds: msg.getStickerIdsList(),
        restrictedCheckinReasonId: msg.getRestrictedCheckinReasonId(),
        membershipList: msg.getMembershipList()
            ? membership_list_1.MembershipListMapper.fromProto(msg.getMembershipList())
            : undefined,
        membershipListId: msg.getMembershipListId(),
    };
};