"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const date_range_pb = require("minga/proto/date_range/date_range_preset_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
exports.toProto = (preset) => {
    const message = new date_range_pb.DateRangePreset();
    if (preset.id)
        message.setId(preset.id);
    if (preset.name)
        message.setName(preset.name);
    if (preset.active)
        message.setActive(preset.active);
    if (preset.startDate)
        message.setStartDate(shared_grpc_1.dayJsObjectToDateTimeMsg(preset.startDate));
    if (preset.endDate)
        message.setEndDate(shared_grpc_1.dayJsObjectToDateTimeMsg(preset.endDate));
    if (preset.endDateIsCurrentDay)
        message.setEndDateIsCurrentDay(preset.endDateIsCurrentDay);
    return message;
};
exports.fromProto = (message) => {
    const dateRange = {
        id: message.getId(),
        name: message.getName(),
        active: message.getActive(),
        startDate: shared_grpc_1.dateTimeMessageToDayjsObject(message.getStartDate()),
        endDateIsCurrentDay: message.getEndDateIsCurrentDay(),
    };
    if (message.getEndDate()) {
        dateRange.endDate = shared_grpc_1.dateTimeMessageToDayjsObject(message.getEndDate());
    }
    return dateRange;
};