import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExistingConsequenceType } from 'minga/domain/consequences';
import { IPbisType } from 'minga/domain/pbis';
import { ReportTypes } from 'minga/domain/reportTypes';
import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';
import { PBIS_CHECKIN_TYPES } from '@modules/teacher-tools/constants/tt-view.constants';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { CarouselStylePresets } from '@shared/components/tiles-carousel';
import { getQueryParamsFromDateRange } from '@shared/utils';

import {
  BehaviorManagerMessages,
  BehaviorManagerRoutes,
} from '../../constants';
import { BehaviorManagerService } from '../../services';
import { BmReportsFilterType } from '../bm-reports/constants';
import { BmReportsService } from '../bm-reports/services';
import { BmDashboardMessages } from './constants';
import { BmDashboardService } from './services';

day.extend(localizedFormat);

@Component({
  selector: 'mg-bm-dashboard',
  templateUrl: './bm-dashboard.component.html',
  styleUrls: ['./bm-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmDashboardComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly BM_MESSAGES = BehaviorManagerMessages;
  public readonly MESSAGES = BmDashboardMessages;
  public readonly CAROUSEL_PRESETS = CarouselStylePresets;

  /** Date */
  public todaysDate: string;

  /** General Observables */
  private readonly _destroyed = new ReplaySubject<void>(1);
  public readonly isLoading$: Observable<boolean> = combineLatest([
    this.bmService.isLoadingTypesData$,
  ]).pipe(map(val => val.every(v => v === true)));
  public readonly areConsequencesEnabled$: Observable<boolean>;

  /** Active types */
  public readonly activePipes$ = this.bmService.types$.pipe(
    map(types => types.filter(type => type.active)),
  );

  /** Component Constructor */
  constructor(
    public bmService: BehaviorManagerService,
    public bmDashboard: BmDashboardService,
    public layout: LayoutService,
    private _router: Router,
    private _bmReportsService: BmReportsService,
    private _settingsService: MingaSettingsService,
    private _modalOverlayService: ModalOverlayService,
  ) {
    this.areConsequencesEnabled$ = this._settingsService.getSettingValueObs(
      mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
    );
  }

  ngOnInit(): void {
    this.todaysDate = day().format('LL');
    this.bmService.fetchTypes(true);
    this.bmService.fetchConsequenceTypes(true);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async goToReports(
    item: IPbisType | ExistingConsequenceType,
    type: string,
  ): Promise<void> {
    const startDate = day().startOf('day');
    const endDate = day().endOf('day');
    const queryParams = getQueryParamsFromDateRange({ startDate, endDate });

    if (type === 'consequence') {
      this._bmReportsService.setFilter(BmReportsFilterType.CONS_TYPE, item.id);
      await this._router.navigate(
        [
          BehaviorManagerRoutes.ROOT,
          BehaviorManagerRoutes.REPORTS,
          ReportTypes.PBIS_CONS_HISTORY,
        ],
        {
          queryParams,
        },
      );
    } else {
      this._bmReportsService.setFilter(BmReportsFilterType.TYPE, item.id);

      await this._router.navigate(
        [
          BehaviorManagerRoutes.ROOT,
          BehaviorManagerRoutes.REPORTS,
          ReportTypes.PBIS_HISTORY,
        ],
        {
          queryParams,
        },
      );
    }
  }

  async openImportBehaviorsDialog() {
    await this._router.navigate(['selection-assigner', 'behavior'], {
      state: { type: PBIS_CHECKIN_TYPES.PRAISE, importBehaviors: true },
    });
  }
}
