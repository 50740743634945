import { Pipe, PipeTransform } from '@angular/core';

/**
 * Array Includes
 */
@Pipe({ name: 'pluck' })
export class ArrayPluckPipe implements PipeTransform {
  // the type of str should be one of the types of the array
  transform<T extends any[]>(value: T, str: string): any[] {
    return (value || []).map(m => m[str]);
  }
}
