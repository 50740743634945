import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ContentRouteData } from 'minga/app/src/app/content-common/types/ContentRouteData';
import { ChallengeContentInfoService } from 'minga/app/src/app/modules/challenges/services';
import { IChallengeDraft } from 'minga/app/src/app/modules/challenges/types';
import { ChallengeType } from 'minga/libraries/domain';

function assertChallengeContentInfo(
  contentInfo: IChallengeDraft | null,
): IChallengeDraft {
  if (!contentInfo)
    throw new Error(
      'Cannot get challenge route data for non-challenge content',
    );
  return contentInfo;
}

export class ChallengeRouteData extends ContentRouteData {
  challengeInfo?: IChallengeDraft;
  challengeType?: ChallengeType;

  constructor(
    protected snapshot: ActivatedRouteSnapshot,
    protected injector: Injector,
    private contentInfoService: ChallengeContentInfoService,
  ) {
    super(snapshot, injector);
  }

  protected async getContentInfo(): Promise<void> {
    await this.contentInfoService
      .getContentInfo(this.contentHash)
      .then(assertChallengeContentInfo)
      .then(async challengeContentInfo => {
        this.challengeInfo = challengeContentInfo;
        this.challengeType = challengeContentInfo.type;
      });
  }
  protected getAdditionalRouteData(): void {
    if (!this.challengeType) {
      this.challengeType = this.snapshot.data.type;
    }
  }
}
