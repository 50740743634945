import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MingaPermission } from 'minga/libraries/domain';
import { ReportTypes } from 'minga/libraries/domain';
import { MingaAppRoutes } from 'src/app/types';

import { AccessRestrictionsGuard } from '@shared/guards';

import {
  HpmDashboardComponent,
  HpmDashboardModule,
  HpmReportsComponent,
  HpmReportsCreatorComponent,
  HpmReportsHistoryComponent,
  HpmReportsLateComponent,
  HpmReportsModule,
  HpmReportsSummaryComponent,
  HpmReportsTypesComponent,
  HpmReportsUserComponent,
  HpmSettingsComponent,
  HpmSettingsModule,
  HpmTypesComponent,
  HpmTypesModule,
} from './components';
import { HpmRestrictionsComponent } from './components/hpm-restrictions/hpm-restrictions.component';
import { HpmRestrictionsModule } from './components/hpm-restrictions/hpm-restrictions.module';
import { HallpassManagerComponent } from './hallpass-manager.component';
import { HallpassManagerModule } from './hallpass-manager.module';
import { HpmRoutes } from './hpm.constants';

export const HPM_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: HallpassManagerComponent,
    data: {
      title: 'Hall Pass',
    },
    children: [
      {
        path: '',
        component: HpmDashboardComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Dashboard',
          enableAutoRedirect: true,
          navigationVisibility: true,
        },
      },
      {
        path: HpmRoutes.REPORTS,
        canActivate: [AccessRestrictionsGuard],
        component: HpmReportsComponent,
        data: {
          title: 'Reports',
          description: 'View and manage hall pass reports',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.HALL_PASS_TYPE_MANAGE,
                MingaPermission.HALL_PASS_VIEW_LIMITED_REPORTS,
                MingaPermission.HALL_PASS_VIEW_REPORTS,
              ],
            },
          },
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: ReportTypes.HALL_PASS_SUMMARY,
          },
          {
            path: ReportTypes.HALL_PASS_SUMMARY,
            component: HpmReportsSummaryComponent,
            data: { title: 'Summary' },
          },
          {
            path: ReportTypes.HALL_PASS_TYPES,
            component: HpmReportsTypesComponent,
            data: { title: 'Types' },
          },
          {
            path: ReportTypes.HALL_PASS_STAFF,
            component: HpmReportsCreatorComponent,
            data: { title: 'Creators' },
          },
          {
            path: ReportTypes.HALL_PASS_STUDENT,
            component: HpmReportsUserComponent,
            data: { title: 'Users' },
          },
          {
            path: ReportTypes.HALL_PASS_HISTORY,
            component: HpmReportsHistoryComponent,
            data: { title: 'History' },
          },
          {
            path: ReportTypes.HALL_PASS_OVERDUE,
            component: HpmReportsLateComponent,
            data: { title: 'Late' },
          },
        ],
      },
      {
        path: HpmRoutes.TYPES,
        canActivate: [AccessRestrictionsGuard],
        component: HpmTypesComponent,
        data: {
          title: 'Types',
          navigationVisibility: true,
          description: 'Manage hall pass types',
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.HALL_PASS_TYPE_MANAGE],
            },
          },
        },
      },
      {
        path: HpmRoutes.RESTRICTIONS,
        canActivate: [AccessRestrictionsGuard],
        component: HpmRestrictionsComponent,
        data: {
          title: 'Restrictions',
          description: 'Manage hall pass restrictions',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.HALL_PASS_TYPE_MANAGE],
            },
          },
        },
      },
      {
        path: HpmRoutes.SETTINGS,
        canActivate: [AccessRestrictionsGuard],
        component: HpmSettingsComponent,
        data: {
          title: 'Settings',
          description: 'Manage hall pass settings',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    HallpassManagerModule,
    HpmDashboardModule,
    HpmReportsModule,
    HpmSettingsModule,
    HpmTypesModule,
    HpmRestrictionsModule,

    RouterModule.forChild(HPM_ROUTES),
  ],
})
export class HpmRoutesModule {}
