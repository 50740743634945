var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RewardsRedeemedMapper = exports.PointRewardMapper = exports.PointHistoryItemMapper = exports.IPointsRedeemedMapper = exports.IPointSummaryItemMapper = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const IPointSummaryItemMapperts = require("minga/libraries/shared-grpc/points/IPointSummaryItemMapper");
exports.IPointSummaryItemMapper = IPointSummaryItemMapperts;
const IPointsRedeemedMapperts = require("minga/libraries/shared-grpc/points/IPointsRedeemedMapper");
exports.IPointsRedeemedMapper = IPointsRedeemedMapperts;
const PointHistoryItemMapperts = require("minga/libraries/shared-grpc/points/PointHistoryItemMapper");
exports.PointHistoryItemMapper = PointHistoryItemMapperts;
const PointRewardMapperts = require("minga/libraries/shared-grpc/points/PointRewardMapper");
exports.PointRewardMapper = PointRewardMapperts;
const RewardsRedeemedMapperts = require("minga/libraries/shared-grpc/points/RewardsRedeemedMapper");
exports.RewardsRedeemedMapper = RewardsRedeemedMapperts;
__exportStar(require("minga/libraries/shared-grpc/points/ITeamPointSummaryItemMapper"), exports);
__exportStar(require("minga/libraries/shared-grpc/points/TeamMapper"), exports);