import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FloatLabelType } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { MgValidators } from 'minga/app/src/app/input/validators';
import { ErrorReporterService } from 'minga/app/src/app/services/ErrorReporter';
import {
  PeopleManagerService,
  PersonFormFields,
} from 'minga/app/src/app/services/PeopleManager';
import { ManualErrorStateMatcher } from 'minga/app/src/app/util/form';
import { StatusCode, UniqueHash } from 'minga/proto/common/legacy_pb';
import { errorPriority } from 'minga/proto/gateway/connect_pb';

export const MG_DEFAULT_OLD_PASSWORD = '**********';

export const MG_PASSWORD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordComponent),
  multi: true,
};

const MG_PASSWORD_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PasswordComponent),
  multi: true,
};

enum SaveState {
  SAVING = 1,
  SAVED,
  ERROR,
  NA,
}

@Component({
  selector: 'mg-password',
  providers: [MG_PASSWORD_VALUE_ACCESSOR, MG_PASSWORD_VALIDATOR],
  templateUrl: './Password.component.html',
  styleUrls: ['./Password.component.scss'],
})
export class PasswordComponent
  implements ControlValueAccessor, Validator, OnChanges, OnInit
{
  // Children

  @ViewChild('inputElement', { static: true })
  inputElementRef?: ElementRef;
  @ViewChild('inputMatElement', { static: true })
  inputMatElementRef?: MatInput;
  @ViewChild('formFieldElement', { static: true })
  formFieldElementRef: any;

  // Inputs

  @Input() disabled = false;
  @Input() editMode = false;
  @Input() name = 'passwordComponent';
  @Input() required = true;
  @Input() initialHint: string;
  @Input() validation = true;
  @Input() hints = true;
  @Input() initialUnset: boolean;
  @Input() mgNoHintMargin: boolean;
  @Input() errorStateMatcher: ErrorStateMatcher | null = null;
  @Input() floatLabel: FloatLabelType = 'always';
  @Input() label = 'Password';
  @Input() editClick?: () => void;

  // Outputs

  @Output() blurEvent: EventEmitter<any> = new EventEmitter();

  private _formGroup: FormGroup;
  public innerValue: string;

  public enableReadOnlyState: boolean =
    !window.MINGA_DEVICE_ANDROID && !window.MINGA_DEVICE_IOS;

  public passwordIcon = 'visibility';
  public passwordVisible = false;
  public inputType = 'password';
  public editing = false;

  public saveStatus: SaveState = SaveState.NA;
  public translateParams = { value: 'password' };
  public SaveState: typeof SaveState = SaveState;
  private _prevStatus: any;
  public outerErrors: ValidationErrors;
  public passwordControl: FormControl;
  public outerControl: FormControl;
  private _initEditString: string = MG_DEFAULT_OLD_PASSWORD;
  public disableBlur = false;
  public matcher: ManualErrorStateMatcher | null = null;

  private _hasFocus = false;

  onChange: (value: unknown) => void;
  onTouched: () => void;

  // Constructor

  constructor(
    public errorReporting: ErrorReporterService,
    private _managerService: PeopleManagerService,
    private _inj: Injector,
  ) {
    this.outerControl = new FormControl();
  }

  // Getters

  get hintText(): string {
    if (!this.initialHint || this._hasFocus) {
      if (this.hints && this._hasFocus) {
        return 'Choose a mix of 8 to 16 letters, numbers, and symbols';
      } else {
        return '';
      }
    }

    return this.initialHint;
  }

  get passwordRequired(): boolean {
    return this.passwordControl.errors && this.passwordControl.errors.required;
  }

  get passwordControlErrorKey() {
    const innerErrors = this.passwordControl.errors
      ? Object.keys(this.passwordControl.errors)[0]
      : '';
    const outerErrors = this.outerControl.errors
      ? Object.keys(this.outerControl.errors)[0]
      : '';

    return innerErrors ? innerErrors : outerErrors;
  }

  // Lifecycle Hooks

  ngOnInit() {
    this.passwordControl = new FormControl({ disabled: this.disabled });
    this._formGroup = new FormGroup({ inputControl: this.passwordControl });

    this._initOuterControl();
    this.passwordControl.setValue(this.innerValue);

    this.passwordControl.statusChanges.subscribe(status => {
      // Angular does not know that the value has changed
      // from our component, so we need to update her with the new value.
      if (
        typeof this.onChange == 'function' &&
        status !== this._prevStatus &&
        this.innerValue !== this._initEditString
      ) {
        if (this.passwordControl.pristine) {
          setTimeout(() => {
            this.onChange(this.innerValue);

            // @TODO: remove this @HACK: to remove error coloring/state when
            // first clicking back after saving...
            this.outerControl.markAsPristine();
            this.formFieldElementRef._elementRef.nativeElement.classList.remove(
              'mat-form-field-invalid',
            );
          });
        } else {
          this._prevStatus = status;
          if (this.onChange) {
            this.onChange(this.innerValue);
            this.onTouched();
          }

          // @TODO: remove this @HACK: to remove error coloring/state when first
          // clicking back after saving...
          if (!!this.passwordControl.errors) {
            this.formFieldElementRef._elementRef.nativeElement.classList.add(
              'mat-form-field-invalid',
            );
          } else {
            this.formFieldElementRef._elementRef.nativeElement.classList.remove(
              'mat-form-field-invalid',
            );
          }
        }
      } else if (this.innerValue === this._initEditString) {
        this.outerControl.markAsPristine();
        this.passwordControl.markAsPristine();
      }
    });

    if (this.editMode) {
      this.initEditMode();
    } else {
      this._setValidators();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialUnset) {
      this.writeValue(
        this.initialUnset ? this.innerValue : this._initEditString,
      );
    }
  }

  // Public Methods

  public validate(_control: AbstractControl): ValidationErrors | null {
    // Pass through our internal
    return this.passwordControl.errors;
  }

  public cancelEdit() {
    this.initEditMode(false);
  }

  public savePassword(personHash: UniqueHash) {
    this.editing = false;
    this.saveStatus = SaveState.SAVING;
    this.passwordControl.markAsPending();

    return new Promise((resolve, reject) => {
      const showError = () => {
        // show error on password input like a validation error...
        this.saveStatus = SaveState.ERROR;
        const error: ValidationErrors = { savePassword: true };
        this.passwordControl.setErrors(error);

        reject('Failed to save password.');
      };

      if (!personHash) {
        console.warn(
          '[Password]savePassword() attempted to save password with no indentiy hash',
        );
        this.errorReporting.sendErrorReport(
          'client Password.component',
          'attempted to save password with no indentiy hash',
          errorPriority.WARN,
        );

        return showError();
      }

      const properties: Partial<PersonFormFields> = {
        password: this.innerValue,
      };

      this._managerService
        .updatePerson(properties, personHash)
        .then(response => {
          const status = response.getStatus();

          if (status === StatusCode.OK) {
            this.saveStatus = SaveState.SAVED;

            // Make sure it's not shown after saving
            this.togglePasswordVisibility(false);
            resolve(null);
          } else {
            console.warn(
              '[Password]savePassword() non OK response',
              response.toObject(),
            );

            showError();
          }
        });
    });
  }

  public writeValue(value: string): void {
    if (typeof value == 'string') {
      if (this.passwordControl.pristine && this.editMode) {
        if (this.initialUnset) {
          value = null;
        } else {
          value = this._initEditString;
        }
      }
      this.innerValue = value;

      this.passwordControl.setValue(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public change(value) {
    // update internal value
    this.innerValue = value;
    // TODO: fix change during render issue
    if (this.onChange) {
      this.onChange(value);
    }
  }

  public togglePasswordVisibility(visibility = !this.passwordVisible) {
    // if input disabled then no toggling visibility
    if (this.disabled) return;

    this.disableBlur = true;

    this.passwordVisible = visibility;
    if (this.passwordVisible) {
      this.passwordIcon = 'visibility_off';
      this.inputType = 'text';
    } else {
      this.passwordIcon = 'visibility';
      this.inputType = 'password';
    }

    // re-enable onBlur in an arbitrary short period of time
    setTimeout(() => {
      this.disableBlur = false;
    }, 300);
  }

  public initEditMode(firstFlag = true, value = this._initEditString) {
    this.saveStatus = SaveState.NA;

    if (!firstFlag) {
      // remove validators, they'll be added back when they click to edit
      this.passwordControl.clearValidators();
      this._formGroup.reset();
    }
    this.editing = false;
    this.innerValue = value;

    this.togglePasswordVisibility(false);
  }

  public onBlur(e) {
    this._hasFocus = false;
    if (!this.disableBlur) {
      this.blurEvent.emit(e);
    }

    // @TODO: remove this @HACK: to remove error coloring/state when first
    // clicking back after saving...
    if (!!this.passwordControl.errors) {
      this.formFieldElementRef._elementRef.nativeElement.classList.add(
        'mat-form-field-invalid',
      );
    }
  }

  public onFocus() {
    this._hasFocus = true;
    this.disableBlur = false;
    this.enableEditClick();
  }

  public enableEditClick() {
    if (!this.disabled && this.editMode && !this.editing) {
      this.initEditMode(false, '');
      this._setValidators();

      // enable show/hide eye
      this.editing = true;

      // tell editClick callback it was clicked
      if (typeof this.editClick == 'function') {
        this.editClick();
      }
    }
  }

  // Private Methods

  private _setValidators() {
    const validators = [];

    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.validation) {
      validators.push(Validators.minLength(8));
      validators.push(Validators.maxLength(16));
      validators.push(MgValidators.AtLeastOneLetterOneNumberOneSymbol);
    }

    this.passwordControl.setValidators(validators);
  }

  private _initOuterControl() {
    // Init outercontrol
    const outerControl = this._inj.get(NgControl);
    if (outerControl && outerControl.control) {
      this.outerControl = outerControl.control as FormControl;
    }
    // Check if the the outer formcontrol has errors and save them if we do
    this.outerControl.statusChanges.subscribe(() => {
      if (
        !!this.outerControl.errors &&
        !!this.passwordControl.errors === false
      ) {
        this.outerErrors = this.outerControl.errors;
        // clear the errors if this status update has none
      } else if (!this.outerControl.errors && this.outerErrors) {
        this.outerErrors = null;
      }
    });
  }
}
