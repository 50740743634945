"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagerType = void 0;
/**
 * @TODO: Delete file after new manager membership list is implemented.
 * Currently required for the deprecated Manager table.
 */
var ManagerType;
(function (ManagerType) {
    ManagerType["EVENT_MANAGER"] = "EVENT_MANAGER";
    ManagerType["ID_MANAGER"] = "ID_MANAGER";
})(ManagerType = exports.ManagerType || (exports.ManagerType = {}));