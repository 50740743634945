"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassTypesService = void 0;
const core_1 = require("@angular/core");
const grpc_web_1 = require("@improbable-eng/grpc-web");
const class_types_pb_service_1 = require("minga/proto/class_types/class_types_pb_service");
const i0 = require("@angular/core");
class ClassTypesService {
    constructor(_ngZone) {
        this._ngZone = _ngZone;
    }
    getClassTypes(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(class_types_pb_service_1.ClassTypesService.GetClassTypes, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getListTypeCounts(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(class_types_pb_service_1.ClassTypesService.GetListTypeCounts, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
}
exports.ClassTypesService = ClassTypesService;
ClassTypesService.ɵfac = function ClassTypesService_Factory(t) { return new (t || ClassTypesService)(i0.ɵɵinject(i0.NgZone)); };
ClassTypesService.ɵprov = i0.ɵɵdefineInjectable({ token: ClassTypesService, factory: ClassTypesService.ɵfac, providedIn: 'root' });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClassTypesService, [{
        type: core_1.Injectable,
        args: [{ providedIn: 'root' }]
    }], function () { return [{ type: i0.NgZone }]; }, null); })();