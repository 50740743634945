<mg-message-lightbox
  #imageLightBox
  [attachments]="activeMessageAttachments"
  [activeImageIndex]="lightboxIndex"></mg-message-lightbox>

<mg-overlay-primary
  class="chat-container"
  size="auto"
  [isLoading]="!messages"
  [overlayTitle]="overlayPreviousText$ | async"
  (closeModal)="onOverlayPrevious()">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="modalFooterTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Template -->
<ng-template #mainTemplate>
  <ng-container
    *ngIf="{
      current: isCurrentParticipant$ | async,
      disabled: messagingDisabled$ | async
    } as observables">
    <ng-container *ngIf="messages as messages; else loadingMessages">
      <virtual-scroller
        *ngIf="messages.length > 0; else noMessages"
        #virtualScroller
        [items]="messages"
        [enableUnequalChildrenSizes]="true"
        [modifyOverflowStyleOfParentScroll]="false"
        [scrollAnimationTime]="0"
        [bufferAmount]="5"
        [skipPrependScrollAdjust]="true"
        (vsUpdate)="onScrollerUpdate($event)"
        [parentScroll]="virtualScroller.window">
        <div
          #container
          class="items"
          [class.has-disabled-message]="
            observables.current &&
            hasTruePropertyValue(observables.disabled) &&
            !loadingParticipantStatus
          ">
          <ng-template
            ngFor
            let-item
            [ngForOf]="virtualScroller.viewPortItems"
            [ngForTrackBy]="trackByFn">
            <div class="item">
              <div class="message-timestamp">
                {{ item.message.timestampString }}
              </div>
              <ng-container
                *ngIf="
                  item.message.showAuthor ||
                  (routeData$ | async)?.showInlineAuthorDisplayNames
                ">
                <div
                  class="message-author"
                  [style.color]="item.color"
                  *ngIf="
                    item.message.authorPersonHash | mgPerson | async as person
                  ">
                  {{ person.displayName }}
                </div>
              </ng-container>
              <mg-dm-conversation-message
                [messageState]="getMessageState(item.message)"
                [personHash]="item.message.authorPersonHash"
                [markKeywords]="item.keywords"
                [attr.style]="getSafeStyle('--message-color', item.color)">
                <ng-container
                  *ngIf="
                    item.message.attachmentList?.length || item.message.body;
                    else processingAttachmentSpinner
                  ">
                  <div
                    [innerHTML]="
                      item.message.body
                        | mgEscapedHtml
                        | ugcLinksHighlight
                        | mgSafeHtml
                    "></div>
                  <div
                    *ngIf="item.message.attachmentList?.length"
                    class="attachment-container">
                    <ng-container
                      *ngFor="
                        let attachment of item.message.attachmentList;
                        let i = index
                      ">
                      <mg-image
                        *ngIf="!!attachment.image"
                        (click)="openLightbox(item.message, i)"
                        [srcs]="
                          attachment.image
                            | mgImageUrls: ['blurloading1', 'bannerlibpreview']
                        "
                        [style.width.px]="getMessageImageWidth(attachment)"
                        [style.height.px]="
                          getMessageImageHeight(attachment)
                        "></mg-image>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #processingAttachmentSpinner>
                  <mg-spinner></mg-spinner>
                </ng-template>
              </mg-dm-conversation-message>
            </div>
          </ng-template>
          <ng-container *ngTemplateOutlet="messagingDisabledTpl"></ng-container>
        </div>
      </virtual-scroller>

      <ng-template #noMessages>
        <ng-container *ngTemplateOutlet="messagingDisabledTpl"></ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Footer Template -->
<ng-template #modalFooterTemplate>
  <ng-container
    *ngIf="{
      current: isCurrentParticipant$ | async,
      disabled: messagingDisabled$ | async
    } as observables">
    <form
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="8px">
      <mg-person-avatar [noDisplayName]="true"></mg-person-avatar>
      <mat-form-field
        fxFlex="auto"
        mgFormField
        [mgNoHintMargin]="true"
        [mgNoBoldFontWeight]="true"
        [mgHideOptionalMarker]="true"
        [class.disabled]="hasTruePropertyValue(observables.disabled)">
        <input
          matInput
          mgAutofocus
          type="text"
          name="messageBody"
          maxlength="512"
          [placeholder]="inputPlaceholder$ | async"
          [disabled]="hasTruePropertyValue(observables.disabled)"
          (keydown)="onMessageInputKeypress($event)"
          [(ngModel)]="messageBodyInput"
          (ngModelChange)="onMessageInputChange($event)" />
        <span
          class="length-hint"
          *ngIf="messageBodyInput.trim()"
          matSuffix
          >{{ messageBodyInput.trim().length }} / 512</span
        >
      </mat-form-field>
      <mg-button
        *ngIf="messageBodyInput.trim()"
        type="outline"
        class="small"
        [disabled]="hasTruePropertyValue(observables.disabled)"
        (click)="onSend()"
        >Send</mg-button
      >
      <mg-file-input
        *ngIf="!messageBodyInput.trim()"
        [class.disabled]="hasTruePropertyValue(observables.disabled)"
        [displayStyle]="fileInputDisplayType"
        [(ngModel)]="pendingFiles"
        [multiple]="true"
        icon="mg-photo-camera"
        name="fileInput"
        customTitleText="Add File"
        (ngModelChange)="onFileChange($event)"></mg-file-input>
    </form>
  </ng-container>
</ng-template>

<!-- Loading Templates -->
<ng-template #loadingMessages>
  <div
    fxLayout="column"
    class="loading-messages-wrapper">
    <mg-spinner></mg-spinner>
  </div>
</ng-template>

<!-- Disabled Templates -->
<ng-template #messagingDisabledTpl>
  <ng-container
    *ngIf="{
      current: isCurrentParticipant$ | async,
      disabled: messagingDisabled$ | async
    } as observables">
    <ng-container *ngIf="observables.current && !loadingParticipantStatus">
      <ng-container *ngIf="observables.disabled as messagingDisabled">
        <mg-empty-state
          *ngIf="messagingDisabled.receiverMingaDisabled; else disabledByAdmin"
          title="You are not able to send messages to this user based on roles/permissions.">
        </mg-empty-state>

        <ng-template #disabledByAdmin>
          <mg-empty-state
            *ngIf="messagingDisabled.disabledByAdmin; else nonMingaDisabling"
            [title]="
              'Sorry, your direct messaging has been turned off by your minga administrator.'
            ">
          </mg-empty-state>
        </ng-template>

        <ng-template #nonMingaDisabling>
          <mg-empty-state
            *ngIf="
              messagingDisabled.currentUserPersonallyDisabled;
              else receiveDisabled
            "
            title="You have direct messaging turned off.">
          </mg-empty-state>
        </ng-template>

        <ng-template #receiveDisabled>
          <mg-empty-state
            *ngIf="messagingDisabled.receiverDisabled"
            [title]="
              messagingDisabled.receiverDisabledMessage ||
              'Sorry, but this user has DM turned off. You are not able to message them at this time.'
            ">
          </mg-empty-state>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
