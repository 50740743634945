import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { LayoutService } from '@modules/layout/services';

import { ProfilePersonInfo } from './user-profile-header.types';

@Component({
  selector: 'mg-user-profile-header',
  templateUrl: './user-profile-header.component.html',
  styleUrls: ['./user-profile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileHeaderComponent {
  private _personSubject = new BehaviorSubject<ProfilePersonInfo | undefined>(
    undefined,
  );
  public person$ = this._personSubject.asObservable();

  // Inputs

  @Input() profileInfo: ProfilePersonInfo;

  /** Component constructor */
  constructor(public layout: LayoutService) {}
}
