"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ISearchPersonDetailsProtoMapper = exports.ISearchPersonDataProtoMapper = exports.IReportPersonMapper = exports.IPeopleCollectionPersonDetailsProtoMapper = void 0;
const IPeopleCollectionPersonDetailsProtoMapperts = require("minga/libraries/shared-grpc/person/IPeopleCollectionPersonDetailsProtoMapper");
exports.IPeopleCollectionPersonDetailsProtoMapper = IPeopleCollectionPersonDetailsProtoMapperts;
const IReportPersonMapperts = require("minga/libraries/shared-grpc/person/IReportPersonMapper");
exports.IReportPersonMapper = IReportPersonMapperts;
const ISearchPersonDataProtoMapperts = require("minga/libraries/shared-grpc/person/ISearchPersonDataProtoMapper");
exports.ISearchPersonDataProtoMapper = ISearchPersonDataProtoMapperts;
const ISearchPersonDetailsProtoMapperts = require("minga/libraries/shared-grpc/person/ISearchPersonDetailsProtoMapper");
exports.ISearchPersonDetailsProtoMapper = ISearchPersonDetailsProtoMapperts;