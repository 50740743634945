"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassTypesServiceClient = exports.ClassTypesService = void 0;
const class_types_class_types_pb = require("minga/proto/class_types/class_types_pb");
class ClassTypesService {
}
exports.ClassTypesService = ClassTypesService;
ClassTypesService.serviceName = "ClassTypesService";
ClassTypesService.GetClassTypes = {
    methodName: "GetClassTypes",
    service: ClassTypesService,
    requestStream: false,
    responseStream: false,
    requestType: class_types_class_types_pb.GetClassTypesRequest,
    responseType: class_types_class_types_pb.GetClassTypesResponse,
};
ClassTypesService.GetListTypeCounts = {
    methodName: "GetListTypeCounts",
    service: ClassTypesService,
    requestStream: false,
    responseStream: false,
    requestType: class_types_class_types_pb.GetListTypeCountsRequest,
    responseType: class_types_class_types_pb.GetListTypeCountsResponse,
};
class ClassTypesServiceClient {
    getClassTypes() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    getListTypeCounts() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
}
exports.ClassTypesServiceClient = ClassTypesServiceClient;