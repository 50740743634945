import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Chart, ChartDataset, ChartOptions, TimeScale } from 'chart.js';
import 'chartjs-adapter-dayjs';
import { Dayjs } from 'dayjs';
import {
  BaseChartDirective,
  PluginServiceGlobalRegistrationAndOptions,
} from 'ng2-charts';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { getGroupRangeByDate } from 'minga/libraries/domain';

import { BAR_CHART_OPTIONS } from './bar-chart.constants';

Chart.register(TimeScale);
/**
 * Bar Chart
 *
 * Generic Bar Chart powered by Chart.js
 *
 * @link https://www.npmjs.com/package/ng2-charts
 * @link https://www.chartjs.org/docs/2.9.4/
 */
@Component({
  selector: 'mg-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements OnDestroy, OnInit, OnChanges {
  /** Element Refs */
  @ViewChild(BaseChartDirective)
  chartCanvas: BaseChartDirective | undefined;

  /** General Observables */
  private readonly _onDestroy = new ReplaySubject(1);
  private _chartOptions = new BehaviorSubject<ChartOptions>(BAR_CHART_OPTIONS);
  public readonly chartOptions$ = this._chartOptions.asObservable();

  /** Chart Labels */
  private readonly _chartLabels = new BehaviorSubject<string[]>([]);
  public readonly chartLabels$ = this._chartLabels.asObservable();

  /** Inputs */
  @Input() data: ChartDataset[];
  @Input() plugins: PluginServiceGlobalRegistrationAndOptions[];
  @Input() legend: boolean;
  @Input() minDate: Dayjs;
  @Input() maxDate: Dayjs;

  /** Component Constructor */
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateChartOptions();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getChartOptions() {
    return this._chartOptions.getValue();
  }

  setChartOptions(options: ChartOptions) {
    this._chartOptions.next(options);
  }

  updateChartOptions() {
    const chartOptions = Object.assign({}, this.getChartOptions());

    const groupBy = getGroupRangeByDate(this.minDate, this.maxDate);

    const xAxisOptions: any = chartOptions.scales.x;
    const singleDay = this.maxDate.diff(this.minDate, 'day') === 0;
    const chartMin = this.minDate.format('YYYY-MM-DD');
    const chartMax = this.maxDate.format('YYYY-MM-DD');
    if (xAxisOptions) {
      xAxisOptions.type = 'time';
      xAxisOptions.min = !singleDay ? chartMin : undefined;
      xAxisOptions.max = !singleDay ? chartMax : undefined;
      xAxisOptions.time.unit = groupBy === 'date' ? 'day' : groupBy;
    }

    chartOptions.scales.x = xAxisOptions;
    this.setChartOptions(chartOptions);
  }
}
