"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OVERDUE = exports.HISTORY_BELL_SCHEDULE = exports.HISTORY = exports.TYPES = exports.STAFF = exports.STUDENTS = void 0;
const domain_1 = require("minga/libraries/domain/index");
const utils_1 = require("minga/libraries/shared/reports_columns/utils/index");
const columns_constants_1 = require("minga/libraries/shared/reports_columns/constants/columns.constants");
const common_constants_1 = require("minga/libraries/shared/reports_columns/constants/common.constants");
const numPassCol = {
    header: 'Assigned',
    key: 'totalPasses',
    accessorFn: (item) => {
        var _a, _b;
        return ((item === null || item === void 0 ? void 0 : item.totalPasses) || ((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.totalPasses) || ((_b = item === null || item === void 0 ? void 0 : item.expiredStats) === null || _b === void 0 ? void 0 : _b.noOfExpiredPasses) ||
            '0');
    },
    sort: true,
    type: 'number',
    width: 'small',
};
const numDeniedPassCol = {
    header: 'Denied',
    key: 'totalDenied',
    accessorFn: (item) => {
        var _a;
        return (item === null || item === void 0 ? void 0 : item.totalDenied) || ((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.totalDenied) || '0';
    },
    type: 'number',
    width: 'small',
    sort: true,
};
const numOverriddenPassCol = {
    header: 'Overridden',
    key: 'totalOverridden',
    accessorFn: (item) => {
        var _a;
        return (item === null || item === void 0 ? void 0 : item.totalOverridden) || ((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.totalOverridden) || '0';
    },
    type: 'number',
    width: 'medium',
    sort: true,
};
const totalTimeCol = {
    header: 'Total Time',
    key: 'totalTime',
    accessorFn: utils_1.totalTimeAccessorFn,
    sort: true,
    type: 'number',
    width: 'medium',
};
const hallPassNameCol = {
    header: 'Pass Type',
    key: 'hallPassName',
    tooltip: true,
    accessorFn: (item, colour) => {
        var _a, _b;
        if (colour)
            return (item === null || item === void 0 ? void 0 : item.typeColor) || ((_a = item === null || item === void 0 ? void 0 : item.hallPassType) === null || _a === void 0 ? void 0 : _a.color) || '';
        return (item === null || item === void 0 ? void 0 : item.typeName) || ((_b = item === null || item === void 0 ? void 0 : item.hallPassType) === null || _b === void 0 ? void 0 : _b.name) || '';
    },
    type: 'text',
    width: 'flex-medium',
};
exports.STUDENTS = [
    Object.assign(Object.assign({}, columns_constants_1.firstName), { accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'first') }),
    Object.assign(Object.assign({}, columns_constants_1.lastName), { accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'last') }),
    columns_constants_1.studentId,
    columns_constants_1.grade,
    columns_constants_1.role,
    numPassCol,
    numDeniedPassCol,
    totalTimeCol,
];
exports.STAFF = [
    Object.assign(Object.assign({}, columns_constants_1.firstName), { accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'first'), sort: false }),
    Object.assign(Object.assign({}, columns_constants_1.lastName), { accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'last'), sort: false }),
    columns_constants_1.studentId,
    columns_constants_1.role,
    numPassCol,
    numDeniedPassCol,
    numOverriddenPassCol,
    totalTimeCol,
];
exports.TYPES = [
    hallPassNameCol,
    numPassCol,
    numDeniedPassCol,
    totalTimeCol,
];
exports.HISTORY = [
    {
        header: 'Status',
        key: 'status',
        type: 'tag',
        width: 'small',
    },
    hallPassNameCol,
    columns_constants_1.assignedTo,
    columns_constants_1.assignedToLast,
    columns_constants_1.studentId,
    {
        header: 'Created by',
        key: 'createdBy',
        tooltip: true,
        type: 'text',
        width: 'flex-medium',
    },
    {
        header: 'Approved by',
        key: 'hpApprovedBy',
        tooltip: true,
        accessorFn: (item) => {
            if (item.status === domain_1.HallPassStatusEnum.APPROVED ||
                item.status === domain_1.HallPassStatusEnum.OVERRIDDEN) {
                return item.authorPersonViewMinimal
                    ? utils_1.nameFinder(item.authorPersonViewMinimal)
                    : '';
            }
            else {
                return '';
            }
        },
        type: 'text',
        width: 'flex-medium',
    },
    {
        header: 'Denied by',
        key: 'deniedBy',
        tooltip: true,
        type: 'text',
        width: 'flex-medium',
        accessorFn: (item) => {
            if (item.deniedByPerson) {
                return item.deniedByPerson;
            }
            else if (item.deniedByError) {
                return domain_1.hallPassErrorToDeniedBy(item.deniedByError);
            }
        },
    },
    {
        header: 'Denied Reason',
        key: 'deniedByReason',
        type: 'text',
        width: 'flex-medium',
    },
    {
        header: 'Ended by',
        key: 'hpEndedBy',
        tooltip: true,
        accessorFn: (item) => {
            var _a;
            if (item.endedByPersonViewMinimal) {
                return utils_1.nameFinder(item.endedByPersonViewMinimal);
            }
            else if ((_a = item.hallPassType) === null || _a === void 0 ? void 0 : _a.manuallyEndPass) {
                return undefined;
            }
            return 'Automatic';
        },
        type: 'text',
        width: 'flex-medium',
    },
    columns_constants_1.noteAndRequesterNote,
    {
        header: 'Date',
        key: common_constants_1.DateColumnKeys.START_DATE,
        tooltip: true,
        sort: true,
        accessorFn: (item) => item.startDate,
        type: 'date',
        width: 'medium',
    },
    {
        header: 'Time',
        key: common_constants_1.TimeColumnKeys.START_TIME,
        accessorFn: (item) => item.startDate,
        type: 'date',
        width: 'small',
    },
    {
        header: 'End Date',
        key: common_constants_1.DateColumnKeys.END_DATE,
        tooltip: true,
        sort: true,
        accessorFn: (item) => item.endDate,
        type: 'date',
        width: 'small',
    },
    {
        header: 'End Time',
        key: common_constants_1.TimeColumnKeys.END_TIME,
        accessorFn: (item) => item.endDate,
        type: 'date',
        width: 'medium',
    },
    {
        header: 'Last Modified',
        key: common_constants_1.DateColumnKeys.MANUALLY_UPDATED_AT,
        type: 'date',
        width: 'medium',
    },
];
exports.HISTORY_BELL_SCHEDULE = [
    ...exports.HISTORY.slice(0, 5),
    columns_constants_1.currentClass,
    ...exports.HISTORY.slice(5),
];
exports.OVERDUE = [
    Object.assign(Object.assign({}, columns_constants_1.firstName), { accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'first') }),
    Object.assign(Object.assign({}, columns_constants_1.lastName), { accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'last') }),
    columns_constants_1.studentId,
    columns_constants_1.grade,
    columns_constants_1.role,
    numPassCol,
    {
        header: 'Total Overdue Time',
        key: 'totalExpiredTime',
        accessorFn: utils_1.totalTimeAccessorFn,
        sort: true,
        type: 'date',
        width: 'large',
    },
];