import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { Person } from 'minga/app/src/app/people';

import { FormSelectComponent } from '@shared/components/form';
import { FormTextInputComponent } from '@shared/components/form/components/form-text-input/form-text-input.component';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';
import { UserListFilterComponent } from '@shared/components/user-list-filter';

import { FiltersFormPopoverMessage } from '../../constants';
import { FiltersFormData } from '../../types';

@Component({
  selector: 'mg-filters-form-popover',
  templateUrl: './filters-form-popover.component.html',
  styleUrls: ['./filters-form-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormPopoverComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Children

  @ViewChild(MultiPersonSearchComponent)
  multiPersonSearchComponent: MultiPersonSearchComponent;
  @ViewChild(UserListFilterComponent)
  userListFilterComponent: UserListFilterComponent;
  @ViewChildren('formSingleSelectComponent')
  formSingleSelectComponent: QueryList<FormSelectComponent>;
  @ViewChildren('formSelectComponent')
  formSelectComponent: QueryList<FormSelectComponent>;
  @ViewChildren('formTextInputComponent')
  formTextInputComponent: QueryList<FormTextInputComponent>;

  // Constants
  public readonly MESSAGE = FiltersFormPopoverMessage;

  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  private readonly _draftFormDataSubject = new BehaviorSubject<any>(null);
  public readonly draftFormData$ = this._draftFormDataSubject.asObservable();

  // Inputs
  @Input() data: FiltersFormData;
  @Input() formGroup: FormGroup;
  @Input() fields: string[];
  @Input() focusFieldOnInit: string;

  // Outputs
  @Output() closePopover = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  // Keyboard Events

  @HostListener('document:keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.closePopover.emit();
    }
  }

  // Component Constructor

  constructor() {}

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this._destroyedSubject), startWith(this.formGroup.value))
      .subscribe(val => {
        const draftFormData = this._draftFormDataSubject.getValue();
        this._draftFormDataSubject.next({ ...draftFormData, ...val });
      });
  }

  ngAfterViewInit(): void {
    this._focusFieldOnInit(this.focusFieldOnInit || this.fields[0]);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.resetForm.complete();
    this.closePopover.complete();
  }

  public clear() {
    this.formSelectComponent.toArray().forEach(component => {
      component.setInitialValue(null);
    });
    this.formSingleSelectComponent.toArray().forEach(component => {
      component.setInitialValue(null);
    });
    this.resetForm.emit();
    this.closePopover.emit();
  }

  public submit() {
    const draftFormData = this._draftFormDataSubject.getValue();
    this.formGroup.patchValue(draftFormData);
    this.closePopover.emit();
  }

  public setSelect(field: string, value: any) {
    this._setDraftFormData(field, value);
  }

  public setToggleGroupValue(field: string, { value }: MatButtonToggleChange) {
    this._setDraftFormData(field, value);
  }

  public setUserList(field: string, value: any) {
    this._setDraftFormData(field, value);
  }

  public setPerson(field: string, value: Partial<Person>[]) {
    this._setDraftFormData(field, value);
  }

  private _setDraftFormData(field: string, value: any) {
    const draftFormData = this._draftFormDataSubject.getValue();
    this._draftFormDataSubject.next({ ...draftFormData, [field]: value });
  }

  public getPersonHashes(value?: Partial<Person>[] | string[]): string[] {
    const hashes: string[] = [];
    if (!value) return hashes;
    for (const v of value) {
      // optimistic assumption, but we really shouldn't be mixing types
      if (typeof v === 'string') return value as string[];
      else hashes.push(v.hash);
    }
    return hashes;
  }

  private _focusFieldOnInit(fieldName: string) {
    let fieldObject = this.data[fieldName];
    if (typeof fieldObject === 'function') {
      fieldObject = fieldObject(this._draftFormDataSubject.getValue(), true);
    }
    const { type } = fieldObject;
    // magic timeout fix
    setTimeout(() => {
      switch (type) {
        case 'people-search':
          this.multiPersonSearchComponent.focus();
          break;
        case 'text':
          this.formTextInputComponent
            .find(component => component.name === fieldName)
            ?.focus();
          break;
        case 'toggle-group':
          break;
        case 'user-list':
          this.userListFilterComponent.focus();
          break;
        // For ng-select based components
        case 'single-select':
          this.formSingleSelectComponent
            .find(component => component.name === fieldName)
            ?.focus();
          break;
        case 'multi-select':
          this.formSelectComponent
            .find(component => component.name === fieldName)
            ?.focus();
          break;
        default: {
          break;
        }
      }
    }, 50);
  }
}
