import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { isEmpty, xor } from 'lodash';

@Pipe({ name: 'checkChipVisibility' })
export class FiltersFormCheckChipVisibilityPipe implements PipeTransform {
  transform({ value }: KeyValue<string, any>, data: any) {
    // design nevers wants us to show chips for toggle groups
    if (data?.type === 'toggle-group') return false;

    const valueIsArray = Array.isArray(value);
    if (valueIsArray) {
      return !isEmpty(xor(value, data?.default ?? []));
    } else {
      if (data.default) return value !== data.default;
      return value !== null && value !== undefined && value !== '';
    }
  }
}
