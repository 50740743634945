"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailedModerationResultMapper = void 0;
const moderation_pb_1 = require("minga/proto/gateway/moderation_pb");
const DetailedModerationResultWrapper_1 = require("minga/libraries/shared-grpc/moderation/DetailedModerationResultWrapper");
var DetailedModerationResultMapper;
(function (DetailedModerationResultMapper) {
    function fromIModerationProviderDetailsToProviderDetails(details) {
        const message = new moderation_pb_1.DetailedModerationResult.ProviderDetails();
        details.tags.forEach(tag => {
            const pair = new moderation_pb_1.DetailedModerationResult.Pair();
            pair.setKey(tag.key);
            pair.setValue(tag.value);
            message.addTags(pair);
        });
        message.setFileurl(details.fileUrl);
        message.setIndex(details.index);
        details.imageTags.forEach(tag => {
            const pair = new moderation_pb_1.DetailedModerationResult.Pair();
            pair.setKey(tag.key);
            pair.setValue(tag.value);
            message.addImageTags(pair);
        });
        if (typeof details.reasonIndex != 'undefined') {
            message.setReasonIndex(details.reasonIndex);
        }
        return message;
    }
    DetailedModerationResultMapper.fromIModerationProviderDetailsToProviderDetails = fromIModerationProviderDetailsToProviderDetails;
    function fromIModerationHistoryToContentHistory(history) {
        const message = new moderation_pb_1.DetailedModerationResult.ContentHistory();
        if (history.personId) {
            message.setPersonid(history.personId);
        }
        message.setTimestamp(history.timestamp);
        message.setPersonDisplayName(history.personDisplayName);
        message.setReasonindex(history.reason);
        message.setStatus(history.status);
        return message;
    }
    DetailedModerationResultMapper.fromIModerationHistoryToContentHistory = fromIModerationHistoryToContentHistory;
    /**
     * Convert IModeration to a protobuf DetailedModerationResult message
     *
     * @param moderation IModeration
     */
    function fromIModeration(moderation) {
        const message = new moderation_pb_1.DetailedModerationResult();
        message.setTitle(moderation.title);
        message.setParentContextHash(moderation.parentContextHash);
        message.setContentContextHash(moderation.contentContextHash);
        message.setMingaHash(moderation.mingaHash);
        message.setMingaName(moderation.mingaName);
        message.setContentAuthorHash(moderation.contentAuthorHash);
        message.setTimestamp(moderation.timestamp);
        message.setContentAuthorFirstName(moderation.contentAuthorFirstName);
        message.setContentAuthorLastName(moderation.contentAuthorLastName);
        message.setContentType(moderation.type);
        moderation.contentHistory.forEach(history => {
            message.addContentHistory(fromIModerationHistoryToContentHistory(history));
        });
        message.setStatus(moderation.status);
        message.setActionContextHash(moderation.actionContextHash);
        message.setGalleryPhotoUuid(moderation.galleryPhotoUuid);
        return message;
    }
    DetailedModerationResultMapper.fromIModeration = fromIModeration;
    function toIModeration(result) {
        return new DetailedModerationResultWrapper_1.DetailedModerationResultWrapper(result);
    }
    DetailedModerationResultMapper.toIModeration = toIModeration;
})(DetailedModerationResultMapper = exports.DetailedModerationResultMapper || (exports.DetailedModerationResultMapper = {}));