import { Injectable } from '@angular/core';

import { from, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { UserStorage } from 'minga/app/src/app/services/UserStorage';
import { MingaStoreFacadeService } from 'minga/app/src/app/store/Minga/services';
import { dateTimeObjectToDateTimeMessage } from 'minga/app/src/app/util/date';
import {
  IMgStreamFilter,
  IMgStreamItem,
  toStreamFilterMessage,
} from 'minga/app/src/app/util/stream';
import {
  IHallPass,
  IHallPassType,
  IHallPassTypeCount,
  IHallPassValidationError,
} from 'minga/libraries/domain';
import { HallPassBlackOutWeekdaysEnum } from 'minga/libraries/domain';
import { IHallPassBlackOut } from 'minga/libraries/domain';
import { IMembershipList, MembershipListType } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { HallPassTypeMapper } from 'minga/libraries/shared-grpc';
import {
  HallPassBlackOutMapper,
  HallPassMapper,
} from 'minga/libraries/shared-grpc';
import { MembershipListMapper } from 'minga/libraries/shared-grpc';
import { mingaSettingTypes } from 'minga/libraries/util';
import { StreamItemMetadata } from 'minga/proto/common/stream_pb';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';
import { HallPassManager } from 'minga/proto/hall_pass/hall_pass_ng_grpc_pb';
import {
  AddDefaultHallPassTypesRequest,
  AddDefaultHallPassTypesResponse,
  ApproveHallPassRequest,
  CancelHallPassRequest,
  CheckHallPassValidityRequest,
  CreateHallPassRequest,
  DeleteHallPassBlackOutWindowRequest,
  DeleteHallPassTypeRequest,
  ExpireHallPassRequest,
  GetHallPassBlackOutWindowsRequest,
  GetHallPassesForRecipientRequest,
  GetHallPassesRequest,
  GetMembershipListsRequest,
  GetMembershipListsResponse,
  GetMingaHallPassTypeRequest,
  GetMingaHallPassTypesCountsRequest,
  GetMingaHallPassTypesRequest,
  HallPassWithType,
  StartHallPassRequest,
  UpdateHallPassBlackOutWindowRequest,
  UpdateHallPassTypeRequest,
} from 'minga/proto/hall_pass/hall_pass_pb';
import { StudentIdManager } from 'minga/proto/student/student_id_ng_grpc_pb';
import { PermissionsService } from 'src/app/permissions';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface IHallPassSettings {
  /* enable hall passes */
  passEnablePasses: boolean;
  /* can teachers grant hall passes */
  passGrantTeachers: boolean;
  /* can staff grant hall passes */
  passGrantStaff: boolean;
  /* can students self grant hall passes */
  passGrantStudents: boolean;
  /* show active hall passes on student id */
  passEnableStudentPasses: boolean;
  /* max hall passes per day per student */
  passMaxPerDay: number;
  /* max active hall passes allowed at one time */
  passMaxActiveAtOnce: number;
  /* max hall passes per day per student for self granted passes */
  passMaxPerDaySelfGranted: number;
  /* max active hall passes allowed at one time for self granted passes */
  passMaxActiveAtOnceSelfGranted: number;
  /* self granted pass duration */
  passDurationSelfGranted: number;
  /* self granted passes should assign a staff member */
  passStudentsAssignStaff: boolean;
  /* student created hall pass input settings */
  passMinTimeBeforeNextStudentCreatedPass: number;
  /* can teachers and staff bypass blackout windows */
  passCanStaffBypassBlackoutWindows: boolean;
}

export enum IHallPassMembershipListType {
  NO_PARTY = MembershipListType.NO_PARTY,
  NO_PASS = MembershipListType.NO_PASS,
  NO_GRANT = MembershipListType.NO_GRANT,
  ONE_PASS = MembershipListType.ONE_PASS,
  TWO_PASS = MembershipListType.TWO_PASS,
}

/**
 * Handle CRUD operations for Hall Passes.
 *
 * @deprecated do not edit or use this file.
 */
@Injectable({ providedIn: 'root' })
export class HallPassService {
  private _tempHallPassRecipeints: string[] = [];

  passSettings$: Observable<IHallPassSettings>;

  private _hallPassEndSubject = new Subject<number>();
  public readonly hallPassDidEnd$ = this._hallPassEndSubject.asObservable();

  constructor(
    private _hallPassManager: HallPassManager,
    private _localStorage: UserStorage,
    private _authInfoService: AuthInfoService,
    private _mingaStore: MingaStoreFacadeService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _studentIdManager: StudentIdManager,
    private _listService: ListMembershipService,
    private _permissionService: PermissionsService,
  ) {
    this.passSettings$ = this._mingaStore.getMingaAsObservable().pipe(
      map(mingaInfo => {
        const hallPassSettings: IHallPassSettings = {
          passEnablePasses: false,
          passGrantTeachers: false,
          passGrantStaff: false,
          passGrantStudents: false,
          passEnableStudentPasses: false,
          passMaxPerDay: 8,
          passMaxActiveAtOnce: 50,
          passMaxPerDaySelfGranted: 8,
          passMaxActiveAtOnceSelfGranted: 50,
          passDurationSelfGranted: 5,
          passStudentsAssignStaff: false,
          passMinTimeBeforeNextStudentCreatedPass: 0,
          passCanStaffBypassBlackoutWindows: false,
        };

        if (mingaInfo.settings) {
          for (const setting of mingaInfo.settings) {
            switch (setting.name) {
              case mingaSettingTypes.PASS_TEACHERS_GRANT: {
                hallPassSettings.passGrantTeachers = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_STAFF_GRANT: {
                hallPassSettings.passGrantStaff = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_STUDENTS_GRANT: {
                hallPassSettings.passGrantStudents = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_MAX_PASSES: {
                hallPassSettings.passMaxPerDay = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_MAX_ACTIVE_PASSES: {
                hallPassSettings.passMaxActiveAtOnce = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_MAX_STUDENT_PASSES: {
                hallPassSettings.passMaxPerDaySelfGranted = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_MAX_ACTIVE_STUDENT_PASSES: {
                hallPassSettings.passMaxActiveAtOnceSelfGranted = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_DURATION_STUDENT_PASSES: {
                hallPassSettings.passDurationSelfGranted = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_ASSIGN_STAFF: {
                hallPassSettings.passStudentsAssignStaff = setting.value;
                break;
              }
              case mingaSettingTypes.PASS_MIN_WAIT_STUDENT_PASS: {
                hallPassSettings.passMinTimeBeforeNextStudentCreatedPass =
                  setting.value;
                break;
              }
              case mingaSettingTypes.PASS_STAFF_BLACKOUT_BYPASS: {
                hallPassSettings.passCanStaffBypassBlackoutWindows =
                  setting.value;
                break;
              }
            }
          }
        }

        return hallPassSettings;
      }),
    );
  }

  async createHallPass(
    hallPassTypeId: number,
    personHashIssuedToList: string[],
    duration: number,
    opts?: {
      startDate?: Date;
      teacherHash?: string;
      note?: string;
      ignoreRestrictions?: boolean;
      createdViaKiosk?: boolean;
      listId?: number;
    },
  ): Promise<{
    errors: IHallPassValidationError[];
    passIds: number[];
    passes: HallPassWithType.AsObject[];
  }> {
    const { startDate, teacherHash, note, ignoreRestrictions } = opts || {};
    try {
      const request = new CreateHallPassRequest();
      request.setHallPassTypeId(hallPassTypeId);
      request.setPersonHashIssuedToList(personHashIssuedToList);
      // if teacherHash exists, then it's student created
      if (teacherHash) {
        request.setTeacherHash(teacherHash);
      }
      if (startDate) {
        request.setStartDateTime(dateTimeObjectToDateTimeMessage(startDate));
      }
      if (note) {
        request.setNote(note);
      }
      if (ignoreRestrictions) {
        request.setIgnoreRestrictions(ignoreRestrictions);
      }
      request.setHallPassDuration(duration);
      request.setIsKiosk(opts?.createdViaKiosk ?? false);
      request.setListId(opts?.listId ?? 0);

      const result = await this._hallPassManager.createHallPass(request);
      const list = result.getErrorsList();
      const passIds = result.getHallPassIdsList();
      const uniqueList = {};
      /* considering a single person can bring up multiple errors, I'm going to
       * use their hash + error code as the key for uniqueness */
      list.forEach(msg => {
        if (!uniqueList[msg.getPersonHash() + ':' + msg.getErrorCode()]) {
          /* if the key doesn't exist, add it */
          uniqueList[msg.getPersonHash() + ':' + msg.getErrorCode()] = {
            error: msg.getErrorCode(),
            personHash: msg.getPersonHash(),
            name: msg.getName(),
            maxPasses: msg.getMaxPasses(),
            hallPassName: msg.getHallPassName(),
          };
        }
      });
      const passes = result.getHallPassesList().map(res => res.toObject());
      /* convert the unique list to an array of the unique error objects */
      return {
        errors: Object.values(uniqueList),
        passIds,
        passes,
      };
    } catch (error) {
      this._systemAlertSnackBar.error(error);
    }
  }

  async expireHallPass(
    hallPassId: number,
    expireDate: Date = new Date(),
    endedByHash?: string,
  ) {
    const request = new ExpireHallPassRequest();
    request.setHallPassId(hallPassId);

    if (endedByHash) {
      request.setEndedByHash(endedByHash);
    }

    const expireDateTime = dateTimeObjectToDateTimeMessage(expireDate);
    request.setExpiredDateTime(expireDateTime);
    try {
      const result = await this._hallPassManager.expireHallPass(request);
      this._hallPassEndSubject.next(hallPassId);
      return result;
    } catch (e) {
      this._systemAlertSnackBar.error('Sorry, could not end hall pass');
      return;
    }
  }

  async startHallPass(hallPassId: number) {
    const request = new StartHallPassRequest();
    request.setHallPassId(hallPassId);

    return await this._hallPassManager.startHallPass(request);
  }

  async cancelHallPass(
    hallPassId: number,
    noteToRequester?: string,
    denying = false,
  ) {
    const request = new CancelHallPassRequest();
    request.setHallPassId(hallPassId);

    if (noteToRequester) {
      request.setNoteToRequester(noteToRequester);
    }

    request.setDenying(denying);

    return await this._hallPassManager.cancelHallPass(request);
  }

  async approveHallPass(hallPassId: number, noteToRequester?: string) {
    const request = new ApproveHallPassRequest();
    request.setHallPassId(hallPassId);

    if (noteToRequester) {
      request.setNoteToRequester(noteToRequester);
    }

    return await this._hallPassManager.approveHallPass(request);
  }

  storeTempHallPassRecipients(peopleHashes: string[]) {
    this._tempHallPassRecipeints = peopleHashes;
  }

  addToStoredTempHallPassRecipients(peopleHashes: string[]) {
    this._tempHallPassRecipeints.push(...peopleHashes);
  }

  consumeTempHallPassRecipients(): string[] {
    const currentRecipeints = this._tempHallPassRecipeints;
    // reset
    this._tempHallPassRecipeints = [];

    return currentRecipeints;
  }

  async getPersonsInNoPassList(
    peopleHashes: string[],
  ): Promise<PersonViewMinimal.AsObject[]> {
    const noPassListMembers =
      await this._getCurrentMingaHallPassListTypeMembers(
        IHallPassMembershipListType.NO_PASS,
      );
    const personsNotAllowedPass = noPassListMembers.filter(personMinimal => {
      return peopleHashes.includes(personMinimal.personHash);
    });

    return personsNotAllowedPass;
  }

  canUserGrantHallPasses(): Observable<boolean> {
    return this._permissionService.observePermission(
      MingaPermission.HALL_PASS_CREATE,
    );
  }

  async updateHallPassType(
    hallPassType: IHallPassType,
  ): Promise<IHallPassType> {
    const request = new UpdateHallPassTypeRequest();
    const hallPassProto = HallPassTypeMapper.toProto(hallPassType);
    request.setHallPassType(hallPassProto);

    const response = await this._hallPassManager.updateHallPassType(request);
    const hallPassTypeProto = response.getHallPassType();
    return HallPassTypeMapper.fromProto(hallPassTypeProto);
  }

  getHallPassTypes(getActiveOnly?: boolean): Observable<IHallPassType[]> {
    const request = new GetMingaHallPassTypesRequest();
    if (getActiveOnly) {
      request.setGetActiveOnly(getActiveOnly);
    }

    const _handleGettingHallPassTypes = async () => {
      const reponse = await this._hallPassManager.getMingaHallPassTypes(
        request,
      );
      const typesProto = reponse.getHallPassTypeList();

      return typesProto.map(HPProto => HallPassTypeMapper.fromProto(HPProto));
    };

    return from(_handleGettingHallPassTypes());
  }

  /**
   * Get Hall Pass Type By Id
   *
   * Returns a single Hall Pass Type filtered by ID provided. This returns as
   * an observable!
   */
  getHallPassTypeById(id: number): Promise<IHallPassType> {
    const req = new GetMingaHallPassTypeRequest();
    req.setHallPassTypeId(id);
    return this._handleGetHallPassTypeById(req);
  }

  /**
   * Handle Get Hall Pass Type By Id
   *
   * ASYNC FETCH REQUEST
   */
  private async _handleGetHallPassTypeById(
    req: GetMingaHallPassTypeRequest,
  ): Promise<IHallPassType> {
    const res = await this._hallPassManager.getMingaHallPassType(req);
    const hallPassType = res.getHallPassType();
    return HallPassTypeMapper.fromProto(hallPassType);
  }

  async deleteHallPassType(passTypeId: number) {
    const request = new DeleteHallPassTypeRequest();
    request.setTypeId(passTypeId);

    await this._hallPassManager.deleteHallPassType(request);
  }

  /**
   * Add default hall pass types to the specificed Minga if it doesn't already
   * have exisiting all pass types.
   *
   * @param mingaId
   */
  async addDefaultHallPassTypesToMinga(
    mingaHash: string,
  ): Promise<string | undefined> {
    const request = new AddDefaultHallPassTypesRequest();
    request.setMingaHash(mingaHash);

    const response: AddDefaultHallPassTypesResponse =
      await this._hallPassManager.addDefaultHallPassTypesToMinga(request);

    const errorMessage = response.getErrorMessage();

    return errorMessage;
  }

  getHallPassTypesCountsActive(): Observable<IHallPassTypeCount[]> {
    const request = new GetMingaHallPassTypesCountsRequest();
    request.setActive(true);

    return this._getHallPassTypesCounts(request);
  }

  getHallPassTypesCountsExpired(): Observable<IHallPassTypeCount[]> {
    const request = new GetMingaHallPassTypesCountsRequest();
    request.setExpired(true);

    return this._getHallPassTypesCounts(request);
  }

  getHallPassTypesCountsScheduled(): Observable<IHallPassTypeCount[]> {
    const request = new GetMingaHallPassTypesCountsRequest();
    request.setScheduled(true);

    return this._getHallPassTypesCounts(request);
  }

  private _getHallPassTypesCounts(
    request: GetMingaHallPassTypesCountsRequest,
  ): Observable<IHallPassTypeCount[]> {
    const _handleGettingHallPassTypesCounts = async () => {
      const response = await this._hallPassManager.getMingaHallPassTypesCounts(
        request,
      );
      const typesProto = response.getHallPassTypeCountList();
      return typesProto.map(typeProto => {
        const hallPassType = HallPassTypeMapper.fromProto(
          typeProto.getHallPassType(),
        );

        return {
          hallPassType,
          count: typeProto.getCount(),
          priority: hallPassType.priority,
        };
      });
    };

    return from(_handleGettingHallPassTypesCounts());
  }

  async createNewHallPassTypeWithDefaults(): Promise<IHallPassType> {
    const defaultHallPassType: IHallPassType = {
      name: '',
      color: '#1C2F59',
    };

    return await this.updateHallPassType(defaultHallPassType);
  }

  async getHallPassesForRecipient(personHash?: string): Promise<IHallPass[]> {
    const request = new GetHallPassesForRecipientRequest();

    // use current person hash if a specific person hash is not provided
    const recipientHash = personHash
      ? personHash
      : this._authInfoService.authInfo?.person?.personHash;
    request.setRecipientPersonHash(recipientHash);

    const result = await this._hallPassManager.getHallPassesForRecipient(
      request,
    );
    const hallPassList = result.getHallPassList();
    const mappedHallPassList: IHallPass[] = hallPassList.map(
      HallPassMapper.fromProto,
    );

    return mappedHallPassList;
  }

  async getHallPassMembershipLists(): Promise<IMembershipList[]> {
    const request = new GetMembershipListsRequest();
    const response: GetMembershipListsResponse =
      await this._hallPassManager.getMembershipLists(request);
    const protoLists = response.getListsList();

    return protoLists.map(MembershipListMapper.fromProto);
  }

  /**
   * Create membership lists for hall pass management use
   *
   * @param name
   * @param listType,
   * @returns
   */
  async createHallPassList(
    name: string,
    listType: IHallPassMembershipListType,
  ): Promise<IMembershipList> {
    const listDefaults: IMembershipList = {
      name,
      listType: MembershipListType[listType],
    };
    return await this._listService.updateMembershipList(listDefaults);
  }

  async checkHallPassValidity(
    peopleHashes: string[],
    hallPassTypeId?: number,
    startDate?: Date,
  ): Promise<IHallPassValidationError[]> {
    const message = new CheckHallPassValidityRequest();
    message.setPeopleHashesList(peopleHashes);
    if (hallPassTypeId) {
      message.setHallPassTypeId(hallPassTypeId);
    }

    if (startDate) {
      message.setStartDate(dateTimeObjectToDateTimeMessage(startDate));
    }

    const result = await this._hallPassManager.checkHallPassValidity(message);
    const list = result.getErrorsList();
    const uniqueList = {};
    /* considering a single person can bring up multiple errors, I'm going to
     * use their hash + error code as the key for uniqueness */
    list.forEach(msg => {
      if (!uniqueList[msg.getPersonHash() + ':' + msg.getErrorCode()]) {
        /* if the key doesn't exist, add it */
        uniqueList[msg.getPersonHash() + ':' + msg.getErrorCode()] = {
          error: msg.getErrorCode(),
          personHash: msg.getPersonHash(),
          name: msg.getName(),
        };
      }
    });
    /* convert the unique list to an array of the unique error objects */
    return Object.values(uniqueList);
  }

  async createBlackOutWindow(): Promise<IHallPassBlackOut> {
    const blackOutWindowDefaults: IHallPassBlackOut = {
      weekday: HallPassBlackOutWeekdaysEnum.EVERYDAY,
      startTime: '12:00:00',
      endTime: '13:00:00',
      schedules: [
        {
          startTime: '12:00:00',
          endTime: '13:00:00',
        },
      ],
      name: '',
      active: false,
      blockMonday: false,
      blockTuesday: false,
      blockWednesday: false,
      blockThursday: false,
      blockFriday: false,
      blockSaturday: false,
      blockSunday: false,
    };
    const blackOutWindowDefaultsProto = HallPassBlackOutMapper.toProto(
      blackOutWindowDefaults,
    );

    const request = new UpdateHallPassBlackOutWindowRequest();
    request.setBlackOutWindowInfo(blackOutWindowDefaultsProto);

    const response = await this._hallPassManager.updateHallPassBlackOutWindow(
      request,
    );

    const newBlackOutWindow = HallPassBlackOutMapper.fromProto(
      response.getBlackOutWindowInfo(),
    );

    return newBlackOutWindow;
  }

  async updateBlackOutWindow(blackOutInfo: IHallPassBlackOut) {
    const request = new UpdateHallPassBlackOutWindowRequest();
    const blackOutInfoProto = HallPassBlackOutMapper.toProto(blackOutInfo);
    request.setBlackOutWindowInfo(blackOutInfoProto);

    await this._hallPassManager.updateHallPassBlackOutWindow(request);
  }

  async deleteBlackOutWindow(blackOutWindowId: number) {
    const request = new DeleteHallPassBlackOutWindowRequest();
    request.setBlackOutWindowId(blackOutWindowId);

    await this._hallPassManager.deleteHallPassBlackOutWindow(request);
  }

  async getBlackOutWindowList(): Promise<IHallPassBlackOut[]> {
    const request = new GetHallPassBlackOutWindowsRequest();

    const response = await this._hallPassManager.getHallPassBlackOutWindows(
      request,
    );
    const blackOutWindowListProto = response.getHallPassBlackOutWindowsList();

    return blackOutWindowListProto.map(blackOutWindow =>
      HallPassBlackOutMapper.fromProto(blackOutWindow),
    );
  }

  private async _getCurrentMingaHallPassListTypeMembers(
    type: IHallPassMembershipListType,
  ): Promise<PersonViewMinimal.AsObject[]> {
    const response = await this._hallPassManager.getMembershipLists(
      new GetMembershipListsRequest(),
    );
    const membershipLists = response.getListsList();

    const noPassList = membershipLists.filter(list => {
      return list.getListType() === type.toString();
    });
    if (noPassList.length === 0) {
      return [];
    }
    const listId = noPassList[0].getListId();

    return await this._listService.getMembersOfList(listId);
  }

  public async getHallPassesList(
    limit: number,
    offset: number,
    filter: IMgStreamFilter,
  ): Promise<IMgStreamItem<HallPassWithType.AsObject>[]> {
    const request = new GetHallPassesRequest();
    request.setFilter(toStreamFilterMessage(filter));
    request.setLimit(limit);
    request.setOffset(offset);

    const response = await this._hallPassManager.getHallPasses(request);
    const items = response.getItemsList();

    return items.map(res => {
      const item = res.getItem().toObject();
      const itemMetadata: StreamItemMetadata = res.getItemMetadata();
      let itemIndex = null;
      let itemId = null;

      if (itemMetadata) {
        itemIndex = itemMetadata.getIndex();
        itemId = itemMetadata.getId();
      }

      return { item, itemIndex, itemId };
    });
  }
}
