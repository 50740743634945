"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const proto = require("minga/proto/student_schedule/student_schedule_pb");
const dateAndTime_1 = require("minga/libraries/shared-grpc/util/dateAndTime");
exports.toProto = (ss) => {
    const msg = new proto.StudentSection();
    msg.setId(ss.id);
    msg.setName(ss.name);
    if (ss.period)
        msg.setPeriod(ss.period);
    if (ss.term)
        msg.setTerm(ss.term);
    if (ss.date) {
        msg.setDate(dateAndTime_1.dateObjectToDateTimeMessage(ss.date));
    }
    if (ss.startTime)
        msg.setStartTime(ss.startTime);
    if (ss.endTime)
        msg.setEndTime(ss.endTime);
    if (ss.teachers)
        msg.setTeachersList(ss.teachers);
    if (ss.location)
        msg.setLocation(ss.location);
    if (ss.type)
        msg.setType(ss.type);
    if (ss.sectionId)
        msg.setSectionId(ss.sectionId);
    if (ss.periodId)
        msg.setPeriodId(ss.periodId);
    if (ss.termId)
        msg.setTermId(ss.termId);
    return msg;
};
exports.fromProto = (msg) => {
    const ss = {
        id: msg.getId(),
        name: msg.getName(),
    };
    if (msg.getPeriod())
        ss.period = msg.getPeriod();
    if (msg.getTerm())
        ss.term = msg.getTerm();
    if (msg.getDate()) {
        ss.date = dateAndTime_1.dateTimeMessageToDateObject(msg.getDate());
    }
    if (msg.getStartTime())
        ss.startTime = msg.getStartTime();
    if (msg.getEndTime())
        ss.endTime = msg.getEndTime();
    if (msg.getTeachersList())
        ss.teachers = msg.getTeachersList();
    if (msg.getLocation())
        ss.location = msg.getLocation();
    if (msg.getType())
        ss.type = msg.getType();
    if (msg.getSectionId())
        ss.sectionId = msg.getSectionId();
    if (msg.getPeriodId())
        ss.periodId = msg.getPeriodId();
    if (msg.getTermId())
        ss.termId = msg.getTermId();
    return ss;
};