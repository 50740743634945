import { Component, Input } from '@angular/core';

/**
 * Tool Stats Placeholder
 * This is used by multiple components, such as admin, and people view
 *
 * FIXME: having second thoughts about this. The grid is probably best defined
 * by the parent, see st-my-tools.component.html for an example. That allows the
 * local grid to be reused with the actual elements too. Consider simplifying
 * this once the admin tools and people view have been updated to match the
 * student tools css.
 */
@Component({
  selector: 'mg-tools-stats-placeholder',
  templateUrl: './tools-stats-placeholder.component.html',
})

//
export class ToolsStatsPlaceholderComponent {
  _quantity = 4;

  /**
   * Set the quantity of placeholder cards that should be generated in the view.
   * Using a setter method to limit the input numbers to a range. The range must
   * correspond to the grid lookup values.
   */
  @Input() set quantity(value: number) {
    if (value > 0 && value <= 5) {
      this._quantity = value;
    } else {
      // do nothing
    }
  }

  get quantity() {
    return this._quantity;
  }

  /**
   * Map input properties to style classes. Intentionally using full class
   * style names here, because some purgeCSS tools don't work well with
   * dynamically generated style names, eg. tw-grid-cols-{quantity}. Planning
   * for future addition.
   */
  get styleClasses(): string {
    const gridLookup: Record<number, string> = {
      1: '', // not necessary because default columns is 1
      2: 'md:tw-grid-cols-2',
      3: 'md:tw-grid-cols-3',
      4: 'sm:tw-grid-cols-2 md:tw-grid-cols-4',
      5: 'sm:tw-grid-cols-2 md:tw-grid-cols-5',
    };

    const output = [
      'tw-grid tw-grid-cols-1 tw-gap-2',
      gridLookup[this.quantity],
    ].join(' ');

    return output;
  }
}

/**
 * Helper function to count view stats based on what is enabled. Hopefully
 * we can remove this in the near future once individual stat metrics
 * handle their own loading and placeholder displaying.
 */
export const countActiveStatViews = (
  stats: boolean[],
  viewCounts: Record<number, number>,
): number => {
  return stats.reduce((count, enabled, index) => {
    const increment = viewCounts[index];
    return count + (enabled === true ? increment : 0);
  }, 0);
};
