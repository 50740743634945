import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MingaMinimalModel } from 'minga/libraries/domain';
import { MingaManagerService } from 'src/app/services/MingaManager';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';
import { ChangeMingaAction } from 'src/app/store/root/rootActions';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '../modal-overlay';
import {
  SwitchMingaMessages,
  SwitchMingaModalData,
  SwitchMingaModalResponse,
} from './switch-minga-modal.constants';

@Component({
  selector: 'mg-switch-minga-modal',
  templateUrl: './switch-minga-modal.component.html',
  styleUrls: ['./switch-minga-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchMingaModalComponent implements OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly MODAL_BG = ModalOverlayPrimaryHeaderBackground.PEOPLE_BLUE;
  public readonly MESSAGES = SwitchMingaMessages;

  public mingas$: Observable<MingaMinimalModel[]> = this._mingaStore
    .getAllMingas()
    .pipe(takeUntil(this._destroyed$));

  private _titleSubj = new BehaviorSubject('');
  public title$ = this._titleSubj.asObservable();

  constructor(
    @Inject(MODAL_OVERLAY_DATA) public dialogData: SwitchMingaModalData,
    private _modalOverlay: ModalOverlayRef<
      SwitchMingaModalResponse,
      SwitchMingaModalData
    >,
    private _mingaStore: MingaStoreFacadeService,
    private _mingaService: MingaManagerService,
    private _store: Store<any>,
  ) {
    const modalTitle =
      this.dialogData.modalTitle || SwitchMingaMessages.MODAL_TITLE;
    this._titleSubj.next(modalTitle);
  }

  public async changeMinga(minga: MingaMinimalModel) {
    await this._mingaService.openMinga(minga.hash);
    this._store.dispatch(new ChangeMingaAction(minga.hash));

    this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT, {
      minga,
    });
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
