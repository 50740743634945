<!-- Container -->
<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="start stretch">
  <div fxFlex="grow">
    <!-- Title -->
    <mg-text
      class="title"
      variant="header-xl"
      textAlign="center">
      {{ data.title }}
    </mg-text>

    <!-- Asset / Icon -->
    <div
      class="asset-container"
      [class.has-profile-picture]="data?.profilePicture">
      <!-- Profile picture -->
      <ng-container
        *ngIf="data.profilePicture as src; else stardardAssetTemplate">
        <div
          class="picture-container"
          [mgStudentPicture]="src"></div>
        <div
          [style.backgroundImage]="'url(' + (src | mgAssetUrl) + ')'"
          class="asset-profilePicture"
          title="{{ data.title }}"></div>
        <div class="asset-profilePicture-asset">
          <div
            class="default-asset with-profile-picture"
            [class.error]="data.type === 'error'"></div>
        </div>
      </ng-container>
      <!-- Standard asset template -->
      <ng-template #stardardAssetTemplate>
        <div
          class="default-asset"
          [class.error]="data.type === 'error'"></div>
      </ng-template>
    </div>

    <!-- Subtitle -->
    <mg-text
      class="subtitle"
      variant="header-xl-secondary"
      textAlign="center">
      {{ data.subTitle }}
    </mg-text>

    <!-- Message -->
    <mg-text
      class="message"
      variant="header-xl"
      textAlign="center">
      {{ data.message }}
    </mg-text>

    <!-- Extended Message -->
    <mg-text
      *ngIf="data.extendedMessage"
      class="extended-message"
      variant="body-sm"
      textAlign="center">
      {{ data.extendedMessage }}
    </mg-text>

    <!-- Detailed View -->
    <ng-container
      *ngIf="data?.detailedMessage"
      [ngTemplateOutlet]="detailedMessageTemplate"
      [ngTemplateOutletContext]="{ $implicit: data.detailedMessage }">
    </ng-container>
  </div>
  <div fxFlex="nogrow">
    <!-- Footer -->
    <div class="footer">
      <div
        *ngIf="data.type === 'error'"
        fxLayout="row"
        [fxLayoutAlign]="
          !!data?.canContinue ? 'space-between center' : 'end center'
        "
        fxLayoutGap="16px"
        fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch">
        <!-- Continue Button -->
        <mg-btn
          *ngIf="!!data?.canContinue"
          variant="text"
          [wide]="(media.breakpoint$ | async) === 'xsmall'"
          (pressed)="close('continue')">
          {{ data?.continueText || MSG.BUTTON_LABEL_CONTINUE }}
        </mg-btn>
        <!-- Close Button -->
        <mg-btn
          #closeBtn
          variant="filled"
          [wide]="(media.breakpoint$ | async) === 'xsmall'"
          (pressed)="close()">
          {{ data?.closeText || MSG.BUTTON_LABEL_CLOSE }}
        </mg-btn>
      </div>
    </div>
  </div>
</div>

<!-- Detailed Message Template -->
<ng-template
  #detailedMessageTemplate
  let-data>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="4px"
    class="detailed-message">
    <div
      *ngFor="let row of data; let i = index"
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="16px">
      <mg-text variant="label-md">
        {{ row[0] }}
      </mg-text>
      <mg-text variant="label-md">
        {{ row[1] }}
      </mg-text>
    </div>
  </div>
</ng-template>
