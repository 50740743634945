"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const domain_2 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const checkin_pb_1 = require("minga/proto/checkin/checkin_pb");
const membership_list_1 = require("minga/libraries/shared-grpc/membership_list/index");
exports.toProto = (list) => {
    const msg = new checkin_pb_1.CheckinReason();
    if (list.id)
        msg.setId(list.id);
    if (list.name)
        msg.setName(list.name);
    if (list.createdBy)
        msg.setCreatedBy(list.createdBy);
    if (list.mingaId)
        msg.setMingaId(list.mingaId);
    if (list.active)
        msg.setActive(list.active);
    if (list.icon)
        msg.setIcon(list.icon);
    if (list.pointReward != null)
        msg.setPointReward(list.pointReward);
    if (list.stickerIds)
        msg.setStickerIdsList(list.stickerIds);
    if (list.numberOfAbsentees)
        msg.setNumberOfAbsentees(list.numberOfAbsentees);
    if (list.totalCheckinCount)
        msg.setTotalCheckinCount(list.totalCheckinCount);
    if (list.roles)
        msg.setRolesList(list.roles.map(r => domain_2.MingaRoleType[r]));
    if (list.userLists)
        msg.setUserListsList(list.userLists);
    if (list.groupHashes)
        msg.setMingagrouphashesList(list.groupHashes);
    if (list.blockNoAccess)
        msg.setBlockNoAccess(list.blockNoAccess);
    if (list.priority)
        msg.setPriority(list.priority);
    if (list.color)
        msg.setColor(list.color);
    if (list.hallPassId)
        msg.setHallPassId(list.hallPassId);
    if (list.behaviorId)
        msg.setBehaviorId(list.behaviorId);
    msg.setShowAbsentees(list.showAbsentees);
    msg.setSelfCheckIn(list.selfCheckIn);
    msg.setAllowMultipleCheckins(list.allowMultipleCheckins || false);
    if (list.grades)
        msg.setGradesList(list.grades.map(g => ts_enum_util_1.$enum(util_1.Grade).asValueOrThrow(g)));
    if (list.restrictedCheckinReasonId)
        msg.setRestrictedCheckinReasonId(list.restrictedCheckinReasonId);
    if (list.consequenceId)
        msg.setConsequenceId(list.consequenceId);
    if (list.allowCheckout)
        msg.setAllowCheckout(list.allowCheckout);
    msg.setCheckinReasonType(list.checkinReasonType);
    if (list.membershipList)
        msg.setMembershipList(membership_list_1.MembershipListMapper.toProto(list.membershipList));
    if (list.restrictionId)
        msg.setRestrictionId(list.restrictionId);
    if (list.availableInKiosk)
        msg.setAvailableInKiosk(list.availableInKiosk);
    msg.setEnableStudentPhoto(list.enableStudentPhoto || false);
    return msg;
};
exports.fromProto = (msg) => {
    const id = msg.getId();
    const name = msg.getName();
    const createdBy = msg.getCreatedBy();
    const mingaId = msg.getMingaId();
    const active = msg.getActive();
    const stickerIds = msg.getStickerIdsList();
    const icon = msg.getIcon();
    const rolesStrings = msg.getRolesList();
    const roles = rolesStrings.map(r => ts_enum_util_1.$enum(domain_2.MingaRoleType).asValueOrThrow(r));
    const userLists = msg.getUserListsList();
    const gradeStrings = msg.getGradesList();
    const grades = gradeStrings.map(r => ts_enum_util_1.$enum(util_1.Grade).asValueOrThrow(r));
    const groupHashes = msg.getMingagrouphashesList();
    const blockNoAccess = msg.getBlockNoAccess();
    const restrictedCheckinReasonId = msg.getRestrictedCheckinReasonId();
    const priority = msg.getPriority();
    const pointReward = msg.getPointReward();
    const showAbsentees = msg.getShowAbsentees();
    const numberOfAbsentees = msg.getNumberOfAbsentees();
    const totalCheckinCount = msg.getTotalCheckinCount();
    const hallPassId = msg.getHallPassId();
    const behaviorId = msg.getBehaviorId();
    const color = msg.getColor();
    const selfCheckIn = msg.getSelfCheckIn();
    const consequenceId = msg.getConsequenceId();
    const allowCheckout = msg.getAllowCheckout();
    const checkinReasonType = ts_enum_util_1.$enum(domain_1.CheckinReasonType).asValueOrDefault(msg.getCheckinReasonType(), domain_1.CheckinReasonType.CHECKIN);
    const allowMultipleCheckins = msg.getAllowMultipleCheckins();
    const membershipList = msg.getMembershipList()
        ? membership_list_1.MembershipListMapper.fromProto(msg.getMembershipList())
        : undefined;
    const availableInKiosk = msg.getAvailableInKiosk();
    const enableStudentPhoto = msg.getEnableStudentPhoto();
    return {
        id,
        name,
        createdBy,
        mingaId,
        active,
        stickerIds,
        icon,
        pointReward,
        showAbsentees,
        numberOfAbsentees,
        totalCheckinCount,
        roles,
        userLists,
        grades,
        groupHashes,
        blockNoAccess,
        restrictedCheckinReasonId,
        priority,
        hallPassId: hallPassId || undefined,
        behaviorId: behaviorId || undefined,
        color,
        selfCheckIn,
        consequenceId: consequenceId || undefined,
        allowCheckout,
        checkinReasonType,
        membershipList,
        restrictionId: msg.getRestrictionId(),
        allowMultipleCheckins,
        enableStudentPhoto,
        availableInKiosk,
    };
};