import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IHallPassType } from 'minga/libraries/domain';
import { mingaSettingTypes } from 'minga/libraries/util';
import { MgValidators } from 'src/app/input/validators';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { DEFAULT_STAFF_PASS_DURATION } from '@modules/selection-assigner';

import {
  HALLPASS_GROUP,
  HALLPASS_FIELDS,
  MyClassMessages,
} from '../../constants/tt-my-class.constants';
import { AssignmentType } from '../../types/tt-my-class.types';

@Component({
  selector: 'mg-tt-hallpass-fields',
  templateUrl: './tt-hallpass-fields.component.html',
  styleUrls: ['./tt-hallpass-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtHallpassFieldsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() set type(hallpassType: IHallPassType) {
    if (hallpassType) {
      this._hallpassTypeSubject.next(hallpassType);
    }
  }

  private _hallpassTypeSubject = new BehaviorSubject<IHallPassType>(null);
  private _hasspassType$ = this._hallpassTypeSubject.asObservable();

  private _groupInitializedSubject = new BehaviorSubject<boolean>(false);
  private _groupInitialized$ = this._groupInitializedSubject.asObservable();

  private _destroyedSubject = new ReplaySubject<void>(1);
  public group: FormGroup;
  public HALLPASS_FIELDS = HALLPASS_FIELDS;
  public readonly canAddNote$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.PASS_ALLOW_NOTE,
  );
  public scheduleForLater = new FormControl(false);
  public today = day();
  public MESSAGES = MyClassMessages;

  private _disabledApprovedBySubject = new BehaviorSubject<boolean>(false);
  public disabledApprovedBy$ = this._disabledApprovedBySubject.asObservable();
  private _disabledScheduledLaterSubject = new BehaviorSubject<boolean>(false);
  public disabledScheduledLater$ =
    this._disabledScheduledLaterSubject.asObservable();

  // need local form control just to keep internal state of approve by selector
  // since we need both name and hash we manually set that on change
  public approveByControl = new FormControl();

  constructor(
    private _fb: FormBuilder,
    private _settingsService: MingaSettingsService,
  ) {}

  ngOnInit(): void {
    this._setFormGroup();

    this.scheduleForLater.valueChanges
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(value => {
        this._onScheduleFormLaterChange(value);
      });

    combineLatest([this._hasspassType$, this._groupInitialized$])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(([hallpassType, groupInitialized]) => {
        if (hallpassType && groupInitialized) {
          this._setDefaultValues(hallpassType);
        }
      });
  }

  ngOnDestroy(): void {
    this.form.removeControl(AssignmentType.HALLPASS);
    this.form.updateValueAndValidity();
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public matMenuKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      return;
    }
    event.stopPropagation();
  }

  public assignerChange(data: any): void {
    const control = this.group.get(HALLPASS_FIELDS.APPROVED_BY);
    const value = data
      ? {
          hash: data.value,
          name: data.label,
        }
      : null;

    control.setValue(value);

    // if we have a teacher assigned we need to disable schedule for later
    if (data) {
      this.scheduleForLater.setValue(false);
      this.scheduleForLater.disable();
      this._disabledScheduledLaterSubject.next(true);
    } else {
      this.scheduleForLater.enable();
      this._disabledScheduledLaterSubject.next(false);
    }
  }

  private _onScheduleFormLaterChange(value: boolean): void {
    const dateControl = this.group.get(HALLPASS_FIELDS.DATE);
    const timeControl = this.group.get(HALLPASS_FIELDS.TIME);

    if (value) {
      dateControl.setValidators([
        Validators.required,
        MgValidators.DateNotInPastValidator,
      ]);
      dateControl.setValue(this.today);
      timeControl.setValidators([Validators.required]);
      timeControl.setValue(this.today.add(1, 'hour').format('HH:mm'));
      this.group.setValidators(
        MgValidators.DateTimeNotInPastValidator(
          HALLPASS_FIELDS.DATE,
          HALLPASS_FIELDS.TIME,
        ),
      );

      this._resetApproveByControl();
      this.approveByControl.disable();
      this._disabledApprovedBySubject.next(true);
    } else {
      dateControl.setValue(null);
      dateControl.setValidators(null);
      dateControl.markAsUntouched();
      timeControl.setValue(null);
      timeControl.setValidators(null);
      timeControl.markAsUntouched();
      this.group.setValidators(null);

      this.approveByControl.enable();
      this._disabledApprovedBySubject.next(false);
    }

    this.approveByControl.updateValueAndValidity();
    dateControl.updateValueAndValidity();
    timeControl.updateValueAndValidity();
  }

  private _setFormGroup() {
    this.group = this._fb.group(HALLPASS_GROUP());
    this.form.addControl(AssignmentType.HALLPASS, this.group);
    this.form.updateValueAndValidity();
    this._groupInitializedSubject.next(true);
  }

  private _setDefaultValues(hallpassType: IHallPassType) {
    const defaultValue =
      hallpassType?.defaultHallPassTime || DEFAULT_STAFF_PASS_DURATION;
    this.group.get(HALLPASS_FIELDS.DURATION).setValue(defaultValue);
    this.group.get(HALLPASS_FIELDS.DATE).setValue(null);
    this.group.get(HALLPASS_FIELDS.TIME).setValue(null);
    this._resetApproveByControl();
    this.scheduleForLater.setValue(false);
  }

  // we need to reset both local and main form controls
  private _resetApproveByControl() {
    this.group.get(HALLPASS_FIELDS.APPROVED_BY).setValue(null);
    this.approveByControl.setValue(null);
  }
}
