import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { IAvatarClusterItem } from 'minga/app/src/app/components/AvatarCluster';
import { StreamSwipeStackComponent } from 'minga/app/src/app/components/StreamSwipeStack';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import {
  IOverlayConfig,
  IOverlayConfigurable,
} from 'minga/app/src/app/misc/overlay';
import { ChallengeResponseStackStream } from 'minga/app/src/app/modules/challenges/services/ChallengeResponseStackStream';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { dateMessageObjectToDateObject } from 'minga/app/src/app/util/date';
import { ChallengeType } from 'minga/libraries/domain';
import { MingaRoleType } from 'minga/libraries/domain';
import { badgeRoleNameToMingaRoleType } from 'minga/libraries/shared';
import { ChallengeResponseSummaryItemMapper } from 'minga/libraries/shared-grpc';
import { ChallengeResponse as ChallengeResponseProto } from 'minga/proto/content/challenge/challenge_response_ng_grpc_pb';
import { ChallengeResponseCard } from 'minga/proto/content/challenge/challenge_response_pb';
import { LongChallengeCardView } from 'minga/proto/gateway/content_views_pb';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';

import { LongCardBaseClass } from '../LongCardBase/LongCardBaseClass';

const RESPONSE_PREVIEW_LIMIT = 2;

@Component({
  selector: 'mg-challenge-long-card',
  templateUrl: './ChallengeLongCard.component.html',
  styleUrls: ['./ChallengeLongCard.component.scss'],
})
export class ChallengeLongCardComponent
  extends LongCardBaseClass
  implements OnDestroy, OnInit, OnChanges, IOverlayConfigurable
{
  content?: LongChallengeCardView.AsObject;

  windowWidth: number = 400;
  avatarClusterItems: IAvatarClusterItem[] = [];
  peopleResponsesText: string = '';

  stackIndex: number = 0;
  currentDate: Date = new Date();
  hasResponded: boolean = false;
  dueDate: Date = new Date();

  canApprove: boolean = false;

  @ViewChild('heroImageWrap', { static: false })
  heroImageWrap?: ElementRef;

  @ViewChild('streamSwipeStack', { static: true })
  streamSwipeStack?: StreamSwipeStackComponent<ChallengeResponseCard.AsObject>;

  @HostBinding('class.has-responses')
  get hasResponses(): boolean {
    return !!this.content?.responsesSummaryList.length;
  }

  readonly bannerImageSizes: ReadonlyArray<string | string[]> = [
    'blurloading1',
    ['longcardbanner', 'cardbanner', 'banner'],
  ];
  feedFilter: any = {};

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.updateScreenSizing();
  }

  responseStream?: ChallengeResponseStackStream;

  readonly currentUser$: Observable<PersonViewMinimal.AsObject | null>;
  private _destroyed$ = new ReplaySubject<void>(1);
  private _currentUserSubscription?: Subscription;
  hasPermission: boolean = false;

  constructor(
    contentEvents: ContentEvents,
    private sanitizer: DomSanitizer,
    public media: MediaObserver,
    router: Router,
    groupFacade: GroupsFacadeService,
    contentState: ContentState,
    public responseProto: ChallengeResponseProto,
    private authInfo: AuthInfoService,
  ) {
    super(contentEvents, groupFacade, contentState, router);

    this.currentUser$ = this.authInfo.authInfo$.pipe(
      takeUntil(this._destroyed$),
      map(info => {
        if (info && info.person) {
          return info.person;
        }
        return null;
      }),
    );
  }

  get typeStyleClass() {
    if (!this.content || !this.content.challengeType) return '';

    switch (this.content.challengeType) {
      case ChallengeType.TEXT:
        return 'text';
      case ChallengeType.VIDEO:
        return 'video';
      case ChallengeType.IMAGE:
      default:
        return 'photo';
    }
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    fn({
      boxShadow: 'none',
      contentBackground: '#ffffff',
      fullContentBackground: '#ffffff',
      borderRadius: '0px',
    });
  }

  getFeaturedImageUrl() {
    if (this.content && this.content.featuredImage) {
      return this.getImageUrl(this.content.featuredImage, [
        'longcardbanner',
        'cardbanner',
        'banner',
      ]);
    } else {
      return '';
    }
  }

  getDueDate() {
    if (!this.content || !this.content.dueDate) {
      return null;
    }

    this.dueDate = dateMessageObjectToDateObject(this.content.dueDate);

    return formatDate(this.dueDate, 'MMM d, y', 'en-CA').toUpperCase();
  }

  setHasResponded() {
    this.hasResponded = this.content?.hasResponded || false;
  }

  updateScreenSizing() {
    this.windowWidth = window.innerWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this.updateAvatarClusterItems();
      this.updatePeopleResponsesText();
      this.updateStreamDataSource();
    }
    if (changes.contextHash && this.contextHash) {
      this.updateStreamDataSource();
    }
  }

  ngOnInit() {
    if (this.content && this.content.ownerGroupHash) {
      this.ownerGroupHash.next(this.content.ownerGroupHash);
    }
    this.disableActions = true;
    this.setCanApprove();

    this.updateScreenSizing();
    this.updateAvatarClusterItems();
    this.setHasResponded();
    this.updatePeopleResponsesText();
    this.setResponseStreamFilterState();

    this.updateStreamDataSource();
    this.setResponseToView();

    this._currentUserSubscription = this.currentUser$.subscribe(person => {
      if (!this.content?.allowedRolesList) {
        return;
      }

      const personBadgeRoleName = person?.badgeRoleName || '';
      const personRole = badgeRoleNameToMingaRoleType(personBadgeRoleName);
      const allowedRoles = this.content.allowedRolesList.map(role => {
        return $enum(MingaRoleType).getValueOrThrow(role);
      });

      if (personRole && allowedRoles && allowedRoles.includes(personRole)) {
        this.hasPermission = true;
      }
    });
  }

  ngOnDestroy() {
    this._currentUserSubscription?.unsubscribe();
  }

  onStackIndexChange(index: number) {}

  setCanApprove() {
    const isAuthor =
      this.content?.authorPersonView?.personHash ==
      this.authInfo.authPersonHash;

    this.canApprove = isAuthor && !!this.content?.requiresApproval;
  }

  setResponseStreamFilterState() {
    if (this.contextHash) {
      const responsesFilter = this.contentState.getStateValue(
        this.contextHash,
        'responseStreamState',
      );
      if (responsesFilter) {
        this.feedFilter['challengeResponse.approved'] = responsesFilter;
      }
    }
  }

  setResponseToView() {
    if (this.contextHash) {
      // check if there's a response context to be opened to...
      const responseContextHash = this.contentState.getStateValue(
        this.contextHash,
        'responseContextHash',
      );
      if (responseContextHash) {
        // go to it!
        setTimeout(() => {
          this.onResponseClick(`${responseContextHash}`);
        }, 0);
      }
    }
  }

  updateStreamDataSource() {
    if (this.contextHash) {
      let previewItems: ChallengeResponseCard.AsObject[] = [];
      if (this.content) {
        previewItems = this.content.responsesSummaryList.map(
          ChallengeResponseSummaryItemMapper.toChallengeResponseCard,
        );
      }

      if (this.responseStream?.ownerContextHash == this.contextHash) {
        this.responseStream.setPreviewInitialItems(previewItems);
      } else {
        this.responseStream = new ChallengeResponseStackStream(
          this.responseProto,
          previewItems,
          this.contextHash,
          this.feedFilter,
        );
      }
    } else {
      delete this.responseStream;
    }
  }

  updateAvatarClusterItems() {
    if (!this.content || !this.content?.responsesSummaryList) {
      this.avatarClusterItems = [];
      return;
    }

    const clusterItems = [];
    for (let response of this.content.responsesSummaryList) {
      if (response.authorPersonView) {
        clusterItems.push({
          src: response.authorPersonView.profileImageUrl,
          color: response.authorPersonView.badgeIconColor,
          personHash: response.authorPersonView.personHash,
        });
      }

      if (clusterItems.length == RESPONSE_PREVIEW_LIMIT) {
        break;
      }
    }
    this.avatarClusterItems = clusterItems;
  }

  updatePeopleResponsesText() {
    let responseString = '';

    if (!this.content || !this.content?.responsesSummaryList) {
      this.peopleResponsesText = responseString;
      return;
    }

    for (let i = 0; i < this.content.responsesSummaryList.length; i++) {
      if (i == RESPONSE_PREVIEW_LIMIT) {
        if (i === this.content.respondedPeopleCount - 1)
          responseString += '1 more person';
        else
          responseString +=
            'and ' + (this.content.respondedPeopleCount - i) + ' more people';
        break;
      }

      const response = this.content.responsesSummaryList[i];
      responseString += response.authorPersonView?.displayName;
      if (i + 2 < this.content.responsesSummaryList.length) {
        responseString += ', ';
      } else if (i + 1 < this.content.responsesSummaryList.length) {
        responseString += ' and ';
      }
    }
    if (this.content.responsesSummaryList.length === 1)
      responseString += ' has posted their answer!';
    else responseString += ' have posted their answers!';
    this.peopleResponsesText = responseString;
  }

  onResponseClick(responseContextHash: string) {
    if (!this.responseStream) {
      console.error(
        `No response stream. Cannot handle response click: ${responseContextHash}`,
      );
      return;
    }

    if (!this.streamSwipeStack) {
      console.error(
        `No stream swipe stack. Cannot handle response click: ${responseContextHash}`,
      );
      return;
    }

    // seek the stream until we find the response we are looking for
    const seekComplete$ = new Subject();
    this.responseStream
      .seekToResponseContextHash(responseContextHash)
      .pipe(takeUntil(seekComplete$))
      .subscribe(index => {
        if (index === -1) {
          seekComplete$.next(true);
          return;
        }
        if (index !== null && index > -1) {
          if (this.streamSwipeStack) {
            this.streamSwipeStack.setDataSourceIndex(index);
            seekComplete$.next(true);
          }
        }
      });
  }

  onResponseApproved(contextHash: string) {
    this.streamSwipeStack?.next();
  }

  onResponseDeclined(contextHash: string) {
    this.streamSwipeStack?.next();
  }
}
