import { EventEmitter, Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import * as report_proto from 'minga/proto/stats_report/stats_report_pb';
import { ReportTypes } from 'minga/libraries/domain';

export interface filterPersonEmitterTriggers {
  accessor: string;
  fieldsToReset?: string[];
}

/**
 * Abstract class to house ReportsService's filter
 * functionality.
 */
@Injectable()
export abstract class ReportServiceFilterFunctions<F> implements OnDestroy {
  //** Filter types need to be configured by children services. */
  filter: F;
  private _filterSubj: BehaviorSubject<F>;
  private _nonArrayFilterTypes: any[];
  private _initFilterState: F;
  filter$: Observable<F>;

  // An emitter for updating the person select filter display. @deprecated with new report filter architecture
  public readonly onPersonSelectedFromTable = new EventEmitter<string>();
  private _personEmitTriggers:
    | Record<string, filterPersonEmitterTriggers>
    | undefined;

  constructor(
    /**
     * initFilterState is used to set our initial filters.
     * Typically the accessors with empty values.
     */
    initFilterState: F,
    /**
     * By default, this class assumes filters are array
     * types (i.e. we can have multiple values selected
     * for a filter type). To override this, specify the
     * filter accessors in nonArrayFilterTypes.
     */
    nonArrayFilterTypes?: string[],
    /**
     * When using mg-multi-person-search we need to manually
     * emit our person selection when we click on row-based
     * links, so that the filter change is reflected on the
     * UI. Use personEmitTriggers to specify which filter has
     * a trigger and what events should be triggered.
     */
    personEmitTriggers?: Record<string, filterPersonEmitterTriggers>,
  ) {
    this.filter = { ...initFilterState };
    this._filterSubj = new BehaviorSubject<F>(this.filter);
    this.filter$ = this._filterSubj.asObservable();
    this._initFilterState = { ...initFilterState };
    this._nonArrayFilterTypes = nonArrayFilterTypes ?? [];
    this._personEmitTriggers = personEmitTriggers;
  }

  ngOnDestroy() {
    this._filterSubj.complete();
  }

  //** Filter functions that have typically shared functionality */

  /**
   * setAndApplyFilter sets and applies filters. Typically used
   * when we click on row-based links.
   *
   * @param filterType
   * @param value
   * @param fromTable: tells us if we need to emit a person
   */
  setAndApplyFilter(filterType: string, value: any, fromTable?: boolean): void {
    if (fromTable) {
      value = this._convertTableDataForFilter(value);
    }
    this.setFilter(filterType, value);
    if (fromTable && this._personEmitTriggers) {
      if (this._personEmitTriggers[filterType]) {
        const trigger = this._personEmitTriggers[filterType];
        if (trigger.fieldsToReset?.length) {
          for (const reset of trigger.fieldsToReset) {
            this.filter[reset] = this._initFilterState[reset];
          }
        }
        // When we are emitting a person we only emit one, since we will be clicking
        // on an individual row.
        const personToEmit = Array.isArray(this.filter[trigger.accessor])
          ? this.filter[trigger.accessor][0]
          : this.filter[trigger.accessor];
        this.onPersonSelectedFromTable.emit(personToEmit);
      }
    }
    this.applyFilter();
  }

  /**
   * Prepares the table data for filter setting
   *
   * @param value
   * @returns
   */
  protected _convertTableDataForFilter(value: any) {
    if (value.person) {
      return value.person;
    }
    return value;
  }

  /**
   * Converts filter data to an array if needed and sets
   * filter. Used directly by report components.
   *
   * @param filterType
   * @param value
   */
  setFilter(filterType: string, value: any) {
    if (!this._nonArrayFilterTypes.includes(filterType)) {
      // need this if else to safe guard against getting [undefined]
      if (value) {
        value = Array.isArray(value) ? value : [value];
      } else {
        value = [];
      }
    }
    this._handleSetFilter(filterType, value);
  }

  /**
   * Updates filter subject. Used directly by report
   * components.
   */
  applyFilter() {
    this._filterSubj.next(this.filter);
  }

  /**
   * Clears filter. Used directly by report components.
   */
  clearFilter() {
    this.filter = { ...this._initFilterState };
    this.applyFilter();
  }

  /**
   * Gets current filter state.
   */
  getCurrentFilterState(): F {
    return this._filterSubj.getValue();
  }

  //** ABSTRACTS */

  /**
   * Handles setting the filter, often a switch statement.
   */
  protected abstract _handleSetFilter(filter: any, value: any): void;

  /**
   * Map the filters to the protobuf message. Must be implemented by the subclass.
   *
   * @todo remove any return type when all reports are converted and make abstract
   * @param reportType - needed for the grpc service
   * @param offset - where to start
   * @param limit - page limit for infinite scroll
   */
  protected abstract _mapFiltersToFilterMessage(
    reportType: ReportTypes,
    offset?: number,
    limit?: number,
  ): report_proto.GetOrExportReportRequest;
}
