"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileContentClient = exports.ProfileContent = void 0;
const gateway_profileContent_pb = require("minga/proto/gateway/profileContent_pb");
const common_stream_pb = require("minga/proto/common/stream_pb");
class ProfileContent {
}
exports.ProfileContent = ProfileContent;
ProfileContent.serviceName = "minga.profile.ProfileContent";
ProfileContent.Search = {
    methodName: "Search",
    service: ProfileContent,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_profileContent_pb.SearchProfileContentResponse,
};
ProfileContent.SearchControl = {
    methodName: "SearchControl",
    service: ProfileContent,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
};
class ProfileContentClient {
    search() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    searchControl() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
}
exports.ProfileContentClient = ProfileContentClient;