import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { isEqual } from 'lodash';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { CHECKIN_ICONS, ICheckinReason } from 'minga/domain/checkin';
import { MembershipListType } from 'minga/domain/membershipList';
import { mingaSettingTypes } from 'minga/util';
import { HallPassService } from 'src/app/services/HallPass';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  FormRestrictionTabs,
  setDefaultRestrictionControlValue,
} from '@shared/components/form-restriction-input/form-restriction-input.constants';
import { ImageViewerModalComponent } from '@shared/components/image-viewer-modal';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinService } from '@shared/services/checkin';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { PbisService } from '@shared/services/pbis';
import QrCodeService from '@shared/services/qrcode';

import {
  CheckinManagerReasonsEditEligbleUsers,
  CheckinManagerReasonsEditMessages,
  CheckInReasonsFormFields,
  PRESET_COLORS,
} from './checkin-manager-reasons-edit.constants';
import {
  CheckinManagerReasonsEditData,
  CheckinManagerReasonsEditDialogData,
} from './checkin-manager-reasons-edit.types';

/**
 * Checkin Manager Reasons Edit
 */
@Component({
  selector: 'mg-checkin-manager-reasons-edit',
  templateUrl: './checkin-manager-reasons-edit.component.html',
  styleUrls: ['./checkin-manager-reasons-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinManagerReasonsEditComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly FORM_FIELDS = CheckInReasonsFormFields;
  public readonly RESTRICTION_TABS = FormRestrictionTabs;
  public readonly CHECKIN_ICONS = CHECKIN_ICONS;
  public readonly PRESET_COLORS = PRESET_COLORS;
  public readonly MESSAGES: typeof CheckinManagerReasonsEditMessages =
    CheckinManagerReasonsEditMessages;

  /** Subscription Cleanup */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Reason */
  private _reason$: Observable<ICheckinReason>;

  /** Component Data */
  data: CheckinManagerReasonsEditData = {
    original: undefined,
    changes: undefined,
    isLoading: true,
  };
  disableCloseErrorMsg = '';

  /** Is New Reason */
  get isNewReason(): boolean {
    return this.dialogData.type === 'new' ? true : false;
  }

  /** Get Default Status */
  get isDefaultReason(): boolean {
    if (this.isNewReason) return false;
    if (!this.data.original) return false;
    return this.data.original.createdBy ? false : true;
  }

  /** Can Submit Form */
  canSubmitForm = false;

  /** Eligible Users  */
  ELIGIBLE_USERS: typeof CheckinManagerReasonsEditEligbleUsers =
    CheckinManagerReasonsEditEligbleUsers;
  eligibleUsers: CheckinManagerReasonsEditEligbleUsers =
    CheckinManagerReasonsEditEligbleUsers.ROLE;

  /** Modal Config */
  get modalConfig(): {
    title: string;
    headerBg: ModalOverlayPrimaryHeaderBackground;
  } {
    const { original } = this.data;
    if (!original) {
      return {
        title: 'Edit',
        headerBg: ModalOverlayPrimaryHeaderBackground.TEAL,
      };
    }
    if (this.isNewReason) {
      return {
        title: CheckinManagerReasonsEditMessages.MODAL_TITLE_NEW_CHECKIN,
        headerBg: ModalOverlayPrimaryHeaderBackground.TEAL,
      };
    }
    return {
      title: CheckinManagerReasonsEditMessages.MODAL_TITLE_CHECKIN,
      headerBg: ModalOverlayPrimaryHeaderBackground.TEAL,
    };
  }

  /** General Observables */
  public readonly hasPbis$: Observable<boolean>;
  public readonly hasHp$: Observable<boolean>;
  public readonly hasCons$: Observable<boolean>;
  public readonly hallPasses$: Observable<any>;
  public readonly behaviors$: Observable<any>;
  public readonly consequences$: Observable<any>;
  public readonly kioskEnabled$: Observable<boolean>;

  /** Form Control */
  nameControl = new FormControl('', [
    Validators.minLength(1),
    Validators.maxLength(25),
  ]);
  pointsControl = new FormControl(0);
  absenteesControl = new FormControl(false);
  noAccessControl = new FormControl(false);
  qrCodeControl = new FormControl(false);
  enableStudentPhotoControl = new FormControl(false);
  multipleCheckinsControl = new FormControl(false);
  consControl = new FormControl(null);
  colorControl = new FormControl('#1d9fb9', [Validators.required]);
  checkoutControl = new FormControl(null);
  restrictionsControl = setDefaultRestrictionControlValue();
  kioskControl = new FormControl(false);

  /** Membership Details */
  public readonly membershipType = MembershipListType.CHECKIN_RESTRICTION_LIST;
  private _membershipListId: number;

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: CheckinManagerReasonsEditDialogData,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _checkinService: CheckinService,
    private _dialog: MatDialog,
    private _modalOverlay: ModalOverlayService,
    private _modalOverlayRef: ModalOverlayRef,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _settingsService: MingaSettingsService,
    private _hallPassService: HallPassService,
    private _behaviorService: PbisService,
    private _qrCodeService: QrCodeService,
    public kioskPermissions: KioskPermissionsService,
  ) {
    this.hasPbis$ = this._settingsService.isPbisModuleEnabled();
    this.hasHp$ = this._settingsService.isHallPassModuleEnabled();
    this.hasCons$ = this._settingsService.getSettingValueObs(
      mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
    );
    this.kioskEnabled$ = this._settingsService
      .getSettingValueObs(mingaSettingTypes.CHECKIN_KIOSK)
      .pipe(takeUntil(this._destroyed$));
    this.hallPasses$ = this._hallPassService.getHallPassTypes(false).pipe(
      map(types => {
        return types
          .map(type => ({
            label: type.name,
            contextLabel: !type.active
              ? CheckinManagerReasonsEditMessages.HALLPASS_DISABLE_MSG
              : '',
            value: type.id,
            disabled: !type.active,
          }))
          .sort((a, b) => {
            const disabledDiff = +a.disabled - +b.disabled;
            if (disabledDiff !== 0) {
              return disabledDiff;
            }
            return a.label.localeCompare(b.label);
          });
      }),
    );
    this.behaviors$ = this._behaviorService.getTypes(true).pipe(
      takeUntil(this._destroyed$),
      map(types => types.map(type => ({ label: type.name, value: type.id }))),
    );
    this.consequences$ = this._behaviorService.getConsTypes(true).pipe(
      takeUntil(this._destroyed$),
      map(types => types.map(type => ({ label: type.name, value: type.id }))),
    );

    this.pointsControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(pointValue => {
        this.data.changes.pointReward = Number(pointValue);
        this.onChange();
      });

    this.nameControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(nameValue => {
        this.data.changes.name = nameValue;
        this.onChange();
      });

    this.absenteesControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(absenteesValue => {
        this.data.changes.showAbsentees = absenteesValue;
        this.onChange();
      });

    this.noAccessControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(noAccessValue => {
        this.data.changes.blockNoAccess = noAccessValue;
        this.onChange();
      });

    this.colorControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(colorValue => {
        this.data.changes.color = colorValue;
        this.onChange();
      });

    this.consControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        this.data.changes.consequenceId = value;
        this.onChange();
      });

    this.qrCodeControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(selfCheckIn => {
        this.data.changes.selfCheckIn = selfCheckIn;
        this.onChange();
      });

    this.enableStudentPhotoControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(v => {
        this.data.changes.enableStudentPhoto = v;
        this.onChange();
      });

    this.checkoutControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(allowCheckout => {
        this.data.changes.allowCheckout = allowCheckout;
        this.onChange();
      });

    this.multipleCheckinsControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(allowMultipleCheckins => {
        this.data.changes.allowMultipleCheckins = allowMultipleCheckins;
        this.onChange();
      });

    this.restrictionsControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        this.data.changes.roles = value[FormRestrictionTabs.Role];
        this.data.changes.userLists = value[FormRestrictionTabs.Lists];
        this.data.changes.grades = value[FormRestrictionTabs.Grades];
        this.data.changes.groupHashes = value[FormRestrictionTabs.Groups];
        this.data.changes.restrictedCheckinReasonId =
          value[FormRestrictionTabs.Reasons];
        this.data.changes.stickerIds = value[FormRestrictionTabs.Stickers];
        this.data.changes.membershipList =
          value[FormRestrictionTabs.MembershipList];
        this.onChange();
      });

    this.kioskControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(availableInKiosk => {
        this.data.changes.availableInKiosk = availableInKiosk;
        this.onChange();
      });
  }

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {
    const { data } = this.dialogData;

    /** Reason */
    this._reason$ = this.isNewReason
      ? of(data as ICheckinReason)
      : this._checkinService.getReason(data as number);
    this._reason$
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => {
          this.data.isLoading = true;
          this._cdr.markForCheck();
        }),
      )
      .subscribe(reason => this._handleReasonSub(reason));

    this._handleDeletedHallPassTypes();
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onChange(event?: any) {
    this._validateChanges();
  }

  /**
   * Validate Changes
   *
   * This is used to check if there are new changes to the original reason
   */
  private _validateChanges(): void {
    const { changes, original } = this.data;
    this.canSubmitForm = true;
    if (changes.name.length === 0 || changes.name.length > 255) {
      this.canSubmitForm = false;
    }

    const conditions = [
      !!changes.roles?.length,
      !!changes.userLists?.length,
      !!changes.stickerIds?.length,
      !!changes.grades?.length,
      !!changes.groupHashes?.length,
      !!changes.restrictedCheckinReasonId,
      !!changes.membershipList?.memberCount,
    ];
    const trueConditionsCount = conditions.filter(Boolean).length;
    if (trueConditionsCount !== 1) this.canSubmitForm = false;

    if (this.isNewReason) {
      this._cdr.markForCheck();
      return;
    }
    if (this.canSubmitForm) {
      const propertiesToCheck: (keyof ICheckinReason)[] = [
        'name',
        'showAbsentees',
        'icon',
        'pointReward',
        'roles',
        'userLists',
        'stickerIds',
        'grades',
        'groupHashes',
        'restrictedCheckinReasonId',
        'blockNoAccess',
        'hallPassId',
        'behaviorId',
        'color',
        'consequenceId',
        'allowCheckout',
        'selfCheckIn',
        'membershipList',
        'allowMultipleCheckins',
        'enableStudentPhoto',
      ];
      const isDifferentFromOriginal = !propertiesToCheck.every(property => {
        if (Array.isArray(original[property])) {
          return isEqual(original[property], changes[property]);
        }
        return changes[property] === original[property];
      });
      this.canSubmitForm = isDifferentFromOriginal;
    }
    this._cdr.markForCheck();
  }

  private _handleReasonSub(reason: ICheckinReason): void {
    this.data.original = { ...reason };
    this.data.changes = { ...reason };

    if (reason.name) {
      this.nameControl.setValue(reason.name);
    }
    if (reason.pointReward) {
      this.pointsControl.setValue(Number(reason.pointReward));
    }
    if (reason.showAbsentees) {
      this.absenteesControl.setValue(reason.showAbsentees);
    }
    if (reason.blockNoAccess) {
      this.noAccessControl.setValue(reason.blockNoAccess);
    }
    if (reason.color) {
      this.colorControl.setValue(reason.color);
    }
    if (reason.consequenceId) {
      this.consControl.setValue(reason.consequenceId);
    }
    if (reason.allowCheckout) {
      this.checkoutControl.setValue(reason.allowCheckout);
    }
    if (reason.selfCheckIn) this.qrCodeControl.setValue(reason.selfCheckIn);
    if (reason.allowMultipleCheckins) {
      this.multipleCheckinsControl.setValue(reason.allowMultipleCheckins);
    }
    if (reason.enableStudentPhoto) {
      this.enableStudentPhotoControl.setValue(reason.enableStudentPhoto);
    }

    this.restrictionsControl.setValue({
      [FormRestrictionTabs.Select]: null,
      [FormRestrictionTabs.Role]: reason.roles || [],
      [FormRestrictionTabs.Lists]: reason.userLists || [],
      [FormRestrictionTabs.Stickers]: reason.stickerIds || [],
      [FormRestrictionTabs.Grades]: reason.grades || [],
      [FormRestrictionTabs.Groups]: reason.groupHashes || [],
      [FormRestrictionTabs.Reasons]: reason.restrictedCheckinReasonId || null,
      [FormRestrictionTabs.MembershipList]: reason.membershipList || null,
    });

    if (reason.availableInKiosk) {
      this.kioskControl.setValue(reason.availableInKiosk);
    }

    this._membershipListId = reason.membershipList?.id;

    this.data.isLoading = false;
    this._cdr.markForCheck();
  }

  /**
   * Handle On Click Delete
   */
  public async handleOnClickDelete(): Promise<void> {
    if (!this.data.original) return;
    const { name, id } = this.data.original;
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this.isNewReason
            ? `Are you sure you want to discard this new reason?`
            : `Are you sure you want to delete ${name}?`,
          deleteBtn: 'Delete',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async res => {
      if (!res) return;
      if (res.confirmed) {
        if (!this.isNewReason) {
          await this._checkinService.deleteReason(id);
        }
        this._cdr.markForCheck();
        if (this.isNewReason) {
          this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        } else {
          this._modalOverlayRef.close(
            ModalOverlayServiceCloseEventType.SUBMIT,
            {
              deleted: true,
            },
          );
        }
      }
    });
  }

  /**
   * Handle On Click Save
   */
  public async handleOnClickSave(): Promise<void> {
    const { changes } = this.data;
    if (!this.canSubmitForm) return;
    if (
      changes.roles?.length === 0 &&
      changes.userLists?.length === 0 &&
      changes.stickerIds?.length === 0 &&
      changes.grades?.length === 0 &&
      changes.groupHashes?.length === 0 &&
      !changes.restrictedCheckinReasonId &&
      changes.membershipList?.memberCount === 0
    ) {
      this._systemAlertSnackBar.warning(
        'Please select at least one restriction',
      );
      return;
    }
    try {
      const updatedReason = await this._checkinService.updateReason(
        this.data.changes,
      );
      this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
        isNewReason: this.isNewReason,
        reason: updatedReason,
      });
    } catch (error) {
      this._systemAlertSnackBar.error(error);
    }
  }

  public async openQrViewer() {
    const url = await this._qrCodeService.createCheckinReasonQrCode(
      this.data.original.id,
    );
    this._modalOverlay.open(ImageViewerModalComponent, {
      data: {
        imageUrl: url,
        title: 'Print QR Code',
        headerColor: ModalOverlayPrimaryHeaderBackground.TEAL,
        buttonColor: ModalOverlayPrimaryHeaderBackground.ORANGE,
      },
    });
  }

  // Google Chrome does not support top frame navigation anymore
  public openImg(link: string) {
    const tab = window.open();
    tab.document.write('<iframe src="' + link + '"style="border:0;"></iframe>');
  }

  public async handleOnClickSticker(id?: number): Promise<void> {
    const { changes } = this.data;
    changes.stickerIds = id ? [id] : [];
    this._validateChanges();
    this._cdr.markForCheck();
  }

  public changeHallPass(id?: number) {
    this.data.changes.hallPassId = id;
    this._validateChanges();
  }

  public changeBehavior(id?: number) {
    this.data.changes.behaviorId = id;
    this._validateChanges();
  }

  onColorChange(hex: string) {
    this.data.changes.color = hex;
    this._validateChanges();
  }

  /**
   * If a hall pass type is deleted, we need to remove it from the checkin reason
   */
  private _handleDeletedHallPassTypes() {
    combineLatest([this._reason$, this.hallPasses$])
      .pipe(
        takeUntil(this._destroyed$),
        filter(([reason, hallPasses]) => {
          return !!reason.hallPassId;
        }),
      )
      .subscribe(([reason, hallPasses]) => {
        const hallPass = hallPasses.find(h => h.value === reason.hallPassId);

        if (!hallPass) {
          this.changeHallPass(null);
        }
      });
  }
}
