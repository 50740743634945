"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveHallPassResponse = exports.ArchiveHallPassRequest = exports.UpdateHallPassResponse = exports.UpdateHallPassRequest = exports.GetHallPassBlackOutWindowsResponse = exports.GetHallPassBlackOutWindowsRequest = exports.DeleteHallPassBlackOutWindowResponse = exports.DeleteHallPassBlackOutWindowRequest = exports.UpdateHallPassBlackOutWindowResponse = exports.UpdateHallPassBlackOutWindowRequest = exports.CheckHallPassValidityResponse = exports.CheckHallPassValidityError = exports.CheckHallPassValidityRequest = exports.GetMembershipListsResponse = exports.GetMembershipListsRequest = exports.GetHallPassesForRecipientResponse = exports.GetHallPassesForRecipientRequest = exports.GetHallPassesResponse = exports.GetHallPassesRequest = exports.StreamHallPassWithTypeItem = exports.GetMingaHallPassTypesCountsResponse = exports.GetMingaHallPassTypesCountsRequest = exports.AddDefaultHallPassTypesResponse = exports.AddDefaultHallPassTypesRequest = exports.GetMingaHallPassTypesResponse = exports.GetMingaHallPassTypesRequest = exports.GetMingaHallPassTypeResponse = exports.GetMingaHallPassTypeRequest = exports.CancelHallPassResponse = exports.CancelHallPassRequest = exports.StartHallPassResponse = exports.StartHallPassRequest = exports.ApproveHallPassResponse = exports.ApproveHallPassRequest = exports.ExpireHallPassResponse = exports.ExpireHallPassRequest = exports.CreateHallPassResponse = exports.CreateHallPassRequest = exports.DeleteHallPassTypeResponse = exports.DeleteHallPassTypeRequest = exports.UpdateHallPassTypeResponse = exports.UpdateHallPassTypeRequest = exports.HallPassBlackOutSchedule = exports.HallPassBlackOut = exports.HallPassWithType = exports.EditableHallPass = exports.HallPass = exports.HallPassType = void 0;
const jspb = require("google-protobuf");
const google_protobuf_wrappers_pb = require("minga/proto/google/protobuf/wrappers_pb");
const image_image_pb = require("minga/proto/image/image_pb");
const common_date_pb = require("minga/proto/common/date_pb");
const common_stream_pb = require("minga/proto/common/stream_pb");
const gateway_person_view_pb = require("minga/proto/gateway/person_view_pb");
const restriction_restriction_pb = require("minga/proto/restriction/restriction_pb");
const membership_list_membership_list_pb = require("minga/proto/membership_list/membership_list_pb");
const student_schedule_student_schedule_pb = require("minga/proto/student_schedule/student_schedule_pb");
class HallPassType extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return HallPassType.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!HallPassType} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            asset: (f = msg.getAsset()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
            bannerHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
            bannerReference: jspb.Message.getFieldWithDefault(msg, 6, ""),
            color: jspb.Message.getFieldWithDefault(msg, 7, ""),
            createdBy: jspb.Message.getFieldWithDefault(msg, 8, 0),
            mingaId: jspb.Message.getFieldWithDefault(msg, 9, 0),
            active: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
            selfIssue: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
            priority: jspb.Message.getFieldWithDefault(msg, 12, 0),
            defaultHallPassTime: jspb.Message.getFieldWithDefault(msg, 13, 0),
            membershipListId: jspb.Message.getFieldWithDefault(msg, 14, 0),
            maxSimultaneousPasses: jspb.Message.getFieldWithDefault(msg, 15, 0),
            ignoreLimits: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
            ignoreBlackout: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
            membershipList: (f = msg.getMembershipList()) && membership_list_membership_list_pb.MembershipList.toObject(includeInstance, f),
            requireTeacherApproval: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
            restriction: (f = msg.getRestriction()) && restriction_restriction_pb.Restriction.toObject(includeInstance, f),
            dailyPassLimit: jspb.Message.getFieldWithDefault(msg, 21, 0),
            manuallyEndPass: (f = msg.getManuallyEndPass()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
            studentCanEndPass: (f = msg.getStudentCanEndPass()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
            canEndOnMobile: (f = msg.getCanEndOnMobile()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
            availableInKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 25, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!HallPassType}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new HallPassType;
        return HallPassType.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!HallPassType} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!HallPassType}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setName(value2);
                    break;
                case 4:
                    var value4 = new image_image_pb.ImageInfo;
                    reader.readMessage(value4, image_image_pb.ImageInfo.deserializeBinaryFromReader);
                    msg.setAsset(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setBannerHash(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setBannerReference(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setColor(value7);
                    break;
                case 8:
                    var value8 = /** @type {number} */ (reader.readInt32());
                    msg.setCreatedBy(value8);
                    break;
                case 9:
                    var value9 = /** @type {number} */ (reader.readInt32());
                    msg.setMingaId(value9);
                    break;
                case 10:
                    var value10 = /** @type {boolean} */ (reader.readBool());
                    msg.setActive(value10);
                    break;
                case 11:
                    var value11 = /** @type {boolean} */ (reader.readBool());
                    msg.setSelfIssue(value11);
                    break;
                case 12:
                    var value12 = /** @type {number} */ (reader.readInt32());
                    msg.setPriority(value12);
                    break;
                case 13:
                    var value13 = /** @type {number} */ (reader.readInt32());
                    msg.setDefaultHallPassTime(value13);
                    break;
                case 14:
                    var value14 = /** @type {number} */ (reader.readInt32());
                    msg.setMembershipListId(value14);
                    break;
                case 15:
                    var value15 = /** @type {number} */ (reader.readInt32());
                    msg.setMaxSimultaneousPasses(value15);
                    break;
                case 16:
                    var value16 = /** @type {boolean} */ (reader.readBool());
                    msg.setIgnoreLimits(value16);
                    break;
                case 17:
                    var value17 = /** @type {boolean} */ (reader.readBool());
                    msg.setIgnoreBlackout(value17);
                    break;
                case 18:
                    var value18 = new membership_list_membership_list_pb.MembershipList;
                    reader.readMessage(value18, membership_list_membership_list_pb.MembershipList.deserializeBinaryFromReader);
                    msg.setMembershipList(value18);
                    break;
                case 19:
                    var value19 = /** @type {boolean} */ (reader.readBool());
                    msg.setRequireTeacherApproval(value19);
                    break;
                case 20:
                    var value20 = new restriction_restriction_pb.Restriction;
                    reader.readMessage(value20, restriction_restriction_pb.Restriction.deserializeBinaryFromReader);
                    msg.setRestriction(value20);
                    break;
                case 21:
                    var value21 = /** @type {number} */ (reader.readInt32());
                    msg.setDailyPassLimit(value21);
                    break;
                case 22:
                    var value22 = new google_protobuf_wrappers_pb.BoolValue;
                    reader.readMessage(value22, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
                    msg.setManuallyEndPass(value22);
                    break;
                case 23:
                    var value23 = new google_protobuf_wrappers_pb.BoolValue;
                    reader.readMessage(value23, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
                    msg.setStudentCanEndPass(value23);
                    break;
                case 24:
                    var value24 = new google_protobuf_wrappers_pb.BoolValue;
                    reader.readMessage(value24, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
                    msg.setCanEndOnMobile(value24);
                    break;
                case 25:
                    var value25 = /** @type {boolean} */ (reader.readBool());
                    msg.setAvailableInKiosk(value25);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!HallPassType} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getAsset();
        if (f != null) {
            writer.writeMessage(4, f, image_image_pb.ImageInfo.serializeBinaryToWriter);
        }
        f = message.getBannerHash();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getBannerReference();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getColor();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getCreatedBy();
        if (f !== 0) {
            writer.writeInt32(8, f);
        }
        f = message.getMingaId();
        if (f !== 0) {
            writer.writeInt32(9, f);
        }
        f = message.getActive();
        if (f) {
            writer.writeBool(10, f);
        }
        f = message.getSelfIssue();
        if (f) {
            writer.writeBool(11, f);
        }
        f = message.getPriority();
        if (f !== 0) {
            writer.writeInt32(12, f);
        }
        f = message.getDefaultHallPassTime();
        if (f !== 0) {
            writer.writeInt32(13, f);
        }
        f = message.getMembershipListId();
        if (f !== 0) {
            writer.writeInt32(14, f);
        }
        f = message.getMaxSimultaneousPasses();
        if (f !== 0) {
            writer.writeInt32(15, f);
        }
        f = message.getIgnoreLimits();
        if (f) {
            writer.writeBool(16, f);
        }
        f = message.getIgnoreBlackout();
        if (f) {
            writer.writeBool(17, f);
        }
        f = message.getMembershipList();
        if (f != null) {
            writer.writeMessage(18, f, membership_list_membership_list_pb.MembershipList.serializeBinaryToWriter);
        }
        f = message.getRequireTeacherApproval();
        if (f) {
            writer.writeBool(19, f);
        }
        f = message.getRestriction();
        if (f != null) {
            writer.writeMessage(20, f, restriction_restriction_pb.Restriction.serializeBinaryToWriter);
        }
        f = message.getDailyPassLimit();
        if (f !== 0) {
            writer.writeInt32(21, f);
        }
        f = message.getManuallyEndPass();
        if (f != null) {
            writer.writeMessage(22, f, google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter);
        }
        f = message.getStudentCanEndPass();
        if (f != null) {
            writer.writeMessage(23, f, google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter);
        }
        f = message.getCanEndOnMobile();
        if (f != null) {
            writer.writeMessage(24, f, google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter);
        }
        f = message.getAvailableInKiosk();
        if (f) {
            writer.writeBool(25, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        HallPassType.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string name = 2;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional ImageInfo asset = 4;
     * @return {?ImageInfo}
     */
    getAsset() {
        return /** @type{?ImageInfo} */ (jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
    }
    /** @param {?ImageInfo|undefined} value */
    setAsset(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAsset() {
        this.setAsset(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAsset() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional string banner_hash = 5;
     * @return {string}
     */
    getBannerHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setBannerHash(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional string banner_reference = 6;
     * @return {string}
     */
    getBannerReference() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setBannerReference(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional string color = 7;
     * @return {string}
     */
    getColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setColor(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * optional int32 created_by = 8;
     * @return {number}
     */
    getCreatedBy() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
    }
    ;
    /** @param {number} value */
    setCreatedBy(value) {
        jspb.Message.setProto3IntField(this, 8, value);
    }
    /**
     * optional int32 minga_id = 9;
     * @return {number}
     */
    getMingaId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
    }
    ;
    /** @param {number} value */
    setMingaId(value) {
        jspb.Message.setProto3IntField(this, 9, value);
    }
    /**
     * optional bool active = 10;
     * @return {boolean}
     */
    getActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
    }
    ;
    /** @param {boolean} value */
    setActive(value) {
        jspb.Message.setProto3BooleanField(this, 10, value);
    }
    /**
     * optional bool self_issue = 11;
     * @return {boolean}
     */
    getSelfIssue() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
    }
    ;
    /** @param {boolean} value */
    setSelfIssue(value) {
        jspb.Message.setProto3BooleanField(this, 11, value);
    }
    /**
     * optional int32 priority = 12;
     * @return {number}
     */
    getPriority() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
    }
    ;
    /** @param {number} value */
    setPriority(value) {
        jspb.Message.setProto3IntField(this, 12, value);
    }
    /**
     * optional int32 default_hall_pass_time = 13;
     * @return {number}
     */
    getDefaultHallPassTime() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
    }
    ;
    /** @param {number} value */
    setDefaultHallPassTime(value) {
        jspb.Message.setProto3IntField(this, 13, value);
    }
    /**
     * optional int32 membership_list_id = 14;
     * @return {number}
     */
    getMembershipListId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
    }
    ;
    /** @param {number} value */
    setMembershipListId(value) {
        jspb.Message.setProto3IntField(this, 14, value);
    }
    /**
     * optional int32 max_simultaneous_passes = 15;
     * @return {number}
     */
    getMaxSimultaneousPasses() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
    }
    ;
    /** @param {number} value */
    setMaxSimultaneousPasses(value) {
        jspb.Message.setProto3IntField(this, 15, value);
    }
    /**
     * optional bool ignore_limits = 16;
     * @return {boolean}
     */
    getIgnoreLimits() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
    }
    ;
    /** @param {boolean} value */
    setIgnoreLimits(value) {
        jspb.Message.setProto3BooleanField(this, 16, value);
    }
    /**
     * optional bool ignore_blackout = 17;
     * @return {boolean}
     */
    getIgnoreBlackout() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
    }
    ;
    /** @param {boolean} value */
    setIgnoreBlackout(value) {
        jspb.Message.setProto3BooleanField(this, 17, value);
    }
    /**
     * optional membership_list.MembershipList membership_list = 18;
     * @return {?MembershipList}
     */
    getMembershipList() {
        return /** @type{?MembershipList} */ (jspb.Message.getWrapperField(this, membership_list_membership_list_pb.MembershipList, 18));
    }
    /** @param {?MembershipList|undefined} value */
    setMembershipList(value) {
        jspb.Message.setWrapperField(this, 18, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearMembershipList() {
        this.setMembershipList(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasMembershipList() {
        return jspb.Message.getField(this, 18) != null;
    }
    /**
     * optional bool require_teacher_approval = 19;
     * @return {boolean}
     */
    getRequireTeacherApproval() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
    }
    ;
    /** @param {boolean} value */
    setRequireTeacherApproval(value) {
        jspb.Message.setProto3BooleanField(this, 19, value);
    }
    /**
     * optional restriction.Restriction restriction = 20;
     * @return {?Restriction}
     */
    getRestriction() {
        return /** @type{?Restriction} */ (jspb.Message.getWrapperField(this, restriction_restriction_pb.Restriction, 20));
    }
    /** @param {?Restriction|undefined} value */
    setRestriction(value) {
        jspb.Message.setWrapperField(this, 20, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearRestriction() {
        this.setRestriction(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasRestriction() {
        return jspb.Message.getField(this, 20) != null;
    }
    /**
     * optional int32 daily_pass_limit = 21;
     * @return {number}
     */
    getDailyPassLimit() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
    }
    ;
    /** @param {number} value */
    setDailyPassLimit(value) {
        jspb.Message.setProto3IntField(this, 21, value);
    }
    /**
     * optional google.protobuf.BoolValue manually_end_pass = 22;
     * @return {?BoolValue}
     */
    getManuallyEndPass() {
        return /** @type{?BoolValue} */ (jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 22));
    }
    /** @param {?BoolValue|undefined} value */
    setManuallyEndPass(value) {
        jspb.Message.setWrapperField(this, 22, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearManuallyEndPass() {
        this.setManuallyEndPass(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasManuallyEndPass() {
        return jspb.Message.getField(this, 22) != null;
    }
    /**
     * optional google.protobuf.BoolValue student_can_end_pass = 23;
     * @return {?BoolValue}
     */
    getStudentCanEndPass() {
        return /** @type{?BoolValue} */ (jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 23));
    }
    /** @param {?BoolValue|undefined} value */
    setStudentCanEndPass(value) {
        jspb.Message.setWrapperField(this, 23, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStudentCanEndPass() {
        this.setStudentCanEndPass(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStudentCanEndPass() {
        return jspb.Message.getField(this, 23) != null;
    }
    /**
     * optional google.protobuf.BoolValue can_end_on_mobile = 24;
     * @return {?BoolValue}
     */
    getCanEndOnMobile() {
        return /** @type{?BoolValue} */ (jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 24));
    }
    /** @param {?BoolValue|undefined} value */
    setCanEndOnMobile(value) {
        jspb.Message.setWrapperField(this, 24, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearCanEndOnMobile() {
        this.setCanEndOnMobile(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasCanEndOnMobile() {
        return jspb.Message.getField(this, 24) != null;
    }
    /**
     * optional bool available_in_kiosk = 25;
     * @return {boolean}
     */
    getAvailableInKiosk() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
    }
    ;
    /** @param {boolean} value */
    setAvailableInKiosk(value) {
        jspb.Message.setProto3BooleanField(this, 25, value);
    }
}
exports.HallPassType = HallPassType;
HallPassType.displayName = "HallPassType";
class HallPass extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, HallPass.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return HallPass.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!HallPass} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            typeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
            recipientPersonView: (f = msg.getRecipientPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
            authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
            startDateTime: (f = msg.getStartDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            expiredDateTime: (f = msg.getExpiredDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            endedByPersonView: (f = msg.getEndedByPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
            typeInfo: (f = msg.getTypeInfo()) && HallPassType.toObject(includeInstance, f),
            note: jspb.Message.getFieldWithDefault(msg, 9, ""),
            endedTime: (f = msg.getEndedTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            studentCreated: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
            startDateString: jspb.Message.getFieldWithDefault(msg, 12, ""),
            endDateString: jspb.Message.getFieldWithDefault(msg, 13, ""),
            approvalDate: (f = msg.getApprovalDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
            approvedByPersonView: (f = msg.getApprovedByPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
            isKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
            noteToRequester: jspb.Message.getFieldWithDefault(msg, 17, ""),
            requestedByPersonView: (f = msg.getRequestedByPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
            deniedByReason: jspb.Message.getFieldWithDefault(msg, 19, ""),
            deniedByPerson: jspb.Message.getFieldWithDefault(msg, 20, ""),
            deniedByError: jspb.Message.getFieldWithDefault(msg, 21, 0),
            deniedTime: (f = msg.getDeniedTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            status: jspb.Message.getFieldWithDefault(msg, 23, ""),
            createdBy: jspb.Message.getFieldWithDefault(msg, 24, ""),
            currentClass: (f = msg.getCurrentClass()) && student_schedule_student_schedule_pb.StudentSection.toObject(includeInstance, f),
            currentClassesList: jspb.Message.toObjectList(msg.getCurrentClassesList(), student_schedule_student_schedule_pb.StudentSection.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!HallPass}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new HallPass;
        return HallPass.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!HallPass} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!HallPass}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readInt32());
                    msg.setTypeId(value2);
                    break;
                case 3:
                    var value3 = new gateway_person_view_pb.PersonViewMinimal;
                    reader.readMessage(value3, gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
                    msg.setRecipientPersonView(value3);
                    break;
                case 4:
                    var value4 = new gateway_person_view_pb.PersonViewMinimal;
                    reader.readMessage(value4, gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
                    msg.setAuthorPersonView(value4);
                    break;
                case 5:
                    var value5 = new common_date_pb.DateTime;
                    reader.readMessage(value5, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setStartDateTime(value5);
                    break;
                case 6:
                    var value6 = new common_date_pb.DateTime;
                    reader.readMessage(value6, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setExpiredDateTime(value6);
                    break;
                case 7:
                    var value7 = new gateway_person_view_pb.PersonViewMinimal;
                    reader.readMessage(value7, gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
                    msg.setEndedByPersonView(value7);
                    break;
                case 8:
                    var value8 = new HallPassType;
                    reader.readMessage(value8, HallPassType.deserializeBinaryFromReader);
                    msg.setTypeInfo(value8);
                    break;
                case 9:
                    var value9 = /** @type {string} */ (reader.readString());
                    msg.setNote(value9);
                    break;
                case 10:
                    var value10 = new common_date_pb.DateTime;
                    reader.readMessage(value10, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setEndedTime(value10);
                    break;
                case 11:
                    var value11 = /** @type {boolean} */ (reader.readBool());
                    msg.setStudentCreated(value11);
                    break;
                case 12:
                    var value12 = /** @type {string} */ (reader.readString());
                    msg.setStartDateString(value12);
                    break;
                case 13:
                    var value13 = /** @type {string} */ (reader.readString());
                    msg.setEndDateString(value13);
                    break;
                case 14:
                    var value14 = new common_date_pb.DateTime;
                    reader.readMessage(value14, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setApprovalDate(value14);
                    break;
                case 15:
                    var value15 = new gateway_person_view_pb.PersonViewMinimal;
                    reader.readMessage(value15, gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
                    msg.setApprovedByPersonView(value15);
                    break;
                case 16:
                    var value16 = /** @type {boolean} */ (reader.readBool());
                    msg.setIsKiosk(value16);
                    break;
                case 17:
                    var value17 = /** @type {string} */ (reader.readString());
                    msg.setNoteToRequester(value17);
                    break;
                case 18:
                    var value18 = new gateway_person_view_pb.PersonViewMinimal;
                    reader.readMessage(value18, gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
                    msg.setRequestedByPersonView(value18);
                    break;
                case 19:
                    var value19 = /** @type {string} */ (reader.readString());
                    msg.setDeniedByReason(value19);
                    break;
                case 20:
                    var value20 = /** @type {string} */ (reader.readString());
                    msg.setDeniedByPerson(value20);
                    break;
                case 21:
                    var value21 = /** @type {number} */ (reader.readInt32());
                    msg.setDeniedByError(value21);
                    break;
                case 22:
                    var value22 = new common_date_pb.DateTime;
                    reader.readMessage(value22, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setDeniedTime(value22);
                    break;
                case 23:
                    var value23 = /** @type {string} */ (reader.readString());
                    msg.setStatus(value23);
                    break;
                case 24:
                    var value24 = /** @type {string} */ (reader.readString());
                    msg.setCreatedBy(value24);
                    break;
                case 25:
                    var value25 = new student_schedule_student_schedule_pb.StudentSection;
                    reader.readMessage(value25, student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
                    msg.setCurrentClass(value25);
                    break;
                case 26:
                    var value26 = new student_schedule_student_schedule_pb.StudentSection;
                    reader.readMessage(value26, student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
                    msg.addCurrentClasses(value26);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!HallPass} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getTypeId();
        if (f !== 0) {
            writer.writeInt32(2, f);
        }
        f = message.getRecipientPersonView();
        if (f != null) {
            writer.writeMessage(3, f, gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter);
        }
        f = message.getAuthorPersonView();
        if (f != null) {
            writer.writeMessage(4, f, gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter);
        }
        f = message.getStartDateTime();
        if (f != null) {
            writer.writeMessage(5, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getExpiredDateTime();
        if (f != null) {
            writer.writeMessage(6, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getEndedByPersonView();
        if (f != null) {
            writer.writeMessage(7, f, gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter);
        }
        f = message.getTypeInfo();
        if (f != null) {
            writer.writeMessage(8, f, HallPassType.serializeBinaryToWriter);
        }
        f = message.getNote();
        if (f.length > 0) {
            writer.writeString(9, f);
        }
        f = message.getEndedTime();
        if (f != null) {
            writer.writeMessage(10, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getStudentCreated();
        if (f) {
            writer.writeBool(11, f);
        }
        f = message.getStartDateString();
        if (f.length > 0) {
            writer.writeString(12, f);
        }
        f = message.getEndDateString();
        if (f.length > 0) {
            writer.writeString(13, f);
        }
        f = message.getApprovalDate();
        if (f != null) {
            writer.writeMessage(14, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getApprovedByPersonView();
        if (f != null) {
            writer.writeMessage(15, f, gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter);
        }
        f = message.getIsKiosk();
        if (f) {
            writer.writeBool(16, f);
        }
        f = message.getNoteToRequester();
        if (f.length > 0) {
            writer.writeString(17, f);
        }
        f = message.getRequestedByPersonView();
        if (f != null) {
            writer.writeMessage(18, f, gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter);
        }
        f = message.getDeniedByReason();
        if (f.length > 0) {
            writer.writeString(19, f);
        }
        f = message.getDeniedByPerson();
        if (f.length > 0) {
            writer.writeString(20, f);
        }
        f = message.getDeniedByError();
        if (f !== 0) {
            writer.writeInt32(21, f);
        }
        f = message.getDeniedTime();
        if (f != null) {
            writer.writeMessage(22, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getStatus();
        if (f.length > 0) {
            writer.writeString(23, f);
        }
        f = message.getCreatedBy();
        if (f.length > 0) {
            writer.writeString(24, f);
        }
        f = message.getCurrentClass();
        if (f != null) {
            writer.writeMessage(25, f, student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter);
        }
        f = message.getCurrentClassesList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(26, f, student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        HallPass.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional int32 type_id = 2;
     * @return {number}
     */
    getTypeId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setTypeId(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
    /**
     * optional PersonViewMinimal recipient_person_view = 3;
     * @return {?PersonViewMinimal}
     */
    getRecipientPersonView() {
        return /** @type{?PersonViewMinimal} */ (jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 3));
    }
    /** @param {?PersonViewMinimal|undefined} value */
    setRecipientPersonView(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearRecipientPersonView() {
        this.setRecipientPersonView(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasRecipientPersonView() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional PersonViewMinimal author_person_view = 4;
     * @return {?PersonViewMinimal}
     */
    getAuthorPersonView() {
        return /** @type{?PersonViewMinimal} */ (jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 4));
    }
    /** @param {?PersonViewMinimal|undefined} value */
    setAuthorPersonView(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAuthorPersonView() {
        this.setAuthorPersonView(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAuthorPersonView() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional minga.common.DateTime start_date_time = 5;
     * @return {?DateTime}
     */
    getStartDateTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
    }
    /** @param {?DateTime|undefined} value */
    setStartDateTime(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStartDateTime() {
        this.setStartDateTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStartDateTime() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.DateTime expired_date_time = 6;
     * @return {?DateTime}
     */
    getExpiredDateTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
    }
    /** @param {?DateTime|undefined} value */
    setExpiredDateTime(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearExpiredDateTime() {
        this.setExpiredDateTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasExpiredDateTime() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional PersonViewMinimal ended_by_person_view = 7;
     * @return {?PersonViewMinimal}
     */
    getEndedByPersonView() {
        return /** @type{?PersonViewMinimal} */ (jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 7));
    }
    /** @param {?PersonViewMinimal|undefined} value */
    setEndedByPersonView(value) {
        jspb.Message.setWrapperField(this, 7, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearEndedByPersonView() {
        this.setEndedByPersonView(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasEndedByPersonView() {
        return jspb.Message.getField(this, 7) != null;
    }
    /**
     * optional HallPassType type_info = 8;
     * @return {?HallPassType}
     */
    getTypeInfo() {
        return /** @type{?HallPassType} */ (jspb.Message.getWrapperField(this, HallPassType, 8));
    }
    /** @param {?HallPassType|undefined} value */
    setTypeInfo(value) {
        jspb.Message.setWrapperField(this, 8, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearTypeInfo() {
        this.setTypeInfo(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasTypeInfo() {
        return jspb.Message.getField(this, 8) != null;
    }
    /**
     * optional string note = 9;
     * @return {string}
     */
    getNote() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
    }
    ;
    /** @param {string} value */
    setNote(value) {
        jspb.Message.setProto3StringField(this, 9, value);
    }
    /**
     * optional minga.common.DateTime ended_time = 10;
     * @return {?DateTime}
     */
    getEndedTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 10));
    }
    /** @param {?DateTime|undefined} value */
    setEndedTime(value) {
        jspb.Message.setWrapperField(this, 10, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearEndedTime() {
        this.setEndedTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasEndedTime() {
        return jspb.Message.getField(this, 10) != null;
    }
    /**
     * optional bool student_created = 11;
     * @return {boolean}
     */
    getStudentCreated() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
    }
    ;
    /** @param {boolean} value */
    setStudentCreated(value) {
        jspb.Message.setProto3BooleanField(this, 11, value);
    }
    /**
     * optional string start_date_string = 12;
     * @return {string}
     */
    getStartDateString() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
    }
    ;
    /** @param {string} value */
    setStartDateString(value) {
        jspb.Message.setProto3StringField(this, 12, value);
    }
    /**
     * optional string end_date_string = 13;
     * @return {string}
     */
    getEndDateString() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
    }
    ;
    /** @param {string} value */
    setEndDateString(value) {
        jspb.Message.setProto3StringField(this, 13, value);
    }
    /**
     * optional minga.common.DateTime approval_date = 14;
     * @return {?DateTime}
     */
    getApprovalDate() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 14));
    }
    /** @param {?DateTime|undefined} value */
    setApprovalDate(value) {
        jspb.Message.setWrapperField(this, 14, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearApprovalDate() {
        this.setApprovalDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasApprovalDate() {
        return jspb.Message.getField(this, 14) != null;
    }
    /**
     * optional PersonViewMinimal approved_by_person_view = 15;
     * @return {?PersonViewMinimal}
     */
    getApprovedByPersonView() {
        return /** @type{?PersonViewMinimal} */ (jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 15));
    }
    /** @param {?PersonViewMinimal|undefined} value */
    setApprovedByPersonView(value) {
        jspb.Message.setWrapperField(this, 15, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearApprovedByPersonView() {
        this.setApprovedByPersonView(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasApprovedByPersonView() {
        return jspb.Message.getField(this, 15) != null;
    }
    /**
     * optional bool is_kiosk = 16;
     * @return {boolean}
     */
    getIsKiosk() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
    }
    ;
    /** @param {boolean} value */
    setIsKiosk(value) {
        jspb.Message.setProto3BooleanField(this, 16, value);
    }
    /**
     * optional string note_to_requester = 17;
     * @return {string}
     */
    getNoteToRequester() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
    }
    ;
    /** @param {string} value */
    setNoteToRequester(value) {
        jspb.Message.setProto3StringField(this, 17, value);
    }
    /**
     * optional PersonViewMinimal requested_by_person_view = 18;
     * @return {?PersonViewMinimal}
     */
    getRequestedByPersonView() {
        return /** @type{?PersonViewMinimal} */ (jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 18));
    }
    /** @param {?PersonViewMinimal|undefined} value */
    setRequestedByPersonView(value) {
        jspb.Message.setWrapperField(this, 18, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearRequestedByPersonView() {
        this.setRequestedByPersonView(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasRequestedByPersonView() {
        return jspb.Message.getField(this, 18) != null;
    }
    /**
     * optional string denied_by_reason = 19;
     * @return {string}
     */
    getDeniedByReason() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
    }
    ;
    /** @param {string} value */
    setDeniedByReason(value) {
        jspb.Message.setProto3StringField(this, 19, value);
    }
    /**
     * optional string denied_by_person = 20;
     * @return {string}
     */
    getDeniedByPerson() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
    }
    ;
    /** @param {string} value */
    setDeniedByPerson(value) {
        jspb.Message.setProto3StringField(this, 20, value);
    }
    /**
     * optional int32 denied_by_error = 21;
     * @return {number}
     */
    getDeniedByError() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
    }
    ;
    /** @param {number} value */
    setDeniedByError(value) {
        jspb.Message.setProto3IntField(this, 21, value);
    }
    /**
     * optional minga.common.DateTime denied_time = 22;
     * @return {?DateTime}
     */
    getDeniedTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 22));
    }
    /** @param {?DateTime|undefined} value */
    setDeniedTime(value) {
        jspb.Message.setWrapperField(this, 22, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDeniedTime() {
        this.setDeniedTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDeniedTime() {
        return jspb.Message.getField(this, 22) != null;
    }
    /**
     * optional string status = 23;
     * @return {string}
     */
    getStatus() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
    }
    ;
    /** @param {string} value */
    setStatus(value) {
        jspb.Message.setProto3StringField(this, 23, value);
    }
    /**
     * optional string created_by = 24;
     * @return {string}
     */
    getCreatedBy() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
    }
    ;
    /** @param {string} value */
    setCreatedBy(value) {
        jspb.Message.setProto3StringField(this, 24, value);
    }
    /**
     * optional minga.student_schedule.StudentSection current_class = 25;
     * @return {?StudentSection}
     */
    getCurrentClass() {
        return /** @type{?StudentSection} */ (jspb.Message.getWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 25));
    }
    /** @param {?StudentSection|undefined} value */
    setCurrentClass(value) {
        jspb.Message.setWrapperField(this, 25, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearCurrentClass() {
        this.setCurrentClass(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasCurrentClass() {
        return jspb.Message.getField(this, 25) != null;
    }
    /**
     * repeated minga.student_schedule.StudentSection current_classes = 26;
     * @return {!Array<!StudentSection>}
     */
    getCurrentClassesList() {
        return /** @type{!Array<!StudentSection>} */ (jspb.Message.getRepeatedWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 26));
    }
    /** @param {!Array<!StudentSection>} value */
    setCurrentClassesList(value) {
        jspb.Message.setRepeatedWrapperField(this, 26, value);
    }
    /**
     * @param {!StudentSection=} opt_value
     * @param {number=} opt_index
     * @return {!StudentSection}
     */
    addCurrentClasses(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, student_schedule_student_schedule_pb.StudentSection, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearCurrentClassesList() {
        this.setCurrentClassesList([]);
    }
}
exports.HallPass = HallPass;
HallPass.displayName = "HallPass";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
HallPass.repeatedFields_ = [26];
class EditableHallPass extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return EditableHallPass.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!EditableHallPass} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPass: (f = msg.getHallPass()) && HallPass.toObject(includeInstance, f),
            manuallyUpdatedAt: (f = msg.getManuallyUpdatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!EditableHallPass}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new EditableHallPass;
        return EditableHallPass.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!EditableHallPass} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!EditableHallPass}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPass;
                    reader.readMessage(value1, HallPass.deserializeBinaryFromReader);
                    msg.setHallPass(value1);
                    break;
                case 2:
                    var value2 = new common_date_pb.DateTime;
                    reader.readMessage(value2, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setManuallyUpdatedAt(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!EditableHallPass} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPass();
        if (f != null) {
            writer.writeMessage(1, f, HallPass.serializeBinaryToWriter);
        }
        f = message.getManuallyUpdatedAt();
        if (f != null) {
            writer.writeMessage(2, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        EditableHallPass.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional HallPass hall_pass = 1;
     * @return {?HallPass}
     */
    getHallPass() {
        return /** @type{?HallPass} */ (jspb.Message.getWrapperField(this, HallPass, 1));
    }
    /** @param {?HallPass|undefined} value */
    setHallPass(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPass() {
        this.setHallPass(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPass() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional minga.common.DateTime manually_updated_at = 2;
     * @return {?DateTime}
     */
    getManuallyUpdatedAt() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
    }
    /** @param {?DateTime|undefined} value */
    setManuallyUpdatedAt(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearManuallyUpdatedAt() {
        this.setManuallyUpdatedAt(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasManuallyUpdatedAt() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.EditableHallPass = EditableHallPass;
EditableHallPass.displayName = "EditableHallPass";
class HallPassWithType extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return HallPassWithType.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!HallPassWithType} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPass: (f = msg.getHallPass()) && HallPass.toObject(includeInstance, f),
            hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f),
            recipientStudentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            recipientStudentIdFilename: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!HallPassWithType}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new HallPassWithType;
        return HallPassWithType.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!HallPassWithType} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!HallPassWithType}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPass;
                    reader.readMessage(value1, HallPass.deserializeBinaryFromReader);
                    msg.setHallPass(value1);
                    break;
                case 2:
                    var value2 = new HallPassType;
                    reader.readMessage(value2, HallPassType.deserializeBinaryFromReader);
                    msg.setHallPassType(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setRecipientStudentId(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setRecipientStudentIdFilename(value4);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!HallPassWithType} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPass();
        if (f != null) {
            writer.writeMessage(1, f, HallPass.serializeBinaryToWriter);
        }
        f = message.getHallPassType();
        if (f != null) {
            writer.writeMessage(2, f, HallPassType.serializeBinaryToWriter);
        }
        f = message.getRecipientStudentId();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getRecipientStudentIdFilename();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        HallPassWithType.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional HallPass hall_pass = 1;
     * @return {?HallPass}
     */
    getHallPass() {
        return /** @type{?HallPass} */ (jspb.Message.getWrapperField(this, HallPass, 1));
    }
    /** @param {?HallPass|undefined} value */
    setHallPass(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPass() {
        this.setHallPass(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPass() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional HallPassType hall_pass_type = 2;
     * @return {?HallPassType}
     */
    getHallPassType() {
        return /** @type{?HallPassType} */ (jspb.Message.getWrapperField(this, HallPassType, 2));
    }
    /** @param {?HallPassType|undefined} value */
    setHallPassType(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPassType() {
        this.setHallPassType(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPassType() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional string recipient_student_id = 3;
     * @return {string}
     */
    getRecipientStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setRecipientStudentId(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string recipient_student_id_filename = 4;
     * @return {string}
     */
    getRecipientStudentIdFilename() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setRecipientStudentIdFilename(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
}
exports.HallPassWithType = HallPassWithType;
HallPassWithType.displayName = "HallPassWithType";
class HallPassBlackOut extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, HallPassBlackOut.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return HallPassBlackOut.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!HallPassBlackOut} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            weekday: jspb.Message.getFieldWithDefault(msg, 2, ""),
            startTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
            endTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
            mingaId: jspb.Message.getFieldWithDefault(msg, 5, 0),
            createdBy: jspb.Message.getFieldWithDefault(msg, 6, 0),
            name: jspb.Message.getFieldWithDefault(msg, 7, ""),
            active: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            blockMonday: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            blockTuesday: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
            blockWednesday: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
            blockThursday: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
            blockFriday: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
            blockSaturday: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
            blockSunday: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
            hallPassBlackOutScheduleList: jspb.Message.toObjectList(msg.getHallPassBlackOutScheduleList(), HallPassBlackOutSchedule.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!HallPassBlackOut}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new HallPassBlackOut;
        return HallPassBlackOut.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!HallPassBlackOut} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!HallPassBlackOut}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setWeekday(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setStartTime(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setEndTime(value4);
                    break;
                case 5:
                    var value5 = /** @type {number} */ (reader.readInt32());
                    msg.setMingaId(value5);
                    break;
                case 6:
                    var value6 = /** @type {number} */ (reader.readInt32());
                    msg.setCreatedBy(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setName(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setActive(value8);
                    break;
                case 9:
                    var value9 = /** @type {boolean} */ (reader.readBool());
                    msg.setBlockMonday(value9);
                    break;
                case 10:
                    var value10 = /** @type {boolean} */ (reader.readBool());
                    msg.setBlockTuesday(value10);
                    break;
                case 11:
                    var value11 = /** @type {boolean} */ (reader.readBool());
                    msg.setBlockWednesday(value11);
                    break;
                case 12:
                    var value12 = /** @type {boolean} */ (reader.readBool());
                    msg.setBlockThursday(value12);
                    break;
                case 13:
                    var value13 = /** @type {boolean} */ (reader.readBool());
                    msg.setBlockFriday(value13);
                    break;
                case 14:
                    var value14 = /** @type {boolean} */ (reader.readBool());
                    msg.setBlockSaturday(value14);
                    break;
                case 15:
                    var value15 = /** @type {boolean} */ (reader.readBool());
                    msg.setBlockSunday(value15);
                    break;
                case 16:
                    var value16 = new HallPassBlackOutSchedule;
                    reader.readMessage(value16, HallPassBlackOutSchedule.deserializeBinaryFromReader);
                    msg.addHallPassBlackOutSchedule(value16);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!HallPassBlackOut} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getWeekday();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getStartTime();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getEndTime();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getMingaId();
        if (f !== 0) {
            writer.writeInt32(5, f);
        }
        f = message.getCreatedBy();
        if (f !== 0) {
            writer.writeInt32(6, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getActive();
        if (f) {
            writer.writeBool(8, f);
        }
        f = message.getBlockMonday();
        if (f) {
            writer.writeBool(9, f);
        }
        f = message.getBlockTuesday();
        if (f) {
            writer.writeBool(10, f);
        }
        f = message.getBlockWednesday();
        if (f) {
            writer.writeBool(11, f);
        }
        f = message.getBlockThursday();
        if (f) {
            writer.writeBool(12, f);
        }
        f = message.getBlockFriday();
        if (f) {
            writer.writeBool(13, f);
        }
        f = message.getBlockSaturday();
        if (f) {
            writer.writeBool(14, f);
        }
        f = message.getBlockSunday();
        if (f) {
            writer.writeBool(15, f);
        }
        f = message.getHallPassBlackOutScheduleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(16, f, HallPassBlackOutSchedule.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        HallPassBlackOut.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string weekday = 2;
     * @return {string}
     */
    getWeekday() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setWeekday(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string start_time = 3;
     * @return {string}
     */
    getStartTime() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setStartTime(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string end_time = 4;
     * @return {string}
     */
    getEndTime() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setEndTime(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional int32 minga_id = 5;
     * @return {number}
     */
    getMingaId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
    }
    ;
    /** @param {number} value */
    setMingaId(value) {
        jspb.Message.setProto3IntField(this, 5, value);
    }
    /**
     * optional int32 created_by = 6;
     * @return {number}
     */
    getCreatedBy() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
    }
    ;
    /** @param {number} value */
    setCreatedBy(value) {
        jspb.Message.setProto3IntField(this, 6, value);
    }
    /**
     * optional string name = 7;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * optional bool active = 8;
     * @return {boolean}
     */
    getActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setActive(value) {
        jspb.Message.setProto3BooleanField(this, 8, value);
    }
    /**
     * optional bool block_monday = 9;
     * @return {boolean}
     */
    getBlockMonday() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
    }
    ;
    /** @param {boolean} value */
    setBlockMonday(value) {
        jspb.Message.setProto3BooleanField(this, 9, value);
    }
    /**
     * optional bool block_tuesday = 10;
     * @return {boolean}
     */
    getBlockTuesday() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
    }
    ;
    /** @param {boolean} value */
    setBlockTuesday(value) {
        jspb.Message.setProto3BooleanField(this, 10, value);
    }
    /**
     * optional bool block_wednesday = 11;
     * @return {boolean}
     */
    getBlockWednesday() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
    }
    ;
    /** @param {boolean} value */
    setBlockWednesday(value) {
        jspb.Message.setProto3BooleanField(this, 11, value);
    }
    /**
     * optional bool block_thursday = 12;
     * @return {boolean}
     */
    getBlockThursday() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
    }
    ;
    /** @param {boolean} value */
    setBlockThursday(value) {
        jspb.Message.setProto3BooleanField(this, 12, value);
    }
    /**
     * optional bool block_friday = 13;
     * @return {boolean}
     */
    getBlockFriday() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
    }
    ;
    /** @param {boolean} value */
    setBlockFriday(value) {
        jspb.Message.setProto3BooleanField(this, 13, value);
    }
    /**
     * optional bool block_saturday = 14;
     * @return {boolean}
     */
    getBlockSaturday() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
    }
    ;
    /** @param {boolean} value */
    setBlockSaturday(value) {
        jspb.Message.setProto3BooleanField(this, 14, value);
    }
    /**
     * optional bool block_sunday = 15;
     * @return {boolean}
     */
    getBlockSunday() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
    }
    ;
    /** @param {boolean} value */
    setBlockSunday(value) {
        jspb.Message.setProto3BooleanField(this, 15, value);
    }
    /**
     * repeated HallPassBlackOutSchedule hall_pass_black_out_schedule = 16;
     * @return {!Array<!HallPassBlackOutSchedule>}
     */
    getHallPassBlackOutScheduleList() {
        return /** @type{!Array<!HallPassBlackOutSchedule>} */ (jspb.Message.getRepeatedWrapperField(this, HallPassBlackOutSchedule, 16));
    }
    /** @param {!Array<!HallPassBlackOutSchedule>} value */
    setHallPassBlackOutScheduleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 16, value);
    }
    /**
     * @param {!HallPassBlackOutSchedule=} opt_value
     * @param {number=} opt_index
     * @return {!HallPassBlackOutSchedule}
     */
    addHallPassBlackOutSchedule(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, HallPassBlackOutSchedule, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHallPassBlackOutScheduleList() {
        this.setHallPassBlackOutScheduleList([]);
    }
}
exports.HallPassBlackOut = HallPassBlackOut;
HallPassBlackOut.displayName = "HallPassBlackOut";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
HallPassBlackOut.repeatedFields_ = [16];
class HallPassBlackOutSchedule extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return HallPassBlackOutSchedule.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!HallPassBlackOutSchedule} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            startTime: jspb.Message.getFieldWithDefault(msg, 1, ""),
            endTime: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!HallPassBlackOutSchedule}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new HallPassBlackOutSchedule;
        return HallPassBlackOutSchedule.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!HallPassBlackOutSchedule} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!HallPassBlackOutSchedule}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setStartTime(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setEndTime(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!HallPassBlackOutSchedule} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStartTime();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getEndTime();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        HallPassBlackOutSchedule.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string start_time = 1;
     * @return {string}
     */
    getStartTime() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setStartTime(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string end_time = 2;
     * @return {string}
     */
    getEndTime() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setEndTime(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
}
exports.HallPassBlackOutSchedule = HallPassBlackOutSchedule;
HallPassBlackOutSchedule.displayName = "HallPassBlackOutSchedule";
class UpdateHallPassTypeRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdateHallPassTypeRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdateHallPassTypeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdateHallPassTypeRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdateHallPassTypeRequest;
        return UpdateHallPassTypeRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdateHallPassTypeRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdateHallPassTypeRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPassType;
                    reader.readMessage(value1, HallPassType.deserializeBinaryFromReader);
                    msg.setHallPassType(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdateHallPassTypeRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassType();
        if (f != null) {
            writer.writeMessage(1, f, HallPassType.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdateHallPassTypeRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional HallPassType hall_pass_type = 1;
     * @return {?HallPassType}
     */
    getHallPassType() {
        return /** @type{?HallPassType} */ (jspb.Message.getWrapperField(this, HallPassType, 1));
    }
    /** @param {?HallPassType|undefined} value */
    setHallPassType(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPassType() {
        this.setHallPassType(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPassType() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.UpdateHallPassTypeRequest = UpdateHallPassTypeRequest;
UpdateHallPassTypeRequest.displayName = "UpdateHallPassTypeRequest";
class UpdateHallPassTypeResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdateHallPassTypeResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdateHallPassTypeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdateHallPassTypeResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdateHallPassTypeResponse;
        return UpdateHallPassTypeResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdateHallPassTypeResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdateHallPassTypeResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 2:
                    var value2 = new HallPassType;
                    reader.readMessage(value2, HallPassType.deserializeBinaryFromReader);
                    msg.setHallPassType(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdateHallPassTypeResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassType();
        if (f != null) {
            writer.writeMessage(2, f, HallPassType.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdateHallPassTypeResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional HallPassType hall_pass_type = 2;
     * @return {?HallPassType}
     */
    getHallPassType() {
        return /** @type{?HallPassType} */ (jspb.Message.getWrapperField(this, HallPassType, 2));
    }
    /** @param {?HallPassType|undefined} value */
    setHallPassType(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPassType() {
        this.setHallPassType(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPassType() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.UpdateHallPassTypeResponse = UpdateHallPassTypeResponse;
UpdateHallPassTypeResponse.displayName = "UpdateHallPassTypeResponse";
class DeleteHallPassTypeRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteHallPassTypeRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteHallPassTypeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            typeId: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteHallPassTypeRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteHallPassTypeRequest;
        return DeleteHallPassTypeRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteHallPassTypeRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteHallPassTypeRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setTypeId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteHallPassTypeRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getTypeId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteHallPassTypeRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 type_id = 1;
     * @return {number}
     */
    getTypeId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setTypeId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.DeleteHallPassTypeRequest = DeleteHallPassTypeRequest;
DeleteHallPassTypeRequest.displayName = "DeleteHallPassTypeRequest";
class DeleteHallPassTypeResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteHallPassTypeResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteHallPassTypeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteHallPassTypeResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteHallPassTypeResponse;
        return DeleteHallPassTypeResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteHallPassTypeResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteHallPassTypeResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteHallPassTypeResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteHallPassTypeResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.DeleteHallPassTypeResponse = DeleteHallPassTypeResponse;
DeleteHallPassTypeResponse.displayName = "DeleteHallPassTypeResponse";
class CreateHallPassRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, CreateHallPassRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CreateHallPassRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CreateHallPassRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
            personHashIssuedToList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
            startDateTime: (f = msg.getStartDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            expiredDateTime: (f = msg.getExpiredDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            hallPassDuration: jspb.Message.getFieldWithDefault(msg, 5, 0),
            teacherHash: jspb.Message.getFieldWithDefault(msg, 6, ""),
            note: jspb.Message.getFieldWithDefault(msg, 7, ""),
            ignoreRestrictions: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            isKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            listId: jspb.Message.getFieldWithDefault(msg, 10, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CreateHallPassRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CreateHallPassRequest;
        return CreateHallPassRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CreateHallPassRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CreateHallPassRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassTypeId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.addPersonHashIssuedTo(value2);
                    break;
                case 3:
                    var value3 = new common_date_pb.DateTime;
                    reader.readMessage(value3, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setStartDateTime(value3);
                    break;
                case 4:
                    var value4 = new common_date_pb.DateTime;
                    reader.readMessage(value4, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setExpiredDateTime(value4);
                    break;
                case 5:
                    var value5 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassDuration(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setTeacherHash(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setNote(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setIgnoreRestrictions(value8);
                    break;
                case 9:
                    var value9 = /** @type {boolean} */ (reader.readBool());
                    msg.setIsKiosk(value9);
                    break;
                case 10:
                    var value10 = /** @type {number} */ (reader.readInt32());
                    msg.setListId(value10);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CreateHallPassRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassTypeId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getPersonHashIssuedToList();
        if (f.length > 0) {
            writer.writeRepeatedString(2, f);
        }
        f = message.getStartDateTime();
        if (f != null) {
            writer.writeMessage(3, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getExpiredDateTime();
        if (f != null) {
            writer.writeMessage(4, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getHallPassDuration();
        if (f !== 0) {
            writer.writeInt32(5, f);
        }
        f = message.getTeacherHash();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getNote();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getIgnoreRestrictions();
        if (f) {
            writer.writeBool(8, f);
        }
        f = message.getIsKiosk();
        if (f) {
            writer.writeBool(9, f);
        }
        f = message.getListId();
        if (f !== 0) {
            writer.writeInt32(10, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CreateHallPassRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 hall_pass_type_id = 1;
     * @return {number}
     */
    getHallPassTypeId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setHallPassTypeId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * repeated string person_hash_issued_to = 2;
     * @return {!Array<string>}
     */
    getPersonHashIssuedToList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
    }
    ;
    /** @param {!Array<string>} value */
    setPersonHashIssuedToList(value) {
        jspb.Message.setField(this, 2, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addPersonHashIssuedTo(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 2, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPersonHashIssuedToList() {
        this.setPersonHashIssuedToList([]);
    }
    /**
     * optional minga.common.DateTime start_date_time = 3;
     * @return {?DateTime}
     */
    getStartDateTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
    }
    /** @param {?DateTime|undefined} value */
    setStartDateTime(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStartDateTime() {
        this.setStartDateTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStartDateTime() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional minga.common.DateTime expired_date_time = 4;
     * @return {?DateTime}
     */
    getExpiredDateTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
    }
    /** @param {?DateTime|undefined} value */
    setExpiredDateTime(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearExpiredDateTime() {
        this.setExpiredDateTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasExpiredDateTime() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional int32 hall_pass_duration = 5;
     * @return {number}
     */
    getHallPassDuration() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
    }
    ;
    /** @param {number} value */
    setHallPassDuration(value) {
        jspb.Message.setProto3IntField(this, 5, value);
    }
    /**
     * optional string teacher_hash = 6;
     * @return {string}
     */
    getTeacherHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setTeacherHash(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional string note = 7;
     * @return {string}
     */
    getNote() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setNote(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * optional bool ignore_restrictions = 8;
     * @return {boolean}
     */
    getIgnoreRestrictions() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setIgnoreRestrictions(value) {
        jspb.Message.setProto3BooleanField(this, 8, value);
    }
    /**
     * optional bool is_kiosk = 9;
     * @return {boolean}
     */
    getIsKiosk() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
    }
    ;
    /** @param {boolean} value */
    setIsKiosk(value) {
        jspb.Message.setProto3BooleanField(this, 9, value);
    }
    /**
     * optional int32 list_id = 10;
     * @return {number}
     */
    getListId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
    }
    ;
    /** @param {number} value */
    setListId(value) {
        jspb.Message.setProto3IntField(this, 10, value);
    }
}
exports.CreateHallPassRequest = CreateHallPassRequest;
CreateHallPassRequest.displayName = "CreateHallPassRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
CreateHallPassRequest.repeatedFields_ = [2];
class CreateHallPassResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, CreateHallPassResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CreateHallPassResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CreateHallPassResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            errorsList: jspb.Message.toObjectList(msg.getErrorsList(), CheckHallPassValidityError.toObject, includeInstance),
            hallPassIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
            hallPassesList: jspb.Message.toObjectList(msg.getHallPassesList(), HallPassWithType.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CreateHallPassResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CreateHallPassResponse;
        return CreateHallPassResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CreateHallPassResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CreateHallPassResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new CheckHallPassValidityError;
                    reader.readMessage(value1, CheckHallPassValidityError.deserializeBinaryFromReader);
                    msg.addErrors(value1);
                    break;
                case 2:
                    var value2 = /** @type {!Array<number>} */ (reader.readPackedInt32());
                    msg.setHallPassIdsList(value2);
                    break;
                case 3:
                    var value3 = new HallPassWithType;
                    reader.readMessage(value3, HallPassWithType.deserializeBinaryFromReader);
                    msg.addHallPasses(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CreateHallPassResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getErrorsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, CheckHallPassValidityError.serializeBinaryToWriter);
        }
        f = message.getHallPassIdsList();
        if (f.length > 0) {
            writer.writePackedInt32(2, f);
        }
        f = message.getHallPassesList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(3, f, HallPassWithType.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CreateHallPassResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated CheckHallPassValidityError errors = 1;
     * @return {!Array<!CheckHallPassValidityError>}
     */
    getErrorsList() {
        return /** @type{!Array<!CheckHallPassValidityError>} */ (jspb.Message.getRepeatedWrapperField(this, CheckHallPassValidityError, 1));
    }
    /** @param {!Array<!CheckHallPassValidityError>} value */
    setErrorsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!CheckHallPassValidityError=} opt_value
     * @param {number=} opt_index
     * @return {!CheckHallPassValidityError}
     */
    addErrors(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, CheckHallPassValidityError, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearErrorsList() {
        this.setErrorsList([]);
    }
    /**
     * repeated int32 hall_pass_ids = 2;
     * @return {!Array<number>}
     */
    getHallPassIdsList() {
        return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
    }
    ;
    /** @param {!Array<number>} value */
    setHallPassIdsList(value) {
        jspb.Message.setField(this, 2, value || []);
    }
    /**
     * @param {number} value
     * @param {number=} opt_index
     */
    addHallPassIds(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 2, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHallPassIdsList() {
        this.setHallPassIdsList([]);
    }
    /**
     * repeated HallPassWithType hall_passes = 3;
     * @return {!Array<!HallPassWithType>}
     */
    getHallPassesList() {
        return /** @type{!Array<!HallPassWithType>} */ (jspb.Message.getRepeatedWrapperField(this, HallPassWithType, 3));
    }
    /** @param {!Array<!HallPassWithType>} value */
    setHallPassesList(value) {
        jspb.Message.setRepeatedWrapperField(this, 3, value);
    }
    /**
     * @param {!HallPassWithType=} opt_value
     * @param {number=} opt_index
     * @return {!HallPassWithType}
     */
    addHallPasses(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, HallPassWithType, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHallPassesList() {
        this.setHallPassesList([]);
    }
}
exports.CreateHallPassResponse = CreateHallPassResponse;
CreateHallPassResponse.displayName = "CreateHallPassResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
CreateHallPassResponse.repeatedFields_ = [1, 2, 3];
class ExpireHallPassRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ExpireHallPassRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ExpireHallPassRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0),
            expiredDateTime: (f = msg.getExpiredDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            endedByHash: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ExpireHallPassRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ExpireHallPassRequest;
        return ExpireHallPassRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ExpireHallPassRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ExpireHallPassRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassId(value1);
                    break;
                case 2:
                    var value2 = new common_date_pb.DateTime;
                    reader.readMessage(value2, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setExpiredDateTime(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setEndedByHash(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ExpireHallPassRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getExpiredDateTime();
        if (f != null) {
            writer.writeMessage(2, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getEndedByHash();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ExpireHallPassRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 hall_pass_id = 1;
     * @return {number}
     */
    getHallPassId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setHallPassId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional minga.common.DateTime expired_date_time = 2;
     * @return {?DateTime}
     */
    getExpiredDateTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
    }
    /** @param {?DateTime|undefined} value */
    setExpiredDateTime(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearExpiredDateTime() {
        this.setExpiredDateTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasExpiredDateTime() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional string ended_by_hash = 3;
     * @return {string}
     */
    getEndedByHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setEndedByHash(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
}
exports.ExpireHallPassRequest = ExpireHallPassRequest;
ExpireHallPassRequest.displayName = "ExpireHallPassRequest";
class ExpireHallPassResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ExpireHallPassResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ExpireHallPassResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ExpireHallPassResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ExpireHallPassResponse;
        return ExpireHallPassResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ExpireHallPassResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ExpireHallPassResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ExpireHallPassResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ExpireHallPassResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.ExpireHallPassResponse = ExpireHallPassResponse;
ExpireHallPassResponse.displayName = "ExpireHallPassResponse";
class ApproveHallPassRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ApproveHallPassRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ApproveHallPassRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0),
            noteToRequester: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ApproveHallPassRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ApproveHallPassRequest;
        return ApproveHallPassRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ApproveHallPassRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ApproveHallPassRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setNoteToRequester(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ApproveHallPassRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getNoteToRequester();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ApproveHallPassRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 hall_pass_id = 1;
     * @return {number}
     */
    getHallPassId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setHallPassId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string note_to_requester = 2;
     * @return {string}
     */
    getNoteToRequester() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setNoteToRequester(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
}
exports.ApproveHallPassRequest = ApproveHallPassRequest;
ApproveHallPassRequest.displayName = "ApproveHallPassRequest";
class ApproveHallPassResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ApproveHallPassResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ApproveHallPassResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ApproveHallPassResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ApproveHallPassResponse;
        return ApproveHallPassResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ApproveHallPassResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ApproveHallPassResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ApproveHallPassResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ApproveHallPassResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.ApproveHallPassResponse = ApproveHallPassResponse;
ApproveHallPassResponse.displayName = "ApproveHallPassResponse";
class StartHallPassRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StartHallPassRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StartHallPassRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StartHallPassRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StartHallPassRequest;
        return StartHallPassRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StartHallPassRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StartHallPassRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StartHallPassRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StartHallPassRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 hall_pass_id = 1;
     * @return {number}
     */
    getHallPassId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setHallPassId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.StartHallPassRequest = StartHallPassRequest;
StartHallPassRequest.displayName = "StartHallPassRequest";
class StartHallPassResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StartHallPassResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StartHallPassResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StartHallPassResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StartHallPassResponse;
        return StartHallPassResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StartHallPassResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StartHallPassResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StartHallPassResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StartHallPassResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.StartHallPassResponse = StartHallPassResponse;
StartHallPassResponse.displayName = "StartHallPassResponse";
class CancelHallPassRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CancelHallPassRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CancelHallPassRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0),
            noteToRequester: jspb.Message.getFieldWithDefault(msg, 2, ""),
            denying: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CancelHallPassRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CancelHallPassRequest;
        return CancelHallPassRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CancelHallPassRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CancelHallPassRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setNoteToRequester(value2);
                    break;
                case 3:
                    var value3 = /** @type {boolean} */ (reader.readBool());
                    msg.setDenying(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CancelHallPassRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getNoteToRequester();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDenying();
        if (f) {
            writer.writeBool(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CancelHallPassRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 hall_pass_id = 1;
     * @return {number}
     */
    getHallPassId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setHallPassId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string note_to_requester = 2;
     * @return {string}
     */
    getNoteToRequester() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setNoteToRequester(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional bool denying = 3;
     * @return {boolean}
     */
    getDenying() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
    }
    ;
    /** @param {boolean} value */
    setDenying(value) {
        jspb.Message.setProto3BooleanField(this, 3, value);
    }
}
exports.CancelHallPassRequest = CancelHallPassRequest;
CancelHallPassRequest.displayName = "CancelHallPassRequest";
class CancelHallPassResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CancelHallPassResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CancelHallPassResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CancelHallPassResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CancelHallPassResponse;
        return CancelHallPassResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CancelHallPassResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CancelHallPassResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CancelHallPassResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CancelHallPassResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.CancelHallPassResponse = CancelHallPassResponse;
CancelHallPassResponse.displayName = "CancelHallPassResponse";
class GetMingaHallPassTypeRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMingaHallPassTypeRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMingaHallPassTypeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMingaHallPassTypeRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMingaHallPassTypeRequest;
        return GetMingaHallPassTypeRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMingaHallPassTypeRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMingaHallPassTypeRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassTypeId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMingaHallPassTypeRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassTypeId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMingaHallPassTypeRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 hall_pass_type_id = 1;
     * @return {number}
     */
    getHallPassTypeId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setHallPassTypeId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.GetMingaHallPassTypeRequest = GetMingaHallPassTypeRequest;
GetMingaHallPassTypeRequest.displayName = "GetMingaHallPassTypeRequest";
class GetMingaHallPassTypeResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMingaHallPassTypeResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMingaHallPassTypeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMingaHallPassTypeResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMingaHallPassTypeResponse;
        return GetMingaHallPassTypeResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMingaHallPassTypeResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMingaHallPassTypeResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPassType;
                    reader.readMessage(value1, HallPassType.deserializeBinaryFromReader);
                    msg.setHallPassType(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMingaHallPassTypeResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassType();
        if (f != null) {
            writer.writeMessage(1, f, HallPassType.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMingaHallPassTypeResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional HallPassType hall_pass_type = 1;
     * @return {?HallPassType}
     */
    getHallPassType() {
        return /** @type{?HallPassType} */ (jspb.Message.getWrapperField(this, HallPassType, 1));
    }
    /** @param {?HallPassType|undefined} value */
    setHallPassType(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPassType() {
        this.setHallPassType(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPassType() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.GetMingaHallPassTypeResponse = GetMingaHallPassTypeResponse;
GetMingaHallPassTypeResponse.displayName = "GetMingaHallPassTypeResponse";
class GetMingaHallPassTypesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMingaHallPassTypesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMingaHallPassTypesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            getActiveOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            getPermittedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            getKioskPassesOnly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMingaHallPassTypesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMingaHallPassTypesRequest;
        return GetMingaHallPassTypesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMingaHallPassTypesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMingaHallPassTypesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {boolean} */ (reader.readBool());
                    msg.setGetActiveOnly(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setGetPermittedOnly(value2);
                    break;
                case 3:
                    var value3 = /** @type {boolean} */ (reader.readBool());
                    msg.setGetKioskPassesOnly(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMingaHallPassTypesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getGetActiveOnly();
        if (f) {
            writer.writeBool(1, f);
        }
        f = message.getGetPermittedOnly();
        if (f) {
            writer.writeBool(2, f);
        }
        f = message.getGetKioskPassesOnly();
        if (f) {
            writer.writeBool(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMingaHallPassTypesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bool get_active_only = 1;
     * @return {boolean}
     */
    getGetActiveOnly() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
    }
    ;
    /** @param {boolean} value */
    setGetActiveOnly(value) {
        jspb.Message.setProto3BooleanField(this, 1, value);
    }
    /**
     * optional bool get_permitted_only = 2;
     * @return {boolean}
     */
    getGetPermittedOnly() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setGetPermittedOnly(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
    /**
     * optional bool get_kiosk_passes_only = 3;
     * @return {boolean}
     */
    getGetKioskPassesOnly() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
    }
    ;
    /** @param {boolean} value */
    setGetKioskPassesOnly(value) {
        jspb.Message.setProto3BooleanField(this, 3, value);
    }
}
exports.GetMingaHallPassTypesRequest = GetMingaHallPassTypesRequest;
GetMingaHallPassTypesRequest.displayName = "GetMingaHallPassTypesRequest";
class GetMingaHallPassTypesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetMingaHallPassTypesResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMingaHallPassTypesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMingaHallPassTypesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassTypeList: jspb.Message.toObjectList(msg.getHallPassTypeList(), HallPassType.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMingaHallPassTypesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMingaHallPassTypesResponse;
        return GetMingaHallPassTypesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMingaHallPassTypesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMingaHallPassTypesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPassType;
                    reader.readMessage(value1, HallPassType.deserializeBinaryFromReader);
                    msg.addHallPassType(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMingaHallPassTypesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassTypeList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, HallPassType.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMingaHallPassTypesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated HallPassType hall_pass_type = 1;
     * @return {!Array<!HallPassType>}
     */
    getHallPassTypeList() {
        return /** @type{!Array<!HallPassType>} */ (jspb.Message.getRepeatedWrapperField(this, HallPassType, 1));
    }
    /** @param {!Array<!HallPassType>} value */
    setHallPassTypeList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!HallPassType=} opt_value
     * @param {number=} opt_index
     * @return {!HallPassType}
     */
    addHallPassType(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, HallPassType, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHallPassTypeList() {
        this.setHallPassTypeList([]);
    }
}
exports.GetMingaHallPassTypesResponse = GetMingaHallPassTypesResponse;
GetMingaHallPassTypesResponse.displayName = "GetMingaHallPassTypesResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetMingaHallPassTypesResponse.repeatedFields_ = [1];
class AddDefaultHallPassTypesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return AddDefaultHallPassTypesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!AddDefaultHallPassTypesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!AddDefaultHallPassTypesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new AddDefaultHallPassTypesRequest;
        return AddDefaultHallPassTypesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!AddDefaultHallPassTypesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!AddDefaultHallPassTypesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setMingaHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!AddDefaultHallPassTypesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getMingaHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        AddDefaultHallPassTypesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string minga_hash = 1;
     * @return {string}
     */
    getMingaHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setMingaHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.AddDefaultHallPassTypesRequest = AddDefaultHallPassTypesRequest;
AddDefaultHallPassTypesRequest.displayName = "AddDefaultHallPassTypesRequest";
class AddDefaultHallPassTypesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return AddDefaultHallPassTypesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!AddDefaultHallPassTypesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!AddDefaultHallPassTypesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new AddDefaultHallPassTypesResponse;
        return AddDefaultHallPassTypesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!AddDefaultHallPassTypesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!AddDefaultHallPassTypesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setErrorMessage(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!AddDefaultHallPassTypesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getErrorMessage();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        AddDefaultHallPassTypesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string error_message = 1;
     * @return {string}
     */
    getErrorMessage() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setErrorMessage(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.AddDefaultHallPassTypesResponse = AddDefaultHallPassTypesResponse;
AddDefaultHallPassTypesResponse.displayName = "AddDefaultHallPassTypesResponse";
class GetMingaHallPassTypesCountsRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, GetMingaHallPassTypesCountsRequest.oneofGroups_);
    }
    /**
     * @return {GetMingaHallPassTypesCountsRequest.TimelineCase}
     */
    getTimelineCase() {
        return /** @type {GetMingaHallPassTypesCountsRequest.TimelineCase} */ (jspb.Message.computeOneofCase(this, GetMingaHallPassTypesCountsRequest.oneofGroups_[0]));
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMingaHallPassTypesCountsRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMingaHallPassTypesCountsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            expired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            scheduled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMingaHallPassTypesCountsRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMingaHallPassTypesCountsRequest;
        return GetMingaHallPassTypesCountsRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMingaHallPassTypesCountsRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMingaHallPassTypesCountsRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {boolean} */ (reader.readBool());
                    msg.setActive(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setExpired(value2);
                    break;
                case 3:
                    var value3 = /** @type {boolean} */ (reader.readBool());
                    msg.setScheduled(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMingaHallPassTypesCountsRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
        if (f != null) {
            writer.writeBool(1, f);
        }
        f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
        if (f != null) {
            writer.writeBool(2, f);
        }
        f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
        if (f != null) {
            writer.writeBool(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMingaHallPassTypesCountsRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bool active = 1;
     * @return {boolean}
     */
    getActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
    }
    ;
    /** @param {boolean} value */
    setActive(value) {
        jspb.Message.setOneofField(this, 1, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearActive() {
        jspb.Message.setOneofField(this, 1, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasActive() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional bool expired = 2;
     * @return {boolean}
     */
    getExpired() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setExpired(value) {
        jspb.Message.setOneofField(this, 2, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearExpired() {
        jspb.Message.setOneofField(this, 2, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasExpired() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional bool scheduled = 3;
     * @return {boolean}
     */
    getScheduled() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
    }
    ;
    /** @param {boolean} value */
    setScheduled(value) {
        jspb.Message.setOneofField(this, 3, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearScheduled() {
        jspb.Message.setOneofField(this, 3, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasScheduled() {
        return jspb.Message.getField(this, 3) != null;
    }
}
exports.GetMingaHallPassTypesCountsRequest = GetMingaHallPassTypesCountsRequest;
GetMingaHallPassTypesCountsRequest.displayName = "GetMingaHallPassTypesCountsRequest";
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
GetMingaHallPassTypesCountsRequest.oneofGroups_ = [[1, 2, 3]];
(function (GetMingaHallPassTypesCountsRequest) {
    let TimelineCase;
    (function (TimelineCase) {
        TimelineCase[TimelineCase["TIMELINE_NOT_SET"] = 0] = "TIMELINE_NOT_SET";
        TimelineCase[TimelineCase["ACTIVE"] = 1] = "ACTIVE";
        TimelineCase[TimelineCase["EXPIRED"] = 2] = "EXPIRED";
        TimelineCase[TimelineCase["SCHEDULED"] = 3] = "SCHEDULED";
    })(TimelineCase = GetMingaHallPassTypesCountsRequest.TimelineCase || (GetMingaHallPassTypesCountsRequest.TimelineCase = {}));
})(GetMingaHallPassTypesCountsRequest = exports.GetMingaHallPassTypesCountsRequest || (exports.GetMingaHallPassTypesCountsRequest = {})); // namespace GetMingaHallPassTypesCountsRequest
class GetMingaHallPassTypesCountsResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetMingaHallPassTypesCountsResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMingaHallPassTypesCountsResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMingaHallPassTypesCountsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassTypeCountList: jspb.Message.toObjectList(msg.getHallPassTypeCountList(), GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMingaHallPassTypesCountsResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMingaHallPassTypesCountsResponse;
        return GetMingaHallPassTypesCountsResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMingaHallPassTypesCountsResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMingaHallPassTypesCountsResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount;
                    reader.readMessage(value1, GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.deserializeBinaryFromReader);
                    msg.addHallPassTypeCount(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMingaHallPassTypesCountsResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassTypeCountList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMingaHallPassTypesCountsResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated HallPassTypeWithCount hall_pass_type_count = 1;
     * @return {!Array<!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount>}
     */
    getHallPassTypeCountList() {
        return /** @type{!Array<!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount>} */ (jspb.Message.getRepeatedWrapperField(this, GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount, 1));
    }
    /** @param {!Array<!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount>} value */
    setHallPassTypeCountList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount=} opt_value
     * @param {number=} opt_index
     * @return {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount}
     */
    addHallPassTypeCount(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHallPassTypeCountList() {
        this.setHallPassTypeCountList([]);
    }
}
exports.GetMingaHallPassTypesCountsResponse = GetMingaHallPassTypesCountsResponse;
GetMingaHallPassTypesCountsResponse.displayName = "GetMingaHallPassTypesCountsResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetMingaHallPassTypesCountsResponse.repeatedFields_ = [1];
(function (GetMingaHallPassTypesCountsResponse) {
    class HallPassTypeWithCount extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return HallPassTypeWithCount.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!HallPassTypeWithCount} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f),
                count: jspb.Message.getFieldWithDefault(msg, 2, 0)
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!HallPassTypeWithCount}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new HallPassTypeWithCount;
            return HallPassTypeWithCount.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = new HallPassType;
                        reader.readMessage(value1, HallPassType.deserializeBinaryFromReader);
                        msg.setHallPassType(value1);
                        break;
                    case 2:
                        var value2 = /** @type {number} */ (reader.readInt32());
                        msg.setCount(value2);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getHallPassType();
            if (f != null) {
                writer.writeMessage(1, f, HallPassType.serializeBinaryToWriter);
            }
            f = message.getCount();
            if (f !== 0) {
                writer.writeInt32(2, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional HallPassType hall_pass_type = 1;
         * @return {?HallPassType}
         */
        getHallPassType() {
            return /** @type{?HallPassType} */ (jspb.Message.getWrapperField(this, HallPassType, 1));
        }
        /** @param {?HallPassType|undefined} value */
        setHallPassType(value) {
            jspb.Message.setWrapperField(this, 1, value);
        }
        /**
         * Clears the message field making it undefined.
         */
        clearHallPassType() {
            this.setHallPassType(undefined);
        }
        /**
         * Returns whether this field is set.
         * @return {boolean}
         */
        hasHallPassType() {
            return jspb.Message.getField(this, 1) != null;
        }
        /**
         * optional int32 count = 2;
         * @return {number}
         */
        getCount() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
        }
        ;
        /** @param {number} value */
        setCount(value) {
            jspb.Message.setProto3IntField(this, 2, value);
        }
    }
    HallPassTypeWithCount.displayName = "GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount";
    GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount = HallPassTypeWithCount;
})(GetMingaHallPassTypesCountsResponse = exports.GetMingaHallPassTypesCountsResponse || (exports.GetMingaHallPassTypesCountsResponse = {})); // namespace GetMingaHallPassTypesCountsResponse
class StreamHallPassWithTypeItem extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StreamHallPassWithTypeItem.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StreamHallPassWithTypeItem} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
            item: (f = msg.getItem()) && HallPassWithType.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StreamHallPassWithTypeItem}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StreamHallPassWithTypeItem;
        return StreamHallPassWithTypeItem.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StreamHallPassWithTypeItem} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StreamHallPassWithTypeItem}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_stream_pb.StreamItemMetadata;
                    reader.readMessage(value1, common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
                    msg.setItemMetadata(value1);
                    break;
                case 2:
                    var value2 = new HallPassWithType;
                    reader.readMessage(value2, HallPassWithType.deserializeBinaryFromReader);
                    msg.setItem(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StreamHallPassWithTypeItem} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getItemMetadata();
        if (f != null) {
            writer.writeMessage(1, f, common_stream_pb.StreamItemMetadata.serializeBinaryToWriter);
        }
        f = message.getItem();
        if (f != null) {
            writer.writeMessage(2, f, HallPassWithType.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StreamHallPassWithTypeItem.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StreamItemMetadata item_metadata = 1;
     * @return {?StreamItemMetadata}
     */
    getItemMetadata() {
        return /** @type{?StreamItemMetadata} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
    }
    /** @param {?StreamItemMetadata|undefined} value */
    setItemMetadata(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItemMetadata() {
        this.setItemMetadata(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItemMetadata() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional HallPassWithType item = 2;
     * @return {?HallPassWithType}
     */
    getItem() {
        return /** @type{?HallPassWithType} */ (jspb.Message.getWrapperField(this, HallPassWithType, 2));
    }
    /** @param {?HallPassWithType|undefined} value */
    setItem(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItem() {
        this.setItem(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItem() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.StreamHallPassWithTypeItem = StreamHallPassWithTypeItem;
StreamHallPassWithTypeItem.displayName = "StreamHallPassWithTypeItem";
class GetHallPassesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetHallPassesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetHallPassesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            filter: (f = msg.getFilter()) && common_stream_pb.StreamFilter.toObject(includeInstance, f),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
            includeActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            includeEnded: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            includePending: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            includeScheduled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            includeOverdue: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            showCreated: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            includeDenied: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetHallPassesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetHallPassesRequest;
        return GetHallPassesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetHallPassesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetHallPassesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_stream_pb.StreamFilter;
                    reader.readMessage(value1, common_stream_pb.StreamFilter.deserializeBinaryFromReader);
                    msg.setFilter(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readInt32());
                    msg.setLimit(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readInt32());
                    msg.setOffset(value3);
                    break;
                case 4:
                    var value4 = /** @type {boolean} */ (reader.readBool());
                    msg.setIncludeActive(value4);
                    break;
                case 5:
                    var value5 = /** @type {boolean} */ (reader.readBool());
                    msg.setIncludeEnded(value5);
                    break;
                case 6:
                    var value6 = /** @type {boolean} */ (reader.readBool());
                    msg.setIncludePending(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setIncludeScheduled(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setIncludeOverdue(value8);
                    break;
                case 9:
                    var value9 = /** @type {boolean} */ (reader.readBool());
                    msg.setShowCreated(value9);
                    break;
                case 10:
                    var value10 = /** @type {boolean} */ (reader.readBool());
                    msg.setIncludeDenied(value10);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetHallPassesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getFilter();
        if (f != null) {
            writer.writeMessage(1, f, common_stream_pb.StreamFilter.serializeBinaryToWriter);
        }
        f = message.getLimit();
        if (f !== 0) {
            writer.writeInt32(2, f);
        }
        f = message.getOffset();
        if (f !== 0) {
            writer.writeInt32(3, f);
        }
        f = message.getIncludeActive();
        if (f) {
            writer.writeBool(4, f);
        }
        f = message.getIncludeEnded();
        if (f) {
            writer.writeBool(5, f);
        }
        f = message.getIncludePending();
        if (f) {
            writer.writeBool(6, f);
        }
        f = message.getIncludeScheduled();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getIncludeOverdue();
        if (f) {
            writer.writeBool(8, f);
        }
        f = message.getShowCreated();
        if (f) {
            writer.writeBool(9, f);
        }
        f = message.getIncludeDenied();
        if (f) {
            writer.writeBool(10, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetHallPassesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StreamFilter filter = 1;
     * @return {?StreamFilter}
     */
    getFilter() {
        return /** @type{?StreamFilter} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamFilter, 1));
    }
    /** @param {?StreamFilter|undefined} value */
    setFilter(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearFilter() {
        this.setFilter(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasFilter() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional int32 limit = 2;
     * @return {number}
     */
    getLimit() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setLimit(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
    /**
     * optional int32 offset = 3;
     * @return {number}
     */
    getOffset() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
    }
    ;
    /** @param {number} value */
    setOffset(value) {
        jspb.Message.setProto3IntField(this, 3, value);
    }
    /**
     * optional bool include_active = 4;
     * @return {boolean}
     */
    getIncludeActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
    }
    ;
    /** @param {boolean} value */
    setIncludeActive(value) {
        jspb.Message.setProto3BooleanField(this, 4, value);
    }
    /**
     * optional bool include_ended = 5;
     * @return {boolean}
     */
    getIncludeEnded() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
    }
    ;
    /** @param {boolean} value */
    setIncludeEnded(value) {
        jspb.Message.setProto3BooleanField(this, 5, value);
    }
    /**
     * optional bool include_pending = 6;
     * @return {boolean}
     */
    getIncludePending() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
    }
    ;
    /** @param {boolean} value */
    setIncludePending(value) {
        jspb.Message.setProto3BooleanField(this, 6, value);
    }
    /**
     * optional bool include_scheduled = 7;
     * @return {boolean}
     */
    getIncludeScheduled() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setIncludeScheduled(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional bool include_overdue = 8;
     * @return {boolean}
     */
    getIncludeOverdue() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setIncludeOverdue(value) {
        jspb.Message.setProto3BooleanField(this, 8, value);
    }
    /**
     * optional bool show_created = 9;
     * @return {boolean}
     */
    getShowCreated() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
    }
    ;
    /** @param {boolean} value */
    setShowCreated(value) {
        jspb.Message.setProto3BooleanField(this, 9, value);
    }
    /**
     * optional bool include_denied = 10;
     * @return {boolean}
     */
    getIncludeDenied() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
    }
    ;
    /** @param {boolean} value */
    setIncludeDenied(value) {
        jspb.Message.setProto3BooleanField(this, 10, value);
    }
}
exports.GetHallPassesRequest = GetHallPassesRequest;
GetHallPassesRequest.displayName = "GetHallPassesRequest";
class GetHallPassesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetHallPassesResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetHallPassesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetHallPassesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            itemsList: jspb.Message.toObjectList(msg.getItemsList(), StreamHallPassWithTypeItem.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetHallPassesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetHallPassesResponse;
        return GetHallPassesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetHallPassesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetHallPassesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new StreamHallPassWithTypeItem;
                    reader.readMessage(value1, StreamHallPassWithTypeItem.deserializeBinaryFromReader);
                    msg.addItems(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetHallPassesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getItemsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, StreamHallPassWithTypeItem.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetHallPassesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated StreamHallPassWithTypeItem items = 1;
     * @return {!Array<!StreamHallPassWithTypeItem>}
     */
    getItemsList() {
        return /** @type{!Array<!StreamHallPassWithTypeItem>} */ (jspb.Message.getRepeatedWrapperField(this, StreamHallPassWithTypeItem, 1));
    }
    /** @param {!Array<!StreamHallPassWithTypeItem>} value */
    setItemsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!StreamHallPassWithTypeItem=} opt_value
     * @param {number=} opt_index
     * @return {!StreamHallPassWithTypeItem}
     */
    addItems(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, StreamHallPassWithTypeItem, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearItemsList() {
        this.setItemsList([]);
    }
}
exports.GetHallPassesResponse = GetHallPassesResponse;
GetHallPassesResponse.displayName = "GetHallPassesResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetHallPassesResponse.repeatedFields_ = [1];
class GetHallPassesForRecipientRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetHallPassesForRecipientRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetHallPassesForRecipientRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            recipientPersonHash: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetHallPassesForRecipientRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetHallPassesForRecipientRequest;
        return GetHallPassesForRecipientRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetHallPassesForRecipientRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetHallPassesForRecipientRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setRecipientPersonHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetHallPassesForRecipientRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getRecipientPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetHallPassesForRecipientRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string recipient_person_hash = 1;
     * @return {string}
     */
    getRecipientPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setRecipientPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.GetHallPassesForRecipientRequest = GetHallPassesForRecipientRequest;
GetHallPassesForRecipientRequest.displayName = "GetHallPassesForRecipientRequest";
class GetHallPassesForRecipientResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetHallPassesForRecipientResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetHallPassesForRecipientResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetHallPassesForRecipientResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassList: jspb.Message.toObjectList(msg.getHallPassList(), HallPass.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetHallPassesForRecipientResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetHallPassesForRecipientResponse;
        return GetHallPassesForRecipientResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetHallPassesForRecipientResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetHallPassesForRecipientResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPass;
                    reader.readMessage(value1, HallPass.deserializeBinaryFromReader);
                    msg.addHallPass(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetHallPassesForRecipientResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, HallPass.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetHallPassesForRecipientResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated HallPass hall_pass = 1;
     * @return {!Array<!HallPass>}
     */
    getHallPassList() {
        return /** @type{!Array<!HallPass>} */ (jspb.Message.getRepeatedWrapperField(this, HallPass, 1));
    }
    /** @param {!Array<!HallPass>} value */
    setHallPassList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!HallPass=} opt_value
     * @param {number=} opt_index
     * @return {!HallPass}
     */
    addHallPass(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, HallPass, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHallPassList() {
        this.setHallPassList([]);
    }
}
exports.GetHallPassesForRecipientResponse = GetHallPassesForRecipientResponse;
GetHallPassesForRecipientResponse.displayName = "GetHallPassesForRecipientResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetHallPassesForRecipientResponse.repeatedFields_ = [1];
class GetMembershipListsRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMembershipListsRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMembershipListsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMembershipListsRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMembershipListsRequest;
        return GetMembershipListsRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMembershipListsRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMembershipListsRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMembershipListsRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMembershipListsRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.GetMembershipListsRequest = GetMembershipListsRequest;
GetMembershipListsRequest.displayName = "GetMembershipListsRequest";
class GetMembershipListsResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetMembershipListsResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetMembershipListsResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetMembershipListsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            listsList: jspb.Message.toObjectList(msg.getListsList(), membership_list_membership_list_pb.MembershipList.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetMembershipListsResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetMembershipListsResponse;
        return GetMembershipListsResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetMembershipListsResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetMembershipListsResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new membership_list_membership_list_pb.MembershipList;
                    reader.readMessage(value1, membership_list_membership_list_pb.MembershipList.deserializeBinaryFromReader);
                    msg.addLists(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetMembershipListsResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getListsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, membership_list_membership_list_pb.MembershipList.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetMembershipListsResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated membership_list.MembershipList lists = 1;
     * @return {!Array<!MembershipList>}
     */
    getListsList() {
        return /** @type{!Array<!MembershipList>} */ (jspb.Message.getRepeatedWrapperField(this, membership_list_membership_list_pb.MembershipList, 1));
    }
    /** @param {!Array<!MembershipList>} value */
    setListsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!MembershipList=} opt_value
     * @param {number=} opt_index
     * @return {!MembershipList}
     */
    addLists(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, membership_list_membership_list_pb.MembershipList, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearListsList() {
        this.setListsList([]);
    }
}
exports.GetMembershipListsResponse = GetMembershipListsResponse;
GetMembershipListsResponse.displayName = "GetMembershipListsResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetMembershipListsResponse.repeatedFields_ = [1];
class CheckHallPassValidityRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, CheckHallPassValidityRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CheckHallPassValidityRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CheckHallPassValidityRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            peopleHashesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            hallPassTypeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
            startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CheckHallPassValidityRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CheckHallPassValidityRequest;
        return CheckHallPassValidityRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CheckHallPassValidityRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CheckHallPassValidityRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addPeopleHashes(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readInt32());
                    msg.setHallPassTypeId(value2);
                    break;
                case 3:
                    var value3 = new common_date_pb.DateTime;
                    reader.readMessage(value3, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setStartDate(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CheckHallPassValidityRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPeopleHashesList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
        f = message.getHallPassTypeId();
        if (f !== 0) {
            writer.writeInt32(2, f);
        }
        f = message.getStartDate();
        if (f != null) {
            writer.writeMessage(3, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CheckHallPassValidityRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string people_hashes = 1;
     * @return {!Array<string>}
     */
    getPeopleHashesList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setPeopleHashesList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addPeopleHashes(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleHashesList() {
        this.setPeopleHashesList([]);
    }
    /**
     * optional int32 hall_pass_type_id = 2;
     * @return {number}
     */
    getHallPassTypeId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setHallPassTypeId(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
    /**
     * optional minga.common.DateTime start_date = 3;
     * @return {?DateTime}
     */
    getStartDate() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
    }
    /** @param {?DateTime|undefined} value */
    setStartDate(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStartDate() {
        this.setStartDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStartDate() {
        return jspb.Message.getField(this, 3) != null;
    }
}
exports.CheckHallPassValidityRequest = CheckHallPassValidityRequest;
CheckHallPassValidityRequest.displayName = "CheckHallPassValidityRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
CheckHallPassValidityRequest.repeatedFields_ = [1];
class CheckHallPassValidityError extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CheckHallPassValidityError.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CheckHallPassValidityError} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
            personHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
            name: jspb.Message.getFieldWithDefault(msg, 3, ""),
            maxPasses: jspb.Message.getFieldWithDefault(msg, 4, 0),
            hallPassName: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CheckHallPassValidityError}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CheckHallPassValidityError;
        return CheckHallPassValidityError.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CheckHallPassValidityError} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CheckHallPassValidityError}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setErrorCode(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setName(value3);
                    break;
                case 4:
                    var value4 = /** @type {number} */ (reader.readInt32());
                    msg.setMaxPasses(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setHallPassName(value5);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CheckHallPassValidityError} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getErrorCode();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getMaxPasses();
        if (f !== 0) {
            writer.writeInt32(4, f);
        }
        f = message.getHallPassName();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CheckHallPassValidityError.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 error_code = 1;
     * @return {number}
     */
    getErrorCode() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setErrorCode(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string person_hash = 2;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string name = 3;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional int32 max_passes = 4;
     * @return {number}
     */
    getMaxPasses() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
    }
    ;
    /** @param {number} value */
    setMaxPasses(value) {
        jspb.Message.setProto3IntField(this, 4, value);
    }
    /**
     * optional string hall_pass_name = 5;
     * @return {string}
     */
    getHallPassName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setHallPassName(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
}
exports.CheckHallPassValidityError = CheckHallPassValidityError;
CheckHallPassValidityError.displayName = "CheckHallPassValidityError";
class CheckHallPassValidityResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, CheckHallPassValidityResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CheckHallPassValidityResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CheckHallPassValidityResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            errorsList: jspb.Message.toObjectList(msg.getErrorsList(), CheckHallPassValidityError.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CheckHallPassValidityResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CheckHallPassValidityResponse;
        return CheckHallPassValidityResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CheckHallPassValidityResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CheckHallPassValidityResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new CheckHallPassValidityError;
                    reader.readMessage(value1, CheckHallPassValidityError.deserializeBinaryFromReader);
                    msg.addErrors(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CheckHallPassValidityResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getErrorsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, CheckHallPassValidityError.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CheckHallPassValidityResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated CheckHallPassValidityError errors = 1;
     * @return {!Array<!CheckHallPassValidityError>}
     */
    getErrorsList() {
        return /** @type{!Array<!CheckHallPassValidityError>} */ (jspb.Message.getRepeatedWrapperField(this, CheckHallPassValidityError, 1));
    }
    /** @param {!Array<!CheckHallPassValidityError>} value */
    setErrorsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!CheckHallPassValidityError=} opt_value
     * @param {number=} opt_index
     * @return {!CheckHallPassValidityError}
     */
    addErrors(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, CheckHallPassValidityError, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearErrorsList() {
        this.setErrorsList([]);
    }
}
exports.CheckHallPassValidityResponse = CheckHallPassValidityResponse;
CheckHallPassValidityResponse.displayName = "CheckHallPassValidityResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
CheckHallPassValidityResponse.repeatedFields_ = [1];
class UpdateHallPassBlackOutWindowRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdateHallPassBlackOutWindowRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdateHallPassBlackOutWindowRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            blackOutWindowInfo: (f = msg.getBlackOutWindowInfo()) && HallPassBlackOut.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdateHallPassBlackOutWindowRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdateHallPassBlackOutWindowRequest;
        return UpdateHallPassBlackOutWindowRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdateHallPassBlackOutWindowRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdateHallPassBlackOutWindowRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPassBlackOut;
                    reader.readMessage(value1, HallPassBlackOut.deserializeBinaryFromReader);
                    msg.setBlackOutWindowInfo(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdateHallPassBlackOutWindowRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBlackOutWindowInfo();
        if (f != null) {
            writer.writeMessage(1, f, HallPassBlackOut.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdateHallPassBlackOutWindowRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional HallPassBlackOut black_out_window_info = 1;
     * @return {?HallPassBlackOut}
     */
    getBlackOutWindowInfo() {
        return /** @type{?HallPassBlackOut} */ (jspb.Message.getWrapperField(this, HallPassBlackOut, 1));
    }
    /** @param {?HallPassBlackOut|undefined} value */
    setBlackOutWindowInfo(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBlackOutWindowInfo() {
        this.setBlackOutWindowInfo(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBlackOutWindowInfo() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.UpdateHallPassBlackOutWindowRequest = UpdateHallPassBlackOutWindowRequest;
UpdateHallPassBlackOutWindowRequest.displayName = "UpdateHallPassBlackOutWindowRequest";
class UpdateHallPassBlackOutWindowResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdateHallPassBlackOutWindowResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdateHallPassBlackOutWindowResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            blackOutWindowInfo: (f = msg.getBlackOutWindowInfo()) && HallPassBlackOut.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdateHallPassBlackOutWindowResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdateHallPassBlackOutWindowResponse;
        return UpdateHallPassBlackOutWindowResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdateHallPassBlackOutWindowResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdateHallPassBlackOutWindowResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 2:
                    var value2 = new HallPassBlackOut;
                    reader.readMessage(value2, HallPassBlackOut.deserializeBinaryFromReader);
                    msg.setBlackOutWindowInfo(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdateHallPassBlackOutWindowResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBlackOutWindowInfo();
        if (f != null) {
            writer.writeMessage(2, f, HallPassBlackOut.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdateHallPassBlackOutWindowResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional HallPassBlackOut black_out_window_info = 2;
     * @return {?HallPassBlackOut}
     */
    getBlackOutWindowInfo() {
        return /** @type{?HallPassBlackOut} */ (jspb.Message.getWrapperField(this, HallPassBlackOut, 2));
    }
    /** @param {?HallPassBlackOut|undefined} value */
    setBlackOutWindowInfo(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBlackOutWindowInfo() {
        this.setBlackOutWindowInfo(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBlackOutWindowInfo() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.UpdateHallPassBlackOutWindowResponse = UpdateHallPassBlackOutWindowResponse;
UpdateHallPassBlackOutWindowResponse.displayName = "UpdateHallPassBlackOutWindowResponse";
class DeleteHallPassBlackOutWindowRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteHallPassBlackOutWindowRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteHallPassBlackOutWindowRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            blackOutWindowId: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteHallPassBlackOutWindowRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteHallPassBlackOutWindowRequest;
        return DeleteHallPassBlackOutWindowRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteHallPassBlackOutWindowRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteHallPassBlackOutWindowRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setBlackOutWindowId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteHallPassBlackOutWindowRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBlackOutWindowId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteHallPassBlackOutWindowRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 black_out_window_id = 1;
     * @return {number}
     */
    getBlackOutWindowId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setBlackOutWindowId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.DeleteHallPassBlackOutWindowRequest = DeleteHallPassBlackOutWindowRequest;
DeleteHallPassBlackOutWindowRequest.displayName = "DeleteHallPassBlackOutWindowRequest";
class DeleteHallPassBlackOutWindowResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteHallPassBlackOutWindowResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteHallPassBlackOutWindowResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteHallPassBlackOutWindowResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteHallPassBlackOutWindowResponse;
        return DeleteHallPassBlackOutWindowResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteHallPassBlackOutWindowResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteHallPassBlackOutWindowResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteHallPassBlackOutWindowResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteHallPassBlackOutWindowResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.DeleteHallPassBlackOutWindowResponse = DeleteHallPassBlackOutWindowResponse;
DeleteHallPassBlackOutWindowResponse.displayName = "DeleteHallPassBlackOutWindowResponse";
class GetHallPassBlackOutWindowsRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetHallPassBlackOutWindowsRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetHallPassBlackOutWindowsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetHallPassBlackOutWindowsRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetHallPassBlackOutWindowsRequest;
        return GetHallPassBlackOutWindowsRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetHallPassBlackOutWindowsRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetHallPassBlackOutWindowsRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetHallPassBlackOutWindowsRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetHallPassBlackOutWindowsRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.GetHallPassBlackOutWindowsRequest = GetHallPassBlackOutWindowsRequest;
GetHallPassBlackOutWindowsRequest.displayName = "GetHallPassBlackOutWindowsRequest";
class GetHallPassBlackOutWindowsResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetHallPassBlackOutWindowsResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetHallPassBlackOutWindowsResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetHallPassBlackOutWindowsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPassBlackOutWindowsList: jspb.Message.toObjectList(msg.getHallPassBlackOutWindowsList(), HallPassBlackOut.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetHallPassBlackOutWindowsResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetHallPassBlackOutWindowsResponse;
        return GetHallPassBlackOutWindowsResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetHallPassBlackOutWindowsResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetHallPassBlackOutWindowsResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new HallPassBlackOut;
                    reader.readMessage(value1, HallPassBlackOut.deserializeBinaryFromReader);
                    msg.addHallPassBlackOutWindows(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetHallPassBlackOutWindowsResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPassBlackOutWindowsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, HallPassBlackOut.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetHallPassBlackOutWindowsResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated HallPassBlackOut hall_pass_black_out_windows = 1;
     * @return {!Array<!HallPassBlackOut>}
     */
    getHallPassBlackOutWindowsList() {
        return /** @type{!Array<!HallPassBlackOut>} */ (jspb.Message.getRepeatedWrapperField(this, HallPassBlackOut, 1));
    }
    /** @param {!Array<!HallPassBlackOut>} value */
    setHallPassBlackOutWindowsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!HallPassBlackOut=} opt_value
     * @param {number=} opt_index
     * @return {!HallPassBlackOut}
     */
    addHallPassBlackOutWindows(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, HallPassBlackOut, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHallPassBlackOutWindowsList() {
        this.setHallPassBlackOutWindowsList([]);
    }
}
exports.GetHallPassBlackOutWindowsResponse = GetHallPassBlackOutWindowsResponse;
GetHallPassBlackOutWindowsResponse.displayName = "GetHallPassBlackOutWindowsResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetHallPassBlackOutWindowsResponse.repeatedFields_ = [1];
class UpdateHallPassRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdateHallPassRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdateHallPassRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPass: (f = msg.getHallPass()) && EditableHallPass.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdateHallPassRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdateHallPassRequest;
        return UpdateHallPassRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdateHallPassRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdateHallPassRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new EditableHallPass;
                    reader.readMessage(value1, EditableHallPass.deserializeBinaryFromReader);
                    msg.setHallPass(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdateHallPassRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPass();
        if (f != null) {
            writer.writeMessage(1, f, EditableHallPass.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdateHallPassRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional EditableHallPass hall_pass = 1;
     * @return {?EditableHallPass}
     */
    getHallPass() {
        return /** @type{?EditableHallPass} */ (jspb.Message.getWrapperField(this, EditableHallPass, 1));
    }
    /** @param {?EditableHallPass|undefined} value */
    setHallPass(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPass() {
        this.setHallPass(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPass() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.UpdateHallPassRequest = UpdateHallPassRequest;
UpdateHallPassRequest.displayName = "UpdateHallPassRequest";
class UpdateHallPassResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdateHallPassResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdateHallPassResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hallPass: (f = msg.getHallPass()) && EditableHallPass.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdateHallPassResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdateHallPassResponse;
        return UpdateHallPassResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdateHallPassResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdateHallPassResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 2:
                    var value2 = new EditableHallPass;
                    reader.readMessage(value2, EditableHallPass.deserializeBinaryFromReader);
                    msg.setHallPass(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdateHallPassResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHallPass();
        if (f != null) {
            writer.writeMessage(2, f, EditableHallPass.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdateHallPassResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional EditableHallPass hall_pass = 2;
     * @return {?EditableHallPass}
     */
    getHallPass() {
        return /** @type{?EditableHallPass} */ (jspb.Message.getWrapperField(this, EditableHallPass, 2));
    }
    /** @param {?EditableHallPass|undefined} value */
    setHallPass(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHallPass() {
        this.setHallPass(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHallPass() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.UpdateHallPassResponse = UpdateHallPassResponse;
UpdateHallPassResponse.displayName = "UpdateHallPassResponse";
class ArchiveHallPassRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, ArchiveHallPassRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ArchiveHallPassRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ArchiveHallPassRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ArchiveHallPassRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ArchiveHallPassRequest;
        return ArchiveHallPassRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ArchiveHallPassRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ArchiveHallPassRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
                    msg.setIdsList(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ArchiveHallPassRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getIdsList();
        if (f.length > 0) {
            writer.writePackedInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ArchiveHallPassRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated int32 ids = 1;
     * @return {!Array<number>}
     */
    getIdsList() {
        return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<number>} value */
    setIdsList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {number} value
     * @param {number=} opt_index
     */
    addIds(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearIdsList() {
        this.setIdsList([]);
    }
}
exports.ArchiveHallPassRequest = ArchiveHallPassRequest;
ArchiveHallPassRequest.displayName = "ArchiveHallPassRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
ArchiveHallPassRequest.repeatedFields_ = [1];
class ArchiveHallPassResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ArchiveHallPassResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ArchiveHallPassResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ArchiveHallPassResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ArchiveHallPassResponse;
        return ArchiveHallPassResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ArchiveHallPassResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ArchiveHallPassResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ArchiveHallPassResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ArchiveHallPassResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.ArchiveHallPassResponse = ArchiveHallPassResponse;
ArchiveHallPassResponse.displayName = "ArchiveHallPassResponse";