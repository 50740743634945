"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SisUnifiedSyncLogMapper = exports.SisSyncLogTotalsMapper = exports.SisSettingsMapper = exports.SisSyncLogMapper = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const SisSettingsmapperprotots = require("minga/libraries/shared-grpc/sis/SisSettings.mapper.proto");
exports.SisSettingsMapper = SisSettingsmapperprotots;
const SisSyncLogmapperprotots = require("minga/libraries/shared-grpc/sis/SisSyncLog.mapper.proto");
exports.SisSyncLogMapper = SisSyncLogmapperprotots;
const SisSyncLogTotalsmapperprotots = require("minga/libraries/shared-grpc/sis/SisSyncLogTotals.mapper.proto");
exports.SisSyncLogTotalsMapper = SisSyncLogTotalsmapperprotots;
const SisUnifiedSyncLogmapperprotots = require("minga/libraries/shared-grpc/sis/SisUnifiedSyncLog.mapper.proto");
exports.SisUnifiedSyncLogMapper = SisUnifiedSyncLogmapperprotots;