"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PbisCategoriesObj = exports.PbisCategories = exports.SUCCESS_DIALOG_CONFIG = exports.PbisCategory = void 0;
const domain_1 = require("minga/libraries/domain/index");
var PbisCategory;
(function (PbisCategory) {
    PbisCategory[PbisCategory["PRAISE"] = 0] = "PRAISE";
    PbisCategory[PbisCategory["GUIDANCE"] = 1] = "GUIDANCE";
})(PbisCategory = exports.PbisCategory || (exports.PbisCategory = {}));
exports.SUCCESS_DIALOG_CONFIG = {
    [domain_1.PbisNameCategory.PRAISE]: {
        message: 'Success! Praise Assigned',
        imagePath: '/assets/teacher-tools/praise-icon.svg',
    },
    [domain_1.PbisNameCategory.GUIDANCE]: {
        message: 'Success! Guidance Assigned',
        imagePath: '/assets/teacher-tools/behavior-icon.svg',
    },
    [domain_1.PbisNameCategory.CONSEQUENCE]: {
        message: 'Success! Consequence Assigned',
        imagePath: '/assets/teacher-tools/consequence-icon.svg',
    },
};
exports.PbisCategories = [
    {
        id: PbisCategory.PRAISE,
        name: 'Praise',
        color: '#91BCAD',
    },
    {
        id: PbisCategory.GUIDANCE,
        name: 'Guidance',
        color: '#DB6578',
    },
];
exports.PbisCategoriesObj = {
    [PbisCategory.PRAISE]: {
        name: 'Praise',
        color: '#91BCAD',
    },
    [PbisCategory.GUIDANCE]: {
        name: 'Guidance',
        color: '#DB6578',
    },
};