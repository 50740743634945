import { Injectable } from '@angular/core';

import * as gallery_pb from 'minga/proto/gateway/gallery_pb';
import { EventsFacadeService } from 'minga/app/src/app/events/services';
import { ReportService } from 'minga/app/src/app/services/Report';
import { IContentEventMinimal } from 'minga/libraries/domain';
import { MingaGallery } from 'minga/proto/gateway/gallery_ng_grpc_pb';

import { IAddGalleryPhotoOptions, IDeleteGalleryPhotoOptions } from '../types';
import { GalleryServiceAbstract } from '../utils';

@Injectable({ providedIn: 'root' })
export class EventGalleryService extends GalleryServiceAbstract {
  private _eventContent?: PromiseLike<IContentEventMinimal | undefined>;

  constructor(
    public mingaGalleryProto: MingaGallery,
    public reportService: ReportService,
    private _eventsFacade: EventsFacadeService,
  ) {
    super(mingaGalleryProto, reportService);
  }

  setEventContent(eventContent: IContentEventMinimal | undefined) {
    this._eventContent = Promise.resolve(eventContent);
  }

  async setEventFromContextHash(contextHash: string) {
    this._eventContent =
      this._eventsFacade.fetchEventByContextHash(contextHash);
  }

  private async _getEventContentHash(): Promise<IContentEventMinimal> {
    if (!this._eventContent) {
      throw new Error(`EventGalleryService called without an event`);
    }
    const eventContent = await this._eventContent;
    if (!eventContent) {
      throw new Error(
        `EventGalleryService could not find content hash for the current event`,
      );
    } else return eventContent;
  }

  async fetchSummary() {
    const eventContent = await this._getEventContentHash();

    const request = new gallery_pb.MingaGallerySummaryRequest();
    const wasLoading = this._loading.getValue();
    request.setEventContentHash(eventContent.hash);

    try {
      if (!wasLoading) this._loading.next(true);
      const response = await this.mingaGalleryProto.summary(request);
      this._summary.next(response.toObject());
    } finally {
      if (!wasLoading) this._loading.next(false);
    }
  }

  async getSearchPhotoTotal(search: string): Promise<number> {
    const eventContent = await this._getEventContentHash();

    const request = new gallery_pb.MingaGalleryGetPhotoTotalRequest();
    request.setSearchQuery(search);
    request.setEventContentHash(eventContent.hash);

    const response = await this.mingaGalleryProto.getPhotoTotal(request);

    return response.getPhotoTotal();
  }

  async addPhoto(options: IAddGalleryPhotoOptions) {
    return await this._addPhoto(this._summary, options);
  }

  async deletePhoto(options: IDeleteGalleryPhotoOptions): Promise<boolean> {
    return await this._deletePhoto(this._summary, options);
  }

  async resolvePhoto(galleryPhotoUuid: string): Promise<boolean> {
    return await this._resolvePhoto(galleryPhotoUuid);
  }
}
