"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const wrappers_pb_1 = require("minga/proto/google/protobuf/wrappers_pb");
const pbis_stats_pb_1 = require("minga/proto/pbis_stats/pbis_stats_pb");
exports.toProto = (filters, limit, offset) => {
    const filterProto = new pbis_stats_pb_1.PbisReportFilters();
    if (filters.startDate)
        filterProto.setStartDate(shared_grpc_1.dateObjectToDateTimeMessage(filters.startDate.toDate()));
    if (filters.endDate)
        filterProto.setEndDate(shared_grpc_1.dateObjectToDateTimeMessage(filters.endDate.toDate()));
    filterProto.setGradeList(filters.grades);
    if (filters.types)
        filterProto.setBehaviorTypeList(filters.types);
    if (filters.consTypes)
        filterProto.setConsequenceTypeList(filters.consTypes);
    if (filters.issuedBy)
        filterProto.setIssuedByList(filters.issuedBy);
    if (filters.issuedTo)
        filterProto.setIssuedToList(filters.issuedTo);
    if (filters.consCatTypes)
        filterProto.setConsequenceCatTypeList(filters.consCatTypes);
    if (filters.consStatus)
        filterProto.setConsequenceStatusList(filters.consStatus);
    if (filters.automationTypes)
        filterProto.setAutomationTypeList(filters.automationTypes);
    if (filters.consID)
        filterProto.setConsequenceId(filters.consID);
    if (filters.autoGroup)
        filterProto.setAutomationGroupId(filters.autoGroup);
    if (typeof filters.consequenceCategory === 'number') {
        const wrapper = new wrappers_pb_1.Int32Value();
        wrapper.setValue(filters.consequenceCategory);
        filterProto.setConsequenceCategory(wrapper);
    }
    if (offset)
        filterProto.setOffset(offset);
    if (limit)
        filterProto.setLimit(limit);
    filterProto.setUserListList(filters.userList);
    return filterProto;
};
exports.fromProto = (proto) => {
    const startDateProto = proto.getStartDate();
    const endDateProto = proto.getEndDate();
    let startDate;
    let endDate;
    if (startDateProto)
        startDate = shared_grpc_1.dateTimeMessageToDayjsObject(startDateProto);
    if (endDateProto)
        endDate = shared_grpc_1.dateTimeMessageToDayjsObject(endDateProto);
    return {
        startDate,
        endDate,
        grades: proto.getGradeList(),
        types: proto.getBehaviorTypeList(),
        consTypes: proto.getConsequenceTypeList(),
        issuedBy: proto.getIssuedByList(),
        issuedTo: proto.getIssuedToList(),
        consCatTypes: proto.getConsequenceCatTypeList(),
        consStatus: proto.getConsequenceStatusList(),
        offset: proto.getOffset(),
        limit: proto.getLimit(),
        automationTypes: proto.getAutomationTypeList(),
        consID: proto.getConsequenceId(),
        autoGroup: proto.getAutomationGroupId(),
        consequenceCategory: typeof proto.getConsequenceCategory() === 'number'
            ? proto.getConsequenceCategory().getValue()
            : undefined,
        userList: proto.getUserListList(),
    };
};