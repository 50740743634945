<div class="container">
  <button
    *ngFor="let option of options$ | async"
    class="button btn-reset"
    type="button"
    matRipple
    [attr.data-analytics]="id + '-' + option"
    [attr.data-test]="id + '-' + option"
    [matRippleDisabled]="readonly"
    [matRippleColor]="'rgba(255, 255, 255, .10)'"
    [title]="option"
    [class.active]="activeOptions$ | async | includes: option"
    [class.disabled]="readonly"
    [class.invalid]="
      (invalidDays | includes: option) &&
      (activeOptions$ | async | includes: option)
    "
    (click)="toggleOption(option)">
    {{ option | substring: 0:1 }}
  </button>
</div>
