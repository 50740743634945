"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HallPassBlackOutScheduleMapper = exports.HallPassBlackOutMapper = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const day_1 = require("minga/libraries/day/index");
const domain_1 = require("minga/libraries/domain/index");
const hall_pass_pb_1 = require("minga/proto/hall_pass/hall_pass_pb");
var HallPassBlackOutMapper;
(function (HallPassBlackOutMapper) {
    function toProto(hallPassBlackOut) {
        const msg = new hall_pass_pb_1.HallPassBlackOut();
        if (hallPassBlackOut.id) {
            msg.setId(hallPassBlackOut.id);
        }
        if (hallPassBlackOut.weekday) {
            msg.setWeekday(hallPassBlackOut.weekday);
        }
        if (hallPassBlackOut.startTime) {
            msg.setStartTime(hallPassBlackOut.startTime);
        }
        if (hallPassBlackOut.endTime) {
            msg.setEndTime(hallPassBlackOut.endTime);
        }
        if (hallPassBlackOut.mingaId) {
            msg.setMingaId(hallPassBlackOut.mingaId);
        }
        if (hallPassBlackOut.createdBy) {
            msg.setCreatedBy(hallPassBlackOut.createdBy);
        }
        if (hallPassBlackOut.name) {
            msg.setName(hallPassBlackOut.name);
        }
        if (hallPassBlackOut.active !== undefined) {
            msg.setActive(hallPassBlackOut.active);
        }
        if (hallPassBlackOut.blockMonday !== undefined) {
            msg.setBlockMonday(hallPassBlackOut.blockMonday);
        }
        if (hallPassBlackOut.blockTuesday !== undefined) {
            msg.setBlockTuesday(hallPassBlackOut.blockTuesday);
        }
        if (hallPassBlackOut.blockWednesday !== undefined) {
            msg.setBlockWednesday(hallPassBlackOut.blockWednesday);
        }
        if (hallPassBlackOut.blockThursday !== undefined) {
            msg.setBlockThursday(hallPassBlackOut.blockThursday);
        }
        if (hallPassBlackOut.blockFriday !== undefined) {
            msg.setBlockFriday(hallPassBlackOut.blockFriday);
        }
        if (hallPassBlackOut.blockSaturday !== undefined) {
            msg.setBlockSaturday(hallPassBlackOut.blockSaturday);
        }
        if (hallPassBlackOut.blockSunday !== undefined) {
            msg.setBlockSunday(hallPassBlackOut.blockSunday);
        }
        if (hallPassBlackOut.schedules) {
            msg.setHallPassBlackOutScheduleList(hallPassBlackOut.schedules.map(HallPassBlackOutScheduleMapper.toProto));
        }
        return msg;
    }
    HallPassBlackOutMapper.toProto = toProto;
    function fromProto(msg) {
        const hallPassBlackOut = {
            id: msg.getId(),
            startTime: HallPassBlackOutScheduleMapper.parseTime(msg.getStartTime()),
            endTime: HallPassBlackOutScheduleMapper.parseTime(msg.getEndTime()),
            mingaId: msg.getMingaId(),
            createdBy: msg.getCreatedBy(),
            name: msg.getName(),
            active: msg.getActive(),
            blockMonday: msg.getBlockMonday(),
            blockTuesday: msg.getBlockTuesday(),
            blockWednesday: msg.getBlockWednesday(),
            blockThursday: msg.getBlockThursday(),
            blockFriday: msg.getBlockFriday(),
            blockSaturday: msg.getBlockSaturday(),
            blockSunday: msg.getBlockSunday(),
            schedules: msg
                .getHallPassBlackOutScheduleList()
                .map(HallPassBlackOutScheduleMapper.fromProto),
        };
        const weekday = msg.getWeekday();
        if (weekday) {
            hallPassBlackOut.weekday = ts_enum_util_1.$enum(domain_1.HallPassBlackOutWeekdaysEnum).getValueOrDefault(msg.getWeekday(), domain_1.HallPassBlackOutWeekdaysEnum.EVERYDAY);
        }
        return hallPassBlackOut;
    }
    HallPassBlackOutMapper.fromProto = fromProto;
})(HallPassBlackOutMapper = exports.HallPassBlackOutMapper || (exports.HallPassBlackOutMapper = {}));
var HallPassBlackOutScheduleMapper;
(function (HallPassBlackOutScheduleMapper) {
    function parseTime(t) {
        var d = new Date();
        var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
        d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
        d.setMinutes(parseInt(time[2]) || 0);
        d.setSeconds(0);
        return day_1.day(d).format('HH:mm:ss');
    }
    HallPassBlackOutScheduleMapper.parseTime = parseTime;
    function fromProto(msg) {
        return {
            startTime: HallPassBlackOutScheduleMapper.parseTime(msg.getStartTime()),
            endTime: HallPassBlackOutScheduleMapper.parseTime(msg.getEndTime()),
        };
    }
    HallPassBlackOutScheduleMapper.fromProto = fromProto;
    function toProto(hallPassBlackOutSchedule) {
        const msg = new hall_pass_pb_1.HallPassBlackOutSchedule();
        msg.setStartTime(hallPassBlackOutSchedule.startTime);
        msg.setEndTime(hallPassBlackOutSchedule.endTime);
        return msg;
    }
    HallPassBlackOutScheduleMapper.toProto = toProto;
})(HallPassBlackOutScheduleMapper = exports.HallPassBlackOutScheduleMapper || (exports.HallPassBlackOutScheduleMapper = {}));