"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const automation_pb_1 = require("minga/proto/automation/automation_pb");
exports.toProto = (automation) => {
    var _a, _b, _c;
    const message = new automation_pb_1.Automation();
    if (automation.id)
        message.setId(automation.id);
    if (automation.mingaId)
        message.setMingaId(automation.mingaId);
    if (automation.name)
        message.setName(automation.name);
    if (automation.description)
        message.setDescription(automation.description);
    if (typeof automation.active !== 'undefined')
        message.setActive(automation.active);
    if (automation.resetCounter) {
        message.setResetCounter(automation.resetCounter);
    }
    if (automation.note) {
        message.setNote(automation.note);
    }
    if (automation.triggers) {
        const triggersMsg = new automation_pb_1.AutomationTriggers();
        (_a = automation.triggers.pbisTriggers) === null || _a === void 0 ? void 0 : _a.forEach(trigger => {
            const msg = new automation_pb_1.AutomationTrigger();
            msg.setId(trigger.id);
            msg.setIconType(trigger.iconType || '');
            msg.setIconColor(trigger.iconColor || '');
            msg.setName(trigger.name || '');
            triggersMsg.addBehaviors(msg);
        });
        (_b = automation.triggers.checkinReasonTriggers) === null || _b === void 0 ? void 0 : _b.forEach(trigger => {
            const msg = new automation_pb_1.AutomationTrigger();
            msg.setId(trigger.id);
            msg.setIconType(trigger.iconType || '');
            msg.setIconColor(trigger.iconColor || '');
            msg.setName(trigger.name || '');
            triggersMsg.addCheckinReason(msg);
        });
        message.setTriggers(triggersMsg);
    }
    if ((_c = automation.autoReset) === null || _c === void 0 ? void 0 : _c.length)
        for (const autoReset of automation.autoReset) {
            const autoResetMsg = new automation_pb_1.AutomationAutoReset();
            autoResetMsg.setFrequency(autoReset.frequency);
            if (autoReset.frequency === domain_1.AutomationAutoResetFrequency.CUSTOM) {
                autoResetMsg.setDate(autoReset.date);
                autoResetMsg.setTime(autoReset.time);
            }
            message.addAutoReset(autoResetMsg);
        }
    return message;
};
exports.fromProto = (message) => {
    let pbisTriggers = [];
    let checkinReasonTriggers = [];
    const triggerMsg = message.getTriggers();
    if (triggerMsg) {
        pbisTriggers = triggerMsg.getBehaviorsList().map(behavior => {
            return { id: behavior.getId() };
        });
        checkinReasonTriggers = triggerMsg.getCheckinReasonList().map(reason => {
            return { id: reason.getId() };
        });
    }
    const autoResetMsgList = message.getAutoResetList();
    const autoResetList = [];
    if (autoResetMsgList === null || autoResetMsgList === void 0 ? void 0 : autoResetMsgList.length)
        for (const autoResetMsg of autoResetMsgList) {
            const frequency = ts_enum_util_1.$enum(domain_1.AutomationAutoResetFrequency).asValueOrThrow(autoResetMsg.getFrequency());
            const autoReset = {
                frequency,
            };
            const isCustom = (obj) => obj.frequency === domain_1.AutomationAutoResetFrequency.CUSTOM;
            const validFrequency = (obj) => obj.frequency === domain_1.AutomationAutoResetFrequency.MONTHLY ||
                obj.frequency === domain_1.AutomationAutoResetFrequency.DAILY ||
                obj.frequency === domain_1.AutomationAutoResetFrequency.WEEKLY ||
                obj.frequency === domain_1.AutomationAutoResetFrequency.CUSTOM;
            if (isCustom(autoReset)) {
                autoReset.date = autoResetMsg.getDate();
                autoReset.time = autoResetMsg.getTime();
            }
            else if (!validFrequency(autoReset))
                throw new Error('invalid frequency');
            autoResetList.push(autoReset);
        }
    return {
        id: message.getId(),
        mingaId: message.getMingaId(),
        name: message.getName(),
        description: message.getDescription(),
        note: message.getNote(),
        active: message.getActive(),
        resetCounter: message.getResetCounter(),
        triggers: {
            pbisTriggers,
            checkinReasonTriggers,
        },
        autoReset: autoResetList,
    };
};