"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const flex_time_pb = require("minga/proto/flex_time/flex_time_pb");
const restriction_1 = require("minga/libraries/shared-grpc/restriction/index");
const message_1 = require("minga/libraries/shared-grpc/util/message");
exports.toProto = (activity) => {
    const message = new flex_time_pb.FlexTimeActivity();
    if (activity.mingaId)
        message.setMingaId(activity.mingaId);
    if (activity.id)
        message.setId(activity.id);
    if (activity.name)
        message.setName(activity.name);
    if (activity.registrationLimit)
        message.setRegistrationLimit(activity.registrationLimit);
    if (activity.createdBy)
        message.setCreatedBy(activity.createdBy);
    if (activity.imagePath)
        message.setImagePath(activity.imagePath);
    if (activity.bannerHash)
        message.setBannerHash(activity.bannerHash);
    if (activity.restrictionId)
        message.setRestrictionId(activity.restrictionId);
    if (activity.restriction)
        message.setRestriction(restriction_1.RestrictionMapper.toProto(activity.restriction));
    if (activity.bannerImage)
        message.setBannerImage(activity.bannerImage);
    if (activity.allowStudentToRegister)
        message.setAllowStudentToRegister(activity.allowStudentToRegister);
    if (activity.description)
        message.setDescription(activity.description);
    if (activity.createdByPerson)
        message.setCreatedByPerson(message_1.asObjectToMessage(activity.createdByPerson, new flex_time_pb.FlexTimeActivityCreatedByPerson()));
    if (activity.location)
        message.setLocation(activity.location);
    if (activity.type)
        message.setType(message_1.asObjectToMessage(activity.type, new flex_time_pb.FlexTimeActivityType()));
    return message;
};
exports.fromProto = (message) => {
    var _a, _b, _c;
    let restriction;
    if (message.getRestriction()) {
        restriction = restriction_1.RestrictionMapper.fromProto(message.getRestriction());
    }
    return {
        mingaId: message.getMingaId(),
        id: (_a = message.getId()) !== null && _a !== void 0 ? _a : undefined,
        name: message.getName(),
        description: message.getDescription(),
        registrationLimit: message.getRegistrationLimit(),
        createdBy: message.getCreatedBy(),
        bannerHash: message.getBannerHash() || undefined,
        bannerImage: message.getBannerImage() || undefined,
        imagePath: message.getImagePath() || undefined,
        allowStudentToRegister: message.getAllowStudentToRegister(),
        restrictionId: message.getRestrictionId(),
        restriction,
        createdByPerson: (_b = message.getCreatedByPerson()) === null || _b === void 0 ? void 0 : _b.toObject(),
        location: message.getLocation(),
        type: (_c = message.getType()) === null || _c === void 0 ? void 0 : _c.toObject(),
    };
};