"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IBannerCategoryMapper = void 0;
const banner_pb_1 = require("minga/proto/gateway/banner_pb");
var IBannerCategoryMapper;
(function (IBannerCategoryMapper) {
    function toBannerLibraryStats(category) {
        const countMsg = new banner_pb_1.BannerLibrarayStats.CategoryCount();
        const catEnum = bannerCategorySqlEnumToMsgEnum(category.name);
        countMsg.setCategory(catEnum);
        countMsg.setCategoryCount(category.count);
        return countMsg;
    }
    IBannerCategoryMapper.toBannerLibraryStats = toBannerLibraryStats;
    function bannerCategoryMsgEnumToSqlEnum(catEnum) {
        switch (catEnum) {
            case banner_pb_1.Banner.Category.ART:
                return 'ART';
            case banner_pb_1.Banner.Category.CAUSES:
                return 'CAUSES';
            case banner_pb_1.Banner.Category.FOOD:
                return 'FOOD';
            case banner_pb_1.Banner.Category.GENERAL:
                return 'GENERAL';
            case banner_pb_1.Banner.Category.SCHOOL_EVENTS:
                return 'SCHOOL_EVENTS';
            case banner_pb_1.Banner.Category.SEASONAL:
                return 'SEASONAL';
            case banner_pb_1.Banner.Category.SPORTS:
                return 'SPORTS';
            case banner_pb_1.Banner.Category.GROUP:
                return 'GROUP';
        }
        return 'GENERAL';
    }
    IBannerCategoryMapper.bannerCategoryMsgEnumToSqlEnum = bannerCategoryMsgEnumToSqlEnum;
    function bannerCategorySqlEnumToMsgEnum(catEnum) {
        switch (catEnum) {
            case 'ART':
                return banner_pb_1.Banner.Category.ART;
            case 'CAUSES':
                return banner_pb_1.Banner.Category.CAUSES;
            case 'FOOD':
                return banner_pb_1.Banner.Category.FOOD;
            case 'GENERAL':
                return banner_pb_1.Banner.Category.GENERAL;
            case 'SCHOOL_EVENTS':
                return banner_pb_1.Banner.Category.SCHOOL_EVENTS;
            case 'SEASONAL':
                return banner_pb_1.Banner.Category.SEASONAL;
            case 'SPORTS':
                return banner_pb_1.Banner.Category.SPORTS;
            case 'GROUP':
                return banner_pb_1.Banner.Category.GROUP;
        }
        return banner_pb_1.Banner.Category.GENERAL;
    }
    IBannerCategoryMapper.bannerCategorySqlEnumToMsgEnum = bannerCategorySqlEnumToMsgEnum;
})(IBannerCategoryMapper = exports.IBannerCategoryMapper || (exports.IBannerCategoryMapper = {}));