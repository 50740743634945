import { Injectable, OnDestroy } from '@angular/core';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HallPassStatusEnum, IHallPassType } from 'minga/libraries/domain';
import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';

import {
  HpmDashboardFilter,
  HpmDashboardTableItem,
  mapHallPassToDashboardItem,
} from '@modules/hallpass-manager';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { HallPassService } from '@shared/services/hall-pass';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';

import { KioskHallPassTableMessage } from '../constants';
import { KioskStateStorage } from '../utils';

@Injectable()
export class KioskHallPassTableService implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);

  public readonly timeTicker$ = interval(1000).pipe(
    takeUntil(this._destroyedSubject),
  );

  private readonly _enableTableSubject = new BehaviorSubject<boolean>(
    KioskStateStorage.get()?.hallPassTableEnabled ?? false,
  );
  public readonly enableTable$ = this._enableTableSubject.asObservable();

  private readonly _passTypeData = new BehaviorSubject<IHallPassType[]>([]);
  public readonly passTypeData$ = this._passTypeData.asObservable();

  private readonly _activePasses = new BehaviorSubject<HpmDashboardTableItem[]>(
    [],
  );
  public readonly activePasses$ = this._activePasses.asObservable();

  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _hallPass: HallPassService,
    private _hallPassActions: HallPassActionsService,
  ) {
    this._hallPassActions.passCountdownChange$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(({ id, type }) => {
        if (type === 'remove') this.removePass(id);
      });
  }

  public ngOnDestroy(): void {
    this._enableTableSubject.complete();
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async toggleEnableTable() {
    try {
      const tableEnabled = !this._enableTableSubject.getValue();
      KioskStateStorage.set({ hallPassTableEnabled: tableEnabled });
      this._enableTableSubject.next(tableEnabled);
      this._systemAlertSnackBar.success(
        tableEnabled
          ? KioskHallPassTableMessage.SETTING_ENABLED_MESSAGE
          : KioskHallPassTableMessage.SETTING_DISABLED_MESSAGE,
      );
    } catch (error) {
      this._systemAlertSnackBar.error('Error setting active passes preference');
    }
  }

  public addPass(pass: HallPassWithType.AsObject) {
    const now = day();
    const mappedPass = mapHallPassToDashboardItem(pass, now);
    const passes = this._activePasses.value;
    if (mappedPass) passes.push(mappedPass);
    this._activePasses.next(passes);
  }

  public removePass(passId: number) {
    const passes = this._activePasses.value;
    if (passes) {
      const filtered = passes.filter(pass => pass.id !== passId);
      this._activePasses.next(filtered);
    }
  }

  public async fetchActivePasses(): Promise<void> {
    const passes = await this._hallPass.listPasses({
      [HpmDashboardFilter.PASS_ID]: undefined,
      [HpmDashboardFilter.AUTHORS]: undefined,
      [HpmDashboardFilter.RECIPIENTS]: undefined,
      [HpmDashboardFilter.SHOW_MINE]: true,
      [HpmDashboardFilter.PASS_STATUS]: [
        'Active',
        'Overdue',
      ] as HallPassStatusEnum[],
    });
    const now = day();
    const mappedPasses = passes.map(item =>
      mapHallPassToDashboardItem(item, now),
    );

    // need this because the backend will always return the recently ended depending on a global setting
    const activePasses = mappedPasses.filter(
      pass => pass.status.end > new Date(),
    );
    this._activePasses.next(activePasses);
  }
}
