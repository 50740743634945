import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { ReplaySubject } from 'rxjs';

import { scrollIntoView } from 'minga/app/src/app/util/scroll-into-view';
import { IMingaDistrict } from 'minga/libraries/domain';
import { RootService } from 'src/app/minimal/services/RootService';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { DistrictsService } from '@shared/services/districts/districts.service';

import {
  createForm,
  DISTRICT_FORM_FIELDS,
  DISTRICT_MESSAGES,
  getData,
  MmDistrictEditData,
  MmDistrictEditResponse,
  setForm,
} from '../../mm-districts.constants';

@Component({
  selector: 'mg-mm-districts-edit',
  templateUrl: './mm-districts-edit.component.html',
  styleUrls: ['./mm-districts-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmDistrictsEditComponent
  extends CrudFormBase<IMingaDistrict>
  implements OnInit, OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(1);

  public MESSAGES = DISTRICT_MESSAGES;
  public FORM_FIELDS = DISTRICT_FORM_FIELDS;
  public readonly form = this._fb.group(createForm());

  @ViewChild('crudForm', { static: false })
  crudForm?: ElementRef<HTMLElement>;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: MmDistrictEditData,
    private _modalRef: ModalOverlayRef<
      MmDistrictEditResponse,
      MmDistrictEditData
    >,
    private _fb: FormBuilder,
    private _systemAlertModal: SystemAlertModalService,
    public rootService: RootService,
    private _districtsService: DistrictsService,
  ) {
    super({
      id: dialogData?.id,
      get: async id => {
        return this._districtsService.get(id);
      },
      create: data => {
        return this._districtsService.upsert(data as IMingaDistrict);
      },
      update: data => {
        return this._districtsService.upsert(data as IMingaDistrict);
      },
      delete: async data => {
        await this._districtsService.delete(data.id);
        return data;
      },
      onSetForm: data => {
        setForm(data, this.form);
      },
      onValidate: data => {
        markNestedFormGroupAsDirty(this.form);
        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE, {
            deleted: data.id,
          });
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            created: data,
          });
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            updated: data,
          });
        }
      },
      onSubmit: data => {
        const mapped = getData(data, this.form);
        return mapped;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: DISTRICT_MESSAGES.DELETE_CONFIRM_DISCARD_TITLE,
          message: DISTRICT_MESSAGES.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: DISTRICT_MESSAGES.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: DISTRICT_MESSAGES.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: DISTRICT_MESSAGES.DELETE_CONFIRM_TITLE,
          message: DISTRICT_MESSAGES.DELETE_CONFIRM_DESC,
          closeBtn: DISTRICT_MESSAGES.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: DISTRICT_MESSAGES.DELETE_CONFIRM_DELETE_BTN,
          input: {
            placeholder: 'Type DELETE to confirm',
          },
        });

        const response = await modalRef.afterClosed().toPromise();

        return (
          response?.type === SystemAlertCloseEvents.CONFIRM &&
          response.inputValue === 'DELETE'
        );
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.crudForm.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
