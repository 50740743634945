import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { BehaviorSubject, from, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IBellSchedule } from 'minga/libraries/domain';
import { RootService } from 'src/app/minimal/services/RootService';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  BellSchedulesInterface,
  BellSchedulesService,
} from '@shared/services/bell-schedule/bs-schedules.interface';
import {
  BellScheduleTermsInterface,
  BellScheduleTermsService,
} from '@shared/services/bell-schedule/bs-terms.interface';
import { MediaService } from '@shared/services/media';

import {
  SCHEDULE_TABLE_COLUMNS,
  SCHEDULES_MESSAGES,
  ScheduleTableColumn,
} from '../../constants/mm-bs-schedules.constants';
import { BellScheduleCacheService } from '../../services/bell-schedule-cache.service';
import { BsScheduleEditData } from '../../types/mm-bell-schedule.types';
import { MmBsSchedulesEditComponent } from '../mm-bs-schedules-edit/mm-bs-schedules-edit.component';

@Component({
  selector: 'mg-mm-bs-schedules',
  templateUrl: './mm-bs-schedules.component.html',
  styleUrls: ['./mm-bs-schedules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BellScheduleCacheService],
})
export class MmBsSchedulesComponent implements OnInit, OnDestroy {
  /** Constants */
  public MESSAGES = SCHEDULES_MESSAGES;

  /** Subjects */
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _loadingStateSubject = new BehaviorSubject<
    'idle' | 'loading' | 'error'
  >('idle');
  private _schedulesSubject = new BehaviorSubject<IBellSchedule[]>([]);

  public readonly COLUMN_DEF = ScheduleTableColumn;

  /** Observables */
  public loadingState$ = this._loadingStateSubject.asObservable();

  public noTerms$ = from(this._termService.fetchAll()).pipe(
    map(terms => terms.length === 0),
  );

  public readonly dataSource = new MatTableDataSource<IBellSchedule>([]);

  public readonly displayedColumns$ = this._media.breakpoint$.pipe(
    takeUntil(this._destroyedSubject),
    map(bp => {
      if (['xsmall', 'small'].includes(bp)) return [ScheduleTableColumn.MOBILE];
      else return SCHEDULE_TABLE_COLUMNS;
    }),
  );

  constructor(
    private _modalService: ModalOverlayService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    @Inject(BellSchedulesInterface)
    private _schedulesService: BellSchedulesService,
    @Inject(BellScheduleTermsInterface)
    private _termService: BellScheduleTermsService,
    private _media: MediaService,
    private _rootService: RootService,
    private _cdr: ChangeDetectorRef,
    private _bsCacheService: BellScheduleCacheService,
  ) {}

  ngOnInit(): void {
    this._schedulesSubject
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(schedules => {
        this.dataSource.data = schedules;
        this._cdr.markForCheck();
      });

    this._fetchSchedules();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async openEditModal(id?: number) {
    const modalRef = this._modalService.open<BsScheduleEditData>(
      MmBsSchedulesEditComponent,
      {
        data: { id },
      },
    );

    const response = await modalRef.afterClosed.toPromise();
    const { type, data } = response;

    let message = '';
    let cacheData;

    if (type === ModalOverlayServiceCloseEventType.CREATE) {
      cacheData = {
        action: 'create',
        data: data.created,
      };
      message = SCHEDULES_MESSAGES.SNACKBAR_CREATE_SUCCESS;
    }

    if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
      cacheData = {
        action: 'update',
        data: data.updated,
      };
      message = SCHEDULES_MESSAGES.SNACKBAR_UPDATE_SUCCESS;
    }

    if (type === ModalOverlayServiceCloseEventType.DELETE) {
      cacheData = {
        action: 'delete',
        data: data.deleted,
      };
      message = SCHEDULES_MESSAGES.SNACKBAR_DELETE_SUCCESS;
    }

    if (message) {
      this._systemAlertSnackBar.open({
        type: 'success',
        message,
      });
    }

    if (cacheData) {
      const updated = await this._schedulesService.updateListCache(cacheData);
      this._schedulesSubject.next(updated);

      // delete all bell schedule caches
      this._bsCacheService.clearBellScheduleCache();
    }
  }

  public trackById(index: number, item) {
    return item?.id || index;
  }

  public async copySchedule(schedule) {
    try {
      const copy = await this._rootService.addLoadingPromise(
        this._schedulesService.duplicate(schedule),
      );
      const updated = await this._schedulesService.updateListCache({
        action: 'create',
        data: copy,
      });
      this._schedulesSubject.next(updated);

      this._systemAlertSnackBar.open({
        type: 'success',
        message: SCHEDULES_MESSAGES.SNACKBAR_DUPLICATED_SUCCESS,
      });
    } catch (error) {
      console.error(error);
      this._systemAlertSnackBar.error(
        SCHEDULES_MESSAGES.ERROR_DUPLICATING_SCHEDULE,
      );
    }
  }

  private async _fetchSchedules() {
    try {
      this._loadingStateSubject.next('loading');
      const schedules = await this._schedulesService.fetchAll();
      this._schedulesSubject.next(schedules);
      this._loadingStateSubject.next('idle');
    } catch (error) {
      this._loadingStateSubject.next('error');
      this._systemAlertSnackBar.error(SCHEDULES_MESSAGES.ERROR_LOADING_DATA);
    }
  }
}
