"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateConsequenceMapper = exports.ConsequenceMapper = void 0;
const pbis_pb = require("minga/proto/pbis/pbis_pb");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const person_view_pb_1 = require("minga/proto/gateway/person_view_pb");
const consequence_type_mappers_proto_1 = require("minga/libraries/shared-grpc/consequences/consequence-type.mappers.proto");
var ConsequenceMapper;
(function (ConsequenceMapper) {
    ConsequenceMapper.toProto = (consequence) => {
        const msg = new pbis_pb.Consequence();
        msg.setId(consequence.id);
        msg.setTypeId(consequence.typeId);
        msg.setIssuedAt(shared_grpc_1.dateObjectToDateTimeMessage(consequence.issuedAt));
        if (consequence.issuedBy)
            msg.setIssuedBy(consequence.issuedBy);
        msg.setIssuedTo(consequence.issuedTo);
        if (consequence.type) {
            msg.setType(consequence_type_mappers_proto_1.ConsequenceTypeMapper.toProto(consequence.type));
        }
        if (consequence.status) {
            msg.setStatus(consequence.status);
        }
        if (consequence.completedAt) {
            msg.setCompletedAt(shared_grpc_1.dateObjectToDateTimeMessage(consequence.completedAt));
        }
        if (consequence.completeBy) {
            msg.setCompleteBy(shared_grpc_1.dateObjectToDateTimeMessage(consequence.completeBy));
        }
        if (consequence.issuedByPerson) {
            msg.setIssuedByPerson(shared_grpc_1.asObjectToMessage(consequence.issuedByPerson, new person_view_pb_1.PersonViewMinimal()));
        }
        if (consequence.issuedToPerson) {
            msg.setIssuedToPerson(shared_grpc_1.asObjectToMessage(consequence.issuedToPerson, new person_view_pb_1.PersonViewMinimal()));
        }
        if (consequence.note)
            msg.setNotes(consequence.note);
        msg.setComplete(consequence.complete || false);
        if (consequence.imported)
            msg.setImported(consequence.imported);
        if (consequence.automationName)
            msg.setAutomationName(consequence.automationName);
        if (consequence.sourceId)
            msg.setSourceId(consequence.sourceId);
        if (consequence.handledByOverdueAction)
            msg.setOverdueActionType(consequence.handledByOverdueAction);
        if (consequence.actionName)
            msg.setActionName(consequence.actionName);
        if (consequence.completedBy)
            msg.setCompletedBy(consequence.completedBy);
        if (consequence.completedByPerson) {
            msg.setCompletedByPerson(shared_grpc_1.asObjectToMessage(consequence.completedByPerson, new person_view_pb_1.PersonViewMinimal()));
        }
        if (consequence.sourceOverdueActionType)
            msg.setSourceOverdueActionType(consequence.sourceOverdueActionType);
        return msg;
    };
    ConsequenceMapper.toProtoEditable = (cons) => {
        const msg = ConsequenceMapper.toProto(cons);
        const editable = new pbis_pb.EditableConsequence();
        editable.setConsequence(msg);
        if (cons.manuallyUpdatedAt)
            editable.setManuallyUpdatedAt(shared_grpc_1.dateObjectToDateTimeMessage(cons.manuallyUpdatedAt));
        return editable;
    };
    ConsequenceMapper.fromProto = (consequence) => {
        var _a, _b, _c;
        const isOverdueActionType = Object.values(domain_1.OverdueConsequenceActionType).includes(consequence.getOverdueActionType());
        const overdueActionType = isOverdueActionType
            ? consequence.getOverdueActionType()
            : undefined;
        return {
            id: consequence.getId(),
            typeId: consequence.getTypeId(),
            type: consequence.getType()
                ? consequence_type_mappers_proto_1.ConsequenceTypeMapper.fromProto(consequence.getType())
                : undefined,
            issuedAt: shared_grpc_1.dateTimeMessageToDateObject(consequence.getIssuedAt()),
            issuedTo: consequence.getIssuedTo(),
            recipientId: consequence.getIssuedTo(),
            issuedBy: consequence.getIssuedBy(),
            completeBy: consequence.getCompleteBy()
                ? shared_grpc_1.dateTimeMessageToDateObject(consequence.getCompleteBy())
                : undefined,
            completedAt: consequence.getCompletedAt()
                ? shared_grpc_1.dateTimeMessageToDateObject(consequence.getCompletedAt())
                : undefined,
            status: consequence.getStatus(),
            issuedByPerson: (_a = consequence.getIssuedByPerson()) === null || _a === void 0 ? void 0 : _a.toObject(),
            issuedToPerson: (_b = consequence.getIssuedToPerson()) === null || _b === void 0 ? void 0 : _b.toObject(),
            complete: consequence.getComplete(),
            imported: consequence.getImported(),
            automationName: consequence.getAutomationName(),
            note: consequence.getNotes(),
            sourceId: consequence.getSourceId(),
            handledByOverdueAction: overdueActionType,
            actionName: consequence.getActionName(),
            completedBy: consequence.getCompletedBy(),
            completedByPerson: (_c = consequence.getCompletedByPerson()) === null || _c === void 0 ? void 0 : _c.toObject(),
            sourceOverdueActionType: consequence.getSourceOverdueActionType(),
        };
    };
    ConsequenceMapper.fromProtoEditable = (msg) => {
        const cons = ConsequenceMapper.fromProto(msg.getConsequence());
        const manuallyUpdatedAt = msg.getManuallyUpdatedAt()
            ? shared_grpc_1.dateTimeMessageToDateObject(msg.getManuallyUpdatedAt())
            : undefined;
        return Object.assign(Object.assign({}, cons), { manuallyUpdatedAt });
    };
})(ConsequenceMapper = exports.ConsequenceMapper || (exports.ConsequenceMapper = {}));
var UpdateConsequenceMapper;
(function (UpdateConsequenceMapper) {
    UpdateConsequenceMapper.toProto = (consequence) => {
        const msg = new pbis_pb.UpdateConsequences();
        msg.setIssuedToList(consequence.issuedTo);
        if (consequence.id)
            msg.setId(consequence.id);
        if (consequence.type)
            msg.setType(consequence.type);
        if (consequence.notes)
            msg.setNotes(consequence.notes);
        if (consequence.status)
            msg.setStatus(consequence.status);
        if (consequence.completeBy) {
            msg.setCompleteBy(shared_grpc_1.dayJsObjectToDateTimeMsg(consequence.completeBy));
        }
        if (consequence.completedAt) {
            msg.setCompletedAt(shared_grpc_1.dayJsObjectToDateTimeMsg(consequence.completedAt));
        }
        return msg;
    };
    UpdateConsequenceMapper.fromProto = (msg, tz) => {
        const consequence = {
            id: msg.getId(),
            type: msg.getType(),
            status: msg.getStatus(),
            issuedTo: msg.getIssuedToList(),
            issuedBy: msg.getIssuedBy(),
            notes: msg.getNotes(),
        };
        if (msg.getCompleteBy()) {
            consequence.completeBy = shared_grpc_1.dateTimeMessageToDayjsObject(msg.getCompleteBy(), tz);
        }
        if (msg.getCompletedAt()) {
            consequence.completedAt = shared_grpc_1.dateTimeMessageToDayjsObject(msg.getCompletedAt(), tz);
        }
        return consequence;
    };
})(UpdateConsequenceMapper = exports.UpdateConsequenceMapper || (exports.UpdateConsequenceMapper = {}));