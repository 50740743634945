"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPointsNotification = void 0;
const domain_1 = require("minga/libraries/domain/index");
exports.isPointsNotification = (type) => {
    if (!type) {
        return false;
    }
    switch (type) {
        case domain_1.INotificationType.POINTS_EVENT_CHECKIN:
            return true;
        case domain_1.INotificationType.POINTS_IMAGE_CHALLENGE:
            return true;
        case domain_1.INotificationType.POINTS_TEXT_CHALLENGE:
            return true;
        case domain_1.INotificationType.POINTS_POLL:
            return true;
        case domain_1.INotificationType.POINTS_CUSTOM:
            return true;
        default:
            break;
    }
    return false;
};