<!-- Angular Material Table -->
<mat-table
  class="list-table no-explicit-mobile-layout"
  [dataSource]="dataSource">
  <!-- Indicator Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.INDICATOR">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        class="indicator"
        [ngClass]="'indicator-' + STATUS_COLOR_MAP[element.status.state]"></div>
    </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.STATUS">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_STATUS }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-tag [color]="STATUS_COLOR_MAP[element.status.state]">
        {{ element.status.state }}
      </mg-tag>
    </mat-cell>
  </ng-container>

  <!-- Timer Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.TIMER">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_TIMER }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-icon
        *ngIf="
          showIcon(element.manuallyEndPass) &&
          element.status.state == PASS_STATUS.ACTIVE
        "
        matTooltip="Automatically ended"
        matTooltipClass="mg-tooltip"
        class="auto-end-icon"
        iconName="mg-auto-end">
      </mg-icon>
      <ng-container
        *ngIf="
          element
            | countdown
              : 0
              : (manuallyEndedPassesSetting$ | async)
              : kioskHallPassTable.timeTicker$
              : element.manuallyEndPass
            | async as countdown;
          else inlineLoadingSpinnerTemplate
        ">
        <mg-text
          *ngIf="countdown !== 'DISABLED'"
          variant="body-md"
          textAlign="left"
          [numberOfLines]="1">
          {{ countdown }}
        </mg-text>
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Picture Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.RECIPIENT_PICTURE">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        class="picture-container"
        [mgStudentPicture]="element.filename"></div>
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.RECIPIENT_NAME">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_NAME }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-text
        variant="body-md"
        [numberOfLines]="2">
        {{ element.recipientName }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <!-- Pass Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.PASS_TYPE">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_PASS_TYPE }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="pass-type">
        <mg-icon
          class="pass-type-icon"
          matTooltipClass="mg-tooltip"
          [matTooltip]="element.passName"
          [iconName]="element.passIcon || 'hallpass'"
          [style.color]="element.passColor">
        </mg-icon>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Start Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.START_TIME">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_START_TIME }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.startTime | date: 'h:mm aaaaa\'m\'' as time">
        <mg-text
          variant="body-md"
          textAlign="left"
          [numberOfLines]="1">
          {{ time }}
        </mg-text>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Mobile Column -->
  <ng-container [matColumnDef]="COLUMN_DEF.MOBILE">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
        [style.width.%]="100">
        <div
          class="indicator"
          [ngClass]="
            'indicator-' + STATUS_COLOR_MAP[element.status.state]
          "></div>
        <div
          fxFlex
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="8px">
          <div
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="8px"
            [style.width.%]="100">
            <div
              class="picture-container"
              [mgStudentPicture]="element.filename"></div>
            <div
              fxFlex
              fxLayout="row"
              fxLayoutAlign="space-between stretch"
              fxLayoutGap="8px">
              <div
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="4px">
                <mg-text
                  variant="header-md-secondary"
                  [numberOfLines]="2">
                  {{ element.recipientName }}
                </mg-text>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="4px">
                  <div class="pass-type">
                    <mg-icon
                      class="pass-type-icon"
                      [iconName]="element.passIcon || 'hallpass'"
                      [style.color]="element.passColor">
                    </mg-icon>
                  </div>
                  <mg-text variant="label-md">
                    {{ element.passName }}
                  </mg-text>
                </div>
              </div>
              <div
                fxFlex="noshrink"
                fxLayout="column"
                fxLayoutAlign="start end"
                fxLayoutGap="4px">
                <mg-tag [color]="STATUS_COLOR_MAP[element.status.state]">
                  {{ element.status.state }}
                </mg-tag>
                <ng-container
                  *ngIf="
                    element
                      | countdown
                        : 0
                        : (manuallyEndedPassesSetting$ | async)
                        : kioskHallPassTable.timeTicker$
                        : element.manuallyEndPass
                      | async as countdown;
                    else inlineLoadingSpinnerTemplate
                  ">
                  <mg-text
                    *ngIf="countdown !== 'DISABLED'"
                    variant="body-md"
                    textAlign="left"
                    [numberOfLines]="1">
                    {{ countdown }}
                  </mg-text>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between start">
            <mg-text
              variant="body-sm"
              color="surface-alt">
              {{ MESSAGES.MOBILE_LABEL_START_TIME }}
            </mg-text>
            <div *ngIf="element.startTime | date: 'h:mm aaaaa\'m\'' as time">
              <mg-text
                variant="body-md"
                textAlign="left"
                [numberOfLines]="1">
                {{ time }}
              </mg-text>
            </div>
          </div>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns$ | async"
    [fxHide]="!dataSource.data.length">
  </mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns$ | async"
    [ngClass]="{
      'overdue-container': row.status.state === PASS_STATUS.OVERDUE
    }">
  </mat-row>
</mat-table>

<!-- Inline Loading Spinner -->
<ng-template #inlineLoadingSpinnerTemplate>
  <div class="inline-spinner">
    <mat-spinner
      diameter="20"
      strokeWidth="2">
    </mat-spinner>
  </div>
</ng-template>
