import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MingaPermission } from 'minga/libraries/domain';
import { IPointsHistoryItem } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import {
  PmReportsHistoryDatasourceService,
  PmReportsService,
} from '../../services';
import { PmReportsHistoryActionService } from '../../services/pm-reports-history-action.service';
import { PmReportsHistoryMessages } from './pm-reports-history.constants';

@Component({
  selector: 'mg-pm-reports-history',
  templateUrl: './pm-reports-history.component.html',
  styleUrls: [
    '../pm-reports.styles.scss',
    './pm-reports-history.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PmReportsHistoryDatasourceService, PmReportsHistoryActionService],
})
export class PmReportsHistoryComponent implements OnInit, OnDestroy {
  public readonly MESSAGES = PmReportsHistoryMessages;
  public readonly canMultiSelect$ = this._permissions.observePermission(
    MingaPermission.POINTS_MANAGE,
  );

  private _destroyedSubject = new ReplaySubject<void>(1);
  private _selectableItems: IPointsHistoryItem[] = [];

  constructor(
    public ds: PmReportsHistoryDatasourceService,
    public actionService: PmReportsHistoryActionService,
    private _permissions: PermissionsService,
    private _pmReportService: PmReportsService,
  ) {}

  ngOnInit(): void {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.clearSelection();
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this._pmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => this.actionService.clearSelection());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
