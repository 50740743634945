"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverrideBellScheduleForDateResponse = exports.OverrideBellScheduleForDateRequest = exports.DeleteBellScheduleResponse = exports.DeleteBellScheduleRequest = exports.UpsertBellScheduleResponse = exports.UpsertBellScheduleRequest = exports.GetBellScheduleResponse = exports.GetBellScheduleRequest = exports.GetBellSchedulesResponse = exports.GetBellSchedulesRequest = exports.OverrideDates = exports.IBellSchedule = exports.BellSchedule = exports.Term = void 0;
const jspb = require("google-protobuf");
const period_period_pb = require("minga/proto/period/period_pb");
const common_date_pb = require("minga/proto/common/date_pb");
class Term extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return Term.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!Term} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            title: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!Term}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new Term;
        return Term.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!Term} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!Term}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setTitle(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!Term} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getTitle();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        Term.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string title = 2;
     * @return {string}
     */
    getTitle() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setTitle(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
}
exports.Term = Term;
Term.displayName = "Term";
class BellSchedule extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, BellSchedule.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return BellSchedule.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!BellSchedule} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            periodsList: jspb.Message.toObjectList(msg.getPeriodsList(), period_period_pb.Period.toObject, includeInstance),
            daysList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
            termIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
            mingaId: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!BellSchedule}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new BellSchedule;
        return BellSchedule.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!BellSchedule} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!BellSchedule}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setName(value2);
                    break;
                case 3:
                    var value3 = new period_period_pb.Period;
                    reader.readMessage(value3, period_period_pb.Period.deserializeBinaryFromReader);
                    msg.addPeriods(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.addDays(value4);
                    break;
                case 5:
                    var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
                    msg.setTermIdsList(value5);
                    break;
                case 6:
                    var value6 = /** @type {number} */ (reader.readInt32());
                    msg.setMingaId(value6);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!BellSchedule} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getPeriodsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(3, f, period_period_pb.Period.serializeBinaryToWriter);
        }
        f = message.getDaysList();
        if (f.length > 0) {
            writer.writeRepeatedString(4, f);
        }
        f = message.getTermIdsList();
        if (f.length > 0) {
            writer.writePackedInt32(5, f);
        }
        f = message.getMingaId();
        if (f !== 0) {
            writer.writeInt32(6, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        BellSchedule.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string name = 2;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * repeated period.Period periods = 3;
     * @return {!Array<!Period>}
     */
    getPeriodsList() {
        return /** @type{!Array<!Period>} */ (jspb.Message.getRepeatedWrapperField(this, period_period_pb.Period, 3));
    }
    /** @param {!Array<!Period>} value */
    setPeriodsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 3, value);
    }
    /**
     * @param {!Period=} opt_value
     * @param {number=} opt_index
     * @return {!Period}
     */
    addPeriods(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, period_period_pb.Period, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeriodsList() {
        this.setPeriodsList([]);
    }
    /**
     * repeated string days = 4;
     * @return {!Array<string>}
     */
    getDaysList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
    }
    ;
    /** @param {!Array<string>} value */
    setDaysList(value) {
        jspb.Message.setField(this, 4, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addDays(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 4, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearDaysList() {
        this.setDaysList([]);
    }
    /**
     * repeated int32 term_ids = 5;
     * @return {!Array<number>}
     */
    getTermIdsList() {
        return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
    }
    ;
    /** @param {!Array<number>} value */
    setTermIdsList(value) {
        jspb.Message.setField(this, 5, value || []);
    }
    /**
     * @param {number} value
     * @param {number=} opt_index
     */
    addTermIds(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 5, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearTermIdsList() {
        this.setTermIdsList([]);
    }
    /**
     * optional int32 minga_id = 6;
     * @return {number}
     */
    getMingaId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
    }
    ;
    /** @param {number} value */
    setMingaId(value) {
        jspb.Message.setProto3IntField(this, 6, value);
    }
}
exports.BellSchedule = BellSchedule;
BellSchedule.displayName = "BellSchedule";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
BellSchedule.repeatedFields_ = [3, 4, 5];
class IBellSchedule extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, IBellSchedule.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return IBellSchedule.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!IBellSchedule} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            periodsList: jspb.Message.toObjectList(msg.getPeriodsList(), period_period_pb.Period.toObject, includeInstance),
            daysList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
            termsList: jspb.Message.toObjectList(msg.getTermsList(), Term.toObject, includeInstance),
            termIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
            overrideDatesList: jspb.Message.toObjectList(msg.getOverrideDatesList(), OverrideDates.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!IBellSchedule}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new IBellSchedule;
        return IBellSchedule.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!IBellSchedule} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!IBellSchedule}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setName(value2);
                    break;
                case 3:
                    var value3 = new period_period_pb.Period;
                    reader.readMessage(value3, period_period_pb.Period.deserializeBinaryFromReader);
                    msg.addPeriods(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.addDays(value4);
                    break;
                case 5:
                    var value5 = new Term;
                    reader.readMessage(value5, Term.deserializeBinaryFromReader);
                    msg.addTerms(value5);
                    break;
                case 6:
                    var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
                    msg.setTermIdsList(value6);
                    break;
                case 7:
                    var value7 = new OverrideDates;
                    reader.readMessage(value7, OverrideDates.deserializeBinaryFromReader);
                    msg.addOverrideDates(value7);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!IBellSchedule} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getPeriodsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(3, f, period_period_pb.Period.serializeBinaryToWriter);
        }
        f = message.getDaysList();
        if (f.length > 0) {
            writer.writeRepeatedString(4, f);
        }
        f = message.getTermsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(5, f, Term.serializeBinaryToWriter);
        }
        f = message.getTermIdsList();
        if (f.length > 0) {
            writer.writePackedInt32(6, f);
        }
        f = message.getOverrideDatesList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(7, f, OverrideDates.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        IBellSchedule.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string name = 2;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * repeated period.Period periods = 3;
     * @return {!Array<!Period>}
     */
    getPeriodsList() {
        return /** @type{!Array<!Period>} */ (jspb.Message.getRepeatedWrapperField(this, period_period_pb.Period, 3));
    }
    /** @param {!Array<!Period>} value */
    setPeriodsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 3, value);
    }
    /**
     * @param {!Period=} opt_value
     * @param {number=} opt_index
     * @return {!Period}
     */
    addPeriods(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, period_period_pb.Period, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeriodsList() {
        this.setPeriodsList([]);
    }
    /**
     * repeated string days = 4;
     * @return {!Array<string>}
     */
    getDaysList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
    }
    ;
    /** @param {!Array<string>} value */
    setDaysList(value) {
        jspb.Message.setField(this, 4, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addDays(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 4, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearDaysList() {
        this.setDaysList([]);
    }
    /**
     * repeated Term terms = 5;
     * @return {!Array<!Term>}
     */
    getTermsList() {
        return /** @type{!Array<!Term>} */ (jspb.Message.getRepeatedWrapperField(this, Term, 5));
    }
    /** @param {!Array<!Term>} value */
    setTermsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 5, value);
    }
    /**
     * @param {!Term=} opt_value
     * @param {number=} opt_index
     * @return {!Term}
     */
    addTerms(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, Term, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearTermsList() {
        this.setTermsList([]);
    }
    /**
     * repeated int32 term_ids = 6;
     * @return {!Array<number>}
     */
    getTermIdsList() {
        return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
    }
    ;
    /** @param {!Array<number>} value */
    setTermIdsList(value) {
        jspb.Message.setField(this, 6, value || []);
    }
    /**
     * @param {number} value
     * @param {number=} opt_index
     */
    addTermIds(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 6, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearTermIdsList() {
        this.setTermIdsList([]);
    }
    /**
     * repeated OverrideDates override_dates = 7;
     * @return {!Array<!OverrideDates>}
     */
    getOverrideDatesList() {
        return /** @type{!Array<!OverrideDates>} */ (jspb.Message.getRepeatedWrapperField(this, OverrideDates, 7));
    }
    /** @param {!Array<!OverrideDates>} value */
    setOverrideDatesList(value) {
        jspb.Message.setRepeatedWrapperField(this, 7, value);
    }
    /**
     * @param {!OverrideDates=} opt_value
     * @param {number=} opt_index
     * @return {!OverrideDates}
     */
    addOverrideDates(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, OverrideDates, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearOverrideDatesList() {
        this.setOverrideDatesList([]);
    }
}
exports.IBellSchedule = IBellSchedule;
IBellSchedule.displayName = "IBellSchedule";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
IBellSchedule.repeatedFields_ = [3, 4, 5, 6, 7];
class OverrideDates extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, OverrideDates.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return OverrideDates.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!OverrideDates} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            termId: jspb.Message.getFieldWithDefault(msg, 1, 0),
            datesList: jspb.Message.toObjectList(msg.getDatesList(), common_date_pb.DateTime.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!OverrideDates}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new OverrideDates;
        return OverrideDates.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!OverrideDates} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!OverrideDates}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setTermId(value1);
                    break;
                case 2:
                    var value2 = new common_date_pb.DateTime;
                    reader.readMessage(value2, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.addDates(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!OverrideDates} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getTermId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getDatesList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        OverrideDates.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 term_id = 1;
     * @return {number}
     */
    getTermId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setTermId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * repeated minga.common.DateTime dates = 2;
     * @return {!Array<!DateTime>}
     */
    getDatesList() {
        return /** @type{!Array<!DateTime>} */ (jspb.Message.getRepeatedWrapperField(this, common_date_pb.DateTime, 2));
    }
    /** @param {!Array<!DateTime>} value */
    setDatesList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!DateTime=} opt_value
     * @param {number=} opt_index
     * @return {!DateTime}
     */
    addDates(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_date_pb.DateTime, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearDatesList() {
        this.setDatesList([]);
    }
}
exports.OverrideDates = OverrideDates;
OverrideDates.displayName = "OverrideDates";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
OverrideDates.repeatedFields_ = [2];
class GetBellSchedulesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetBellSchedulesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetBellSchedulesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            includeNoSchedule: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetBellSchedulesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetBellSchedulesRequest;
        return GetBellSchedulesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetBellSchedulesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetBellSchedulesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {boolean} */ (reader.readBool());
                    msg.setIncludeNoSchedule(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetBellSchedulesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getIncludeNoSchedule();
        if (f) {
            writer.writeBool(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetBellSchedulesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bool include_no_schedule = 1;
     * @return {boolean}
     */
    getIncludeNoSchedule() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
    }
    ;
    /** @param {boolean} value */
    setIncludeNoSchedule(value) {
        jspb.Message.setProto3BooleanField(this, 1, value);
    }
}
exports.GetBellSchedulesRequest = GetBellSchedulesRequest;
GetBellSchedulesRequest.displayName = "GetBellSchedulesRequest";
class GetBellSchedulesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetBellSchedulesResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetBellSchedulesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetBellSchedulesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            bellScheduleList: jspb.Message.toObjectList(msg.getBellScheduleList(), IBellSchedule.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetBellSchedulesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetBellSchedulesResponse;
        return GetBellSchedulesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetBellSchedulesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetBellSchedulesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new IBellSchedule;
                    reader.readMessage(value1, IBellSchedule.deserializeBinaryFromReader);
                    msg.addBellSchedule(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetBellSchedulesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBellScheduleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, IBellSchedule.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetBellSchedulesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated IBellSchedule bell_schedule = 1;
     * @return {!Array<!IBellSchedule>}
     */
    getBellScheduleList() {
        return /** @type{!Array<!IBellSchedule>} */ (jspb.Message.getRepeatedWrapperField(this, IBellSchedule, 1));
    }
    /** @param {!Array<!IBellSchedule>} value */
    setBellScheduleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!IBellSchedule=} opt_value
     * @param {number=} opt_index
     * @return {!IBellSchedule}
     */
    addBellSchedule(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, IBellSchedule, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearBellScheduleList() {
        this.setBellScheduleList([]);
    }
}
exports.GetBellSchedulesResponse = GetBellSchedulesResponse;
GetBellSchedulesResponse.displayName = "GetBellSchedulesResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetBellSchedulesResponse.repeatedFields_ = [1];
class GetBellScheduleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetBellScheduleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetBellScheduleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetBellScheduleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetBellScheduleRequest;
        return GetBellScheduleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetBellScheduleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetBellScheduleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetBellScheduleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetBellScheduleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.GetBellScheduleRequest = GetBellScheduleRequest;
GetBellScheduleRequest.displayName = "GetBellScheduleRequest";
class GetBellScheduleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetBellScheduleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetBellScheduleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            bellSchedule: (f = msg.getBellSchedule()) && IBellSchedule.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetBellScheduleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetBellScheduleResponse;
        return GetBellScheduleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetBellScheduleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetBellScheduleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new IBellSchedule;
                    reader.readMessage(value1, IBellSchedule.deserializeBinaryFromReader);
                    msg.setBellSchedule(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetBellScheduleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBellSchedule();
        if (f != null) {
            writer.writeMessage(1, f, IBellSchedule.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetBellScheduleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional IBellSchedule bell_schedule = 1;
     * @return {?IBellSchedule}
     */
    getBellSchedule() {
        return /** @type{?IBellSchedule} */ (jspb.Message.getWrapperField(this, IBellSchedule, 1));
    }
    /** @param {?IBellSchedule|undefined} value */
    setBellSchedule(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBellSchedule() {
        this.setBellSchedule(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBellSchedule() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.GetBellScheduleResponse = GetBellScheduleResponse;
GetBellScheduleResponse.displayName = "GetBellScheduleResponse";
class UpsertBellScheduleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpsertBellScheduleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpsertBellScheduleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            bellSchedule: (f = msg.getBellSchedule()) && IBellSchedule.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpsertBellScheduleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpsertBellScheduleRequest;
        return UpsertBellScheduleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpsertBellScheduleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpsertBellScheduleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new IBellSchedule;
                    reader.readMessage(value1, IBellSchedule.deserializeBinaryFromReader);
                    msg.setBellSchedule(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpsertBellScheduleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBellSchedule();
        if (f != null) {
            writer.writeMessage(1, f, IBellSchedule.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpsertBellScheduleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional IBellSchedule bell_schedule = 1;
     * @return {?IBellSchedule}
     */
    getBellSchedule() {
        return /** @type{?IBellSchedule} */ (jspb.Message.getWrapperField(this, IBellSchedule, 1));
    }
    /** @param {?IBellSchedule|undefined} value */
    setBellSchedule(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBellSchedule() {
        this.setBellSchedule(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBellSchedule() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.UpsertBellScheduleRequest = UpsertBellScheduleRequest;
UpsertBellScheduleRequest.displayName = "UpsertBellScheduleRequest";
class UpsertBellScheduleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpsertBellScheduleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpsertBellScheduleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            bellSchedule: (f = msg.getBellSchedule()) && IBellSchedule.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpsertBellScheduleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpsertBellScheduleResponse;
        return UpsertBellScheduleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpsertBellScheduleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpsertBellScheduleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new IBellSchedule;
                    reader.readMessage(value1, IBellSchedule.deserializeBinaryFromReader);
                    msg.setBellSchedule(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpsertBellScheduleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBellSchedule();
        if (f != null) {
            writer.writeMessage(1, f, IBellSchedule.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpsertBellScheduleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional IBellSchedule bell_schedule = 1;
     * @return {?IBellSchedule}
     */
    getBellSchedule() {
        return /** @type{?IBellSchedule} */ (jspb.Message.getWrapperField(this, IBellSchedule, 1));
    }
    /** @param {?IBellSchedule|undefined} value */
    setBellSchedule(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBellSchedule() {
        this.setBellSchedule(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBellSchedule() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.UpsertBellScheduleResponse = UpsertBellScheduleResponse;
UpsertBellScheduleResponse.displayName = "UpsertBellScheduleResponse";
class DeleteBellScheduleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteBellScheduleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteBellScheduleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteBellScheduleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteBellScheduleRequest;
        return DeleteBellScheduleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteBellScheduleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteBellScheduleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteBellScheduleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteBellScheduleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.DeleteBellScheduleRequest = DeleteBellScheduleRequest;
DeleteBellScheduleRequest.displayName = "DeleteBellScheduleRequest";
class DeleteBellScheduleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteBellScheduleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteBellScheduleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteBellScheduleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteBellScheduleResponse;
        return DeleteBellScheduleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteBellScheduleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteBellScheduleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteBellScheduleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteBellScheduleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.DeleteBellScheduleResponse = DeleteBellScheduleResponse;
DeleteBellScheduleResponse.displayName = "DeleteBellScheduleResponse";
class OverrideBellScheduleForDateRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return OverrideBellScheduleForDateRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!OverrideBellScheduleForDateRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
            termId: jspb.Message.getFieldWithDefault(msg, 3, 0),
            isOverride: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!OverrideBellScheduleForDateRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new OverrideBellScheduleForDateRequest;
        return OverrideBellScheduleForDateRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!OverrideBellScheduleForDateRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!OverrideBellScheduleForDateRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = new common_date_pb.DateTime;
                    reader.readMessage(value2, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setDate(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readInt32());
                    msg.setTermId(value3);
                    break;
                case 4:
                    var value4 = /** @type {boolean} */ (reader.readBool());
                    msg.setIsOverride(value4);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!OverrideBellScheduleForDateRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getDate();
        if (f != null) {
            writer.writeMessage(2, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getTermId();
        if (f !== 0) {
            writer.writeInt32(3, f);
        }
        f = message.getIsOverride();
        if (f) {
            writer.writeBool(4, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        OverrideBellScheduleForDateRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional minga.common.DateTime date = 2;
     * @return {?DateTime}
     */
    getDate() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
    }
    /** @param {?DateTime|undefined} value */
    setDate(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDate() {
        this.setDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDate() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional int32 term_id = 3;
     * @return {number}
     */
    getTermId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
    }
    ;
    /** @param {number} value */
    setTermId(value) {
        jspb.Message.setProto3IntField(this, 3, value);
    }
    /**
     * optional bool is_override = 4;
     * @return {boolean}
     */
    getIsOverride() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
    }
    ;
    /** @param {boolean} value */
    setIsOverride(value) {
        jspb.Message.setProto3BooleanField(this, 4, value);
    }
}
exports.OverrideBellScheduleForDateRequest = OverrideBellScheduleForDateRequest;
OverrideBellScheduleForDateRequest.displayName = "OverrideBellScheduleForDateRequest";
class OverrideBellScheduleForDateResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return OverrideBellScheduleForDateResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!OverrideBellScheduleForDateResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!OverrideBellScheduleForDateResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new OverrideBellScheduleForDateResponse;
        return OverrideBellScheduleForDateResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!OverrideBellScheduleForDateResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!OverrideBellScheduleForDateResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!OverrideBellScheduleForDateResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        OverrideBellScheduleForDateResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.OverrideBellScheduleForDateResponse = OverrideBellScheduleForDateResponse;
OverrideBellScheduleForDateResponse.displayName = "OverrideBellScheduleForDateResponse";