"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const points_pb_1 = require("minga/proto/points/points_pb");
exports.toProto = (filters, limit, offset) => {
    const filterProto = new points_pb_1.PointsReportFilters();
    if (filters.startDate)
        filterProto.setStartDate(shared_grpc_1.dateObjectToDateTimeMessage(filters.startDate.toDate()));
    if (filters.endDate)
        filterProto.setEndDate(shared_grpc_1.dateObjectToDateTimeMessage(filters.endDate.toDate()));
    filterProto.setGradeList(filters.grades);
    if (filters.startDate && filters.endDate)
        filterProto.setGroupBy(domain_1.getGroupRangeByDate(filters.startDate, filters.endDate));
    if (filters.issuedTo)
        filterProto.setIssuedToList(filters.issuedTo);
    if (offset)
        filterProto.setOffset(offset);
    if (limit)
        filterProto.setLimit(limit);
    filterProto.setTeamsList(filters.teams);
    filterProto.setRewardList(filters.rewards);
    filterProto.setUserListList(filters.userList);
    return filterProto;
};
exports.fromProto = (proto) => {
    const startDateProto = proto.getStartDate();
    const endDateProto = proto.getEndDate();
    let startDate;
    let endDate;
    if (startDateProto)
        startDate = shared_grpc_1.dateTimeMessageToDayjsObject(startDateProto);
    if (endDateProto)
        endDate = shared_grpc_1.dateTimeMessageToDayjsObject(endDateProto);
    return {
        startDate,
        endDate,
        grades: proto.getGradeList(),
        issuedTo: proto.getIssuedToList(),
        teams: proto.getTeamsList(),
        rewards: proto.getRewardList(),
        offset: proto.getOffset(),
        limit: proto.getLimit(),
        groupBy: ts_enum_util_1.$enum(domain_1.GroupType).asValueOrThrow(proto.getGroupBy()),
        userList: proto.getUserListList(),
    };
};