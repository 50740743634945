import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { cloneDeep } from 'lodash';
import { Observable, ReplaySubject, combineLatest, from } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import {
  ExistingConsequenceType,
  IConsequenceType,
  OverdueConsequenceActionType,
} from 'minga/libraries/domain';
import { MembershipListType } from 'minga/libraries/domain';
import { mingaSettingTypes } from 'minga/libraries/util';
import { RootService } from 'src/app/minimal/services/RootService';
import { ListMembershipService } from 'src/app/services/ListMembership';
import { MingaSettingsService } from 'src/app/store/Minga/services';
import { scrollIntoView } from 'src/app/util/scroll-into-view';

import { SelectOption } from '@modules/behavior-manager/components/bm-reports/types';
import { BehaviorManagerService } from '@modules/behavior-manager/services';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import { FormSelectOption } from '@shared/components/form';
import {
  FormRestrictionTabs,
  fromRestrictionInput,
  toRestrictionInput,
} from '@shared/components/form-restriction-input/form-restriction-input.constants';
import { COLOR_PICKER_PRESETS } from '@shared/components/form/constants';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

import {
  BehaviorMsgCategory,
  ConsequenceTypeOptionsMapper,
} from '../../constants';
import {
  BmTypesConsequenceEditModalData,
  BmTypesConsequenceEditModalResponse,
  OverdueActionConsequenceData,
} from '../../types';
import {
  CONSEQUENCE_FORM,
  ConsequenceEditFormFields,
  ConsequenceEditMessages,
  ICON_CONFIG_DEFAULTS,
  ICON_OPTIONS,
} from './bm-types-consequence-edit.constants';

@Component({
  selector: 'mg-bm-types-consequence-edit',
  templateUrl: './bm-types-consequence-edit.component.html',
  styleUrls: ['./bm-types-consequence-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesConsequenceEditComponent
  extends CrudFormBase<ExistingConsequenceType>
  implements OnInit, OnDestroy
{
  public toolbarData: Record<string, any>;
  private _destroyed = new ReplaySubject<void>(1);

  /** Constants */
  public readonly MESSAGES = ConsequenceEditMessages;
  public readonly FORM_FIELDS = ConsequenceEditFormFields;
  public readonly RESTRICTION_TABS = FormRestrictionTabs;
  public readonly MEMBERSHIP_LIST_TYPE = MembershipListType;
  public readonly MSG_CATEGORY = BehaviorMsgCategory;
  public readonly REQUIRED_TYPES = [3, 4];
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };
  public readonly OVERDUE_ACTION = OverdueConsequenceActionType;

  @ViewChild('formElement', { static: false })
  formElement?: ElementRef<HTMLElement>;

  public readonly hasSmsEnabled$: Observable<boolean> =
    this._settingService.getSettingValueObs(mingaSettingTypes.ENABLE_SMS);
  public praiseOptions =
    ConsequenceTypeOptionsMapper[BehaviorMsgCategory.PRAISE];
  public guidanceOptions =
    ConsequenceTypeOptionsMapper[BehaviorMsgCategory.GUIDANCE];
  public readonly form = this._fb.group(cloneDeep(CONSEQUENCE_FORM));
  public PRESET_COLORS = Object.values(COLOR_PICKER_PRESETS).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );
  public ICON_OPTIONS = ICON_OPTIONS;

  public stickerOptions$ = from(
    this._listService.getMembershipListByType(
      [MembershipListType.NO_ACCESS, MembershipListType.STICKER],
      true,
    ),
  ).pipe(
    map(lists => lists.map(list => ({ label: list.name, value: list.id }))),
  );
  public emailOptions: SelectOption[];

  public consequenceSelectOptions$: Observable<FormSelectOption[]>;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: BmTypesConsequenceEditModalData,
    private _modalRef: ModalOverlayRef<
      BmTypesConsequenceEditModalResponse,
      BmTypesConsequenceEditModalData
    >,
    private _fb: FormBuilder,
    public _bmService: BehaviorManagerService,
    private _settingService: MingaSettingsService,
    public rootService: RootService,
    private _systemAlertModal: SystemAlertModalService,
    private _listService: ListMembershipService,
  ) {
    super({
      id: dialogData?.consequenceType?.id,
      get: async () => {
        return dialogData.consequenceType;
      },
      create: data => {
        return this._bmService.createConsequenceType(data as IConsequenceType);
      },
      update: data => {
        return this._bmService.updateConsequenceType(
          data as ExistingConsequenceType,
        );
      },
      delete: async data => {
        await this._bmService.deleteConsequenceType(data.id);
        return data;
      },
      onSetForm: (type, isNew) => {
        if (isNew) return;

        this.form.get(ConsequenceEditFormFields.NAME).setValue(type.name);
        this.form
          .get(ConsequenceEditFormFields.POINTS)
          .setValue(type.points || 0);
        this.form
          .get(ConsequenceEditFormFields.CATEGORY)
          .setValue(type.categoryId);
        this.form
          .get(ConsequenceEditFormFields.NOTE_ENABLE)
          .setValue(type.addNotes);
        this.form
          .get(ConsequenceEditFormFields.SEND_PARENT_EMAIL)
          .setValue(type.sendParentEmail);
        this.form
          .get(ConsequenceEditFormFields.SEND_PARENT_SMS)
          .setValue(type.sendParentSms);
        this.form
          .get(ConsequenceEditFormFields.SEND_ADMIN_EMAIL)
          .setValue(type.sendAdminEmail);

        if (type.description) {
          this.form
            .get(ConsequenceEditFormFields.DESCRIPTION)
            .setValue(type.description);
        }
        if (type.emailTemplate?.body) {
          this.form
            .get(ConsequenceEditFormFields.PARENT_EMAIL_BODY)
            .setValue(type.emailTemplate?.body);
        }
        if (type.emailTemplate?.subject) {
          this.form
            .get(ConsequenceEditFormFields.PARENT_EMAIL_SUBJECT)
            .setValue(type.emailTemplate?.subject);
        }
        if (type.smsTemplate?.body) {
          this.form
            .get(ConsequenceEditFormFields.PARENT_SMS_TEMPLATE)
            .setValue(type.smsTemplate?.body);
        }
        if (type.adminEmails) {
          this.form
            .get(ConsequenceEditFormFields.ADMIN_EMAIL)
            .setValue(type.adminEmails);
        }
        if (type.type) {
          this.form.get(ConsequenceEditFormFields.TYPE).setValue(type.type);
        }
        if (type.restriction) {
          this.form
            .get(ConsequenceEditFormFields.RESTRICTION)
            .patchValue(toRestrictionInput(type.restriction));
        }
        if (type.stickerId) {
          this.form
            .get(ConsequenceEditFormFields.STICKER_ADD)
            .setValue(type.stickerId);
        }
        if (type.removeStickerId) {
          this.form
            .get(ConsequenceEditFormFields.STICKER_REMOVE)
            .setValue(type.removeStickerId);
        }

        const isFieldDisabled = !this.REQUIRED_TYPES.includes(
          this.form.get(this.FORM_FIELDS.TYPE).value,
        );
        this.form
          .get(ConsequenceEditFormFields.DUE_DATE_ENABLE)
          .setValue(isFieldDisabled ? false : type.enableDueDate ?? true);

        this.form
          .get(ConsequenceEditFormFields.ICON_COLOR)
          .setValue(
            type.iconColor || ICON_CONFIG_DEFAULTS[type.categoryId].color,
          );
        this.form
          .get(ConsequenceEditFormFields.ICON)
          .setValue(
            type.iconType || ICON_CONFIG_DEFAULTS[type.categoryId].icon,
          );

        this.form
          .get(ConsequenceEditFormFields.STICKER_ENABLE)
          .setValue(type.removeStickerId || type.stickerId);

        const adminEmail = this.form.get(
          ConsequenceEditFormFields.ADMIN_EMAIL,
        ).value;

        this.emailOptions = [];
        adminEmail.forEach(email => {
          this.emailOptions.push({ value: email, label: email });
        });

        const overdue = type.overdueActionId;
        this.form
          .get(ConsequenceEditFormFields.OVERDUE_ACTION)
          .setValue(!!overdue);

        this.form
          .get(ConsequenceEditFormFields.OVERDUE_ACTION_TYPE)
          .setValue(type.overdueActionType || null);
        this.form
          .get(ConsequenceEditFormFields.OVERDUE_ACTION_CONSEQUENCE)
          .setValue(type.overdueActionId || null);
        this.form
          .get(ConsequenceEditFormFields.OVERDUE_ACTION_DUE_DATE)
          .setValue(type.overdueActionDueDate !== null);
        this.form
          .get(ConsequenceEditFormFields.OVERDUE_ACTION_DAYS_TO_COMPLETE)
          .setValue(type.overdueActionDueDate ?? 0);

        this.form.markAsPristine();
        this.form.markAsUntouched();
      },
      onValidate: () => {
        markNestedFormGroupAsDirty(this.form);

        return this.form.valid;
      },
      onSuccess: type => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE);
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE);
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT);
        }
      },
      onSubmit: data => {
        const isFieldDisabled = !this.REQUIRED_TYPES.includes(
          this.form.get(this.FORM_FIELDS.TYPE).value,
        );

        const overdueActionEnabled = this.form.get(
          ConsequenceEditFormFields.OVERDUE_ACTION,
        ).value;

        const mapped = {
          name: this.form.get(ConsequenceEditFormFields.NAME).value,
          points: this.form.get(ConsequenceEditFormFields.POINTS).value,
          description: this.form.get(ConsequenceEditFormFields.DESCRIPTION)
            .value,
          categoryId: this.form.get(ConsequenceEditFormFields.CATEGORY).value,
          addNotes: this.form.get(ConsequenceEditFormFields.NOTE_ENABLE).value,
          sendParentEmail: this.form.get(
            ConsequenceEditFormFields.SEND_PARENT_EMAIL,
          ).value,
          emailTemplate: {
            subject: this.form.get(
              ConsequenceEditFormFields.PARENT_EMAIL_SUBJECT,
            ).value,
            body: this.form.get(ConsequenceEditFormFields.PARENT_EMAIL_BODY)
              .value,
          },
          sendParentSms: this.form.get(
            ConsequenceEditFormFields.SEND_PARENT_SMS,
          ).value,
          smsTemplate: {
            body: this.form.get(ConsequenceEditFormFields.PARENT_SMS_TEMPLATE)
              .value,
          },
          sendAdminEmail: this.form.get(
            ConsequenceEditFormFields.SEND_ADMIN_EMAIL,
          ).value,
          adminEmails: this.form.get(ConsequenceEditFormFields.ADMIN_EMAIL)
            .value,
          active: data.active ?? true,
          type: this.form.get(ConsequenceEditFormFields.TYPE).value,
          restriction: {
            id: data.restriction?.id ?? undefined,
            ...fromRestrictionInput(
              this.form.get(ConsequenceEditFormFields.RESTRICTION).value ||
                undefined,
              data.restriction,
            ),
          },
          stickerId: this.form.get(ConsequenceEditFormFields.STICKER_ADD).value,
          ...(data.id ? { id: data.id } : {}),
          iconType: this.form.get(ConsequenceEditFormFields.ICON).value,
          iconColor: this.form.get(ConsequenceEditFormFields.ICON_COLOR).value,
          enableDueDate: isFieldDisabled
            ? false
            : this.form.get(ConsequenceEditFormFields.DUE_DATE_ENABLE).value,
          removeStickerId: this.form.get(
            ConsequenceEditFormFields.STICKER_REMOVE,
          ).value,
          overdueActionId: overdueActionEnabled
            ? this.form.get(
                ConsequenceEditFormFields.OVERDUE_ACTION_CONSEQUENCE,
              ).value
            : null,
          overdueActionType: overdueActionEnabled
            ? this.form.get(ConsequenceEditFormFields.OVERDUE_ACTION_TYPE).value
            : null,
          overdueActionDueDate: overdueActionEnabled
            ? this.form.get(ConsequenceEditFormFields.OVERDUE_ACTION_DUE_DATE)
                .value
              ? this.form.get(
                  ConsequenceEditFormFields.OVERDUE_ACTION_DAYS_TO_COMPLETE,
                ).value
              : null
            : null,
        };

        return mapped;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: ConsequenceEditMessages.DELETE_CONFIRM_DISCARD_TITLE,
          message: ConsequenceEditMessages.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: ConsequenceEditMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: ConsequenceEditMessages.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: ConsequenceEditMessages.DELETE_CONFIRM_TITLE,
          closeBtn: ConsequenceEditMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: ConsequenceEditMessages.DELETE_CONFIRM_DELETE_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.formElement.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });

    this.form.valueChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(formValue => {
        this.toolbarData = {
          typeName: formValue[ConsequenceEditFormFields.NAME],
          points: formValue[ConsequenceEditFormFields.POINTS],
        };
      });
  }

  ngOnInit(): void {
    this.init();

    this.form
      .get(this.FORM_FIELDS.STICKER_ENABLE)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        this._onStickerEnabledChange(value);
      });

    this.form
      .get(ConsequenceEditFormFields.DUE_DATE_ENABLE)
      .valueChanges.pipe(
        takeUntil(this._destroyed),
        filter(value => !value),
      )
      .subscribe(() =>
        this.form.get(ConsequenceEditFormFields.OVERDUE_ACTION).setValue(false),
      );

    // If overdueaction form is enabled, then set overdue action type and consequence as required
    this.form
      .get(ConsequenceEditFormFields.OVERDUE_ACTION)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        const typeFC = this.form.get(
          ConsequenceEditFormFields.OVERDUE_ACTION_TYPE,
        );
        const consequenceFC = this.form.get(
          ConsequenceEditFormFields.OVERDUE_ACTION_CONSEQUENCE,
        );
        if (value) {
          typeFC.setValidators(Validators.required);
          consequenceFC.setValidators(Validators.required);
        } else {
          typeFC.clearValidators();
          consequenceFC.clearValidators();
        }
      });

    // If overdue action due date toggle is enabled, then set days to complete as required
    this.form
      .get(ConsequenceEditFormFields.OVERDUE_ACTION_DUE_DATE)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        const formControl = this.form.get(
          ConsequenceEditFormFields.OVERDUE_ACTION_DAYS_TO_COMPLETE,
        );
        if (value)
          formControl.setValidators([Validators.required, Validators.min(0)]);
        else formControl.clearValidators();
      });

    this.consequenceSelectOptions$ = this._bmService.getConsTypes(false).pipe(
      takeUntil(this._destroyed),
      map(consequences => {
        return consequences
          .map(consequence => ({
            label: consequence.name,
            contextLabel: !consequence.active
              ? ConsequenceEditMessages.DISABLED_CONSEQUENCE_OPTION_LABEL
              : '',
            value: consequence.id,
            disabled: !consequence.active,
            type: consequence.type,
            category: consequence.categoryId,
            dueDate: consequence.enableDueDate,
          }))
          .sort((a, b) => {
            return +a.disabled - +b.disabled;
          });
      }),
    );

    // Enable current escalation form due date if overdue action consequence type supports due dates
    combineLatest([
      this.form.get(this.FORM_FIELDS.OVERDUE_ACTION_CONSEQUENCE).valueChanges,
      this.consequenceSelectOptions$,
    ])
      .pipe(
        map(([consequenceId, options]) => {
          const selectedConsequence = options.find(
            cons => cons.value === consequenceId,
          ) as OverdueActionConsequenceData;
          if (!selectedConsequence) return false;
          return this.REQUIRED_TYPES.includes(selectedConsequence?.type);
        }),
      )
      .subscribe(canEnable => {
        const overdueActionDueDate = this.form.get(
          ConsequenceEditFormFields.OVERDUE_ACTION_DUE_DATE,
        );
        if (!canEnable) {
          overdueActionDueDate.setValue(canEnable);
          overdueActionDueDate.disable();

          // if escalation form due date is disabled, then set toggle to false and input to 0
          this.form
            .get(ConsequenceEditFormFields.OVERDUE_ACTION_DAYS_TO_COMPLETE)
            .setValue(0);
        } else overdueActionDueDate.enable();
      });

    // Reset escalation form if current consequence type does not support due dates
    this.form
      .get(ConsequenceEditFormFields.TYPE)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(type => {
        const hasDueDate = this.REQUIRED_TYPES.includes(type);
        if (!hasDueDate) this._resetAndDisableEscalationForm();
      });

    // Make admin email required if send admin email is enabled
    this.form
      .get(ConsequenceEditFormFields.SEND_ADMIN_EMAIL)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        const adminEmail = this.form.get(ConsequenceEditFormFields.ADMIN_EMAIL);
        if (value) adminEmail.setValidators(Validators.required);
        else adminEmail.clearValidators();
      });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public addNew(email: string) {
    return { label: email, value: email };
  }

  public setCategory(category) {
    this.form.get(ConsequenceEditFormFields.CATEGORY).setValue(category);
    this._setDefaultIcon(category);
    const typeControl = this.form.get(ConsequenceEditFormFields.TYPE);
    typeControl.setValue(undefined);
    typeControl.markAsUntouched();
    typeControl.markAsPristine();
  }

  public async onAdminEmailChange(emails) {
    this.form.get(ConsequenceEditFormFields.ADMIN_EMAIL).setValue(emails);
  }

  private _setDefaultIcon(category: BehaviorMsgCategory) {
    const iconColor = this.form.get(ConsequenceEditFormFields.ICON_COLOR);
    const iconType = this.form.get(ConsequenceEditFormFields.ICON);
    const selectedType = ICON_CONFIG_DEFAULTS[category];
    const isNew = this.isNewSubj.getValue();

    if (!selectedType || !isNew) return;

    if (iconColor.untouched) {
      const defaultColor = selectedType.color;
      iconColor.setValue(defaultColor);
    }

    if (iconType.untouched) {
      const defaultIcon = selectedType.icon;
      iconType.setValue(defaultIcon);
    }
  }

  private _onStickerEnabledChange(enabled: boolean) {
    if (!enabled) {
      this.form.get(ConsequenceEditFormFields.STICKER_ADD).setValue(null);
      this.form.get(ConsequenceEditFormFields.STICKER_REMOVE).setValue(null);
    }
  }

  private _resetAndDisableEscalationForm() {
    this.form.get(ConsequenceEditFormFields.OVERDUE_ACTION).setValue(false);
    this.form.get(ConsequenceEditFormFields.OVERDUE_ACTION_TYPE).setValue(null);
    this.form
      .get(ConsequenceEditFormFields.OVERDUE_ACTION_CONSEQUENCE)
      .setValue(null);
    this.form
      .get(ConsequenceEditFormFields.OVERDUE_ACTION_DUE_DATE)
      .setValue(false);
    this.form
      .get(ConsequenceEditFormFields.OVERDUE_ACTION_DAYS_TO_COMPLETE)
      .setValue(0);
  }
}
