import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MgDirectivesModule } from 'minga/app/src/app/directives';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { ReportFormModule } from 'src/app/components/Form/ReportForm';
import { LogoModule } from 'src/app/components/Logo';
import { NavbarModule } from 'src/app/components/Navbar';
import { NavfillModule } from 'src/app/components/Navfill';
import { NaviconModule } from 'src/app/components/Navicon';
import { NavsearchModule } from 'src/app/components/Navsearch';
import { PersonAvatarModule } from 'src/app/components/PersonAvatar';
import { ContentViewsModule } from 'src/app/content-views';
import { FileModule } from 'src/app/file';
import { FtueHighlightPipeModule } from 'src/app/ftue/Highlight/pipes/FtueHighlight';
import { GroupsModule } from 'src/app/groups/groups.module';
import { ScrollTargetDirectiveModule } from 'src/app/misc/ScrollTarget/directive';
import { TopNavModule } from 'src/app/navigation/components/TopNav';
import { ContentNavModule } from 'src/app/navigation/containers/ContentNav';
import { SideNavModule } from 'src/app/navigation/containers/SideNav';
import { AboutUsModule } from 'src/app/overlays/AboutUs';
import { MainFeedModule } from 'src/app/routes/MainFeed';
import { SearchProvidersModule } from 'src/app/search';
import { SearchContentModule } from 'src/app/search-content';
import { SearchOverlayModule } from 'src/app/search-overlay';
import { NotificationStoreModule } from 'src/app/store/Notifications';

import { GlobalSearchModule } from '@modules/global-search';

import { GenericModule } from '@shared/components/generic';
import { LayoutHeaderModule } from '@shared/components/layout-header';
import { NavigationModule } from '@shared/components/navigation/navigation.module';
import { CommonFormModules, CommonUIModules } from '@shared/modules';
import { ArrayPipesModule } from '@shared/pipes';

import { LayoutAccessDeniedComponent } from './components/layout-access-denied/layout-access-denied.component';
import { LayoutGlobalMenuComponent } from './components/layout-global-menu/layout-global-menu.component';
import { LayoutLegacyRootComponent } from './components/layout-legacy-root/layout-legacy-root.component';
import { LayoutLegacyComponent } from './components/layout-legacy/layout-legacy.component';
import { LayoutPageNotFoundComponent } from './components/layout-page-not-found/layout-page-not-found.component';
import { LayoutPrimaryMobileNavComponent } from './components/layout-primary-mobile-nav/layout-primary-mobile-nav.component';
import { LayoutPrimaryNavComponent } from './components/layout-primary-nav/layout-primary-nav.component';
import { LayoutSecondaryBreadcrumbComponent } from './components/layout-secondary-breadcrumb/layout-secondary-breadcrumb.component';
import { LayoutServerErrorComponent } from './components/layout-server-error/layout-server-error.component';
import { CheckNavigationDirective } from './directives';
import { LayoutComponent } from './layout.component';
import { CheckNavigationPermissionPipe } from './pipes';

@NgModule({
  imports: [
    // Common modules
    CommonUIModules,
    CommonFormModules,

    // Minga dependencies
    LayoutHeaderModule,
    SearchOverlayModule,
    GenericModule,
    PermissionsModule,
    MgDirectivesModule,
    PersonAvatarModule,
    SearchContentModule,
    ReportFormModule,
    NavsearchModule,
    NaviconModule,
    NavfillModule,
    MainFeedModule,
    NavbarModule,
    SearchProvidersModule,
    GroupsModule,
    NotificationStoreModule,
    SideNavModule,
    FileModule,
    ContentViewsModule,
    TopNavModule,
    FtueHighlightPipeModule,
    ContentNavModule,
    LogoModule,
    NavigationModule,
    ArrayPipesModule,
    GlobalSearchModule,
    ScrollTargetDirectiveModule,
    AboutUsModule,

    // External dependencies
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatSidenavModule,
  ],
  declarations: [
    // Pipes
    CheckNavigationPermissionPipe,

    // Directives
    CheckNavigationDirective,

    // Components
    LayoutComponent,
    LayoutPrimaryNavComponent,
    LayoutPrimaryMobileNavComponent,
    LayoutLegacyComponent,
    LayoutSecondaryBreadcrumbComponent,
    LayoutPageNotFoundComponent,
    LayoutAccessDeniedComponent,
    LayoutGlobalMenuComponent,
    LayoutServerErrorComponent,
    LayoutLegacyRootComponent,
  ],
  exports: [
    LayoutComponent,
    LayoutPageNotFoundComponent,
    LayoutAccessDeniedComponent,
  ],
})
export class LayoutModule {}
