import * as day from 'dayjs';

export enum AdminToolsMessages {
  HALL_PASS_BTN = 'Hall Pass',
  CHECK_IN_BTN = 'Check in',
  ACTIVITY_BTN = 'Activity',
  PRAISE_BTN = 'Praise',
  GUIDANCE_BTN = 'Guidance',
  CONSEQUENCE_BTN = 'Consequence',
  REDEEM_POINTS_BTN = 'Redeem',
  MANAGE_POINTS_BTN = 'Manage',
  ASSIGN_BTN = 'Assign',
  POINTS_BTN = 'Points',
  KIOSK_BTN = 'Kiosk mode',
  FILTER_7_DAYS = 'Last 7 days',
  FILTER_30_DAYS = 'Last 30 days',
  USAGE_STATS = 'Usage Stats',
}

export enum StatRangeKey {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
}

export type STAT_RANGE_PRESET = {
  label: string;
  value: StatRangeKey;
  getRange: () => { startDate: day.Dayjs; endDate: day.Dayjs };
};

export const STATS_RANGE_PRESETS: STAT_RANGE_PRESET[] = [
  {
    label: AdminToolsMessages.FILTER_7_DAYS,
    value: StatRangeKey.LAST_7_DAYS,
    getRange: () => {
      return {
        startDate: day()
          .subtract(1, 'day')
          .endOf('day')
          .subtract(6, 'day')
          .startOf('day'),
        endDate: day().subtract(1, 'day').endOf('day'),
      };
    },
  },
  {
    label: AdminToolsMessages.FILTER_30_DAYS,
    value: StatRangeKey.LAST_30_DAYS,
    getRange: () => {
      return {
        startDate: day()
          .subtract(1, 'day')
          .endOf('day')
          .subtract(29, 'day')
          .startOf('day'),
        endDate: day().subtract(1, 'day').endOf('day'),
      };
    },
  },
];
