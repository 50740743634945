import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IMembershipList } from 'minga/libraries/domain';
import { BarcodeScanner } from 'src/app/barcodeScanner';
import { RootService } from 'src/app/minimal/services/RootService';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { MembershipListTableService } from '@shared/components/membership-list-table';
import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PsData, PsSummaryErrorsData } from '../../types';
import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsListMembershipService extends PeopleSelectorFormService<'List Membership'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _listMembership: ListMembershipService,
    private _membershipListTable: MembershipListTableService,
    private _rootService: RootService,
    private _systemAlertModal: SystemAlertModalService,
  ) {
    super(
      {
        name: 'List Membership',
        pageDefinitions: {
          add: {
            submitFn: async () => this.submitAdd(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters),
          },
          remove: {
            submitFn: async () => this.submitRemove(),
            searchFn: async () => this._fetchMembershipListMembers(),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
  }

  public async submitAdd(): Promise<void> {
    const { tempId, listId, contextHash } = this.data;
    const selectedHashes = this.selection.getSelectionHashes();
    const isSingleSubmission = selectedHashes.length === 1;
    let validHashes: string[] = [];
    let invalidHashes: string[] = [];
    if (tempId) {
      const {
        newList,
        validHashes: valid,
        invalidHashes: invalid,
      } = await this._rootService.addLoadingPromise(
        this._createMembershipListWithMembers(selectedHashes),
      );
      this._membershipListTable.createTemporyReference(tempId, newList);

      validHashes = valid;
      invalidHashes = invalid;
    } else {
      const { validHashes: valid, invalidHashes: invalid } =
        await this._rootService.addLoadingPromise(
          this._listMembership.addMembersToList(
            selectedHashes,
            listId,
            contextHash,
          ),
        );

      validHashes = valid;
      invalidHashes = invalid;
    }

    if (validHashes.length === selectedHashes.length) {
      this.snackbar.open({
        type: 'success',
        message: 'Added successfully',
      });
      return;
    }

    if (isSingleSubmission && invalidHashes.length === 1) {
      const modalRef = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.ERROR,
        heading: 'This user cannot be added',
        message: 'This user does not meet the role requirements',
      });
      await modalRef.afterClosed().toPromise();
    } else {
      const errorsData = this._makeErrorData(validHashes, invalidHashes);
      await this.peopleSelector.openSummary({
        message: `could not be added`,
        title: `Membership list summary`,
        subMessage: '',
        errorsData,
        displayOnly: true,
      });

      if (validHashes.length) {
        this.snackbar.open({
          type: 'success',
          message: 'Added successfully',
        });
        await this._rootService.addLoadingPromise(
          this._listMembership.removeMembersFromList(
            validHashes,
            listId,
            contextHash,
          ),
        );
      }
    }
  }

  public async submitRemove(): Promise<void> {
    const { listId, contextHash } = this.data;
    const selectedHashes = this.selection.getSelectionHashes();
    await this._rootService.addLoadingPromise(
      this._listMembership.removeMembersFromList(
        selectedHashes,
        listId,
        contextHash,
      ),
    );
    await this.peopleSelector.openDialog({
      type: 'success',
      subTitle: 'Removed successfully',
      message: this.formTitle,
      extendedMessage: `${selectedHashes.length} ${
        selectedHashes.length > 1 ? 'people' : 'person'
      } have been removed.`,
    });
  }

  private async _createMembershipListWithMembers(
    personHashes: string[],
  ): Promise<{
    newList: IMembershipList;
    validHashes: string[];
    invalidHashes: string[];
  }> {
    const { listType, contextHash, name } = this.data;
    const newList = await this._listMembership.updateMembershipList({
      listType,
      members: [...new Set(personHashes)],
      contextHash: contextHash || null,
      name: name || '',
    });
    newList.memberCount = newList.members.length;

    const validHashes = newList.members;
    const invalidHashes = personHashes.filter(
      hash => !newList.members.includes(hash),
    );

    return { newList, validHashes, invalidHashes };
  }

  private async _fetchMembershipListMembers(): Promise<PsData[]> {
    let { listId, tempId } = this.data;
    if (tempId) {
      const list = this._membershipListTable.getListFromReference(tempId);
      if (list?.id) listId = list.id;
    }
    if (!listId)
      throw new Error(
        'No membership list created. Add members to create a list.',
      );
    const result = await this._listMembership.getMembersOfList(listId);
    return result.map(person => ({
      personHash: person.personHash,
      displayName: `${person.firstName} ${person.lastName}`,
      badge: person.badgeIconUrl,
      studentId: person.studentId,
    }));
  }

  private _makeErrorData(
    validHashes: string[],
    invalidHashes: string[],
  ): PsSummaryErrorsData[] {
    const successes = validHashes.map(hash => {
      const person = this.selection.getSelected(hash);
      return {
        hash,
        name: person.displayName,
        status: 'success',
        reasons: ['Added'],
      } as PsSummaryErrorsData;
    });
    const errors = invalidHashes.map(hash => {
      const person = this.selection.getSelected(hash);
      return {
        hash,
        name: person.displayName,
        status: 'error',
        reasons: ['Does not meet role requirements'],
      } as PsSummaryErrorsData;
    });

    return [...errors, ...successes];
  }
}
