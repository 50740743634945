"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const pbis_pb = require("minga/proto/pbis/pbis_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const membership_list_1 = require("minga/libraries/shared-grpc/membership_list/index");
const restriction_1 = require("minga/libraries/shared-grpc/restriction/index");
exports.toProto = (item) => {
    const msg = new pbis_pb.PbisType();
    if (item.id)
        msg.setId(item.id);
    if (item.name)
        msg.setName(item.name);
    if (typeof item.categoryId === 'number')
        msg.setCategoryId(item.categoryId);
    if (item.points)
        msg.setPoints(item.points);
    if (item.active)
        msg.setActive(item.active);
    if (item.createdBy)
        msg.setCreatedBy(item.createdBy);
    if (item.mingaId)
        msg.setMingaId(item.mingaId);
    if (typeof item.sendParentEmail == 'boolean')
        msg.setSendParentEmail(item.sendParentEmail);
    if (typeof item.sendParentSms == 'boolean')
        msg.setSendParentSms(item.sendParentSms);
    if (typeof item.sendAdminEmail == 'boolean')
        msg.setSendAdminEmail(item.sendAdminEmail);
    if (item.adminEmails)
        msg.setAdminEmailList(item.adminEmails);
    // if (item.automationId) msg.setAutomationId(item.automationId);
    if (item.dailyBehaviorCount)
        msg.setDailyBehaviorCount(item.dailyBehaviorCount);
    if (item.hallPassTypeId)
        msg.setHallPassTypeId(item.hallPassTypeId);
    if (item.canTrackMembershipListId)
        msg.setMembershipListId(item.canTrackMembershipListId);
    if (item.canTrackMembershipList) {
        const membershipListMsg = membership_list_1.MembershipListMapper.toProto(item.canTrackMembershipList);
        msg.setMembershipList(membershipListMsg);
    }
    if (item.emailTemplate)
        msg.setEmailTemplate(shared_grpc_1.asObjectToMessage(item.emailTemplate, new pbis_pb.EmailTemplate()));
    if (item.smsTemplate)
        msg.setSmsTemplate(shared_grpc_1.asObjectToMessage(item.smsTemplate, new pbis_pb.SmsTemplate()));
    if (item.iconType)
        msg.setIconType(item.iconType);
    if (item.iconColor)
        msg.setIconColor(item.iconColor);
    if (item.restriction) {
        const restrictionMsg = restriction_1.RestrictionMapper.toProto(item.restriction);
        msg.setRestriction(restrictionMsg);
    }
    return msg;
};
exports.fromProto = (msg) => {
    var _a, _b;
    let membershipList;
    if (msg.getMembershipList()) {
        membershipList = membership_list_1.MembershipListMapper.fromProto(msg.getMembershipList());
    }
    let restriction;
    if (msg.getRestriction()) {
        restriction = restriction_1.RestrictionMapper.fromProto(msg.getRestriction());
    }
    return {
        id: msg.getId(),
        name: msg.getName(),
        categoryId: msg.getCategoryId(),
        points: msg.getPoints(),
        active: msg.getActive(),
        createdBy: msg.getCreatedBy(),
        mingaId: msg.getMingaId(),
        sendParentEmail: msg.getSendParentEmail(),
        sendParentSms: msg.getSendParentSms(),
        sendAdminEmail: msg.getSendAdminEmail(),
        adminEmails: msg.getAdminEmailList(),
        dailyBehaviorCount: msg.getDailyBehaviorCount(),
        hallPassTypeId: msg.getHallPassTypeId(),
        canTrackMembershipListId: msg.getMembershipListId(),
        canTrackMembershipList: membershipList,
        emailTemplate: (_a = msg.getEmailTemplate()) === null || _a === void 0 ? void 0 : _a.toObject(),
        smsTemplate: (_b = msg.getSmsTemplate()) === null || _b === void 0 ? void 0 : _b.toObject(),
        iconType: msg.getIconType(),
        iconColor: msg.getIconColor(),
        restriction,
    };
};