"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromSisProto = exports.toSisProto = exports.fromProto = exports.toProto = void 0;
const proto = require("minga/proto/term/term_pb");
const dateAndTime_1 = require("minga/libraries/shared-grpc/util/dateAndTime");
exports.toProto = (term) => {
    const msg = new proto.Term();
    if (term.id)
        msg.setId(term.id);
    msg.setSourcedId(term.sourcedId);
    msg.setTitle(term.title);
    if (term.status)
        msg.setStatus(term.status);
    if (term.startDate) {
        msg.setStartDate(dateAndTime_1.dateObjectToDateTimeMessage(term.startDate));
    }
    if (term.endDate)
        msg.setEndDate(dateAndTime_1.dateObjectToDateTimeMessage(term.endDate));
    if (term.startDateString)
        msg.setStartDateString(term.startDateString);
    if (term.endDateString)
        msg.setEndDateString(term.endDateString);
    if (term.type)
        msg.setType(term.type);
    if (term.schoolYear)
        msg.setSchoolYear(term.schoolYear);
    if (term.parentSourcedId)
        msg.setParentSourcedId(term.parentSourcedId);
    if (term.childSourcedIds)
        msg.setChildSourcedIdsList(term.childSourcedIds);
    if (term.dateLastModified) {
        msg.setDateLastModified(dateAndTime_1.dateObjectToDateTimeMessage(term.dateLastModified));
    }
    if (term.bellSchedules) {
        const bellScheduleMsgs = [];
        term.bellSchedules.forEach(bellSchedule => {
            const bellScheduleMsg = new proto.BellSchedule();
            bellScheduleMsg.setId(bellSchedule.id);
            bellScheduleMsg.setName(bellSchedule.name);
            bellScheduleMsgs.push(bellScheduleMsg);
        });
        msg.setBellSchedulesList(bellScheduleMsgs);
    }
    return msg;
};
exports.fromProto = (msg) => {
    const term = {
        id: msg.getId(),
        sourcedId: msg.getSourcedId(),
        title: msg.getTitle(),
        status: msg.getStatus(),
        type: msg.getType(),
        schoolYear: msg.getSchoolYear(),
        parentSourcedId: msg.getParentSourcedId(),
        childSourcedIds: msg.getChildSourcedIdsList(),
        startDateString: msg.getStartDateString(),
        endDateString: msg.getEndDateString(),
    };
    if (msg.getDateLastModified()) {
        term.dateLastModified = dateAndTime_1.dateTimeMessageToDateObject(msg.getDateLastModified());
    }
    if (msg.getStartDate()) {
        term.startDate = dateAndTime_1.dateTimeMessageToDateObject(msg.getStartDate());
    }
    if (msg.getEndDate()) {
        term.endDate = dateAndTime_1.dateTimeMessageToDateObject(msg.getEndDate());
    }
    if (msg.getBellSchedulesList()) {
        const bellScheduleList = [];
        if (msg.getBellSchedulesList().length > 0) {
            for (const bellSchedule of msg.getBellSchedulesList()) {
                const bellScheduleD = {
                    id: bellSchedule.getId(),
                    name: bellSchedule.getName(),
                };
                bellScheduleList.push(bellScheduleD);
            }
        }
        term.bellSchedules = bellScheduleList;
    }
    return term;
};
exports.toSisProto = (term) => {
    const msg = new proto.Term();
    msg.setSourcedId(term.sourcedId);
    msg.setTitle(term.title);
    if (term.status)
        msg.setStatus(term.status);
    if (term.startDate) {
        msg.setStartDate(dateAndTime_1.dateObjectToDateTimeMessage(term.startDate));
    }
    if (term.endDate) {
        msg.setEndDate(dateAndTime_1.dateObjectToDateTimeMessage(term.endDate));
    }
    if (term.type)
        msg.setType(term.type);
    if (term.schoolYear)
        msg.setSchoolYear(term.schoolYear);
    return msg;
};
exports.fromSisProto = (msg) => {
    const term = {
        sourcedId: msg.getSourcedId(),
        title: msg.getTitle(),
        status: msg.getStatus(),
        type: msg.getType(),
        schoolYear: msg.getSchoolYear(),
    };
    if (msg.getStartDate()) {
        term.startDate = dateAndTime_1.dateTimeMessageToDateObject(msg.getStartDate());
    }
    if (msg.getEndDate()) {
        term.endDate = dateAndTime_1.dateTimeMessageToDateObject(msg.getEndDate());
    }
    return term;
};