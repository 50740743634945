var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RealtimeDirectMessageSender = exports.RealtimeDirectMessageConsumer = void 0;
const operators_1 = require("rxjs/operators");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const REALTIME_NEW_DM_MESSAGE_KEY = 'newDm';
class RealtimeDirectMessageConsumer {
    constructor(_dataConsumer) {
        this.newMessage$ = _dataConsumer.messageNotice$.pipe(operators_1.map(payload => payload[REALTIME_NEW_DM_MESSAGE_KEY]), operators_1.filter(newDm => typeof newDm !== 'undefined'), operators_1.map(msgBin => msgBin), operators_1.map(shared_grpc_1.INewDirectMessageMapper.fromBase64));
    }
}
exports.RealtimeDirectMessageConsumer = RealtimeDirectMessageConsumer;
class RealtimeDirectMessageSender {
    constructor(_dataSender) {
        this._dataSender = _dataSender;
    }
    sendNewMessageNotice(message) {
        return __awaiter(this, void 0, void 0, function* () {
            const payload = {
                [REALTIME_NEW_DM_MESSAGE_KEY]: shared_grpc_1.INewDirectMessageMapper.toBase64(message),
            };
            yield this._dataSender.send(payload);
        });
    }
}
exports.RealtimeDirectMessageSender = RealtimeDirectMessageSender;