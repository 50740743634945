import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ReplaySubject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FlexReportUnregisteredTableData } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import { FtmReportsService } from '../../services';
import { FtmReportActionService } from '../../services/ftm-reports-action.service';
import { FtmReportsUnregisteredDatasourceService } from '../../services/ftm-reports-unreg-data.service';
import { FtmReportsUnRegMessages } from './ftm-reports-unreg.constants';

@Component({
  selector: 'mg-ftm-reports-unreg',
  templateUrl: './ftm-reports-unreg.component.html',
  styleUrls: ['./ftm-reports-unreg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmReportsUnregisteredComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly MESSAGES = FtmReportsUnRegMessages;

  /** General Observables and Subject Behaviors */
  private _destroyedSubject = new ReplaySubject<void>(1);
  private readonly _canAdminSelect$ = this._permissions.observePermission(
    MingaPermission.FLEX_TIME_MANAGE,
  );
  private readonly _canTeacherSelect$ = this._permissions.observePermission(
    MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
  );
  public readonly canMultiSelect$ = combineLatest([
    this._canAdminSelect$,
    this._canTeacherSelect$,
  ]).pipe(map(([admin, teacher]) => admin || teacher));

  /** Other */
  private _selectableItems: FlexReportUnregisteredTableData[] = [];
  public readonly noResultMessage = 'Try selecting a different period.';

  constructor(
    public ds: FtmReportsUnregisteredDatasourceService,
    public actionService: FtmReportActionService,
    private _ftmReportService: FtmReportsService,
    private _permissions: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this._ftmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this.actionService.clearSelection();
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
