var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRole = void 0;
const domain_1 = require("minga/libraries/domain/index");
const AttendeeRole_1 = require("minga/libraries/shared/permissions/internal/AttendeeRole");
const DistrictLeaderRole_1 = require("minga/libraries/shared/permissions/internal/DistrictLeaderRole");
const DistrictManagerRole_1 = require("minga/libraries/shared/permissions/internal/DistrictManagerRole");
const KioskRole_1 = require("minga/libraries/shared/permissions/internal/KioskRole");
const ManagerRole_1 = require("minga/libraries/shared/permissions/internal/ManagerRole");
const OwnerRole_1 = require("minga/libraries/shared/permissions/internal/OwnerRole");
const ParentRole_1 = require("minga/libraries/shared/permissions/internal/ParentRole");
const ReadOnlyRole_1 = require("minga/libraries/shared/permissions/internal/ReadOnlyRole");
const SchoolManagerRole_1 = require("minga/libraries/shared/permissions/internal/SchoolManagerRole");
const StaffRole_1 = require("minga/libraries/shared/permissions/internal/StaffRole");
const StudentLeaderRole_1 = require("minga/libraries/shared/permissions/internal/StudentLeaderRole");
const StudentRole_1 = require("minga/libraries/shared/permissions/internal/StudentRole");
const SuperAdminRole_1 = require("minga/libraries/shared/permissions/internal/SuperAdminRole");
const TeacherRole_1 = require("minga/libraries/shared/permissions/internal/TeacherRole");
const roleTypeMap = {
    [domain_1.MingaRoleType.ATTENDEE]: new AttendeeRole_1.AttendeeRole(),
    [domain_1.MingaRoleType.MANAGER]: new ManagerRole_1.ManagerRole(),
    [domain_1.MingaRoleType.OWNER]: new OwnerRole_1.OwnerRole(),
    [domain_1.MingaRoleType.PARENT]: new ParentRole_1.ParentRole(),
    [domain_1.MingaRoleType.READ_ONLY]: new ReadOnlyRole_1.ReadOnlyRole(),
    [domain_1.MingaRoleType.STUDENT_LEADER]: new StudentLeaderRole_1.StudentLeaderRole(),
    [domain_1.MingaRoleType.STUDENT]: new StudentRole_1.StudentRole(),
    [domain_1.MingaRoleType.SUPERADMIN]: new SuperAdminRole_1.SuperAdminRole(),
    [domain_1.MingaRoleType.TEACHER]: new TeacherRole_1.TeacherRole(),
    [domain_1.MingaRoleType.STAFF]: new StaffRole_1.StaffRole(),
    [domain_1.MingaRoleType.SCHOOL_MANAGER]: new SchoolManagerRole_1.SchoolManagerRole(),
    [domain_1.MingaRoleType.DISTRICT_LEADER]: new DistrictLeaderRole_1.DistrictLeaderRole(),
    [domain_1.MingaRoleType.DISTRICT_MANAGER]: new DistrictManagerRole_1.DistrictManagerRole(),
    [domain_1.MingaRoleType.KIOSK]: new KioskRole_1.KioskRole(),
};
exports.getRole = (role) => {
    if (roleTypeMap[role]) {
        return roleTypeMap[role];
    }
    throw new Error(`Role type '${role}' does not exist`);
};
__exportStar(require("minga/libraries/shared/permissions/interface"), exports);