import { Injectable } from '@angular/core';

import { flatten } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { CacheService } from '@shared/services/cache/cache.service';
import { CacheKey } from '@shared/services/cache/cache.types';
import { createCacheCollection } from '@shared/services/cache/cache.utils';

@Injectable()
export class MyClassLists {
  private _cachedLists = createCacheCollection(key =>
    this._cacheService.create<PersonViewMinimal.AsObject[]>(
      `${CacheKey.MY_CLASS_LIST_MEMBERS}:${key}`,
      async listId => {
        return await this._listService.getMembersOfList(listId);
      },
      {
        ttl: 60,
      },
    ),
  );

  constructor(
    private _cacheService: CacheService,
    private _listService: ListMembershipService,
  ) {}

  public fetchMembersForLists(
    listIds: number[],
  ): Observable<PersonViewMinimal.AsObject[]> {
    if (!listIds || listIds.length === 0) {
      return of([]);
    }

    return forkJoin(
      listIds.map(listId => this._getMembersForList(listId)),
    ).pipe(switchMap(results => of(flatten(results))));
  }

  private _getMembersForList(
    listId: number,
  ): Observable<PersonViewMinimal.AsObject[]> {
    const cacheItem = this._cachedLists.get(listId);
    return cacheItem.get(listId);
  }
}
