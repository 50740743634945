import { ComponentType, OverlayRef } from '@angular/cdk/overlay';

import confetti from 'canvas-confetti';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import {
  MODAL_OVERLAY_BEFORE_CLOSED_SUCCESS,
  ModalOverlayServiceCloseEventType,
} from '../modal-overlay.constants';
import {
  ModalOverlayBeforeClosedSuccess,
  ModalOverlayServiceCloseEvent,
  ModalOverlayServiceConfig,
} from '../modal-overlay.types';

/**
 * Modal Overlay Ref
 */
export class ModalOverlayRef<R = any, T = any> {
  /** Constants */
  public readonly BEFORE_CLOSED_SUCCESS = MODAL_OVERLAY_BEFORE_CLOSED_SUCCESS;

  /** General Observables */
  public readonly afterClosed = new Subject<ModalOverlayServiceCloseEvent<R>>();
  private readonly _beforeCloseSuccess$ =
    new BehaviorSubject<ModalOverlayBeforeClosedSuccess>(null);
  public readonly beforeCloseSuccess$ =
    this._beforeCloseSuccess$.asObservable();
  public escapeSubscription$: Subscription;
  public emitAction = new Subject<any>();

  /** Service Constructor */
  constructor(
    public overlay: OverlayRef,
    public content: ComponentType<any>,
    public data: T,
    public beforeCloseSuccess: ModalOverlayServiceConfig['beforeCloseSuccess'],
  ) {
    this.escapeSubscription$ = overlay.keydownEvents().subscribe(key => {
      if (key.key === 'Escape') {
        this._close(ModalOverlayServiceCloseEventType.ESCAPE, null);
      }
    });
  }

  close(type: ModalOverlayServiceCloseEventType, data?: R) {
    const beforeCloseSuccess =
      this.BEFORE_CLOSED_SUCCESS[this.beforeCloseSuccess];
    if (
      beforeCloseSuccess &&
      [
        ModalOverlayServiceCloseEventType.SUBMIT,
        ModalOverlayServiceCloseEventType.CREATE,
      ].includes(type)
    ) {
      this._beforeCloseSuccess$.next(beforeCloseSuccess);
      if (beforeCloseSuccess.showConfetti) {
        this._showConfetti();
      }
      setTimeout(() => {
        this._close(type, data);
      }, beforeCloseSuccess.hangTime || 3000);
      return;
    }
    this._close(type, data);
  }

  disableEscape() {
    this.escapeSubscription$.unsubscribe();
  }

  private async _showConfetti(): Promise<void> {
    const colors = ['#003366', '#9933ff', '#ffcc00', '#33ccff'];
    confetti({
      zIndex: 1001, // to be above the modal
      origin: {
        x: 0.5,
        y: -0.5,
      },
      particleCount: 500,
      angle: 270,
      spread: 180,
      colors,
    });
  }

  private _close(type: ModalOverlayServiceCloseEventType, data: R) {
    this.overlay.dispose();
    this.afterClosed.next({ type, data });
    this.afterClosed.complete();
  }
}
