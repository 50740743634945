import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

import * as day from 'dayjs';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  from,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { Person } from 'minga/app/src/app/people';
import { GradesService } from 'minga/app/src/app/services/Grades';
import { ReportTypes } from 'minga/domain/reportTypes';
import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { ReportBaseAbstract } from 'src/app/components/manager-report/services/report-base.util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';
import { MmScheduledReportsService } from '@modules/minga-manager/components/mm-scheduled-reports/services';
import {
  SUPPORTED_SCHEDULE_TYPES,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import {
  FiltersFormData,
  FiltersFormService,
} from '@shared/components/filters-form';
import { FormSelectComponent } from '@shared/components/form';
import { FormSelectOption } from '@shared/components/form/types';
import { AutomationService } from '@shared/services/automation';
import { MediaService } from '@shared/services/media';
import { PbisService } from '@shared/services/pbis';

import { BehaviorManagerRoutes } from '../../constants';
import {
  BmReportsFilterType,
  BmReportsMessages,
  CONS_CATEGORIES,
  CONS_REPORT_CAT_TYPES,
  CONS_REPORT_STATUS,
  CONS_REPORT_TYPES,
  REPORT_TYPES,
  BM_AUTO_FILTER_STATE_KEY,
  BM_REPORTS_FILTER_INIT_STATE,
} from './constants';
import { BmReportsService } from './services';
import { BmReportType } from './types';
import { peopleToIssuedFilters } from './utils';

/**
 * Behavior Manager Reports
 */
@Component({
  selector: 'mg-bm-reports',
  templateUrl: './bm-reports.component.html',
  styleUrls: ['./bm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmReportsComponent extends ReportBaseAbstract {
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  /** Enums & Constants */
  MESSAGES = BmReportsMessages;

  /** Table Data Service */
  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  /** Filters */
  public readonly automationGroupOptions$: Observable<
    FormSelectOption<number>[]
  >;
  public readonly automationTypeOptions$: Observable<FormSelectOption<any>[]>;
  public readonly userListOptions$: Observable<FormSelectOption<number>[]>;
  public filters$ = this._trackingReports.filter$;

  public readonly canScheduleReport$ = this._permissions.observePermission(
    MingaPermission.PBIS_TYPE_MANAGE,
  );
  public SUPPPORTED_SCHEDULE_TYPES: ScheduledReportType[] =
    SUPPORTED_SCHEDULE_TYPES;

  public readonly media$: Observable<any>;

  /** Search */
  setPeopleSearchValue$: Subject<string[]> = new Subject<string[]>();

  /** General Variables */
  protected readonly _reportTypeSubject = new BehaviorSubject<ReportTypes>(
    ReportTypes.PBIS_SUMMARY,
  );
  reportOptions: BmReportType[] = REPORT_TYPES;
  noDateFilterTypes = [
    ReportTypes.PBIS_AUTOMATION_COUNT,
    ReportTypes.PBIS_CONS_OVERDUE,
  ];
  consequenceReports = CONS_REPORT_TYPES.map(option => option.value);
  consHistType = ReportTypes.PBIS_CONS_HISTORY;
  autoCounterType = ReportTypes.PBIS_AUTOMATION_COUNT;
  consStatusOptions = CONS_REPORT_STATUS;
  consCatTypes = CONS_REPORT_CAT_TYPES;
  consCat = CONS_CATEGORIES;

  private readonly _loadingSubject = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loadingSubject.asObservable();

  public readonly filtersFormStructure$: Observable<FiltersFormData> =
    combineLatest([
      this._reportTypeSubject,
      this._gradesService.grades$,
      from(this._automationService.fetchAutomations()),
      this._pbisService.getConsTypes(true),
      this._pbisService.getTypes(true),
      this._trackingReports.filter$,
    ]).pipe(
      takeUntil(this._destroyed),
      map(
        ([
          reportType,
          grades,
          automationTypeOptions,
          consTypes,
          behaviorTypes,
          filters,
        ]) => {
          const issuedBy = filters.issuedBy || [];
          const issuedTo = filters.issuedTo || [];
          const formData: FiltersFormData = {
            person: {
              type: 'people-search',
              label: 'Name, ID or email',
              multiple: true,
              value: [...issuedBy, ...issuedTo],
            },
            grade: {
              type: 'multi-select',
              label: this.MESSAGES.SELECT_LABEL_GRADE,
              options: grades.map(grade => ({ label: grade, value: grade })),
              value: filters.grades,
            },
          };
          if (reportType === ReportTypes.PBIS_AUTOMATION_COUNT) {
            let value = filters.automationTypes?.length
              ? filters.automationTypes[0]
              : undefined;
            if (!value) {
              value = automationTypeOptions.length
                ? automationTypeOptions[0].id
                : undefined;
              if (value) {
                this._trackingReports.setAndApplyFilter(
                  BmReportsFilterType.AUTO_TYPE,
                  value,
                );
              }
            }
            formData['automation-type'] = {
              type: 'single-select',
              label: this.MESSAGES.SELECT_OPTION_AUTO_GROUP,
              options: automationTypeOptions.map(auto => ({
                label: auto.name,
                value: auto.id,
              })),
              value,
            };
          } else if (this.consequenceReports.includes(reportType)) {
            formData['consequence-type'] = {
              type: 'multi-select',
              label: this.MESSAGES.SELECT_LABEL_CONS_TYPE,
              options: consTypes.map(t => ({ label: t.name, value: t.id })),
              value: filters.consTypes,
            };
            if (reportType === ReportTypes.PBIS_CONS_HISTORY) {
              formData['consequence-id'] = {
                type: 'text',
                label: this.MESSAGES.SELECT_LABEL_CONS_ID,
                value: filters.consID,
              };
              formData['consequence-category'] = {
                type: 'single-select',
                label: this.MESSAGES.SELECT_LABEL_CONS_CAT,
                options: this.consCat,
                value: filters.consequenceCategory,
              };
              formData['consequence-cat-type'] = {
                type: 'multi-select',
                label: this.MESSAGES.SELECT_LABEL_CONS_CAT_TYPE,
                options: this.consCatTypes,
                value: filters.consCatTypes,
              };
              formData['consequence-status'] = {
                type: 'multi-select',
                label: this.MESSAGES.SELECT_LABEL_CONS_STATUS,
                options: this.consStatusOptions,
                value: filters.consStatus,
              };
              formData['consequence-automation-group'] = {
                type: 'single-select',
                label: this.MESSAGES.SELECT_OPTION_AUTO_GROUP,
                options: automationTypeOptions.map(auto => ({
                  label: auto.name,
                  value: auto.id,
                })),
                value: filters.autoGroup,
              };
            }
          } else {
            formData['behavior-type'] = {
              type: 'multi-select',
              label: this.MESSAGES.SELECT_LABEL_TYPE,
              options: behaviorTypes.map(t => ({
                label: t.name,
                value: t.id,
              })),
              value: filters.types,
            };
          }

          formData['user-list'] = {
            type: 'user-list',
            label: this.MESSAGES.SELECT_LABEL_USER_LIST,
            value: filters.userList,
          };

          return this._filtersFormService.create(formData);
        },
      ),
      tap(() => {
        this._loadingSubject.next(false);
      }),
    );

  /** Component Constructor */
  constructor(
    public media: MediaService,
    public mediaObserver: MediaObserver,
    public layout: LayoutService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _pbisService: PbisService,
    private _trackingReports: BmReportsService,
    private _gradesService: GradesService,
    private _settingService: MingaSettingsService,
    private _route: ActivatedRoute,
    private _analytics: AnalyticsService,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _permissions: PermissionsService,
    private _automationService: AutomationService,
    private _filtersFormService: FiltersFormService,
  ) {
    super(_trackingReports);
    // have to do this so that we are updated whenever the url
    // is changed.
    this._route.url.pipe(takeUntil(this._destroyed)).subscribe(() => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed))
          .subscribe(url => {
            if (url[0]) {
              const reportPath = url[0].path;
              try {
                const report = $enum(ReportTypes).asValueOrThrow(reportPath);
                const possiblePaths = REPORT_TYPES.map(
                  type => type.value,
                ).concat(this.consequenceReports);
                if (possiblePaths.includes(report)) {
                  this._reportTypeSubject.next(report);
                  this._cdr.markForCheck();
                }
              } catch (e) {
                // no need for snackbar, user will go to summary otherwise
                console.error(e);
              }
            }
          });
      }
    });

    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    this._gradesService.fetchIfNeeded();
    const automationFilterState = localStorage.getItem(
      BM_AUTO_FILTER_STATE_KEY,
    );
    if (automationFilterState) {
      try {
        this._trackingReports.setAndApplyFilter(
          BmReportsFilterType.AUTO_TYPE,
          JSON.parse(automationFilterState),
        );
      } catch (e) {
        // TODO: investigate why sometimes getting that the value is 'undefined'
        console.error(e);
      }
    }

    this._route.queryParams
      .pipe(takeUntil(this._destroyed))
      .subscribe(params => {
        if (params.automationType) {
          this._trackingReports.setFilter(BmReportsFilterType.AUTO_TYPE, [
            +params.automationType,
          ]);
        }

        if (params.consequenceId) {
          this._filterByConsequenceId(+params.consequenceId);
        }
      });
    this._settingService
      .getSettingValueObs(mingaSettingTypes.BM_CONSEQUENCE_ENABLE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(enabled => {
        if (enabled) {
          this.reportOptions = [...REPORT_TYPES, ...CONS_REPORT_TYPES];
        } else {
          this.reportOptions = REPORT_TYPES.filter(
            report => report.value !== ReportTypes.PBIS_AUTOMATION_COUNT,
          );
        }
      });

    this._initializeDates(
      BM_REPORTS_FILTER_INIT_STATE,
      this._route.snapshot?.queryParams,
      this._destroyed,
      (range, fromChangeEvent) => {
        this._trackingReports.setFilter(
          BmReportsFilterType.START_DATE,
          range.start,
        );
        this._trackingReports.setFilter(
          BmReportsFilterType.END_DATE,
          range.end,
        );

        if (fromChangeEvent) {
          this._trackingReports.applyFilter();
        }
      },
    );
  }

  async onChangeReportType(value: string): Promise<void> {
    await this._router.navigateByUrl(
      `/${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${value}`,
    );
    return;
  }

  async onChangeType(value: any, consequence?: boolean): Promise<void> {
    let filter = BmReportsFilterType.TYPE;
    if (consequence) filter = BmReportsFilterType.CONS_TYPE;
    this._trackingReports.setFilter(filter, value);
    return;
  }

  async onChangeCatType(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.CONS_CAT_TYPE, value);
    return;
  }

  async onChangeConsStatus(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.CONS_STATUS, value);
    return;
  }

  async onChangeGrade(value): Promise<void> {
    this._trackingReports.setFilter(BmReportsFilterType.GRADE, value);
    return;
  }

  async onPersonSelected(people: Person[]): Promise<void> {
    const { issuedTo, issuedBy } = peopleToIssuedFilters(
      people,
      this._reportTypeSubject.value,
    );

    this._trackingReports.setFilter(BmReportsFilterType.ISSUED_TO, issuedTo);
    this._trackingReports.setFilter(BmReportsFilterType.ISSUED_BY, issuedBy);
    return;
  }

  async onAutomationTypeChange(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.AUTO_TYPE, value);
    localStorage.setItem(BM_AUTO_FILTER_STATE_KEY, JSON.stringify(value));
    return;
  }

  public async onAutomationGroupChange(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.AUTO_GROUP, value);
    return;
  }

  public async onConsequenceCategoryChange(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.CONS_CAT, value);
    return;
  }

  public onChangeUserList(value: number[]): void {
    this._trackingReports.setFilter(BmReportsFilterType.USER_LIST, value);
  }

  public async onConsequenceIdChange(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.CONS_ID, value);
    return;
  }

  updateFilters(filters: any) {
    this.onChangeGrade(filters.grade);
    this.onChangeUserList(filters['user-list']);
    this.onPersonSelected(filters.person);
    if (this.consequenceReports.includes(this._reportTypeSubject.value)) {
      this.onChangeType(filters['consequence-type'], true);
      if (this._reportTypeSubject.value === ReportTypes.PBIS_CONS_HISTORY) {
        this.onChangeCatType(filters['consequence-cat-type']);
        this.onChangeConsStatus(filters['consequence-status']);
        this.onConsequenceIdChange(filters['consequence-id']);
        this.onConsequenceCategoryChange(filters['consequence-category']);
        this.onAutomationGroupChange(filters['consequence-automation-group']);
      }
    } else if (
      this._reportTypeSubject.value === ReportTypes.PBIS_AUTOMATION_COUNT
    ) {
      this.onAutomationTypeChange(filters['automation-type']);
    } else {
      this.onChangeType(filters['behavior-type']);
    }

    this.applyFilter();
  }

  applyFilter() {
    this._trackingReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'pbis',
      reportType: this._reportTypeSubject.value,
    });
  }

  onClickExport() {
    this._trackingReports.exportReport(this._reportTypeSubject.value);
  }

  clearFilter() {
    this._trackingReports.clearFilter();
    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: BM_REPORTS_FILTER_INIT_STATE.startDate,
      end: BM_REPORTS_FILTER_INIT_STATE.endDate,
    });
  }

  public async onScheduleReport() {
    const filters = await this.filters$.pipe(take(1)).toPromise();

    await this._mmScheduledReportService.scheduleReport(
      this._reportTypeSubject.value,
      filters,
    );
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }

  private async _filterByConsequenceId(id: number) {
    this._trackingReports.clearFilter();

    await this._trackingReports._handleSetFilter(
      BmReportsFilterType.CONS_ID,
      id,
    );
    const today = day();
    // we need start of school year since we don't know when this consequence was issued
    const startOfSchoolYear = today.isAfter(`${today.format('YYYY')}-08-01`)
      ? day().month(7).startOf('month')
      : day().subtract(1, 'years').month(7).startOf('month');

    await this._trackingReports._handleSetFilter(
      BmReportsFilterType.START_DATE,
      startOfSchoolYear,
    );
    await this._trackingReports._handleSetFilter(
      BmReportsFilterType.END_DATE,
      today,
    );
    this._trackingReports.applyFilter();
  }
}
