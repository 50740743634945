<!-- Trigger button container-->
<div
  #triggerButton
  [ngClass]="{ 'responsive-trigger-button': responsive }">
  <mg-btn
    [variant]="appearance === 'primary' ? 'outlined' : 'icon'"
    size="medium"
    iconSet="minga"
    [size]="appearance === 'primary' ? 'medium' : 'large'"
    [icon]="(appliedFiltersCount$ | async) > 0 ? 'mg-filter-on' : 'mg-filter'"
    [responsive]="responsive"
    [ngClass]="{ 'has-filters': (appliedFiltersCount$ | async) > 0 }"
    [loading]="loading"
    [active]="overlay.isOpen$ | async"
    (pressed)="open()">
    {{ appearance === 'primary' && MESSAGE.BUTTON_LABEL_FILTERS }}
    <!-- Active filters count -->
    <ng-container
      *ngIf="
        appearance === 'primary' &&
        (appliedFiltersCount$ | async) as appliedFiltersCount
      ">
      ({{ appliedFiltersCount }})
    </ng-container>
  </mg-btn>
</div>

<!-- Show chips on desktop -->
<ng-container *ngIf="showChips && (shouldShowChips$ | async)">
  <ng-container *ngFor="let filter of form.value | keyvalue: chipSortOrder">
    <ng-container *ngIf="$any(filter.value) as value">
      <ng-container
        *ngIf="
          dataState
            | checkForFieldCallback: filter.key:formValues$
            | async
            | async as field
        ">
        <!-- Chip component -->
        <mg-chip
          *ngIf="$any(filter) | checkChipVisibility: field"
          size="medium"
          [addOnLeft]="field?.label + ': '"
          [showCheckmark]="false"
          [active]="true"
          (pressed)="open({ focusField: $any(filter.key) })">
          <mg-text
            variant="label-md-secondary"
            color="surface-alt">
            <!-- Switch case for the type of filter field -->
            <ng-container [ngSwitch]="field.type">
              <!-- Person search -->
              <ng-container *ngSwitchCase="FIELD_TYPE.PEOPLE_SEARCH">
                {{ value | getPeopleNameLabels | join: ', ' }}
              </ng-container>
              <!-- Text input -->
              <ng-container *ngSwitchCase="FIELD_TYPE.TEXT">
                {{ value }}
              </ng-container>
              <!-- Single select -->
              <ng-container *ngSwitchCase="FIELD_TYPE.SINGLE_SELECT">
                {{ ($any(field)?.options | find: 'value':value)?.label }}
              </ng-container>
              <!-- Toggle group -->
              <ng-container *ngSwitchCase="FIELD_TYPE.TOGGLE_GROUP">
                {{ $any(field)?.options | getLabelsFromOptions: value:true:2 }}
              </ng-container>
              <!-- Multi select -->
              <ng-container *ngSwitchCase="FIELD_TYPE.MULTI_SELECT">
                {{ $any(field)?.options | getLabelsFromOptions: value:true:2 }}
              </ng-container>
              <!-- User list -->
              <ng-container *ngSwitchCase="FIELD_TYPE.USER_LIST">
                {{ value | getUserListLabelsFromIds | async | join: ', ' }}
              </ng-container>
            </ng-container>
          </mg-text>
        </mg-chip>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
