"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toIContentEventMinimal = exports.fromIContentEventMinimal = void 0;
const domain_1 = require("minga/libraries/domain/index");
const event_collection_pb_1 = require("minga/proto/content/eventCollection/event_collection_pb");
exports.fromIContentEventMinimal = (contentEvent) => {
    const message = new event_collection_pb_1.EventViewMinimal();
    message.setContentHash(contentEvent.hash);
    message.setName(contentEvent.contentTagTitle);
    message.setContextHash(contentEvent.contextHash);
    return message;
};
exports.toIContentEventMinimal = (eventView) => {
    return {
        hash: eventView.getContentHash(),
        contentTagTitle: eventView.getName(),
        contentType: domain_1.ContentType.EVENT,
        contextHash: eventView.getContextHash(),
    };
};