"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roleTypeToIconUrl = exports.roleTypeToDefaultProfileUrl = exports.badgeRoleNameToIconUrl = exports.badgeRoleNameToMingaRoleType = void 0;
const domain_1 = require("minga/libraries/domain/index");
const shared_1 = require("minga/libraries/shared/index");
exports.badgeRoleNameToMingaRoleType = (badgeRoleName) => {
    switch (badgeRoleName) {
        case 'Super Admin':
            return domain_1.MingaRoleType.SUPERADMIN;
            break;
        case 'District Manager':
            return domain_1.MingaRoleType.DISTRICT_MANAGER;
            break;
        case 'District Leader':
            return domain_1.MingaRoleType.DISTRICT_LEADER;
            break;
        case 'School Manager':
            return domain_1.MingaRoleType.SCHOOL_MANAGER;
            break;
        case 'Owner':
            return domain_1.MingaRoleType.OWNER;
            break;
        case 'Manager':
            return domain_1.MingaRoleType.MANAGER;
            break;
        case 'Teacher':
            return domain_1.MingaRoleType.TEACHER;
            break;
        case 'Staff':
            return domain_1.MingaRoleType.STAFF;
            break;
        case 'Student Leader':
            return domain_1.MingaRoleType.STUDENT_LEADER;
            break;
        case 'Student':
            return domain_1.MingaRoleType.STUDENT;
            break;
        case 'Parent':
            return domain_1.MingaRoleType.PARENT;
            break;
        case 'Attendee':
            return domain_1.MingaRoleType.ATTENDEE;
            break;
        case 'Read-Only':
            return domain_1.MingaRoleType.READ_ONLY;
            break;
        case 'Kiosk':
            return domain_1.MingaRoleType.KIOSK;
            break;
        default:
            console.log('badgeRoleNameToMingaRoleType unhandled badgeRoleName:', badgeRoleName);
            break;
    }
};
exports.badgeRoleNameToIconUrl = (badgeRoleName) => {
    const roleType = exports.badgeRoleNameToMingaRoleType(badgeRoleName);
    return roleType ? exports.roleTypeToIconUrl(roleType) : '';
};
exports.roleTypeToDefaultProfileUrl = (roleType) => {
    return `https://cdn.minga.io/assets/${roleType}.svg`;
};
exports.roleTypeToIconUrl = (roleType) => {
    const role = shared_1.getRole(roleType);
    return role.iconUrl;
};