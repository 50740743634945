import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';

import * as pbis_proto from 'minga/proto/pbis/pbis_pb';
import * as report_proto from 'minga/proto/stats_report/stats_report_pb';
import { IConsequence } from 'minga/domain/consequences';
import {
  IPbisAutomationCounterResult,
  IPbisBehavior,
  IPbisSummaryResult,
} from 'minga/domain/pbis';
import { BmReportsFilters } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { PbisManager } from 'minga/proto/pbis/pbis_ng_grpc_pb';
import { StatsReportManager } from 'minga/proto/stats_report/stats_report_ng_grpc_pb';
import {
  ConsequenceMapper,
  ConsequencePersonMapper,
  ConsequenceSummaryMapper,
} from 'minga/shared-grpc/consequences/';
import {
  PbisAutomationCounterMapper,
  PbisBehaviorMapper,
  PbisStatsPersonMapper,
  PbisSummaryMapper,
} from 'minga/shared-grpc/pbis/';
import { BehaviorFilterMapper } from 'minga/shared-grpc/report_filters';
import { ReportsService } from 'src/app/components/manager-report/services/report-service.service';
import { RootService } from 'src/app/minimal/services/RootService';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  BM_REPORTS_FILTER_INIT_STATE,
  BM_REPORTS_FILTER_NON_ARRAY_TYPES,
  BM_REPORTS_PERSON_EMITTER_ACCESSORS,
  BmReportsFilterType,
} from '../constants';

@Injectable()
export class BmReportsService extends ReportsService<BmReportsFilters> {
  /** Service Constructor */
  constructor(
    private _pbisManager: PbisManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _dialog: MatDialog,
    _reportManager: StatsReportManager,
    _rootService: RootService,
  ) {
    super(
      BM_REPORTS_FILTER_INIT_STATE,
      BM_REPORTS_FILTER_NON_ARRAY_TYPES,
      BM_REPORTS_PERSON_EMITTER_ACCESSORS,
      _reportManager,
      _rootService,
    );
  }

  public async getSummary(): Promise<IPbisSummaryResult[]> {
    const { infos } = await this._getReport(ReportTypes.PBIS_SUMMARY, 0);
    const rows = infos.map(info =>
      PbisSummaryMapper.fromProto(info.getPbisSummary()),
    );
    return rows;
  }

  public async getStudents(
    offset: number = 0,
    limit: number = 10,
    sort?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_STUDENT,
      offset,
      limit,
      sort,
    );
    const items = infos.map(info =>
      PbisStatsPersonMapper.fromProto(info.getPbisPerson()),
    );
    return { items, pageToken };
  }

  public async getStaff(offset: number = 0, limit: number = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_STAFF,
      offset,
      limit,
      sort,
    );
    const rows = infos.map(info =>
      PbisStatsPersonMapper.fromProto(info.getPbisPerson()),
    );
    return { items: rows, pageToken };
  }

  public async getBehaviorTypes(
    offset: number = 0,
    limit: number = 10,
    sort?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_TYPES,
      offset,
      limit,
      sort,
    );
    const items = infos.map(info =>
      PbisSummaryMapper.fromProto(info.getPbisSummary()),
    );
    return { items, pageToken };
  }

  public async getConsequenceTypes(
    offset: number = 0,
    limit: number = 10,
    sort?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_CONS_TYPES,
      offset,
      limit,
      sort,
    );
    const items = infos.map(info =>
      ConsequenceSummaryMapper.fromProto(info.getConsequenceSummary()),
    );
    return { items, pageToken };
  }

  public async getAutoCounter(
    offset: number = 0,
    limit: number = 10,
    sort?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_AUTOMATION_COUNT,
      offset,
      limit,
      sort,
    );
    const items: IPbisAutomationCounterResult[] = infos.map(proto =>
      PbisAutomationCounterMapper.fromProto(proto.getPbisAutomationStats()),
    );
    return { items, pageToken };
  }

  public async getBehaviorHistory(offset: number, limit: number, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_HISTORY,
      offset,
      limit,
      sort,
    );
    const items = infos.map(info =>
      PbisBehaviorMapper.fromProtoEditable(info.getEditableBehaviors()),
    );
    return { items, pageToken };
  }

  public async getConsequenceHistory(
    offset: number,
    limit: number,
    sort?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_CONS_HISTORY,
      offset,
      limit,
      sort,
    );
    const items = infos.map(info =>
      ConsequenceMapper.fromProtoEditable(info.getEditableConsequences()),
    );
    return { items, pageToken };
  }

  public async getConsequenceOverdue(
    offset: number,
    limit: number,
    sort?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.PBIS_CONS_OVERDUE,
      offset,
      limit,
      sort,
    );
    const items = infos.map(info =>
      ConsequencePersonMapper.fromProto(info.getConsequencePerson()),
    );
    return { items, pageToken };
  }

  public async deleteBehavior(
    pbis: IPbisBehavior | IPbisBehavior[],
  ): Promise<void> {
    let pbisIds: number[] = [];
    if (Array.isArray(pbis)) {
      pbisIds = pbis.map(p => p.id);
    } else {
      pbisIds = [pbis.id];
    }

    const req = new pbis_proto.DeleteBehaviorRequest();
    req.setIdsList(pbisIds);
    await this._pbisManager.deleteBehavior(req);
  }

  public async deleteConsequence(cons: IConsequence | IConsequence[]) {
    let consIds: number[] = [];
    if (Array.isArray(cons)) {
      consIds = cons.map(c => c.id);
    } else {
      consIds = [cons.id];
    }
    const req = new pbis_proto.DeleteConsequenceRequest();
    req.setIdsList(consIds);
    await this._pbisManager.deleteConsequence(req);
  }

  public async completeItem(item: any) {
    await this.completeConsequence([item.consequenceId], item.complete);
  }

  public async completeConsequence(consIds: number[], isComplete: boolean) {
    if (isComplete) {
      this._completeConsequence(consIds, 'reactivate');
    } else {
      this._completeConsequence(consIds, 'complete');
    }
  }

  private async _completeConsequence(consIds: number[], phrase: string) {
    try {
      const req = new pbis_proto.CompleteConsequenceRequest();
      req.setIdsList(consIds);
      await this._pbisManager.completeConsequence(req);
    } catch (err) {
      this._systemAlertSnackBar.error(`Failed to ${phrase} consequence!`);
    }
    return;
  }

  async _handleSetFilter(
    filter: BmReportsFilterType,
    value: any,
  ): Promise<void> {
    switch (filter) {
      case BmReportsFilterType.START_DATE: {
        this.filter.startDate = value;
        break;
      }
      case BmReportsFilterType.END_DATE: {
        this.filter.endDate = value;
        break;
      }
      case BmReportsFilterType.TYPE: {
        this.filter.types = value;
        break;
      }
      case BmReportsFilterType.CONS_TYPE: {
        this.filter.consTypes = value;
        break;
      }
      case BmReportsFilterType.GRADE: {
        this.filter.grades = value;
        break;
      }
      case BmReportsFilterType.ISSUED_TO: {
        this.filter.issuedTo = value;
        break;
      }
      case BmReportsFilterType.ISSUED_BY: {
        this.filter.issuedBy = value;
        break;
      }
      case BmReportsFilterType.CONS_CAT_TYPE: {
        this.filter.consCatTypes = value;
        break;
      }
      case BmReportsFilterType.CONS_STATUS: {
        this.filter.consStatus = value;
        break;
      }
      case BmReportsFilterType.AUTO_TYPE: {
        this.filter.automationTypes = value;
        break;
      }
      case BmReportsFilterType.CONS_ID: {
        this.filter.consID = value;
        break;
      }
      case BmReportsFilterType.AUTO_GROUP: {
        this.filter.autoGroup = value;
        break;
      }
      case BmReportsFilterType.CONS_CAT: {
        this.filter.consequenceCategory = value;
        break;
      }
      case BmReportsFilterType.USER_LIST: {
        this.filter.userList = value;
        break;
      }
      default: {
        break;
      }
    }
  }

  _convertTableDataForFilter(value: any) {
    if (value.person) {
      return value.person;
    } else if (value.typeId) {
      return value.typeId;
    }
    return value;
  }

  _mapFiltersToFilterMessage(
    reportType: ReportTypes = ReportTypes.PBIS_SUMMARY,
    offset?: number,
    limit?: number,
  ): report_proto.GetOrExportReportRequest {
    const req = new report_proto.GetOrExportReportRequest();
    const filter = {
      ...this.filter,
      issuedTo: this.filter.issuedTo.map((p: any) => p.hash || p.personHash),
      issuedBy: this.filter.issuedBy.map((p: any) => p.hash || p.personHash),
    };
    const filters = BehaviorFilterMapper.toProto(filter, limit, offset);
    req.setReportType(reportType);
    req.setPbisFilters(filters);
    return req;
  }
}
