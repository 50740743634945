<mg-overlay-primary
  size="medium"
  [overlayTitle]="'About Minga'">
  <div class="about-us-content">
    <mg-text
      variant="body-sm"
      spacing="4">
      <span>
        You are running Minga version <strong>{{ version }}</strong> on
        <strong>{{ platform }}</strong
        >.
        <br />
        Messaging strategy is <strong>{{ messagingStrategy }}</strong> ({{
          messagingPermissionStatus$ | async
        }})
        <br />
        <!-- outputs a couple characters from remote config to help with debug purposes -->
        {{ appConfig.getValue('REMOTE_DEBUG') }}
      </span>
    </mg-text>

    <mg-text
      variant="header-md-secondary"
      spacing="2"
      >About Us</mg-text
    >
    <mg-text
      variant="body-sm"
      spacing="2">
      Minga is based in beautiful Kelowna, BC. We are a dedicated and growing
      team that every day, finds ways to connect people to each other, to
      inspiring and relevant content, and to their community.
    </mg-text>
    <mg-text
      variant="body-sm"
      spacing="2">
      <span>
        In developing Minga, we have partnered with some amazing teachers,
        students, experts, and leaders - we'd like to extend a heartfelt thank
        you for all of the time and wisdom you have provided. And a very loud
        shout-out to
        <a
          href="https://www.pexels.com/"
          target="_blank"
          >Pexels</a
        >
        and
        <a
          href="https://unsplash.com/"
          target="_blank"
          >Unsplash</a
        >
        contributors for their amazing photography and images that we have been
        fortunate enough to utilize in our development.
      </span>
    </mg-text>
    <mg-text
      variant="body-sm"
      spacing="4">
      <span>
        We welcome your feedback, comments, and questions. Please reach out to
        us at
        <a href="mailto:hello@minga.io">hello@minga.io</a>. If you'd like, you
        may also write to us at:
      </span>
    </mg-text>
    <mg-text
      variant="body-sm"
      spacing="2">
      <span>
        <strong>Minga<br /></strong>Unit 301, 460 Doyle Avenue<br />Kelowna,
        BC<br />V1Y 0C2
      </span>
    </mg-text>

    <mat-accordion>
      <mat-expansion-panel class="bordered">
        <mat-expansion-panel-header>
          <mat-panel-title mat-subheader>
            <mg-text variant="body-lg">Privacy Policy</mg-text>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mg-page
            [path]="'privacy-policy'"
            margin="0"></mg-page>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="bordered">
        <mat-expansion-panel-header>
          <mat-panel-title mat-subheader>
            <mg-text variant="body-lg">Terms of Use</mg-text>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mg-page
            [path]="'tos'"
            margin="0"></mg-page>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="bordered">
        <mat-expansion-panel-header>
          <mat-panel-title mat-subheader>
            <mg-text variant="body-lg">Photo Sources</mg-text>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <mg-text variant="header-sm">Pexels</mg-text>

          <mg-text
            variant="body-sm"
            spacing="4">
            <span>
              License:
              <a
                href="https://creativecommons.org/publicdomain/zero/1.0/"
                target="_blank"
                >Creative Commons Zero</a
              >
              <br />Url:
              <a
                href="https://www.pexels.com/"
                target="_blank"
                >https://www.pexels.com/</a
              ></span
            >
          </mg-text>

          <mg-text variant="header-sm">Unsplash</mg-text>
          <mg-text variant="body-sm">
            <span>
              License:
              <a
                href="https://creativecommons.org/publicdomain/zero/1.0/"
                target="_blank"
                >Creative Commons Zero</a
              >
              <br />Url:
              <a
                href="https://unsplash.com/"
                target="_blank"
                >https://unsplash.com/</a
              >
            </span>
          </mg-text>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="bordered">
        <mat-expansion-panel-header>
          <mat-panel-title mat-subheader>
            <mg-text variant="body-lg">Libraries We Use</mg-text>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <mg-text
            variant="body-sm"
            spacing="2">
            The following sets forth attribution notices for third party
            software that may be contained in portions of the "Minga" mobile
            applications. We thank the open source community for all of their
            contributions.
          </mg-text>
          <div
            *ngFor="let license of licenses"
            class="list-item">
            <mg-text variant="header-sm">{{ license.name }}</mg-text>
            <mg-text
              variant="body-sm"
              spacing="2">
              <span>
                License: {{ license.license }}
                <span *ngIf="license.publisher"
                  ><br />Publisher: {{ license.publisher }}</span
                >
                <span *ngIf="license.url"
                  ><br />Url:
                  <a
                    [href]="license.url"
                    target="_blank"
                    >{{ license.url }}</a
                  ></span
                >
              </span>
            </mg-text>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mg-overlay-primary>
