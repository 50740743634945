import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IPbisStatsPeopleResult } from 'minga/libraries/domain';
import {
  ColumnInfo,
  TemplateColumnKeys,
  BehaviorReportColumns,
} from 'minga/libraries/shared';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';

import { BHStudentSummaryInfo, BHStudentHistoryInfo } from '../constants';
import { BmReportsService } from './bm-reports.service';

@Injectable()
export class BmReportsStudentDatasourceService extends ReportDatasourceService<IPbisStatsPeopleResult> {
  historyInfo = BHStudentHistoryInfo;
  summaryInfo = BHStudentSummaryInfo;

  constructor(_router: Router, private _bmReports: BmReportsService) {
    super(_router, _bmReports, BmReportsStudentDatasourceService.name);
    const displayCol: ColumnInfo[] = [
      {
        key: TemplateColumnKeys.SUMMARY,
        header: 'Summary',
        columnClasses: ['w-16'],
      },
      {
        key: TemplateColumnKeys.HISTORY,
        header: 'History',
        columnClasses: ['w-16'],
      },
    ];
    super.setDisplayColumns(BehaviorReportColumns.STUDENTS, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._bmReports.getStudents(offset, limit, this._currentSort);
  }
}
