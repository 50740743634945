import { Injectable } from '@angular/core';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Group, MingaGroupMemberRank } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { RolesService } from 'minga/app/src/app/roles/services';
import { MingaPermission } from 'minga/libraries/domain';
import { MingaContent } from 'minga/proto/content/minga_ng_grpc_pb';
import { MingaSetCommentingRolesRequest } from 'minga/proto/content/minga_pb';

@Injectable({ providedIn: 'root' })
export class CommentHandlerService {
  constructor(
    private mingaContent: MingaContent,
    private groupsFacade: GroupsFacadeService,
    private rolesService: RolesService,
    private permissions: PermissionsService,
  ) {}

  async sendCommentRolesRequest(contentHash: string, roleTypes: string[]) {
    const request = new MingaSetCommentingRolesRequest();
    if (!roleTypes) {
      return;
    }
    request.setContentHash(contentHash);
    request.setAllowedCommentingRoleTypesList(roleTypes);

    await this.mingaContent.setCommentingRoles(request);
  }

  /**
   * Get default commentable roles. If the content is in a group with group
   * comment settings, use the group settings, otherwise use account level
   * settings.
   *
   * @param ownerGroupHash
   */
  getDefaultCommentableRoles(ownerGroupHash?: string): Observable<string[]> {
    const groupObservable = this._getGroupObservable(ownerGroupHash);

    return combineLatest([
      this.rolesService.commentPermitableRoles$,
      groupObservable,
    ]).pipe(
      map(([defaultAccountRoles, group]) => {
        if (group && group.allowedCommentingRoleTypes) {
          return group.allowedCommentingRoleTypes;
        } else {
          return defaultAccountRoles.map(r => r.roleType);
        }
      }),
    );
  }

  /**
   * Get the group information from the hash if possible, otherwise
   * get it from context.
   */
  private _getGroupObservable(
    ownerGroupHash?: string,
  ): Observable<Group | null> {
    if (ownerGroupHash) {
      return this.groupsFacade.getGroup(ownerGroupHash);
    } else {
      return this.groupsFacade.getCurrentGroupIfSet();
    }
  }

  /**
   * Can the user comment, based on their role and comment settings
   *
   * TODO: Ideally move permission check into canComment function for
   * testability. Not sure how to do that just yet given the way
   * permission service is setup.
   *
   * @param commentRoles - specific role settings saved on a piece of content.
   */
  canCurrentUserComment(
    commentRoles: string[] | null,
    ownerGroupHash?: string,
  ): Observable<boolean> {
    this.rolesService.fetchIfNeeded();

    /** Super admin can always comment on content. */
    if (this.permissions.hasPermission(MingaPermission.SUPERADMIN)) {
      return of(true);
    }

    /**
     * The user does not have permissions to create comments. This permission
     * is set for each user type in `/permissions/internal`, such as
     * StudentLeaderRole or TeacherRole.
     */
    if (
      !this.permissions.hasPermission(MingaPermission.CONTENT_COMMENT_CREATE)
    ) {
      return of(false);
    }

    /**
     * I'm not a fan of calling this service twice, here and potentially in
     * getDefaultCommentableRoles, but I didn't want to touch that method
     * as it appears to be used in more places than canCurrentUserComment.
     */
    const groupObservable = this._getGroupObservable(ownerGroupHash);

    return combineLatest([
      this.getDefaultCommentableRoles(ownerGroupHash),
      this.permissions.mingaRoleType$,
      groupObservable,
    ]).pipe(
      map(([groupRoles, userRole, group]) =>
        this._canComment(
          userRole,
          groupRoles ?? [],
          commentRoles ?? [],
          group?.rank,
        ),
      ),
    );
  }

  /** TODO: Ideally move permissions into here too. */
  private _canComment(
    userRole: string,
    groupRoles: string[],
    commentRoles: string[],
    groupMemberRank?: MingaGroupMemberRank,
  ): boolean {
    /** No role, no commenting allowed. */
    if (!userRole) {
      return false;
    }

    /** The owner of a group can always comment */
    if (groupMemberRank === MingaGroupMemberRank.OWNER) {
      return true;
    }

    /**
     * Post comment roles are set when the user creates the post.
     * They can specify who is allowed to comment on the content.
     *
     * This takes precendence over the default group roles. For
     * example, if the group specifies students cannot comment,
     * and the commentRoles specify that students can comment,
     * then students can comment.
     */
    if (commentRoles && commentRoles.length > 0) {
      return commentRoles.includes(userRole);
    }

    /**
     * Group roles are the default roles that are set when the
     * group is created.
     */
    if (groupRoles) {
      return groupRoles.includes(userRole);
    }

    return false;
  }
}
