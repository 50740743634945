import { Pipe, PipeTransform } from '@angular/core';

import { formatTime } from 'minga/libraries/util';

/**
 * Expects time string to come in 24 hr format eg. 13:00:00
 */
@Pipe({ name: 'formatTime' })
export class MgFormatTimePipe implements PipeTransform {
  transform(time: string): string {
    return formatTime(time);
  }
}
