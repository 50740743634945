"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GalleryItemMapper = void 0;
const gallery_pb_1 = require("minga/proto/gateway/gallery_pb");
var GalleryItemMapper;
(function (GalleryItemMapper) {
    /**
     * Convert IGalleryPhoto to a protobuf GalleryItem message
     *
     * @param imageInfo - Since `ImageInfo` cannot be purely retrieved from
     * `IGalleryInfo` it is an extra parameter.
     */
    function fromIGalleryPhoto(value, extras) {
        const msg = new gallery_pb_1.GalleryItem();
        msg.setDescription(value.description);
        msg.setGalleryPhotoUuid(value.uuid);
        msg.setTimestamp(value.createdTimestamp);
        msg.setImageInfo(extras.imageInfo);
        msg.setAuthorDisplayName(extras.authorDisplayName);
        msg.setAuthorPersonHash(extras.authorPersonHash);
        for (const taggedGroup of value.taggedGroups) {
            const tagMsg = new gallery_pb_1.Tag();
            const groupTagMsg = new gallery_pb_1.Tag.GroupTag();
            groupTagMsg.setGroupHash(taggedGroup.groupHash);
            tagMsg.setGroupTag(groupTagMsg);
            msg.addTag(tagMsg);
        }
        for (const taggedPerson of value.taggedPeople) {
            const tagMsg = new gallery_pb_1.Tag();
            const personTagMsg = new gallery_pb_1.Tag.PersonTag();
            personTagMsg.setPersonHash(taggedPerson.personHash);
            personTagMsg.setDisplayName(taggedPerson.displayName);
            tagMsg.setPersonTag(personTagMsg);
            msg.addTag(tagMsg);
        }
        for (const taggedContent of value.taggedContent) {
            const tagMsg = new gallery_pb_1.Tag();
            const contentTagMsg = new gallery_pb_1.Tag.ContentTag();
            contentTagMsg.setContentHash(taggedContent.hash);
            contentTagMsg.setContentTagTitle(taggedContent.contentTagTitle);
            tagMsg.setContentTag(contentTagMsg);
            msg.addTag(tagMsg);
        }
        return msg;
    }
    GalleryItemMapper.fromIGalleryPhoto = fromIGalleryPhoto;
})(GalleryItemMapper = exports.GalleryItemMapper || (exports.GalleryItemMapper = {}));