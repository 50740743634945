"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const event_pb = require("minga/proto/content/event_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
exports.toProto = (eventPerson) => {
    const msg = new event_pb.EventPerson();
    if (eventPerson.name)
        msg.setName(eventPerson.name);
    if (eventPerson.tickets)
        msg.setTickets(eventPerson.tickets);
    if (eventPerson.checkinTime)
        msg.setCheckinTime(shared_grpc_1.dateObjectToDateTimeMessage(eventPerson.checkinTime));
    if (eventPerson.checkoutTime)
        msg.setCheckoutTime(shared_grpc_1.dateObjectToDateTimeMessage(eventPerson.checkoutTime));
    if (eventPerson.studentId)
        msg.setStudentId(eventPerson.studentId);
    return msg;
};
exports.fromProto = (eventPerson) => {
    const checkinTimeProto = eventPerson.getCheckinTime();
    const checkoutTimeProto = eventPerson.getCheckoutTime();
    return {
        name: eventPerson.getName(),
        tickets: eventPerson.getTickets(),
        checkinTime: checkinTimeProto
            ? shared_grpc_1.dateTimeMessageToDateObject(checkinTimeProto)
            : undefined,
        checkoutTime: checkoutTimeProto
            ? shared_grpc_1.dateTimeMessageToDateObject(checkoutTimeProto)
            : undefined,
        studentId: eventPerson.getStudentId(),
    };
};