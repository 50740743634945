"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamPollsV2Item = exports.DeltaPollInfo = exports.PollInfo = void 0;
const jspb = require("google-protobuf");
const common_delta_pb = require("minga/proto/common/delta_pb");
const common_date_pb = require("minga/proto/common/date_pb");
const common_stream_pb = require("minga/proto/common/stream_pb");
const gateway_content_views_pb = require("minga/proto/gateway/content_views_pb");
class PollInfo extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, PollInfo.oneofGroups_);
    }
    /**
     * @return {PollInfo.PollChoicesCase}
     */
    getPollChoicesCase() {
        return /** @type {PollInfo.PollChoicesCase} */ (jspb.Message.computeOneofCase(this, PollInfo.oneofGroups_[0]));
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PollInfo.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PollInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            question: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pollStyleId: jspb.Message.getFieldWithDefault(msg, 4, 0),
            showResults: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            closeDate: (f = msg.getCloseDate()) && common_date_pb.Date.toObject(includeInstance, f),
            closed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            points: jspb.Message.getFieldWithDefault(msg, 8, 0),
            emotivePollChoices: (f = msg.getEmotivePollChoices()) && PollInfo.EmotivePollChoices.toObject(includeInstance, f),
            listPollChoices: (f = msg.getListPollChoices()) && PollInfo.ListPollChoices.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PollInfo}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PollInfo;
        return PollInfo.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PollInfo} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PollInfo}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setQuestion(value1);
                    break;
                case 4:
                    var value4 = /** @type {number} */ (reader.readUint32());
                    msg.setPollStyleId(value4);
                    break;
                case 5:
                    var value5 = /** @type {boolean} */ (reader.readBool());
                    msg.setShowResults(value5);
                    break;
                case 6:
                    var value6 = new common_date_pb.Date;
                    reader.readMessage(value6, common_date_pb.Date.deserializeBinaryFromReader);
                    msg.setCloseDate(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setClosed(value7);
                    break;
                case 8:
                    var value8 = /** @type {number} */ (reader.readInt32());
                    msg.setPoints(value8);
                    break;
                case 2:
                    var value2 = new PollInfo.EmotivePollChoices;
                    reader.readMessage(value2, PollInfo.EmotivePollChoices.deserializeBinaryFromReader);
                    msg.setEmotivePollChoices(value2);
                    break;
                case 3:
                    var value3 = new PollInfo.ListPollChoices;
                    reader.readMessage(value3, PollInfo.ListPollChoices.deserializeBinaryFromReader);
                    msg.setListPollChoices(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PollInfo} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getQuestion();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getPollStyleId();
        if (f !== 0) {
            writer.writeUint32(4, f);
        }
        f = message.getShowResults();
        if (f) {
            writer.writeBool(5, f);
        }
        f = message.getCloseDate();
        if (f != null) {
            writer.writeMessage(6, f, common_date_pb.Date.serializeBinaryToWriter);
        }
        f = message.getClosed();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getPoints();
        if (f !== 0) {
            writer.writeInt32(8, f);
        }
        f = message.getEmotivePollChoices();
        if (f != null) {
            writer.writeMessage(2, f, PollInfo.EmotivePollChoices.serializeBinaryToWriter);
        }
        f = message.getListPollChoices();
        if (f != null) {
            writer.writeMessage(3, f, PollInfo.ListPollChoices.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PollInfo.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string question = 1;
     * @return {string}
     */
    getQuestion() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setQuestion(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional uint32 poll_style_id = 4;
     * @return {number}
     */
    getPollStyleId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
    }
    ;
    /** @param {number} value */
    setPollStyleId(value) {
        jspb.Message.setProto3IntField(this, 4, value);
    }
    /**
     * optional bool show_results = 5;
     * @return {boolean}
     */
    getShowResults() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
    }
    ;
    /** @param {boolean} value */
    setShowResults(value) {
        jspb.Message.setProto3BooleanField(this, 5, value);
    }
    /**
     * optional minga.common.Date close_date = 6;
     * @return {?Date}
     */
    getCloseDate() {
        return /** @type{?Date} */ (jspb.Message.getWrapperField(this, common_date_pb.Date, 6));
    }
    /** @param {?Date|undefined} value */
    setCloseDate(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearCloseDate() {
        this.setCloseDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasCloseDate() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional bool closed = 7;
     * @return {boolean}
     */
    getClosed() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setClosed(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional int32 points = 8;
     * @return {number}
     */
    getPoints() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
    }
    ;
    /** @param {number} value */
    setPoints(value) {
        jspb.Message.setProto3IntField(this, 8, value);
    }
    /**
     * optional EmotivePollChoices emotive_poll_choices = 2;
     * @return {?PollInfo.EmotivePollChoices}
     */
    getEmotivePollChoices() {
        return /** @type{?PollInfo.EmotivePollChoices} */ (jspb.Message.getWrapperField(this, PollInfo.EmotivePollChoices, 2));
    }
    /** @param {?PollInfo.EmotivePollChoices|undefined} value */
    setEmotivePollChoices(value) {
        jspb.Message.setOneofWrapperField(this, 2, PollInfo.oneofGroups_[0], value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearEmotivePollChoices() {
        this.setEmotivePollChoices(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasEmotivePollChoices() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional ListPollChoices list_poll_choices = 3;
     * @return {?PollInfo.ListPollChoices}
     */
    getListPollChoices() {
        return /** @type{?PollInfo.ListPollChoices} */ (jspb.Message.getWrapperField(this, PollInfo.ListPollChoices, 3));
    }
    /** @param {?PollInfo.ListPollChoices|undefined} value */
    setListPollChoices(value) {
        jspb.Message.setOneofWrapperField(this, 3, PollInfo.oneofGroups_[0], value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearListPollChoices() {
        this.setListPollChoices(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasListPollChoices() {
        return jspb.Message.getField(this, 3) != null;
    }
}
exports.PollInfo = PollInfo;
PollInfo.displayName = "PollInfo";
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
PollInfo.oneofGroups_ = [[2, 3]];
(function (PollInfo) {
    let PollChoicesCase;
    (function (PollChoicesCase) {
        PollChoicesCase[PollChoicesCase["POLL_CHOICES_NOT_SET"] = 0] = "POLL_CHOICES_NOT_SET";
        PollChoicesCase[PollChoicesCase["EMOTIVE_POLL_CHOICES"] = 2] = "EMOTIVE_POLL_CHOICES";
        PollChoicesCase[PollChoicesCase["LIST_POLL_CHOICES"] = 3] = "LIST_POLL_CHOICES";
    })(PollChoicesCase = PollInfo.PollChoicesCase || (PollInfo.PollChoicesCase = {}));
})(PollInfo = exports.PollInfo || (exports.PollInfo = {})); // namespace PollInfo
(function (PollInfo) {
    class EmotivePollChoices extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return EmotivePollChoices.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!EmotivePollChoices} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {};
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!EmotivePollChoices}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new EmotivePollChoices;
            return EmotivePollChoices.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!PollInfo.EmotivePollChoices} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!PollInfo.EmotivePollChoices}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!PollInfo.EmotivePollChoices} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            PollInfo.EmotivePollChoices.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
    }
    EmotivePollChoices.displayName = "PollInfo.EmotivePollChoices";
    PollInfo.EmotivePollChoices = EmotivePollChoices;
})(PollInfo = exports.PollInfo || (exports.PollInfo = {})); // namespace PollInfo
(function (PollInfo) {
    class ListPollChoices extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, PollInfo.ListPollChoices.repeatedFields_, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return ListPollChoices.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!ListPollChoices} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                choiceList: jspb.Message.toObjectList(msg.getChoiceList(), PollInfo.ListPollChoices.Choice.toObject, includeInstance)
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!ListPollChoices}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new ListPollChoices;
            return ListPollChoices.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!PollInfo.ListPollChoices} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!PollInfo.ListPollChoices}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = new PollInfo.ListPollChoices.Choice;
                        reader.readMessage(value1, PollInfo.ListPollChoices.Choice.deserializeBinaryFromReader);
                        msg.addChoice(value1);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!PollInfo.ListPollChoices} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getChoiceList();
            if (f.length > 0) {
                writer.writeRepeatedMessage(1, f, PollInfo.ListPollChoices.Choice.serializeBinaryToWriter);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            PollInfo.ListPollChoices.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * repeated Choice choice = 1;
         * @return {!Array<!PollInfo.ListPollChoices.Choice>}
         */
        getChoiceList() {
            return /** @type{!Array<!PollInfo.ListPollChoices.Choice>} */ (jspb.Message.getRepeatedWrapperField(this, PollInfo.ListPollChoices.Choice, 1));
        }
        /** @param {!Array<!PollInfo.ListPollChoices.Choice>} value */
        setChoiceList(value) {
            jspb.Message.setRepeatedWrapperField(this, 1, value);
        }
        /**
         * @param {!PollInfo.ListPollChoices.Choice=} opt_value
         * @param {number=} opt_index
         * @return {!PollInfo.ListPollChoices.Choice}
         */
        addChoice(opt_value, opt_index) {
            return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PollInfo.ListPollChoices.Choice, opt_index);
        }
        /**
         * Clears the list making it empty but non-null.
         */
        clearChoiceList() {
            this.setChoiceList([]);
        }
    }
    ListPollChoices.displayName = "PollInfo.ListPollChoices";
    /**
     * List of repeated fields within this message type.
     * @private {!Array<number>}
     * @const
     */
    ListPollChoices.repeatedFields_ = [1];
    PollInfo.ListPollChoices = ListPollChoices;
})(PollInfo = exports.PollInfo || (exports.PollInfo = {})); // namespace PollInfo
(function (PollInfo) {
    let ListPollChoices;
    (function (ListPollChoices) {
        class Choice extends jspb.Message {
            /**
             * Generated by JsPbCodeGenerator.
             * @param {Array=} opt_data Optional initial data array, typically from a
             * server response, or constructed directly in Javascript. The array is used
             * in place and becomes part of the constructed object. It is not cloned.
             * If no data is provided, the constructed object will be empty, but still
             * valid.
             * @extends {jspb.Message}
             * @constructor
             */
            constructor(opt_data) {
                super();
                jspb.Message.initialize(this, opt_data, 0, -1, null, null);
            }
            /**
             * Creates an object representation of this proto.
             * Field names that are reserved in JavaScript and will be renamed to pb_name.
             * Optional fields that are not set will be set to undefined.
             * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
             * For the list of reserved names please see:
             *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
             * @param {boolean=} opt_includeInstance Deprecated. whether to include the
             *     JSPB instance for transitional soy proto support:
             *     http://goto/soy-param-migration
             * @return {!Object}
             */
            toObject(includeInstance) {
                return Choice.toObject(includeInstance || false, this);
            }
            /**
             * Static version of the {@see toObject} method.
             * @param {boolean|undefined} includeInstance Deprecated. Whether to include
             *     the JSPB instance for transitional soy proto support:
             *     http://goto/soy-param-migration
             * @param {!Choice} msg The msg instance to transform.
             * @return {!Object}
             * @suppress {unusedLocalVariables} f is only used for nested messages
             */
            static toObject(includeInstance, msg) {
                var f;
                var obj = {
                    choiceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
                    choiceText: jspb.Message.getFieldWithDefault(msg, 2, "")
                };
                if (includeInstance) {
                    obj.$jspbMessageInstance = msg;
                }
                return obj;
            }
            /**
             * Deserializes binary data (in protobuf wire format).
             * @param {jspb.ByteSource} bytes The bytes to deserialize.
             * @return {!Choice}
             */
            static deserializeBinary(bytes) {
                var reader = new jspb.BinaryReader(bytes);
                var msg = new Choice;
                return Choice.deserializeBinaryFromReader(msg, reader);
            }
            /**
             * Deserializes binary data (in protobuf wire format) from the
             * given reader into the given message object.
             * @param {!PollInfo.ListPollChoices.Choice} msg The message object to deserialize into.
             * @param {!jspb.BinaryReader} reader The BinaryReader to use.
             * @return {!PollInfo.ListPollChoices.Choice}
             */
            static deserializeBinaryFromReader(msg, reader) {
                while (reader.nextField()) {
                    if (reader.isEndGroup()) {
                        break;
                    }
                    var field = reader.getFieldNumber();
                    switch (field) {
                        case 1:
                            var value1 = /** @type {number} */ (reader.readUint32());
                            msg.setChoiceId(value1);
                            break;
                        case 2:
                            var value2 = /** @type {string} */ (reader.readString());
                            msg.setChoiceText(value2);
                            break;
                        default:
                            reader.skipField();
                            break;
                    }
                }
                return msg;
            }
            /**
             * Serializes the given message to binary data (in protobuf wire
             * format), writing to the given BinaryWriter.
             * @param {!PollInfo.ListPollChoices.Choice} message
             * @param {!jspb.BinaryWriter} writer
             * @suppress {unusedLocalVariables} f is only used for nested messages
             */
            static serializeBinaryToWriter(message, writer) {
                var f = undefined;
                f = message.getChoiceId();
                if (f !== 0) {
                    writer.writeUint32(1, f);
                }
                f = message.getChoiceText();
                if (f.length > 0) {
                    writer.writeString(2, f);
                }
            }
            /**
             * Serializes the message to binary data (in protobuf wire format).
             * @return {!Uint8Array}
             */
            serializeBinary() {
                var writer = new jspb.BinaryWriter();
                PollInfo.ListPollChoices.Choice.serializeBinaryToWriter(this, writer);
                return writer.getResultBuffer();
            }
            /**
             * optional uint32 choice_id = 1;
             * @return {number}
             */
            getChoiceId() {
                return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
            }
            ;
            /** @param {number} value */
            setChoiceId(value) {
                jspb.Message.setProto3IntField(this, 1, value);
            }
            /**
             * optional string choice_text = 2;
             * @return {string}
             */
            getChoiceText() {
                return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
            }
            ;
            /** @param {string} value */
            setChoiceText(value) {
                jspb.Message.setProto3StringField(this, 2, value);
            }
        }
        Choice.displayName = "PollInfo.ListPollChoices.Choice";
        ListPollChoices.Choice = Choice;
    })(ListPollChoices = PollInfo.ListPollChoices || (PollInfo.ListPollChoices = {})); // namespace PollInfo
})(PollInfo = exports.PollInfo || (exports.PollInfo = {})); // namespace ListPollChoices
class DeltaPollInfo extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, DeltaPollInfo.oneofGroups_);
    }
    /**
     * @return {DeltaPollInfo.PollChoicesCase}
     */
    getPollChoicesCase() {
        return /** @type {DeltaPollInfo.PollChoicesCase} */ (jspb.Message.computeOneofCase(this, DeltaPollInfo.oneofGroups_[0]));
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeltaPollInfo.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeltaPollInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            question: (f = msg.getQuestion()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            pollStyleId: (f = msg.getPollStyleId()) && common_delta_pb.Uint32Delta.toObject(includeInstance, f),
            showResults: (f = msg.getShowResults()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            closeDate: (f = msg.getCloseDate()) && common_delta_pb.DateDelta.toObject(includeInstance, f),
            closed: (f = msg.getClosed()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            points: (f = msg.getPoints()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
            emotivePollChoices: (f = msg.getEmotivePollChoices()) && DeltaPollInfo.EmotivePollChoicesDelta.toObject(includeInstance, f),
            listPollChoices: (f = msg.getListPollChoices()) && PollInfo.ListPollChoices.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeltaPollInfo}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeltaPollInfo;
        return DeltaPollInfo.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeltaPollInfo} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeltaPollInfo}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setContentHash(value1);
                    break;
                case 2:
                    var value2 = new common_delta_pb.StringDelta;
                    reader.readMessage(value2, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setQuestion(value2);
                    break;
                case 5:
                    var value5 = new common_delta_pb.Uint32Delta;
                    reader.readMessage(value5, common_delta_pb.Uint32Delta.deserializeBinaryFromReader);
                    msg.setPollStyleId(value5);
                    break;
                case 6:
                    var value6 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value6, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setShowResults(value6);
                    break;
                case 7:
                    var value7 = new common_delta_pb.DateDelta;
                    reader.readMessage(value7, common_delta_pb.DateDelta.deserializeBinaryFromReader);
                    msg.setCloseDate(value7);
                    break;
                case 8:
                    var value8 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value8, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setClosed(value8);
                    break;
                case 9:
                    var value9 = new common_delta_pb.Int32Delta;
                    reader.readMessage(value9, common_delta_pb.Int32Delta.deserializeBinaryFromReader);
                    msg.setPoints(value9);
                    break;
                case 3:
                    var value3 = new DeltaPollInfo.EmotivePollChoicesDelta;
                    reader.readMessage(value3, DeltaPollInfo.EmotivePollChoicesDelta.deserializeBinaryFromReader);
                    msg.setEmotivePollChoices(value3);
                    break;
                case 4:
                    var value4 = new PollInfo.ListPollChoices;
                    reader.readMessage(value4, PollInfo.ListPollChoices.deserializeBinaryFromReader);
                    msg.setListPollChoices(value4);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeltaPollInfo} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getContentHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getQuestion();
        if (f != null) {
            writer.writeMessage(2, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getPollStyleId();
        if (f != null) {
            writer.writeMessage(5, f, common_delta_pb.Uint32Delta.serializeBinaryToWriter);
        }
        f = message.getShowResults();
        if (f != null) {
            writer.writeMessage(6, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getCloseDate();
        if (f != null) {
            writer.writeMessage(7, f, common_delta_pb.DateDelta.serializeBinaryToWriter);
        }
        f = message.getClosed();
        if (f != null) {
            writer.writeMessage(8, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getPoints();
        if (f != null) {
            writer.writeMessage(9, f, common_delta_pb.Int32Delta.serializeBinaryToWriter);
        }
        f = message.getEmotivePollChoices();
        if (f != null) {
            writer.writeMessage(3, f, DeltaPollInfo.EmotivePollChoicesDelta.serializeBinaryToWriter);
        }
        f = message.getListPollChoices();
        if (f != null) {
            writer.writeMessage(4, f, PollInfo.ListPollChoices.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeltaPollInfo.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string content_hash = 1;
     * @return {string}
     */
    getContentHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setContentHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional minga.common.StringDelta question = 2;
     * @return {?StringDelta}
     */
    getQuestion() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
    }
    /** @param {?StringDelta|undefined} value */
    setQuestion(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearQuestion() {
        this.setQuestion(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasQuestion() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional minga.common.Uint32Delta poll_style_id = 5;
     * @return {?Uint32Delta}
     */
    getPollStyleId() {
        return /** @type{?Uint32Delta} */ (jspb.Message.getWrapperField(this, common_delta_pb.Uint32Delta, 5));
    }
    /** @param {?Uint32Delta|undefined} value */
    setPollStyleId(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPollStyleId() {
        this.setPollStyleId(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPollStyleId() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.BoolDelta show_results = 6;
     * @return {?BoolDelta}
     */
    getShowResults() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 6));
    }
    /** @param {?BoolDelta|undefined} value */
    setShowResults(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearShowResults() {
        this.setShowResults(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasShowResults() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional minga.common.DateDelta close_date = 7;
     * @return {?DateDelta}
     */
    getCloseDate() {
        return /** @type{?DateDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.DateDelta, 7));
    }
    /** @param {?DateDelta|undefined} value */
    setCloseDate(value) {
        jspb.Message.setWrapperField(this, 7, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearCloseDate() {
        this.setCloseDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasCloseDate() {
        return jspb.Message.getField(this, 7) != null;
    }
    /**
     * optional minga.common.BoolDelta closed = 8;
     * @return {?BoolDelta}
     */
    getClosed() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 8));
    }
    /** @param {?BoolDelta|undefined} value */
    setClosed(value) {
        jspb.Message.setWrapperField(this, 8, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearClosed() {
        this.setClosed(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasClosed() {
        return jspb.Message.getField(this, 8) != null;
    }
    /**
     * optional minga.common.Int32Delta points = 9;
     * @return {?Int32Delta}
     */
    getPoints() {
        return /** @type{?Int32Delta} */ (jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 9));
    }
    /** @param {?Int32Delta|undefined} value */
    setPoints(value) {
        jspb.Message.setWrapperField(this, 9, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPoints() {
        this.setPoints(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPoints() {
        return jspb.Message.getField(this, 9) != null;
    }
    /**
     * optional EmotivePollChoicesDelta emotive_poll_choices = 3;
     * @return {?DeltaPollInfo.EmotivePollChoicesDelta}
     */
    getEmotivePollChoices() {
        return /** @type{?DeltaPollInfo.EmotivePollChoicesDelta} */ (jspb.Message.getWrapperField(this, DeltaPollInfo.EmotivePollChoicesDelta, 3));
    }
    /** @param {?DeltaPollInfo.EmotivePollChoicesDelta|undefined} value */
    setEmotivePollChoices(value) {
        jspb.Message.setOneofWrapperField(this, 3, DeltaPollInfo.oneofGroups_[0], value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearEmotivePollChoices() {
        this.setEmotivePollChoices(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasEmotivePollChoices() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional PollInfo.ListPollChoices list_poll_choices = 4;
     * @return {?PollInfo.ListPollChoices}
     */
    getListPollChoices() {
        return /** @type{?PollInfo.ListPollChoices} */ (jspb.Message.getWrapperField(this, PollInfo.ListPollChoices, 4));
    }
    /** @param {?PollInfo.ListPollChoices|undefined} value */
    setListPollChoices(value) {
        jspb.Message.setOneofWrapperField(this, 4, DeltaPollInfo.oneofGroups_[0], value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearListPollChoices() {
        this.setListPollChoices(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasListPollChoices() {
        return jspb.Message.getField(this, 4) != null;
    }
}
exports.DeltaPollInfo = DeltaPollInfo;
DeltaPollInfo.displayName = "DeltaPollInfo";
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
DeltaPollInfo.oneofGroups_ = [[3, 4]];
(function (DeltaPollInfo) {
    let PollChoicesCase;
    (function (PollChoicesCase) {
        PollChoicesCase[PollChoicesCase["POLL_CHOICES_NOT_SET"] = 0] = "POLL_CHOICES_NOT_SET";
        PollChoicesCase[PollChoicesCase["EMOTIVE_POLL_CHOICES"] = 3] = "EMOTIVE_POLL_CHOICES";
        PollChoicesCase[PollChoicesCase["LIST_POLL_CHOICES"] = 4] = "LIST_POLL_CHOICES";
    })(PollChoicesCase = DeltaPollInfo.PollChoicesCase || (DeltaPollInfo.PollChoicesCase = {}));
})(DeltaPollInfo = exports.DeltaPollInfo || (exports.DeltaPollInfo = {})); // namespace DeltaPollInfo
(function (DeltaPollInfo) {
    class EmotivePollChoicesDelta extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return EmotivePollChoicesDelta.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!EmotivePollChoicesDelta} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {};
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!EmotivePollChoicesDelta}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new EmotivePollChoicesDelta;
            return EmotivePollChoicesDelta.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!DeltaPollInfo.EmotivePollChoicesDelta} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!DeltaPollInfo.EmotivePollChoicesDelta}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!DeltaPollInfo.EmotivePollChoicesDelta} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            DeltaPollInfo.EmotivePollChoicesDelta.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
    }
    EmotivePollChoicesDelta.displayName = "DeltaPollInfo.EmotivePollChoicesDelta";
    DeltaPollInfo.EmotivePollChoicesDelta = EmotivePollChoicesDelta;
})(DeltaPollInfo = exports.DeltaPollInfo || (exports.DeltaPollInfo = {})); // namespace DeltaPollInfo
class StreamPollsV2Item extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StreamPollsV2Item.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StreamPollsV2Item} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
            item: (f = msg.getItem()) && gateway_content_views_pb.ShortPollCardView.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StreamPollsV2Item}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StreamPollsV2Item;
        return StreamPollsV2Item.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StreamPollsV2Item} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StreamPollsV2Item}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_stream_pb.StreamItemMetadata;
                    reader.readMessage(value1, common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
                    msg.setItemMetadata(value1);
                    break;
                case 2:
                    var value2 = new gateway_content_views_pb.ShortPollCardView;
                    reader.readMessage(value2, gateway_content_views_pb.ShortPollCardView.deserializeBinaryFromReader);
                    msg.setItem(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StreamPollsV2Item} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getItemMetadata();
        if (f != null) {
            writer.writeMessage(1, f, common_stream_pb.StreamItemMetadata.serializeBinaryToWriter);
        }
        f = message.getItem();
        if (f != null) {
            writer.writeMessage(2, f, gateway_content_views_pb.ShortPollCardView.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StreamPollsV2Item.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StreamItemMetadata item_metadata = 1;
     * @return {?StreamItemMetadata}
     */
    getItemMetadata() {
        return /** @type{?StreamItemMetadata} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
    }
    /** @param {?StreamItemMetadata|undefined} value */
    setItemMetadata(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItemMetadata() {
        this.setItemMetadata(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItemMetadata() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional ShortPollCardView item = 2;
     * @return {?ShortPollCardView}
     */
    getItem() {
        return /** @type{?ShortPollCardView} */ (jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortPollCardView, 2));
    }
    /** @param {?ShortPollCardView|undefined} value */
    setItem(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItem() {
        this.setItem(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItem() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.StreamPollsV2Item = StreamPollsV2Item;
StreamPollsV2Item.displayName = "StreamPollsV2Item";