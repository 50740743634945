"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexToStudentSection = void 0;
const domain_1 = require("minga/libraries/domain/index");
exports.flexToStudentSection = (r) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return {
        id: (_a = r.registration) === null || _a === void 0 ? void 0 : _a.flexTimeActivity.id,
        name: (_b = r.registration) === null || _b === void 0 ? void 0 : _b.flexTimeActivity.name,
        period: (_d = (_c = r.registration) === null || _c === void 0 ? void 0 : _c.flexTimePeriod) === null || _d === void 0 ? void 0 : _d.title,
        term: '',
        date: (_e = r.registration) === null || _e === void 0 ? void 0 : _e.flexTimePeriod.date,
        startTime: (_f = r.registration) === null || _f === void 0 ? void 0 : _f.flexTimePeriod.startTime,
        endTime: (_g = r.registration) === null || _g === void 0 ? void 0 : _g.flexTimePeriod.endTime,
        teachers: [(_k = (_j = (_h = r.registration) === null || _h === void 0 ? void 0 : _h.flexTimeActivity) === null || _j === void 0 ? void 0 : _j.createdByPerson) === null || _k === void 0 ? void 0 : _k.name],
        location: (_l = r.registration) === null || _l === void 0 ? void 0 : _l.flexTimeActivity.location,
        type: domain_1.SectionType.FLEX,
    };
};