"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentLeaderRole = void 0;
const domain_1 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const UserRole_1 = require("minga/libraries/shared/permissions/internal/UserRole");
class StudentLeaderRole extends UserRole_1.UserRole {
    constructor() {
        super(...arguments);
        this.roleType = domain_1.MingaRoleType.STUDENT_LEADER;
        this.name = 'Student Leader';
        this.displayNameFormat = util_1.DisplayNameFormat.FIRST_LAST;
        this.iconUrl = 'assets/roles/student-leader.svg';
        this.iconColor = '#86BFAC';
        this.capabilitiesDescription = `Student leaders will be allowed to share posts, pictures, videos, and events`;
        this.feedPermittable = true;
        this.galleryPermittable = true;
        this.roleFields = util_1.RoleFields.GRAD_STUDENT;
        this.groupFeedPermittable = true;
        this.programManagePermittable = false;
        this.videoUploadPermittable = true;
        this.commentPermittable = true;
        this.groupCreatePermittable = true;
        this.admin = false;
        this.requiredFeatures = [];
        this.sendToMailChimp = false;
        this.IDRole = UserRole_1.RoleCategory.STUDENT;
        this.permissions = [
            domain_1.MingaPermission.ACCOUNT_PROFILE_CHANGE,
            domain_1.MingaPermission.ASSET_UPLOAD,
            domain_1.MingaPermission.CONTENT_COMMENT_CREATE,
            domain_1.MingaPermission.CONTENT_COMMENTS_VIEW,
            domain_1.MingaPermission.CONTENT_GROUP_ENABLED,
            domain_1.MingaPermission.CONTENT_LIKES_LIKE,
            domain_1.MingaPermission.CONTENT_POST_CREATE,
            domain_1.MingaPermission.CONTENT_REPORT_CREATE,
            domain_1.MingaPermission.CONTENT_VIDEO_CREATE,
            domain_1.MingaPermission.CONTENT_POLL_ANSWER,
            domain_1.MingaPermission.GROUP_CONTENT_EVENTS_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_POST_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_POLL_CREATE,
            domain_1.MingaPermission.CONTENT_POLL_CREATE,
            domain_1.MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
            domain_1.MingaPermission.GROUP_ALLOW_PARENT_GROUP,
            domain_1.MingaPermission.EMAIL_CONTENT_AS_GROUP_OWNER,
            domain_1.MingaPermission.CONTENT_POST_SET_TITLE,
            domain_1.MingaPermission.CONTENT_PUBLISH_DATES_SET,
            domain_1.MingaPermission.CHALLENGE_COMPLETE,
            domain_1.MingaPermission.STUDENT_TOOLS,
            domain_1.MingaPermission.SELF_CHECKIN,
            domain_1.MingaPermission.FLEX_TIME_REGISTER_FOR_ACTIVITY,
            domain_1.MingaPermission.STUDENT_SCHEDULE_VIEW,
            domain_1.MingaPermission.CONTENT_EVENT_CREATE,
        ];
        this.overridablePermissions = [];
    }
}
exports.StudentLeaderRole = StudentLeaderRole;