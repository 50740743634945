import { Observable } from 'rxjs';

export type CacheItem<T> = {
  date: Date;
  value: Observable<T>;
};

export type StorageAdapter = {
  get<T>(key: string): Promise<CacheItem<T> | null>;
  set<T>(
    key: string,
    value: {
      date: Date;
      value: Observable<T>;
    },
  ): Promise<void>;
  delete(key: string): Promise<void>;
  clearAll(): Promise<void>;
  getKeysByNamespace(namespace: CacheKey): Promise<string[]>;
};

export type StorageAdapterType = 'inMemory';

export enum CacheKey {
  DATE_PRESETS_CUSTOM = 'datePresets:custom',
  USER_LIST_MY_LISTS = 'userList:myLists',
  USER_LIST_ALL_LISTS = 'userList:allLists',
  DISTRICT_LIST = 'district:list',
  STATS_USER = 'stats:user',
  STATS_MINGA = 'stats:minga',
  STUDENT_SCHEDULE = 'studentSchedule',
  HALL_PASS_TYPES_WITH_INACTIVE = 'hallPass:typesWithInactive',
  BELL_SCHEDULE_SCHEDULES_LIST = 'bellSchedule:schedules:list',
  BELL_SCHEDULE_TERMS_LIST = 'bellSchedule:terms:list',
  BELL_SCHEDULE_PERIODS_LIST = 'bellSchedule:periods:list',
  BELL_SCHEDULE_CALENDAR_LIST = 'bellSchedule:calendar:list',
  MY_CLASS_MOST_USED_BY_LIST = 'myClass:mostUsedByList',
  MY_CLASS_LIST_MEMBERS = 'myClass:listMembers',
  MY_CLASS_ACTIONS = 'myClass:actions',
  SIS_SYNC_ERROR = 'sisSync:error',
}

export type CacheConfig = {
  /**
   * In minutes
   */
  ttl: number;
};

export type FetchOptions = {
  /**
   * Force re-fetching the data
   */
  revalidate?: boolean;
};

export type CacheItemMethods<T> = {
  get: (data?: any, options?: FetchOptions) => Observable<T>;
  set: (data: T) => Promise<void>;
  clear: () => Promise<void>;
};
