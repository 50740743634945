import { Routes } from '@angular/router';

import { AuthGuard, HasUserGuard } from '@shared/guards';

export const OVERLAY_ROUTES: Routes = [
  {
    outlet: 'o',
    path: 'aboutus',
    loadChildren: () =>
      import(
        'minga/app/src/app/overlays/AboutUs/AboutUsOverlayRouting.module'
      ).then(m => m.AboutUsOverlayRoutingModule),
  },
  {
    outlet: 'o',
    path: 'suicidepreventionhotline',
    loadChildren: () =>
      import(
        'minga/app/src/app/overlays/suicide-prevention-hotline-overlay/suicide-prevention-hotline-overlay-routing.module'
      ).then(m => m.SuicidePreventionHotlineOverlayRoutingModule),
  },
  {
    outlet: 'o',
    path: 'content',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/content/Content.module').then(
        m => m.ContentModule,
      ),
  },
  {
    outlet: 'o',
    path: 'page/:path',
    loadChildren: () =>
      import('minga/app/src/app/page/PageRouting.module').then(
        m => m.PageRoutingModule,
      ),
  },
  {
    outlet: 'o',
    canActivate: [AuthGuard],
    path: 'view/:contextHash',
    loadChildren: () =>
      import('minga/app/src/app/content-views/ContentViewsRouting.module').then(
        m => m.ContentViewsRoutingModule,
      ),
  },
  {
    outlet: 'o',
    path: 'test',
    canLoad: [],
    loadChildren: () =>
      import('minga/app/src/app/routes/test/TestOverlayRoutes.module').then(
        m => m.TestOverlayRoutesModule,
      ),
  },

  {
    outlet: 'o',
    path: 'preview~content/:contentHash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/content-views/ContentViewsRouting.module').then(
        m => m.ContentViewsRoutingModule,
      ),
  },

  {
    outlet: 'o',
    path: 'preview~content/:contentHash/:programContentHash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/content-views/ContentViewsRouting.module').then(
        m => m.ContentViewsRoutingModule,
      ),
  },
  {
    outlet: 'o',
    path: 'view/event~people/going/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/EventGoingPeople/EventGoingPeopleRouting.module'
      ).then(m => m.EventGoingPeopleRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/event~people/event-managers/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/EventManagerPeople/EventManagerPeopleRouting.module'
      ).then(m => m.EventManagerPeopleRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/event~people/event-tickets/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/EventTickets/EventTicketsRouting.module'
      ).then(m => m.EventTicketsRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/event~people/add-ticket/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/events/addTickets/AddTickets.module').then(
        m => m.AddTicketsModule,
      ),
  },
  {
    outlet: 'o',
    path: 'view/event~people/event-ticket-holder-upload/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/EventTicketHolderUpload/EventTicketHolderUploadRouting.module'
      ).then(m => m.EventTicketHolderUploadRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/post~people/likes/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/LikesPeople/LikesPeopleRouting.module'
      ).then(m => m.LikesPeopleRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/program~mingas/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/ProgramMingas/ProgramMingasRouting.module'
      ).then(m => m.ProgramMingasRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/birthday~people/today/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/BirthdayPeople/BirthdayPeopleRouting.module'
      ).then(m => m.BirthdayPeopleRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/content/images',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/ImageList/ImageListRouting.module'
      ).then(m => m.ImageListRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/event~people/event-invited-list/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/EventInvitePeople/EventInvitePeopleRouting.module'
      ).then(m => m.EventInvitePeopleRoutingModule),
  },
  {
    outlet: 'o',
    path: 'view/event~people/invite-people/:hash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/events/InviteEvent/InviteEvent.module').then(
        m => m.InviteEventModule,
      ),
  },
  {
    outlet: 'o',
    path: 'groups',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/groups/groups-overlays.module').then(
        m => m.GroupsOverlaysModule,
      ),
  },
  {
    outlet: 'o',
    path: 'people',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/routes/admin/PeopleAdminOverlayRoutes.module'
      ).then(m => m.PeopleAdminOverlayRoutesModule),
  },
  {
    outlet: 'o',
    path: 'program',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/routes/admin/ProgramAdminOverlayRoutes.module'
      ).then(m => m.ProgramAdminOverlayRoutesModule),
  },
  {
    outlet: 'o',
    path: 'poll-style',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/routes/sadmin/PollStyleAdminOverlayRoutes.module'
      ).then(m => m.PollStyleAdminOverlayRoutesModule),
  },
  {
    outlet: 'o',
    path: 'report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Form/ReportForm/ReportFormOverlayRoutes.module'
      ).then(m => m.ReportFormOverlayRoutesModule),
  },

  {
    outlet: 'o',
    path: 'dialog',
    loadChildren: () =>
      import('minga/app/src/app/dialog/DialogViewOverlayRoute.module').then(
        m => m.DialogViewOverlayRouteModule,
      ),
  },
  {
    outlet: 'o',
    path: 'minga-files',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/MingaFiles/MingaFilesRouting.module'
      ).then(m => m.MingaFilesRoutingModule),
  },
  {
    outlet: 'o',
    path: 'minga-subscriptions',
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/MingaSubscriptionsOverlay/MingaSubscriptionsRouting.module'
      ).then(m => m.MingaSubscriptionsRoutingModule),
  },
  {
    outlet: 'o',
    path: 'minga-subscriptions/:planId/:mingaSize',
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/MingaSubscriptionsOverlay/MingaSubscriptionsRouting.module'
      ).then(m => m.MingaSubscriptionsRoutingModule),
  },
  {
    outlet: 'o',
    path: 'minga-subscriptions/:mingaSize',
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/MingaSubscriptionsOverlay/MingaSubscriptionsRouting.module'
      ).then(m => m.MingaSubscriptionsRoutingModule),
  },
  {
    outlet: 'o',
    path: 'files~upload',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/MingaFileUploadOverlay/MingaFileUploadOverlayRouting.module'
      ).then(m => m.MingaFileUploadOverlayRoutingModule),
  },
  {
    outlet: 'o',
    path: 'minga-profile-link',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/components/Overlay/MingaLinkForm/MingaLinkFormRouting.module'
      ).then(m => m.MingaLinkFormRoutingModule),
  },
  {
    outlet: 'o',
    path: 'banner',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/routes/sadmin/BannerOverlayRoutes.module').then(
        m => m.BannerOverlayRoutesModule,
      ),
  },
  {
    outlet: 'o',
    path: 'gallery',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/routes/gallery/AddGalleryPhotoRoute/AddGalleryPhotoRoute.module'
      ).then(m => m.AddGalleryPhotoRouteModule),
  },
  {
    outlet: 'o',
    path: 'gallery/:photoHash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('minga/app/src/app/routes/gallery/GalleryRoute.module').then(
        m => m.GalleryRouteModule,
      ),
  },
  {
    outlet: 'o',
    path: 'messaging',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/direct-message').then(m => m.DirectMessageModule),
  },
  {
    outlet: 'o',
    path: 'student-id',
    canActivate: [HasUserGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/overlays/student-id-overlay/student-id-overlay-routes.module'
      ).then(m => m.StudentIdOverlayRoutesModule),
  },
  {
    outlet: 'o',
    path: 'teacher-tools',
    canActivate: [HasUserGuard],
    loadChildren: () =>
      import('@modules/teacher-tools/teacher-tools.routes.module').then(
        m => m.TeacherToolsRoutesModule,
      ),
  },
  {
    outlet: 'o',
    path: 'pbis-notification',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/overlays/pbis-notification-overlay/pbis.notification-overlay.routes.module'
      ).then(m => m.PbisNotificationOverlayRoutesModule),
  },
  {
    outlet: 'o',
    path: 'challenge~answer/:challengeId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/modules/challenges/components/ChallengeAnswerOverlay/ChallengeAnswerOverlayRouting.module'
      ).then(m => m.ChallengeAnswerOverlayRoutingModule),
  },
  {
    outlet: 'o',
    path: 'challenge~answer/preview/:challengeId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/modules/challenges/components/ChallengeAnswerPreviewOverlay/ChallengeAnswerPreviewOverlayRouting.module'
      ).then(m => m.ChallengeAnswerPreviewOverlayRoutingModule),
  },
  {
    outlet: 'o',
    path: 'people-membership/view-members/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'src/app/membership-list/ViewMembershipListMembers/ViewMembershipListMembers.module'
      ).then(m => m.ViewMembershipListMembersModule),
  },

  {
    outlet: 'o',
    path: 'points-manager',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/overlays/points/PointsManagerOverlay/PointsManagerOverlayRoutes.module'
      ).then(m => m.PointsManagerOverlayRoutesModule),
  },
  {
    outlet: 'o',
    path: 'points-manager/search',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'minga/app/src/app/overlays/points/PointsManagerOverlay/PointsManagerOverlayRoutes.module'
      ).then(m => m.PointsManagerOverlayRoutesModule),
  },
];
