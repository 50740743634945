import { Action, createReducer, on } from '@ngrx/store';

import { IMessage } from 'minga/libraries/domain';
import { getLastMessageBodyPreview } from 'minga/libraries/shared-grpc';

import { MessagingActions } from '../actions';
import { MessagingState } from '../state';

/**
 * @NOTE clang-format makes an absolute mess in here so take care on your
 * formatting in this block please :)
 */
// clang-format off
const _singleConversationReducer = createReducer(
  MessagingState.initialState.conversations,
  on(MessagingActions.fetchConversation, (state, { conversationId }) => {
    return MessagingState.conversationEntities.updateOne(
      {
        id: conversationId,
        changes: { fetching: true },
      },
      state,
    );
  }),
  on(MessagingActions.fetchConversationSuccess, (state, { conversation }) => {
    return MessagingState.conversationEntities.updateOne(
      {
        id: conversation.id,
        changes: {
          fetching: false,
          ...conversation,
        },
      },
      state,
    );
  }),
  on(MessagingActions.fetchConversationError, (state, { conversationId }) => {
    return MessagingState.conversationEntities.updateOne(
      {
        id: conversationId,
        changes: { fetching: false },
      },
      state,
    );
  }),
  on(MessagingActions.markConversationAsRead, (state, action) => {
    const { conversationId, personHash } = action;
    const conversation = state.entities[conversationId]!;
    if (!conversation.messages) return state;

    const messageEntities = conversation.messages.entities;
    const readAt = new Date();
    for (const key in messageEntities) {
      messageEntities[key]!.statusMap.set(key, { readAt });
    }

    return MessagingState.conversationEntities.updateOne(
      {
        id: conversationId,
        changes: {
          readStatus: true,
          messages: {
            ...conversation.messages,
            entities: messageEntities,
          },
        },
      },
      state,
    );
  }),
);

const _messageReducer = createReducer(
  MessagingState.messagesInitialState,
  on(MessagingActions.fetchMessages, state => {
    return {
      ...state,
      fetching: true,
    };
  }),
  on(MessagingActions.fetchMessagesError, state => {
    return {
      ...state,
      fetching: false,
    };
  }),
  on(MessagingActions.fetchMessagesSuccess, state => {
    return {
      ...state,
      fetching: false,
    };
  }),
  on(MessagingActions.sendMessage, (state, action) => {
    const { authorPersonHash, tempMessageId, bodyText } = action;
    return MessagingState.messageEntities.addOne(
      {
        authorPersonHash,
        body: bodyText,
        id: tempMessageId,
        createdDate: new Date(),
        sendState: 'pending',
        statusMap: new Map(),
        attachmentList: [],
      },
      state,
    );
  }),
  on(
    MessagingActions.sendMessageSuccess,
    (state, { tempMessageId, message }) => {
      const existing =
        MessagingState.messageSelectors.selectEntities(state)[tempMessageId];
      state = MessagingState.messageEntities.removeOne(tempMessageId, state);
      return MessagingState.messageEntities.addOne(
        {
          ...message,
          // Preferring the existing created date to prevent sorting causing the
          // message to jump around
          createdDate: existing?.createdDate || message.createdDate,
        },
        state,
      );
    },
  ),
  on(MessagingActions.sendMessageError, (state, { tempMessageId }) => {
    return MessagingState.messageEntities.updateOne(
      {
        id: tempMessageId,
        changes: { sendState: 'failed_to_send' },
      },
      state,
    );
  }),
  on(MessagingActions.setMessages, (state, { messages }) => {
    return MessagingState.messageEntities.setAll(messages, state);
  }),
  on(MessagingActions.receiveNewMessage, (state, { newMessage }) => {
    return MessagingState.messageEntities.upsertOne(newMessage, state);
  }),
);

const _messagingReducer = createReducer(
  MessagingState.initialState,

  ///////////////////////////////////////////////////////////////
  /// Handling actions that work on single conversation objects
  on(
    MessagingActions.fetchConversation,
    MessagingActions.fetchConversationError,
    MessagingActions.fetchConversationSuccess,
    MessagingActions.markConversationAsRead,
    (state, action) => ({
      ...state,
      conversations: {
        ..._singleConversationReducer(
          MessagingState.ensureConversation(action.conversationId, state),
          action,
        ),
      },
    }),
  ),

  ///////////////////////////////////////////////////////////////
  /// Handling actions that work on conversation messages
  on(
    MessagingActions.setMessages,
    MessagingActions.sendMessage,
    MessagingActions.sendMessageError,
    MessagingActions.sendMessageSuccess,
    MessagingActions.fetchMessages,
    MessagingActions.fetchMessagesError,
    MessagingActions.fetchMessagesSuccess,
    MessagingActions.receiveNewMessage,
    (state, action) => {
      const conversations = MessagingState.ensureConversation(
        action.conversationId,
        state,
      );
      let conversation = conversations.entities[action.conversationId]!;
      const messages = conversation?.messages;

      let message: IMessage | null = null;
      if (MessagingActions.receiveNewMessage.type == action.type) {
        message = action.newMessage;
      } else if (MessagingActions.sendMessageSuccess.type == action.type) {
        message = action.message;
      }

      if (!!message) {
        conversation = {
          ...conversation,
          readStatus: false,
          lastMessageBody: getLastMessageBodyPreview(message),
          lastMessageDate: message.createdDate,
        };
      }

      return {
        ...state,
        conversations: {
          ...conversations,
          entities: {
            ...conversations.entities,
            [action.conversationId]: {
              ...conversation,
              messages: _messageReducer(messages, action),
            },
          },
        },
      };
    },
  ),

  ///////////////////////////////////////////////////////////////
  /// Handling actions that work on entire conversations state
  on(MessagingActions.fetchConversations, state => {
    return {
      ...state,
      conversations: {
        ...state.conversations,
        fetching: true,
      },
    };
  }),
  on(MessagingActions.fetchConversationsSuccess, (state, { conversations }) => {
    let conversationsState = MessagingState.conversationEntities.removeAll(
      state.conversations,
    );
    for (const conv of conversations) {
      conversationsState = MessagingState.conversationEntities.updateOne(
        {
          id: conv.id,
          changes: { ...conv },
        },
        MessagingState.ensureConversation(conv.id, state),
      );
    }
    return {
      ...state,
      conversations: {
        ...conversationsState,
        fetching: false,
      },
    };
  }),
  on(MessagingActions.fetchConversationsError, (state, { error }) => {
    return {
      ...state,
      conversations: {
        ...state.conversations,
        fetching: false,
      },
    };
  }),
);
// clang-format on

export function messagingReducer(
  state: MessagingState | undefined,
  action: Action,
) {
  return _messagingReducer(state, action);
}
