import { HallPassBlackOutWeekdaysEnum } from 'minga/libraries/domain';
import {
  IHallPassBlackOut,
  IHallPassBlackOutSchedule,
} from 'minga/libraries/domain';
import { IMembershipList, MembershipListType } from 'minga/libraries/domain';

import { HpmRestrictionsListType } from '../types';

export enum HpmRestrictionsMessages {
  PAGE_TITLE = 'Hall Pass Restrictions',

  BUTTON_CREATE_NEW = 'Create',
  BUTTON_CREATE_NO_PARTY = 'No party group',
  BUTTON_CREATE_BLACKOUT = 'Blackout schedule',
}

export enum HpmRestrictionsPasslistMessages {
  PASS_LIST = 'Pass List',

  COLUMN_LABEL_LIST_NAME = 'Pass List',
  COLUMN_LABEL_MEMBERS = 'Members',
  COLUMN_LABEL_EDIT = 'Edit',

  SNACK_UPDATE_SUCCESS = 'Updated successfully',
  SNACK_DELETE_SUCCESS = 'Deleted successfully',
  SNACK_UPDATE_FAIL = 'Error updating pass list',
}

export enum HpmRestrictionsNoPartyMessages {
  SECTION_TITLE = 'No Party Groups',

  COLUMN_LABEL_LIST_NAME = 'Party Group Name',
  COLUMN_LABEL_MEMBERS = 'Members',
  COLUMN_LABEL_STATUS = 'Status',
  COLUMN_LABEL_EDIT = 'Edit',

  TABLE_VIEW_MORE = 'View more',
  TABLE_VIEW_LESS = 'View less',

  SNACK_UPDATE_SUCCESS = 'No party list updated successfully',
  SNACK_DELETE_SUCCESS = 'No party list deleted successfully',
  SNACK_CREATE_SUCCESS = 'Successfully created new list',
  SNACK_CREATE_FAIL = 'Error creating new list',
  SNACK_UPDATE_FAIL = 'Error updating no party list',
}

export enum HpmRestrictionsBlackoutMessages {
  SECTION_TITLE = 'Campus Blackout Schedules',

  COLUMN_LABEL_LIST_NAME = 'Schedule Name',
  COLUMN_LABEL_DAYS_ACTIVE = 'Days Active',
  COLUMN_LABEL_STATUS = 'Active',
  COLUMN_LABEL_EDIT = 'Edit',

  BUTTON_LABEL_ADD_NEW = 'Add new',

  TABLE_VIEW_MORE = 'View More',
  TABLE_VIEW_LESS = 'View Less',

  SNACK_UPDATE_SUCCESS = 'No Party List Updated Successfully',
  SNACK_DELETE_SUCCESS = 'No Party List Deleted Successfully',
  SNACK_CREATE_SUCCESS = 'Successfully Created New List',
  SNACK_CREATE_FAIL = 'Error Creating New List',
  SNACK_UPDATE_FAIL = 'Error Updating No Party List',

  TOOLTIP_SCHEDULE = "Allows you to specify times when students can't be given hall passes.",
}

export enum HpmRestrictionsBlackoutEditMessages {
  COLUMN_LABEL_START_TIME = 'Start Time',
  COLUMN_LABEL_END_TIME = 'End Time',
  COLUMN_LABEL_DELETE = 'Delete',

  MODAL_TITLE = 'Create Blackout Schedule',
  MODAL_TITLE_EDIT = 'Edit Blackout Schedule',

  SECTION_TITLE_TIME_SCHEDULE = 'Time Schedule',
  SECTION_TITLE_ACTIVE_DAYS = 'Days Active',

  BUTTON_LABEL_ADD_TIME = 'Add time',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_CLOSE = 'Close',
  BUTTON_LABEL_DELETE = 'Delete',

  ERROR_OPENING_MODAL = 'Something went wrong while trying to open the modal.',

  NEW_SCHEDULE_NAME_PLACEHOLDER = 'Schedule name',

  NO_TIMESLOTS_TITLE = 'No time slots',
  NO_TIMESLOTS_SUBTITLE = 'Start by adding a new time slot for this black out schedule.',
}

export enum HpmRestrictionsEditMessages {
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_CLOSE = 'Close',
  BUTTON_LABEL_DELETE = 'Delete',

  ERROR_OPENING_MODAL = 'Something went wrong while trying to open the modal.',

  MODAL_TITLE = 'Create No Party Group',
  NAME_INPUT_LABEL = 'Name',
}

export enum HpmRestrictionsBlackoutEditForm {
  NAME = 'NAME',
  DAYS_ACTIVE = 'DAYS_ACTIVE',
  TIME_BLOCK = 'TIME_BLOCK',
}

export const HP_RESTRICTIONS_PASSLIST_TABLE_COLUMNS: string[] = [
  'name',
  'members',
  'edit',
];

export const HP_RESTRICTIONS_NOPARTY_TABLE_COLUMNS: string[] = [
  'name',
  'members',
  'status',
  'edit',
];

export const HP_RESTRICTIONS_BLACKOUT_TABLE_COLUMNS: string[] = [
  'name',
  'daysActive',
  'edit',
  'status',
];

export const HP_RESTRICTIONS_BLACKOUT_TIME_TABLE_COLUMNS: string[] = [
  'startTime',
  'endTime',
  'delete',
];

export const HP_RESTRICTIONS_PASSLISTS: HpmRestrictionsListType[] = [
  MembershipListType.NO_GRANT,
  MembershipListType.NO_PASS,
];

export const HP_SELF_GRANT_LISTS: HpmRestrictionsListType[] = [
  MembershipListType.ONE_PASS,
  MembershipListType.TWO_PASS,
];

export const RESTRICT_LIST_TYPE_PRETTY_NAME: Partial<
  Record<HpmRestrictionsListType, string>
> = {
  [MembershipListType.NO_GRANT]: 'No Grant',
  [MembershipListType.NO_PARTY]: 'No Party Group',
  [MembershipListType.NO_PASS]: 'No Pass',
  [MembershipListType.ONE_PASS]: 'One Pass',
  [MembershipListType.TWO_PASS]: 'Two Pass',
};

export const HP_RESTRICTIONS_NOPARTYLISTS: HpmRestrictionsListType[] = [
  MembershipListType.NO_PARTY,
];

export const HPM_RESTRICTION_INITIAL_LIST_STATE = {
  id: undefined,
  name: 'Restricted to',
  listType: MembershipListType.PASS_TYPE,
  memberCount: 0,
};

export const HPM_RESTRICTION_INITIAL_NOPARTY_LIST_STATE: IMembershipList = {
  name: '',
  listType: MembershipListType.NO_PARTY,
  memberCount: 0,
};

export const HPM_RESTRICTIONS_INITIAL_SCHEDULE_ACTIVE_DAYS_STATE = {
  blockMonday: false,
  blockTuesday: false,
  blockWednesday: false,
  blockThursday: false,
  blockFriday: false,
  blockSaturday: false,
  blockSunday: false,
};

export const HPM_RESTRICTIONS_INITIAL_TIME_BLOCK_STATE: IHallPassBlackOutSchedule =
  {
    startTime: '12:00:00',
    endTime: '13:00:00',
  };

export const HPM_RESTRICTIONS_INITIAL_SCHEDULE_STATE: IHallPassBlackOut = {
  ...HPM_RESTRICTIONS_INITIAL_SCHEDULE_ACTIVE_DAYS_STATE,
  name: '',
  weekday: HallPassBlackOutWeekdaysEnum.EVERYDAY,
  active: false,
  startTime: '12:00:00',
  endTime: '13:00:00',
  schedules: [HPM_RESTRICTIONS_INITIAL_TIME_BLOCK_STATE],
};
