"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_USER_LIST = void 0;
exports.DEFAULT_USER_LIST = {
    uuid: null,
    termSourceIds: [],
    periodSourceIds: [],
    public: false,
    active: true,
    description: '',
    title: 'New User List',
    managerListId: undefined,
};