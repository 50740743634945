"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRoleTypeFromLegacyId = exports.getLegacyRoleId = exports.getAllLegacyPermissions = exports.convertLegacyPermission = exports.MingaRoleType = exports.MingaPermission = void 0;
var MingaPermission;
(function (MingaPermission) {
    MingaPermission[MingaPermission["SUPERADMIN"] = 1] = "SUPERADMIN";
    MingaPermission[MingaPermission["CONTENT_DELETE_OTHERS"] = 2] = "CONTENT_DELETE_OTHERS";
    MingaPermission[MingaPermission["ACCOUNT_DISPLAYNAME_CHANGE"] = 3] = "ACCOUNT_DISPLAYNAME_CHANGE";
    MingaPermission[MingaPermission["ACCOUNT_PROFILE_CHANGE"] = 4] = "ACCOUNT_PROFILE_CHANGE";
    MingaPermission[MingaPermission["APP_BROADCAST_SEND"] = 5] = "APP_BROADCAST_SEND";
    MingaPermission[MingaPermission["ASSET_UPLOAD"] = 6] = "ASSET_UPLOAD";
    MingaPermission[MingaPermission["CONTENT_ANNOUNCEMENT_CREATE"] = 7] = "CONTENT_ANNOUNCEMENT_CREATE";
    MingaPermission[MingaPermission["CONTENT_ANY_GET_DELETED"] = 8] = "CONTENT_ANY_GET_DELETED";
    MingaPermission[MingaPermission["CONTENT_COMMENT_CREATE"] = 9] = "CONTENT_COMMENT_CREATE";
    MingaPermission[MingaPermission["CONTENT_COMMENTS_VIEW"] = 10] = "CONTENT_COMMENTS_VIEW";
    MingaPermission[MingaPermission["CONTENT_EVENT_CREATE"] = 11] = "CONTENT_EVENT_CREATE";
    MingaPermission[MingaPermission["CONTENT_FULL_GRAPHIC_CREATE"] = 12] = "CONTENT_FULL_GRAPHIC_CREATE";
    MingaPermission[MingaPermission["CONTENT_GROUP_CREATE"] = 13] = "CONTENT_GROUP_CREATE";
    MingaPermission[MingaPermission["CONTENT_GROUP_VIEWALL"] = 14] = "CONTENT_GROUP_VIEWALL";
    MingaPermission[MingaPermission["CONTENT_GROUP_ENABLED"] = 15] = "CONTENT_GROUP_ENABLED";
    MingaPermission[MingaPermission["CONTENT_HTML_INCLUDE"] = 16] = "CONTENT_HTML_INCLUDE";
    MingaPermission[MingaPermission["CONTENT_LIKES_LIKE"] = 17] = "CONTENT_LIKES_LIKE";
    MingaPermission[MingaPermission["CONTENT_LIKES_VIEW"] = 18] = "CONTENT_LIKES_VIEW";
    MingaPermission[MingaPermission["CONTENT_MODERATION_OVERRIDE"] = 19] = "CONTENT_MODERATION_OVERRIDE";
    MingaPermission[MingaPermission["CONTENT_POLL_CREATE"] = 20] = "CONTENT_POLL_CREATE";
    MingaPermission[MingaPermission["CONTENT_POLL_ANSWER"] = 21] = "CONTENT_POLL_ANSWER";
    MingaPermission[MingaPermission["CONTENT_POST_CREATE"] = 22] = "CONTENT_POST_CREATE";
    MingaPermission[MingaPermission["CONTENT_POST_SET_TITLE"] = 23] = "CONTENT_POST_SET_TITLE";
    MingaPermission[MingaPermission["CONTENT_PUBLISH_DATES_SET"] = 24] = "CONTENT_PUBLISH_DATES_SET";
    MingaPermission[MingaPermission["CONTENT_REPORT_CREATE"] = 25] = "CONTENT_REPORT_CREATE";
    MingaPermission[MingaPermission["CONTENT_REPORTS_MANAGE"] = 26] = "CONTENT_REPORTS_MANAGE";
    MingaPermission[MingaPermission["CONTENT_VIDEO_CREATE"] = 27] = "CONTENT_VIDEO_CREATE";
    MingaPermission[MingaPermission["MINGA_ACCOUNT_MANAGE"] = 28] = "MINGA_ACCOUNT_MANAGE";
    MingaPermission[MingaPermission["GROUP_BROADCAST_SEND"] = 29] = "GROUP_BROADCAST_SEND";
    MingaPermission[MingaPermission["GROUP_CONTENT_ANNOUNCEMENT_CREATE"] = 30] = "GROUP_CONTENT_ANNOUNCEMENT_CREATE";
    MingaPermission[MingaPermission["GROUP_CONTENT_FULL_GRAPHIC_CREATE"] = 31] = "GROUP_CONTENT_FULL_GRAPHIC_CREATE";
    MingaPermission[MingaPermission["GROUP_CONTENT_EVENTS_CREATE"] = 32] = "GROUP_CONTENT_EVENTS_CREATE";
    MingaPermission[MingaPermission["GROUP_CONTENT_POLL_CREATE"] = 33] = "GROUP_CONTENT_POLL_CREATE";
    MingaPermission[MingaPermission["GROUP_CONTENT_POST_CREATE"] = 34] = "GROUP_CONTENT_POST_CREATE";
    MingaPermission[MingaPermission["GROUP_CONTENT_VIDEO_CREATE"] = 35] = "GROUP_CONTENT_VIDEO_CREATE";
    MingaPermission[MingaPermission["MINGA_BLOCKORDER_UPLOAD"] = 36] = "MINGA_BLOCKORDER_UPLOAD";
    MingaPermission[MingaPermission["MINGA_CONTENT_PIN"] = 37] = "MINGA_CONTENT_PIN";
    MingaPermission[MingaPermission["MINGA_CONTENT_MAIN_FEED"] = 38] = "MINGA_CONTENT_MAIN_FEED";
    MingaPermission[MingaPermission["MINGA_CONTENT_UPDATE_OTHERS"] = 39] = "MINGA_CONTENT_UPDATE_OTHERS";
    MingaPermission[MingaPermission["MINGA_FILE_CREATE"] = 40] = "MINGA_FILE_CREATE";
    MingaPermission[MingaPermission["MINGA_PROFILE_EDIT"] = 41] = "MINGA_PROFILE_EDIT";
    MingaPermission[MingaPermission["MINGA_PEOPLE_MANAGE"] = 42] = "MINGA_PEOPLE_MANAGE";
    MingaPermission[MingaPermission["MINGA_POLLS_MANAGE"] = 43] = "MINGA_POLLS_MANAGE";
    MingaPermission[MingaPermission["MINGA_PROGRAMS_MANAGE"] = 44] = "MINGA_PROGRAMS_MANAGE";
    MingaPermission[MingaPermission["PEOPLE_DELETE"] = 45] = "PEOPLE_DELETE";
    MingaPermission[MingaPermission["CONTENT_GROUP_MANAGE"] = 46] = "CONTENT_GROUP_MANAGE";
    MingaPermission[MingaPermission["GALLERY_ADD_PHOTO"] = 47] = "GALLERY_ADD_PHOTO";
    MingaPermission[MingaPermission["GALLERY_DELETE_OTHERS_PHOTO"] = 48] = "GALLERY_DELETE_OTHERS_PHOTO";
    MingaPermission[MingaPermission["VIDEO_UPLOAD"] = 49] = "VIDEO_UPLOAD";
    MingaPermission[MingaPermission["CONTENT_CREATED_MINGA_DESIGNER_CONTENT"] = 50] = "CONTENT_CREATED_MINGA_DESIGNER_CONTENT";
    /**
     * Can add/remove a parent group to a group only from the list of parent
     * groups you're associated with.
     */
    MingaPermission[MingaPermission["GROUP_ALLOW_PARENT_GROUP"] = 51] = "GROUP_ALLOW_PARENT_GROUP";
    /**
     * Can add/remove a parent group to a group
     */
    MingaPermission[MingaPermission["GROUP_ALLOW_ANY_PARENT_GROUP"] = 52] = "GROUP_ALLOW_ANY_PARENT_GROUP";
    /**
     * Denote the user as a parent which may have different functionality
     */
    MingaPermission[MingaPermission["IS_PARENT"] = 53] = "IS_PARENT";
    /**
     * Allowed to email content to other users
     */
    MingaPermission[MingaPermission["EMAIL_CONTENT"] = 54] = "EMAIL_CONTENT";
    /**
     * Allowed to email content to the group you are an owner of
     */
    MingaPermission[MingaPermission["EMAIL_CONTENT_AS_GROUP_OWNER"] = 55] = "EMAIL_CONTENT_AS_GROUP_OWNER";
    /**
     * Allowed to change who can comment on a post.
     */
    MingaPermission[MingaPermission["CONTENT_MANAGE_COMMENT_SETTINGS"] = 56] = "CONTENT_MANAGE_COMMENT_SETTINGS";
    /**
     * Get sent notifications if content is reported.
     */
    MingaPermission[MingaPermission["MODERATION_GET_REPORT_NOTIFICATIONS"] = 57] = "MODERATION_GET_REPORT_NOTIFICATIONS";
    /**
     * Can always manage events
     */
    MingaPermission[MingaPermission["EVENTS_MANAGE"] = 58] = "EVENTS_MANAGE";
    MingaPermission[MingaPermission["CONTENT_CHALLENGE_CREATE"] = 59] = "CONTENT_CHALLENGE_CREATE";
    MingaPermission[MingaPermission["GROUP_CONTENT_CHALLENGE_CREATE"] = 60] = "GROUP_CONTENT_CHALLENGE_CREATE";
    MingaPermission[MingaPermission["CONTENT_CHALLENGE_MANAGE"] = 61] = "CONTENT_CHALLENGE_MANAGE";
    MingaPermission[MingaPermission["CHALLENGE_COMPLETE"] = 62] = "CHALLENGE_COMPLETE";
    /**
     * Can arbitrarily add/remove points from people and teams
     */
    MingaPermission[MingaPermission["POINTS_MANAGE"] = 63] = "POINTS_MANAGE";
    /**
     * Can create SMS message content
     */
    MingaPermission[MingaPermission["CONTENT_SMS_MESSAGE_CREATE"] = 64] = "CONTENT_SMS_MESSAGE_CREATE";
    MingaPermission[MingaPermission["GROUP_CONTENT_SMS_MESSAGE_CREATE"] = 65] = "GROUP_CONTENT_SMS_MESSAGE_CREATE";
    /**
     * Can allocate points if the setting is enabled.
     */
    MingaPermission[MingaPermission["POINTS_ALLOCATE_ALLOWED"] = 66] = "POINTS_ALLOCATE_ALLOWED";
    /**
     * If have this permission, can access all student id manager, except mass
     * uploading images and changing student id settings.
     */
    MingaPermission[MingaPermission["ADMINISTER_STUDENT_ID"] = 67] = "ADMINISTER_STUDENT_ID";
    /**
     * If have this permission, user can change student id settings.
     */
    MingaPermission[MingaPermission["ADMINISTER_STUDENT_ID_SETTINGS"] = 68] = "ADMINISTER_STUDENT_ID_SETTINGS";
    /**
     * Can create, edit, remove hall pass types
     */
    MingaPermission[MingaPermission["HALL_PASS_TYPE_MANAGE"] = 69] = "HALL_PASS_TYPE_MANAGE";
    /**
     * Can view and end hall passes
     */
    MingaPermission[MingaPermission["HALL_PASS_MANAGE"] = 70] = "HALL_PASS_MANAGE";
    /**
     * Can only create a hall pass
     */
    MingaPermission[MingaPermission["HALL_PASS_CREATE"] = 71] = "HALL_PASS_CREATE";
    /**
     * Can use teacher tools
     */
    MingaPermission[MingaPermission["TEACHER_TOOLS"] = 72] = "TEACHER_TOOLS";
    /**
     * Can view the student ID Dashboard.
     */
    MingaPermission[MingaPermission["VIEW_STUDENT_ID_DASHBOARD"] = 73] = "VIEW_STUDENT_ID_DASHBOARD";
    /**
     * Can view and manage PBIS behavior types
     */
    MingaPermission[MingaPermission["PBIS_TYPE_MANAGE"] = 74] = "PBIS_TYPE_MANAGE";
    /**
     * Can award PBIS behaviors
     */
    MingaPermission[MingaPermission["PBIS_CREATE"] = 75] = "PBIS_CREATE";
    /**
     * Can view and manage PBIS behavior types
     */
    MingaPermission[MingaPermission["PBIS_VIEW_REPORTS"] = 76] = "PBIS_VIEW_REPORTS";
    /**
     * Can manually issue consequences
     */
    MingaPermission[MingaPermission["CONSEQUENCE_CREATE"] = 77] = "CONSEQUENCE_CREATE";
    /**
     * Can manage checkin types
     */
    MingaPermission[MingaPermission["CHECKIN_TYPE_MANAGE"] = 78] = "CHECKIN_TYPE_MANAGE";
    /**
     * Can checkin and checkout
     */
    MingaPermission[MingaPermission["CHECKIN_MANAGE"] = 79] = "CHECKIN_MANAGE";
    /**
     * Can view check in reports
     */
    MingaPermission[MingaPermission["CHECKIN_VIEW_REPORTS"] = 80] = "CHECKIN_VIEW_REPORTS";
    /**
     * Can view check in reports
     */
    MingaPermission[MingaPermission["SELF_CHECKIN"] = 81] = "SELF_CHECKIN";
    /**
     * Can view a limited set of hall pass reports
     */
    MingaPermission[MingaPermission["HALL_PASS_VIEW_LIMITED_REPORTS"] = 82] = "HALL_PASS_VIEW_LIMITED_REPORTS";
    /**
     * Can view full set of hall pass reports?
     */
    MingaPermission[MingaPermission["HALL_PASS_VIEW_REPORTS"] = 83] = "HALL_PASS_VIEW_REPORTS";
    /**
     * Can view the Minga Manager Dashboard.
     */
    MingaPermission[MingaPermission["VIEW_MINGA_DASHBOARD"] = 84] = "VIEW_MINGA_DASHBOARD";
    /**
     * Can view points reports
     */
    MingaPermission[MingaPermission["POINTS_VIEW_REPORTS"] = 85] = "POINTS_VIEW_REPORTS";
    /**
     * Can manage rewards and redeem points
     */
    MingaPermission[MingaPermission["MANAGE_REWARDS"] = 86] = "MANAGE_REWARDS";
    /**
     * Can use student tools
     */
    MingaPermission[MingaPermission["STUDENT_TOOLS"] = 87] = "STUDENT_TOOLS";
    /** Flex time related permissions */
    MingaPermission[MingaPermission["FLEX_TIME_MANAGE"] = 88] = "FLEX_TIME_MANAGE";
    MingaPermission[MingaPermission["FLEX_TIME_VIEW_REPORTS"] = 89] = "FLEX_TIME_VIEW_REPORTS";
    MingaPermission[MingaPermission["FLEXTIME_MANAGE_OWN_ACTIVITY"] = 90] = "FLEXTIME_MANAGE_OWN_ACTIVITY";
    MingaPermission[MingaPermission["FLEX_TIME_REGISTER_FOR_ACTIVITY"] = 91] = "FLEX_TIME_REGISTER_FOR_ACTIVITY";
    /**
     * User List create and manager permissions
     */
    MingaPermission[MingaPermission["USER_LIST_VIEW"] = 92] = "USER_LIST_VIEW";
    MingaPermission[MingaPermission["USER_LIST_MANAGE"] = 93] = "USER_LIST_MANAGE";
    MingaPermission[MingaPermission["USER_LIST_CREATE"] = 94] = "USER_LIST_CREATE";
    MingaPermission[MingaPermission["USER_LIST_ADMIN"] = 95] = "USER_LIST_ADMIN";
    /**
     * Scheduled reports permissions
     */
    MingaPermission[MingaPermission["SCHEDULED_REPORTS_MANAGE"] = 96] = "SCHEDULED_REPORTS_MANAGE";
    MingaPermission[MingaPermission["SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT"] = 97] = "SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT";
    /**
     * Can always use direct messaging. Only applies uni-directionally
     */
    MingaPermission[MingaPermission["DM_CAN_MESSAGE"] = 98] = "DM_CAN_MESSAGE";
    /**
     * Can manage various managers and settings
     */
    MingaPermission[MingaPermission["MINGA_MANAGE"] = 99] = "MINGA_MANAGE";
    MingaPermission[MingaPermission["FLEX_TIME_VIEW_MY_ACTIVITY"] = 100] = "FLEX_TIME_VIEW_MY_ACTIVITY";
    /**
     * Can manage date range presets
     */
    MingaPermission[MingaPermission["DATE_RANGE_PRESETS_MANAGE"] = 101] = "DATE_RANGE_PRESETS_MANAGE";
    MingaPermission[MingaPermission["KIOSK_MANAGE"] = 102] = "KIOSK_MANAGE";
    MingaPermission[MingaPermission["DATE_RANGE_PRESETS_VIEW"] = 103] = "DATE_RANGE_PRESETS_VIEW";
    MingaPermission[MingaPermission["ID_CHANGE_OWN_PHOTO"] = 104] = "ID_CHANGE_OWN_PHOTO";
    MingaPermission[MingaPermission["ADMIN_TOOLS"] = 105] = "ADMIN_TOOLS";
    MingaPermission[MingaPermission["STUDENT_SCHEDULE_VIEW"] = 106] = "STUDENT_SCHEDULE_VIEW";
    MingaPermission[MingaPermission["BELL_SCHEDULE_MANAGE"] = 107] = "BELL_SCHEDULE_MANAGE";
    /**
     * View a users profile
     */
    MingaPermission[MingaPermission["VIEW_PROFILE"] = 108] = "VIEW_PROFILE";
})(MingaPermission = exports.MingaPermission || (exports.MingaPermission = {}));
var MingaRoleType;
(function (MingaRoleType) {
    MingaRoleType["SUPERADMIN"] = "SUPERADMIN";
    MingaRoleType["OWNER"] = "OWNER";
    MingaRoleType["TEACHER"] = "TEACHER";
    MingaRoleType["STAFF"] = "STAFF";
    MingaRoleType["STUDENT_LEADER"] = "STUDENT_LEADER";
    MingaRoleType["STUDENT"] = "STUDENT";
    MingaRoleType["READ_ONLY"] = "READ_ONLY";
    MingaRoleType["MANAGER"] = "MANAGER";
    MingaRoleType["ATTENDEE"] = "ATTENDEE";
    MingaRoleType["PARENT"] = "PARENT";
    MingaRoleType["SCHOOL_MANAGER"] = "SCHOOL_MANAGER";
    MingaRoleType["DISTRICT_LEADER"] = "DISTRICT_LEADER";
    MingaRoleType["DISTRICT_MANAGER"] = "DISTRICT_MANAGER";
    MingaRoleType["KIOSK"] = "KIOSK";
})(MingaRoleType = exports.MingaRoleType || (exports.MingaRoleType = {}));
const legacyMingaRoleIdMap = {
    [MingaRoleType.SUPERADMIN]: 1,
    [MingaRoleType.OWNER]: 3,
    [MingaRoleType.TEACHER]: 4,
    [MingaRoleType.STUDENT_LEADER]: 5,
    [MingaRoleType.STUDENT]: 6,
    [MingaRoleType.PARENT]: 7,
    [MingaRoleType.READ_ONLY]: 8,
    // New roles need ids still to support older clients
    [MingaRoleType.ATTENDEE]: 9,
    [MingaRoleType.MANAGER]: 11,
    [MingaRoleType.STAFF]: 12,
    [MingaRoleType.SCHOOL_MANAGER]: 13,
    [MingaRoleType.DISTRICT_LEADER]: 14,
    [MingaRoleType.DISTRICT_MANAGER]: 15,
    [MingaRoleType.KIOSK]: 16,
};
// Permissions that everyone has
const defaultLegacyPermissions = [
    'content.comments.deleteOwn',
    'content.announcement.delete',
    'content.event.delete',
    'content.file.delete',
    'content.fullGraphic.delete',
    'content.group.delete',
    'content.poll.delete',
    'content.post.deleteOwn',
    'content.video.deleteOwn',
    'minga.content.removeOwn',
    'app.notifications.list',
    'asset.get.any',
    'content.banner.list',
    'content.banner.search',
    'content.post.get',
    'feed.content.list',
];
const legacyPermissionMap = {
    [MingaPermission.CONTENT_DELETE_OTHERS]: [
        'content.comments.delete',
        'content.announcement.deleteOthers',
        'content.event.deleteOthers',
        'content.file.deleteOthers',
        'content.fullGraphic.deleteOthers',
        'content.group.deleteOthers',
        'content.poll.deleteOthers',
        'content.post.deleteOthers',
        'content.video.deleteOthers',
        'minga.file.delete',
        'minga.content.removeAny',
    ],
    [MingaPermission.ACCOUNT_DISPLAYNAME_CHANGE]: ['account.displayName.change'],
    [MingaPermission.ACCOUNT_PROFILE_CHANGE]: ['account.profile.change'],
    [MingaPermission.SUPERADMIN]: [
        'admin.access.addRole',
        'admin.access.deleteRole',
        'admin.access.updateRole',
        'admin.access.list',
        'admin.content.removeAny',
        'app.broadcast.global',
        'app.dev.testing',
        'asset.process.any',
        'content.banner.add',
        'content.banner.archive',
        'content.banner.delete',
        'content.banner.edit',
        'content.banner.searchArchive',
        'content.pollstyle.archive',
        'content.pollstyle.add',
        'content.pollstyle.archive',
        'content.pollstyle.edit',
        'content.pollstyle.delete',
        'content.pollstyle.listAdmin',
        'content.programs.create',
        'content.programs.delete',
        'content.programs.list',
        'content.programs.update',
        'minga.pause.negate',
        'mingaadmin.enable_all_features',
        'mingaadmin.mingas.browse',
        'mingaadmin.mingas.list',
        'mingaadmin.mingas.delete',
        'mingaadmin.mingas.create',
        'mingaadmin.mingas.update',
        'mingaadmin.programs.list',
        'mingaadmin.programs.remove',
        'mingadmin.reports.list',
        'content.group.updateOthers',
    ],
    [MingaPermission.APP_BROADCAST_SEND]: ['app.broadcast.send'],
    [MingaPermission.ASSET_UPLOAD]: ['asset.set.any', 'asset.upload.any'],
    [MingaPermission.CONTENT_ANNOUNCEMENT_CREATE]: [
        'content.announcement.create',
        'content.announcement.update',
    ],
    [MingaPermission.CONTENT_COMMENT_CREATE]: ['content.comments.create'],
    [MingaPermission.CONTENT_COMMENTS_VIEW]: ['content.comments.view'],
    [MingaPermission.CONTENT_EVENT_CREATE]: [
        'content.event.create',
        'content.event.update',
        'content.events.update',
        'content.events.create',
    ],
    [MingaPermission.CONTENT_FULL_GRAPHIC_CREATE]: [
        'content.fullGraphic.update',
        'content.inspiration.create',
        'content.inspiration.style',
    ],
    [MingaPermission.CONTENT_GROUP_CREATE]: ['content.group.create'],
    [MingaPermission.CONTENT_GROUP_ENABLED]: ['content.group.enabled'],
    [MingaPermission.CONTENT_GROUP_VIEWALL]: ['content.group.viewall'],
    [MingaPermission.CONTENT_HTML_INCLUDE]: ['content.html.include'],
    [MingaPermission.CONTENT_LIKES_LIKE]: [
        'content.likes.set',
        'content.likes.viewOwn',
    ],
    [MingaPermission.CONTENT_LIKES_VIEW]: ['content.likes.viewOthers'],
    [MingaPermission.CONTENT_MODERATION_OVERRIDE]: [
        'content.moderation.get',
        'content.moderation.override',
    ],
    [MingaPermission.CONTENT_POLL_CREATE]: [
        'content.poll.create',
        'content.poll.update',
    ],
    [MingaPermission.CONTENT_POST_CREATE]: [
        'content.post.create',
        'content.post.update',
    ],
    [MingaPermission.CONTENT_POST_SET_TITLE]: ['content.post.setTitle'],
    [MingaPermission.CONTENT_PUBLISH_DATES_SET]: ['content.publishDates.set'],
    [MingaPermission.CONTENT_REPORT_CREATE]: ['content.report.create'],
    [MingaPermission.CONTENT_REPORTS_MANAGE]: [
        'content.report.list',
        'content.report.resolve',
        'content.report.stats',
    ],
    [MingaPermission.CONTENT_VIDEO_CREATE]: [
        'content.video.create',
        'content.video.update',
    ],
    [MingaPermission.MINGA_ACCOUNT_MANAGE]: [
        'minga.account.read',
        'minga.account.updateOwn',
        'minga.pause.contact',
    ],
    [MingaPermission.GROUP_BROADCAST_SEND]: ['group.broadcast.send'],
    [MingaPermission.GROUP_CONTENT_ANNOUNCEMENT_CREATE]: [
        'group.content.announcement.create',
    ],
    [MingaPermission.GROUP_CONTENT_FULL_GRAPHIC_CREATE]: [
        'group.content.inspiration.create',
    ],
    [MingaPermission.GROUP_CONTENT_EVENTS_CREATE]: [
        'group.content.events.create',
    ],
    [MingaPermission.GROUP_CONTENT_POLL_CREATE]: ['group.content.poll.create'],
    [MingaPermission.GROUP_CONTENT_POST_CREATE]: ['group.content.post.create'],
    [MingaPermission.GROUP_CONTENT_VIDEO_CREATE]: ['group.content.video.create'],
    [MingaPermission.MINGA_BLOCKORDER_UPLOAD]: ['minga.blockorder.upload'],
    [MingaPermission.MINGA_CONTENT_PIN]: [
        'minga.content.editPin',
        'minga.content.unpin',
    ],
    [MingaPermission.MINGA_CONTENT_MAIN_FEED]: ['minga.content.mainFeed'],
    [MingaPermission.MINGA_CONTENT_UPDATE_OTHERS]: ['minga.content.updateOthers'],
    [MingaPermission.MINGA_FILE_CREATE]: ['minga.file.add'],
    [MingaPermission.MINGA_PROFILE_EDIT]: ['minga.mingas.updateOwn'],
    [MingaPermission.MINGA_PEOPLE_MANAGE]: [
        'minga.people.create',
        'minga.people.list',
        'peopleadmin.people.archive',
        'peopleadmin.people.create',
        'peopleadmin.people.createBulk',
        'peopleadmin.people.list',
        'peopleadmin.people.update',
        'minga.people.createBulk',
    ],
    [MingaPermission.MINGA_POLLS_MANAGE]: [
        'minga.poll.list',
        'minga.poll.listUnpublished',
        'minga.poll.listVotes',
    ],
    [MingaPermission.MINGA_PROGRAMS_MANAGE]: [
        'minga.programs.add',
        'minga.programs.remove',
        'minga.programs.list',
    ],
    [MingaPermission.PEOPLE_DELETE]: ['peopleadmin.people.delete'],
};
function convertLegacyPermission(legacyPermission) {
    for (const perm in legacyPermissionMap) {
        const legacyPerms = legacyPermissionMap[perm];
        if (legacyPerms.includes(legacyPermission)) {
            return parseInt(perm);
        }
    }
    throw new Error(`legacyPermission ${legacyPermission} is invalid`);
}
exports.convertLegacyPermission = convertLegacyPermission;
function getLegacyPermissions(permission) {
    return legacyPermissionMap[permission] || [];
}
function getAllLegacyPermissions(permissions) {
    const legacyPermissions = [].concat(defaultLegacyPermissions);
    return legacyPermissions.concat(...permissions.map(getLegacyPermissions));
}
exports.getAllLegacyPermissions = getAllLegacyPermissions;
function getLegacyRoleId(roleType) {
    const legacyId = legacyMingaRoleIdMap[roleType];
    return legacyId || 0;
}
exports.getLegacyRoleId = getLegacyRoleId;
function getRoleTypeFromLegacyId(legacyRoleId) {
    for (const key in legacyMingaRoleIdMap) {
        const roleType = key;
        if (legacyRoleId === getLegacyRoleId(roleType)) {
            return roleType;
        }
    }
    throw new Error(`Invalid legacy id ${legacyRoleId}`);
}
exports.getRoleTypeFromLegacyId = getRoleTypeFromLegacyId;