import { Pipe, PipeTransform } from '@angular/core';

/**
 * Mimics the array find method used with an array of objects.
 */
@Pipe({ name: 'find' })
export class ArrayFindPipe implements PipeTransform {
  transform(array: any[], field: string, value: any) {
    return (array || []).find(item => item[field] === value);
  }
}
