"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPersonByStudentIdResponse = exports.GetPersonByStudentIdRequest = exports.GetPeopleFromFileResponse = exports.GetPeopleFromFileRequest = exports.PersonSearchFields = exports.ExportPeopleForSelectorChunk = exports.GetPeopleForSelectorResponse = exports.GetPeopleForSelectorRequest = exports.PeopleCollectionSummaryResponse = exports.GetPeopleByRolesResponse = exports.GetPeopleByRolesRequest = exports.PeopleCollectionSummaryRequest = exports.GetPersonResponse = exports.GetPersonRequest = exports.SearchPeopleCollectionResponse = exports.SearchPeopleCollectionRequest = exports.GetPeopleCollectionResponse = exports.GetPeopleCollectionRequest = exports.PeopleCollectionPersonAdminDetails = exports.PeopleCollectionPersonAuthDetails = exports.PeopleCollectionPersonDetails = void 0;
const jspb = require("google-protobuf");
const gateway_person_view_pb = require("minga/proto/gateway/person_view_pb");
const common_order_pb = require("minga/proto/common/order_pb");
class PeopleCollectionPersonDetails extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PeopleCollectionPersonDetails.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PeopleCollectionPersonDetails} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            badgeIconColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            badgeRoleName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            archived: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            firstName: jspb.Message.getFieldWithDefault(msg, 8, ""),
            lastName: jspb.Message.getFieldWithDefault(msg, 9, ""),
            roleType: jspb.Message.getFieldWithDefault(msg, 10, ""),
            dmEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
            email: jspb.Message.getFieldWithDefault(msg, 12, ""),
            studentId: jspb.Message.getFieldWithDefault(msg, 13, ""),
            grade: jspb.Message.getFieldWithDefault(msg, 14, ""),
            filename: jspb.Message.getFieldWithDefault(msg, 15, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PeopleCollectionPersonDetails}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PeopleCollectionPersonDetails;
        return PeopleCollectionPersonDetails.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PeopleCollectionPersonDetails} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PeopleCollectionPersonDetails}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setProfileImageUrl(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setDisplayName(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconUrl(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconColor(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setBadgeRoleName(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setArchived(value7);
                    break;
                case 8:
                    var value8 = /** @type {string} */ (reader.readString());
                    msg.setFirstName(value8);
                    break;
                case 9:
                    var value9 = /** @type {string} */ (reader.readString());
                    msg.setLastName(value9);
                    break;
                case 10:
                    var value10 = /** @type {string} */ (reader.readString());
                    msg.setRoleType(value10);
                    break;
                case 11:
                    var value11 = /** @type {boolean} */ (reader.readBool());
                    msg.setDmEnabled(value11);
                    break;
                case 12:
                    var value12 = /** @type {string} */ (reader.readString());
                    msg.setEmail(value12);
                    break;
                case 13:
                    var value13 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value13);
                    break;
                case 14:
                    var value14 = /** @type {string} */ (reader.readString());
                    msg.setGrade(value14);
                    break;
                case 15:
                    var value15 = /** @type {string} */ (reader.readString());
                    msg.setFilename(value15);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PeopleCollectionPersonDetails} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getProfileImageUrl();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDisplayName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getBadgeIconUrl();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getBadgeIconColor();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getBadgeRoleName();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getArchived();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getFirstName();
        if (f.length > 0) {
            writer.writeString(8, f);
        }
        f = message.getLastName();
        if (f.length > 0) {
            writer.writeString(9, f);
        }
        f = message.getRoleType();
        if (f.length > 0) {
            writer.writeString(10, f);
        }
        f = message.getDmEnabled();
        if (f) {
            writer.writeBool(11, f);
        }
        f = message.getEmail();
        if (f.length > 0) {
            writer.writeString(12, f);
        }
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(13, f);
        }
        f = message.getGrade();
        if (f.length > 0) {
            writer.writeString(14, f);
        }
        f = message.getFilename();
        if (f.length > 0) {
            writer.writeString(15, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PeopleCollectionPersonDetails.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string profile_image_url = 2;
     * @return {string}
     */
    getProfileImageUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setProfileImageUrl(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string display_name = 3;
     * @return {string}
     */
    getDisplayName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setDisplayName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string badge_icon_url = 4;
     * @return {string}
     */
    getBadgeIconUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconUrl(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional string badge_icon_color = 5;
     * @return {string}
     */
    getBadgeIconColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconColor(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional string badge_role_name = 6;
     * @return {string}
     */
    getBadgeRoleName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setBadgeRoleName(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional bool archived = 7;
     * @return {boolean}
     */
    getArchived() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setArchived(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional string first_name = 8;
     * @return {string}
     */
    getFirstName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
    }
    ;
    /** @param {string} value */
    setFirstName(value) {
        jspb.Message.setProto3StringField(this, 8, value);
    }
    /**
     * optional string last_name = 9;
     * @return {string}
     */
    getLastName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
    }
    ;
    /** @param {string} value */
    setLastName(value) {
        jspb.Message.setProto3StringField(this, 9, value);
    }
    /**
     * optional string role_type = 10;
     * @return {string}
     */
    getRoleType() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
    }
    ;
    /** @param {string} value */
    setRoleType(value) {
        jspb.Message.setProto3StringField(this, 10, value);
    }
    /**
     * optional bool dm_enabled = 11;
     * @return {boolean}
     */
    getDmEnabled() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
    }
    ;
    /** @param {boolean} value */
    setDmEnabled(value) {
        jspb.Message.setProto3BooleanField(this, 11, value);
    }
    /**
     * optional string email = 12;
     * @return {string}
     */
    getEmail() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
    }
    ;
    /** @param {string} value */
    setEmail(value) {
        jspb.Message.setProto3StringField(this, 12, value);
    }
    /**
     * optional string student_id = 13;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 13, value);
    }
    /**
     * optional string grade = 14;
     * @return {string}
     */
    getGrade() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
    }
    ;
    /** @param {string} value */
    setGrade(value) {
        jspb.Message.setProto3StringField(this, 14, value);
    }
    /**
     * optional string filename = 15;
     * @return {string}
     */
    getFilename() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
    }
    ;
    /** @param {string} value */
    setFilename(value) {
        jspb.Message.setProto3StringField(this, 15, value);
    }
}
exports.PeopleCollectionPersonDetails = PeopleCollectionPersonDetails;
PeopleCollectionPersonDetails.displayName = "PeopleCollectionPersonDetails";
class PeopleCollectionPersonAuthDetails extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PeopleCollectionPersonAuthDetails.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PeopleCollectionPersonAuthDetails} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            receivingDirectMessages: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            directMessagesPersonallyDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            mingaSettingDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PeopleCollectionPersonAuthDetails}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PeopleCollectionPersonAuthDetails;
        return PeopleCollectionPersonAuthDetails.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PeopleCollectionPersonAuthDetails} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PeopleCollectionPersonAuthDetails}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {boolean} */ (reader.readBool());
                    msg.setReceivingDirectMessages(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setDirectMessagesPersonallyDisabled(value2);
                    break;
                case 3:
                    var value3 = /** @type {boolean} */ (reader.readBool());
                    msg.setMingaSettingDisabled(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PeopleCollectionPersonAuthDetails} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getReceivingDirectMessages();
        if (f) {
            writer.writeBool(1, f);
        }
        f = message.getDirectMessagesPersonallyDisabled();
        if (f) {
            writer.writeBool(2, f);
        }
        f = message.getMingaSettingDisabled();
        if (f) {
            writer.writeBool(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PeopleCollectionPersonAuthDetails.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bool receiving_direct_messages = 1;
     * @return {boolean}
     */
    getReceivingDirectMessages() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
    }
    ;
    /** @param {boolean} value */
    setReceivingDirectMessages(value) {
        jspb.Message.setProto3BooleanField(this, 1, value);
    }
    /**
     * optional bool direct_messages_personally_disabled = 2;
     * @return {boolean}
     */
    getDirectMessagesPersonallyDisabled() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setDirectMessagesPersonallyDisabled(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
    /**
     * optional bool minga_setting_disabled = 3;
     * @return {boolean}
     */
    getMingaSettingDisabled() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
    }
    ;
    /** @param {boolean} value */
    setMingaSettingDisabled(value) {
        jspb.Message.setProto3BooleanField(this, 3, value);
    }
}
exports.PeopleCollectionPersonAuthDetails = PeopleCollectionPersonAuthDetails;
PeopleCollectionPersonAuthDetails.displayName = "PeopleCollectionPersonAuthDetails";
class PeopleCollectionPersonAdminDetails extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PeopleCollectionPersonAdminDetails.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PeopleCollectionPersonAdminDetails} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            studentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            grade: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PeopleCollectionPersonAdminDetails}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PeopleCollectionPersonAdminDetails;
        return PeopleCollectionPersonAdminDetails.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PeopleCollectionPersonAdminDetails} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PeopleCollectionPersonAdminDetails}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setGrade(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PeopleCollectionPersonAdminDetails} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getGrade();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PeopleCollectionPersonAdminDetails.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string student_id = 1;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string grade = 2;
     * @return {string}
     */
    getGrade() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setGrade(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
}
exports.PeopleCollectionPersonAdminDetails = PeopleCollectionPersonAdminDetails;
PeopleCollectionPersonAdminDetails.displayName = "PeopleCollectionPersonAdminDetails";
class GetPeopleCollectionRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleCollectionRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleCollectionRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            token: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleCollectionRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleCollectionRequest;
        return GetPeopleCollectionRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleCollectionRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleCollectionRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setToken(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleCollectionRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getToken();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleCollectionRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string token = 1;
     * @return {string}
     */
    getToken() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setToken(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.GetPeopleCollectionRequest = GetPeopleCollectionRequest;
GetPeopleCollectionRequest.displayName = "GetPeopleCollectionRequest";
class GetPeopleCollectionResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleCollectionResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleCollectionResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            token: jspb.Message.getFieldWithDefault(msg, 1, ""),
            person: (f = msg.getPerson()) && PeopleCollectionPersonDetails.toObject(includeInstance, f),
            removePerson: jspb.Message.getFieldWithDefault(msg, 3, ""),
            personAuthDetails: (f = msg.getPersonAuthDetails()) && PeopleCollectionPersonAuthDetails.toObject(includeInstance, f),
            personAdminDetails: (f = msg.getPersonAdminDetails()) && PeopleCollectionPersonAdminDetails.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleCollectionResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleCollectionResponse;
        return GetPeopleCollectionResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleCollectionResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleCollectionResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setToken(value1);
                    break;
                case 2:
                    var value2 = new PeopleCollectionPersonDetails;
                    reader.readMessage(value2, PeopleCollectionPersonDetails.deserializeBinaryFromReader);
                    msg.setPerson(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setRemovePerson(value3);
                    break;
                case 4:
                    var value4 = new PeopleCollectionPersonAuthDetails;
                    reader.readMessage(value4, PeopleCollectionPersonAuthDetails.deserializeBinaryFromReader);
                    msg.setPersonAuthDetails(value4);
                    break;
                case 5:
                    var value5 = new PeopleCollectionPersonAdminDetails;
                    reader.readMessage(value5, PeopleCollectionPersonAdminDetails.deserializeBinaryFromReader);
                    msg.setPersonAdminDetails(value5);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleCollectionResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getToken();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getPerson();
        if (f != null) {
            writer.writeMessage(2, f, PeopleCollectionPersonDetails.serializeBinaryToWriter);
        }
        f = message.getRemovePerson();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getPersonAuthDetails();
        if (f != null) {
            writer.writeMessage(4, f, PeopleCollectionPersonAuthDetails.serializeBinaryToWriter);
        }
        f = message.getPersonAdminDetails();
        if (f != null) {
            writer.writeMessage(5, f, PeopleCollectionPersonAdminDetails.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleCollectionResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string token = 1;
     * @return {string}
     */
    getToken() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setToken(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional PeopleCollectionPersonDetails person = 2;
     * @return {?PeopleCollectionPersonDetails}
     */
    getPerson() {
        return /** @type{?PeopleCollectionPersonDetails} */ (jspb.Message.getWrapperField(this, PeopleCollectionPersonDetails, 2));
    }
    /** @param {?PeopleCollectionPersonDetails|undefined} value */
    setPerson(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPerson() {
        this.setPerson(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPerson() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional string remove_person = 3;
     * @return {string}
     */
    getRemovePerson() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setRemovePerson(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional PeopleCollectionPersonAuthDetails person_auth_details = 4;
     * @return {?PeopleCollectionPersonAuthDetails}
     */
    getPersonAuthDetails() {
        return /** @type{?PeopleCollectionPersonAuthDetails} */ (jspb.Message.getWrapperField(this, PeopleCollectionPersonAuthDetails, 4));
    }
    /** @param {?PeopleCollectionPersonAuthDetails|undefined} value */
    setPersonAuthDetails(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPersonAuthDetails() {
        this.setPersonAuthDetails(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPersonAuthDetails() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional PeopleCollectionPersonAdminDetails person_admin_details = 5;
     * @return {?PeopleCollectionPersonAdminDetails}
     */
    getPersonAdminDetails() {
        return /** @type{?PeopleCollectionPersonAdminDetails} */ (jspb.Message.getWrapperField(this, PeopleCollectionPersonAdminDetails, 5));
    }
    /** @param {?PeopleCollectionPersonAdminDetails|undefined} value */
    setPersonAdminDetails(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPersonAdminDetails() {
        this.setPersonAdminDetails(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPersonAdminDetails() {
        return jspb.Message.getField(this, 5) != null;
    }
}
exports.GetPeopleCollectionResponse = GetPeopleCollectionResponse;
GetPeopleCollectionResponse.displayName = "GetPeopleCollectionResponse";
class SearchPeopleCollectionRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return SearchPeopleCollectionRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!SearchPeopleCollectionRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            filter: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!SearchPeopleCollectionRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new SearchPeopleCollectionRequest;
        return SearchPeopleCollectionRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!SearchPeopleCollectionRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!SearchPeopleCollectionRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setFilter(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!SearchPeopleCollectionRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getFilter();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        SearchPeopleCollectionRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string filter = 1;
     * @return {string}
     */
    getFilter() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setFilter(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.SearchPeopleCollectionRequest = SearchPeopleCollectionRequest;
SearchPeopleCollectionRequest.displayName = "SearchPeopleCollectionRequest";
class SearchPeopleCollectionResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return SearchPeopleCollectionResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!SearchPeopleCollectionResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            person: (f = msg.getPerson()) && PeopleCollectionPersonDetails.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!SearchPeopleCollectionResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new SearchPeopleCollectionResponse;
        return SearchPeopleCollectionResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!SearchPeopleCollectionResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!SearchPeopleCollectionResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new PeopleCollectionPersonDetails;
                    reader.readMessage(value1, PeopleCollectionPersonDetails.deserializeBinaryFromReader);
                    msg.setPerson(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!SearchPeopleCollectionResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPerson();
        if (f != null) {
            writer.writeMessage(1, f, PeopleCollectionPersonDetails.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        SearchPeopleCollectionResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional PeopleCollectionPersonDetails person = 1;
     * @return {?PeopleCollectionPersonDetails}
     */
    getPerson() {
        return /** @type{?PeopleCollectionPersonDetails} */ (jspb.Message.getWrapperField(this, PeopleCollectionPersonDetails, 1));
    }
    /** @param {?PeopleCollectionPersonDetails|undefined} value */
    setPerson(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPerson() {
        this.setPerson(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPerson() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.SearchPeopleCollectionResponse = SearchPeopleCollectionResponse;
SearchPeopleCollectionResponse.displayName = "SearchPeopleCollectionResponse";
class GetPersonRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPersonRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonRequest;
        return GetPersonRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHashList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string hash = 1;
     * @return {!Array<string>}
     */
    getHashList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setHashList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addHash(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHashList() {
        this.setHashList([]);
    }
}
exports.GetPersonRequest = GetPersonRequest;
GetPersonRequest.displayName = "GetPersonRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPersonRequest.repeatedFields_ = [1];
class GetPersonResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPersonResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personList: jspb.Message.toObjectList(msg.getPersonList(), PeopleCollectionPersonDetails.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonResponse;
        return GetPersonResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new PeopleCollectionPersonDetails;
                    reader.readMessage(value1, PeopleCollectionPersonDetails.deserializeBinaryFromReader);
                    msg.addPerson(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, PeopleCollectionPersonDetails.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated PeopleCollectionPersonDetails person = 1;
     * @return {!Array<!PeopleCollectionPersonDetails>}
     */
    getPersonList() {
        return /** @type{!Array<!PeopleCollectionPersonDetails>} */ (jspb.Message.getRepeatedWrapperField(this, PeopleCollectionPersonDetails, 1));
    }
    /** @param {!Array<!PeopleCollectionPersonDetails>} value */
    setPersonList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!PeopleCollectionPersonDetails=} opt_value
     * @param {number=} opt_index
     * @return {!PeopleCollectionPersonDetails}
     */
    addPerson(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PeopleCollectionPersonDetails, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPersonList() {
        this.setPersonList([]);
    }
}
exports.GetPersonResponse = GetPersonResponse;
GetPersonResponse.displayName = "GetPersonResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPersonResponse.repeatedFields_ = [1];
class PeopleCollectionSummaryRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PeopleCollectionSummaryRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PeopleCollectionSummaryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PeopleCollectionSummaryRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PeopleCollectionSummaryRequest;
        return PeopleCollectionSummaryRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PeopleCollectionSummaryRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PeopleCollectionSummaryRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PeopleCollectionSummaryRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PeopleCollectionSummaryRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.PeopleCollectionSummaryRequest = PeopleCollectionSummaryRequest;
PeopleCollectionSummaryRequest.displayName = "PeopleCollectionSummaryRequest";
class GetPeopleByRolesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPeopleByRolesRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleByRolesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleByRolesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            roleList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            canGrantHallPass: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleByRolesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleByRolesRequest;
        return GetPeopleByRolesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleByRolesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleByRolesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addRole(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setCanGrantHallPass(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleByRolesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getRoleList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
        f = message.getCanGrantHallPass();
        if (f) {
            writer.writeBool(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleByRolesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string role = 1;
     * @return {!Array<string>}
     */
    getRoleList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setRoleList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addRole(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearRoleList() {
        this.setRoleList([]);
    }
    /**
     * optional bool can_grant_hall_pass = 2;
     * @return {boolean}
     */
    getCanGrantHallPass() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setCanGrantHallPass(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
}
exports.GetPeopleByRolesRequest = GetPeopleByRolesRequest;
GetPeopleByRolesRequest.displayName = "GetPeopleByRolesRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPeopleByRolesRequest.repeatedFields_ = [1];
class GetPeopleByRolesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPeopleByRolesResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleByRolesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleByRolesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personList: jspb.Message.toObjectList(msg.getPersonList(), PeopleCollectionPersonDetails.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleByRolesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleByRolesResponse;
        return GetPeopleByRolesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleByRolesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleByRolesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new PeopleCollectionPersonDetails;
                    reader.readMessage(value1, PeopleCollectionPersonDetails.deserializeBinaryFromReader);
                    msg.addPerson(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleByRolesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, PeopleCollectionPersonDetails.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleByRolesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated PeopleCollectionPersonDetails person = 1;
     * @return {!Array<!PeopleCollectionPersonDetails>}
     */
    getPersonList() {
        return /** @type{!Array<!PeopleCollectionPersonDetails>} */ (jspb.Message.getRepeatedWrapperField(this, PeopleCollectionPersonDetails, 1));
    }
    /** @param {!Array<!PeopleCollectionPersonDetails>} value */
    setPersonList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!PeopleCollectionPersonDetails=} opt_value
     * @param {number=} opt_index
     * @return {!PeopleCollectionPersonDetails}
     */
    addPerson(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PeopleCollectionPersonDetails, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPersonList() {
        this.setPersonList([]);
    }
}
exports.GetPeopleByRolesResponse = GetPeopleByRolesResponse;
GetPeopleByRolesResponse.displayName = "GetPeopleByRolesResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPeopleByRolesResponse.repeatedFields_ = [1];
class PeopleCollectionSummaryResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PeopleCollectionSummaryResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PeopleCollectionSummaryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
            adminCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PeopleCollectionSummaryResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PeopleCollectionSummaryResponse;
        return PeopleCollectionSummaryResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PeopleCollectionSummaryResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PeopleCollectionSummaryResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setTotalCount(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readInt32());
                    msg.setAdminCount(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PeopleCollectionSummaryResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getTotalCount();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getAdminCount();
        if (f !== 0) {
            writer.writeInt32(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PeopleCollectionSummaryResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 total_count = 1;
     * @return {number}
     */
    getTotalCount() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setTotalCount(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional int32 admin_count = 2;
     * @return {number}
     */
    getAdminCount() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setAdminCount(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
}
exports.PeopleCollectionSummaryResponse = PeopleCollectionSummaryResponse;
PeopleCollectionSummaryResponse.displayName = "PeopleCollectionSummaryResponse";
class GetPeopleForSelectorRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPeopleForSelectorRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleForSelectorRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleForSelectorRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            query: jspb.Message.getFieldWithDefault(msg, 1, ""),
            gradesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
            rolesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
            groupsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
            personHashesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
            userListsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
            roleType: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            bypassFilterCheck: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            limit: jspb.Message.getFieldWithDefault(msg, 9, 0),
            offset: jspb.Message.getFieldWithDefault(msg, 10, 0),
            registeredOnly: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
            unregisteredOnly: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
            orderBy: (f = msg.getOrderBy()) && common_order_pb.OrderBy.toObject(includeInstance, f),
            onlyVisibleRoles: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleForSelectorRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleForSelectorRequest;
        return GetPeopleForSelectorRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleForSelectorRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleForSelectorRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setQuery(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.addGrades(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.addRoles(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.addGroups(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.addPersonHashes(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.addUserLists(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setRoleType(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setBypassFilterCheck(value8);
                    break;
                case 9:
                    var value9 = /** @type {number} */ (reader.readInt32());
                    msg.setLimit(value9);
                    break;
                case 10:
                    var value10 = /** @type {number} */ (reader.readInt32());
                    msg.setOffset(value10);
                    break;
                case 11:
                    var value11 = /** @type {boolean} */ (reader.readBool());
                    msg.setRegisteredOnly(value11);
                    break;
                case 12:
                    var value12 = /** @type {boolean} */ (reader.readBool());
                    msg.setUnregisteredOnly(value12);
                    break;
                case 13:
                    var value13 = new common_order_pb.OrderBy;
                    reader.readMessage(value13, common_order_pb.OrderBy.deserializeBinaryFromReader);
                    msg.setOrderBy(value13);
                    break;
                case 14:
                    var value14 = /** @type {boolean} */ (reader.readBool());
                    msg.setOnlyVisibleRoles(value14);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleForSelectorRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getQuery();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getGradesList();
        if (f.length > 0) {
            writer.writeRepeatedString(2, f);
        }
        f = message.getRolesList();
        if (f.length > 0) {
            writer.writeRepeatedString(3, f);
        }
        f = message.getGroupsList();
        if (f.length > 0) {
            writer.writeRepeatedString(4, f);
        }
        f = message.getPersonHashesList();
        if (f.length > 0) {
            writer.writeRepeatedString(5, f);
        }
        f = message.getUserListsList();
        if (f.length > 0) {
            writer.writeRepeatedString(6, f);
        }
        f = message.getRoleType();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getBypassFilterCheck();
        if (f) {
            writer.writeBool(8, f);
        }
        f = message.getLimit();
        if (f !== 0) {
            writer.writeInt32(9, f);
        }
        f = message.getOffset();
        if (f !== 0) {
            writer.writeInt32(10, f);
        }
        f = message.getRegisteredOnly();
        if (f) {
            writer.writeBool(11, f);
        }
        f = message.getUnregisteredOnly();
        if (f) {
            writer.writeBool(12, f);
        }
        f = message.getOrderBy();
        if (f != null) {
            writer.writeMessage(13, f, common_order_pb.OrderBy.serializeBinaryToWriter);
        }
        f = message.getOnlyVisibleRoles();
        if (f) {
            writer.writeBool(14, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleForSelectorRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string query = 1;
     * @return {string}
     */
    getQuery() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setQuery(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * repeated string grades = 2;
     * @return {!Array<string>}
     */
    getGradesList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
    }
    ;
    /** @param {!Array<string>} value */
    setGradesList(value) {
        jspb.Message.setField(this, 2, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGrades(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 2, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGradesList() {
        this.setGradesList([]);
    }
    /**
     * repeated string roles = 3;
     * @return {!Array<string>}
     */
    getRolesList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
    }
    ;
    /** @param {!Array<string>} value */
    setRolesList(value) {
        jspb.Message.setField(this, 3, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addRoles(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 3, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearRolesList() {
        this.setRolesList([]);
    }
    /**
     * repeated string groups = 4;
     * @return {!Array<string>}
     */
    getGroupsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
    }
    ;
    /** @param {!Array<string>} value */
    setGroupsList(value) {
        jspb.Message.setField(this, 4, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGroups(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 4, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGroupsList() {
        this.setGroupsList([]);
    }
    /**
     * repeated string person_hashes = 5;
     * @return {!Array<string>}
     */
    getPersonHashesList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
    }
    ;
    /** @param {!Array<string>} value */
    setPersonHashesList(value) {
        jspb.Message.setField(this, 5, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addPersonHashes(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 5, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPersonHashesList() {
        this.setPersonHashesList([]);
    }
    /**
     * repeated string user_lists = 6;
     * @return {!Array<string>}
     */
    getUserListsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
    }
    ;
    /** @param {!Array<string>} value */
    setUserListsList(value) {
        jspb.Message.setField(this, 6, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addUserLists(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 6, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearUserListsList() {
        this.setUserListsList([]);
    }
    /**
     * optional bool role_type = 7;
     * @return {boolean}
     */
    getRoleType() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setRoleType(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional bool bypass_filter_check = 8;
     * @return {boolean}
     */
    getBypassFilterCheck() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setBypassFilterCheck(value) {
        jspb.Message.setProto3BooleanField(this, 8, value);
    }
    /**
     * optional int32 limit = 9;
     * @return {number}
     */
    getLimit() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
    }
    ;
    /** @param {number} value */
    setLimit(value) {
        jspb.Message.setProto3IntField(this, 9, value);
    }
    /**
     * optional int32 offset = 10;
     * @return {number}
     */
    getOffset() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
    }
    ;
    /** @param {number} value */
    setOffset(value) {
        jspb.Message.setProto3IntField(this, 10, value);
    }
    /**
     * optional bool registered_only = 11;
     * @return {boolean}
     */
    getRegisteredOnly() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
    }
    ;
    /** @param {boolean} value */
    setRegisteredOnly(value) {
        jspb.Message.setProto3BooleanField(this, 11, value);
    }
    /**
     * optional bool unregistered_only = 12;
     * @return {boolean}
     */
    getUnregisteredOnly() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
    }
    ;
    /** @param {boolean} value */
    setUnregisteredOnly(value) {
        jspb.Message.setProto3BooleanField(this, 12, value);
    }
    /**
     * optional OrderBy order_by = 13;
     * @return {?OrderBy}
     */
    getOrderBy() {
        return /** @type{?OrderBy} */ (jspb.Message.getWrapperField(this, common_order_pb.OrderBy, 13));
    }
    /** @param {?OrderBy|undefined} value */
    setOrderBy(value) {
        jspb.Message.setWrapperField(this, 13, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearOrderBy() {
        this.setOrderBy(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasOrderBy() {
        return jspb.Message.getField(this, 13) != null;
    }
    /**
     * optional bool only_visible_roles = 14;
     * @return {boolean}
     */
    getOnlyVisibleRoles() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
    }
    ;
    /** @param {boolean} value */
    setOnlyVisibleRoles(value) {
        jspb.Message.setProto3BooleanField(this, 14, value);
    }
}
exports.GetPeopleForSelectorRequest = GetPeopleForSelectorRequest;
GetPeopleForSelectorRequest.displayName = "GetPeopleForSelectorRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPeopleForSelectorRequest.repeatedFields_ = [2, 3, 4, 5, 6];
class GetPeopleForSelectorResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPeopleForSelectorResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleForSelectorResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleForSelectorResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            peopleList: jspb.Message.toObjectList(msg.getPeopleList(), gateway_person_view_pb.AdminPersonView.toObject, includeInstance),
            totalSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleForSelectorResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleForSelectorResponse;
        return GetPeopleForSelectorResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleForSelectorResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleForSelectorResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new gateway_person_view_pb.AdminPersonView;
                    reader.readMessage(value1, gateway_person_view_pb.AdminPersonView.deserializeBinaryFromReader);
                    msg.addPeople(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readInt32());
                    msg.setTotalSize(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleForSelectorResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, gateway_person_view_pb.AdminPersonView.serializeBinaryToWriter);
        }
        f = message.getTotalSize();
        if (f !== 0) {
            writer.writeInt32(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleForSelectorResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated AdminPersonView people = 1;
     * @return {!Array<!AdminPersonView>}
     */
    getPeopleList() {
        return /** @type{!Array<!AdminPersonView>} */ (jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.AdminPersonView, 1));
    }
    /** @param {!Array<!AdminPersonView>} value */
    setPeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!AdminPersonView=} opt_value
     * @param {number=} opt_index
     * @return {!AdminPersonView}
     */
    addPeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_person_view_pb.AdminPersonView, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleList() {
        this.setPeopleList([]);
    }
    /**
     * optional int32 total_size = 2;
     * @return {number}
     */
    getTotalSize() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setTotalSize(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
}
exports.GetPeopleForSelectorResponse = GetPeopleForSelectorResponse;
GetPeopleForSelectorResponse.displayName = "GetPeopleForSelectorResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPeopleForSelectorResponse.repeatedFields_ = [1];
class ExportPeopleForSelectorChunk extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, ExportPeopleForSelectorChunk.oneofGroups_);
    }
    /**
     * @return {ExportPeopleForSelectorChunk.InfoCase}
     */
    getInfoCase() {
        return /** @type {ExportPeopleForSelectorChunk.InfoCase} */ (jspb.Message.computeOneofCase(this, ExportPeopleForSelectorChunk.oneofGroups_[0]));
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ExportPeopleForSelectorChunk.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ExportPeopleForSelectorChunk} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            chunk: msg.getChunk_asB64(),
            filename: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ExportPeopleForSelectorChunk}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ExportPeopleForSelectorChunk;
        return ExportPeopleForSelectorChunk.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ExportPeopleForSelectorChunk} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ExportPeopleForSelectorChunk}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
                    msg.setChunk(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setFilename(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ExportPeopleForSelectorChunk} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
        if (f != null) {
            writer.writeBytes(1, f);
        }
        f = /** @type {string} */ (jspb.Message.getField(message, 2));
        if (f != null) {
            writer.writeString(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ExportPeopleForSelectorChunk.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bytes chunk = 1;
     * @return {!(string|Uint8Array)}
     */
    getChunk() {
        return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /**
     * optional bytes chunk = 1;
     * This is a type-conversion wrapper around `getChunk()`
     * @return {string}
     */
    getChunk_asB64() {
        return /** @type {string} */ (jspb.Message.bytesAsB64(this.getChunk()));
    }
    /**
     * optional bytes chunk = 1;
     * Note that Uint8Array is not supported on all browsers.
   * @see http://caniuse.com/Uint8Array
   * This is a type-conversion wrapper around `getChunk()`
     * @return {!Uint8Array}
     */
    getChunk_asU8() {
        return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getChunk()));
    }
    /** @param {!(string|Uint8Array)} value */
    setChunk(value) {
        jspb.Message.setOneofField(this, 1, ExportPeopleForSelectorChunk.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearChunk() {
        jspb.Message.setOneofField(this, 1, ExportPeopleForSelectorChunk.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasChunk() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional string filename = 2;
     * @return {string}
     */
    getFilename() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setFilename(value) {
        jspb.Message.setOneofField(this, 2, ExportPeopleForSelectorChunk.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearFilename() {
        jspb.Message.setOneofField(this, 2, ExportPeopleForSelectorChunk.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasFilename() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.ExportPeopleForSelectorChunk = ExportPeopleForSelectorChunk;
ExportPeopleForSelectorChunk.displayName = "ExportPeopleForSelectorChunk";
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
ExportPeopleForSelectorChunk.oneofGroups_ = [[1, 2]];
(function (ExportPeopleForSelectorChunk) {
    let InfoCase;
    (function (InfoCase) {
        InfoCase[InfoCase["INFO_NOT_SET"] = 0] = "INFO_NOT_SET";
        InfoCase[InfoCase["CHUNK"] = 1] = "CHUNK";
        InfoCase[InfoCase["FILENAME"] = 2] = "FILENAME";
    })(InfoCase = ExportPeopleForSelectorChunk.InfoCase || (ExportPeopleForSelectorChunk.InfoCase = {}));
})(ExportPeopleForSelectorChunk = exports.ExportPeopleForSelectorChunk || (exports.ExportPeopleForSelectorChunk = {})); // namespace ExportPeopleForSelectorChunk
class PersonSearchFields extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PersonSearchFields.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PersonSearchFields} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            email: jspb.Message.getFieldWithDefault(msg, 1, ""),
            firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
            lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            studentId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PersonSearchFields}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PersonSearchFields;
        return PersonSearchFields.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PersonSearchFields} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PersonSearchFields}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setEmail(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setFirstName(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setLastName(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value4);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PersonSearchFields} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getEmail();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getFirstName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getLastName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PersonSearchFields.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string email = 1;
     * @return {string}
     */
    getEmail() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setEmail(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string first_name = 2;
     * @return {string}
     */
    getFirstName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setFirstName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string last_name = 3;
     * @return {string}
     */
    getLastName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setLastName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string student_id = 4;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
}
exports.PersonSearchFields = PersonSearchFields;
PersonSearchFields.displayName = "PersonSearchFields";
class GetPeopleFromFileRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPeopleFromFileRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleFromFileRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleFromFileRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personList: jspb.Message.toObjectList(msg.getPersonList(), PersonSearchFields.toObject, includeInstance),
            gradesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
            rolesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
            groupsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
            userListsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleFromFileRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleFromFileRequest;
        return GetPeopleFromFileRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleFromFileRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleFromFileRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new PersonSearchFields;
                    reader.readMessage(value1, PersonSearchFields.deserializeBinaryFromReader);
                    msg.addPerson(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.addGrades(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.addRoles(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.addGroups(value4);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.addUserLists(value6);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleFromFileRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, PersonSearchFields.serializeBinaryToWriter);
        }
        f = message.getGradesList();
        if (f.length > 0) {
            writer.writeRepeatedString(2, f);
        }
        f = message.getRolesList();
        if (f.length > 0) {
            writer.writeRepeatedString(3, f);
        }
        f = message.getGroupsList();
        if (f.length > 0) {
            writer.writeRepeatedString(4, f);
        }
        f = message.getUserListsList();
        if (f.length > 0) {
            writer.writeRepeatedString(6, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleFromFileRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated PersonSearchFields person = 1;
     * @return {!Array<!PersonSearchFields>}
     */
    getPersonList() {
        return /** @type{!Array<!PersonSearchFields>} */ (jspb.Message.getRepeatedWrapperField(this, PersonSearchFields, 1));
    }
    /** @param {!Array<!PersonSearchFields>} value */
    setPersonList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!PersonSearchFields=} opt_value
     * @param {number=} opt_index
     * @return {!PersonSearchFields}
     */
    addPerson(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PersonSearchFields, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPersonList() {
        this.setPersonList([]);
    }
    /**
     * repeated string grades = 2;
     * @return {!Array<string>}
     */
    getGradesList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
    }
    ;
    /** @param {!Array<string>} value */
    setGradesList(value) {
        jspb.Message.setField(this, 2, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGrades(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 2, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGradesList() {
        this.setGradesList([]);
    }
    /**
     * repeated string roles = 3;
     * @return {!Array<string>}
     */
    getRolesList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
    }
    ;
    /** @param {!Array<string>} value */
    setRolesList(value) {
        jspb.Message.setField(this, 3, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addRoles(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 3, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearRolesList() {
        this.setRolesList([]);
    }
    /**
     * repeated string groups = 4;
     * @return {!Array<string>}
     */
    getGroupsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
    }
    ;
    /** @param {!Array<string>} value */
    setGroupsList(value) {
        jspb.Message.setField(this, 4, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGroups(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 4, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGroupsList() {
        this.setGroupsList([]);
    }
    /**
     * repeated string user_lists = 6;
     * @return {!Array<string>}
     */
    getUserListsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
    }
    ;
    /** @param {!Array<string>} value */
    setUserListsList(value) {
        jspb.Message.setField(this, 6, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addUserLists(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 6, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearUserListsList() {
        this.setUserListsList([]);
    }
}
exports.GetPeopleFromFileRequest = GetPeopleFromFileRequest;
GetPeopleFromFileRequest.displayName = "GetPeopleFromFileRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPeopleFromFileRequest.repeatedFields_ = [1, 2, 3, 4, 6];
class GetPeopleFromFileResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPeopleFromFileResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPeopleFromFileResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPeopleFromFileResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            peopleList: jspb.Message.toObjectList(msg.getPeopleList(), gateway_person_view_pb.AdminPersonView.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPeopleFromFileResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPeopleFromFileResponse;
        return GetPeopleFromFileResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPeopleFromFileResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPeopleFromFileResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new gateway_person_view_pb.AdminPersonView;
                    reader.readMessage(value1, gateway_person_view_pb.AdminPersonView.deserializeBinaryFromReader);
                    msg.addPeople(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPeopleFromFileResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, gateway_person_view_pb.AdminPersonView.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPeopleFromFileResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated AdminPersonView people = 1;
     * @return {!Array<!AdminPersonView>}
     */
    getPeopleList() {
        return /** @type{!Array<!AdminPersonView>} */ (jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.AdminPersonView, 1));
    }
    /** @param {!Array<!AdminPersonView>} value */
    setPeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!AdminPersonView=} opt_value
     * @param {number=} opt_index
     * @return {!AdminPersonView}
     */
    addPeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_person_view_pb.AdminPersonView, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleList() {
        this.setPeopleList([]);
    }
}
exports.GetPeopleFromFileResponse = GetPeopleFromFileResponse;
GetPeopleFromFileResponse.displayName = "GetPeopleFromFileResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPeopleFromFileResponse.repeatedFields_ = [1];
class GetPersonByStudentIdRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonByStudentIdRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonByStudentIdRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            studentId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonByStudentIdRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonByStudentIdRequest;
        return GetPersonByStudentIdRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonByStudentIdRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonByStudentIdRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonByStudentIdRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonByStudentIdRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string student_id = 1;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.GetPersonByStudentIdRequest = GetPersonByStudentIdRequest;
GetPersonByStudentIdRequest.displayName = "GetPersonByStudentIdRequest";
class GetPersonByStudentIdResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonByStudentIdResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonByStudentIdResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            person: (f = msg.getPerson()) && PeopleCollectionPersonDetails.toObject(includeInstance, f),
            personAuthDetails: (f = msg.getPersonAuthDetails()) && PeopleCollectionPersonAuthDetails.toObject(includeInstance, f),
            personAdminDetails: (f = msg.getPersonAdminDetails()) && PeopleCollectionPersonAdminDetails.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonByStudentIdResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonByStudentIdResponse;
        return GetPersonByStudentIdResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonByStudentIdResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonByStudentIdResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new PeopleCollectionPersonDetails;
                    reader.readMessage(value1, PeopleCollectionPersonDetails.deserializeBinaryFromReader);
                    msg.setPerson(value1);
                    break;
                case 2:
                    var value2 = new PeopleCollectionPersonAuthDetails;
                    reader.readMessage(value2, PeopleCollectionPersonAuthDetails.deserializeBinaryFromReader);
                    msg.setPersonAuthDetails(value2);
                    break;
                case 3:
                    var value3 = new PeopleCollectionPersonAdminDetails;
                    reader.readMessage(value3, PeopleCollectionPersonAdminDetails.deserializeBinaryFromReader);
                    msg.setPersonAdminDetails(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonByStudentIdResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPerson();
        if (f != null) {
            writer.writeMessage(1, f, PeopleCollectionPersonDetails.serializeBinaryToWriter);
        }
        f = message.getPersonAuthDetails();
        if (f != null) {
            writer.writeMessage(2, f, PeopleCollectionPersonAuthDetails.serializeBinaryToWriter);
        }
        f = message.getPersonAdminDetails();
        if (f != null) {
            writer.writeMessage(3, f, PeopleCollectionPersonAdminDetails.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonByStudentIdResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional PeopleCollectionPersonDetails person = 1;
     * @return {?PeopleCollectionPersonDetails}
     */
    getPerson() {
        return /** @type{?PeopleCollectionPersonDetails} */ (jspb.Message.getWrapperField(this, PeopleCollectionPersonDetails, 1));
    }
    /** @param {?PeopleCollectionPersonDetails|undefined} value */
    setPerson(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPerson() {
        this.setPerson(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPerson() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional PeopleCollectionPersonAuthDetails person_auth_details = 2;
     * @return {?PeopleCollectionPersonAuthDetails}
     */
    getPersonAuthDetails() {
        return /** @type{?PeopleCollectionPersonAuthDetails} */ (jspb.Message.getWrapperField(this, PeopleCollectionPersonAuthDetails, 2));
    }
    /** @param {?PeopleCollectionPersonAuthDetails|undefined} value */
    setPersonAuthDetails(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPersonAuthDetails() {
        this.setPersonAuthDetails(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPersonAuthDetails() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional PeopleCollectionPersonAdminDetails person_admin_details = 3;
     * @return {?PeopleCollectionPersonAdminDetails}
     */
    getPersonAdminDetails() {
        return /** @type{?PeopleCollectionPersonAdminDetails} */ (jspb.Message.getWrapperField(this, PeopleCollectionPersonAdminDetails, 3));
    }
    /** @param {?PeopleCollectionPersonAdminDetails|undefined} value */
    setPersonAdminDetails(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPersonAdminDetails() {
        this.setPersonAdminDetails(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPersonAdminDetails() {
        return jspb.Message.getField(this, 3) != null;
    }
}
exports.GetPersonByStudentIdResponse = GetPersonByStudentIdResponse;
GetPersonByStudentIdResponse.displayName = "GetPersonByStudentIdResponse";