"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MembershipListMapper = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const membership_list_pb_1 = require("minga/proto/membership_list/membership_list_pb");
var MembershipListMapper;
(function (MembershipListMapper) {
    MembershipListMapper.toProto = (list) => {
        const msg = new membership_list_pb_1.MembershipList();
        if (list.id) {
            msg.setListId(list.id);
        }
        if (list.name) {
            msg.setListName(list.name);
        }
        if (list.assetPath) {
            msg.setListAsset(list.assetPath);
        }
        if (list.bannerHash) {
            msg.setListBanner(list.bannerHash);
        }
        if (list.bannerImage) {
            msg.setBannerImage(list.bannerImage);
        }
        if (list.active) {
            msg.setListActive(list.active);
        }
        if (list.listType) {
            msg.setListType(list.listType);
        }
        if (list.memberCount) {
            msg.setMemberCount(list.memberCount);
        }
        if (list.members) {
            msg.setMembersList(list.members);
        }
        if (list.contextHash) {
            msg.setContextHash(list.contextHash);
        }
        if (list.color) {
            msg.setColor(list.color);
        }
        if (list.deniedPasses)
            msg.setDeniedPassCount(list.deniedPasses);
        msg.setListPriority(list.priority || 0);
        return msg;
    };
    MembershipListMapper.fromProto = (list) => {
        const listType = list.getListType()
            ? ts_enum_util_1.$enum(domain_1.MembershipListType).asValueOrThrow(list.getListType())
            : undefined;
        return {
            id: list.getListId(),
            name: list.getListName(),
            assetPath: list.getListAsset(),
            bannerHash: list.getListBanner(),
            bannerImage: list.getBannerImage(),
            memberCount: list.getMemberCount(),
            priority: list.getListPriority(),
            active: list.getListActive(),
            members: list.getMembersList(),
            contextHash: list.getContextHash(),
            color: list.getColor(),
            listType,
            deniedPasses: list.getDeniedPassCount(),
        };
    };
})(MembershipListMapper = exports.MembershipListMapper || (exports.MembershipListMapper = {}));