"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeResponseSummaryItemMapper = void 0;
const domain_1 = require("minga/libraries/domain/index");
const stream_pb_1 = require("minga/proto/common/stream_pb");
var ChallengeResponseSummaryItemMapper;
(function (ChallengeResponseSummaryItemMapper) {
    /**
     * Convert a summary item to a challenge response card (partially). Useful
     * for showing a response card while it's still being loaded.
     */
    function toChallengeResponseCard(summary) {
        return {
            contentHash: '',
            contextHash: summary.contentContextHash,
            body: summary.body,
            ownerContextHash: '',
            backgroundBannerHash: '',
            backgroundColor: '',
            type: '',
            privacy: '',
            likeCount: 0,
            commentCount: 0,
            commentListPage: 0,
            totalCommentCount: 0,
            commentList: [],
            authorPersonView: summary.authorPersonView,
            featuredImage: summary.featuredImage,
            color: 'RED',
            approved: domain_1.ChallengeApprovalState.PENDING,
        };
    }
    ChallengeResponseSummaryItemMapper.toChallengeResponseCard = toChallengeResponseCard;
    function toStreamChallengeResponseCardResponse(summary, index) {
        const streamResp = {
            item: toChallengeResponseCard(summary),
            itemMetadata: {
                index,
                id: summary.contentContextHash,
                placeholder: false,
                position: stream_pb_1.StreamItemPosition.UPSERT,
            },
        };
        return streamResp;
    }
    ChallengeResponseSummaryItemMapper.toStreamChallengeResponseCardResponse = toStreamChallengeResponseCardResponse;
})(ChallengeResponseSummaryItemMapper = exports.ChallengeResponseSummaryItemMapper || (exports.ChallengeResponseSummaryItemMapper = {}));