import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, skipWhile, takeUntil, tap } from 'rxjs/operators';

import { PmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { ReportBaseAbstract } from 'src/app/components/manager-report/services/report-base.util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { GradesService } from 'src/app/services/Grades';

import { PointsManagerMessages } from '@modules/points-manager/constants';

import {
  FilterFormPeopleSearchType,
  FilterFormSelectType,
  FilterFormUserListType,
  FiltersFormData,
  FiltersFormService,
} from '@shared/components/filters-form';
import { MediaService } from '@shared/services/media';

import { PointsManagerRoutes } from '../../constants';
import { PointsManagerService } from '../../services';
import { PmRewardsService } from '../pm-rewards/services';
import {
  PM_REPORT_SELECT_OPTIONS,
  PM_REPORTS_FILTERS_INITIAL_STATE,
  PmReportsMessages,
} from './constants';
import { PmReportsService } from './services';

@Component({
  selector: 'mg-pm-reports',
  templateUrl: './pm-reports.component.html',
  styleUrls: ['./pm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PmReportsService],
})
export class PmReportsComponent extends ReportBaseAbstract {
  /** Constants */
  public readonly PM_MESSAGES = PointsManagerMessages;
  public readonly MESSAGES = PmReportsMessages;
  public readonly REPORT_TYPES = ReportTypes;

  /** General Observables */
  protected readonly _reportTypeSubject = new BehaviorSubject<ReportTypes>(
    ReportTypes.POINTS_SUMMARY,
  );
  private readonly _loadingFilterSubject = new BehaviorSubject<boolean>(true);
  public readonly isFilterLoading$ = this._loadingFilterSubject.asObservable();

  /** Table Data Service */
  private _dataServiceSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    {},
  );
  public readonly dataService$: Observable<any> =
    this._dataServiceSubject.asObservable();

  /** Used by the view. */
  public filtersFormStructure$: Observable<FiltersFormData> = combineLatest([
    this._reportTypeSubject,
    this._gradesService.grades$,
    this._pmService.teams$,
    this._rewardService.rewardTypes$,
    this._pmReports.filter$,
    this._pmService.isLoadingTeams$,
  ]).pipe(
    takeUntil(this._destroyed),
    skipWhile(
      ([reportType, grades, teams, rewards, filters, isLoadingTeams]) =>
        isLoadingTeams,
    ),
    map(([reportType, grades, teams, rewards, filters, _]) => {
      /**
       * Filter Types
       *
       * The record string keys defined here for each filter, such as "people"
       * or "grade" will be used to retrieve get form data and update filters.
       * Reusing the PmReportsFilter enum for consistent key values.
       */
      const peopleFilter: Record<string, FilterFormPeopleSearchType> = {
        [PmReportsFilter.ISSUED_TO]: {
          type: 'people-search',
          label: this.MESSAGES.FORM_LABEL_PEOPLE,
          multiple: true,
          value: filters[PmReportsFilter.ISSUED_TO],
        },
      };

      const gradeFilter: Record<string, FilterFormSelectType> = {
        [PmReportsFilter.GRADES]: {
          type: 'multi-select',
          label: this.MESSAGES.FORM_LABEL_GRADE,
          options: grades.map(grade => ({ label: grade, value: grade })),
          value: filters[PmReportsFilter.GRADES],
        },
      };

      const teamsFilter: Record<string, FilterFormSelectType> = {
        [PmReportsFilter.TEAMS]: {
          type: 'multi-select',
          label: this.MESSAGES.FORM_LABEL_TEAMS,
          options: teams.map(team => ({ label: team.name, value: team.id })),
          value: filters[PmReportsFilter.TEAMS],
        },
      };

      const rewardsFilter: Record<string, FilterFormSelectType> = {
        [PmReportsFilter.REWARDS]: {
          type: 'multi-select',
          label: this.MESSAGES.FORM_LABEL_REWARDS,
          options: rewards.map(reward => ({
            label: reward.name,
            value: reward.id,
          })),
          value: filters[PmReportsFilter.REWARDS],
        },
      };

      const userListFilter: Record<string, FilterFormUserListType> = {
        [PmReportsFilter.USER_LIST]: {
          type: 'user-list',
          label: this.MESSAGES.SELECT_LABEL_USER_LIST,
          value: filters[PmReportsFilter.USER_LIST],
        },
      };

      /**
       * Report Form Filters
       * Make the reports with the specified filters.
       */
      const makePointsSummaryReport = (): FiltersFormData => ({
        ...peopleFilter,
        ...gradeFilter,
        ...teamsFilter,
        ...userListFilter,
      });

      const makeTeamsPointReport = (): FiltersFormData => ({
        ...peopleFilter,
        ...teamsFilter,
      });

      const makeRewardsRedeemedReport = (): FiltersFormData => ({
        ...rewardsFilter,
      });

      const makeRedemptionHistoryReport = (): FiltersFormData => ({
        ...peopleFilter,
        ...gradeFilter,
        ...teamsFilter,
        ...rewardsFilter,
        ...userListFilter,
      });

      /** Prepare the form for the specified report type. */
      const getFormData = (): FiltersFormData => {
        if (reportType === this.REPORT_TYPES.POINTS_TEAM_SUMMARY) {
          return makeTeamsPointReport();
        } else if (reportType === this.REPORT_TYPES.POINTS_REWARDS_REDEEMED) {
          return makeRewardsRedeemedReport();
        } else if (reportType === this.REPORT_TYPES.POINTS_REDEEMED) {
          return makeRedemptionHistoryReport();
        } else {
          // POINTS_SUMMARY, POINTS_HISTORY
          return makePointsSummaryReport();
        }
      };

      const formData = getFormData();
      return this._filtersFormService.create(formData);
    }),
    tap(() => this._loadingFilterSubject.next(false)),
  );

  /** General Variables */
  public readonly REPORT_OPTIONS = PM_REPORT_SELECT_OPTIONS;

  /** Component Constructor */
  constructor(
    public media: MediaService,
    private _pmReports: PmReportsService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _gradesService: GradesService,
    private _pmService: PointsManagerService,
    private _analytics: AnalyticsService,
    private _rewardService: PmRewardsService,
    private _filtersFormService: FiltersFormService,
  ) {
    super(_pmReports);
    this._gradesService.fetchIfNeeded();
    this._pmService.fetchTeams();
    this._rewardService.fetchRewardTypes();

    /**
     * Route changed, set the active (selected) report from the new url.
     *
     * TODO: this is virtually the same as other report components routing,
     * consider generalizing the actions to have a reusable function across
     * all. IN: until, this.route, reportType.options, action. OUT: void
     */
    this._route.url.pipe(takeUntil(this._destroyed)).subscribe(x => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path as ReportTypes;
              const possiblePaths = this.REPORT_OPTIONS.map(type => type.value);
              if (possiblePaths.includes(report)) {
                this._reportTypeSubject.next(report);
                this._cdr.markForCheck();
              }
            }
          });
      }
    });

    /** Initialize the view date range */
    this._initializeDates(
      PM_REPORTS_FILTERS_INITIAL_STATE,
      this._route.snapshot?.queryParams,
      this._destroyed,
      (range, fromChangeEvent) => {
        this._pmReports.setFilter(PmReportsFilter.START_DATE, range.start);
        this._pmReports.setFilter(PmReportsFilter.END_DATE, range.end);

        if (fromChangeEvent) {
          this._pmReports.applyFilter();
        }
      },
    );
  }

  /**
   * The keys used to retrieve form data are the same key values
   * you defined in FiltersFormData for the various filters. For
   * consistency, the PmReportsFilter enum is being reused here.
   *
   * Used by the view.
   *
   * TODO: given that most of these setters look almost identical
   * and use the PmReportsFilter it feels like a cleaner approach
   * could be taken - excludes (start_date, end_date, behavior_types)
   */
  public updateFilters(formData: any) {
    if (this._reportTypeSubject.value !== ReportTypes.POINTS_REWARDS_REDEEMED) {
      this._pmReports.setFilter(
        PmReportsFilter.ISSUED_TO,
        formData[PmReportsFilter.ISSUED_TO],
      );
      this._pmReports.setFilter(
        PmReportsFilter.TEAMS,
        formData[PmReportsFilter.TEAMS],
      );
      if (this._reportTypeSubject.value !== ReportTypes.POINTS_TEAM_SUMMARY) {
        this._pmReports.setFilter(
          PmReportsFilter.GRADES,
          formData[PmReportsFilter.GRADES],
        );
        this._pmReports.setFilter(
          PmReportsFilter.USER_LIST,
          formData[PmReportsFilter.USER_LIST],
        );
      }
    }

    if (
      this._reportTypeSubject.value === ReportTypes.POINTS_REWARDS_REDEEMED ||
      this._reportTypeSubject.value === ReportTypes.POINTS_REDEEMED
    ) {
      this._pmReports.setFilter(
        PmReportsFilter.REWARDS,
        formData[PmReportsFilter.REWARDS],
      );
    }

    // Apply the filter
    this._pmReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'points',
      reportType: this._reportTypeSubject.value,
    });
  }

  /** Triggered by the view. */
  public async changeReportType(type: string): Promise<void> {
    await this._router.navigateByUrl(
      `/${PointsManagerRoutes.ROOT}/${PointsManagerRoutes.REPORTS}/${type}`,
    );
  }

  /** Used by the view. */
  public onActivate(componentRef: any) {
    this._dataServiceSubject.next(componentRef.ds);
  }
}
