import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as stats_report from 'minga/proto/stats_report/stats_report_pb';
import { PastReport } from 'minga/libraries/domain';
import { PastReportMapper as PastMappers } from 'minga/libraries/shared-grpc';
import { StatsReportManager } from 'minga/proto/stats_report/stats_report_ng_grpc_pb';
import { downloadjs } from 'src/app/util/downloadjs';

import { ErrorHandlerService } from '@shared/services/error-handler';

@Injectable({ providedIn: 'root' })
export class ScheduledReportsHistoryService {
  /** Service Constructor */
  constructor(
    private _errorHandler: ErrorHandlerService,
    private _statsManager: StatsReportManager,
    private _httpService: HttpClient,
  ) {}
  public async getAll(): Promise<PastReport[]> {
    try {
      const request = new stats_report.ListPastReportsRequest();
      const response = await this._statsManager.listPastReports(request);
      return response.getPastReportsList().map(PastMappers.fromProto);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Failed to fetch past reports`,
        error,
        true,
      );
    }
  }

  public async get(id: number): Promise<PastReport> {
    try {
      const request = new stats_report.GetPastReportRequest();
      request.setId(id);
      const response = await this._statsManager.getPastReport(request);
      return PastMappers.fromProto(response.getPastReport());
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Failed to fetch scheduled report`,
        error,
        true,
      );
    }
  }

  public async download(id: number) {
    try {
      const request = new stats_report.DownloadPastReportRequest();
      request.setId(id);
      const response = await this._statsManager.downloadPastReport(request);

      const signedUrl = response.getSignedUrl();
      const filename = response.getFilename();

      const fileResponse = await this._httpService
        .get(signedUrl, { responseType: 'arraybuffer' })
        .toPromise();

      const type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const file = new Blob([fileResponse], { type });
      downloadjs(file, filename, type);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Failed to download this report`,
        error,
        true,
      );
    }
  }
}
