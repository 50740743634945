import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import { ConversationNavigator } from 'minga/app/src/app/modules/direct-message/services';
import { MessagingFacade } from 'minga/app/src/app/modules/direct-message/store';
import { PeopleFacadeService } from 'minga/app/src/app/people';
import {
  IMingaProfile,
  MingaManagerService,
} from 'minga/app/src/app/services/MingaManager';
import { MingaStoreFacadeService } from 'minga/app/src/app/store/Minga/services/MingaStoreFacade.service';
import 'minga/app/src/globals';
import { IPeopleCollectionPersonDetails } from 'minga/libraries/domain';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { FeedbackService } from 'minga/proto/gateway/feedback_ng_grpc_pb';
import {
  SendFeedbackRequest,
  SendFeedbackResponse,
} from 'minga/proto/gateway/feedback_pb';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface ISendFeedbackDialogResponse {
  canceled?: boolean;
  successOverlay?: TemplateRef<SendFeedbackDialog>;
}

enum FeedbackSelectionEnum {
  CHOICES = 'CHOICES',
  MINGA = 'MINGA',
}

function getMingaAdminTextFromAdmins(
  admins: IPeopleCollectionPersonDetails[],
): string {
  if (admins.length == 1) {
    return `'s name is '${admins[0].displayName}'`;
  } else {
    let adminString = `s' names are `;
    for (let i = 0; i < admins.length; i++) {
      adminString += admins[i].displayName;
      if (i + 2 < admins.length) {
        adminString += ', ';
      } else if (i + 1 < admins.length) {
        adminString += ' and ';
      }
    }
    return adminString;
  }
}

@Component({
  selector: 'mg-send-feedback',
  templateUrl: './SendFeedback.component.html',
  styleUrls: ['./SendFeedback.component.scss'],
})
export class SendFeedbackDialog {
  feedbackText: string = '';
  isIOS: boolean = window.MINGA_DEVICE_IOS;
  sending: boolean = false;

  selection: FeedbackSelectionEnum = FeedbackSelectionEnum.CHOICES;

  readonly isDistrictEnabled$: Observable<boolean>;
  readonly admins$: Observable<IPeopleCollectionPersonDetails[]>;
  readonly adminsText$: Observable<string>;
  mingaProfile?: IMingaProfile;

  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate?: TemplateRef<SendFeedbackDialog>;

  constructor(
    private dialogRef: MatDialogRef<SendFeedbackDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private feedback: FeedbackService,
    private appConfig: AppConfigService,
    private mingaStore: MingaStoreFacadeService,
    private mingaManager: MingaManagerService,
    private peopleFacade: PeopleFacadeService,
    private messagingFacade: MessagingFacade,
    private conversationNavigator: ConversationNavigator,
  ) {
    this.isDistrictEnabled$ = mingaStore.observeDistrictFeatureEnabled();
    this.initMinga();

    this.admins$ = combineLatest([
      this.peopleFacade.getPeopleByRole('MANAGER'),
      this.peopleFacade.getPeopleByRole('OWNER'),
      this.isDistrictEnabled$,
    ]).pipe(
      map(([managers, owners, isDistrict]) => {
        if (isDistrict) {
          return [...managers, ...owners];
        }
        return owners;
      }),
    );

    this.adminsText$ = this.admins$.pipe(
      map(admins => getMingaAdminTextFromAdmins(admins)),
    );
  }

  get adminProfileAdminText() {
    return this.mingaProfile?.adminFullName
      ? `'s name is ` + this.mingaProfile.adminFullName
      : ' is available';
  }

  async initMinga() {
    // ensure users we need are in people collection
    await this.peopleFacade.fetchPeopleByRoleTypes(['MANAGER', 'OWNER']);

    this.mingaProfile = await this.mingaManager.readUserMinga();
  }

  clickClose() {
    if (this.sending) {
      return;
    }

    this.close();
  }

  close() {
    this.dialogRef.close({ canceled: true });
  }

  changeSelection(choice: string) {
    this.selection = $enum(FeedbackSelectionEnum).asValueOrDefault(
      choice,
      FeedbackSelectionEnum.CHOICES,
    );
  }

  async submitMinga() {
    this.sending = true;
    const request = new SendFeedbackRequest();

    request.setMessage(this.feedbackText);
    const version = await this.appConfig.getAppVersion();
    request.setAppVersion(version);

    const response = await this.feedback.sendFeedback(request).catch(err => {
      let _response = new SendFeedbackResponse();
      _response.setStatus(StatusCode.ERROR);
      return _response;
    });

    if (response.getStatus() !== StatusCode.OK) {
      console.error(
        `[SendFeedbackDialog] submit() response error: `,
        response.toObject(),
      );
      const errorMessage = `Unable to send feedback at the moment, please try again later`;
      this._systemAlertSnackBar.error(errorMessage);
      this.sending = false;
    } else {
      this.dialogRef.close({
        canceled: false,
        successOverlay: this.successOverlayTemplate,
      });
    }
  }

  async messageAdmins(admins: IPeopleCollectionPersonDetails[]) {
    this.sending = true;
    const adminHashes = admins.map(admin => admin.personHash);

    this.close();

    const conv = await this.messagingFacade
      .startConversation(adminHashes)
      .pipe(take(1))
      .toPromise();
    await this.conversationNavigator.navigateByConversationId(conv.id);
  }

  async mailAdmin() {
    let adminEmail = this.mingaProfile?.adminEmail || this.mingaProfile?.email;
    window.location.href = `mailto:${adminEmail}`;
  }
}
