"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IChallengeResponseProtoMapper = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const challenge_response_pb_1 = require("minga/proto/content/challenge/challenge_response_pb");
const video_upload_1 = require("minga/libraries/shared-grpc/video_upload/index");
var IChallengeResponseProtoMapper;
(function (IChallengeResponseProtoMapper) {
    function fromChallengeResponseInfoMessage(message) {
        const videoUpload = video_upload_1.IVideoUploadProtoMapper.fromIVideoUploadMessage(message.getVideo());
        return {
            body: message.getBody(),
            contentHash: message.getContentHash(),
            privacy: ts_enum_util_1.$enum(domain_1.ChallengePrivacy).asValueOrDefault(message.getPrivacy(), domain_1.ChallengePrivacy.PUBLIC),
            type: ts_enum_util_1.$enum(domain_1.ChallengeType).asValueOrDefault(message.getType(), domain_1.ChallengeType.TEXT),
            video: videoUpload,
            videoId: null,
            backgroundBannerHash: message.getBackgroundBannerHash(),
            backgroundColor: message.getBackgroundColor(),
            // contentid
            asset: message.getAssetPath(),
            ownerContextHash: message.getOwnerContextHash(),
            approved: ts_enum_util_1.$enum(domain_1.ChallengeApprovalState).asValueOrDefault(message.getApproved(), domain_1.ChallengeApprovalState.PENDING),
        };
    }
    IChallengeResponseProtoMapper.fromChallengeResponseInfoMessage = fromChallengeResponseInfoMessage;
    function toChallengeResponseInfoMessage(challenge) {
        const message = new challenge_response_pb_1.ChallengeResponseInfo();
        if (challenge.backgroundBannerHash) {
            message.setBackgroundBannerHash(challenge.backgroundBannerHash);
        }
        if (challenge.backgroundColor) {
            message.setBackgroundColor(challenge.backgroundColor);
        }
        if (challenge.asset) {
            message.setAssetPath(challenge.asset);
        }
        if (challenge.contentHash) {
            message.setContentHash(challenge.contentHash);
        }
        if (challenge.ownerContextHash) {
            message.setOwnerContextHash(challenge.ownerContextHash);
        }
        message.setBody(challenge.body);
        message.setPrivacy(challenge.privacy);
        message.setType(challenge.type);
        if (challenge.video) {
            const video = video_upload_1.IVideoUploadProtoMapper.toIVideoUploadMessage(challenge.video);
            message.setVideo(video);
        }
        if (challenge.approved) {
            message.setApproved(challenge.approved);
        }
        return message;
    }
    IChallengeResponseProtoMapper.toChallengeResponseInfoMessage = toChallengeResponseInfoMessage;
})(IChallengeResponseProtoMapper = exports.IChallengeResponseProtoMapper || (exports.IChallengeResponseProtoMapper = {}));