"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const event_pb_1 = require("minga/proto/content/event_pb");
exports.toProto = (filter, limit, offset) => {
    const filterMessage = new event_pb_1.EventReportFilter();
    if (offset)
        filterMessage.setOffset(offset);
    if (limit)
        filterMessage.setLimit(limit);
    filterMessage.setContextHash(filter.eventContext);
    filterMessage.setPersonStatus(filter.personStatus);
    return filterMessage;
};
exports.fromProto = (proto) => {
    const personStatus = ts_enum_util_1.$enum(domain_1.EventStatus).asValueOrThrow(proto.getPersonStatus());
    return {
        eventContext: proto.getContextHash(),
        personStatus,
        limit: proto.getLimit(),
        offset: proto.getOffset(),
    };
};