<mg-collapsible
  class="mobile-header-collapse"
  collapseText="Hide filters"
  expandText="Show filters"
  [enabled]="media.isMobileView$ | async"
  [initiallyCollapsed]="false">
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="24px"
    fxLayoutGap.lt-md="12px">
    <div class="search-filter-container">
      <mg-form-text-input
        id="schedule-day-filter-text-search"
        [isClearable]="true"
        label="Search"
        [control]="searchControl">
      </mg-form-text-input>
    </div>

    <!-- We only need to show the date filters if we using bell schedule test mode -->
    <ng-container *ngIf="isBellScheduleEnabledForCurrentUser$ | async">
      <mg-form-select
        *ngIf="media.isMobileView$ | async; else desktopDaysFilter"
        id="schedule-day-filter-select"
        #typesFilterSelect
        class="filters-status"
        placeholder="Day of the week"
        [value]="activeDayQueryParam$ | async"
        [closeOnSelect]="true"
        [multiple]="false"
        [searchable]="false"
        [options]="selectOptions$ | async"
        returnMode="full"
        (selectionChanged)="setDayFilter($event.date)">
      </mg-form-select>

      <ng-template #desktopDaysFilter>
        <div class="quick-actions-container">
          <mat-button-toggle-group
            name="filter-toggle-group"
            class="filter-toggle-group"
            [multiple]="false"
            [value]="activeDayQueryParam$ | async"
            (change)="setDayFilter($event.value)">
            <mat-button-toggle
              *ngFor="let day of currentWeek$ | async"
              [id]="'schedule-day-filter-toggle-' + day.day()"
              class="toggle-button"
              [value]="day"
              [checked]="day.day() === (activeDayQueryParam$ | async)">
              <mg-text variant="body-sm-secondary">
                {{ day.format('ddd') }}
              </mg-text>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </ng-template>
    </ng-container>
  </div>
</mg-collapsible>

<!-- Main content -->
<section id="main-content">
  <!-- Table -->
  <ng-container *ngTemplateOutlet="tableTemplate"></ng-container>
  <!-- Loading -->
  <ng-container
    *ngIf="loading$ | async"
    [ngTemplateOutlet]="loadingTemplate">
  </ng-container>
  <!-- Empty state -->
  <ng-container
    *ngIf="(loading$ | async) === false && dataSource.data.length === 0"
    [ngTemplateOutlet]="emptyStateTemplate">
  </ng-container>
</section>

<!-- Table Template -->
<ng-template #tableTemplate>
  <mat-table
    matSort
    [matSortActive]="defaultSortOrder$ | async"
    matSortDirection="asc"
    class="list-table"
    [dataSource]="dataSource">
    <!-- Period column -->
    <ng-container matColumnDef="period">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        mgSortHeader
        class="w-sm-flex"
        [activeColumnKey]="activeSortColumn$ | async"
        (click)="selectActiveColumn('period')">
        <mg-text
          class="column-header"
          textAlign="left"
          variant="body-xs-secondary"
          color="grey-300">
          {{ MESSAGES.COLUMN_LABEL_PERIOD }}
        </mg-text>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let schedule"
        class="w-sm-flex">
        <mg-text
          matTooltipClass="mg-tooltip"
          variant="body-md"
          textAlign="left"
          [numberOfLines]="1"
          [matTooltip]="schedule?.period">
          {{ schedule.period || '&mdash;' }}
        </mg-text>
      </mat-cell>
    </ng-container>

    <!-- Course column -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        mgSortHeader
        class="w-md-flex"
        [activeColumnKey]="activeSortColumn$ | async"
        (click)="selectActiveColumn('course')">
        <mg-text
          class="column-header"
          textAlign="left"
          variant="body-xs-secondary"
          color="grey-300">
          {{ MESSAGES.COLUMN_LABEL_COURSE }}
        </mg-text>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let schedule"
        class="w-md-flex">
        <mg-text
          matTooltipClass="mg-tooltip"
          variant="body-md"
          textAlign="left"
          [numberOfLines]="2"
          [matTooltip]="schedule.name">
          {{ schedule.name || '&mdash;' }}
        </mg-text>
      </mat-cell>
    </ng-container>

    <!-- Room column -->
    <ng-container matColumnDef="location">
      <mat-header-cell
        *matHeaderCellDef
        class="w-sm-flex">
        <mg-text
          class="column-header"
          textAlign="left"
          variant="body-xs-secondary"
          color="grey-300">
          {{ MESSAGES.COLUMN_LABEL_ROOM }}
        </mg-text>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let schedule"
        class="w-sm-flex">
        <mg-text
          class="clickable-text"
          matTooltipClass="mg-tooltip"
          variant="body-md"
          textAlign="left"
          [numberOfLines]="1"
          [matTooltip]="schedule.location">
          {{ schedule.location || '&mdash;' }}
        </mg-text>
      </mat-cell>
    </ng-container>

    <!-- Teacher column -->
    <ng-container matColumnDef="teachers">
      <mat-header-cell
        *matHeaderCellDef
        class="w-md-flex w-auto-max">
        <mg-text
          class="column-header"
          textAlign="left"
          variant="body-xs-secondary"
          color="grey-300">
          {{ MESSAGES.COLUMN_LABEL_TEACHER }}
        </mg-text>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let schedule"
        class="w-md-flex w-auto-max">
        <mg-text
          class="clickable-text"
          matTooltipClass="mg-tooltip"
          variant="body-md"
          textAlign="left"
          [numberOfLines]="1"
          [matTooltip]="
            schedule.teachers.length > 0 ? schedule.teachers.join(', ') : ''
          ">
          {{
            schedule.teachers.length > 0
              ? schedule.teachers.join(', ')
              : '&mdash;'
          }}
        </mg-text>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        mgSortHeader
        [activeColumnKey]="activeSortColumn$ | async"
        (click)="selectActiveColumn('startTime')"
        class="w-20">
        <mg-text
          class="column-header"
          textAlign="center"
          variant="body-xs-secondary"
          color="grey-300">
          {{ MESSAGES.COLUMN_LABEL_START_TIME }}
        </mg-text>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let schedule"
        class="w-20">
        <mg-text
          variant="body-md"
          textAlign="center">
          {{ (schedule.startTime | formatTime) || '—' }}
        </mg-text>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <mat-header-cell
        *matHeaderCellDef
        class="w-20">
        <mg-text
          class="column-header"
          textAlign="center"
          variant="body-xs-secondary"
          color="grey-300">
          {{ MESSAGES.COLUMN_LABEL_END_TIME }}
        </mg-text>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let schedule"
        class="w-20">
        <mg-text
          variant="body-md"
          textAlign="center">
          {{ (schedule.endTime | formatTime) || '—' }}
        </mg-text>
      </mat-cell>
    </ng-container>

    <!-- Mobile column -->
    <ng-container matColumnDef="mobile">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell
        *matCellDef="let schedule"
        class="mobile-layout">
        <mg-mobile-card
          [title]="schedule.name"
          [subtitle]="'Period: ' + schedule.period">
          <div
            class="mobile-card-body-container"
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="4px">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between start">
              <mg-text
                variant="label-md"
                color="surface-alt">
                {{ MESSAGES.COLUMN_LABEL_ROOM }}
              </mg-text>
              <mg-text variant="label-md">
                {{ schedule.location || '&mdash;' }}
              </mg-text>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between start">
              <mg-text
                variant="label-md"
                color="surface-alt">
                {{ MESSAGES.COLUMN_LABEL_TEACHER || '&mdash;' }}
              </mg-text>
              <mg-text variant="label-md">
                {{
                  schedule.teachers.length > 0
                    ? schedule.teachers.join(', ')
                    : '&mdash;'
                }}
              </mg-text>
            </div>
          </div>
        </mg-mobile-card>
      </mat-cell>
    </ng-container>

    <!-- Table header row -->

    <mat-header-row
      [fxHide]="(loading$ | async) || dataSource.data?.length === 0"
      *matHeaderRowDef="displayColumns$ | async">
    </mat-header-row>

    <!-- Table row -->
    <mat-row
      [fxHide]="(loading$ | async) || dataSource.data?.length === 0"
      *matRowDef="let row; columns: displayColumns$ | async">
    </mat-row>
  </mat-table>
</ng-template>

<!-- Empty State Template -->
<ng-template #emptyStateTemplate>
  <mg-empty-state [title]="MESSAGES.EMPTY_SCHEDULE"> </mg-empty-state>
</ng-template>

<!-- Loading Template -->
<ng-template #loadingTemplate>
  <div class="loading">
    <mg-spinner></mg-spinner>
  </div>
</ng-template>
