"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TollFreeVerificationStatusType = exports.PhoneNumberStatusType = void 0;
var PhoneNumberStatusType;
(function (PhoneNumberStatusType) {
    PhoneNumberStatusType["IN_SERVICE"] = "IN_SERVICE";
    PhoneNumberStatusType["NOT_IN_SERVICE"] = "NOT_IN_SERVICE";
})(PhoneNumberStatusType = exports.PhoneNumberStatusType || (exports.PhoneNumberStatusType = {}));
var TollFreeVerificationStatusType;
(function (TollFreeVerificationStatusType) {
    TollFreeVerificationStatusType["VERIFIED"] = "VERIFIED";
    TollFreeVerificationStatusType["UNVERIFIED"] = "UNVERIFIED";
    TollFreeVerificationStatusType["PENDING"] = "PENDING";
    TollFreeVerificationStatusType["PARTIALLY_VERIFIED"] = "PARTIALLY_VERIFIED";
    TollFreeVerificationStatusType["INVALID_STATUS"] = "INVALID_STATUS";
})(TollFreeVerificationStatusType = exports.TollFreeVerificationStatusType || (exports.TollFreeVerificationStatusType = {}));