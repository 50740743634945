"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INewDirectMessageMapper = void 0;
const google_protobuf_1 = require("google-protobuf");
const realtime_pb_1 = require("minga/proto/messaging/realtime_pb");
const messaging_1 = require("minga/libraries/shared-grpc/messaging/index");
var INewDirectMessageMapper;
(function (INewDirectMessageMapper) {
    function fromBase64(b64) {
        const proto = realtime_pb_1.NewDirectMessage.deserializeBinary(b64);
        return fromProto(proto);
    }
    INewDirectMessageMapper.fromBase64 = fromBase64;
    function toBase64(newDm) {
        const binaryWriter = new google_protobuf_1.BinaryWriter();
        realtime_pb_1.NewDirectMessage.serializeBinaryToWriter(toProto(newDm), binaryWriter);
        return binaryWriter.getResultBase64String();
    }
    INewDirectMessageMapper.toBase64 = toBase64;
    function fromProto(msg) {
        return {
            conversationId: msg.getConversationId(),
            messageId: msg.getMessageId(),
            body: msg.getBody(),
            timestamp: msg.getTimestamp(),
            authorPersonHash: msg.getAuthorPersonHash(),
            attachmentList: messaging_1.MessageMapper.fromProtoMessageAttachmentToIMessageAttachment(msg.getAttachmentList()),
        };
    }
    INewDirectMessageMapper.fromProto = fromProto;
    function toProto(newDm) {
        const msg = new realtime_pb_1.NewDirectMessage();
        msg.setConversationId(newDm.conversationId);
        msg.setMessageId(newDm.messageId);
        msg.setBody(newDm.body);
        msg.setTimestamp(newDm.timestamp);
        msg.setAuthorPersonHash(newDm.authorPersonHash);
        msg.setAttachmentList(messaging_1.MessageMapper.fromIMessageAttachmentToProtoMessageAttachment(newDm.attachmentList));
        return msg;
    }
    INewDirectMessageMapper.toProto = toProto;
})(INewDirectMessageMapper = exports.INewDirectMessageMapper || (exports.INewDirectMessageMapper = {}));