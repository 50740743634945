"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const domain_2 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const points_pb_1 = require("minga/proto/points/points_pb");
exports.toProto = (item) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const msg = new points_pb_1.PointsHistoryItem();
    msg.setId(item.id);
    msg.setPoints(item.points);
    msg.setReason(item.reason);
    msg.setType(item.type);
    if (item.issuedBy) {
        const personMsg = new points_pb_1.PointsHistoryItemPerson();
        personMsg.setPersonHash((_a = item.issuedBy) === null || _a === void 0 ? void 0 : _a.personHash);
        personMsg.setStudentId(((_b = item.issuedBy) === null || _b === void 0 ? void 0 : _b.studentId) || '');
        personMsg.setFirstName(((_c = item.issuedBy) === null || _c === void 0 ? void 0 : _c.firstName) || '');
        personMsg.setLastName(((_d = item.issuedBy) === null || _d === void 0 ? void 0 : _d.lastName) || '');
        personMsg.setGrade(((_e = item.issuedBy) === null || _e === void 0 ? void 0 : _e.grade) || '');
        personMsg.setId(((_f = item.issuedBy) === null || _f === void 0 ? void 0 : _f.id) || 0);
        personMsg.setEmail(((_g = item.issuedBy) === null || _g === void 0 ? void 0 : _g.email) || '');
        personMsg.setBadgeRoleName(((_h = item.issuedBy) === null || _h === void 0 ? void 0 : _h.roleType) || '');
        msg.setIssuedBy(personMsg);
    }
    if (item.issuedTo) {
        const personMsg = new points_pb_1.PointsHistoryItemPerson();
        personMsg.setPersonHash((_j = item.issuedTo) === null || _j === void 0 ? void 0 : _j.personHash);
        personMsg.setStudentId(((_k = item.issuedTo) === null || _k === void 0 ? void 0 : _k.studentId) || '');
        personMsg.setFirstName(((_l = item.issuedTo) === null || _l === void 0 ? void 0 : _l.firstName) || '');
        personMsg.setLastName(((_m = item.issuedTo) === null || _m === void 0 ? void 0 : _m.lastName) || '');
        personMsg.setGrade(((_o = item.issuedTo) === null || _o === void 0 ? void 0 : _o.grade) || '');
        personMsg.setId(((_p = item.issuedTo) === null || _p === void 0 ? void 0 : _p.id) || 0);
        personMsg.setEmail(((_q = item.issuedTo) === null || _q === void 0 ? void 0 : _q.email) || '');
        personMsg.setBadgeRoleName(((_r = item.issuedTo) === null || _r === void 0 ? void 0 : _r.roleType) || '');
        msg.setIssuedTo(personMsg);
    }
    if (item.teamName) {
        msg.setTeamName(item.teamName);
        msg.setTeamId(item.teamId || 0);
    }
    const dateMsg = shared_grpc_1.dateObjectToDateTimeMessage(item.date);
    msg.setDate(dateMsg);
    if (item.imported)
        msg.setImported(item.imported);
    return msg;
};
exports.fromProto = (msg) => {
    const date = shared_grpc_1.dateTimeMessageToDateObject(msg.getDate());
    const points = msg.getPoints();
    const reason = msg.getReason();
    const issuedBy = msg.getIssuedBy();
    const issuedTo = msg.getIssuedTo();
    const typeValue = msg.getType();
    const type = ts_enum_util_1.$enum(domain_2.PointHistoryType).asValueOrDefault(typeValue, domain_2.PointHistoryType.CUSTOM);
    return {
        id: msg.getId(),
        date,
        points,
        reason,
        issuedBy: issuedBy
            ? {
                id: issuedBy.getId(),
                email: issuedBy.getEmail(),
                personHash: issuedBy.getPersonHash(),
                studentId: issuedBy.getStudentId(),
                firstName: issuedBy.getFirstName(),
                lastName: issuedBy.getLastName(),
                grade: issuedBy.getGrade(),
                roleType: ts_enum_util_1.$enum(domain_1.MingaRoleType).asValueOrThrow(issuedBy.getBadgeRoleName()),
            }
            : undefined,
        issuedTo: issuedTo
            ? {
                id: issuedTo.getId(),
                email: issuedTo.getEmail(),
                personHash: issuedTo.getPersonHash(),
                studentId: issuedTo.getStudentId(),
                firstName: issuedTo.getFirstName(),
                lastName: issuedTo.getLastName(),
                grade: issuedTo.getGrade(),
                roleType: ts_enum_util_1.$enum(domain_1.MingaRoleType).asValueOrThrow(issuedTo.getBadgeRoleName()),
            }
            : undefined,
        type,
        imported: msg.getImported(),
        teamName: msg.getTeamName(),
        teamId: msg.getTeamId(),
    };
};