"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBadgeNotification = void 0;
const domain_1 = require("minga/libraries/domain/index");
function isBadgeNotification(type) {
    if (!type) {
        return false;
    }
    switch (type) {
        case domain_1.INotificationType.BADGE_IMAGE_CHALLENGE:
            return true;
        case domain_1.INotificationType.BADGE_TEXT_CHALLENGE:
            return true;
        case domain_1.INotificationType.BADGE_CUSTOM:
            return true;
        default:
            break;
    }
    return false;
}
exports.isBadgeNotification = isBadgeNotification;