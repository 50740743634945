"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IChallengeProtoMapper = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const domain_2 = require("minga/libraries/domain/index");
const challenge_pb_1 = require("minga/proto/content/challenge/challenge_pb");
const content_views_pb_1 = require("minga/proto/gateway/content_views_pb");
const common_1 = require("minga/libraries/shared-grpc/common/index");
const video_upload_1 = require("minga/libraries/shared-grpc/video_upload/index");
var IChallengeProtoMapper;
(function (IChallengeProtoMapper) {
    function fromChallengeInfoMessage(message) {
        const videoUpload = video_upload_1.IVideoUploadProtoMapper.fromIVideoUploadMessage(message.getVideo());
        const date = message.getDueDate();
        if (!date) {
            throw new Error('Due date is required');
        }
        const dueDate = common_1.DateProtoMapper.dateTimeMessageToDateObject(date);
        const allowedRoles = message.getAllowedRolesList().map(role => {
            return ts_enum_util_1.$enum(domain_2.MingaRoleType).asValueOrThrow(role);
        });
        return {
            body: message.getBody(),
            commentsAllowed: message.getCommentsAllowed(),
            contentHash: message.getContentHash(),
            dueDate: dueDate,
            privacy: ts_enum_util_1.$enum(domain_1.ChallengePrivacy).asValueOrDefault(message.getPrivacy(), domain_1.ChallengePrivacy.PUBLIC),
            title: message.getTitle(),
            type: ts_enum_util_1.$enum(domain_1.ChallengeType).asValueOrDefault(message.getType(), domain_1.ChallengeType.TEXT),
            bannerHash: message.getBannerHash(),
            video: videoUpload,
            videoId: null,
            bannerAsset: message.getBannerAsset(),
            color: ts_enum_util_1.$enum(domain_1.ChallengeColor).asValueOrDefault(message.getColor(), domain_1.ChallengeColor.RED),
            allowedRoles: allowedRoles,
            invitedPeopleCount: message.getInvitePeopleCount(),
            invitedResponseCount: message.getInviteResponseCount(),
            requiresApproval: message.getRequiresApproval(),
        };
    }
    IChallengeProtoMapper.fromChallengeInfoMessage = fromChallengeInfoMessage;
    function toChallengeInfoMessage(challenge) {
        const message = new challenge_pb_1.ChallengeInfo();
        if (challenge.bannerHash) {
            message.setBannerHash(challenge.bannerHash);
        }
        if (challenge.contentHash) {
            message.setContentHash(challenge.contentHash);
        }
        if (challenge.contextHash) {
            message.setContextHash(challenge.contextHash);
        }
        message.setBody(challenge.body);
        message.setCommentsAllowed(challenge.commentsAllowed);
        if (challenge.dueDate) {
            message.setDueDate(common_1.DateProtoMapper.dateObjectToDateTimeMessage(challenge.dueDate));
        }
        message.setPrivacy(challenge.privacy);
        message.setTitle(challenge.title);
        message.setType(challenge.type);
        if (challenge.video) {
            const video = video_upload_1.IVideoUploadProtoMapper.toIVideoUploadMessage(challenge.video);
            message.setVideo(video);
        }
        message.setColor(challenge.color);
        message.setAllowedRolesList(challenge.allowedRoles);
        message.setRequiresApproval(challenge.requiresApproval || false);
        message.setInvitePeopleCount(challenge.invitedPeopleCount || 0);
        message.setInviteResponseCount(challenge.invitedResponseCount || 0);
        return message;
    }
    IChallengeProtoMapper.toChallengeInfoMessage = toChallengeInfoMessage;
    function toLongCardChallengeViewMessage(challenge) {
        const message = new content_views_pb_1.LongChallengeCardView();
        if (challenge.contentHash) {
            message.setContentHash(challenge.contentHash);
        }
        message.setBody(challenge.body);
        message.setCommentsAllowed(challenge.commentsAllowed);
        if (challenge.dueDate) {
            message.setDueDate(common_1.DateProtoMapper.dateObjectToDateTimeMessage(challenge.dueDate));
        }
        message.setPrivacy(challenge.privacy);
        message.setTitle(challenge.title);
        message.setChallengeType(challenge.type);
        if (challenge.video && challenge.video.cloudflareUid) {
            message.setCloudflareUid(challenge.video.cloudflareUid);
            message.setCloudflareReady(!!challenge.video.processing);
        }
        message.setColor(challenge.color);
        message.setAllowedRolesList(challenge.allowedRoles);
        message.setRequiresApproval(!!challenge.requiresApproval);
        return message;
    }
    IChallengeProtoMapper.toLongCardChallengeViewMessage = toLongCardChallengeViewMessage;
})(IChallengeProtoMapper = exports.IChallengeProtoMapper || (exports.IChallengeProtoMapper = {}));