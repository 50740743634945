"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MingaType = void 0;
const domain_1 = require("minga/libraries/domain/index");
const shared_1 = require("minga/libraries/shared/index");
class MingaType {
    getInviteRole() {
        return this.getDefaultRole();
    }
    getAvailableRoleTypes() {
        return Object.keys(this.availableRoles);
    }
    getAvailableRoles() {
        return this.getAvailableRoleTypes().map(shared_1.getRole);
    }
    getAvailableRolesWithPermission(perm) {
        return this.getAvailableRoles().filter(r => r.hasPermission(perm));
    }
    getBillableRoles(isPricedPerDefaultRole = true) {
        return this.getAvailableRoles();
    }
    getAllowedToJoinRoles() {
        return [this.getDefaultRole()];
    }
    getMingaRolesFilteredForUserRole(role) {
        const options = this.availableRoles[role.roleType];
        const filteredRoles = [];
        if (options) {
            const roleWeight = options.weight;
            for (const key in this.availableRoles) {
                const roleType = key;
                const options = this.availableRoles[roleType];
                if (options && options.weight <= roleWeight) {
                    filteredRoles.push(shared_1.getRole(roleType));
                }
            }
        }
        return filteredRoles;
    }
    maxAvailableRole(role, target) {
        const roleWeight = this._getRoleWeight(role);
        const targetWeight = this._getRoleWeight(target);
        if (roleWeight >= targetWeight) {
            return target;
        }
        else {
            return role;
        }
    }
    getDefaultMingaSettingsAsMap() {
        return new Map(this.defaultSettings.map(setting => [setting.name, setting.value]));
    }
    _getRoleWeight(role) {
        var _a;
        return ((_a = this.availableRoles[role.roleType]) === null || _a === void 0 ? void 0 : _a.weight) || 0;
    }
    getImmutableCommentRoles() {
        return [
            shared_1.getRole(domain_1.MingaRoleType.MANAGER),
            shared_1.getRole(domain_1.MingaRoleType.OWNER),
            shared_1.getRole(domain_1.MingaRoleType.DISTRICT_LEADER),
            shared_1.getRole(domain_1.MingaRoleType.DISTRICT_MANAGER),
        ];
    }
    getDisplaySubscriptions() {
        return [];
    }
    getImmutableGroupCreateRoles() {
        return [
            shared_1.getRole(domain_1.MingaRoleType.MANAGER),
            shared_1.getRole(domain_1.MingaRoleType.OWNER),
            shared_1.getRole(domain_1.MingaRoleType.DISTRICT_LEADER),
            shared_1.getRole(domain_1.MingaRoleType.SCHOOL_MANAGER),
            shared_1.getRole(domain_1.MingaRoleType.DISTRICT_MANAGER),
        ];
    }
}
exports.MingaType = MingaType;