import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import * as mingaProto from 'minga/proto/gateway/minga_pb';
import { GroupType, getGroupRangeByDate } from 'minga/libraries/domain';
import { GraphDataProtoMapper } from 'minga/libraries/shared-grpc';
import { GraphGroupBy } from 'minga/proto/common/graph_pb';
import { MingaManager } from 'minga/proto/gateway/minga_ng_grpc_pb';
import { dateTimeObjectToDateTimeMessage } from 'src/app/util/date';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { MmReportsFiltersService } from './mm-filters.services';

@Injectable()
export class MmReportsService implements OnDestroy {
  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);
  private readonly _isLoadingGraph = new BehaviorSubject<boolean>(false);
  public readonly isLoadingGraph$ = this._isLoadingGraph
    .asObservable()
    .pipe(shareReplay());
  private readonly _isLoadingTotals = new BehaviorSubject<boolean>(false);
  public readonly isLoadingTotals$ = this._isLoadingTotals
    .asObservable()
    .pipe(shareReplay());

  /** Graph Data */
  private readonly _graphData = new BehaviorSubject<
    GraphDataProtoMapper.AggregateDataPoint[]
  >([]);
  public readonly graphData$ = this._graphData
    .asObservable()
    .pipe(shareReplay());

  private readonly _totalsData = new BehaviorSubject<number[]>([]);
  public readonly totalsData$ = this._totalsData.asObservable();

  /** Service Constructor */
  constructor(
    private _mingaManager: MingaManager,
    private _filters: MmReportsFiltersService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._isLoadingGraph.complete();
    this._isLoadingTotals.complete();
    this._graphData.complete();
  }

  public async getReportsData(): Promise<void> {
    try {
      this._isLoadingTotals.next(true);
      const result = await this._listGraphData();
      this._graphData.next(result);
      const totals = await this.getStatTotals();
      this._totalsData.next(totals);
    } catch (error) {
      this._systemAlertSnackBar.error('failed to fetch graph data');
      console.error(error);
    } finally {
      this._isLoadingTotals.next(false);
    }
  }

  public async getGraphData(): Promise<void> {
    try {
      this._isLoadingGraph.next(true);
      const result = await this._listGraphData();
      this._graphData.next(result);
    } catch (error) {
      this._systemAlertSnackBar.error('failed to fetch graph data');
      console.error(error);
    } finally {
      this._isLoadingGraph.next(false);
    }
  }

  private async _listGraphData(): Promise<
    GraphDataProtoMapper.AggregateDataPoint[]
  > {
    let request = new mingaProto.GetMingaReportRequest();
    await this._setFiltersOnRequest(request);

    const response = await this._mingaManager.getMingaReport(request);
    return response.getDataList().map(GraphDataProtoMapper.fromProto) || [];
  }

  private _setProtoGroupBy(reportType: GroupType): any {
    switch (reportType) {
      case GroupType.BY_DAY:
        return GraphGroupBy.DATE;
      case GroupType.BY_WEEK:
        return GraphGroupBy.WEEK;
      case GroupType.BY_MONTH:
        return GraphGroupBy.MONTH;
      case GroupType.BY_HOUR:
        return GraphGroupBy.DATE;
      default:
        this._systemAlertSnackBar.error('report group by type not recognized');
    }
  }

  private async _setFiltersOnRequest(
    request:
      | mingaProto.GetMingaReportSummaryRequest
      | mingaProto.GetMingaReportRequest,
  ) {
    const {
      district,
      partner,
      state,
      hash,
      status,
      subscription,
      startDate,
      endDate,
      type,
      module,
    } = await this._filters.getCurrentFilterState();
    if (hash) request.setMingaHash(hash);
    if (state) request.setState(state);
    if (district) request.setDistrict(district);
    if (partner) request.setPartner(partner);
    if (status.length) request.setStatusList(status);
    if (subscription.length) request.setSubscriptionList(subscription as any[]);
    if (module?.length) request.setModulesList(module);

    request.setStartDate(dateTimeObjectToDateTimeMessage(startDate.toDate()));
    request.setEndDate(dateTimeObjectToDateTimeMessage(endDate.toDate()));
    const groupBy = getGroupRangeByDate(startDate, endDate);
    if ('setType' in request) {
      request.setType(type);
      request.setGroupBy(this._setProtoGroupBy(groupBy));
    }
  }

  public async getStatTotals(): Promise<number[]> {
    const request = new mingaProto.GetMingaReportSummaryRequest();
    await this._setFiltersOnRequest(request);
    const result = await this._mingaManager.getMingaReportSummary(request);
    const totals = [];
    totals[mingaProto.MingaReportType.POST] = result.getPostCount();
    totals[mingaProto.MingaReportType.ACTIVE_USER] =
      result.getActiveUserCount();
    totals[mingaProto.MingaReportType.BEHAVIOR] = result.getBehaviorCount();
    totals[mingaProto.MingaReportType.HALL_PASS] = result.getHallPassCount();
    totals[mingaProto.MingaReportType.ACTIVE_STUDENT] =
      result.getActiveStudentCount();
    totals[mingaProto.MingaReportType.CHECKIN] = result.getCheckinCount();

    return totals;
  }
}
