"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const pbis_stats_pb_1 = require("minga/proto/pbis_stats/pbis_stats_pb");
const person_1 = require("minga/libraries/shared-grpc/person/index");
exports.toProto = (item) => {
    const msg = new pbis_stats_pb_1.PbisAutomationStats();
    msg.setComplete(item.complete);
    msg.setIncomplete(item.incomplete);
    msg.setOverdue(item.overdue);
    msg.setCounter(item.counter);
    msg.setConsequence(item.consequence);
    msg.setAutomationGroupId(item.automationGroupId);
    msg.setAutomationGroup(item.automationGroup);
    msg.setPerson(person_1.IReportPersonMapper.toProto(item.person));
    return msg;
};
exports.fromProto = (msg) => ({
    complete: msg.getComplete(),
    incomplete: msg.getIncomplete(),
    overdue: msg.getOverdue(),
    counter: msg.getCounter(),
    consequence: msg.getConsequence(),
    automationGroupId: msg.getAutomationGroupId(),
    automationGroup: msg.getAutomationGroup(),
    person: person_1.IReportPersonMapper.fromProto(msg.getPerson()),
});