import { IMingaFeatureToggleKeys } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { MingaAppMainRoutes } from 'src/app/types';

import { DirectMessageRoute } from '@modules/direct-message';
import { HomeRoute } from '@modules/home/constants';
import { KioskRoute } from '@modules/kiosk';
import { MingaProfileRoute } from '@modules/minga-profile';
import { SuperAdminRoute } from '@modules/super-admin';
import { ToolsRoutes } from '@modules/tools/tools.types';

import {
  AccessRestrictionsGuard,
  AuthGuard,
  CountryGuard,
  FeatureFlagGuard,
  HasUserGuard,
} from '@shared/guards';

import { GroupRoutes } from '../../groups/groups.types';

export const APP_ROUTES: MingaAppMainRoutes = [
  // Landing
  {
    path: 'landing',
    data: {
      title: 'Welcome',
      navigationVisibility: false,
    },
    loadChildren: () => import('@modules/landing').then(m => m.LandingModule),
  },
  /**
   * @todo create a seeder Minga so that this route can be removed
   */
  {
    path: 'landing2',
    loadChildren: () =>
      import('minga/app/src/app/landing2/Landing2.module').then(
        m => m.Landing2Module,
      ),
  },
  // Home
  {
    path: HomeRoute.ROOT,
    canActivate: [AuthGuard, CountryGuard, HasUserGuard],
    data: {
      title: 'Home',
      description: 'Your personal Minga home',
      navigationVisibility: true,
      icon: {
        default: 'mg-home-menu-o',
        active: 'mg-home-menu',
      },
    },
    loadChildren: () => import('@modules/home').then(m => m.HomeModule),
  },
  // Minga profile
  {
    path: MingaProfileRoute.ROOT,
    canActivate: [AuthGuard],
    data: {
      title: 'Minga Profile',
      description: 'Your personal Minga',
      navigationVisibility: false,
    },
    loadChildren: () =>
      import('@modules/minga-profile').then(m => m.MingaProfileModule),
  },
  // Direct messages
  {
    path: DirectMessageRoute.ROOT,
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      title: 'Direct Messaging',
      description: 'Send and recieve messages',
      navigationVisibility: false,
      accessRestrictions: {
        featureFlag: {
          only: [IMingaFeatureToggleKeys.DM_ENABLED],
        },
      },
    },
    loadChildren: () =>
      import('@modules/direct-message').then(m => m.DirectMessageModule),
  },
  // Super admin
  {
    path: SuperAdminRoute.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'Super Admin',
      description: 'Only for super admins!!',
      navigationVisibility: true,
      icon: {
        default: 'mg-super-admin-menu-o',
        active: 'mg-super-admin-menu',
      },
      accessRestrictions: {
        permissions: {
          atLeastOne: [MingaPermission.SUPERADMIN],
        },
      },
    },
    loadChildren: () =>
      import('@modules/super-admin').then(m => m.SuperAdminModule),
  },
  // My Tools
  {
    path: ToolsRoutes.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'My Tools',
      description: 'Access your tools',
      navigationVisibility: true,
      accessRestrictions: {
        permissions: {
          atLeastOne: [
            MingaPermission.TEACHER_TOOLS,
            MingaPermission.STUDENT_TOOLS,
            MingaPermission.IS_PARENT,
          ],
        },
      },
    },
    loadChildren: () => import('@modules/tools').then(m => m.ToolsRoutesModule),
  },
  {
    path: KioskRoute.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'Kiosk',
      navigationVisibility: false,
      accessRestrictions: {
        permissions: {
          atLeastOne: [MingaPermission.KIOSK_MANAGE],
        },
      },
    },
    loadChildren: () => import('@modules/kiosk').then(m => m.KioskModule),
  },
  // Groups
  {
    path: GroupRoutes.ROOT,
    canActivate: [AuthGuard],
    data: {
      title: 'Groups',
      description: 'View your groups',
      navigationVisibility: true,
      icon: {
        default: 'mg-group-menu-o',
        active: 'mg-group-menu',
      },
    },
    loadChildren: () =>
      import('minga/app/src/app/groups/groups-routing.module').then(
        m => m.GroupsRoutingModule,
      ),
  },
  // Paused
  {
    path: 'paused',
    loadChildren: () =>
      import('minga/app/src/app/paused-route/PausedRoutes.module').then(
        m => m.PausedRoutesModule,
      ),
  },
  // Challenge
  {
    path: 'challenge',
    canActivate: [AuthGuard],
    data: {
      title: 'Challenges',
      description: 'View and manage your challenges',
      navigationVisibility: false,
      icon: {
        default: 'mg-home-menu',
        active: 'mg-home-menu',
      },
    },
    loadChildren: () =>
      import('@modules/challenges').then(m => m.ChallengesRouteModule),
  },
];
