import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import {
  AutomationAction,
  IActionThresholdAutomation,
} from 'minga/libraries/domain';
import { RootService } from 'src/app/minimal/services/RootService';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  AutomationActionsService,
  AutomationGroupService,
} from '@shared/services/automation';
import { MediaService } from '@shared/services/media';

import { BehaviorManagerRoutes } from '../../../../constants';
import { BehaviorManagerService } from '../../../../services';
import { BmTypesAutomationService } from '../../services';
import {
  EDIT_AUTOMATION_STEP_FORMGROUP,
  EditAutomationStepFields,
  EditAutomationStepMessages,
  RepeatEnd,
} from './bm-types-automation-step-edit.constants';

const widthAnimation = trigger('widthAnimation', [
  transition(':enter', [
    style({ width: 0 }),
    animate('300ms ease-in', style({ width: '*' })),
  ]),
]);

@Component({
  selector: 'mg-bm-types-automation-step-edit',
  templateUrl: './bm-types-automation-step-edit.component.html',
  styleUrls: ['./bm-types-automation-step-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [widthAnimation],
})
export class BmTypesAutomationStepEditComponent
  extends CrudFormBase<AutomationAction>
  implements OnDestroy, OnInit
{
  private _destroyed = new ReplaySubject<void>(1);
  public MESSAGES = EditAutomationStepMessages;
  public FORM_FIELDS = EditAutomationStepFields;

  private _automationGroupSubj =
    new BehaviorSubject<IActionThresholdAutomation>(null);
  public automationGroup$ = this._automationGroupSubj.asObservable();

  private _behaviorNameSubject = new BehaviorSubject<string>('');
  private readonly _behaviorNameSubscription = combineLatest([
    this._bmService.getBehaviorTypes(false),
    this.automationGroup$,
  ])
    .pipe(takeUntil(this._destroyed))
    .subscribe(([types, group]) => {
      const triggerId = group?.triggers?.pbisTriggers[0].id;
      const selectedType = types.find(type => type.id === triggerId);

      this._behaviorNameSubject.next(selectedType?.name ?? '');
    });

  public readonly consequenceOptions$ = this._bmService
    .getConsTypes(false)
    .pipe(
      takeUntil(this._destroyed),
      map(consequences => {
        return consequences
          .map(consequence => ({
            label: consequence.name,
            contextLabel: !consequence.active
              ? EditAutomationStepMessages.DISABLED_CONSEQUENCE_OPTION_LABEL
              : '',
            value: consequence.id,
            disabled: !consequence.active,
            type: consequence.type,
            category: consequence.categoryId,
            dueDate: consequence.enableDueDate,
          }))
          .sort((a, b) => {
            return +a.disabled - +b.disabled;
          });
      }),
    );

  public readonly form = this._fb.group(EDIT_AUTOMATION_STEP_FORMGROUP);

  public readonly disabledConsequenceWarning$ = combineLatest([
    this.consequenceOptions$,
    this.form.get(EditAutomationStepFields.CONSQUENCE_TO_ASSIGN).valueChanges,
  ]).pipe(
    map(([options, value]) => {
      return options.find(option => value === option.value)?.disabled;
    }),
  );

  public RepeatEnd = RepeatEnd;

  private _consequenceNameSubject = new BehaviorSubject<string>('');
  private readonly _consequenceNameSubscription = combineLatest([
    this.consequenceOptions$,
    this.form.controls[EditAutomationStepFields.CONSQUENCE_TO_ASSIGN]
      .valueChanges,
  ])
    .pipe(takeUntil(this._destroyed))
    .subscribe(([consequenceOptions, selectedValue]) => {
      const selectedOption = consequenceOptions.find(
        option => option.value === selectedValue,
      );
      const consequenceName = selectedOption ? selectedOption.label : '';
      this._consequenceNameSubject.next(consequenceName);
    });

  public get notesTemplateVariables() {
    return {
      behaviorCount:
        this.form.controls[EditAutomationStepFields.BEHAVIOR_THRESHOLD]
          ?.value ?? 0,
      automationGroupName:
        this.form.controls[EditAutomationStepFields.NAME]?.value ?? '',
      behaviorName: this._behaviorNameSubject.value,
      consequenceName: this._consequenceNameSubject.value,
    };
  }

  constructor(
    public rootService: RootService,
    public route: ActivatedRoute,
    public router: Router,
    public media: MediaService,
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _bmService: BehaviorManagerService,
    private _systemAlertModal: SystemAlertModalService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _automationActions: AutomationActionsService,
    private _bmAutomation: BmTypesAutomationService,
    private _router: Router,
    private _automationGroup: AutomationGroupService,
  ) {
    super({
      id: route.snapshot.params?.stepId,
      labels: {
        create: 'Add',
      },
      get: id => {
        return this._automationActions.fetch(id);
      },
      create: data => {
        return this._automationActions.create(data as any);
      },
      update: data => {
        return this._automationActions.update(data as any);
      },
      delete: async data => {
        await this._automationActions.delete(data.id);
        return data;
      },
      onSetForm: data => {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const control = this.form.controls[key];
            if (!control) continue;
            const value = data[key];
            control.setValue(value);
            control.markAsPristine();
            control.markAsUntouched();
          }
        }

        const repeatEnd = this.form.get(EditAutomationStepFields.REPEAT_END);
        if (data.additionalBehaviors > 0) {
          repeatEnd.setValue(RepeatEnd.AFTER);

          const additionalBehaviors = this.form.get(
            EditAutomationStepFields.ADDITIONAL_BEHAVIORS,
          );
          // need to add 1 to this since the form reads as total occurences to the user
          additionalBehaviors.setValue(data.additionalBehaviors + 1);
        } else repeatEnd.setValue(RepeatEnd.NEVER);

        this._cdr.markForCheck();
        this.form.markAsPristine();
        this.form.markAsUntouched();
      },
      onValidate: () => {
        Object.values(this.form.controls).forEach(control => {
          control.markAsTouched();
          control.updateValueAndValidity();
        });

        return this.form.valid;
      },
      onSuccess: (type, data) => {
        switch (type) {
          case 'get': {
            break;
          }
          case 'update': {
            this._systemAlertSnackBar.success(
              'Successfully updated automation step',
            );
            this._navigateAfterSave(data.automationId);
            break;
          }
          case 'create': {
            this._systemAlertSnackBar.success(
              'Successfully created automation step',
            );
            this._navigateAfterSave(data.automationId);
            break;
          }
          case 'delete': {
            this._systemAlertSnackBar.success(
              `Successfully deleted automation step`,
            );
            this._navigateAfterSave(data.automationId);
            break;
          }
          default: {
            this._navigateAfterSave(data.automationId);
            break;
          }
        }
      },
      onSubmit: data => {
        const repeatEnabled = this.form.get(
          EditAutomationStepFields.REPEAT_AUTOMATION,
        ).value;

        const additionalBehaviorsValue = this.form.get(
          EditAutomationStepFields.ADDITIONAL_BEHAVIORS,
        ).value;
        const additionalBehaviors =
          repeatEnabled && additionalBehaviorsValue > 1
            ? // need to remove 1 from since the form reads as total occurences to the user
              additionalBehaviorsValue - 1
            : null;
        const repeatEvery = repeatEnabled
          ? this.form.get(EditAutomationStepFields.REPEAT_FREQUENCY).value
          : null;

        return {
          ...data,
          ...this.form.value,
          additionalBehaviors,
          repeatEvery,
          automationId: data.automationId || route.snapshot.params?.groupId,
        };
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        this.onCancel();
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: 'Are you sure you want to delete this automation step?',
          closeBtn: 'Cancel',
          confirmActionBtn: 'Delete',
        });
        const response = await modalRef.afterClosed().toPromise();
        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
    });
  }

  ngOnInit() {
    this._fetchAutomationGroup();
    this.init();
    this._actionIdFromRouteSubscription();
    this.form
      .get(this.FORM_FIELDS.SEND_STUDENT_NOTE)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        this._onSendNoteToStudentChange(value);
      });

    this.form
      .get(this.FORM_FIELDS.REPEAT_END)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        this._onRepeatEndChange(value);
      });

    this.form
      .get(this.FORM_FIELDS.REPEAT_AUTOMATION)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        this._onRepeatEnabledChange(value);
      });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    this._consequenceNameSubject.complete();
    this._behaviorNameSubject.complete();
    this._consequenceNameSubscription.unsubscribe();
    this._behaviorNameSubscription.unsubscribe();
  }

  private _actionIdFromRouteSubscription() {
    return this.router.events
      .pipe(
        takeUntil(this._destroyed),
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const params = this.route.snapshot.params as {
            groupId: number;
            stepId: number | undefined;
          };
          return params?.stepId;
        }),
      )
      .subscribe(id => {
        this.init(id);
        this._fetchAutomationGroup();
      });
  }

  private async _navigateAfterSave(automationId: number) {
    this._bmAutomation.setUpdatedAutomationGroup(automationId);
    await this.router.navigate([
      BehaviorManagerRoutes.ROOT,
      BehaviorManagerRoutes.AUTOMATIONS,
    ]);
  }

  private _onSendNoteToStudentChange(value: boolean) {
    const studentNote = this.form.get(this.FORM_FIELDS.STUDENT_NOTE);
    if (value) {
      studentNote.setValidators([Validators.required]);
    } else {
      studentNote.setValidators(null);
    }

    studentNote.updateValueAndValidity();
  }

  public async onCancel() {
    const navigateToConsequences = () => {
      this._router.navigate([
        BehaviorManagerRoutes.ROOT,
        BehaviorManagerRoutes.AUTOMATIONS,
      ]);
    };

    if (this.form.pristine) {
      return navigateToConsequences();
    }

    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.WARNING,
      heading: EditAutomationStepMessages.DELETE_CONFIRM_DISCARD_TITLE,
      message: EditAutomationStepMessages.DELETE_CONFIRM_DISCARD_DESC,
      closeBtn: EditAutomationStepMessages.DELETE_CONFIRM_CANCEL_BTN,
      confirmActionBtn: EditAutomationStepMessages.DELETE_CONFIRM_DISCARD_BTN,
    });

    const response = await modalRef.afterClosed().toPromise();

    if (response?.type === SystemAlertCloseEvents.CONFIRM) {
      return navigateToConsequences();
    }
  }

  private _onRepeatEndChange(value: RepeatEnd) {
    const additionalBehaviors = this.form.get(
      this.FORM_FIELDS.ADDITIONAL_BEHAVIORS,
    );
    if (value === RepeatEnd.AFTER) {
      additionalBehaviors.setValidators([
        Validators.required,
        Validators.min(2),
      ]);
    } else {
      additionalBehaviors.setValidators(null);
      additionalBehaviors.setValue(null);
    }
  }
  private _onRepeatEnabledChange(value: boolean) {
    const repeatFrequency = this.form.get(this.FORM_FIELDS.REPEAT_FREQUENCY);
    if (value) {
      repeatFrequency.setValidators([Validators.required, Validators.min(1)]);
    } else {
      repeatFrequency.setValidators(null);
    }
  }

  private async _fetchAutomationGroup() {
    const currentGroupId = this._automationGroupSubj.value?.id;
    // don't need to refetch if it's the same group
    if (currentGroupId === +this.route.snapshot.params?.groupId) return;

    try {
      const group = await this._automationGroup.fetch(
        this.route.snapshot.params?.groupId,
      );
      this._automationGroupSubj.next(group);
      // we can fail silently, we just getting the group to display the name
    } catch (error) {}
  }
}
