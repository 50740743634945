import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Person } from 'minga/app/src/app/people';
import { HallPassStudentCreatedFilter } from 'minga/libraries/domain';
import { HpmReportsFilters } from 'minga/libraries/domain';
import { HallPassService } from 'src/app/services/HallPass';

import { HPM_CREATED_BY_OPTIONS } from '@modules/hallpass-manager';
import {
  ScheduledReportEditFormFields,
  getFilterGroupControlKey,
} from '@modules/minga-manager/components/mm-scheduled-reports/constants';
import {
  ClientSupportedScheduledReportFilter,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { UserListCategory } from '@shared/components/user-list-filter/user-list.types';

import { setIssuedOnPeopleSelect } from '../../utils/mm-sr-reports-edit.utils';
import {
  HallPassReportFields,
  HallPassReportMessages,
} from './sr-hall-pass-filters.constants';

@Component({
  selector: 'mg-sr-hall-pass-filters',
  templateUrl: './sr-hall-pass-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrHallPassFiltersComponent implements OnInit, OnDestroy {
  private _defaultIssuedHashesSubject = new BehaviorSubject<string[]>([]);
  public defaultIssuedHashes$ = this._defaultIssuedHashesSubject.asObservable();
  private _destroyed$ = new ReplaySubject<void>(1);
  private _filtersSubject = new BehaviorSubject<
    Record<HallPassReportFields, any>
  >(null);
  public filters$ = this._filtersSubject.asObservable();
  public MESSAGES = HallPassReportMessages;
  public FIELDS = HallPassReportFields;
  public PARENT_FIELDS = ScheduledReportEditFormFields;
  public readonly CREATED_BY_OPTIONS = HPM_CREATED_BY_OPTIONS;

  public UserListCategory = UserListCategory;

  public hallpassTypeOptions$ = this._hallpassService.getHallPassTypes().pipe(
    takeUntil(this._destroyed$),
    map(types =>
      types.map(type => ({
        label: type.name,
        value: type.id,
      })),
    ),
  );

  public getFilterGroupControlKey = getFilterGroupControlKey;
  @Input() public form: FormGroup;
  @Input() defaultValues$: Observable<ClientSupportedScheduledReportFilter>;

  constructor(private _hallpassService: HallPassService) {}

  ngOnInit(): void {
    // wait till we have all the dropdown data before setting values on it
    combineLatest([this.defaultValues$, this.hallpassTypeOptions$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([defaultValues]) => {
        this._onReady(defaultValues);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onPersonSelected(people: Partial<Person>[]) {
    setIssuedOnPeopleSelect(people, this.form, {
      reportType: ScheduledReportType.HALL_PASS_HISTORY,
      issuedToKey: this.FIELDS.ISSUED_TO,
      issuedByKey: this.FIELDS.ISSUED_BY,
    });
  }

  private _onReady(defaultValues: ClientSupportedScheduledReportFilter) {
    const {
      issuedTo = [],
      issuedBy = [],
      createdBy = HallPassStudentCreatedFilter.ALL,
      hallpassType = [],
      userList = [],
    } = (defaultValues?.filters || {}) as HpmReportsFilters;

    this._defaultIssuedHashesSubject.next([...issuedTo, ...issuedBy]);

    this._filtersSubject.next({
      [HallPassReportFields.ISSUED_TO]: [issuedTo],
      [HallPassReportFields.ISSUED_BY]: [issuedBy],
      [HallPassReportFields.CREATED_BY]: [createdBy],
      [HallPassReportFields.HALLPASS_TYPE]: [hallpassType],
      [HallPassReportFields.USER_LIST]: [userList],
    });
  }
}
