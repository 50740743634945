import { SisConfig } from 'minga/libraries/domain';
import { SisIdFieldKey } from 'minga/libraries/domain';
import { MgValidators } from 'src/app/input/validators';

import { FormSelectOption } from '@shared/components/form';

export enum OneRosterMessages {
  INFO_TITLE = 'ABOUT OneRoster 1.1',
  DETAILS_MSG = 'When using OneRoster, your Student Information System must be setup to allow Minga to connect using the OneRoster API. Each evening Minga will request new roster information from your Student Information System and update Minga rosters. ',
  HELP_MSG = 'Please refer to the following Knowledge Base Article to ensure OneRoster is setup properly for your SIS.',
  KB_LINK = 'https://support.minga.io/knowledge/setting-up-automatic-rostering-with-your-sis',
}

export enum SFTPMessages {
  INFO_TITLE = 'ABOUT SFTP',
  KB_LINK = 'https://support.minga.io/knowledge/setting-up-sftp-for-rostering',
  CREDENTIALS_MSG = 'IMPORTANT: make sure to save these credentials. They will not be shown again, and you will have to delete your settings to regenerate your sftp account to get new credentials',
  DETAILS_MSG = 'When using SFTP you must configure your Student Information System to send student and staff roster files to Minga (using the information below).',
  HELP_MSG = 'IMPORTANT: Refer to the following SFTP Knowledge article to ensure these files are setup correctly.',
}

export enum SisMessages {
  TOOLTIP_SYNC_STATUS = 'Toggle this to make syncs active or inactive',
  TOOLTIP_UPDATE_USERS = 'If existing Minga users have changes in your SIS sync, selecting this will automatically update them',
  TOOLTIP_ARCHIVE_USERS = 'Remove student, student leaders, and teacher roles when they do not appear in the data returned from SIS',
  TOOLTIP_ADD_EMAIL_LIST = 'Send email notification about rostering status to email addresses listed',

  EMAIL_LIST_LABEL = 'Send rostering results email',
  EMAIL_LIST_PLACEHOLDER = '[Email address list]',
  EMAIL_LIST_ADD_LABEL = 'Add email',

  BUTTON_RESET = 'Reset',
  BUTTON_SAVE = 'Save',
  BUTTON_MANAGE = 'Manage',
  BUTTON_DELETE = 'Delete',
  BUTTON_TEST = 'Test connection / URL',
  BUTTON_RUN_DAILY = 'Run daily sync',
  BUTTON_RUN_WEEKLY = 'Run weekly sync',

  TITLE_ADD_EMAIL_LIST = 'Send rostering results email',
  TITLE_ROSTER_STUDENTS = 'Roster students',
  TITLE_ROSTER_PARENTS = 'Include primary parent contact info',
  TITLE_ROSTER_TEACHERS = 'Roster teachers',
  TITLE_ROSTER_STAFF = 'Roster staff',
  TITLE_ROSTER_ADMIN = 'Roster admin',
  TITLE_ROSTER_CLASS_LISTS = 'Sync class rosters as user lists automatically',
  TITLE_MANAGE_CLASS_LISTS = 'Manage class rosters to be synced',

  TOOLTIP_ROSTER_CLASS_LISTS = 'Sync class lists as user lists automatically. Student and teacher rosters must be enabled to import class lists.',
  TOOLTIP_ROSTER_STUDENTS = 'Update student data via SIS',
  TOOLTIP_ROSTER_PARENTS = 'Sync parent contact information via SIS',
  TOOLTIP_ROSTER_TEACHERS = 'Update teacher data via SIS',
  TOOLTIP_ROSTER_STAFF = 'Update staff data via SIS',
  TOOLTIP_ROSTER_ADMIN = 'Update admin data via SIS',

  TOOLTIP_SCHOOL_ID = 'Separate multiple school IDs with a comma',
  TITLE_AUTO_UPDATE = 'Auto update',
  TITLE_ARCHIVE = 'Archive',

  TOOLTIP_AUTO_UPDATE_STUDENTS = 'Students will be automatically updated during SIS sync if they have changes',
  TOOLTIP_AUTO_UPDATE_TEACHERS = 'Teachers will be automatically updated during SIS sync if they have changes',
  TOOLTIP_AUTO_UPDATE_STAFF = 'Staff will be automatically updated during SIS sync if they have changes',
  TOOLTIP_AUTO_UPDATE_ADMIN = 'Admins will be automatically updated during SIS sync if they have changes',

  TOOLTIP_ARCHIVE_STUDENTS = "Students will be archived if they don't appear in the data returned from SIS",
  TOOLTIP_ARCHIVE_TEACHERS = "Teachers will be archived if they don't appear in the data returned from SIS",
  TOOLTIP_ARCHIVE_STAFF = "Staff will be archived if they don't appear in the data returned from SIS",
  TOOLTIP_ARCHIVE_ADMIN = "Admin will be archived if they don't appear in the data returned from SIS",

  PLACEHOLDER_SA_TEST_URL = 'SA Test URL',

  DELETE_MSG = 'Are you sure you want to delete the configuration details and disable rostering?',
}

export enum SFTPCredentialType {
  HOST = 'SFTP Host',
  USERNAME = 'SFTP Username',
  PASSWORD = 'SFTP Password',
}

export enum SisSettingsNames {
  SIS_URL = 'SIS_URL',
  SIS_CLIENT_ID = 'SIS_CLIENT_ID',
  SIS_CLIENT_SECRET = 'SIS_CLIENT_SECRET',
}

export const SIS_TYPE_OPTIONS: FormSelectOption<SisConfig.SisType>[] = [
  // { label: SisType.POWERSCHOOL.valueOf(), value: SisType.POWERSCHOOL },
  {
    label: SisConfig.SisType.SKYWARD.valueOf(),
    value: SisConfig.SisType.SKYWARD,
  },
  {
    label: SisConfig.SisType.AERIES.valueOf(),
    value: SisConfig.SisType.AERIES,
  },
  //  { label: SisType.SYNERGY.valueOf(), value: SisType.SYNERGY },
  { label: SisConfig.SisType.Q.valueOf(), value: SisConfig.SisType.Q },
  {
    label: SisConfig.SisType.CLEVER.valueOf(),
    value: SisConfig.SisType.CLEVER,
  },
  {
    label: SisConfig.SisType.CLASSLINK.valueOf(),
    value: SisConfig.SisType.CLASSLINK,
  },
  {
    label: SisConfig.SisType.INFINITE_CAMPUS.valueOf(),
    value: SisConfig.SisType.INFINITE_CAMPUS,
  },
  {
    label: SisConfig.SisType.ROSTERSTREAM.valueOf(),
    value: SisConfig.SisType.ROSTERSTREAM,
  },
  { label: SisConfig.SisType.OTHER.valueOf(), value: SisConfig.SisType.OTHER },
];

export enum InputSettingFormFields {
  URL = 'url',
  CLIENT_ID = 'clientId',
  CLIENT_SECRET = 'clientSecret',
  AUTH_URL = 'authUrl',
  STUDENT_REQUEST_URL = 'studentUrl',
  TEACHER_REQUEST_URL = 'teacherUrl',
  APPLICATION_ID = 'applicationId',
  ACCESS_TOKEN = 'accessToken',
  SCHOOL_ID = 'schoolId',
  ID_NUMBER_FIELD_NAME = 'idNumberFieldName',
  NOTIFICATION_EMAILS = 'notificationEmails',
  TERM_SOURCED_IDS = 'termSourcedIds',
  GRADES = 'grades',
}

export const INPUT_SIS_SETTING_TYPES = {
  [InputSettingFormFields.URL]: [''],
  [InputSettingFormFields.CLIENT_ID]: [''],
  [InputSettingFormFields.CLIENT_SECRET]: [''],
  [InputSettingFormFields.AUTH_URL]: [''],
  [InputSettingFormFields.STUDENT_REQUEST_URL]: [''],
  [InputSettingFormFields.TEACHER_REQUEST_URL]: [''],
  [InputSettingFormFields.APPLICATION_ID]: [''],
  [InputSettingFormFields.ACCESS_TOKEN]: [''],
  [InputSettingFormFields.SCHOOL_ID]: [''],
  [InputSettingFormFields.ID_NUMBER_FIELD_NAME]: [''],
  [InputSettingFormFields.NOTIFICATION_EMAILS]: [
    [],
    MgValidators.allValuesAsEmailsValidator,
  ],
  [InputSettingFormFields.TERM_SOURCED_IDS]: [null],
  [InputSettingFormFields.GRADES]: [null],
};

export enum ToggleSettingFormFields {
  UPDATE_STUDENT_ROSTERING = 'studentRostering',
  UPDATE_PARENT_ROSTERING = 'parentRostering',
  UPDATE_EXISTING_STUDENTS = 'updateExistingStudents',
  ARCHIVE_MISSING_STUDENTS = 'archiveMissingStudents',
  UPDATE_TEACHER_ROSTERING = 'teacherRostering',
  UPDATE_EXISTING_TEACHERS = 'updateExistingTeachers',
  ARCHIVE_MISSING_TEACHERS = 'archiveMissingTeachers',
  UPDATE_STAFF_ROSTERING = 'staffRostering',
  UPDATE_EXISTING_STAFF = 'updateExistingStaff',
  ARCHIVE_MISSING_STAFF = 'archiveMissingStaff',
  UPDATE_ADMIN_ROSTERING = 'adminRostering',
  UPDATE_EXISTING_ADMINS = 'updateExistingAdmins',
  ARCHIVE_MISSING_ADMINS = 'archiveMissingAdmins',
  UPDATE_CLASS_LIST_ROSTERING = 'classListRostering',
}

export const TOGGLE_SIS_SETTING_TYPES = {
  [ToggleSettingFormFields.UPDATE_STUDENT_ROSTERING]: [false],
  [ToggleSettingFormFields.UPDATE_PARENT_ROSTERING]: [false],
  [ToggleSettingFormFields.UPDATE_EXISTING_STUDENTS]: [false],
  [ToggleSettingFormFields.ARCHIVE_MISSING_STUDENTS]: [false],
  [ToggleSettingFormFields.UPDATE_TEACHER_ROSTERING]: [false],
  [ToggleSettingFormFields.UPDATE_EXISTING_TEACHERS]: [false],
  [ToggleSettingFormFields.ARCHIVE_MISSING_TEACHERS]: [false],
  [ToggleSettingFormFields.UPDATE_STAFF_ROSTERING]: [false],
  [ToggleSettingFormFields.UPDATE_EXISTING_STAFF]: [false],
  [ToggleSettingFormFields.ARCHIVE_MISSING_STAFF]: [false],
  [ToggleSettingFormFields.UPDATE_ADMIN_ROSTERING]: [false],
  [ToggleSettingFormFields.UPDATE_EXISTING_ADMINS]: [false],
  [ToggleSettingFormFields.ARCHIVE_MISSING_ADMINS]: [false],
  [ToggleSettingFormFields.UPDATE_CLASS_LIST_ROSTERING]: [false],
};

export const TOGGLE_SIS_SETTING_RESET = {
  [ToggleSettingFormFields.UPDATE_STUDENT_ROSTERING]: false,
  [ToggleSettingFormFields.UPDATE_PARENT_ROSTERING]: false,
  [ToggleSettingFormFields.UPDATE_EXISTING_STUDENTS]: false,
  [ToggleSettingFormFields.ARCHIVE_MISSING_STUDENTS]: false,
  [ToggleSettingFormFields.UPDATE_TEACHER_ROSTERING]: false,
  [ToggleSettingFormFields.UPDATE_EXISTING_TEACHERS]: false,
  [ToggleSettingFormFields.ARCHIVE_MISSING_TEACHERS]: false,
  [ToggleSettingFormFields.UPDATE_STAFF_ROSTERING]: false,
  [ToggleSettingFormFields.UPDATE_EXISTING_STAFF]: false,
  [ToggleSettingFormFields.ARCHIVE_MISSING_STAFF]: false,
  [ToggleSettingFormFields.UPDATE_ADMIN_ROSTERING]: false,
  [ToggleSettingFormFields.UPDATE_EXISTING_ADMINS]: false,
  [ToggleSettingFormFields.ARCHIVE_MISSING_ADMINS]: false,
  [ToggleSettingFormFields.UPDATE_CLASS_LIST_ROSTERING]: false,
};

export const SIS_FIELD_KEYS_OPTIONS: FormSelectOption<SisIdFieldKey>[] = [
  { label: SisIdFieldKey.IDENTIFIER, value: SisIdFieldKey.IDENTIFIER },
  { label: SisIdFieldKey.SOURCE_ID, value: SisIdFieldKey.SOURCE_ID },
  { label: SisIdFieldKey.USERNAME, value: SisIdFieldKey.USERNAME },
  { label: SisIdFieldKey.STATE_ID, value: SisIdFieldKey.STATE_ID },
  { label: SisIdFieldKey.SIS_ID, value: SisIdFieldKey.SIS_ID },
];
