import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';

import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

import { FileInputUploadComponent } from 'minga/app/src/app/components/Input/FileInputUpload';
import { CancelConfirmationDialogComponent } from 'minga/app/src/app/dialog/CancelConfirmation';
import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import {
  IMessagingSettingsItem,
  MessagingSettingsService,
} from 'minga/app/src/app/modules/direct-message/services';
import { PermissionsService } from 'minga/app/src/app/permissions';
import {
  ISelectRoleSetting,
  roleInfoToSelect,
} from 'minga/app/src/app/roles/components/MultiSelectRole';
import { IRoleInfo, RolesService } from 'minga/app/src/app/roles/services';
import { HallPassService } from 'minga/app/src/app/services/HallPass';
import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';
import { MingaManagerService } from 'minga/app/src/app/services/MingaManager';
import { PointsManagerService } from 'minga/app/src/app/services/PointsManager';
import { SmsMinga } from 'minga/app/src/app/services/SmsMinga';
import { MingaStoreFacadeService } from 'minga/app/src/app/store/Minga/services/MingaStoreFacade.service';
import { Color } from 'minga/app/src/app/util/color';
import { dateMessageObjectToDateObject } from 'minga/app/src/app/util/date';
import { IMingaAccountInfo } from 'minga/app/src/app/util/minga';
import { day } from 'minga/libraries/day';
import { IMingaFeatureToggleKeys } from 'minga/libraries/domain';
import { IMingaSubscription } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { ISmsRecipientDetails } from 'minga/libraries/shared';
import { mingaSettingTypes, MingaTypeValue } from 'minga/libraries/util';
import { ContentCleanup } from 'minga/proto/content/cleanup_ng_grpc_pb';
import { ResetMingaContentRequest } from 'minga/proto/content/cleanup_pb';
import {
  MingaDashboardInfo,
  MingaFeatureToggle,
} from 'minga/proto/gateway/minga_pb';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { MingaManagerMessages } from '@modules/minga-manager/constants';
import { MMSettingService } from '@modules/minga-manager/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinService } from '@shared/services/checkin';
import { PbisService } from '@shared/services/pbis';

import { MmDatePresetsService } from '../mm-date-presets/services/mm-date-presets.service';

const _getPercentageString = (value: number, total: number) => {
  const percentVal = value / total;
  // get width percent, ensuring it's not over 100%
  const percent = (percentVal > 1 ? 1 : percentVal) * 100;
  return `${percent}%`;
};

@Component({
  selector: 'mg-mm-settings',
  templateUrl: './mm-settings.component.html',
  styleUrls: ['./mm-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MmDatePresetsService, MMSettingService],
})
export class MmSettingsComponent implements OnInit, OnDestroy {
  /** Messages */
  public readonly MESSAGES = MingaManagerMessages;
  public readonly MINGA_TYPE_VALUE = MingaTypeValue;
  public readonly FEATURE_TOGGLE_TYPES = IMingaFeatureToggleKeys;
  public readonly MINGA_TYPE = MingaTypeValue;

  public dateMessageObjectToDateObject = dateMessageObjectToDateObject;
  public isSuperAdmin = false;

  public mingaInfo$ = new BehaviorSubject<MingaDashboardInfo.AsObject>(null);
  private _mingaInfoStream?: { cancel(): void } & Observable<IMingaAccountInfo>;
  private _destroyed$ = new ReplaySubject<void>(1);

  public mingaDashboardInfo$ = this._mmSettingService.mingaDashboardInfo$;
  public mingaFeatureToggles$ = this._mmSettingService.mingaFeatureToggles$;
  public subscriptionInfo$: Observable<IMingaSubscription>;
  public peopleProgressWidth$: Observable<string>;

  public settingsService: MingaSettingsService;
  public featureToggles: MingaFeatureToggle.AsObject;

  daysTillPayment$: Observable<number | null>;
  defaultMingaRole$: Observable<IRoleInfo | undefined>;
  memberDisplayName$: Observable<string>;
  memberBarStyle$: Observable<SafeStyle>;

  availableRoles$: Observable<ISelectRoleSetting[]>;
  availableGalleryRoles$: Observable<ISelectRoleSetting[]>;
  availableVideoUploadRoles$: Observable<ISelectRoleSetting[]>;
  availableCommentRoles$: Observable<ISelectRoleSetting[]>;
  availableGroupCreateRoles$: Observable<ISelectRoleSetting[]>;
  mainFeedRoles$: Observable<string[]>;
  galleryRoles$: Observable<string[]>;
  videoUploadRoles$: Observable<string[]>;
  commentRoles$: Observable<string[]>;
  groupCreateRoles$: Observable<string[]>;
  availablePublicJoinableRoles$: Observable<ISelectRoleSetting[]>;
  publicJoinableRoles$: Observable<string[]>;

  readonly dmSettings$: Observable<IMessagingSettingsItem[]>;

  @ViewChild('studentIdFileInputUpload', { static: false })
  studentIdFileInputUpload?: FileInputUploadComponent;
  smsDetails: ISmsRecipientDetails | null = null;
  smsDetailsFetchError = false;
  smsBarStyle: SafeStyle = '';

  readonly mingaSettingTypes = mingaSettingTypes;

  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private dialog: MatDialog,
    private mingaService: MingaManagerService,
    private rootService: RootService,
    private _cdr: ChangeDetectorRef,
    private linkOpener: LinkOpenerService,
    private router: Router,
    private rolesService: RolesService,
    private permissions: PermissionsService,
    private sanitizer: DomSanitizer,
    private messagingSettings: MessagingSettingsService,
    private _mingaStore: MingaStoreFacadeService,
    public appConfig: AppConfigService,
    private smsMinga: SmsMinga,
    private hallPassService: HallPassService,
    private pbisService: PbisService,
    private contentCleanup: ContentCleanup,
    private checkinService: CheckinService,
    private _settingService: MingaSettingsService,
    private _mmSettingService: MMSettingService,
    private _pointsManager: PointsManagerService,
    private _mmDatePresetsService: MmDatePresetsService,
    private _analyticsService: AnalyticsService,
  ) {
    this.dmSettings$ = this.messagingSettings.getRoleMessagingSettings();

    this.settingsService = this._settingService;

    this.mingaDashboardInfo$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(mingaInfo => {
        if (mingaInfo) {
          this.mingaInfo$.next(mingaInfo);
        }
      });

    this.mingaFeatureToggles$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        this.featureToggles = value;
      });

    this.peopleProgressWidth$ = this.mingaDashboardInfo$.pipe(
      map(mingaInfo => {
        const total = mingaInfo.mingaSize || 0;
        const billableCount = mingaInfo.billableCount;
        return _getPercentageString(billableCount, total);
      }),
    );

    this.daysTillPayment$ = this.mingaDashboardInfo$.pipe(
      map(mingaInfo => {
        if (mingaInfo.subscriptionEndDate) {
          const payDate = dateMessageObjectToDateObject(
            mingaInfo.subscriptionEndDate,
          );
          const momentPayDate = day(payDate);
          return Math.ceil(momentPayDate.diff(day(), 'd', true));
        }
        return undefined;
      }),
    );

    this.defaultMingaRole$ = this.mingaDashboardInfo$.pipe(
      mergeMap(mingaInfo =>
        mingaInfo.defaultRoleType
          ? this.rolesService.getRoleByType(mingaInfo.defaultRoleType)
          : undefined,
      ),
    );

    this.memberDisplayName$ = this.defaultMingaRole$.pipe(
      map(defaultRole =>
        typeof defaultRole == 'string' ? 'Members' : `${defaultRole.name}s`,
      ),
    );

    this.memberBarStyle$ = combineLatest([
      this.defaultMingaRole$,
      this.mingaDashboardInfo$,
    ]).pipe(
      map(([role, mingaInfo]) => {
        const width = _getPercentageString(
          mingaInfo.billableCount,
          mingaInfo.mingaSize,
        );
        const roleColor = typeof role !== 'string' ? role.iconColor : '';
        return this.getMemberBarStyle(roleColor, width);
      }),
    );

    const roleInfoToSelectMainFeed = (r: IRoleInfo, disabled: boolean) => {
      const tooltip = r.capabilitiesDescription + ` to the main feed`;
      const setting = {
        ...roleInfoToSelect(r, disabled),
        tooltip: disabled ? '' : tooltip,
      };

      return setting;
    };

    this.availableRoles$ = this.rolesService.availableFeedPermitableRoles$.pipe(
      map(roles =>
        roles.map(r => roleInfoToSelectMainFeed(r, r.immutableFeedPermittable)),
      ),
    );

    // All roles for the minga to be shown, disable the ones that are immutable
    this.availableGalleryRoles$ =
      this.rolesService.availableGalleryPermitableRoles$.pipe(
        map(roles =>
          roles.map(r => roleInfoToSelect(r, r.immutableGalleryPermittable)),
        ),
      );

    this.availableVideoUploadRoles$ =
      this.rolesService.availableVideoUploadPermitableRoles$.pipe(
        map(roles =>
          roles.map(r =>
            roleInfoToSelect(r, r.immutableVideoUploadPermittable),
          ),
        ),
      );
    this.availableCommentRoles$ =
      this.rolesService.availableCommentPermitableRoles$.pipe(
        map(roles =>
          roles.map(r => roleInfoToSelect(r, r.immutableCommentPermittable)),
        ),
      );

    this.availableGroupCreateRoles$ =
      this.rolesService.availableGroupCreatePermittableRoles$.pipe(
        map(roles =>
          roles.map(r =>
            roleInfoToSelect(r, r.immutableGroupCreatePermittable),
          ),
        ),
      );

    this.mainFeedRoles$ = this.rolesService.feedPermitableRoles$.pipe(
      map(roles => roles.map(r => r.roleType)),
    );

    this.galleryRoles$ = this.rolesService.galleryPermitableRoles$.pipe(
      map(roles => roles.map(r => r.roleType)),
    );

    this.videoUploadRoles$ = this.rolesService.videoUploadPermitableRoles$.pipe(
      map(roles => roles.map(r => r.roleType)),
    );

    this.commentRoles$ = this.rolesService.commentPermitableRoles$.pipe(
      map(roles => roles.map(r => r.roleType)),
    );

    this.groupCreateRoles$ =
      this.rolesService.groupCreatePermittableRoles$.pipe(
        map(roles => roles.map(r => r.roleType)),
      );

    this.publicJoinableRoles$ = this.rolesService.publicJoinableRoles$.pipe(
      map(roles => roles.map(r => r.roleType)),
    );

    this.availablePublicJoinableRoles$ = combineLatest(
      this.rolesService.availablePublicJoinableRoles$,
      this.publicJoinableRoles$,
    ).pipe(
      map(([roles, selectedRoles]) => {
        const disabled = (r: IRoleInfo) =>
          selectedRoles.length === 1 && selectedRoles.includes(r.roleType);
        return roles.map(r => roleInfoToSelect(r, disabled(r)));
      }),
    );
  }

  ngOnInit() {
    this.initMingaInfo();
    // Fetching the roles just in case it was updated since our last visit
    this.rolesService.fetch();
    this.isSuperAdmin = this.permissions.hasPermission(
      MingaPermission.SUPERADMIN,
    );
  }

  ngOnDestroy() {
    if (this._mingaInfoStream) {
      this._mingaInfoStream.cancel();
      delete this._mingaInfoStream;
    }

    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get isBrowser() {
    return window.MINGA_APP_BROWSER;
  }

  get isMingaSchool$() {
    return this.mingaDashboardInfo$.pipe(
      map(info => info.mingaType === MingaTypeValue.MINGA),
    );
  }

  selectMainFeedRole(roleType: string) {
    this.rolesService.setFeedPermittableRole(roleType, true);
  }

  deselectMainFeedRole(roleType: string) {
    this.rolesService.setFeedPermittableRole(roleType, false);
  }

  selectGalleryFeedRole(roleType: string) {
    this.rolesService.setGalleryPermittableRole(roleType, true);
  }

  deselectGalleryFeedRole(roleType: string) {
    this.rolesService.setGalleryPermittableRole(roleType, false);
  }

  selectVideoUploadRole(roleType: string) {
    this.rolesService.setVideoUploadPermittableRole(roleType, true);
  }

  deselectVideoUploadRole(roleType: string) {
    this.rolesService.setVideoUploadPermittableRole(roleType, false);
  }

  selectCommentRole(roleType: string) {
    this.rolesService.setCommentPermittableRole(roleType, true);
  }

  deselectCommentRole(roleType: string) {
    this.rolesService.setCommentPermittableRole(roleType, false);
  }

  selectGroupCreateRole(roleType: string) {
    this.rolesService.setGroupCreatePermittableRole(roleType, true);
  }

  deselectGroupCreateRole(roleType: string) {
    this.rolesService.setGroupCreatePermittableRole(roleType, false);
  }

  selectPublicJoinableRole(roleType: string) {
    this.rolesService.setJoinViaCodeRole(roleType, true);
  }

  deselectPublicJoinableRole(roleType: string) {
    this.rolesService.setJoinViaCodeRole(roleType, false);
  }

  async initMingaInfo() {
    this.mingaInfo$.pipe(takeUntil(this._destroyed$)).subscribe(info => {
      if (!info) {
        return;
      }
    });
  }

  preventDefaultEvent(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
  }

  onManagePlan() {
    const mingaInfo = this.mingaInfo$.getValue();

    if (mingaInfo) {
      const { subscriptionPortalUrl } = mingaInfo;
      this.linkOpener.open(subscriptionPortalUrl);
    }
  }

  cancelEval() {
    const options = {
      data: {
        title: 'Cancel Evaluation',
        text: `Your evaluation will end and your Minga will be deleted. Are you sure you would like to proceed?`,
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([{ outlets: { o: ['account', 'cancel-eval'] } }], {
          skipLocationChange: true,
        });
      }
    });
  }

  async showSnackbarSuccess(message: string) {
    this._systemAlertSnackBar.success(message);
  }

  async updateSetting(settingName: mingaSettingTypes, value: boolean) {
    await this._settingService.updateSetting(settingName, value);

    this._cdr.detectChanges();

    this.showSnackbarSuccess(`Setting updated successfully`);
  }

  async updateFeatureToggle(
    settingName: IMingaFeatureToggleKeys,
    value: boolean,
  ) {
    this.featureToggles[settingName] = value;
    await this._settingService.updateFeatureToggle(settingName, value);

    this._cdr.detectChanges();

    this.showSnackbarSuccess(`Setting updated successfully`);
  }

  async onBellScheduleUpdate(enabled: boolean) {
    this.showSnackbarSuccess(`Setting updated successfully`);
    // if bell schedule is disabled lets not let them use test mode since it relies on bell schedule
    if (!enabled) {
      await this._settingService.updateSetting(
        mingaSettingTypes.BELL_SCHEDULE_ENABLE_FOR_TEACHERS_STUDENTS,
        false,
      );
    }
  }

  async updateMessagingSettings(roleGroups: string[][], enabled: boolean) {
    await this.messagingSettings.setRoleMessagingSettings(roleGroups, enabled);
  }

  getMemberBarStyle(roleColor: string, width: string) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `${this._getMemberBarGradient(roleColor)} width: ${width};`,
    );
  }

  private _getMemberBarGradient(_color: string) {
    // default to color in design specs
    const color = _color || '#70B2D7';
    const darkenedColor = Color(color).darken(0.5);

    return `--member-bar-progress-color1: ${darkenedColor}; --member-bar-progress-color2: ${color};`;
  }

  getStudentCount(subscription: IMingaSubscription): string | number {
    if (!subscription) return 0;

    if (subscription.mingaSize) {
      return subscription.mingaSize;
    }

    return 'unlimited';
  }

  async setupHallPassModule() {
    const mingaInfo = this.mingaInfo$.getValue();
    const mingaHash = mingaInfo?.hash;
    if (mingaHash) {
      const errorMessage =
        await this.hallPassService.addDefaultHallPassTypesToMinga(mingaHash);

      if (errorMessage) {
        this._systemAlertSnackBar.error(errorMessage);
      }
    }
  }

  /**
   * Set up PBIS Module
   */
  async setupPbisModule() {
    const mingaInfo = this.mingaInfo$.getValue();
    const mingaHash = mingaInfo?.hash;
    if (mingaHash) {
      const errorMessage = await this.pbisService.addDefaultPbisTypes(
        mingaHash,
      );

      if (errorMessage) {
        this._systemAlertSnackBar.error(errorMessage);
      }
    }
  }

  /**
   * Set up PBIS Module
   */
  async setupCheckinModule() {
    const mingaInfo = this.mingaInfo$.getValue();
    const mingaHash = mingaInfo?.hash;
    if (mingaHash) {
      const err = await this.checkinService.addDefaultCheckinReasonsToMinga(
        mingaHash,
      );
      if (err) {
        this._systemAlertSnackBar.error(err);
      }
    }
  }
  async resetGroups() {
    const options = {
      data: {
        title: 'Are you sure?',
        text: 'Resetting all groups is permanent.',
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.mingaService.resetGroups();
          this._systemAlertSnackBar.success('Success!');
        } catch {
          this._systemAlertSnackBar.error(
            'Resetting groups failed. Please try again later.',
          );
        }
      }
    });
  }
  async resetPoints() {
    const options = {
      data: {
        title: 'Are you sure?',
        text: 'Resetting all points is permanent.',
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.mingaService.resetPoints();
          this._systemAlertSnackBar.success('Success!');
        } catch {
          this._systemAlertSnackBar.error(
            'Resetting points failed. Please try again later.',
          );
        }
      }
    });
  }
  async resetPointsHistory() {
    const options = {
      data: {
        title: 'Are you sure?',
        text: 'Resetting all points is permanent.',
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this._pointsManager.resetMingaPointsHistory();
          this._systemAlertSnackBar.success('Success!');
        } catch {
          this._systemAlertSnackBar.error(
            'Resetting points failed. Please try again later.',
          );
        }
      }
    });
  }
  async resetAutomationCounters() {
    const options = {
      data: {
        title: 'Are you sure?',
        text: 'Resetting all automation counters is permanent.',
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.mingaService.resetAutomationCounters();
          this._systemAlertSnackBar.success('Success!');
        } catch {
          this._systemAlertSnackBar.error(
            'Resetting automation counters failed. Please try again later.',
          );
        }
      }
    });
  }
  async resetGroupsMembers() {
    const options = {
      data: {
        title: 'Are you sure?',
        text: 'Resetting group members is permanent.',
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.mingaService.resetGroupsMembers();
          this._systemAlertSnackBar.success('Success!');
        } catch {
          this._systemAlertSnackBar.error(
            'Resetting group members failed. Please try again later.',
          );
        }
      }
    });
  }

  async resetUserLists() {
    const options = {
      data: {
        title: 'Are you sure?',
        text: 'Resetting all user lists is permanent.',
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.mingaService.resetUserLists();
          this._systemAlertSnackBar.success('Success!');
        } catch {
          this._systemAlertSnackBar.error(
            'Resetting user lists failed. Please try again later.',
          );
        }
      }
    });
  }

  async resetMingaContent() {
    const options = {
      data: {
        title: 'Are you sure?',
        text: "Resetting the Minga's content is permanent. This will delete all feed content.",
      },
    };

    const dialogRef = this.dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.contentCleanup.resetMingaContent(
            new ResetMingaContentRequest(),
          );
          this._systemAlertSnackBar.success('Success!');
        } catch {
          this._systemAlertSnackBar.error(
            'Resetting minga content failed. Please try again later.',
          );
        }
      }
    });
  }

  async openPresets() {
    this._mmDatePresetsService.openPresetsList();
  }

  public onAppRolloutSettingChange(
    type: 'mingaIsBeta' | 'mingaIsCustomerCouncil',
    value: boolean,
  ) {
    this._analyticsService.setUserProperties({ [type]: value });
  }
}
