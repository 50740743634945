"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsService = void 0;
/**
 * A service to use to store and get settings to be used throughout the app.
 * It merges several different sources to allow settings at the app level
 * but also at the minga level. Feature flags can also be enabled as settings.
 * Rows in the database with isDefaultSettings = true, as well as settings in
 * the configuration file under minga_default_config are considered app wide
 * defaults. Defaults can be overridden at the minga level.
 * @todo: cache settings
 */
class SettingsService {
}
exports.SettingsService = SettingsService;
SettingsService.FEATURE_FLAG_PREFIX = 'feature_flags.';