"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetStudentScheduleForRecipientResponse = exports.GetStudentScheduleForRecipientRequest = exports.StudentSection = void 0;
const jspb = require("google-protobuf");
const common_date_pb = require("minga/proto/common/date_pb");
class StudentSection extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, StudentSection.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StudentSection.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StudentSection} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            period: jspb.Message.getFieldWithDefault(msg, 3, ""),
            term: jspb.Message.getFieldWithDefault(msg, 4, ""),
            date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
            startTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
            endTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
            teachersList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
            location: jspb.Message.getFieldWithDefault(msg, 9, ""),
            type: jspb.Message.getFieldWithDefault(msg, 10, ""),
            sectionId: jspb.Message.getFieldWithDefault(msg, 11, ""),
            periodId: jspb.Message.getFieldWithDefault(msg, 12, ""),
            termId: jspb.Message.getFieldWithDefault(msg, 13, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StudentSection}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StudentSection;
        return StudentSection.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StudentSection} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StudentSection}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setName(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setPeriod(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setTerm(value4);
                    break;
                case 5:
                    var value5 = new common_date_pb.DateTime;
                    reader.readMessage(value5, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setDate(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setStartTime(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setEndTime(value7);
                    break;
                case 8:
                    var value8 = /** @type {string} */ (reader.readString());
                    msg.addTeachers(value8);
                    break;
                case 9:
                    var value9 = /** @type {string} */ (reader.readString());
                    msg.setLocation(value9);
                    break;
                case 10:
                    var value10 = /** @type {string} */ (reader.readString());
                    msg.setType(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setSectionId(value11);
                    break;
                case 12:
                    var value12 = /** @type {string} */ (reader.readString());
                    msg.setPeriodId(value12);
                    break;
                case 13:
                    var value13 = /** @type {string} */ (reader.readString());
                    msg.setTermId(value13);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StudentSection} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getPeriod();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getTerm();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getDate();
        if (f != null) {
            writer.writeMessage(5, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getStartTime();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getEndTime();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getTeachersList();
        if (f.length > 0) {
            writer.writeRepeatedString(8, f);
        }
        f = message.getLocation();
        if (f.length > 0) {
            writer.writeString(9, f);
        }
        f = message.getType();
        if (f.length > 0) {
            writer.writeString(10, f);
        }
        f = message.getSectionId();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getPeriodId();
        if (f.length > 0) {
            writer.writeString(12, f);
        }
        f = message.getTermId();
        if (f.length > 0) {
            writer.writeString(13, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StudentSection.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string name = 2;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string period = 3;
     * @return {string}
     */
    getPeriod() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setPeriod(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string term = 4;
     * @return {string}
     */
    getTerm() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setTerm(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional minga.common.DateTime date = 5;
     * @return {?DateTime}
     */
    getDate() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
    }
    /** @param {?DateTime|undefined} value */
    setDate(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDate() {
        this.setDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDate() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional string start_time = 6;
     * @return {string}
     */
    getStartTime() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setStartTime(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional string end_time = 7;
     * @return {string}
     */
    getEndTime() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setEndTime(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * repeated string teachers = 8;
     * @return {!Array<string>}
     */
    getTeachersList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
    }
    ;
    /** @param {!Array<string>} value */
    setTeachersList(value) {
        jspb.Message.setField(this, 8, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addTeachers(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 8, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearTeachersList() {
        this.setTeachersList([]);
    }
    /**
     * optional string location = 9;
     * @return {string}
     */
    getLocation() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
    }
    ;
    /** @param {string} value */
    setLocation(value) {
        jspb.Message.setProto3StringField(this, 9, value);
    }
    /**
     * optional string type = 10;
     * @return {string}
     */
    getType() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
    }
    ;
    /** @param {string} value */
    setType(value) {
        jspb.Message.setProto3StringField(this, 10, value);
    }
    /**
     * optional string section_id = 11;
     * @return {string}
     */
    getSectionId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setSectionId(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional string period_id = 12;
     * @return {string}
     */
    getPeriodId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
    }
    ;
    /** @param {string} value */
    setPeriodId(value) {
        jspb.Message.setProto3StringField(this, 12, value);
    }
    /**
     * optional string term_id = 13;
     * @return {string}
     */
    getTermId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
    }
    ;
    /** @param {string} value */
    setTermId(value) {
        jspb.Message.setProto3StringField(this, 13, value);
    }
}
exports.StudentSection = StudentSection;
StudentSection.displayName = "StudentSection";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
StudentSection.repeatedFields_ = [8];
class GetStudentScheduleForRecipientRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetStudentScheduleForRecipientRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetStudentScheduleForRecipientRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            recipientPersonHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
            endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetStudentScheduleForRecipientRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetStudentScheduleForRecipientRequest;
        return GetStudentScheduleForRecipientRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetStudentScheduleForRecipientRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetStudentScheduleForRecipientRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setRecipientPersonHash(value1);
                    break;
                case 2:
                    var value2 = new common_date_pb.DateTime;
                    reader.readMessage(value2, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setStartDate(value2);
                    break;
                case 3:
                    var value3 = new common_date_pb.DateTime;
                    reader.readMessage(value3, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setEndDate(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetStudentScheduleForRecipientRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getRecipientPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getStartDate();
        if (f != null) {
            writer.writeMessage(2, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getEndDate();
        if (f != null) {
            writer.writeMessage(3, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetStudentScheduleForRecipientRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string recipient_person_hash = 1;
     * @return {string}
     */
    getRecipientPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setRecipientPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional minga.common.DateTime start_date = 2;
     * @return {?DateTime}
     */
    getStartDate() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
    }
    /** @param {?DateTime|undefined} value */
    setStartDate(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStartDate() {
        this.setStartDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStartDate() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional minga.common.DateTime end_date = 3;
     * @return {?DateTime}
     */
    getEndDate() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
    }
    /** @param {?DateTime|undefined} value */
    setEndDate(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearEndDate() {
        this.setEndDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasEndDate() {
        return jspb.Message.getField(this, 3) != null;
    }
}
exports.GetStudentScheduleForRecipientRequest = GetStudentScheduleForRecipientRequest;
GetStudentScheduleForRecipientRequest.displayName = "GetStudentScheduleForRecipientRequest";
class GetStudentScheduleForRecipientResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetStudentScheduleForRecipientResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetStudentScheduleForRecipientResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetStudentScheduleForRecipientResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            studentScheduleList: jspb.Message.toObjectList(msg.getStudentScheduleList(), StudentSection.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetStudentScheduleForRecipientResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetStudentScheduleForRecipientResponse;
        return GetStudentScheduleForRecipientResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetStudentScheduleForRecipientResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetStudentScheduleForRecipientResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new StudentSection;
                    reader.readMessage(value1, StudentSection.deserializeBinaryFromReader);
                    msg.addStudentSchedule(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetStudentScheduleForRecipientResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStudentScheduleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, StudentSection.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetStudentScheduleForRecipientResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated StudentSection student_schedule = 1;
     * @return {!Array<!StudentSection>}
     */
    getStudentScheduleList() {
        return /** @type{!Array<!StudentSection>} */ (jspb.Message.getRepeatedWrapperField(this, StudentSection, 1));
    }
    /** @param {!Array<!StudentSection>} value */
    setStudentScheduleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!StudentSection=} opt_value
     * @param {number=} opt_index
     * @return {!StudentSection}
     */
    addStudentSchedule(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, StudentSection, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearStudentScheduleList() {
        this.setStudentScheduleList([]);
    }
}
exports.GetStudentScheduleForRecipientResponse = GetStudentScheduleForRecipientResponse;
GetStudentScheduleForRecipientResponse.displayName = "GetStudentScheduleForRecipientResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetStudentScheduleForRecipientResponse.repeatedFields_ = [1];