"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RosterJobProtoMapper = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const roster_pb_1 = require("minga/proto/roster/roster_pb");
var RosterJobProtoMapper;
(function (RosterJobProtoMapper) {
    RosterJobProtoMapper.toProto = (item) => {
        const msg = new roster_pb_1.RosterJob();
        msg.setEnqueuedAt(shared_grpc_1.dateObjectToDateTimeMessage(item.enqueueAt));
        msg.setPayload(item.payload);
        msg.setResult(item.result);
        msg.setSuccess(item.success);
        return msg;
    };
    RosterJobProtoMapper.fromProto = (msg) => {
        const success = msg.getSuccess();
        const result = msg.getResult();
        const payload = msg.getPayload();
        const enqueueAt = shared_grpc_1.dateTimeMessageToDateObject(msg.getEnqueuedAt());
        return {
            success,
            result,
            payload,
            enqueueAt,
        };
    };
})(RosterJobProtoMapper = exports.RosterJobProtoMapper || (exports.RosterJobProtoMapper = {}));