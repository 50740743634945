"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const report_person_pb_1 = require("minga/proto/report_person/report_person_pb");
exports.toProto = (person) => {
    const proto = new report_person_pb_1.ReportPerson();
    proto.setId(person.id);
    proto.setFirstName(person.firstName);
    proto.setLastName(person.lastName);
    proto.setEmail(person.email);
    proto.setGrade(person.grade);
    proto.setRoleType(person.roleType);
    proto.setPersonHash(person.personHash);
    proto.setStudentId(person.studentId);
    return proto;
};
exports.fromProto = (proto) => {
    const roleType = ts_enum_util_1.$enum(domain_1.MingaRoleType).asValueOrThrow(proto.getRoleType());
    return {
        id: proto.getId(),
        firstName: proto.getFirstName(),
        lastName: proto.getLastName(),
        email: proto.getEmail(),
        grade: proto.getGrade(),
        roleType,
        personHash: proto.getPersonHash(),
        studentId: proto.getStudentId(),
    };
};