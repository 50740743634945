import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { IBellSchedule } from 'minga/libraries/domain';

import { SCHEDULE_FORM_FIELDS } from '../constants/mm-bs-schedules.constants';
import { getOverlappingDays } from './noOverlappingDays.validator';

export const noOverlappingDaysByTermValidator = (
  schedules: IBellSchedule[],
): ValidatorFn => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const days = formGroup.get(SCHEDULE_FORM_FIELDS.DAYS)?.value as string[];
    const terms = formGroup.get(SCHEDULE_FORM_FIELDS.TERMS)?.value as number[];

    if (!terms?.length || !days?.length) return null;

    let overlappingDays: string[] = [];

    // we only care if there's overlap within the term not across multiple terms
    terms.forEach(t => {
      const schedulesInTerm = schedules.filter(s => s.termIds.includes(t));
      const overlap = getOverlappingDays(schedulesInTerm, days);
      overlappingDays.push(...overlap);
    });

    // dedupe
    overlappingDays = [...new Set(overlappingDays)];

    if (overlappingDays.length === 0) return null;

    return {
      overlappingDaysByTerm: overlappingDays,
    };
  };
};
