import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PageComponent } from './Page.component';
import { PageModule } from './Page.module';

@NgModule({
  imports: [
    // Minga dependencies
    PageModule,
    RouterModule.forChild([
      {
        path: '',
        component: PageComponent,
      },
    ]),
  ],
})
export class PageRoutingModule {}
