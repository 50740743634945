"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONSEQUENCE_OVERDUE = exports.AUTOMATION_COUNTER = exports.CONSEQUENCE_HISTORY = exports.CONSEQUENCE_TYPES = exports.HISTORY = exports.TYPES = exports.STAFF = exports.STUDENTS = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const shared_1 = require("minga/libraries/shared/index");
const utils_1 = require("minga/libraries/shared/reports_columns/utils/index");
const columns_constants_1 = require("minga/libraries/shared/reports_columns/constants/columns.constants");
const common_constants_1 = require("minga/libraries/shared/reports_columns/constants/common.constants");
const category = {
    header: 'Category',
    key: 'behaviorCategory',
    accessorFn: (item, color) => {
        const categoryId = item.type ? item.type.categoryId : item.typeCategory;
        const categoryVal = ts_enum_util_1.$enum(shared_1.PbisCategory).asValueOrThrow(categoryId);
        const behaviorCategory = shared_1.PbisCategoriesObj[categoryVal];
        return color ? behaviorCategory.color : behaviorCategory.name;
    },
    type: 'text',
    width: 'medium',
};
const issuedBy = {
    header: 'Assigned by',
    key: common_constants_1.NameColumnKeys.TEACHER_NAME,
    tooltip: true,
    accessorFn: (item, value) => {
        if (item.imported)
            return 'Imported';
        if (item.issuedByPerson)
            return utils_1.nameFinder(item.issuedByPerson, value);
        if (item.issuedBy)
            return utils_1.nameFinder(item.issuedBy, value);
        return '';
    },
    defaultValue: 'Automation',
    type: 'text',
    width: 'flex-medium',
};
const consequenceName = {
    header: 'Consequence name',
    key: 'consequenceName',
    tooltip: true,
    type: 'text',
    width: 'flex-large',
};
const praiseCount = {
    key: 'praiseCount',
    header: 'Praise',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'small',
    accessorFn: (item) => { var _a; return (_a = item.stats) === null || _a === void 0 ? void 0 : _a.totalPraise; },
};
const guidanceCount = {
    key: 'guidanceCount',
    header: 'Guidance',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'medium',
    accessorFn: (item) => { var _a; return (_a = item.stats) === null || _a === void 0 ? void 0 : _a.totalGuidance; },
};
const consequenceCount = {
    key: 'consequenceCount',
    header: 'Consequence',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'large',
    accessorFn: (item) => { var _a; return (_a = item.stats) === null || _a === void 0 ? void 0 : _a.totalConsequence; },
};
const consequenceEscalatedCount = {
    key: 'consequenceOverdueAction',
    header: 'Additional / Escalated',
    type: 'number',
    width: 'large',
    accessorFn: (item) => { var _a; return (_a = item.stats) === null || _a === void 0 ? void 0 : _a.totalEscalatedConsequence; },
};
exports.STUDENTS = [
    columns_constants_1.firstName,
    columns_constants_1.lastName,
    columns_constants_1.studentId,
    Object.assign(Object.assign({}, columns_constants_1.grade), { width: 'small' }),
    columns_constants_1.role,
    praiseCount,
    guidanceCount,
    consequenceCount,
    {
        key: 'consequenceOverdueCount',
        header: 'Overdue',
        sort: true,
        type: 'number',
        width: 'medium',
        defaultValue: '0',
        accessorFn: (item) => { var _a; return (_a = item.stats) === null || _a === void 0 ? void 0 : _a.totalOverdueConsequence; },
    },
    consequenceEscalatedCount,
    {
        key: 'consequenceActiveCount',
        header: 'Not completed',
        sort: true,
        type: 'number',
        width: 'large',
        defaultValue: '0',
        accessorFn: (item) => { var _a; return (_a = item.stats) === null || _a === void 0 ? void 0 : _a.totalActiveConsequence; },
    },
    {
        key: 'consequenceCompleteCount',
        header: 'Completed',
        sort: true,
        type: 'number',
        width: 'medium',
        defaultValue: '0',
        accessorFn: (item) => { var _a; return (_a = item.stats) === null || _a === void 0 ? void 0 : _a.totalCompleteConsequence; },
    },
];
exports.STAFF = [
    columns_constants_1.firstName,
    columns_constants_1.lastName,
    columns_constants_1.role,
    praiseCount,
    guidanceCount,
    consequenceCount,
];
exports.TYPES = [
    {
        header: 'Behavior',
        key: 'typeName',
        tooltip: true,
        type: 'text',
        width: 'flex-medium',
    },
    category,
    {
        key: 'total',
        header: 'Assigned',
        defaultValue: '0',
        sort: true,
        type: 'number',
        width: 'medium',
    },
];
exports.HISTORY = [
    Object.assign(Object.assign({}, columns_constants_1.firstName), { accessorFn: (item) => item.recipient ? utils_1.nameFinder(item.recipient, 'first') : '' }),
    Object.assign(Object.assign({}, columns_constants_1.lastName), { accessorFn: (item) => item.recipient ? utils_1.nameFinder(item.recipient, 'last') : '' }),
    Object.assign(Object.assign({}, columns_constants_1.studentId), { accessorFn: (item) => utils_1.studentIdFinder(item.recipient) }),
    Object.assign(Object.assign({}, columns_constants_1.grade), { accessorFn: (item) => utils_1.gradeFinder(item.recipient) }),
    Object.assign(Object.assign({}, columns_constants_1.role), { accessorFn: (item, value) => utils_1.roleFinder(item.recipient, value) }),
    {
        header: 'Note',
        key: 'note',
        tooltip: true,
        type: 'text',
        width: 'small',
        accessorFn: (item) => item.message || '',
    },
    columns_constants_1.currentClass,
    columns_constants_1.sectionId,
    columns_constants_1.periodId,
    columns_constants_1.termId,
    {
        header: 'Behavior',
        key: 'behaviorName',
        tooltip: true,
        type: 'text',
        width: 'flex-medium',
        accessorFn: (item) => { var _a; return ((_a = item.type) === null || _a === void 0 ? void 0 : _a.name) || ''; },
    },
    category,
    Object.assign(Object.assign({}, issuedBy), { accessorFn: (item, value) => {
            if (item.creator)
                return utils_1.nameFinder(item.creator, value);
            if (item.imported)
                return 'Imported';
            return '';
        } }),
    {
        header: 'Date',
        key: common_constants_1.DateColumnKeys.BEHAVIOR_DATE,
        sort: true,
        type: 'date',
        width: 'small',
        accessorFn: (item) => item.createdAt,
    },
    {
        header: 'Time',
        key: common_constants_1.TimeColumnKeys.BEHAVIOR_TIME,
        type: 'date',
        width: 'small',
        accessorFn: (item) => item.createdAt,
    },
    {
        header: 'Last Modified',
        key: common_constants_1.DateColumnKeys.MANUALLY_UPDATED_AT,
        type: 'date',
        width: 'medium',
    },
];
exports.CONSEQUENCE_TYPES = [
    Object.assign(Object.assign({}, consequenceName), { key: 'typeName' }),
    category,
    {
        key: 'total',
        header: 'Assigned',
        defaultValue: '0',
        sort: true,
        type: 'number',
        width: 'medium',
    },
    {
        key: 'totalComplete',
        header: 'Completed',
        defaultValue: '0',
        sort: true,
        type: 'number',
        width: 'medium',
    },
    {
        key: 'totalActive',
        header: 'Not completed',
        defaultValue: '0',
        sort: true,
        type: 'number',
        width: 'large',
    },
    {
        key: 'totalOverdue',
        header: 'Overdue',
        defaultValue: '0',
        sort: true,
        type: 'number',
        width: 'small',
    },
];
exports.CONSEQUENCE_HISTORY = [
    { header: 'Status', key: 'status', type: 'tag', width: 'medium' },
    columns_constants_1.firstName,
    columns_constants_1.lastName,
    columns_constants_1.studentId,
    columns_constants_1.grade,
    columns_constants_1.role,
    columns_constants_1.note,
    Object.assign(Object.assign({}, consequenceName), { width: 'flex-large', tooltip: true, accessorFn: (item) => { var _a; return ((_a = item.type) === null || _a === void 0 ? void 0 : _a.name) || ''; } }),
    {
        header: 'Consequence ID',
        key: 'consequenceId',
        type: 'number',
        width: 'large',
        accessorFn: (item) => item.id || undefined,
    },
    Object.assign(Object.assign({}, category), { width: 'large' }),
    {
        header: 'Type',
        key: 'categoryType',
        type: 'text',
        width: 'large',
        accessorFn: (item) => {
            var _a;
            if ((_a = item.type) === null || _a === void 0 ? void 0 : _a.type) {
                const categoryType = domain_1.ConsequenceTypesEnum[item.type.type];
                return categoryType.charAt(0) + categoryType.slice(1).toLowerCase();
            }
            return '';
        },
    },
    Object.assign(Object.assign({}, issuedBy), { accessorFn: (item, value) => {
            if (item.imported)
                return 'Imported';
            if (item === null || item === void 0 ? void 0 : item.sourceOverdueActionType) {
                const overdueActionType = item.sourceOverdueActionType;
                return overdueActionType === domain_1.OverdueConsequenceActionType.ESCALATE
                    ? 'Escalated'
                    : 'Additional';
            }
            if (item.automationName) {
                return value === 'last' ? '' : item.automationName;
            }
            if (item.issuedByPerson)
                return utils_1.nameFinder(item.issuedByPerson, value);
            return '';
        } }),
    {
        header: 'Source ID',
        key: 'sourceId',
        type: 'number',
        width: 'medium',
        linkableFn: (item) => {
            if (!(item === null || item === void 0 ? void 0 : item.sourceOverdueActionType))
                return undefined;
            return {
                href: `/behaviors/reports/pbis_cons_history?consequenceId=${item.sourceId}`,
                target: '_blank',
            };
        },
        accessorFn: (item) => item.sourceId || undefined,
    },
    {
        header: 'Automation Group',
        key: 'automationGroup',
        type: 'text',
        width: 'flex-large',
        tooltip: true,
        accessorFn: (item) => item.automationName || '',
    },
    {
        header: 'Automation',
        key: 'automationName',
        type: 'text',
        width: 'large',
        tooltip: true,
        accessorFn: (item) => item.actionName || '',
    },
    {
        header: 'Assigned Date',
        key: common_constants_1.DateColumnKeys.ISSUED_DATE,
        sort: true,
        type: 'date',
        width: 'medium',
        accessorFn: (item) => item.issuedAt,
    },
    {
        header: 'Assigned Time',
        key: common_constants_1.TimeColumnKeys.ISSUED_TIME,
        type: 'date',
        width: 'medium',
        accessorFn: (item) => item.issuedAt,
    },
    {
        header: 'Due Day',
        key: common_constants_1.DateColumnKeys.DUE_DATE,
        type: 'date',
        sort: true,
        width: 'medium',
        accessorFn: (item) => item.completeBy,
    },
    {
        header: 'Completed Date',
        key: common_constants_1.DateColumnKeys.COMPLETE_DATE,
        type: 'date',
        sort: true,
        width: 'medium',
        accessorFn: (item) => {
            var _a, _b;
            return !domain_1.autoCompleteTypes.includes((_b = (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : 0)
                ? item.completedAt
                : '';
        },
    },
    {
        header: 'Completed Time',
        key: common_constants_1.TimeColumnKeys.COMPLETE_TIME,
        type: 'date',
        width: 'medium',
        accessorFn: (item) => {
            var _a, _b;
            return !domain_1.autoCompleteTypes.includes((_b = (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : 0)
                ? item.completedAt
                : '';
        },
    },
    {
        header: 'Completed by',
        key: 'completedBy',
        type: 'text',
        width: 'flex-medium',
        tooltip: true,
        accessorFn: (item) => {
            var _a, _b;
            if (!item.completedAt ||
                domain_1.autoCompleteTypes.includes((_b = (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : 0)) {
                return '';
            }
            if (item.completedBy && item.completedByPerson) {
                const person = item.completedByPerson;
                return `${person.firstName} ${person.lastName}`;
            }
            else if (item.handledByOverdueAction &&
                item.handledByOverdueAction === domain_1.OverdueConsequenceActionType.ESCALATE) {
                return 'Escalated';
            }
            else {
                return 'Check in automation';
            }
        },
    },
    {
        header: 'Last Modified',
        key: common_constants_1.DateColumnKeys.MANUALLY_UPDATED_AT,
        type: 'date',
        width: 'medium',
    },
];
exports.AUTOMATION_COUNTER = [
    columns_constants_1.firstName,
    columns_constants_1.lastName,
    columns_constants_1.studentId,
    columns_constants_1.grade,
    columns_constants_1.role,
    {
        header: 'Automation Group',
        key: 'automationGroup',
        tooltip: true,
        width: 'flex-medium',
        type: 'text',
    },
    {
        header: 'Counter',
        key: 'counter',
        defaultValue: '0',
        sort: true,
        width: 'small',
        type: 'number',
    },
];
exports.CONSEQUENCE_OVERDUE = [
    columns_constants_1.firstName,
    columns_constants_1.lastName,
    columns_constants_1.studentId,
    {
        key: 'outstanding',
        header: 'Not completed',
        defaultValue: '0',
        accessorFn: (item) => item.stats.totalOutstandingConsequence,
        width: 'small',
    },
    {
        key: 'overdue',
        header: 'Overdue',
        defaultValue: '0',
        accessorFn: (item) => item.stats.totalOverdueConsequence,
        width: 'small',
    },
    {
        key: 'assigned',
        header: 'Assigned',
        defaultValue: '0',
        accessorFn: (item) => item.stats.totalConsequence,
        width: 'small',
    },
    {
        key: 'served',
        header: 'Completed',
        defaultValue: '0',
        accessorFn: (item) => item.stats.totalServedConsequence,
        width: 'small',
    },
];