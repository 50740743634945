import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PbisNameCategory } from 'minga/libraries/domain';
import { BarcodeScanner } from 'src/app/barcodeScanner';
import { RootService } from 'src/app/minimal/services/RootService';
import { PermissionsService } from 'src/app/permissions';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ConsequenceService } from '@shared/services/consequence';
import { PbisService } from '@shared/services/pbis';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsBehaviorService extends PeopleSelectorFormService<'Behavior'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _behaviorService: PbisService,
    private _consequenceService: ConsequenceService,
    private _systemModal: SystemAlertModalService,
    private _rootService: RootService,
    protected _permissions: PermissionsService,
  ) {
    super(
      {
        name: 'Behavior',
        pageDefinitions: {
          assign: {
            submitFn: async () => this.submitAssign(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
      _permissions,
    );
  }

  public async submitAssign(): Promise<void> {
    try {
      const personHashes = this.selection.getSelectionHashes();
      const type = this._behaviorService.getPbisCategory();
      if (type === PbisNameCategory.CONSEQUENCE) {
        await this._rootService.addLoadingPromise(
          this._consequenceService.createConsequences({
            type: this._behaviorService.getPbisTypeId(),
            completeBy: this._behaviorService.getConsequenceCompleteDate(),
            issuedTo: personHashes,
            notes: this._behaviorService.getPbisMessage(),
          }),
        );
        await this._showSuccessModal();
      } else {
        await this._rootService.addLoadingPromise(
          this._behaviorService.createBehavior({
            typeId: this._behaviorService.getPbisTypeId(),
            message: this._behaviorService.getPbisMessage(),
            recipientIds: personHashes,
          }),
        );
        await this._showSuccessModal();
      }
    } catch (e) {
      this.snackBar.open({
        message: `Error assigning ${this._behaviorService.getPbisCategory()}`,
        type: 'error',
      });
    }
  }

  private async _showSuccessModal() {
    const category =
      this._behaviorService.getPbisCategory() || PbisNameCategory.PRAISE;
    const action = category.charAt(0).toUpperCase() + category.slice(1);

    this.snackBar.open({
      type: 'success',
      message: `${action} successfully assigned!`,
    });
  }
}
