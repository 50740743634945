"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MingaManager = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const grpc_web_1 = require("@improbable-eng/grpc-web");
const minga_pb_service_1 = require("minga/proto/gateway/minga_pb_service");
const i0 = require("@angular/core");
/**
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  MingaManager service (all rpc calls available for Mingas)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  */
class MingaManager {
    constructor(_ngZone) {
        this._ngZone = _ngZone;
    }
    resetGroups(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.resetGroups, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    resetPoints(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.resetPoints, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    resetAutomationCounters(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.ResetAutomationCounters, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    resetGroupsMembers(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.resetGroupsMembers, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    resetUserLists(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.resetUserLists, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateSIS(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.updateSIS, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    deleteSIS(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.deleteSIS, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    activateRosteringMethod(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.activateRosteringMethod, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    deactivateAllRosteringMethods(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.deactivateAllRosteringMethods, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getSIS(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.getSIS, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getSISErrorDetails(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.getSISErrorDetails, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    testSISIntegration(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.TestSISIntegration, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getSISSync(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.getSISSync, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getSISUnifiedSync(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.getSISUnifiedSync, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    triggerSISSync(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.triggerSISSync, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    createMingas(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.createMingas, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    readMingas(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.readMingas, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateMingas(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.updateMingas, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    deleteMingas(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.deleteMingas, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    readUserMinga(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.readUserMinga, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateMingaProfile(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.updateMingaProfile, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateMingaImage(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.updateMingaImage, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    addMingaLink(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.addMingaLink, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateMingaLink(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.updateMingaLink, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    removeMingaLink(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.removeMingaLink, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    pauseMinga(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.PauseMinga, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getPublicMingaCampInfo(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetPublicMingaCampInfo, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateUserMingaFeatureToggles(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.UpdateUserMingaFeatureToggles, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaFeatureToggles(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaFeatureToggles, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateMingaFeatureToggles(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.UpdateMingaFeatureToggles, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateMingaSetting(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.UpdateMingaSetting, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaSettings(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaSettings, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateMingaStudentIdImage(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.UpdateMingaStudentIdImage, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    removeMingaStudentIdImage(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.RemoveMingaStudentIdImage, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    addFiles(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.AddFiles, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getFiles(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetFiles, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    removeFile(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.RemoveFile, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaDashboard2(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaDashboard2, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaReport(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaReport, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaReportSummary(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaReportSummary, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaDashboard(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaDashboard, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getUserMingaAccountInfo(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetUserMingaAccountInfo, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    searchForMinga(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.SearchForMinga, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    updateUserMingaSettings(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.UpdateUserMingaSettings, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    readUserMingaJoinCode(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.ReadUserMingaJoinCode, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    readUserMingaGrades(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.ReadUserMingaGrades, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    createUserMingaJoinCode(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.CreateUserMingaJoinCode, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    changeCustomJoinCode(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.ChangeCustomJoinCode, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaSubscriptions(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaSubscriptions, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getActiveMingaSubscription(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetActiveMingaSubscription, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaPartners(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaPartners, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    upsertMingaPartner(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.UpsertMingaPartner, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaDistricts(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaDistricts, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    deleteMingaDistrict(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.DeleteMingaDistrict, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaDistrict(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaDistrict, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    exportDistricts(request, arg1, arg2, arg3, arg4) {
        let ret;
        let metadata;
        let onMessage;
        let onError;
        let onEnd;
        if (typeof arg1 === 'function') {
            onMessage = arg1;
            onError = arg2;
            onEnd = arg3;
        }
        else if (typeof arg2 === 'function') {
            metadata = arg1;
            onMessage = arg2;
            onError = arg3;
            onEnd = arg4;
        }
        else {
            metadata = arg1;
        }
        if (!onMessage) {
            let subject = new rxjs_1.Subject();
            ret = subject.asObservable();
            onMessage = (response) => {
                subject.next(response);
            };
            onError = (err) => {
                subject.error(err);
            };
            onEnd = (code, msg, metadata) => {
                subject.complete();
            };
        }
        else {
            if (!onError) {
                onError = (err) => console.error(err);
            }
            if (!onEnd) {
                onEnd = (code, msg, metadata) => { };
            }
        }
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.ExportDistricts, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onMessage: response => this._ngZone.run(() => {
                onMessage(response);
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code == grpc_web_1.grpc.Code.OK) {
                    onEnd(code, msg, metadata);
                }
                else {
                    let err = new Error(code + ' ' + (msg || ''));
                    err.code = code;
                    onError(err);
                }
            })
        });
        ret.close = () => {
            console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
            return req.close();
        };
        ret.cancel = () => req.close();
        return ret;
    }
    upsertMingaDistrict(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.UpsertMingaDistrict, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    generateMingaSFTPGoUser(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GenerateMingaSFTPGoUser, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getMingaStatsForPerson(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetMingaStatsForPerson, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getStatsForStudent(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(minga_pb_service_1.MingaManager.GetStatsForStudent, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
}
exports.MingaManager = MingaManager;
MingaManager.ɵfac = function MingaManager_Factory(t) { return new (t || MingaManager)(i0.ɵɵinject(i0.NgZone)); };
MingaManager.ɵprov = i0.ɵɵdefineInjectable({ token: MingaManager, factory: MingaManager.ɵfac, providedIn: 'root' });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MingaManager, [{
        type: core_1.Injectable,
        args: [{ providedIn: 'root' }]
    }], function () { return [{ type: i0.NgZone }]; }, null); })();