<!-- Hall Pass Manager Dashboard Subpage -->
<mg-manager-overlay-subpage>
  <!-- Header -->
  <ng-template #subpageHeader>
    <div class="header-container">
      <div
        *ngIf="!hallPassEnabled"
        class="hall-pass-alert">
        <mg-system-alert
          type="warning"
          [message]="MESSAGES.HALLPASSES_DISABLED_ALERT">
        </mg-system-alert>
      </div>
      <ng-container *ngTemplateOutlet="carouselTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
    </div>
  </ng-template>

  <div
    class="actions-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.gt-sm="flex-end center">
    <!-- Pass Count -->
    <ng-container *ngTemplateOutlet="passCount"></ng-container>
    <!-- Quick Actions -->
    <ng-container *ngTemplateOutlet="quickActionsTemplate"></ng-container>
  </div>
  <!-- Passes Data Table -->
  <mg-hpm-dashboard-table></mg-hpm-dashboard-table>
</mg-manager-overlay-subpage>

<!-- Filters Template -->
<ng-template #filtersTemplate>
  <div class="filters">
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutAlign.gt-sm="start stretch"
      fxLayout.gt-sm="row"
      class="filters-content">
      <mg-multi-person-search
        class="filters-person"
        [id]="'hallpass-dashboard-click-search-name'"
        (selectionChanged)="setPeopleFilter($any($event))">
      </mg-multi-person-search>
      <!-- Status Filter -->
      <mg-form-select
        *ngIf="(media.isMobileView$ | async) === false"
        #statusFilterSelect
        class="filters-status"
        placeholder="Status"
        [id]="'hallpass-dashboard-click-status'"
        [labelBackground]="'white'"
        [value]="(hpmDashboard.filters$ | async)?.pass_status"
        [closeOnSelect]="false"
        [multiple]="true"
        [searchable]="true"
        [options]="passStatusOptions$ | async"
        (selectionChanged)="setPassStatusFilter($any($event))">
      </mg-form-select>

      <!-- Show My Passes Toggle -->
      <div
        class="filters-btns"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxFlex.lt-md="100">
          <div fxFlex="grow">
            <mat-button-toggle-group
              name="createdByFilter"
              class="filter-toggle-group"
              [value]="
                (hpmDashboard.filters$ | async)?.show_mine ? 'mine' : 'all'
              "
              (change)="setShowAuthoredFilter($event.value)">
              <mat-button-toggle
                [attr.data-analytics]="'hallpass-dashboard-click-all-hp'"
                [value]="'all'">
                <mg-text variant="body-sm-secondary">
                  {{ MESSAGES.ALL_PASSES }}
                </mg-text>
              </mat-button-toggle>
              <mat-button-toggle
                [attr.data-analytics]="'hallpass-dashboard-click-my-hp'"
                [value]="'mine'">
                <mg-text variant="body-sm-secondary">
                  {{ MESSAGES.MY_PASSES }}
                </mg-text>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="filters-clear-btn">
            <mg-btn
              variant="text"
              size="small"
              [id]="'hallpass-dashboard-click-clear'"
              (pressed)="hpmDashboard.clearFilters()">
              {{ MESSAGES.BUTTON_LABEL_CLEAR_FILTERS }}
            </mg-btn>
          </div>
        </div>

        <div
          *ngIf="
            (hpmDashboard.canGrantPass$ | async) &&
            (media.isMobileView$ | async) === false
          "
          class="filters-assign">
          <mg-btn
            variant="filled"
            [id]="'hallpass-dashboard-click-assign'"
            [wide]="true"
            [responsive]="true"
            (pressed)="assignHallPass()">
            {{ MESSAGES.BUTTON_LABEL_ASSIGN_HALLPASS }}
          </mg-btn>
        </div>
      </div>
    </div>

    <!-- Filters Form -->
    <mg-filters-form
      *ngIf="media.isMobileView$ | async"
      [data]="filtersFormStructure$ | async"
      [responsive]="true"
      [showChips]="false"
      (state)="updateFilters($event)">
    </mg-filters-form>

    <mg-btn
      *ngIf="
        (hpmDashboard.canGrantPass$ | async) && media.isMobileView$ | async
      "
      class="assign-hallpass-btn"
      variant="filled"
      [wide]="true"
      [responsive]="true"
      (pressed)="assignHallPass()">
      {{ MESSAGES.BUTTON_LABEL_ASSIGN_HALLPASS }}
    </mg-btn>
  </div>
</ng-template>

<!-- Carousel Template -->
<ng-template #carouselTemplate>
  <ng-container *ngIf="(media.isMobileView$ | async) === false">
    <div
      class="carousel"
      *ngIf="passTypesWithCounts$ | async as passTypesWithCounts">
      <mg-carousel
        *ngIf="passTypesWithCounts.length > 0"
        [id]="'hallpass-dashboard-click-hp-type'"
        [config]="carouselConfig"
        [dataSource]="passTypesWithCounts">
        <mg-carousel-item
          mgCarouselItem
          *mgCarouselDef="let tile; let j = index">
          <mg-count-tile
            size="auto"
            [icon]="tile?.icon"
            [fixedHeight]="true"
            [count]="tile?.count || 0"
            [label]="tile?.name"
            [color]="tile?.color"
            [image]="tile?.asset"
            [typeIcon]="tile?.bannerHash"
            [isActive]="
              (hpmDashboard.filters$ | async)?.pass_id | includes: tile?.id
            "
            [backgroundColor]="'white'"
            (tileClicked)="setPassIdFilter(tile?.id)">
          </mg-count-tile>
        </mg-carousel-item>
      </mg-carousel>
    </div>
  </ng-container>
</ng-template>

<!-- Active Hall Pass Count -->
<ng-template #passCount>
  <ng-container *ngIf="media.isMobileView$ | async">
    <div class="active-passes">
      <mg-text variant="header-md-secondary">
        {{ MESSAGES.ACTIVE_PASSES_TITLE }} {{ passStatusCounts$ | async }}
      </mg-text>
    </div>
  </ng-container>
</ng-template>

<!-- Quick Actions Template -->
<ng-template #quickActionsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="16px">
    <div
      *ngIf="hpmDashboard.isLoading$ | async"
      fxFlex="nogrow">
      <mg-spinner
        [diameter]="20"
        [thickness]="2">
      </mg-spinner>
    </div>
    <div
      fxFlex="nogrow"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="8px">
      <mg-form-checkbox
        [id]="'hallpass-dashboard-click-auto-refresh'"
        [checked]="hpmDashboard.pollPasses$ | async"
        [disabled]="hpmDashboard.isLoading$ | async"
        (changed)="hpmDashboard.togglePollPasses()">
      </mg-form-checkbox>
      <mg-text variant="body-xs-secondary">
        {{ MESSAGES.TOGGLE_AUTO_REFRESH }}
      </mg-text>
    </div>
    <div fxFlex="nogrow">
      <mg-btn
        variant="icon"
        size="large"
        iconSet="material"
        icon="refresh"
        [id]="'hallpass-dashboard-click-refresh'"
        [disabled]="hpmDashboard.isLoading$ | async"
        (pressed)="hpmDashboard.refetchPasses()">
        {{ (media.isMobileView$ | async) ? '' : MESSAGES.BUTTON_LABEL_REFRESH }}
      </mg-btn>
    </div>
    <div
      fxHide.xs
      fxFlex="nogrow">
      <mg-btn
        *ngIf="canDownloadExport"
        variant="text"
        size="small"
        iconSet="minga"
        icon="mg-export"
        [id]="'hallpass-dashboard-click-export'"
        (pressed)="export()">
        {{ MESSAGES.BUTTON_LABEL_EXPORT }}
      </mg-btn>
    </div>
  </div>
</ng-template>
