"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledReportMinimalMapper = exports.ScheduledReportMapper = exports.PastReportMapper = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const PastReportmapperts = require("minga/libraries/shared-grpc/scheduled_reports/PastReport.mapper");
exports.PastReportMapper = PastReportmapperts;
const ScheduledReportmapperts = require("minga/libraries/shared-grpc/scheduled_reports/ScheduledReport.mapper");
exports.ScheduledReportMapper = ScheduledReportmapperts;
const ScheduledReportMinimalmapperts = require("minga/libraries/shared-grpc/scheduled_reports/ScheduledReportMinimal.mapper");
exports.ScheduledReportMinimalMapper = ScheduledReportMinimalmapperts;