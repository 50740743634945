import { ISsoProviderInfo } from 'minga/libraries/domain';

export namespace FirebaseAuthUserCredentialMapper {
  export async function toISsoProviderInfo(
    userCred: firebase.default.auth.UserCredential,
  ) {
    if (!userCred.user) {
      return null;
    }
    let providerIds: string[] = [];

    let displayName = userCred.user.displayName;
    const email = userCred.user.email;
    const emailVerified = userCred.user.emailVerified;
    const photoUrl = userCred.user.photoURL;
    let firstName: string | null = null;
    let lastName: string | null = null;

    if (!displayName) {
      for (const providerData of userCred.user.providerData) {
        if (providerData) {
          providerIds.push(providerData.providerId);
          if (providerData.displayName) {
            displayName = providerData.displayName;
          }
          break;
        }
      }
    }

    if (userCred.additionalUserInfo) {
      const profile: any = userCred.additionalUserInfo.profile;
      if (profile) {
        if (profile.given_name) {
          firstName = profile.given_name;
        }

        if (profile.family_name) {
          lastName = profile.family_name;
        }

        if (!displayName) {
          displayName = profile.name;
        }
      }
    }

    if (displayName && !firstName && !lastName) {
      const displayNameParts = displayName.split(' ', 2);
      if (displayNameParts[0]) {
        firstName = displayNameParts[0].trim();
      }
      if (displayNameParts[1]) {
        lastName = displayNameParts[1].trim();
      }
    }

    const info: ISsoProviderInfo = {
      providerIds,
      displayName,
      firstName,
      lastName,
      email,
      emailVerified,
      photoUrl,
    };

    return info;
  }
}
