import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { UserListMinimal } from 'minga/domain/userList';
import { Person } from 'src/app/people';

import { BottomSheetEventType } from '@shared/components/bottom-sheet';
import { OptionItem } from '@shared/components/form/components/form-grouped-select/form-grouped-select.types';
import { SelectElementOption } from '@shared/components/select-element';

import {
  FILTERS_FORM_FIELD_TYPE,
  FiltersFormSheetMessage,
} from '../../constants';
import { FiltersFormSheetData } from '../../types';

@Component({
  selector: 'mg-filters-form-sheet',
  templateUrl: './filters-form-sheet.component.html',
  styleUrls: ['./filters-form-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormSheetComponent implements OnInit, OnDestroy {
  // Constants
  public readonly MESSAGE = FiltersFormSheetMessage;
  public readonly FIELD_TYPE = FILTERS_FORM_FIELD_TYPE;

  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  private readonly _activeFieldSubject = new BehaviorSubject<string | null>(
    null,
  );
  public readonly activeField$ = this._activeFieldSubject.asObservable();

  public readonly activeFieldType$ = this.activeField$.pipe(
    takeUntil(this._destroyedSubject),
    map(field => {
      let data = this.data.data[field];
      if (typeof data === 'function') {
        data = data(this._draftFormDataSubject.getValue(), true);
      }
      return data?.type ?? null;
    }),
  );

  public readonly showUnselectAllButton$ = this.activeFieldType$.pipe(
    takeUntil(this._destroyedSubject),
    map(type => {
      if (!type) return false;

      return (
        type === 'multi-select' ||
        type === 'single-select' ||
        type === 'user-list'
      );
    }),
  );

  public readonly hasActiveField = this.activeField$.pipe(
    takeUntil(this._destroyedSubject),
    map(field => field !== null),
  );

  public readonly title$ = this.activeField$.pipe(
    takeUntil(this._destroyedSubject),
    map(field => {
      if (field === null) return this.data.title;
      let fieldObject = this.data.data[field];
      if (typeof fieldObject === 'function') {
        fieldObject = fieldObject(this._draftFormDataSubject.getValue(), true);
      }
      return fieldObject?.label ?? this.data.title;
    }),
  );

  private readonly _draftFormDataSubject = new BehaviorSubject<any>(null);
  public readonly draftFormData$ = this._draftFormDataSubject.asObservable();

  // Events
  private readonly _submitFieldChangesEvent = new Subject<void>();
  public readonly submitFieldChangesEvent$ =
    this._submitFieldChangesEvent.asObservable();

  private readonly _clearSelectionEventSubject = new Subject<void>();
  public readonly clearSelectionEvent$ =
    this._clearSelectionEventSubject.asObservable();

  /** Component constructor */
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: FiltersFormSheetData,
    private _bottomSheetRef: MatBottomSheetRef<FiltersFormSheetComponent>,
  ) {}

  ngOnInit(): void {
    this.data.formGroup.valueChanges
      .pipe(startWith(this.data.formGroup.value))
      .subscribe(val => {
        const draftFormData = this._draftFormDataSubject.getValue();
        this._draftFormDataSubject.next({ ...draftFormData, ...val });
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._activeFieldSubject.complete();
    this._submitFieldChangesEvent.complete();
    this._clearSelectionEventSubject.complete();
  }

  public onEsc() {
    const response = {
      type: BottomSheetEventType.ESC,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public onClose() {
    const response = {
      type: BottomSheetEventType.CLOSE,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public setActiveFilter(field: string) {
    this._activeFieldSubject.next(field);
  }

  public clear() {
    this.data.resetSubject.next();
    const response = {
      type: BottomSheetEventType.SUBMIT,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public unSelectAllFromActiveField() {
    this._clearSelectionEventSubject.next();
  }

  public setToggleGroupValue(field: string, { value }: MatButtonToggleChange) {
    this._setDraftFormData(field, value);
  }

  public clearActiveField() {
    this._activeFieldSubject.next(null);
  }

  public submitFieldChanges() {
    this._submitFieldChangesEvent.next();
    this.clearActiveField();
  }

  public setUserListValue(
    field: string,
    value: OptionItem<number, UserListMinimal>[],
  ) {
    const values = (value || []).map(v => v.value);
    this._setDraftFormData(field, values);
  }

  public setSelectValue(field: string, value: SelectElementOption[]) {
    let fieldObject = this.data.data[field];
    if (typeof fieldObject === 'function') {
      fieldObject = fieldObject(this._draftFormDataSubject.getValue(), true);
    }
    const { type } = fieldObject;
    const values = (value ?? []).map(v => v.value);
    const newValue = type === 'single-select' ? values[0] : values;
    this._setDraftFormData(field, newValue);
  }

  public setPersonValue(people: Partial<Person>[]) {
    let fieldObject = this.data.data.person;
    if (typeof fieldObject === 'function') {
      fieldObject = fieldObject(this._draftFormDataSubject.getValue(), true);
    }
    const { type } = fieldObject;
    const values = people.map(p => ({
      value: p,
      label: `${p.firstName} ${p.lastName}`,
    }));
    this._setDraftFormData(
      'person',
      type === 'single-select' ? values[0] : values,
    );
  }

  public getPersonHashes(value?: Partial<Person>[] | string[]): string[] {
    const hashes: string[] = [];
    if (!value) return hashes;
    for (const v of value) {
      // optimistic assumption, but we really shouldn't be mixing types
      if (typeof v === 'string') return value as string[];
      else hashes.push(v.hash);
    }
    return hashes;
  }

  public submit() {
    const draftFormData = this._draftFormDataSubject.getValue();
    const mappedDraftFormData = Object.keys(draftFormData).reduce(
      (acc, key) => {
        const value = draftFormData[key];
        if (value == null) {
          acc[key] = null;
          return acc;
        }
        acc[key] = value;
        return acc;
      },
      {},
    );
    this.data.formGroup.patchValue(mappedDraftFormData);
    const response = {
      type: BottomSheetEventType.SUBMIT,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  private _setDraftFormData(field: string, value: any) {
    const draftFormData = this._draftFormDataSubject.getValue();
    this._draftFormDataSubject.next({ ...draftFormData, [field]: value });
  }
}
