import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import * as checkin_proto from 'minga/proto/checkin/checkin_pb';
import * as report_proto from 'minga/proto/stats_report/stats_report_pb';
import { Checkin } from 'minga/domain/checkin';
import { CheckinManagerReportFilters } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { CheckinManager } from 'minga/proto/checkin/checkin_ng_grpc_pb';
import { StatsReportManager } from 'minga/proto/stats_report/stats_report_ng_grpc_pb';
import {
  CheckinPersonMapper,
  CheckinReasonMapper,
  CheckinsMapper,
} from 'minga/shared-grpc/checkin';
import { CheckInFilterMapper } from 'minga/shared-grpc/report_filters';
import { ReportsService } from 'src/app/components/manager-report/services/report-service.service';
import { RootService } from 'src/app/minimal/services/RootService';

import {
  CHECKIN_REPORT_FILTER_INITIAL_STATE,
  CHECKIN_REPORT_FILTER_NON_ARRAY_TYPES,
  CHECKIN_REPORT_FILTER_PERSON_EMITTERS,
  CheckinManagerReportFilterType,
} from './checkin-manager-reports.constants';

/**
 * Checkin Manager Reports Service
 *
 * Used to manage data that is shared between the checkin manager
 * reports components.
 */
@Injectable({ providedIn: 'root' })
export class CheckinManagerReportsService extends ReportsService<CheckinManagerReportFilters> {
  /** Service Constructor */
  constructor(
    private _reportService: StatsReportManager,
    private _checkinService: CheckinManager,
    _rootService: RootService,
  ) {
    super(
      CHECKIN_REPORT_FILTER_INITIAL_STATE,
      CHECKIN_REPORT_FILTER_NON_ARRAY_TYPES,
      CHECKIN_REPORT_FILTER_PERSON_EMITTERS,
      _reportService,
      _rootService,
    );
  }

  public async getCheckinReport(
    offset: number = 0,
    limit: number = 10,
    sortBy?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.CHECKIN,
      offset,
      limit,
      sortBy,
    );
    return {
      items: infos.map(info =>
        CheckinsMapper.fromProtoEditable(info.getEditableCheckin()),
      ),
      pageToken,
    };
  }

  public async getAbsenteesReport(
    offset: number = 0,
    limit: number = 10,
    sortBy?: Sort,
  ) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.CHECKIN_ABSENTEES,
      offset,
      limit,
      sortBy,
    );
    return {
      items: infos.map(info => {
        const absentee = info.getCheckin();
        const reason = CheckinReasonMapper.fromProto(absentee.getReason());
        const person = CheckinPersonMapper.fromProto(absentee.getPerson());
        return {
          reason,
          person,
        };
      }),
      pageToken,
    };
  }

  /** For handling using single date view with check in reason report */
  public matchStartEndDate() {
    this.setFilter(
      CheckinManagerReportFilterType.END_DATE,
      this.filter.startDate.endOf('day'),
    );
  }

  /**
   * Handle Set Filter
   */
  _handleSetFilter(filter: CheckinManagerReportFilterType, value: any) {
    switch (filter) {
      case CheckinManagerReportFilterType.TYPE: {
        this.filter.type = value;
        break;
      }
      case CheckinManagerReportFilterType.REASON: {
        this.filter.reason = value;
        break;
      }
      case CheckinManagerReportFilterType.GRADE: {
        this.filter.grade = value;
        break;
      }
      case CheckinManagerReportFilterType.ISSUED_TO: {
        this.filter.issuedTo = value;
        break;
      }
      case CheckinManagerReportFilterType.ISSUED_BY: {
        this.filter.issuedBy = value;
        break;
      }
      case CheckinManagerReportFilterType.ROLE: {
        this.filter.role = value;
        break;
      }
      case CheckinManagerReportFilterType.END_DATE: {
        this.filter.endDate = value;
        break;
      }
      case CheckinManagerReportFilterType.START_DATE: {
        this.filter.startDate = value;
        break;
      }
      case CheckinManagerReportFilterType.USER_LIST: {
        this.filter.userList = value;
        break;
      }
      default: {
        break;
      }
    }
  }

  protected _mapFiltersToFilterMessage(
    reportType: ReportTypes,
    offset?: number,
    limit?: number,
  ) {
    const req = new report_proto.GetOrExportReportRequest();
    const filter = {
      ...this.filter,
      issuedTo: this.filter.issuedTo?.map((p: any) => p.hash || p.personHash),
      issuedBy: this.filter.issuedBy?.map((p: any) => p.hash || p.personHash),
    };
    const filterMessage = CheckInFilterMapper.toProto(filter, limit, offset);
    req.setReportType(reportType);
    req.setCheckInFilters(filterMessage);
    return req;
  }

  public getPerson() {
    // gets the first person in the filter array. Used to check if we
    // are looking at the history of a person's check ins.
    return this.filter.issuedTo[0];
  }

  public async archiveCheckin(checkin: Checkin | Checkin[]) {
    let checkinIds: number[] = [];
    if (Array.isArray(checkin)) {
      checkinIds = checkin.map(p => p.id);
    } else {
      checkinIds = [checkin.id];
    }

    const req = new checkin_proto.ArchiveCheckinRequest();
    req.setIdsList(checkinIds);
    await this._checkinService.archiveCheckin(req);
  }
}
