import { Injectable } from '@angular/core';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { IDateRangePreset } from 'minga/libraries/domain';

import { DatePresetsService } from '@shared/services/date-presets/date-presets.service';

import { ClientDatePreset } from '../types/mm-date-presets.types';
import { customDatePresetMapper } from '../utils/date-presets.utils';

export interface DatePresetsState extends EntityState<ClientDatePreset> {
  isLoading: boolean;
  error: string | null;
}

const initialState = {
  isLoading: false,
  error: null,
};

const adapter = createEntityAdapter<ClientDatePreset>({
  selectId: (item: ClientDatePreset) => item.id as string,
});

const { selectAll } = adapter.getSelectors();

@Injectable({ providedIn: 'root' })
export class DatePresetsStore extends ComponentStore<DatePresetsState> {
  // reducers
  private _setIsLoading = this.updater(state => ({
    ...state,
    isLoading: true,
  }));
  private _setError = this.updater((state, error: string) => ({
    ...state,
    isLoading: false,
    error,
  }));
  private _setItems = this.updater((state, items: ClientDatePreset[]) =>
    adapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  );

  private _updateCache = () => {
    const items = selectAll(this.get());
    this._datePresetsService.updateCache(items);
  };

  public updateDatePreset = this.effect<IDateRangePreset>(item$ =>
    item$.pipe(
      tap((item: IDateRangePreset) => {
        this.setState(state =>
          adapter.setOne(customDatePresetMapper(item), state),
        );
        this._updateCache();
      }),
    ),
  );

  public addDatePreset = this.effect<IDateRangePreset>(item$ =>
    item$.pipe(
      tap((item: IDateRangePreset) => {
        this.setState(state =>
          adapter.addOne(customDatePresetMapper(item), state),
        );
        this._updateCache();
      }),
    ),
  );

  public removeDatePreset = this.effect<number>(id$ =>
    id$.pipe(
      tap((id: number) => {
        this.setState(state => adapter.removeOne(id, state));
        this._updateCache();
      }),
    ),
  );

  public toggleActiveDatePreset = this.effect<{ id: number; active: boolean }>(
    toggle$ =>
      toggle$.pipe(
        tap(({ id, active }) => {
          const update: Update<ClientDatePreset> = {
            id,
            changes: { active },
          };
          this.setState(state => adapter.updateOne(update, state));
          this._updateCache();
        }),
      ),
  );

  // selectors
  public isLoading$: Observable<boolean> = this.select(
    state => state.isLoading,
  );
  public error$ = this.select(state => state.error);
  public datePresets$ = this.select(state => selectAll(state));

  // effects
  public getDatePresets = this.effect<{
    onlyActive: boolean;
    revalidate?: boolean;
  }>(opts$ => {
    return opts$.pipe(
      tap(() => {
        this._setIsLoading();
      }),
      switchMap(opts => {
        return from(this._datePresetsService.fetchPresets(opts)).pipe(
          tapResponse(
            items => this._setItems(items),
            (error: any) => this._setError(error.message),
          ),
        );
      }),
    );
  });

  constructor(private _datePresetsService: DatePresetsService) {
    super(adapter.getInitialState(initialState));
  }
}
