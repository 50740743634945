"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttendeeRole = void 0;
const domain_1 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const UserRole_1 = require("minga/libraries/shared/permissions/internal/UserRole");
class AttendeeRole extends UserRole_1.UserRole {
    constructor() {
        super(...arguments);
        this.roleType = domain_1.MingaRoleType.ATTENDEE;
        this.name = 'Attendee';
        this.displayNameFormat = util_1.DisplayNameFormat.FIRST_LAST;
        this.iconUrl = '';
        this.iconColor = '#1DCE4D';
        this.capabilitiesDescription = `Attendees will be allowed to share posts, pictures, and videos`;
        this.feedPermittable = true;
        this.galleryPermittable = true;
        this.groupFeedPermittable = true;
        this.programManagePermittable = false;
        this.videoUploadPermittable = true;
        this.commentPermittable = true;
        this.groupCreatePermittable = true;
        this.admin = false;
        this.requiredFeatures = [];
        this.sendToMailChimp = false;
        this.permissions = [
            domain_1.MingaPermission.ACCOUNT_PROFILE_CHANGE,
            domain_1.MingaPermission.ASSET_UPLOAD,
            domain_1.MingaPermission.CONTENT_COMMENT_CREATE,
            domain_1.MingaPermission.CONTENT_COMMENTS_VIEW,
            domain_1.MingaPermission.CONTENT_GROUP_ENABLED,
            domain_1.MingaPermission.CONTENT_LIKES_LIKE,
            domain_1.MingaPermission.CONTENT_POST_CREATE,
            domain_1.MingaPermission.CONTENT_REPORT_CREATE,
            domain_1.MingaPermission.CONTENT_VIDEO_CREATE,
            domain_1.MingaPermission.CONTENT_POLL_ANSWER,
            domain_1.MingaPermission.GROUP_CONTENT_POST_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
        ];
        this.overridablePermissions = [];
    }
}
exports.AttendeeRole = AttendeeRole;