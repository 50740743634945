import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'lodash';

import { KeepDiscardDialogComponent } from 'minga/app/src/app/dialog';
import { IChallengeResponseDraft } from 'minga/app/src/app/modules/challenges/types';
import { UserStorage } from 'minga/app/src/app/services/UserStorage';
import {
  ChallengeColor,
  ChallengePrivacy,
  ChallengeType,
} from 'minga/libraries/domain';
import { RecursivePartial } from 'minga/libraries/util';

const STORAGE_DRAFT_KEY = 'MingaChallengeResponseDraft';

function mergeDrafts(
  draft: IChallengeResponseDraft,
  ...drafts: RecursivePartial<IChallengeResponseDraft>[]
): IChallengeResponseDraft;
function mergeDrafts(
  draft: RecursivePartial<IChallengeResponseDraft>,
  ...drafts: RecursivePartial<IChallengeResponseDraft>[]
): RecursivePartial<IChallengeResponseDraft>;
function mergeDrafts(
  draft: RecursivePartial<IChallengeResponseDraft> | IChallengeResponseDraft,
  ...drafts: RecursivePartial<IChallengeResponseDraft>[]
): RecursivePartial<IChallengeResponseDraft> | IChallengeResponseDraft {
  return _.merge(draft, ...drafts);
}

@Injectable({ providedIn: 'root' })
export class ChallengeResponseDraftService {
  /** @internal */
  private draftSaveTimeout: any;
  /** @internal - pending draft that is going to be saved delayed */
  private _pendingDraft: RecursivePartial<IChallengeResponseDraft> = {};

  constructor(private userStorage: UserStorage, private matDialog: MatDialog) {}

  async cancelDraft() {
    let dialogRef = this.matDialog.open(KeepDiscardDialogComponent);
    const value = await dialogRef.beforeClosed().toPromise();

    if (value === 'keep') {
      return '';
    } else if (value === 'discard') {
      await this.clearDraft();
      return '';
    } else {
      throw new Error('User abort');
    }
  }

  async getDraftType(draft?: IChallengeResponseDraft): Promise<ChallengeType> {
    if (!draft) {
      const _draft = await this.getDraft();
      if (_draft) {
        draft = _draft;
      } else {
        throw new Error(`No Challenge Response Draft found for draft type!`);
      }
    }

    return draft.type;
  }

  async clearDraft() {
    await this.userStorage.setItem(STORAGE_DRAFT_KEY, null);
  }

  /**
   * Get the stored draft.
   * @returns `null` when no draft was stored
   */
  async getDraft(): Promise<IChallengeResponseDraft | null> {
    const draft = await this.userStorage.getItem<IChallengeResponseDraft>(
      STORAGE_DRAFT_KEY,
    );
    return draft || null;
  }

  /**
   * Get the stored draft. If there is no stored draft call `defaultFn` and
   * store and return the result.
   */
  async getDraftDefaulted(
    ownerContextHash: string,
    defaultFn: () => IChallengeResponseDraft | Promise<IChallengeResponseDraft>,
  ): Promise<IChallengeResponseDraft> {
    let draft = await this.getDraft();
    if (
      !draft ||
      (ownerContextHash && !(draft.ownerContextHash == ownerContextHash))
    ) {
      draft = await defaultFn();
      await this.storeDraft(draft);
    }

    return draft;
  }

  getDefaultResponse(): IChallengeResponseDraft {
    return {
      body: '',
      privacy: ChallengePrivacy.PUBLIC,
      type: ChallengeType.TEXT,
    };
  }

  /**
   * Get the stored draft and return empty object if not set
   */
  async getDraftAssert(): Promise<IChallengeResponseDraft | {}> {
    const draft = await this.getDraft();
    if (!draft) {
      return {};
    }

    return draft;
  }

  async saveDraft(draft: RecursivePartial<IChallengeResponseDraft>) {
    const pendingDraft = { ...this._pendingDraft };

    this._pendingDraft = mergeDrafts(pendingDraft, draft);
    await this.savePendingDraft();
  }

  saveDraftDelayed(
    draft: RecursivePartial<IChallengeResponseDraft>,
    time = 1000,
  ) {
    const pendingDraft = { ...this._pendingDraft };

    this._pendingDraft = mergeDrafts(pendingDraft, draft);

    clearTimeout(this.draftSaveTimeout);
    this.draftSaveTimeout = setTimeout(() => this.savePendingDraft(), time);
  }

  private async savePendingDraft() {
    clearTimeout(this.draftSaveTimeout);
    const pendingDraft = { ...this._pendingDraft };
    this._pendingDraft = {};

    const previousDraft = await this.getDraftAssert();

    const newDraft = mergeDrafts(previousDraft, pendingDraft);

    // choose a single background type
    if (!pendingDraft.banner && pendingDraft.backgroundColor)
      newDraft.banner = undefined;
    else if (pendingDraft.banner && !pendingDraft.backgroundColor)
      newDraft.backgroundColor = undefined;

    await this.storeDraft(newDraft);
  }

  private async storeDraft(
    draft: IChallengeResponseDraft | RecursivePartial<IChallengeResponseDraft>,
  ) {
    await this.userStorage.setItem<
      IChallengeResponseDraft | RecursivePartial<IChallengeResponseDraft>
    >(STORAGE_DRAFT_KEY, draft);
  }
}
