import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import { ReplaySubject } from 'rxjs';

import { BottomSheetEventType } from '@shared/components/bottom-sheet';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FormSheetSelectMessage } from './constants';
import {
  FormSheetSelectData,
  FormSheetSelectOption,
  FormSheetSelectResponseData,
} from './types';
import { FormSheetSelectFilter, FormSheetSelectSelection } from './utils';

@Component({
  selector: 'mg-form-sheet-select',
  templateUrl: './form-sheet-select.component.html',
  styleUrls: ['./form-sheet-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSheetSelectComponent implements AfterViewInit, OnDestroy {
  // Children

  @ViewChild('mainBottomSheetContentElement')
  public mainBottomSheetContentElement!: ElementRef<HTMLElement>;

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  public viewport!: CdkVirtualScrollViewport;

  // Constants

  public readonly MESSAGE = FormSheetSelectMessage;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Filtered results subject

  public readonly filter = new FormSheetSelectFilter(
    this.data.options,
    this._destroyedSubject,
  );

  // Selection

  public readonly selection = new FormSheetSelectSelection({
    multiple: this.data.multiple,
    initialSelection: this.data.initialSelection,
  });

  // State

  private _sheetContentContainerHeight = 0;

  // Getters

  public get sheetContentContainerHeight(): string {
    if (!this.data?.fullHeight) return '40vh';
    return `${this._sheetContentContainerHeight}px`;
  }

  /** Component constructor */
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: FormSheetSelectData,
    public systemAlert: SystemAlertSnackBarService,
    private _bottomSheetRef: MatBottomSheetRef<FormSheetSelectComponent>,
  ) {}

  ngAfterViewInit(): void {
    this._checkAndSetViewportHeight();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.filter.destroy();
  }

  public onEsc() {
    const response = {
      type: BottomSheetEventType.ESC,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public selectOption(option: FormSheetSelectOption) {
    try {
      this.selection.toggle(option);
    } catch {
      this.systemAlert.error('Error selecting option');
    }
  }

  public submit() {
    const response: FormSheetSelectResponseData = {
      type: BottomSheetEventType.SUBMIT,
      data: {
        selection: this.selection.selected,
      },
    };
    this._bottomSheetRef.dismiss(response);
  }

  public handleClearOrCancel() {
    if (this.data.canCancel) {
      this.cancel();
    } else {
      this.selection.clear();
    }
  }

  public cancel() {
    const response = {
      type: BottomSheetEventType.CANCEL,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public onClose() {
    const response = {
      type: BottomSheetEventType.CLOSE,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  private _checkAndSetViewportHeight(): void {
    if (
      this.data.fullHeight &&
      this.mainBottomSheetContentElement.nativeElement
    ) {
      const { paddingTop, paddingBottom } = getComputedStyle(
        this.mainBottomSheetContentElement.nativeElement,
      );
      this._sheetContentContainerHeight =
        this.mainBottomSheetContentElement.nativeElement.offsetHeight -
        parseFloat(paddingTop) -
        parseFloat(paddingBottom);
    }
    // timeout magic fix for making sure viewport is
    // available before checking size
    setTimeout(() => this.viewport.checkViewportSize(), 20);
  }
}
