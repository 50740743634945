import Fuse from 'fuse.js';

import { SelectElementOption } from '../types';

export enum SelectElementMessage {
  EMPTY_STATE_TITLE = 'No results found',
  EMPTY_STATE_SUBTITLE = 'Try a different search',
}

export const SELECT_ELEMENT_FUSE_OPTIONS: Fuse.IFuseOptions<SelectElementOption> =
  {
    isCaseSensitive: false,
    shouldSort: true,
    keys: ['label', 'value'],
    threshold: 0.2,
  };
