<div class="sidebar-footer">
  <div
    class="sidebar-footer-fields"
    fxLayout="column"
    fxLayoutGap="12px">
    <!-- we use remount$ flag here as an easy way to reset any of the sub fields on assignment success -->
    <ng-container
      *ngIf="
        (remount$ | async) === false &&
        form.get(FORM_FIELDS.SELECTED_ACTION).value
          ?.assignmentType as assignmentType
      ">
      <mg-tt-hallpass-fields
        *ngIf="assignmentType === ASSIGNMENT_TYPE.HALLPASS"
        [form]="form"
        [type]="
          form.get(FORM_FIELDS.SELECTED_ACTION).value.data
        "></mg-tt-hallpass-fields>
      <mg-tt-consequence-fields
        class="block-assignment-input"
        *ngIf="assignmentType === ASSIGNMENT_TYPE.CONSEQUENCE"
        [form]="form"
        [type]="
          form.get(FORM_FIELDS.SELECTED_ACTION).value.data
        "></mg-tt-consequence-fields>
      <mg-tt-behavior-fields
        class="block-assignment-input"
        *ngIf="assignmentType === ASSIGNMENT_TYPE.BEHAVIOR"
        [form]="form"
        [type]="
          form.get(FORM_FIELDS.SELECTED_ACTION).value.data
        "></mg-tt-behavior-fields>
    </ng-container>
    <div
      *ngIf="formErrors.length > 0"
      fxLayout="column"
      fxLayoutGap="8px">
      <mg-system-alert
        class="sidebar-footer-error"
        *ngFor="let error of formErrors"
        type="error"
        [message]="error"></mg-system-alert>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutAlign.gt-sm="end center">
      <mg-btn
        class="assign-btn"
        [id]="
          form.get(FORM_FIELDS.SELECTED_ACTION).value?.assignmentType ===
          ASSIGNMENT_TYPE.COMMUNICATION
            ? 'tools-myclass-click-next'
            : 'tools-myclass-click-assign-' +
              (form.get(FORM_FIELDS.SELECTED_ACTION).value?.assignmentType ||
                'action')
        "
        [wide]="true"
        [disabled]="LOCKED_STATES | includes: formState"
        [iconRight]="(mediaService.isMobileView$ | async) ? '' : 'mg-space'"
        iconSet="minga"
        (pressed)="assign.emit()">
        {{
          form.get(FORM_FIELDS.SELECTED_ACTION).value?.assignmentType ===
          ASSIGNMENT_TYPE.COMMUNICATION
            ? MESSAGES.NEXT_BTN
            : MESSAGES.ASSIGN_BTN
        }}
      </mg-btn>
    </div>
  </div>
</div>
