import { Injectable } from '@angular/core';

import { $enum } from 'ts-enum-util';

import { PermissionsService } from 'minga/app/src/app/permissions';
import { MingaPermission } from 'minga/libraries/domain';

@Injectable({ providedIn: 'root' })
export class NavPermissions {
  constructor(private permissions: PermissionsService) {}

  /**
   * Checks if user has route permissions for a given tab.
   *
   * @TODO: currently has "only" and "atLeastOne" permission checking, 'except'
   * has not been implemented.
   * @param permissions data.permissions of student id route tab data
   */
  hasTabRoutePermissions(permissions: any) {
    if (!permissions) return true;

    if (Array.isArray(permissions.only)) {
      let onlyPerms = permissions.only.map((p: any) =>
        $enum(MingaPermission).asValueOrThrow(p),
      );
      let hasOnlyPerm = true;

      for (let onlyPerm of onlyPerms) {
        hasOnlyPerm = this.permissions.hasPermission(onlyPerm);
        if (!hasOnlyPerm) return false;
      }
      return hasOnlyPerm;
    }

    if (Array.isArray(permissions.atLeastOne)) {
      let perms = permissions.atLeastOne.map((p: any) =>
        $enum(MingaPermission).asValueOrThrow(p),
      );
      let hasAtleastOnePerm = true;

      for (let perm of perms) {
        hasAtleastOnePerm = this.permissions.hasPermission(perm);
        if (hasAtleastOnePerm) break;
      }

      return hasAtleastOnePerm;
    }

    return true;
  }
}
