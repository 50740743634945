<mat-form-field
  #formFieldElement
  mgFormField
  class="mg-password"
  [ngClass]="{ 'click-state': editMode && !editing }"
  [hintLabel]="hintText"
  [hideRequiredMarker]="true"
  [mgNoHintMargin]="mgNoHintMargin"
  [floatLabel]="floatLabel"
  (click)="enableEditClick()">
  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    #inputElement
    #inputMatElement="matInput"
    autocorrect="off"
    autocapitalize="none"
    [attr.type]="inputType"
    [formControl]="passwordControl"
    [readonly]="enableReadOnlyState && editMode && !editing"
    [autocomplete]="name"
    [required]="required"
    [errorStateMatcher]="errorStateMatcher"
    [(ngModel)]="innerValue"
    (ngModelChange)="change($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus()" />
  <div
    class="input-icon"
    mat-icon-button
    matSuffix>
    <mat-icon *ngIf="saveStatus == SaveState.SAVED">done</mat-icon>
    <mat-icon *ngIf="saveStatus == SaveState.ERROR">error_outline</mat-icon>
    <mg-spinner
      *ngIf="saveStatus == SaveState.SAVING"
      color="primary"
      mode="indeterminate"
      [diameter]="25">
    </mg-spinner>
  </div>
  <mg-btn
    *ngIf="editMode && !editing"
    matSuffix
    variant="text"
    size="small">
    {{ 'button.changePassword' | translate }}
  </mg-btn>
  <div
    *ngIf="!editMode || (editMode && editing && saveStatus == SaveState.NA)"
    class="input-icon"
    matSuffix>
    <mat-icon
      class="clickable"
      (mousedown)="togglePasswordVisibility()">
      {{ passwordIcon }}
    </mat-icon>
  </div>

  <mat-error
    *ngIf="
      passwordControl.invalid &&
      (passwordControl.dirty || passwordControl.touched)
    ">
    <ng-container [ngSwitch]="passwordControlErrorKey">
      <span *ngSwitchCase="'maxlength'">
        <!-- Your password must be 16 letters or less -->
        {{ 'input.password.error.maxlength' | translate }}
      </span>
      <span *ngSwitchCase="'minlength'">
        <!-- Your password must be at least 4 letters long -->
        {{ 'input.password.error.minlength' | translate }}
      </span>
      <span *ngSwitchCase="'pattern'">
        <!-- Please use a mix of letters and numbers -->
        {{ 'input.password.error.pattern' | translate }}
      </span>
      <span *ngSwitchCase="'savePassword'">
        {{ 'form.saveFieldFail' | translate: translateParams }}
      </span>
      <span *ngSwitchCase="'required'">
        <!-- You must provide a password -->
        {{ 'input.password.error.required' | translate }}
      </span>
    </ng-container>
  </mat-error>
</mat-form-field>
