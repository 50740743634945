"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildJoinLinkByCode = exports.buildJoinLinkByPin = void 0;
function buildJoinLinkByPin(options) {
    let joinLink = options.origin + '/landing2' +
        `?pin=${options.pin}&email=${encodeURIComponent(options.email)}`;
    if (options.countryCode) {
        joinLink += `&country=${options.countryCode}`;
    }
    return joinLink;
}
exports.buildJoinLinkByPin = buildJoinLinkByPin;
function buildJoinLinkByCode(options) {
    const emailParam = options.email ? `&email=${encodeURIComponent(options.email)}` : '';
    let joinLink = options.origin + '/landing2' +
        `?code=${options.code}${emailParam}`;
    if (options.countryCode) {
        joinLink += `&country=${options.countryCode}`;
    }
    return joinLink;
}
exports.buildJoinLinkByCode = buildJoinLinkByCode;