"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportPeopleChunk = exports.ExportPeopleRequest = exports.MingaPeopleSearchResult = exports.MingaPeopleInfo = exports.MingaPeopleInfoRequest = exports.ResendAllInvitesResponse = exports.ResendAllInvitesRequest = exports.ResendPinEmailResponse = exports.ResendPinEmailRequest = exports.SyncPeopleBulkResponse = exports.SyncPeopleBulkRequest = exports.ArchivePeopleBulkResponse = exports.ArchivePeopleBulkRequest = exports.DeletePeopleBulkResponse = exports.DeletePeopleBulkRequest = exports.BulkPersonFields = exports.UpdatePersonFields = exports.CreatePersonFields = exports.GetPersonsTeamsResponse = exports.GetPersonsTeamsRequest = exports.GetRolesResponse = exports.GetRolesRequest = exports.OpenMingaResponse = exports.OpenMingaRequest = exports.NextMingaPinResponse = exports.NextMingaPinRequest = exports.PinUniqueResponse = exports.PinUniqueRequest = exports.EmailUniqueResponse = exports.EmailUniqueRequest = exports.StreamPeopleControlResponse = exports.StreamPeopleResponse = exports.StreamPeopleRequest = exports.ReadPeopleResponse = exports.ReadPeopleRequest = exports.SelfArchivePersonResponse = exports.SelfArchivePersonRequest = exports.ArchivePeopleResponse = exports.ArchivePeopleRequest = exports.DeletePeopleResponse = exports.DeletePeopleRequest = exports.UpdatePeopleResponse = exports.UpdatePeopleRequest = exports.CreatePeopleResponse = exports.CreatePeopleRequest = exports.PersonDelta = exports.AccountDelta = exports.IdentityDelta = exports.Person = exports.Account = exports.Identity = exports.Role = void 0;
const jspb = require("google-protobuf");
const common_delta_pb = require("minga/proto/common/delta_pb");
const common_date_pb = require("minga/proto/common/date_pb");
const common_legacy_pb = require("minga/proto/common/legacy_pb");
const common_stream_pb = require("minga/proto/common/stream_pb");
const gateway_person_view_pb = require("minga/proto/gateway/person_view_pb");
class Role extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return Role.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!Role} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displaynameformat: jspb.Message.getFieldWithDefault(msg, 3, 0),
            rolefields: jspb.Message.getFieldWithDefault(msg, 4, 0),
            type: jspb.Message.getFieldWithDefault(msg, 5, ""),
            feedPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            capabilitiesDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
            joinViaCodeApplicable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            immutableFeedPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            immutableGroupFeedPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
            iconColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
            galleryPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
            immutableGalleryPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
            programManagePermittable: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
            immutableProgramManagePermittable: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
            videoUploadPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
            immutableVideoUploadPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
            admin: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
            commentPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
            immutableCommentPermittable: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
            billable: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
            groupCreatePermittable: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
            immutableGroupCreatePermittable: jspb.Message.getBooleanFieldWithDefault(msg, 23, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!Role}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new Role;
        return Role.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!Role} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!Role}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setName(value2);
                    break;
                case 3:
                    var value3 = /** @type {!Role.DisplayNameFormat} */ (reader.readEnum());
                    msg.setDisplaynameformat(value3);
                    break;
                case 4:
                    var value4 = /** @type {!Role.RoleFields} */ (reader.readEnum());
                    msg.setRolefields(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setType(value5);
                    break;
                case 6:
                    var value6 = /** @type {boolean} */ (reader.readBool());
                    msg.setFeedPermittable(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setCapabilitiesDescription(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setJoinViaCodeApplicable(value8);
                    break;
                case 9:
                    var value9 = /** @type {boolean} */ (reader.readBool());
                    msg.setImmutableFeedPermittable(value9);
                    break;
                case 10:
                    var value10 = /** @type {boolean} */ (reader.readBool());
                    msg.setImmutableGroupFeedPermittable(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setIconColor(value11);
                    break;
                case 12:
                    var value12 = /** @type {boolean} */ (reader.readBool());
                    msg.setGalleryPermittable(value12);
                    break;
                case 13:
                    var value13 = /** @type {boolean} */ (reader.readBool());
                    msg.setImmutableGalleryPermittable(value13);
                    break;
                case 14:
                    var value14 = /** @type {boolean} */ (reader.readBool());
                    msg.setProgramManagePermittable(value14);
                    break;
                case 15:
                    var value15 = /** @type {boolean} */ (reader.readBool());
                    msg.setImmutableProgramManagePermittable(value15);
                    break;
                case 16:
                    var value16 = /** @type {boolean} */ (reader.readBool());
                    msg.setVideoUploadPermittable(value16);
                    break;
                case 17:
                    var value17 = /** @type {boolean} */ (reader.readBool());
                    msg.setImmutableVideoUploadPermittable(value17);
                    break;
                case 18:
                    var value18 = /** @type {boolean} */ (reader.readBool());
                    msg.setAdmin(value18);
                    break;
                case 19:
                    var value19 = /** @type {boolean} */ (reader.readBool());
                    msg.setCommentPermittable(value19);
                    break;
                case 20:
                    var value20 = /** @type {boolean} */ (reader.readBool());
                    msg.setImmutableCommentPermittable(value20);
                    break;
                case 21:
                    var value21 = /** @type {boolean} */ (reader.readBool());
                    msg.setBillable(value21);
                    break;
                case 22:
                    var value22 = /** @type {boolean} */ (reader.readBool());
                    msg.setGroupCreatePermittable(value22);
                    break;
                case 23:
                    var value23 = /** @type {boolean} */ (reader.readBool());
                    msg.setImmutableGroupCreatePermittable(value23);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!Role} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDisplaynameformat();
        if (f !== 0.0) {
            writer.writeEnum(3, f);
        }
        f = message.getRolefields();
        if (f !== 0.0) {
            writer.writeEnum(4, f);
        }
        f = message.getType();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getFeedPermittable();
        if (f) {
            writer.writeBool(6, f);
        }
        f = message.getCapabilitiesDescription();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getJoinViaCodeApplicable();
        if (f) {
            writer.writeBool(8, f);
        }
        f = message.getImmutableFeedPermittable();
        if (f) {
            writer.writeBool(9, f);
        }
        f = message.getImmutableGroupFeedPermittable();
        if (f) {
            writer.writeBool(10, f);
        }
        f = message.getIconColor();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getGalleryPermittable();
        if (f) {
            writer.writeBool(12, f);
        }
        f = message.getImmutableGalleryPermittable();
        if (f) {
            writer.writeBool(13, f);
        }
        f = message.getProgramManagePermittable();
        if (f) {
            writer.writeBool(14, f);
        }
        f = message.getImmutableProgramManagePermittable();
        if (f) {
            writer.writeBool(15, f);
        }
        f = message.getVideoUploadPermittable();
        if (f) {
            writer.writeBool(16, f);
        }
        f = message.getImmutableVideoUploadPermittable();
        if (f) {
            writer.writeBool(17, f);
        }
        f = message.getAdmin();
        if (f) {
            writer.writeBool(18, f);
        }
        f = message.getCommentPermittable();
        if (f) {
            writer.writeBool(19, f);
        }
        f = message.getImmutableCommentPermittable();
        if (f) {
            writer.writeBool(20, f);
        }
        f = message.getBillable();
        if (f) {
            writer.writeBool(21, f);
        }
        f = message.getGroupCreatePermittable();
        if (f) {
            writer.writeBool(22, f);
        }
        f = message.getImmutableGroupCreatePermittable();
        if (f) {
            writer.writeBool(23, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        Role.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string name = 2;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional DisplayNameFormat displayNameFormat = 3;
     * @return {!Role.DisplayNameFormat}
     */
    getDisplaynameformat() {
        return /** @type {!Role.DisplayNameFormat} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
    }
    ;
    /** @param {!Role.DisplayNameFormat} value */
    setDisplaynameformat(value) {
        jspb.Message.setProto3EnumField(this, 3, value);
    }
    /**
     * optional RoleFields roleFields = 4;
     * @return {!Role.RoleFields}
     */
    getRolefields() {
        return /** @type {!Role.RoleFields} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
    }
    ;
    /** @param {!Role.RoleFields} value */
    setRolefields(value) {
        jspb.Message.setProto3EnumField(this, 4, value);
    }
    /**
     * optional string type = 5;
     * @return {string}
     */
    getType() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setType(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional bool feed_permittable = 6;
     * @return {boolean}
     */
    getFeedPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
    }
    ;
    /** @param {boolean} value */
    setFeedPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 6, value);
    }
    /**
     * optional string capabilities_description = 7;
     * @return {string}
     */
    getCapabilitiesDescription() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setCapabilitiesDescription(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * optional bool join_via_code_applicable = 8;
     * @return {boolean}
     */
    getJoinViaCodeApplicable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setJoinViaCodeApplicable(value) {
        jspb.Message.setProto3BooleanField(this, 8, value);
    }
    /**
     * optional bool immutable_feed_permittable = 9;
     * @return {boolean}
     */
    getImmutableFeedPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
    }
    ;
    /** @param {boolean} value */
    setImmutableFeedPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 9, value);
    }
    /**
     * optional bool immutable_group_feed_permittable = 10;
     * @return {boolean}
     */
    getImmutableGroupFeedPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
    }
    ;
    /** @param {boolean} value */
    setImmutableGroupFeedPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 10, value);
    }
    /**
     * optional string icon_color = 11;
     * @return {string}
     */
    getIconColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setIconColor(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional bool gallery_permittable = 12;
     * @return {boolean}
     */
    getGalleryPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
    }
    ;
    /** @param {boolean} value */
    setGalleryPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 12, value);
    }
    /**
     * optional bool immutable_gallery_permittable = 13;
     * @return {boolean}
     */
    getImmutableGalleryPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
    }
    ;
    /** @param {boolean} value */
    setImmutableGalleryPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 13, value);
    }
    /**
     * optional bool program_manage_permittable = 14;
     * @return {boolean}
     */
    getProgramManagePermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
    }
    ;
    /** @param {boolean} value */
    setProgramManagePermittable(value) {
        jspb.Message.setProto3BooleanField(this, 14, value);
    }
    /**
     * optional bool immutable_program_manage_permittable = 15;
     * @return {boolean}
     */
    getImmutableProgramManagePermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
    }
    ;
    /** @param {boolean} value */
    setImmutableProgramManagePermittable(value) {
        jspb.Message.setProto3BooleanField(this, 15, value);
    }
    /**
     * optional bool video_upload_permittable = 16;
     * @return {boolean}
     */
    getVideoUploadPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
    }
    ;
    /** @param {boolean} value */
    setVideoUploadPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 16, value);
    }
    /**
     * optional bool immutable_video_upload_permittable = 17;
     * @return {boolean}
     */
    getImmutableVideoUploadPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
    }
    ;
    /** @param {boolean} value */
    setImmutableVideoUploadPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 17, value);
    }
    /**
     * optional bool admin = 18;
     * @return {boolean}
     */
    getAdmin() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
    }
    ;
    /** @param {boolean} value */
    setAdmin(value) {
        jspb.Message.setProto3BooleanField(this, 18, value);
    }
    /**
     * optional bool comment_permittable = 19;
     * @return {boolean}
     */
    getCommentPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
    }
    ;
    /** @param {boolean} value */
    setCommentPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 19, value);
    }
    /**
     * optional bool immutable_comment_permittable = 20;
     * @return {boolean}
     */
    getImmutableCommentPermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
    }
    ;
    /** @param {boolean} value */
    setImmutableCommentPermittable(value) {
        jspb.Message.setProto3BooleanField(this, 20, value);
    }
    /**
     * optional bool billable = 21;
     * @return {boolean}
     */
    getBillable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
    }
    ;
    /** @param {boolean} value */
    setBillable(value) {
        jspb.Message.setProto3BooleanField(this, 21, value);
    }
    /**
     * optional bool group_create_permittable = 22;
     * @return {boolean}
     */
    getGroupCreatePermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
    }
    ;
    /** @param {boolean} value */
    setGroupCreatePermittable(value) {
        jspb.Message.setProto3BooleanField(this, 22, value);
    }
    /**
     * optional bool immutable_group_create_permittable = 23;
     * @return {boolean}
     */
    getImmutableGroupCreatePermittable() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
    }
    ;
    /** @param {boolean} value */
    setImmutableGroupCreatePermittable(value) {
        jspb.Message.setProto3BooleanField(this, 23, value);
    }
}
exports.Role = Role;
Role.displayName = "Role";
(function (Role) {
    let DisplayNameFormat;
    (function (DisplayNameFormat) {
        DisplayNameFormat[DisplayNameFormat["FIRST_L"] = 0] = "FIRST_L";
        DisplayNameFormat[DisplayNameFormat["LAST_F"] = 1] = "LAST_F";
        DisplayNameFormat[DisplayNameFormat["FIRST_LAST"] = 2] = "FIRST_LAST";
        DisplayNameFormat[DisplayNameFormat["LAST_FIRST"] = 3] = "LAST_FIRST";
        DisplayNameFormat[DisplayNameFormat["F_LAST"] = 4] = "F_LAST";
        DisplayNameFormat[DisplayNameFormat["L_FIRST"] = 5] = "L_FIRST";
    })(DisplayNameFormat = Role.DisplayNameFormat || (Role.DisplayNameFormat = {}));
})(Role = exports.Role || (exports.Role = {})); // namespace Role
(function (Role) {
    let RoleFields;
    (function (RoleFields) {
        RoleFields[RoleFields["DEFAULT"] = 0] = "DEFAULT";
        RoleFields[RoleFields["GRAD"] = 1] = "GRAD";
        RoleFields[RoleFields["STUDENT"] = 2] = "STUDENT";
        RoleFields[RoleFields["GRAD_STUDENT"] = 3] = "GRAD_STUDENT";
    })(RoleFields = Role.RoleFields || (Role.RoleFields = {}));
})(Role = exports.Role || (exports.Role = {})); // namespace Role
class Identity extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return Identity.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!Identity} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hash: (f = msg.getHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
            emailAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
            password: jspb.Message.getFieldWithDefault(msg, 3, ""),
            firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
            lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
            pin: jspb.Message.getFieldWithDefault(msg, 6, 0),
            studentId: jspb.Message.getFieldWithDefault(msg, 7, ""),
            graduatingYear: jspb.Message.getFieldWithDefault(msg, 8, 0),
            hasPassword: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            phoneNumber: jspb.Message.getFieldWithDefault(msg, 10, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!Identity}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new Identity;
        return Identity.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!Identity} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!Identity}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_legacy_pb.UniqueHash;
                    reader.readMessage(value1, common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
                    msg.setHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setEmailAddress(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setPassword(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setFirstName(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setLastName(value5);
                    break;
                case 6:
                    var value6 = /** @type {number} */ (reader.readInt32());
                    msg.setPin(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value7);
                    break;
                case 8:
                    var value8 = /** @type {number} */ (reader.readInt32());
                    msg.setGraduatingYear(value8);
                    break;
                case 9:
                    var value9 = /** @type {boolean} */ (reader.readBool());
                    msg.setHasPassword(value9);
                    break;
                case 10:
                    var value10 = /** @type {string} */ (reader.readString());
                    msg.setPhoneNumber(value10);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!Identity} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHash();
        if (f != null) {
            writer.writeMessage(1, f, common_legacy_pb.UniqueHash.serializeBinaryToWriter);
        }
        f = message.getEmailAddress();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getPassword();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getFirstName();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getLastName();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getPin();
        if (f !== 0) {
            writer.writeInt32(6, f);
        }
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getGraduatingYear();
        if (f !== 0) {
            writer.writeInt32(8, f);
        }
        f = message.getHasPassword();
        if (f) {
            writer.writeBool(9, f);
        }
        f = message.getPhoneNumber();
        if (f.length > 0) {
            writer.writeString(10, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        Identity.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional UniqueHash hash = 1;
     * @return {?UniqueHash}
     */
    getHash() {
        return /** @type{?UniqueHash} */ (jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 1));
    }
    /** @param {?UniqueHash|undefined} value */
    setHash(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearHash() {
        this.setHash(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasHash() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional string email_address = 2;
     * @return {string}
     */
    getEmailAddress() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setEmailAddress(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string password = 3;
     * @return {string}
     */
    getPassword() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setPassword(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string first_name = 4;
     * @return {string}
     */
    getFirstName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setFirstName(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional string last_name = 5;
     * @return {string}
     */
    getLastName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setLastName(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional int32 pin = 6;
     * @return {number}
     */
    getPin() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
    }
    ;
    /** @param {number} value */
    setPin(value) {
        jspb.Message.setProto3IntField(this, 6, value);
    }
    /**
     * optional string student_id = 7;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * optional int32 graduating_year = 8;
     * @return {number}
     */
    getGraduatingYear() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
    }
    ;
    /** @param {number} value */
    setGraduatingYear(value) {
        jspb.Message.setProto3IntField(this, 8, value);
    }
    /**
     * optional bool has_password = 9;
     * @return {boolean}
     */
    getHasPassword() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
    }
    ;
    /** @param {boolean} value */
    setHasPassword(value) {
        jspb.Message.setProto3BooleanField(this, 9, value);
    }
    /**
     * optional string phone_number = 10;
     * @return {string}
     */
    getPhoneNumber() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
    }
    ;
    /** @param {string} value */
    setPhoneNumber(value) {
        jspb.Message.setProto3StringField(this, 10, value);
    }
}
exports.Identity = Identity;
Identity.displayName = "Identity";
class Account extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, Account.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return Account.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!Account} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
            mingaHash: (f = msg.getMingaHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
            profileImageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
            customDisplayName: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            disabledDm: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            grade: jspb.Message.getFieldWithDefault(msg, 8, ""),
            asb: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            profileCoverImageUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
            idField1: jspb.Message.getFieldWithDefault(msg, 11, ""),
            idField2: jspb.Message.getFieldWithDefault(msg, 12, ""),
            parentEmailList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
            parentPhoneList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
            disableParentNotification: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
            sisInclude: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!Account}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new Account;
        return Account.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!Account} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!Account}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setDisplayName(value1);
                    break;
                case 2:
                    var value2 = new common_legacy_pb.UniqueHash;
                    reader.readMessage(value2, common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
                    msg.setMingaHash(value2);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setProfileImageUrl(value5);
                    break;
                case 6:
                    var value6 = /** @type {boolean} */ (reader.readBool());
                    msg.setCustomDisplayName(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setDisabledDm(value7);
                    break;
                case 8:
                    var value8 = /** @type {string} */ (reader.readString());
                    msg.setGrade(value8);
                    break;
                case 9:
                    var value9 = /** @type {boolean} */ (reader.readBool());
                    msg.setAsb(value9);
                    break;
                case 10:
                    var value10 = /** @type {string} */ (reader.readString());
                    msg.setProfileCoverImageUrl(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setIdField1(value11);
                    break;
                case 12:
                    var value12 = /** @type {string} */ (reader.readString());
                    msg.setIdField2(value12);
                    break;
                case 13:
                    var value13 = /** @type {string} */ (reader.readString());
                    msg.addParentEmail(value13);
                    break;
                case 14:
                    var value14 = /** @type {string} */ (reader.readString());
                    msg.addParentPhone(value14);
                    break;
                case 15:
                    var value15 = /** @type {boolean} */ (reader.readBool());
                    msg.setDisableParentNotification(value15);
                    break;
                case 16:
                    var value16 = /** @type {boolean} */ (reader.readBool());
                    msg.setSisInclude(value16);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!Account} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getDisplayName();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getMingaHash();
        if (f != null) {
            writer.writeMessage(2, f, common_legacy_pb.UniqueHash.serializeBinaryToWriter);
        }
        f = message.getProfileImageUrl();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getCustomDisplayName();
        if (f) {
            writer.writeBool(6, f);
        }
        f = message.getDisabledDm();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getGrade();
        if (f.length > 0) {
            writer.writeString(8, f);
        }
        f = message.getAsb();
        if (f) {
            writer.writeBool(9, f);
        }
        f = message.getProfileCoverImageUrl();
        if (f.length > 0) {
            writer.writeString(10, f);
        }
        f = message.getIdField1();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getIdField2();
        if (f.length > 0) {
            writer.writeString(12, f);
        }
        f = message.getParentEmailList();
        if (f.length > 0) {
            writer.writeRepeatedString(13, f);
        }
        f = message.getParentPhoneList();
        if (f.length > 0) {
            writer.writeRepeatedString(14, f);
        }
        f = message.getDisableParentNotification();
        if (f) {
            writer.writeBool(15, f);
        }
        f = message.getSisInclude();
        if (f) {
            writer.writeBool(16, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        Account.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string display_name = 1;
     * @return {string}
     */
    getDisplayName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setDisplayName(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional UniqueHash minga_hash = 2;
     * @return {?UniqueHash}
     */
    getMingaHash() {
        return /** @type{?UniqueHash} */ (jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 2));
    }
    /** @param {?UniqueHash|undefined} value */
    setMingaHash(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearMingaHash() {
        this.setMingaHash(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasMingaHash() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional string profile_image_url = 5;
     * @return {string}
     */
    getProfileImageUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setProfileImageUrl(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional bool custom_display_name = 6;
     * @return {boolean}
     */
    getCustomDisplayName() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
    }
    ;
    /** @param {boolean} value */
    setCustomDisplayName(value) {
        jspb.Message.setProto3BooleanField(this, 6, value);
    }
    /**
     * optional bool disabled_dm = 7;
     * @return {boolean}
     */
    getDisabledDm() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setDisabledDm(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional string grade = 8;
     * @return {string}
     */
    getGrade() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
    }
    ;
    /** @param {string} value */
    setGrade(value) {
        jspb.Message.setProto3StringField(this, 8, value);
    }
    /**
     * optional bool asb = 9;
     * @return {boolean}
     */
    getAsb() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
    }
    ;
    /** @param {boolean} value */
    setAsb(value) {
        jspb.Message.setProto3BooleanField(this, 9, value);
    }
    /**
     * optional string profile_cover_image_url = 10;
     * @return {string}
     */
    getProfileCoverImageUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
    }
    ;
    /** @param {string} value */
    setProfileCoverImageUrl(value) {
        jspb.Message.setProto3StringField(this, 10, value);
    }
    /**
     * optional string id_field_1 = 11;
     * @return {string}
     */
    getIdField1() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setIdField1(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional string id_field_2 = 12;
     * @return {string}
     */
    getIdField2() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
    }
    ;
    /** @param {string} value */
    setIdField2(value) {
        jspb.Message.setProto3StringField(this, 12, value);
    }
    /**
     * repeated string parent_email = 13;
     * @return {!Array<string>}
     */
    getParentEmailList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
    }
    ;
    /** @param {!Array<string>} value */
    setParentEmailList(value) {
        jspb.Message.setField(this, 13, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addParentEmail(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 13, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentEmailList() {
        this.setParentEmailList([]);
    }
    /**
     * repeated string parent_phone = 14;
     * @return {!Array<string>}
     */
    getParentPhoneList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
    }
    ;
    /** @param {!Array<string>} value */
    setParentPhoneList(value) {
        jspb.Message.setField(this, 14, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addParentPhone(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 14, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentPhoneList() {
        this.setParentPhoneList([]);
    }
    /**
     * optional bool disable_parent_notification = 15;
     * @return {boolean}
     */
    getDisableParentNotification() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
    }
    ;
    /** @param {boolean} value */
    setDisableParentNotification(value) {
        jspb.Message.setProto3BooleanField(this, 15, value);
    }
    /**
     * optional bool sis_include = 16;
     * @return {boolean}
     */
    getSisInclude() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
    }
    ;
    /** @param {boolean} value */
    setSisInclude(value) {
        jspb.Message.setProto3BooleanField(this, 16, value);
    }
}
exports.Account = Account;
Account.displayName = "Account";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
Account.repeatedFields_ = [13, 14];
class Person extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, Person.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return Person.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!Person} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            identity: (f = msg.getIdentity()) && Identity.toObject(includeInstance, f),
            account: (f = msg.getAccount()) && Account.toObject(includeInstance, f),
            active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            accountRole: jspb.Message.getFieldWithDefault(msg, 4, 0),
            birthdate: (f = msg.getBirthdate()) && common_date_pb.Date.toObject(includeInstance, f),
            archived: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            accountRoleType: jspb.Message.getFieldWithDefault(msg, 7, ""),
            parentGroupList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
            studentIdUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
            asbMember: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
            accountRoleColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
            filename: jspb.Message.getFieldWithDefault(msg, 12, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!Person}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new Person;
        return Person.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!Person} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!Person}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new Identity;
                    reader.readMessage(value1, Identity.deserializeBinaryFromReader);
                    msg.setIdentity(value1);
                    break;
                case 2:
                    var value2 = new Account;
                    reader.readMessage(value2, Account.deserializeBinaryFromReader);
                    msg.setAccount(value2);
                    break;
                case 3:
                    var value3 = /** @type {boolean} */ (reader.readBool());
                    msg.setActive(value3);
                    break;
                case 4:
                    var value4 = /** @type {number} */ (reader.readInt32());
                    msg.setAccountRole(value4);
                    break;
                case 5:
                    var value5 = new common_date_pb.Date;
                    reader.readMessage(value5, common_date_pb.Date.deserializeBinaryFromReader);
                    msg.setBirthdate(value5);
                    break;
                case 6:
                    var value6 = /** @type {boolean} */ (reader.readBool());
                    msg.setArchived(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setAccountRoleType(value7);
                    break;
                case 8:
                    var value8 = /** @type {string} */ (reader.readString());
                    msg.addParentGroup(value8);
                    break;
                case 9:
                    var value9 = /** @type {string} */ (reader.readString());
                    msg.setStudentIdUrl(value9);
                    break;
                case 10:
                    var value10 = /** @type {boolean} */ (reader.readBool());
                    msg.setAsbMember(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setAccountRoleColor(value11);
                    break;
                case 12:
                    var value12 = /** @type {string} */ (reader.readString());
                    msg.setFilename(value12);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!Person} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getIdentity();
        if (f != null) {
            writer.writeMessage(1, f, Identity.serializeBinaryToWriter);
        }
        f = message.getAccount();
        if (f != null) {
            writer.writeMessage(2, f, Account.serializeBinaryToWriter);
        }
        f = message.getActive();
        if (f) {
            writer.writeBool(3, f);
        }
        f = message.getAccountRole();
        if (f !== 0) {
            writer.writeInt32(4, f);
        }
        f = message.getBirthdate();
        if (f != null) {
            writer.writeMessage(5, f, common_date_pb.Date.serializeBinaryToWriter);
        }
        f = message.getArchived();
        if (f) {
            writer.writeBool(6, f);
        }
        f = message.getAccountRoleType();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getParentGroupList();
        if (f.length > 0) {
            writer.writeRepeatedString(8, f);
        }
        f = message.getStudentIdUrl();
        if (f.length > 0) {
            writer.writeString(9, f);
        }
        f = message.getAsbMember();
        if (f) {
            writer.writeBool(10, f);
        }
        f = message.getAccountRoleColor();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getFilename();
        if (f.length > 0) {
            writer.writeString(12, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        Person.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional Identity identity = 1;
     * @return {?Identity}
     */
    getIdentity() {
        return /** @type{?Identity} */ (jspb.Message.getWrapperField(this, Identity, 1));
    }
    /** @param {?Identity|undefined} value */
    setIdentity(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearIdentity() {
        this.setIdentity(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasIdentity() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional Account account = 2;
     * @return {?Account}
     */
    getAccount() {
        return /** @type{?Account} */ (jspb.Message.getWrapperField(this, Account, 2));
    }
    /** @param {?Account|undefined} value */
    setAccount(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAccount() {
        this.setAccount(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAccount() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional bool active = 3;
     * @return {boolean}
     */
    getActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
    }
    ;
    /** @param {boolean} value */
    setActive(value) {
        jspb.Message.setProto3BooleanField(this, 3, value);
    }
    /**
     * optional int32 account_role = 4;
     * @return {number}
     */
    getAccountRole() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
    }
    ;
    /** @param {number} value */
    setAccountRole(value) {
        jspb.Message.setProto3IntField(this, 4, value);
    }
    /**
     * optional minga.common.Date birthdate = 5;
     * @return {?Date}
     */
    getBirthdate() {
        return /** @type{?Date} */ (jspb.Message.getWrapperField(this, common_date_pb.Date, 5));
    }
    /** @param {?Date|undefined} value */
    setBirthdate(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBirthdate() {
        this.setBirthdate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBirthdate() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional bool archived = 6;
     * @return {boolean}
     */
    getArchived() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
    }
    ;
    /** @param {boolean} value */
    setArchived(value) {
        jspb.Message.setProto3BooleanField(this, 6, value);
    }
    /**
     * optional string account_role_type = 7;
     * @return {string}
     */
    getAccountRoleType() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setAccountRoleType(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * repeated string parent_group = 8;
     * @return {!Array<string>}
     */
    getParentGroupList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
    }
    ;
    /** @param {!Array<string>} value */
    setParentGroupList(value) {
        jspb.Message.setField(this, 8, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addParentGroup(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 8, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentGroupList() {
        this.setParentGroupList([]);
    }
    /**
     * optional string student_id_url = 9;
     * @return {string}
     */
    getStudentIdUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
    }
    ;
    /** @param {string} value */
    setStudentIdUrl(value) {
        jspb.Message.setProto3StringField(this, 9, value);
    }
    /**
     * optional bool asb_member = 10;
     * @return {boolean}
     */
    getAsbMember() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
    }
    ;
    /** @param {boolean} value */
    setAsbMember(value) {
        jspb.Message.setProto3BooleanField(this, 10, value);
    }
    /**
     * optional string account_role_color = 11;
     * @return {string}
     */
    getAccountRoleColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setAccountRoleColor(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional string filename = 12;
     * @return {string}
     */
    getFilename() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
    }
    ;
    /** @param {string} value */
    setFilename(value) {
        jspb.Message.setProto3StringField(this, 12, value);
    }
}
exports.Person = Person;
Person.displayName = "Person";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
Person.repeatedFields_ = [8];
class IdentityDelta extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return IdentityDelta.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!IdentityDelta} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            emailAddress: (f = msg.getEmailAddress()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            password: (f = msg.getPassword()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            firstName: (f = msg.getFirstName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            lastName: (f = msg.getLastName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            pin: (f = msg.getPin()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
            studentId: (f = msg.getStudentId()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            graduatingYear: (f = msg.getGraduatingYear()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
            phoneNumber: (f = msg.getPhoneNumber()) && common_delta_pb.StringDelta.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!IdentityDelta}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new IdentityDelta;
        return IdentityDelta.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!IdentityDelta} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!IdentityDelta}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_delta_pb.StringDelta;
                    reader.readMessage(value1, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setEmailAddress(value1);
                    break;
                case 2:
                    var value2 = new common_delta_pb.StringDelta;
                    reader.readMessage(value2, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setPassword(value2);
                    break;
                case 3:
                    var value3 = new common_delta_pb.StringDelta;
                    reader.readMessage(value3, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setFirstName(value3);
                    break;
                case 4:
                    var value4 = new common_delta_pb.StringDelta;
                    reader.readMessage(value4, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setLastName(value4);
                    break;
                case 5:
                    var value5 = new common_delta_pb.Int32Delta;
                    reader.readMessage(value5, common_delta_pb.Int32Delta.deserializeBinaryFromReader);
                    msg.setPin(value5);
                    break;
                case 6:
                    var value6 = new common_delta_pb.StringDelta;
                    reader.readMessage(value6, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setStudentId(value6);
                    break;
                case 7:
                    var value7 = new common_delta_pb.Int32Delta;
                    reader.readMessage(value7, common_delta_pb.Int32Delta.deserializeBinaryFromReader);
                    msg.setGraduatingYear(value7);
                    break;
                case 8:
                    var value8 = new common_delta_pb.StringDelta;
                    reader.readMessage(value8, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setPhoneNumber(value8);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!IdentityDelta} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getEmailAddress();
        if (f != null) {
            writer.writeMessage(1, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getPassword();
        if (f != null) {
            writer.writeMessage(2, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getFirstName();
        if (f != null) {
            writer.writeMessage(3, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getLastName();
        if (f != null) {
            writer.writeMessage(4, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getPin();
        if (f != null) {
            writer.writeMessage(5, f, common_delta_pb.Int32Delta.serializeBinaryToWriter);
        }
        f = message.getStudentId();
        if (f != null) {
            writer.writeMessage(6, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getGraduatingYear();
        if (f != null) {
            writer.writeMessage(7, f, common_delta_pb.Int32Delta.serializeBinaryToWriter);
        }
        f = message.getPhoneNumber();
        if (f != null) {
            writer.writeMessage(8, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        IdentityDelta.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional minga.common.StringDelta email_address = 1;
     * @return {?StringDelta}
     */
    getEmailAddress() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 1));
    }
    /** @param {?StringDelta|undefined} value */
    setEmailAddress(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearEmailAddress() {
        this.setEmailAddress(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasEmailAddress() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional minga.common.StringDelta password = 2;
     * @return {?StringDelta}
     */
    getPassword() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
    }
    /** @param {?StringDelta|undefined} value */
    setPassword(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPassword() {
        this.setPassword(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPassword() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional minga.common.StringDelta first_name = 3;
     * @return {?StringDelta}
     */
    getFirstName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
    }
    /** @param {?StringDelta|undefined} value */
    setFirstName(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearFirstName() {
        this.setFirstName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasFirstName() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional minga.common.StringDelta last_name = 4;
     * @return {?StringDelta}
     */
    getLastName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 4));
    }
    /** @param {?StringDelta|undefined} value */
    setLastName(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearLastName() {
        this.setLastName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasLastName() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional minga.common.Int32Delta pin = 5;
     * @return {?Int32Delta}
     */
    getPin() {
        return /** @type{?Int32Delta} */ (jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 5));
    }
    /** @param {?Int32Delta|undefined} value */
    setPin(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPin() {
        this.setPin(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPin() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.StringDelta student_id = 6;
     * @return {?StringDelta}
     */
    getStudentId() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 6));
    }
    /** @param {?StringDelta|undefined} value */
    setStudentId(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStudentId() {
        this.setStudentId(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStudentId() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional minga.common.Int32Delta graduating_year = 7;
     * @return {?Int32Delta}
     */
    getGraduatingYear() {
        return /** @type{?Int32Delta} */ (jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 7));
    }
    /** @param {?Int32Delta|undefined} value */
    setGraduatingYear(value) {
        jspb.Message.setWrapperField(this, 7, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearGraduatingYear() {
        this.setGraduatingYear(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasGraduatingYear() {
        return jspb.Message.getField(this, 7) != null;
    }
    /**
     * optional minga.common.StringDelta phone_number = 8;
     * @return {?StringDelta}
     */
    getPhoneNumber() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 8));
    }
    /** @param {?StringDelta|undefined} value */
    setPhoneNumber(value) {
        jspb.Message.setWrapperField(this, 8, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPhoneNumber() {
        this.setPhoneNumber(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPhoneNumber() {
        return jspb.Message.getField(this, 8) != null;
    }
}
exports.IdentityDelta = IdentityDelta;
IdentityDelta.displayName = "IdentityDelta";
class AccountDelta extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, AccountDelta.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return AccountDelta.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!AccountDelta} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            displayName: (f = msg.getDisplayName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            disabledDm: (f = msg.getDisabledDm()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            grade: (f = msg.getGrade()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            asb: (f = msg.getAsb()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            idField1: (f = msg.getIdField1()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            idField2: (f = msg.getIdField2()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            parentEmailList: jspb.Message.toObjectList(msg.getParentEmailList(), common_delta_pb.StringDelta.toObject, includeInstance),
            parentPhoneList: jspb.Message.toObjectList(msg.getParentPhoneList(), common_delta_pb.StringDelta.toObject, includeInstance),
            disableParentNotification: (f = msg.getDisableParentNotification()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            sisInclude: (f = msg.getSisInclude()) && common_delta_pb.BoolDelta.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!AccountDelta}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new AccountDelta;
        return AccountDelta.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!AccountDelta} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!AccountDelta}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_delta_pb.StringDelta;
                    reader.readMessage(value1, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setDisplayName(value1);
                    break;
                case 2:
                    var value2 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value2, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setDisabledDm(value2);
                    break;
                case 3:
                    var value3 = new common_delta_pb.StringDelta;
                    reader.readMessage(value3, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setGrade(value3);
                    break;
                case 4:
                    var value4 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value4, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setAsb(value4);
                    break;
                case 5:
                    var value5 = new common_delta_pb.StringDelta;
                    reader.readMessage(value5, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setIdField1(value5);
                    break;
                case 6:
                    var value6 = new common_delta_pb.StringDelta;
                    reader.readMessage(value6, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setIdField2(value6);
                    break;
                case 7:
                    var value7 = new common_delta_pb.StringDelta;
                    reader.readMessage(value7, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.addParentEmail(value7);
                    break;
                case 8:
                    var value8 = new common_delta_pb.StringDelta;
                    reader.readMessage(value8, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.addParentPhone(value8);
                    break;
                case 9:
                    var value9 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value9, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setDisableParentNotification(value9);
                    break;
                case 10:
                    var value10 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value10, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setSisInclude(value10);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!AccountDelta} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getDisplayName();
        if (f != null) {
            writer.writeMessage(1, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getDisabledDm();
        if (f != null) {
            writer.writeMessage(2, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getGrade();
        if (f != null) {
            writer.writeMessage(3, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getAsb();
        if (f != null) {
            writer.writeMessage(4, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getIdField1();
        if (f != null) {
            writer.writeMessage(5, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getIdField2();
        if (f != null) {
            writer.writeMessage(6, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getParentEmailList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(7, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getParentPhoneList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(8, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getDisableParentNotification();
        if (f != null) {
            writer.writeMessage(9, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getSisInclude();
        if (f != null) {
            writer.writeMessage(10, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        AccountDelta.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional minga.common.StringDelta display_name = 1;
     * @return {?StringDelta}
     */
    getDisplayName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 1));
    }
    /** @param {?StringDelta|undefined} value */
    setDisplayName(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDisplayName() {
        this.setDisplayName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDisplayName() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional minga.common.BoolDelta disabled_dm = 2;
     * @return {?BoolDelta}
     */
    getDisabledDm() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 2));
    }
    /** @param {?BoolDelta|undefined} value */
    setDisabledDm(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDisabledDm() {
        this.setDisabledDm(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDisabledDm() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional minga.common.StringDelta grade = 3;
     * @return {?StringDelta}
     */
    getGrade() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
    }
    /** @param {?StringDelta|undefined} value */
    setGrade(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearGrade() {
        this.setGrade(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasGrade() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional minga.common.BoolDelta asb = 4;
     * @return {?BoolDelta}
     */
    getAsb() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 4));
    }
    /** @param {?BoolDelta|undefined} value */
    setAsb(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAsb() {
        this.setAsb(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAsb() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional minga.common.StringDelta id_field_1 = 5;
     * @return {?StringDelta}
     */
    getIdField1() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 5));
    }
    /** @param {?StringDelta|undefined} value */
    setIdField1(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearIdField1() {
        this.setIdField1(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasIdField1() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.StringDelta id_field_2 = 6;
     * @return {?StringDelta}
     */
    getIdField2() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 6));
    }
    /** @param {?StringDelta|undefined} value */
    setIdField2(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearIdField2() {
        this.setIdField2(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasIdField2() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * repeated minga.common.StringDelta parent_email = 7;
     * @return {!Array<!StringDelta>}
     */
    getParentEmailList() {
        return /** @type{!Array<!StringDelta>} */ (jspb.Message.getRepeatedWrapperField(this, common_delta_pb.StringDelta, 7));
    }
    /** @param {!Array<!StringDelta>} value */
    setParentEmailList(value) {
        jspb.Message.setRepeatedWrapperField(this, 7, value);
    }
    /**
     * @param {!StringDelta=} opt_value
     * @param {number=} opt_index
     * @return {!StringDelta}
     */
    addParentEmail(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, common_delta_pb.StringDelta, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentEmailList() {
        this.setParentEmailList([]);
    }
    /**
     * repeated minga.common.StringDelta parent_phone = 8;
     * @return {!Array<!StringDelta>}
     */
    getParentPhoneList() {
        return /** @type{!Array<!StringDelta>} */ (jspb.Message.getRepeatedWrapperField(this, common_delta_pb.StringDelta, 8));
    }
    /** @param {!Array<!StringDelta>} value */
    setParentPhoneList(value) {
        jspb.Message.setRepeatedWrapperField(this, 8, value);
    }
    /**
     * @param {!StringDelta=} opt_value
     * @param {number=} opt_index
     * @return {!StringDelta}
     */
    addParentPhone(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, common_delta_pb.StringDelta, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentPhoneList() {
        this.setParentPhoneList([]);
    }
    /**
     * optional minga.common.BoolDelta disable_parent_notification = 9;
     * @return {?BoolDelta}
     */
    getDisableParentNotification() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 9));
    }
    /** @param {?BoolDelta|undefined} value */
    setDisableParentNotification(value) {
        jspb.Message.setWrapperField(this, 9, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDisableParentNotification() {
        this.setDisableParentNotification(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDisableParentNotification() {
        return jspb.Message.getField(this, 9) != null;
    }
    /**
     * optional minga.common.BoolDelta sis_include = 10;
     * @return {?BoolDelta}
     */
    getSisInclude() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 10));
    }
    /** @param {?BoolDelta|undefined} value */
    setSisInclude(value) {
        jspb.Message.setWrapperField(this, 10, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearSisInclude() {
        this.setSisInclude(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasSisInclude() {
        return jspb.Message.getField(this, 10) != null;
    }
}
exports.AccountDelta = AccountDelta;
AccountDelta.displayName = "AccountDelta";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
AccountDelta.repeatedFields_ = [7, 8];
class PersonDelta extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PersonDelta.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PersonDelta} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            identity: (f = msg.getIdentity()) && IdentityDelta.toObject(includeInstance, f),
            account: (f = msg.getAccount()) && AccountDelta.toObject(includeInstance, f),
            active: (f = msg.getActive()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            accountRole: (f = msg.getAccountRole()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
            birthdate: (f = msg.getBirthdate()) && common_delta_pb.DateDelta.toObject(includeInstance, f),
            accountRoleType: (f = msg.getAccountRoleType()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            parentGroup: (f = msg.getParentGroup()) && common_delta_pb.RepeatedStringDelta.toObject(includeInstance, f),
            studentIdUrl: (f = msg.getStudentIdUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            asbMember: (f = msg.getAsbMember()) && common_delta_pb.BoolDelta.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PersonDelta}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PersonDelta;
        return PersonDelta.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PersonDelta} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PersonDelta}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setHash(value1);
                    break;
                case 2:
                    var value2 = new IdentityDelta;
                    reader.readMessage(value2, IdentityDelta.deserializeBinaryFromReader);
                    msg.setIdentity(value2);
                    break;
                case 3:
                    var value3 = new AccountDelta;
                    reader.readMessage(value3, AccountDelta.deserializeBinaryFromReader);
                    msg.setAccount(value3);
                    break;
                case 4:
                    var value4 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value4, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setActive(value4);
                    break;
                case 5:
                    var value5 = new common_delta_pb.Int32Delta;
                    reader.readMessage(value5, common_delta_pb.Int32Delta.deserializeBinaryFromReader);
                    msg.setAccountRole(value5);
                    break;
                case 6:
                    var value6 = new common_delta_pb.DateDelta;
                    reader.readMessage(value6, common_delta_pb.DateDelta.deserializeBinaryFromReader);
                    msg.setBirthdate(value6);
                    break;
                case 7:
                    var value7 = new common_delta_pb.StringDelta;
                    reader.readMessage(value7, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setAccountRoleType(value7);
                    break;
                case 8:
                    var value8 = new common_delta_pb.RepeatedStringDelta;
                    reader.readMessage(value8, common_delta_pb.RepeatedStringDelta.deserializeBinaryFromReader);
                    msg.setParentGroup(value8);
                    break;
                case 9:
                    var value9 = new common_delta_pb.StringDelta;
                    reader.readMessage(value9, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setStudentIdUrl(value9);
                    break;
                case 10:
                    var value10 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value10, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setAsbMember(value10);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PersonDelta} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getIdentity();
        if (f != null) {
            writer.writeMessage(2, f, IdentityDelta.serializeBinaryToWriter);
        }
        f = message.getAccount();
        if (f != null) {
            writer.writeMessage(3, f, AccountDelta.serializeBinaryToWriter);
        }
        f = message.getActive();
        if (f != null) {
            writer.writeMessage(4, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getAccountRole();
        if (f != null) {
            writer.writeMessage(5, f, common_delta_pb.Int32Delta.serializeBinaryToWriter);
        }
        f = message.getBirthdate();
        if (f != null) {
            writer.writeMessage(6, f, common_delta_pb.DateDelta.serializeBinaryToWriter);
        }
        f = message.getAccountRoleType();
        if (f != null) {
            writer.writeMessage(7, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getParentGroup();
        if (f != null) {
            writer.writeMessage(8, f, common_delta_pb.RepeatedStringDelta.serializeBinaryToWriter);
        }
        f = message.getStudentIdUrl();
        if (f != null) {
            writer.writeMessage(9, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getAsbMember();
        if (f != null) {
            writer.writeMessage(10, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PersonDelta.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string hash = 1;
     * @return {string}
     */
    getHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional IdentityDelta identity = 2;
     * @return {?IdentityDelta}
     */
    getIdentity() {
        return /** @type{?IdentityDelta} */ (jspb.Message.getWrapperField(this, IdentityDelta, 2));
    }
    /** @param {?IdentityDelta|undefined} value */
    setIdentity(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearIdentity() {
        this.setIdentity(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasIdentity() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional AccountDelta account = 3;
     * @return {?AccountDelta}
     */
    getAccount() {
        return /** @type{?AccountDelta} */ (jspb.Message.getWrapperField(this, AccountDelta, 3));
    }
    /** @param {?AccountDelta|undefined} value */
    setAccount(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAccount() {
        this.setAccount(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAccount() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional minga.common.BoolDelta active = 4;
     * @return {?BoolDelta}
     */
    getActive() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 4));
    }
    /** @param {?BoolDelta|undefined} value */
    setActive(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearActive() {
        this.setActive(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasActive() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional minga.common.Int32Delta account_role = 5;
     * @return {?Int32Delta}
     */
    getAccountRole() {
        return /** @type{?Int32Delta} */ (jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 5));
    }
    /** @param {?Int32Delta|undefined} value */
    setAccountRole(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAccountRole() {
        this.setAccountRole(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAccountRole() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.DateDelta birthdate = 6;
     * @return {?DateDelta}
     */
    getBirthdate() {
        return /** @type{?DateDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.DateDelta, 6));
    }
    /** @param {?DateDelta|undefined} value */
    setBirthdate(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBirthdate() {
        this.setBirthdate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBirthdate() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional minga.common.StringDelta account_role_type = 7;
     * @return {?StringDelta}
     */
    getAccountRoleType() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 7));
    }
    /** @param {?StringDelta|undefined} value */
    setAccountRoleType(value) {
        jspb.Message.setWrapperField(this, 7, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAccountRoleType() {
        this.setAccountRoleType(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAccountRoleType() {
        return jspb.Message.getField(this, 7) != null;
    }
    /**
     * optional minga.common.RepeatedStringDelta parent_group = 8;
     * @return {?RepeatedStringDelta}
     */
    getParentGroup() {
        return /** @type{?RepeatedStringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.RepeatedStringDelta, 8));
    }
    /** @param {?RepeatedStringDelta|undefined} value */
    setParentGroup(value) {
        jspb.Message.setWrapperField(this, 8, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearParentGroup() {
        this.setParentGroup(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasParentGroup() {
        return jspb.Message.getField(this, 8) != null;
    }
    /**
     * optional minga.common.StringDelta student_id_url = 9;
     * @return {?StringDelta}
     */
    getStudentIdUrl() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 9));
    }
    /** @param {?StringDelta|undefined} value */
    setStudentIdUrl(value) {
        jspb.Message.setWrapperField(this, 9, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStudentIdUrl() {
        this.setStudentIdUrl(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStudentIdUrl() {
        return jspb.Message.getField(this, 9) != null;
    }
    /**
     * optional minga.common.BoolDelta asb_member = 10;
     * @return {?BoolDelta}
     */
    getAsbMember() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 10));
    }
    /** @param {?BoolDelta|undefined} value */
    setAsbMember(value) {
        jspb.Message.setWrapperField(this, 10, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAsbMember() {
        this.setAsbMember(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAsbMember() {
        return jspb.Message.getField(this, 10) != null;
    }
}
exports.PersonDelta = PersonDelta;
PersonDelta.displayName = "PersonDelta";
class CreatePeopleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, CreatePeopleRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CreatePeopleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CreatePeopleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            peopleList: jspb.Message.toObjectList(msg.getPeopleList(), Person.toObject, includeInstance),
            sendPinEmails: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CreatePeopleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CreatePeopleRequest;
        return CreatePeopleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CreatePeopleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CreatePeopleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new Person;
                    reader.readMessage(value1, Person.deserializeBinaryFromReader);
                    msg.addPeople(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setSendPinEmails(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CreatePeopleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, Person.serializeBinaryToWriter);
        }
        f = message.getSendPinEmails();
        if (f) {
            writer.writeBool(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CreatePeopleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated Person people = 1;
     * @return {!Array<!Person>}
     */
    getPeopleList() {
        return /** @type{!Array<!Person>} */ (jspb.Message.getRepeatedWrapperField(this, Person, 1));
    }
    /** @param {!Array<!Person>} value */
    setPeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!Person=} opt_value
     * @param {number=} opt_index
     * @return {!Person}
     */
    addPeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Person, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleList() {
        this.setPeopleList([]);
    }
    /**
     * optional bool send_pin_emails = 2;
     * @return {boolean}
     */
    getSendPinEmails() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setSendPinEmails(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
}
exports.CreatePeopleRequest = CreatePeopleRequest;
CreatePeopleRequest.displayName = "CreatePeopleRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
CreatePeopleRequest.repeatedFields_ = [1];
class CreatePeopleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, CreatePeopleResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CreatePeopleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CreatePeopleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            reasonList: jspb.Message.toObjectList(msg.getReasonList(), common_legacy_pb.StatusReason.toObject, includeInstance),
            peopleList: jspb.Message.toObjectList(msg.getPeopleList(), Person.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CreatePeopleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CreatePeopleResponse;
        return CreatePeopleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CreatePeopleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CreatePeopleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = new common_legacy_pb.StatusReason;
                    reader.readMessage(value2, common_legacy_pb.StatusReason.deserializeBinaryFromReader);
                    msg.addReason(value2);
                    break;
                case 3:
                    var value3 = new Person;
                    reader.readMessage(value3, Person.deserializeBinaryFromReader);
                    msg.addPeople(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CreatePeopleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getReasonList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, common_legacy_pb.StatusReason.serializeBinaryToWriter);
        }
        f = message.getPeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(3, f, Person.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CreatePeopleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * repeated StatusReason reason = 2;
     * @return {!Array<!StatusReason>}
     */
    getReasonList() {
        return /** @type{!Array<!StatusReason>} */ (jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
    }
    /** @param {!Array<!StatusReason>} value */
    setReasonList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!StatusReason=} opt_value
     * @param {number=} opt_index
     * @return {!StatusReason}
     */
    addReason(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearReasonList() {
        this.setReasonList([]);
    }
    /**
     * repeated Person people = 3;
     * @return {!Array<!Person>}
     */
    getPeopleList() {
        return /** @type{!Array<!Person>} */ (jspb.Message.getRepeatedWrapperField(this, Person, 3));
    }
    /** @param {!Array<!Person>} value */
    setPeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 3, value);
    }
    /**
     * @param {!Person=} opt_value
     * @param {number=} opt_index
     * @return {!Person}
     */
    addPeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, Person, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleList() {
        this.setPeopleList([]);
    }
}
exports.CreatePeopleResponse = CreatePeopleResponse;
CreatePeopleResponse.displayName = "CreatePeopleResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
CreatePeopleResponse.repeatedFields_ = [2, 3];
class UpdatePeopleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, UpdatePeopleRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdatePeopleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdatePeopleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            peopleList: jspb.Message.toObjectList(msg.getPeopleList(), PersonDelta.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdatePeopleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdatePeopleRequest;
        return UpdatePeopleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdatePeopleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdatePeopleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new PersonDelta;
                    reader.readMessage(value1, PersonDelta.deserializeBinaryFromReader);
                    msg.addPeople(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdatePeopleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, PersonDelta.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdatePeopleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated PersonDelta people = 1;
     * @return {!Array<!PersonDelta>}
     */
    getPeopleList() {
        return /** @type{!Array<!PersonDelta>} */ (jspb.Message.getRepeatedWrapperField(this, PersonDelta, 1));
    }
    /** @param {!Array<!PersonDelta>} value */
    setPeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!PersonDelta=} opt_value
     * @param {number=} opt_index
     * @return {!PersonDelta}
     */
    addPeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PersonDelta, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleList() {
        this.setPeopleList([]);
    }
}
exports.UpdatePeopleRequest = UpdatePeopleRequest;
UpdatePeopleRequest.displayName = "UpdatePeopleRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
UpdatePeopleRequest.repeatedFields_ = [1];
class UpdatePeopleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, UpdatePeopleResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdatePeopleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdatePeopleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            reasonList: jspb.Message.toObjectList(msg.getReasonList(), common_legacy_pb.StatusReason.toObject, includeInstance),
            peopleList: jspb.Message.toObjectList(msg.getPeopleList(), Person.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdatePeopleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdatePeopleResponse;
        return UpdatePeopleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdatePeopleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdatePeopleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = new common_legacy_pb.StatusReason;
                    reader.readMessage(value2, common_legacy_pb.StatusReason.deserializeBinaryFromReader);
                    msg.addReason(value2);
                    break;
                case 3:
                    var value3 = new Person;
                    reader.readMessage(value3, Person.deserializeBinaryFromReader);
                    msg.addPeople(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdatePeopleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getReasonList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, common_legacy_pb.StatusReason.serializeBinaryToWriter);
        }
        f = message.getPeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(3, f, Person.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdatePeopleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * repeated StatusReason reason = 2;
     * @return {!Array<!StatusReason>}
     */
    getReasonList() {
        return /** @type{!Array<!StatusReason>} */ (jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
    }
    /** @param {!Array<!StatusReason>} value */
    setReasonList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!StatusReason=} opt_value
     * @param {number=} opt_index
     * @return {!StatusReason}
     */
    addReason(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearReasonList() {
        this.setReasonList([]);
    }
    /**
     * repeated Person people = 3;
     * @return {!Array<!Person>}
     */
    getPeopleList() {
        return /** @type{!Array<!Person>} */ (jspb.Message.getRepeatedWrapperField(this, Person, 3));
    }
    /** @param {!Array<!Person>} value */
    setPeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 3, value);
    }
    /**
     * @param {!Person=} opt_value
     * @param {number=} opt_index
     * @return {!Person}
     */
    addPeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, Person, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleList() {
        this.setPeopleList([]);
    }
}
exports.UpdatePeopleResponse = UpdatePeopleResponse;
UpdatePeopleResponse.displayName = "UpdatePeopleResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
UpdatePeopleResponse.repeatedFields_ = [2, 3];
class DeletePeopleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, DeletePeopleRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeletePeopleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeletePeopleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeletePeopleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeletePeopleRequest;
        return DeletePeopleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeletePeopleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeletePeopleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeletePeopleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHashList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeletePeopleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string hash = 1;
     * @return {!Array<string>}
     */
    getHashList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setHashList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addHash(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHashList() {
        this.setHashList([]);
    }
}
exports.DeletePeopleRequest = DeletePeopleRequest;
DeletePeopleRequest.displayName = "DeletePeopleRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
DeletePeopleRequest.repeatedFields_ = [1];
class DeletePeopleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, DeletePeopleResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeletePeopleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeletePeopleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            reasonList: jspb.Message.toObjectList(msg.getReasonList(), common_legacy_pb.StatusReason.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeletePeopleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeletePeopleResponse;
        return DeletePeopleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeletePeopleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeletePeopleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = new common_legacy_pb.StatusReason;
                    reader.readMessage(value2, common_legacy_pb.StatusReason.deserializeBinaryFromReader);
                    msg.addReason(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeletePeopleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getReasonList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, common_legacy_pb.StatusReason.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeletePeopleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * repeated StatusReason reason = 2;
     * @return {!Array<!StatusReason>}
     */
    getReasonList() {
        return /** @type{!Array<!StatusReason>} */ (jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
    }
    /** @param {!Array<!StatusReason>} value */
    setReasonList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!StatusReason=} opt_value
     * @param {number=} opt_index
     * @return {!StatusReason}
     */
    addReason(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearReasonList() {
        this.setReasonList([]);
    }
}
exports.DeletePeopleResponse = DeletePeopleResponse;
DeletePeopleResponse.displayName = "DeletePeopleResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
DeletePeopleResponse.repeatedFields_ = [2];
class ArchivePeopleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, ArchivePeopleRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ArchivePeopleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ArchivePeopleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ArchivePeopleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ArchivePeopleRequest;
        return ArchivePeopleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ArchivePeopleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ArchivePeopleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ArchivePeopleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHashList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ArchivePeopleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string hash = 1;
     * @return {!Array<string>}
     */
    getHashList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setHashList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addHash(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHashList() {
        this.setHashList([]);
    }
}
exports.ArchivePeopleRequest = ArchivePeopleRequest;
ArchivePeopleRequest.displayName = "ArchivePeopleRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
ArchivePeopleRequest.repeatedFields_ = [1];
class ArchivePeopleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ArchivePeopleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ArchivePeopleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ArchivePeopleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ArchivePeopleResponse;
        return ArchivePeopleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ArchivePeopleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ArchivePeopleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ArchivePeopleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ArchivePeopleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
}
exports.ArchivePeopleResponse = ArchivePeopleResponse;
ArchivePeopleResponse.displayName = "ArchivePeopleResponse";
class SelfArchivePersonRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return SelfArchivePersonRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!SelfArchivePersonRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!SelfArchivePersonRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new SelfArchivePersonRequest;
        return SelfArchivePersonRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!SelfArchivePersonRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!SelfArchivePersonRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!SelfArchivePersonRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        SelfArchivePersonRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.SelfArchivePersonRequest = SelfArchivePersonRequest;
SelfArchivePersonRequest.displayName = "SelfArchivePersonRequest";
class SelfArchivePersonResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return SelfArchivePersonResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!SelfArchivePersonResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!SelfArchivePersonResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new SelfArchivePersonResponse;
        return SelfArchivePersonResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!SelfArchivePersonResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!SelfArchivePersonResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!SelfArchivePersonResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        SelfArchivePersonResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
}
exports.SelfArchivePersonResponse = SelfArchivePersonResponse;
SelfArchivePersonResponse.displayName = "SelfArchivePersonResponse";
class ReadPeopleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, ReadPeopleRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ReadPeopleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ReadPeopleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            hiResAvatar: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ReadPeopleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ReadPeopleRequest;
        return ReadPeopleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ReadPeopleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ReadPeopleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setHiResAvatar(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ReadPeopleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHashList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
        f = message.getHiResAvatar();
        if (f) {
            writer.writeBool(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ReadPeopleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string hash = 1;
     * @return {!Array<string>}
     */
    getHashList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setHashList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addHash(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHashList() {
        this.setHashList([]);
    }
    /**
     * optional bool hi_res_avatar = 2;
     * @return {boolean}
     */
    getHiResAvatar() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setHiResAvatar(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
}
exports.ReadPeopleRequest = ReadPeopleRequest;
ReadPeopleRequest.displayName = "ReadPeopleRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
ReadPeopleRequest.repeatedFields_ = [1];
class ReadPeopleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, ReadPeopleResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ReadPeopleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ReadPeopleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            reasonList: jspb.Message.toObjectList(msg.getReasonList(), common_legacy_pb.StatusReason.toObject, includeInstance),
            peopleList: jspb.Message.toObjectList(msg.getPeopleList(), Person.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ReadPeopleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ReadPeopleResponse;
        return ReadPeopleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ReadPeopleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ReadPeopleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = new common_legacy_pb.StatusReason;
                    reader.readMessage(value2, common_legacy_pb.StatusReason.deserializeBinaryFromReader);
                    msg.addReason(value2);
                    break;
                case 3:
                    var value3 = new Person;
                    reader.readMessage(value3, Person.deserializeBinaryFromReader);
                    msg.addPeople(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ReadPeopleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getReasonList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, common_legacy_pb.StatusReason.serializeBinaryToWriter);
        }
        f = message.getPeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(3, f, Person.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ReadPeopleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * repeated StatusReason reason = 2;
     * @return {!Array<!StatusReason>}
     */
    getReasonList() {
        return /** @type{!Array<!StatusReason>} */ (jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
    }
    /** @param {!Array<!StatusReason>} value */
    setReasonList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!StatusReason=} opt_value
     * @param {number=} opt_index
     * @return {!StatusReason}
     */
    addReason(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearReasonList() {
        this.setReasonList([]);
    }
    /**
     * repeated Person people = 3;
     * @return {!Array<!Person>}
     */
    getPeopleList() {
        return /** @type{!Array<!Person>} */ (jspb.Message.getRepeatedWrapperField(this, Person, 3));
    }
    /** @param {!Array<!Person>} value */
    setPeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 3, value);
    }
    /**
     * @param {!Person=} opt_value
     * @param {number=} opt_index
     * @return {!Person}
     */
    addPeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, Person, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeopleList() {
        this.setPeopleList([]);
    }
}
exports.ReadPeopleResponse = ReadPeopleResponse;
ReadPeopleResponse.displayName = "ReadPeopleResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
ReadPeopleResponse.repeatedFields_ = [2, 3];
class StreamPeopleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, StreamPeopleRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StreamPeopleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StreamPeopleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            hashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StreamPeopleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StreamPeopleRequest;
        return StreamPeopleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StreamPeopleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StreamPeopleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StreamPeopleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getHashList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StreamPeopleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string hash = 1;
     * @return {!Array<string>}
     */
    getHashList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setHashList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addHash(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearHashList() {
        this.setHashList([]);
    }
}
exports.StreamPeopleRequest = StreamPeopleRequest;
StreamPeopleRequest.displayName = "StreamPeopleRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
StreamPeopleRequest.repeatedFields_ = [1];
class StreamPeopleResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StreamPeopleResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StreamPeopleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
            item: (f = msg.getItem()) && gateway_person_view_pb.AdminPersonView.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StreamPeopleResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StreamPeopleResponse;
        return StreamPeopleResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StreamPeopleResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StreamPeopleResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_stream_pb.StreamItemMetadata;
                    reader.readMessage(value1, common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
                    msg.setItemMetadata(value1);
                    break;
                case 2:
                    var value2 = new gateway_person_view_pb.AdminPersonView;
                    reader.readMessage(value2, gateway_person_view_pb.AdminPersonView.deserializeBinaryFromReader);
                    msg.setItem(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StreamPeopleResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getItemMetadata();
        if (f != null) {
            writer.writeMessage(1, f, common_stream_pb.StreamItemMetadata.serializeBinaryToWriter);
        }
        f = message.getItem();
        if (f != null) {
            writer.writeMessage(2, f, gateway_person_view_pb.AdminPersonView.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StreamPeopleResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StreamItemMetadata item_metadata = 1;
     * @return {?StreamItemMetadata}
     */
    getItemMetadata() {
        return /** @type{?StreamItemMetadata} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
    }
    /** @param {?StreamItemMetadata|undefined} value */
    setItemMetadata(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItemMetadata() {
        this.setItemMetadata(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItemMetadata() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional AdminPersonView item = 2;
     * @return {?AdminPersonView}
     */
    getItem() {
        return /** @type{?AdminPersonView} */ (jspb.Message.getWrapperField(this, gateway_person_view_pb.AdminPersonView, 2));
    }
    /** @param {?AdminPersonView|undefined} value */
    setItem(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItem() {
        this.setItem(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItem() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.StreamPeopleResponse = StreamPeopleResponse;
StreamPeopleResponse.displayName = "StreamPeopleResponse";
class StreamPeopleControlResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return StreamPeopleControlResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!StreamPeopleControlResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: (f = msg.getId()) && common_stream_pb.StreamID.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!StreamPeopleControlResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new StreamPeopleControlResponse;
        return StreamPeopleControlResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!StreamPeopleControlResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!StreamPeopleControlResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 2:
                    var value2 = new common_stream_pb.StreamID;
                    reader.readMessage(value2, common_stream_pb.StreamID.deserializeBinaryFromReader);
                    msg.setId(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!StreamPeopleControlResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f != null) {
            writer.writeMessage(2, f, common_stream_pb.StreamID.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        StreamPeopleControlResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StreamID id = 2;
     * @return {?StreamID}
     */
    getId() {
        return /** @type{?StreamID} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamID, 2));
    }
    /** @param {?StreamID|undefined} value */
    setId(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearId() {
        this.setId(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasId() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.StreamPeopleControlResponse = StreamPeopleControlResponse;
StreamPeopleControlResponse.displayName = "StreamPeopleControlResponse";
class EmailUniqueRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return EmailUniqueRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!EmailUniqueRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
            mingaHash: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!EmailUniqueRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new EmailUniqueRequest;
        return EmailUniqueRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!EmailUniqueRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!EmailUniqueRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setEmailAddress(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setMingaHash(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!EmailUniqueRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getEmailAddress();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getMingaHash();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        EmailUniqueRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string email_address = 1;
     * @return {string}
     */
    getEmailAddress() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setEmailAddress(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string minga_hash = 2;
     * @return {string}
     */
    getMingaHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setMingaHash(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
}
exports.EmailUniqueRequest = EmailUniqueRequest;
EmailUniqueRequest.displayName = "EmailUniqueRequest";
class EmailUniqueResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return EmailUniqueResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!EmailUniqueResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            unique: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!EmailUniqueResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new EmailUniqueResponse;
        return EmailUniqueResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!EmailUniqueResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!EmailUniqueResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setUnique(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!EmailUniqueResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getUnique();
        if (f) {
            writer.writeBool(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        EmailUniqueResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * optional bool unique = 2;
     * @return {boolean}
     */
    getUnique() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setUnique(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
}
exports.EmailUniqueResponse = EmailUniqueResponse;
EmailUniqueResponse.displayName = "EmailUniqueResponse";
class PinUniqueRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PinUniqueRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PinUniqueRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            pin: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PinUniqueRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PinUniqueRequest;
        return PinUniqueRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PinUniqueRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PinUniqueRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setPin(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PinUniqueRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPin();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PinUniqueRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 pin = 1;
     * @return {number}
     */
    getPin() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setPin(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.PinUniqueRequest = PinUniqueRequest;
PinUniqueRequest.displayName = "PinUniqueRequest";
class PinUniqueResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PinUniqueResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PinUniqueResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            unique: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PinUniqueResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PinUniqueResponse;
        return PinUniqueResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PinUniqueResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PinUniqueResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setUnique(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PinUniqueResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getUnique();
        if (f) {
            writer.writeBool(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PinUniqueResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * optional bool unique = 2;
     * @return {boolean}
     */
    getUnique() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setUnique(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
}
exports.PinUniqueResponse = PinUniqueResponse;
PinUniqueResponse.displayName = "PinUniqueResponse";
class NextMingaPinRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return NextMingaPinRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!NextMingaPinRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!NextMingaPinRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new NextMingaPinRequest;
        return NextMingaPinRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!NextMingaPinRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!NextMingaPinRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setMingaHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!NextMingaPinRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getMingaHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        NextMingaPinRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string minga_hash = 1;
     * @return {string}
     */
    getMingaHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setMingaHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.NextMingaPinRequest = NextMingaPinRequest;
NextMingaPinRequest.displayName = "NextMingaPinRequest";
class NextMingaPinResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return NextMingaPinResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!NextMingaPinResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
            pin: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!NextMingaPinResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new NextMingaPinResponse;
        return NextMingaPinResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!NextMingaPinResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!NextMingaPinResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = new common_legacy_pb.StatusReason;
                    reader.readMessage(value2, common_legacy_pb.StatusReason.deserializeBinaryFromReader);
                    msg.setReason(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readInt32());
                    msg.setPin(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!NextMingaPinResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getReason();
        if (f != null) {
            writer.writeMessage(2, f, common_legacy_pb.StatusReason.serializeBinaryToWriter);
        }
        f = message.getPin();
        if (f !== 0) {
            writer.writeInt32(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        NextMingaPinResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * optional StatusReason reason = 2;
     * @return {?StatusReason}
     */
    getReason() {
        return /** @type{?StatusReason} */ (jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
    }
    /** @param {?StatusReason|undefined} value */
    setReason(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearReason() {
        this.setReason(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasReason() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional int32 pin = 3;
     * @return {number}
     */
    getPin() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
    }
    ;
    /** @param {number} value */
    setPin(value) {
        jspb.Message.setProto3IntField(this, 3, value);
    }
}
exports.NextMingaPinResponse = NextMingaPinResponse;
NextMingaPinResponse.displayName = "NextMingaPinResponse";
class OpenMingaRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return OpenMingaRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!OpenMingaRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!OpenMingaRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new OpenMingaRequest;
        return OpenMingaRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!OpenMingaRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!OpenMingaRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setMingaHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!OpenMingaRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getMingaHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        OpenMingaRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string minga_hash = 1;
     * @return {string}
     */
    getMingaHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setMingaHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.OpenMingaRequest = OpenMingaRequest;
OpenMingaRequest.displayName = "OpenMingaRequest";
class OpenMingaResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return OpenMingaResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!OpenMingaResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!OpenMingaResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new OpenMingaResponse;
        return OpenMingaResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!OpenMingaResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!OpenMingaResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = new common_legacy_pb.StatusReason;
                    reader.readMessage(value2, common_legacy_pb.StatusReason.deserializeBinaryFromReader);
                    msg.setReason(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!OpenMingaResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getReason();
        if (f != null) {
            writer.writeMessage(2, f, common_legacy_pb.StatusReason.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        OpenMingaResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * optional StatusReason reason = 2;
     * @return {?StatusReason}
     */
    getReason() {
        return /** @type{?StatusReason} */ (jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
    }
    /** @param {?StatusReason|undefined} value */
    setReason(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearReason() {
        this.setReason(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasReason() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.OpenMingaResponse = OpenMingaResponse;
OpenMingaResponse.displayName = "OpenMingaResponse";
class GetRolesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetRolesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetRolesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetRolesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetRolesRequest;
        return GetRolesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetRolesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetRolesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetRolesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetRolesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.GetRolesRequest = GetRolesRequest;
GetRolesRequest.displayName = "GetRolesRequest";
class GetRolesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetRolesResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetRolesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetRolesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            roleList: jspb.Message.toObjectList(msg.getRoleList(), Role.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetRolesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetRolesResponse;
        return GetRolesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetRolesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetRolesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!StatusCode} */ (reader.readEnum());
                    msg.setStatus(value1);
                    break;
                case 2:
                    var value2 = new Role;
                    reader.readMessage(value2, Role.deserializeBinaryFromReader);
                    msg.addRole(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetRolesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getStatus();
        if (f !== 0.0) {
            writer.writeEnum(1, f);
        }
        f = message.getRoleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, Role.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetRolesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StatusCode status = 1;
     * @return {!StatusCode}
     */
    getStatus() {
        return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {!StatusCode} value */
    setStatus(value) {
        jspb.Message.setProto3EnumField(this, 1, value);
    }
    /**
     * repeated Role role = 2;
     * @return {!Array<!Role>}
     */
    getRoleList() {
        return /** @type{!Array<!Role>} */ (jspb.Message.getRepeatedWrapperField(this, Role, 2));
    }
    /** @param {!Array<!Role>} value */
    setRoleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!Role=} opt_value
     * @param {number=} opt_index
     * @return {!Role}
     */
    addRole(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, Role, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearRoleList() {
        this.setRoleList([]);
    }
}
exports.GetRolesResponse = GetRolesResponse;
GetRolesResponse.displayName = "GetRolesResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetRolesResponse.repeatedFields_ = [2];
class GetPersonsTeamsRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonsTeamsRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonsTeamsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonsTeamsRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonsTeamsRequest;
        return GetPersonsTeamsRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonsTeamsRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonsTeamsRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonsTeamsRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonsTeamsRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.GetPersonsTeamsRequest = GetPersonsTeamsRequest;
GetPersonsTeamsRequest.displayName = "GetPersonsTeamsRequest";
class GetPersonsTeamsResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPersonsTeamsResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonsTeamsResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonsTeamsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            teamsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonsTeamsResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonsTeamsResponse;
        return GetPersonsTeamsResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonsTeamsResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonsTeamsResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addTeams(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonsTeamsResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getTeamsList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonsTeamsResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string teams = 1;
     * @return {!Array<string>}
     */
    getTeamsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setTeamsList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addTeams(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearTeamsList() {
        this.setTeamsList([]);
    }
}
exports.GetPersonsTeamsResponse = GetPersonsTeamsResponse;
GetPersonsTeamsResponse.displayName = "GetPersonsTeamsResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPersonsTeamsResponse.repeatedFields_ = [1];
class CreatePersonFields extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, CreatePersonFields.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CreatePersonFields.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CreatePersonFields} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
            lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            email: jspb.Message.getFieldWithDefault(msg, 4, ""),
            roleId: jspb.Message.getFieldWithDefault(msg, 5, 0),
            birthdate: (f = msg.getBirthdate()) && common_date_pb.Date.toObject(includeInstance, f),
            studentId: jspb.Message.getFieldWithDefault(msg, 7, ""),
            graduatingYear: jspb.Message.getFieldWithDefault(msg, 8, 0),
            phoneNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
            roleType: jspb.Message.getFieldWithDefault(msg, 10, ""),
            studentIdUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
            asbMember: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
            grade: jspb.Message.getFieldWithDefault(msg, 13, ""),
            groupList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
            password: jspb.Message.getFieldWithDefault(msg, 15, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CreatePersonFields}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CreatePersonFields;
        return CreatePersonFields.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CreatePersonFields} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CreatePersonFields}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setFirstName(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setLastName(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setDisplayName(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setEmail(value4);
                    break;
                case 5:
                    var value5 = /** @type {number} */ (reader.readInt32());
                    msg.setRoleId(value5);
                    break;
                case 6:
                    var value6 = new common_date_pb.Date;
                    reader.readMessage(value6, common_date_pb.Date.deserializeBinaryFromReader);
                    msg.setBirthdate(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value7);
                    break;
                case 8:
                    var value8 = /** @type {number} */ (reader.readInt32());
                    msg.setGraduatingYear(value8);
                    break;
                case 9:
                    var value9 = /** @type {string} */ (reader.readString());
                    msg.setPhoneNumber(value9);
                    break;
                case 10:
                    var value10 = /** @type {string} */ (reader.readString());
                    msg.setRoleType(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setStudentIdUrl(value11);
                    break;
                case 12:
                    var value12 = /** @type {boolean} */ (reader.readBool());
                    msg.setAsbMember(value12);
                    break;
                case 13:
                    var value13 = /** @type {string} */ (reader.readString());
                    msg.setGrade(value13);
                    break;
                case 14:
                    var value14 = /** @type {string} */ (reader.readString());
                    msg.addGroup(value14);
                    break;
                case 15:
                    var value15 = /** @type {string} */ (reader.readString());
                    msg.setPassword(value15);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CreatePersonFields} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getFirstName();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getLastName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDisplayName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getEmail();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getRoleId();
        if (f !== 0) {
            writer.writeInt32(5, f);
        }
        f = message.getBirthdate();
        if (f != null) {
            writer.writeMessage(6, f, common_date_pb.Date.serializeBinaryToWriter);
        }
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = message.getGraduatingYear();
        if (f !== 0) {
            writer.writeInt32(8, f);
        }
        f = message.getPhoneNumber();
        if (f.length > 0) {
            writer.writeString(9, f);
        }
        f = message.getRoleType();
        if (f.length > 0) {
            writer.writeString(10, f);
        }
        f = message.getStudentIdUrl();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getAsbMember();
        if (f) {
            writer.writeBool(12, f);
        }
        f = message.getGrade();
        if (f.length > 0) {
            writer.writeString(13, f);
        }
        f = message.getGroupList();
        if (f.length > 0) {
            writer.writeRepeatedString(14, f);
        }
        f = message.getPassword();
        if (f.length > 0) {
            writer.writeString(15, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CreatePersonFields.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string first_name = 1;
     * @return {string}
     */
    getFirstName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setFirstName(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string last_name = 2;
     * @return {string}
     */
    getLastName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setLastName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string display_name = 3;
     * @return {string}
     */
    getDisplayName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setDisplayName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string email = 4;
     * @return {string}
     */
    getEmail() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setEmail(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional int32 role_id = 5;
     * @return {number}
     */
    getRoleId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
    }
    ;
    /** @param {number} value */
    setRoleId(value) {
        jspb.Message.setProto3IntField(this, 5, value);
    }
    /**
     * optional minga.common.Date birthdate = 6;
     * @return {?Date}
     */
    getBirthdate() {
        return /** @type{?Date} */ (jspb.Message.getWrapperField(this, common_date_pb.Date, 6));
    }
    /** @param {?Date|undefined} value */
    setBirthdate(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBirthdate() {
        this.setBirthdate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBirthdate() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional string student_id = 7;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * optional int32 graduating_year = 8;
     * @return {number}
     */
    getGraduatingYear() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
    }
    ;
    /** @param {number} value */
    setGraduatingYear(value) {
        jspb.Message.setProto3IntField(this, 8, value);
    }
    /**
     * optional string phone_number = 9;
     * @return {string}
     */
    getPhoneNumber() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
    }
    ;
    /** @param {string} value */
    setPhoneNumber(value) {
        jspb.Message.setProto3StringField(this, 9, value);
    }
    /**
     * optional string role_type = 10;
     * @return {string}
     */
    getRoleType() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
    }
    ;
    /** @param {string} value */
    setRoleType(value) {
        jspb.Message.setProto3StringField(this, 10, value);
    }
    /**
     * optional string student_id_url = 11;
     * @return {string}
     */
    getStudentIdUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setStudentIdUrl(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional bool asb_member = 12;
     * @return {boolean}
     */
    getAsbMember() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
    }
    ;
    /** @param {boolean} value */
    setAsbMember(value) {
        jspb.Message.setProto3BooleanField(this, 12, value);
    }
    /**
     * optional string grade = 13;
     * @return {string}
     */
    getGrade() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
    }
    ;
    /** @param {string} value */
    setGrade(value) {
        jspb.Message.setProto3StringField(this, 13, value);
    }
    /**
     * repeated string group = 14;
     * @return {!Array<string>}
     */
    getGroupList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
    }
    ;
    /** @param {!Array<string>} value */
    setGroupList(value) {
        jspb.Message.setField(this, 14, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGroup(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 14, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGroupList() {
        this.setGroupList([]);
    }
    /**
     * optional string password = 15;
     * @return {string}
     */
    getPassword() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
    }
    ;
    /** @param {string} value */
    setPassword(value) {
        jspb.Message.setProto3StringField(this, 15, value);
    }
}
exports.CreatePersonFields = CreatePersonFields;
CreatePersonFields.displayName = "CreatePersonFields";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
CreatePersonFields.repeatedFields_ = [14];
class UpdatePersonFields extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, UpdatePersonFields.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdatePersonFields.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdatePersonFields} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            email: jspb.Message.getFieldWithDefault(msg, 1, ""),
            firstName: (f = msg.getFirstName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            lastName: (f = msg.getLastName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            displayName: (f = msg.getDisplayName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            birthdate: (f = msg.getBirthdate()) && common_delta_pb.DateDelta.toObject(includeInstance, f),
            studentId: (f = msg.getStudentId()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            graduatingYear: (f = msg.getGraduatingYear()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
            phoneNumber: (f = msg.getPhoneNumber()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            roleType: (f = msg.getRoleType()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            studentIdUrl: (f = msg.getStudentIdUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            asbMember: (f = msg.getAsbMember()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            grade: (f = msg.getGrade()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            groupList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
            password: (f = msg.getPassword()) && common_delta_pb.StringDelta.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdatePersonFields}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdatePersonFields;
        return UpdatePersonFields.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdatePersonFields} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdatePersonFields}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setEmail(value1);
                    break;
                case 2:
                    var value2 = new common_delta_pb.StringDelta;
                    reader.readMessage(value2, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setFirstName(value2);
                    break;
                case 3:
                    var value3 = new common_delta_pb.StringDelta;
                    reader.readMessage(value3, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setLastName(value3);
                    break;
                case 4:
                    var value4 = new common_delta_pb.StringDelta;
                    reader.readMessage(value4, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setDisplayName(value4);
                    break;
                case 5:
                    var value5 = new common_delta_pb.DateDelta;
                    reader.readMessage(value5, common_delta_pb.DateDelta.deserializeBinaryFromReader);
                    msg.setBirthdate(value5);
                    break;
                case 6:
                    var value6 = new common_delta_pb.StringDelta;
                    reader.readMessage(value6, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setStudentId(value6);
                    break;
                case 7:
                    var value7 = new common_delta_pb.Int32Delta;
                    reader.readMessage(value7, common_delta_pb.Int32Delta.deserializeBinaryFromReader);
                    msg.setGraduatingYear(value7);
                    break;
                case 8:
                    var value8 = new common_delta_pb.StringDelta;
                    reader.readMessage(value8, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setPhoneNumber(value8);
                    break;
                case 9:
                    var value9 = new common_delta_pb.StringDelta;
                    reader.readMessage(value9, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setRoleType(value9);
                    break;
                case 10:
                    var value10 = new common_delta_pb.StringDelta;
                    reader.readMessage(value10, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setStudentIdUrl(value10);
                    break;
                case 11:
                    var value11 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value11, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setAsbMember(value11);
                    break;
                case 12:
                    var value12 = new common_delta_pb.StringDelta;
                    reader.readMessage(value12, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setGrade(value12);
                    break;
                case 13:
                    var value13 = /** @type {string} */ (reader.readString());
                    msg.addGroup(value13);
                    break;
                case 14:
                    var value14 = new common_delta_pb.StringDelta;
                    reader.readMessage(value14, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setPassword(value14);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdatePersonFields} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getEmail();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getFirstName();
        if (f != null) {
            writer.writeMessage(2, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getLastName();
        if (f != null) {
            writer.writeMessage(3, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getDisplayName();
        if (f != null) {
            writer.writeMessage(4, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getBirthdate();
        if (f != null) {
            writer.writeMessage(5, f, common_delta_pb.DateDelta.serializeBinaryToWriter);
        }
        f = message.getStudentId();
        if (f != null) {
            writer.writeMessage(6, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getGraduatingYear();
        if (f != null) {
            writer.writeMessage(7, f, common_delta_pb.Int32Delta.serializeBinaryToWriter);
        }
        f = message.getPhoneNumber();
        if (f != null) {
            writer.writeMessage(8, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getRoleType();
        if (f != null) {
            writer.writeMessage(9, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getStudentIdUrl();
        if (f != null) {
            writer.writeMessage(10, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getAsbMember();
        if (f != null) {
            writer.writeMessage(11, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getGrade();
        if (f != null) {
            writer.writeMessage(12, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getGroupList();
        if (f.length > 0) {
            writer.writeRepeatedString(13, f);
        }
        f = message.getPassword();
        if (f != null) {
            writer.writeMessage(14, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdatePersonFields.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string email = 1;
     * @return {string}
     */
    getEmail() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setEmail(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional minga.common.StringDelta first_name = 2;
     * @return {?StringDelta}
     */
    getFirstName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
    }
    /** @param {?StringDelta|undefined} value */
    setFirstName(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearFirstName() {
        this.setFirstName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasFirstName() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional minga.common.StringDelta last_name = 3;
     * @return {?StringDelta}
     */
    getLastName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
    }
    /** @param {?StringDelta|undefined} value */
    setLastName(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearLastName() {
        this.setLastName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasLastName() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional minga.common.StringDelta display_name = 4;
     * @return {?StringDelta}
     */
    getDisplayName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 4));
    }
    /** @param {?StringDelta|undefined} value */
    setDisplayName(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDisplayName() {
        this.setDisplayName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDisplayName() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional minga.common.DateDelta birthdate = 5;
     * @return {?DateDelta}
     */
    getBirthdate() {
        return /** @type{?DateDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.DateDelta, 5));
    }
    /** @param {?DateDelta|undefined} value */
    setBirthdate(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBirthdate() {
        this.setBirthdate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBirthdate() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.StringDelta student_id = 6;
     * @return {?StringDelta}
     */
    getStudentId() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 6));
    }
    /** @param {?StringDelta|undefined} value */
    setStudentId(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStudentId() {
        this.setStudentId(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStudentId() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional minga.common.Int32Delta graduating_year = 7;
     * @return {?Int32Delta}
     */
    getGraduatingYear() {
        return /** @type{?Int32Delta} */ (jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 7));
    }
    /** @param {?Int32Delta|undefined} value */
    setGraduatingYear(value) {
        jspb.Message.setWrapperField(this, 7, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearGraduatingYear() {
        this.setGraduatingYear(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasGraduatingYear() {
        return jspb.Message.getField(this, 7) != null;
    }
    /**
     * optional minga.common.StringDelta phone_number = 8;
     * @return {?StringDelta}
     */
    getPhoneNumber() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 8));
    }
    /** @param {?StringDelta|undefined} value */
    setPhoneNumber(value) {
        jspb.Message.setWrapperField(this, 8, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPhoneNumber() {
        this.setPhoneNumber(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPhoneNumber() {
        return jspb.Message.getField(this, 8) != null;
    }
    /**
     * optional minga.common.StringDelta role_type = 9;
     * @return {?StringDelta}
     */
    getRoleType() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 9));
    }
    /** @param {?StringDelta|undefined} value */
    setRoleType(value) {
        jspb.Message.setWrapperField(this, 9, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearRoleType() {
        this.setRoleType(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasRoleType() {
        return jspb.Message.getField(this, 9) != null;
    }
    /**
     * optional minga.common.StringDelta student_id_url = 10;
     * @return {?StringDelta}
     */
    getStudentIdUrl() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 10));
    }
    /** @param {?StringDelta|undefined} value */
    setStudentIdUrl(value) {
        jspb.Message.setWrapperField(this, 10, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStudentIdUrl() {
        this.setStudentIdUrl(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStudentIdUrl() {
        return jspb.Message.getField(this, 10) != null;
    }
    /**
     * optional minga.common.BoolDelta asb_member = 11;
     * @return {?BoolDelta}
     */
    getAsbMember() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 11));
    }
    /** @param {?BoolDelta|undefined} value */
    setAsbMember(value) {
        jspb.Message.setWrapperField(this, 11, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAsbMember() {
        this.setAsbMember(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAsbMember() {
        return jspb.Message.getField(this, 11) != null;
    }
    /**
     * optional minga.common.StringDelta grade = 12;
     * @return {?StringDelta}
     */
    getGrade() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 12));
    }
    /** @param {?StringDelta|undefined} value */
    setGrade(value) {
        jspb.Message.setWrapperField(this, 12, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearGrade() {
        this.setGrade(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasGrade() {
        return jspb.Message.getField(this, 12) != null;
    }
    /**
     * repeated string group = 13;
     * @return {!Array<string>}
     */
    getGroupList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
    }
    ;
    /** @param {!Array<string>} value */
    setGroupList(value) {
        jspb.Message.setField(this, 13, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGroup(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 13, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGroupList() {
        this.setGroupList([]);
    }
    /**
     * optional minga.common.StringDelta password = 14;
     * @return {?StringDelta}
     */
    getPassword() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 14));
    }
    /** @param {?StringDelta|undefined} value */
    setPassword(value) {
        jspb.Message.setWrapperField(this, 14, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPassword() {
        this.setPassword(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPassword() {
        return jspb.Message.getField(this, 14) != null;
    }
}
exports.UpdatePersonFields = UpdatePersonFields;
UpdatePersonFields.displayName = "UpdatePersonFields";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
UpdatePersonFields.repeatedFields_ = [13];
class BulkPersonFields extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, BulkPersonFields.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return BulkPersonFields.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!BulkPersonFields} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            email: jspb.Message.getFieldWithDefault(msg, 1, ""),
            operation: jspb.Message.getFieldWithDefault(msg, 2, ""),
            firstName: (f = msg.getFirstName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            lastName: (f = msg.getLastName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            displayName: (f = msg.getDisplayName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            birthdate: (f = msg.getBirthdate()) && common_delta_pb.DateDelta.toObject(includeInstance, f),
            studentId: (f = msg.getStudentId()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            graduatingYear: (f = msg.getGraduatingYear()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
            phoneNumber: (f = msg.getPhoneNumber()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            roleType: (f = msg.getRoleType()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            studentIdUrl: (f = msg.getStudentIdUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            asbMember: (f = msg.getAsbMember()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
            grade: (f = msg.getGrade()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            groupList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
            password: (f = msg.getPassword()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            idField1: (f = msg.getIdField1()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            idField2: (f = msg.getIdField2()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
            parentEmailList: jspb.Message.toObjectList(msg.getParentEmailList(), common_delta_pb.StringDelta.toObject, includeInstance),
            parentPhoneList: jspb.Message.toObjectList(msg.getParentPhoneList(), common_delta_pb.StringDelta.toObject, includeInstance),
            disableParentNotification: (f = msg.getDisableParentNotification()) && common_delta_pb.BoolDelta.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!BulkPersonFields}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new BulkPersonFields;
        return BulkPersonFields.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!BulkPersonFields} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!BulkPersonFields}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setEmail(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setOperation(value2);
                    break;
                case 3:
                    var value3 = new common_delta_pb.StringDelta;
                    reader.readMessage(value3, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setFirstName(value3);
                    break;
                case 4:
                    var value4 = new common_delta_pb.StringDelta;
                    reader.readMessage(value4, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setLastName(value4);
                    break;
                case 5:
                    var value5 = new common_delta_pb.StringDelta;
                    reader.readMessage(value5, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setDisplayName(value5);
                    break;
                case 6:
                    var value6 = new common_delta_pb.DateDelta;
                    reader.readMessage(value6, common_delta_pb.DateDelta.deserializeBinaryFromReader);
                    msg.setBirthdate(value6);
                    break;
                case 7:
                    var value7 = new common_delta_pb.StringDelta;
                    reader.readMessage(value7, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setStudentId(value7);
                    break;
                case 8:
                    var value8 = new common_delta_pb.Int32Delta;
                    reader.readMessage(value8, common_delta_pb.Int32Delta.deserializeBinaryFromReader);
                    msg.setGraduatingYear(value8);
                    break;
                case 9:
                    var value9 = new common_delta_pb.StringDelta;
                    reader.readMessage(value9, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setPhoneNumber(value9);
                    break;
                case 10:
                    var value10 = new common_delta_pb.StringDelta;
                    reader.readMessage(value10, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setRoleType(value10);
                    break;
                case 11:
                    var value11 = new common_delta_pb.StringDelta;
                    reader.readMessage(value11, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setStudentIdUrl(value11);
                    break;
                case 12:
                    var value12 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value12, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setAsbMember(value12);
                    break;
                case 13:
                    var value13 = new common_delta_pb.StringDelta;
                    reader.readMessage(value13, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setGrade(value13);
                    break;
                case 14:
                    var value14 = /** @type {string} */ (reader.readString());
                    msg.addGroup(value14);
                    break;
                case 15:
                    var value15 = new common_delta_pb.StringDelta;
                    reader.readMessage(value15, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setPassword(value15);
                    break;
                case 16:
                    var value16 = new common_delta_pb.StringDelta;
                    reader.readMessage(value16, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setIdField1(value16);
                    break;
                case 17:
                    var value17 = new common_delta_pb.StringDelta;
                    reader.readMessage(value17, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.setIdField2(value17);
                    break;
                case 18:
                    var value18 = new common_delta_pb.StringDelta;
                    reader.readMessage(value18, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.addParentEmail(value18);
                    break;
                case 19:
                    var value19 = new common_delta_pb.StringDelta;
                    reader.readMessage(value19, common_delta_pb.StringDelta.deserializeBinaryFromReader);
                    msg.addParentPhone(value19);
                    break;
                case 20:
                    var value20 = new common_delta_pb.BoolDelta;
                    reader.readMessage(value20, common_delta_pb.BoolDelta.deserializeBinaryFromReader);
                    msg.setDisableParentNotification(value20);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!BulkPersonFields} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getEmail();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getOperation();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getFirstName();
        if (f != null) {
            writer.writeMessage(3, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getLastName();
        if (f != null) {
            writer.writeMessage(4, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getDisplayName();
        if (f != null) {
            writer.writeMessage(5, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getBirthdate();
        if (f != null) {
            writer.writeMessage(6, f, common_delta_pb.DateDelta.serializeBinaryToWriter);
        }
        f = message.getStudentId();
        if (f != null) {
            writer.writeMessage(7, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getGraduatingYear();
        if (f != null) {
            writer.writeMessage(8, f, common_delta_pb.Int32Delta.serializeBinaryToWriter);
        }
        f = message.getPhoneNumber();
        if (f != null) {
            writer.writeMessage(9, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getRoleType();
        if (f != null) {
            writer.writeMessage(10, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getStudentIdUrl();
        if (f != null) {
            writer.writeMessage(11, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getAsbMember();
        if (f != null) {
            writer.writeMessage(12, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
        f = message.getGrade();
        if (f != null) {
            writer.writeMessage(13, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getGroupList();
        if (f.length > 0) {
            writer.writeRepeatedString(14, f);
        }
        f = message.getPassword();
        if (f != null) {
            writer.writeMessage(15, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getIdField1();
        if (f != null) {
            writer.writeMessage(16, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getIdField2();
        if (f != null) {
            writer.writeMessage(17, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getParentEmailList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(18, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getParentPhoneList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(19, f, common_delta_pb.StringDelta.serializeBinaryToWriter);
        }
        f = message.getDisableParentNotification();
        if (f != null) {
            writer.writeMessage(20, f, common_delta_pb.BoolDelta.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        BulkPersonFields.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string email = 1;
     * @return {string}
     */
    getEmail() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setEmail(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string operation = 2;
     * @return {string}
     */
    getOperation() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setOperation(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional minga.common.StringDelta first_name = 3;
     * @return {?StringDelta}
     */
    getFirstName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
    }
    /** @param {?StringDelta|undefined} value */
    setFirstName(value) {
        jspb.Message.setWrapperField(this, 3, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearFirstName() {
        this.setFirstName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasFirstName() {
        return jspb.Message.getField(this, 3) != null;
    }
    /**
     * optional minga.common.StringDelta last_name = 4;
     * @return {?StringDelta}
     */
    getLastName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 4));
    }
    /** @param {?StringDelta|undefined} value */
    setLastName(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearLastName() {
        this.setLastName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasLastName() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional minga.common.StringDelta display_name = 5;
     * @return {?StringDelta}
     */
    getDisplayName() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 5));
    }
    /** @param {?StringDelta|undefined} value */
    setDisplayName(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDisplayName() {
        this.setDisplayName(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDisplayName() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.DateDelta birthdate = 6;
     * @return {?DateDelta}
     */
    getBirthdate() {
        return /** @type{?DateDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.DateDelta, 6));
    }
    /** @param {?DateDelta|undefined} value */
    setBirthdate(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBirthdate() {
        this.setBirthdate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBirthdate() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * optional minga.common.StringDelta student_id = 7;
     * @return {?StringDelta}
     */
    getStudentId() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 7));
    }
    /** @param {?StringDelta|undefined} value */
    setStudentId(value) {
        jspb.Message.setWrapperField(this, 7, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStudentId() {
        this.setStudentId(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStudentId() {
        return jspb.Message.getField(this, 7) != null;
    }
    /**
     * optional minga.common.Int32Delta graduating_year = 8;
     * @return {?Int32Delta}
     */
    getGraduatingYear() {
        return /** @type{?Int32Delta} */ (jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 8));
    }
    /** @param {?Int32Delta|undefined} value */
    setGraduatingYear(value) {
        jspb.Message.setWrapperField(this, 8, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearGraduatingYear() {
        this.setGraduatingYear(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasGraduatingYear() {
        return jspb.Message.getField(this, 8) != null;
    }
    /**
     * optional minga.common.StringDelta phone_number = 9;
     * @return {?StringDelta}
     */
    getPhoneNumber() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 9));
    }
    /** @param {?StringDelta|undefined} value */
    setPhoneNumber(value) {
        jspb.Message.setWrapperField(this, 9, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPhoneNumber() {
        this.setPhoneNumber(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPhoneNumber() {
        return jspb.Message.getField(this, 9) != null;
    }
    /**
     * optional minga.common.StringDelta role_type = 10;
     * @return {?StringDelta}
     */
    getRoleType() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 10));
    }
    /** @param {?StringDelta|undefined} value */
    setRoleType(value) {
        jspb.Message.setWrapperField(this, 10, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearRoleType() {
        this.setRoleType(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasRoleType() {
        return jspb.Message.getField(this, 10) != null;
    }
    /**
     * optional minga.common.StringDelta student_id_url = 11;
     * @return {?StringDelta}
     */
    getStudentIdUrl() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 11));
    }
    /** @param {?StringDelta|undefined} value */
    setStudentIdUrl(value) {
        jspb.Message.setWrapperField(this, 11, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearStudentIdUrl() {
        this.setStudentIdUrl(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasStudentIdUrl() {
        return jspb.Message.getField(this, 11) != null;
    }
    /**
     * optional minga.common.BoolDelta asb_member = 12;
     * @return {?BoolDelta}
     */
    getAsbMember() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 12));
    }
    /** @param {?BoolDelta|undefined} value */
    setAsbMember(value) {
        jspb.Message.setWrapperField(this, 12, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAsbMember() {
        this.setAsbMember(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAsbMember() {
        return jspb.Message.getField(this, 12) != null;
    }
    /**
     * optional minga.common.StringDelta grade = 13;
     * @return {?StringDelta}
     */
    getGrade() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 13));
    }
    /** @param {?StringDelta|undefined} value */
    setGrade(value) {
        jspb.Message.setWrapperField(this, 13, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearGrade() {
        this.setGrade(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasGrade() {
        return jspb.Message.getField(this, 13) != null;
    }
    /**
     * repeated string group = 14;
     * @return {!Array<string>}
     */
    getGroupList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
    }
    ;
    /** @param {!Array<string>} value */
    setGroupList(value) {
        jspb.Message.setField(this, 14, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGroup(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 14, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGroupList() {
        this.setGroupList([]);
    }
    /**
     * optional minga.common.StringDelta password = 15;
     * @return {?StringDelta}
     */
    getPassword() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 15));
    }
    /** @param {?StringDelta|undefined} value */
    setPassword(value) {
        jspb.Message.setWrapperField(this, 15, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearPassword() {
        this.setPassword(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasPassword() {
        return jspb.Message.getField(this, 15) != null;
    }
    /**
     * optional minga.common.StringDelta id_field_1 = 16;
     * @return {?StringDelta}
     */
    getIdField1() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 16));
    }
    /** @param {?StringDelta|undefined} value */
    setIdField1(value) {
        jspb.Message.setWrapperField(this, 16, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearIdField1() {
        this.setIdField1(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasIdField1() {
        return jspb.Message.getField(this, 16) != null;
    }
    /**
     * optional minga.common.StringDelta id_field_2 = 17;
     * @return {?StringDelta}
     */
    getIdField2() {
        return /** @type{?StringDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 17));
    }
    /** @param {?StringDelta|undefined} value */
    setIdField2(value) {
        jspb.Message.setWrapperField(this, 17, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearIdField2() {
        this.setIdField2(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasIdField2() {
        return jspb.Message.getField(this, 17) != null;
    }
    /**
     * repeated minga.common.StringDelta parent_email = 18;
     * @return {!Array<!StringDelta>}
     */
    getParentEmailList() {
        return /** @type{!Array<!StringDelta>} */ (jspb.Message.getRepeatedWrapperField(this, common_delta_pb.StringDelta, 18));
    }
    /** @param {!Array<!StringDelta>} value */
    setParentEmailList(value) {
        jspb.Message.setRepeatedWrapperField(this, 18, value);
    }
    /**
     * @param {!StringDelta=} opt_value
     * @param {number=} opt_index
     * @return {!StringDelta}
     */
    addParentEmail(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, common_delta_pb.StringDelta, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentEmailList() {
        this.setParentEmailList([]);
    }
    /**
     * repeated minga.common.StringDelta parent_phone = 19;
     * @return {!Array<!StringDelta>}
     */
    getParentPhoneList() {
        return /** @type{!Array<!StringDelta>} */ (jspb.Message.getRepeatedWrapperField(this, common_delta_pb.StringDelta, 19));
    }
    /** @param {!Array<!StringDelta>} value */
    setParentPhoneList(value) {
        jspb.Message.setRepeatedWrapperField(this, 19, value);
    }
    /**
     * @param {!StringDelta=} opt_value
     * @param {number=} opt_index
     * @return {!StringDelta}
     */
    addParentPhone(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, common_delta_pb.StringDelta, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentPhoneList() {
        this.setParentPhoneList([]);
    }
    /**
     * optional minga.common.BoolDelta disable_parent_notification = 20;
     * @return {?BoolDelta}
     */
    getDisableParentNotification() {
        return /** @type{?BoolDelta} */ (jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 20));
    }
    /** @param {?BoolDelta|undefined} value */
    setDisableParentNotification(value) {
        jspb.Message.setWrapperField(this, 20, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDisableParentNotification() {
        this.setDisableParentNotification(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDisableParentNotification() {
        return jspb.Message.getField(this, 20) != null;
    }
}
exports.BulkPersonFields = BulkPersonFields;
BulkPersonFields.displayName = "BulkPersonFields";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
BulkPersonFields.repeatedFields_ = [14, 18, 19];
class DeletePeopleBulkRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, DeletePeopleBulkRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeletePeopleBulkRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeletePeopleBulkRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            emailList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeletePeopleBulkRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeletePeopleBulkRequest;
        return DeletePeopleBulkRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeletePeopleBulkRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeletePeopleBulkRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addEmail(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeletePeopleBulkRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getEmailList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeletePeopleBulkRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string email = 1;
     * @return {!Array<string>}
     */
    getEmailList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setEmailList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addEmail(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearEmailList() {
        this.setEmailList([]);
    }
}
exports.DeletePeopleBulkRequest = DeletePeopleBulkRequest;
DeletePeopleBulkRequest.displayName = "DeletePeopleBulkRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
DeletePeopleBulkRequest.repeatedFields_ = [1];
class DeletePeopleBulkResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeletePeopleBulkResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeletePeopleBulkResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            index: jspb.Message.getFieldWithDefault(msg, 1, 0),
            progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
            totalProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
            error: (f = msg.getError()) && DeletePeopleBulkResponse.Error.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeletePeopleBulkResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeletePeopleBulkResponse;
        return DeletePeopleBulkResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeletePeopleBulkResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeletePeopleBulkResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setIndex(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readFloat());
                    msg.setProgress(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readFloat());
                    msg.setTotalProgress(value3);
                    break;
                case 4:
                    var value4 = new DeletePeopleBulkResponse.Error;
                    reader.readMessage(value4, DeletePeopleBulkResponse.Error.deserializeBinaryFromReader);
                    msg.setError(value4);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeletePeopleBulkResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getIndex();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getProgress();
        if (f !== 0.0) {
            writer.writeFloat(2, f);
        }
        f = message.getTotalProgress();
        if (f !== 0.0) {
            writer.writeFloat(3, f);
        }
        f = message.getError();
        if (f != null) {
            writer.writeMessage(4, f, DeletePeopleBulkResponse.Error.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeletePeopleBulkResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 index = 1;
     * @return {number}
     */
    getIndex() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setIndex(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional float progress = 2;
     * @return {number}
     */
    getProgress() {
        return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
    }
    ;
    /** @param {number} value */
    setProgress(value) {
        jspb.Message.setProto3FloatField(this, 2, value);
    }
    /**
     * optional float total_progress = 3;
     * @return {number}
     */
    getTotalProgress() {
        return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
    }
    ;
    /** @param {number} value */
    setTotalProgress(value) {
        jspb.Message.setProto3FloatField(this, 3, value);
    }
    /**
     * optional Error error = 4;
     * @return {?DeletePeopleBulkResponse.Error}
     */
    getError() {
        return /** @type{?DeletePeopleBulkResponse.Error} */ (jspb.Message.getWrapperField(this, DeletePeopleBulkResponse.Error, 4));
    }
    /** @param {?DeletePeopleBulkResponse.Error|undefined} value */
    setError(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearError() {
        this.setError(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasError() {
        return jspb.Message.getField(this, 4) != null;
    }
}
exports.DeletePeopleBulkResponse = DeletePeopleBulkResponse;
DeletePeopleBulkResponse.displayName = "DeletePeopleBulkResponse";
(function (DeletePeopleBulkResponse) {
    class Error extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Error.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Error} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                index: jspb.Message.getFieldWithDefault(msg, 1, 0),
                message: jspb.Message.getFieldWithDefault(msg, 2, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Error}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Error;
            return Error.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!DeletePeopleBulkResponse.Error} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!DeletePeopleBulkResponse.Error}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {number} */ (reader.readInt32());
                        msg.setIndex(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setMessage(value2);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!DeletePeopleBulkResponse.Error} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getIndex();
            if (f !== 0) {
                writer.writeInt32(1, f);
            }
            f = message.getMessage();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            DeletePeopleBulkResponse.Error.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional int32 index = 1;
         * @return {number}
         */
        getIndex() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
        }
        ;
        /** @param {number} value */
        setIndex(value) {
            jspb.Message.setProto3IntField(this, 1, value);
        }
        /**
         * optional string message = 2;
         * @return {string}
         */
        getMessage() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setMessage(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
    }
    Error.displayName = "DeletePeopleBulkResponse.Error";
    DeletePeopleBulkResponse.Error = Error;
})(DeletePeopleBulkResponse = exports.DeletePeopleBulkResponse || (exports.DeletePeopleBulkResponse = {})); // namespace DeletePeopleBulkResponse
class ArchivePeopleBulkRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, ArchivePeopleBulkRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ArchivePeopleBulkRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ArchivePeopleBulkRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            emailList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ArchivePeopleBulkRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ArchivePeopleBulkRequest;
        return ArchivePeopleBulkRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ArchivePeopleBulkRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ArchivePeopleBulkRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.addEmail(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ArchivePeopleBulkRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getEmailList();
        if (f.length > 0) {
            writer.writeRepeatedString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ArchivePeopleBulkRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated string email = 1;
     * @return {!Array<string>}
     */
    getEmailList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<string>} value */
    setEmailList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addEmail(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearEmailList() {
        this.setEmailList([]);
    }
}
exports.ArchivePeopleBulkRequest = ArchivePeopleBulkRequest;
ArchivePeopleBulkRequest.displayName = "ArchivePeopleBulkRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
ArchivePeopleBulkRequest.repeatedFields_ = [1];
class ArchivePeopleBulkResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ArchivePeopleBulkResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ArchivePeopleBulkResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            error: (f = msg.getError()) && ArchivePeopleBulkResponse.Error.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ArchivePeopleBulkResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ArchivePeopleBulkResponse;
        return ArchivePeopleBulkResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ArchivePeopleBulkResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ArchivePeopleBulkResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 4:
                    var value4 = new ArchivePeopleBulkResponse.Error;
                    reader.readMessage(value4, ArchivePeopleBulkResponse.Error.deserializeBinaryFromReader);
                    msg.setError(value4);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ArchivePeopleBulkResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getError();
        if (f != null) {
            writer.writeMessage(4, f, ArchivePeopleBulkResponse.Error.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ArchivePeopleBulkResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional Error error = 4;
     * @return {?ArchivePeopleBulkResponse.Error}
     */
    getError() {
        return /** @type{?ArchivePeopleBulkResponse.Error} */ (jspb.Message.getWrapperField(this, ArchivePeopleBulkResponse.Error, 4));
    }
    /** @param {?ArchivePeopleBulkResponse.Error|undefined} value */
    setError(value) {
        jspb.Message.setWrapperField(this, 4, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearError() {
        this.setError(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasError() {
        return jspb.Message.getField(this, 4) != null;
    }
}
exports.ArchivePeopleBulkResponse = ArchivePeopleBulkResponse;
ArchivePeopleBulkResponse.displayName = "ArchivePeopleBulkResponse";
(function (ArchivePeopleBulkResponse) {
    class Error extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Error.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Error} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                message: jspb.Message.getFieldWithDefault(msg, 2, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Error}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Error;
            return Error.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!ArchivePeopleBulkResponse.Error} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!ArchivePeopleBulkResponse.Error}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setMessage(value2);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!ArchivePeopleBulkResponse.Error} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getMessage();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            ArchivePeopleBulkResponse.Error.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional string message = 2;
         * @return {string}
         */
        getMessage() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setMessage(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
    }
    Error.displayName = "ArchivePeopleBulkResponse.Error";
    ArchivePeopleBulkResponse.Error = Error;
})(ArchivePeopleBulkResponse = exports.ArchivePeopleBulkResponse || (exports.ArchivePeopleBulkResponse = {})); // namespace ArchivePeopleBulkResponse
class SyncPeopleBulkRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, SyncPeopleBulkRequest.repeatedFields_, SyncPeopleBulkRequest.oneofGroups_);
    }
    /**
     * @return {SyncPeopleBulkRequest.IdFieldCase}
     */
    getIdFieldCase() {
        return /** @type {SyncPeopleBulkRequest.IdFieldCase} */ (jspb.Message.computeOneofCase(this, SyncPeopleBulkRequest.oneofGroups_[0]));
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return SyncPeopleBulkRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!SyncPeopleBulkRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personFieldsList: jspb.Message.toObjectList(msg.getPersonFieldsList(), BulkPersonFields.toObject, includeInstance),
            sendPinEmails: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            parentGroupList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
            syncRoster: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            archivePeople: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            useRole: jspb.Message.getFieldWithDefault(msg, 7, ""),
            useStudentId: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            useEmail: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!SyncPeopleBulkRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new SyncPeopleBulkRequest;
        return SyncPeopleBulkRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!SyncPeopleBulkRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!SyncPeopleBulkRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new BulkPersonFields;
                    reader.readMessage(value1, BulkPersonFields.deserializeBinaryFromReader);
                    msg.addPersonFields(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setSendPinEmails(value2);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.addParentGroup(value4);
                    break;
                case 5:
                    var value5 = /** @type {boolean} */ (reader.readBool());
                    msg.setSyncRoster(value5);
                    break;
                case 6:
                    var value6 = /** @type {boolean} */ (reader.readBool());
                    msg.setArchivePeople(value6);
                    break;
                case 7:
                    var value7 = /** @type {string} */ (reader.readString());
                    msg.setUseRole(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setUseStudentId(value8);
                    break;
                case 9:
                    var value9 = /** @type {boolean} */ (reader.readBool());
                    msg.setUseEmail(value9);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!SyncPeopleBulkRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonFieldsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, BulkPersonFields.serializeBinaryToWriter);
        }
        f = message.getSendPinEmails();
        if (f) {
            writer.writeBool(2, f);
        }
        f = message.getParentGroupList();
        if (f.length > 0) {
            writer.writeRepeatedString(4, f);
        }
        f = message.getSyncRoster();
        if (f) {
            writer.writeBool(5, f);
        }
        f = message.getArchivePeople();
        if (f) {
            writer.writeBool(6, f);
        }
        f = message.getUseRole();
        if (f.length > 0) {
            writer.writeString(7, f);
        }
        f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
        if (f != null) {
            writer.writeBool(8, f);
        }
        f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
        if (f != null) {
            writer.writeBool(9, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        SyncPeopleBulkRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated BulkPersonFields person_fields = 1;
     * @return {!Array<!BulkPersonFields>}
     */
    getPersonFieldsList() {
        return /** @type{!Array<!BulkPersonFields>} */ (jspb.Message.getRepeatedWrapperField(this, BulkPersonFields, 1));
    }
    /** @param {!Array<!BulkPersonFields>} value */
    setPersonFieldsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!BulkPersonFields=} opt_value
     * @param {number=} opt_index
     * @return {!BulkPersonFields}
     */
    addPersonFields(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, BulkPersonFields, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPersonFieldsList() {
        this.setPersonFieldsList([]);
    }
    /**
     * optional bool send_pin_emails = 2;
     * @return {boolean}
     */
    getSendPinEmails() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setSendPinEmails(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
    /**
     * repeated string parent_group = 4;
     * @return {!Array<string>}
     */
    getParentGroupList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
    }
    ;
    /** @param {!Array<string>} value */
    setParentGroupList(value) {
        jspb.Message.setField(this, 4, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addParentGroup(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 4, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearParentGroupList() {
        this.setParentGroupList([]);
    }
    /**
     * optional bool sync_roster = 5;
     * @return {boolean}
     */
    getSyncRoster() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
    }
    ;
    /** @param {boolean} value */
    setSyncRoster(value) {
        jspb.Message.setProto3BooleanField(this, 5, value);
    }
    /**
     * optional bool archive_people = 6;
     * @return {boolean}
     */
    getArchivePeople() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
    }
    ;
    /** @param {boolean} value */
    setArchivePeople(value) {
        jspb.Message.setProto3BooleanField(this, 6, value);
    }
    /**
     * optional string use_role = 7;
     * @return {string}
     */
    getUseRole() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
    }
    ;
    /** @param {string} value */
    setUseRole(value) {
        jspb.Message.setProto3StringField(this, 7, value);
    }
    /**
     * optional bool use_student_id = 8;
     * @return {boolean}
     */
    getUseStudentId() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setUseStudentId(value) {
        jspb.Message.setOneofField(this, 8, SyncPeopleBulkRequest.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearUseStudentId() {
        jspb.Message.setOneofField(this, 8, SyncPeopleBulkRequest.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasUseStudentId() {
        return jspb.Message.getField(this, 8) != null;
    }
    /**
     * optional bool use_email = 9;
     * @return {boolean}
     */
    getUseEmail() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
    }
    ;
    /** @param {boolean} value */
    setUseEmail(value) {
        jspb.Message.setOneofField(this, 9, SyncPeopleBulkRequest.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearUseEmail() {
        jspb.Message.setOneofField(this, 9, SyncPeopleBulkRequest.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasUseEmail() {
        return jspb.Message.getField(this, 9) != null;
    }
}
exports.SyncPeopleBulkRequest = SyncPeopleBulkRequest;
SyncPeopleBulkRequest.displayName = "SyncPeopleBulkRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
SyncPeopleBulkRequest.repeatedFields_ = [1, 4];
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
SyncPeopleBulkRequest.oneofGroups_ = [[8, 9]];
(function (SyncPeopleBulkRequest) {
    let IdFieldCase;
    (function (IdFieldCase) {
        IdFieldCase[IdFieldCase["ID_FIELD_NOT_SET"] = 0] = "ID_FIELD_NOT_SET";
        IdFieldCase[IdFieldCase["USE_STUDENT_ID"] = 8] = "USE_STUDENT_ID";
        IdFieldCase[IdFieldCase["USE_EMAIL"] = 9] = "USE_EMAIL";
    })(IdFieldCase = SyncPeopleBulkRequest.IdFieldCase || (SyncPeopleBulkRequest.IdFieldCase = {}));
})(SyncPeopleBulkRequest = exports.SyncPeopleBulkRequest || (exports.SyncPeopleBulkRequest = {})); // namespace SyncPeopleBulkRequest
class SyncPeopleBulkResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, SyncPeopleBulkResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return SyncPeopleBulkResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!SyncPeopleBulkResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            currentOperation: jspb.Message.getFieldWithDefault(msg, 1, ""),
            index: jspb.Message.getFieldWithDefault(msg, 2, 0),
            progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
            totalProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
            error: (f = msg.getError()) && SyncPeopleBulkResponse.Error.toObject(includeInstance, f),
            archiveEmailsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
            totalUpdated: jspb.Message.getFieldWithDefault(msg, 7, 0),
            totalCreated: jspb.Message.getFieldWithDefault(msg, 8, 0),
            totalIgnored: jspb.Message.getFieldWithDefault(msg, 9, 0),
            archivePeopleList: jspb.Message.toObjectList(msg.getArchivePeopleList(), SyncPeopleBulkResponse.ArchivePeople.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!SyncPeopleBulkResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new SyncPeopleBulkResponse;
        return SyncPeopleBulkResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!SyncPeopleBulkResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!SyncPeopleBulkResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setCurrentOperation(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readInt32());
                    msg.setIndex(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readFloat());
                    msg.setProgress(value3);
                    break;
                case 4:
                    var value4 = /** @type {number} */ (reader.readFloat());
                    msg.setTotalProgress(value4);
                    break;
                case 5:
                    var value5 = new SyncPeopleBulkResponse.Error;
                    reader.readMessage(value5, SyncPeopleBulkResponse.Error.deserializeBinaryFromReader);
                    msg.setError(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.addArchiveEmails(value6);
                    break;
                case 7:
                    var value7 = /** @type {number} */ (reader.readInt32());
                    msg.setTotalUpdated(value7);
                    break;
                case 8:
                    var value8 = /** @type {number} */ (reader.readInt32());
                    msg.setTotalCreated(value8);
                    break;
                case 9:
                    var value9 = /** @type {number} */ (reader.readInt32());
                    msg.setTotalIgnored(value9);
                    break;
                case 10:
                    var value10 = new SyncPeopleBulkResponse.ArchivePeople;
                    reader.readMessage(value10, SyncPeopleBulkResponse.ArchivePeople.deserializeBinaryFromReader);
                    msg.addArchivePeople(value10);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!SyncPeopleBulkResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getCurrentOperation();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getIndex();
        if (f !== 0) {
            writer.writeInt32(2, f);
        }
        f = message.getProgress();
        if (f !== 0.0) {
            writer.writeFloat(3, f);
        }
        f = message.getTotalProgress();
        if (f !== 0.0) {
            writer.writeFloat(4, f);
        }
        f = message.getError();
        if (f != null) {
            writer.writeMessage(5, f, SyncPeopleBulkResponse.Error.serializeBinaryToWriter);
        }
        f = message.getArchiveEmailsList();
        if (f.length > 0) {
            writer.writeRepeatedString(6, f);
        }
        f = message.getTotalUpdated();
        if (f !== 0) {
            writer.writeInt32(7, f);
        }
        f = message.getTotalCreated();
        if (f !== 0) {
            writer.writeInt32(8, f);
        }
        f = message.getTotalIgnored();
        if (f !== 0) {
            writer.writeInt32(9, f);
        }
        f = message.getArchivePeopleList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(10, f, SyncPeopleBulkResponse.ArchivePeople.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        SyncPeopleBulkResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string current_operation = 1;
     * @return {string}
     */
    getCurrentOperation() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setCurrentOperation(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional int32 index = 2;
     * @return {number}
     */
    getIndex() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setIndex(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
    /**
     * optional float progress = 3;
     * @return {number}
     */
    getProgress() {
        return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
    }
    ;
    /** @param {number} value */
    setProgress(value) {
        jspb.Message.setProto3FloatField(this, 3, value);
    }
    /**
     * optional float total_progress = 4;
     * @return {number}
     */
    getTotalProgress() {
        return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
    }
    ;
    /** @param {number} value */
    setTotalProgress(value) {
        jspb.Message.setProto3FloatField(this, 4, value);
    }
    /**
     * optional Error error = 5;
     * @return {?SyncPeopleBulkResponse.Error}
     */
    getError() {
        return /** @type{?SyncPeopleBulkResponse.Error} */ (jspb.Message.getWrapperField(this, SyncPeopleBulkResponse.Error, 5));
    }
    /** @param {?SyncPeopleBulkResponse.Error|undefined} value */
    setError(value) {
        jspb.Message.setWrapperField(this, 5, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearError() {
        this.setError(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasError() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * repeated string archive_emails = 6;
     * @return {!Array<string>}
     */
    getArchiveEmailsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
    }
    ;
    /** @param {!Array<string>} value */
    setArchiveEmailsList(value) {
        jspb.Message.setField(this, 6, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addArchiveEmails(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 6, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearArchiveEmailsList() {
        this.setArchiveEmailsList([]);
    }
    /**
     * optional int32 total_updated = 7;
     * @return {number}
     */
    getTotalUpdated() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
    }
    ;
    /** @param {number} value */
    setTotalUpdated(value) {
        jspb.Message.setProto3IntField(this, 7, value);
    }
    /**
     * optional int32 total_created = 8;
     * @return {number}
     */
    getTotalCreated() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
    }
    ;
    /** @param {number} value */
    setTotalCreated(value) {
        jspb.Message.setProto3IntField(this, 8, value);
    }
    /**
     * optional int32 total_ignored = 9;
     * @return {number}
     */
    getTotalIgnored() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
    }
    ;
    /** @param {number} value */
    setTotalIgnored(value) {
        jspb.Message.setProto3IntField(this, 9, value);
    }
    /**
     * repeated ArchivePeople archive_people = 10;
     * @return {!Array<!SyncPeopleBulkResponse.ArchivePeople>}
     */
    getArchivePeopleList() {
        return /** @type{!Array<!SyncPeopleBulkResponse.ArchivePeople>} */ (jspb.Message.getRepeatedWrapperField(this, SyncPeopleBulkResponse.ArchivePeople, 10));
    }
    /** @param {!Array<!SyncPeopleBulkResponse.ArchivePeople>} value */
    setArchivePeopleList(value) {
        jspb.Message.setRepeatedWrapperField(this, 10, value);
    }
    /**
     * @param {!SyncPeopleBulkResponse.ArchivePeople=} opt_value
     * @param {number=} opt_index
     * @return {!SyncPeopleBulkResponse.ArchivePeople}
     */
    addArchivePeople(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, SyncPeopleBulkResponse.ArchivePeople, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearArchivePeopleList() {
        this.setArchivePeopleList([]);
    }
}
exports.SyncPeopleBulkResponse = SyncPeopleBulkResponse;
SyncPeopleBulkResponse.displayName = "SyncPeopleBulkResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
SyncPeopleBulkResponse.repeatedFields_ = [6, 10];
(function (SyncPeopleBulkResponse) {
    class Error extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Error.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Error} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                index: jspb.Message.getFieldWithDefault(msg, 1, 0),
                message: jspb.Message.getFieldWithDefault(msg, 2, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Error}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Error;
            return Error.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!SyncPeopleBulkResponse.Error} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!SyncPeopleBulkResponse.Error}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {number} */ (reader.readInt32());
                        msg.setIndex(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setMessage(value2);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!SyncPeopleBulkResponse.Error} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getIndex();
            if (f !== 0) {
                writer.writeInt32(1, f);
            }
            f = message.getMessage();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            SyncPeopleBulkResponse.Error.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional int32 index = 1;
         * @return {number}
         */
        getIndex() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
        }
        ;
        /** @param {number} value */
        setIndex(value) {
            jspb.Message.setProto3IntField(this, 1, value);
        }
        /**
         * optional string message = 2;
         * @return {string}
         */
        getMessage() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setMessage(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
    }
    Error.displayName = "SyncPeopleBulkResponse.Error";
    SyncPeopleBulkResponse.Error = Error;
})(SyncPeopleBulkResponse = exports.SyncPeopleBulkResponse || (exports.SyncPeopleBulkResponse = {})); // namespace SyncPeopleBulkResponse
(function (SyncPeopleBulkResponse) {
    class ArchivePeople extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return ArchivePeople.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!ArchivePeople} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                email: jspb.Message.getFieldWithDefault(msg, 1, ""),
                name: jspb.Message.getFieldWithDefault(msg, 2, ""),
                grade: jspb.Message.getFieldWithDefault(msg, 3, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!ArchivePeople}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new ArchivePeople;
            return ArchivePeople.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!SyncPeopleBulkResponse.ArchivePeople} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!SyncPeopleBulkResponse.ArchivePeople}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {string} */ (reader.readString());
                        msg.setEmail(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setName(value2);
                        break;
                    case 3:
                        var value3 = /** @type {string} */ (reader.readString());
                        msg.setGrade(value3);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!SyncPeopleBulkResponse.ArchivePeople} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getEmail();
            if (f.length > 0) {
                writer.writeString(1, f);
            }
            f = message.getName();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
            f = message.getGrade();
            if (f.length > 0) {
                writer.writeString(3, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            SyncPeopleBulkResponse.ArchivePeople.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional string email = 1;
         * @return {string}
         */
        getEmail() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
        }
        ;
        /** @param {string} value */
        setEmail(value) {
            jspb.Message.setProto3StringField(this, 1, value);
        }
        /**
         * optional string name = 2;
         * @return {string}
         */
        getName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setName(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
        /**
         * optional string grade = 3;
         * @return {string}
         */
        getGrade() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
        }
        ;
        /** @param {string} value */
        setGrade(value) {
            jspb.Message.setProto3StringField(this, 3, value);
        }
    }
    ArchivePeople.displayName = "SyncPeopleBulkResponse.ArchivePeople";
    SyncPeopleBulkResponse.ArchivePeople = ArchivePeople;
})(SyncPeopleBulkResponse = exports.SyncPeopleBulkResponse || (exports.SyncPeopleBulkResponse = {})); // namespace SyncPeopleBulkResponse
class ResendPinEmailRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ResendPinEmailRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ResendPinEmailRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            sendPinEmails: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ResendPinEmailRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ResendPinEmailRequest;
        return ResendPinEmailRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ResendPinEmailRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ResendPinEmailRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setSendPinEmails(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ResendPinEmailRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getSendPinEmails();
        if (f) {
            writer.writeBool(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ResendPinEmailRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional bool send_pin_emails = 2;
     * @return {boolean}
     */
    getSendPinEmails() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setSendPinEmails(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
}
exports.ResendPinEmailRequest = ResendPinEmailRequest;
ResendPinEmailRequest.displayName = "ResendPinEmailRequest";
class ResendPinEmailResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ResendPinEmailResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ResendPinEmailResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ResendPinEmailResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ResendPinEmailResponse;
        return ResendPinEmailResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ResendPinEmailResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ResendPinEmailResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ResendPinEmailResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ResendPinEmailResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.ResendPinEmailResponse = ResendPinEmailResponse;
ResendPinEmailResponse.displayName = "ResendPinEmailResponse";
class ResendAllInvitesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ResendAllInvitesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ResendAllInvitesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            sendInviteEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            sendInviteSms: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ResendAllInvitesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ResendAllInvitesRequest;
        return ResendAllInvitesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ResendAllInvitesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ResendAllInvitesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {boolean} */ (reader.readBool());
                    msg.setSendInviteEmail(value1);
                    break;
                case 2:
                    var value2 = /** @type {boolean} */ (reader.readBool());
                    msg.setSendInviteSms(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ResendAllInvitesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getSendInviteEmail();
        if (f) {
            writer.writeBool(1, f);
        }
        f = message.getSendInviteSms();
        if (f) {
            writer.writeBool(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ResendAllInvitesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bool send_invite_email = 1;
     * @return {boolean}
     */
    getSendInviteEmail() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
    }
    ;
    /** @param {boolean} value */
    setSendInviteEmail(value) {
        jspb.Message.setProto3BooleanField(this, 1, value);
    }
    /**
     * optional bool send_invite_sms = 2;
     * @return {boolean}
     */
    getSendInviteSms() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
    }
    ;
    /** @param {boolean} value */
    setSendInviteSms(value) {
        jspb.Message.setProto3BooleanField(this, 2, value);
    }
}
exports.ResendAllInvitesRequest = ResendAllInvitesRequest;
ResendAllInvitesRequest.displayName = "ResendAllInvitesRequest";
class ResendAllInvitesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ResendAllInvitesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ResendAllInvitesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ResendAllInvitesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ResendAllInvitesResponse;
        return ResendAllInvitesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ResendAllInvitesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ResendAllInvitesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ResendAllInvitesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ResendAllInvitesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.ResendAllInvitesResponse = ResendAllInvitesResponse;
ResendAllInvitesResponse.displayName = "ResendAllInvitesResponse";
class MingaPeopleInfoRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return MingaPeopleInfoRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!MingaPeopleInfoRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            parentGroupHash: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!MingaPeopleInfoRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new MingaPeopleInfoRequest;
        return MingaPeopleInfoRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!MingaPeopleInfoRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!MingaPeopleInfoRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setParentGroupHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!MingaPeopleInfoRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getParentGroupHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        MingaPeopleInfoRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string parent_group_hash = 1;
     * @return {string}
     */
    getParentGroupHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setParentGroupHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.MingaPeopleInfoRequest = MingaPeopleInfoRequest;
MingaPeopleInfoRequest.displayName = "MingaPeopleInfoRequest";
class MingaPeopleInfo extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, MingaPeopleInfo.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return MingaPeopleInfo.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!MingaPeopleInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            primaryStatFilterList: jspb.Message.toObjectList(msg.getPrimaryStatFilterList(), MingaPeopleInfo.StatFilter.toObject, includeInstance),
            secondaryStatFilterList: jspb.Message.toObjectList(msg.getSecondaryStatFilterList(), MingaPeopleInfo.StatFilter.toObject, includeInstance),
            unregisteredCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!MingaPeopleInfo}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new MingaPeopleInfo;
        return MingaPeopleInfo.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!MingaPeopleInfo} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!MingaPeopleInfo}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new MingaPeopleInfo.StatFilter;
                    reader.readMessage(value1, MingaPeopleInfo.StatFilter.deserializeBinaryFromReader);
                    msg.addPrimaryStatFilter(value1);
                    break;
                case 2:
                    var value2 = new MingaPeopleInfo.StatFilter;
                    reader.readMessage(value2, MingaPeopleInfo.StatFilter.deserializeBinaryFromReader);
                    msg.addSecondaryStatFilter(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readInt32());
                    msg.setUnregisteredCount(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!MingaPeopleInfo} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPrimaryStatFilterList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, MingaPeopleInfo.StatFilter.serializeBinaryToWriter);
        }
        f = message.getSecondaryStatFilterList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, MingaPeopleInfo.StatFilter.serializeBinaryToWriter);
        }
        f = message.getUnregisteredCount();
        if (f !== 0) {
            writer.writeInt32(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        MingaPeopleInfo.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated StatFilter primary_stat_filter = 1;
     * @return {!Array<!MingaPeopleInfo.StatFilter>}
     */
    getPrimaryStatFilterList() {
        return /** @type{!Array<!MingaPeopleInfo.StatFilter>} */ (jspb.Message.getRepeatedWrapperField(this, MingaPeopleInfo.StatFilter, 1));
    }
    /** @param {!Array<!MingaPeopleInfo.StatFilter>} value */
    setPrimaryStatFilterList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!MingaPeopleInfo.StatFilter=} opt_value
     * @param {number=} opt_index
     * @return {!MingaPeopleInfo.StatFilter}
     */
    addPrimaryStatFilter(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaPeopleInfo.StatFilter, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPrimaryStatFilterList() {
        this.setPrimaryStatFilterList([]);
    }
    /**
     * repeated StatFilter secondary_stat_filter = 2;
     * @return {!Array<!MingaPeopleInfo.StatFilter>}
     */
    getSecondaryStatFilterList() {
        return /** @type{!Array<!MingaPeopleInfo.StatFilter>} */ (jspb.Message.getRepeatedWrapperField(this, MingaPeopleInfo.StatFilter, 2));
    }
    /** @param {!Array<!MingaPeopleInfo.StatFilter>} value */
    setSecondaryStatFilterList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!MingaPeopleInfo.StatFilter=} opt_value
     * @param {number=} opt_index
     * @return {!MingaPeopleInfo.StatFilter}
     */
    addSecondaryStatFilter(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, MingaPeopleInfo.StatFilter, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearSecondaryStatFilterList() {
        this.setSecondaryStatFilterList([]);
    }
    /**
     * optional int32 unregistered_count = 3;
     * @return {number}
     */
    getUnregisteredCount() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
    }
    ;
    /** @param {number} value */
    setUnregisteredCount(value) {
        jspb.Message.setProto3IntField(this, 3, value);
    }
}
exports.MingaPeopleInfo = MingaPeopleInfo;
MingaPeopleInfo.displayName = "MingaPeopleInfo";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
MingaPeopleInfo.repeatedFields_ = [1, 2];
(function (MingaPeopleInfo) {
    let StatChange;
    (function (StatChange) {
        StatChange[StatChange["NONE"] = 0] = "NONE";
        StatChange[StatChange["INCREASE"] = 1] = "INCREASE";
        StatChange[StatChange["DECREASE"] = 2] = "DECREASE";
    })(StatChange = MingaPeopleInfo.StatChange || (MingaPeopleInfo.StatChange = {}));
})(MingaPeopleInfo = exports.MingaPeopleInfo || (exports.MingaPeopleInfo = {})); // namespace MingaPeopleInfo
(function (MingaPeopleInfo) {
    class StatFilter extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, MingaPeopleInfo.StatFilter.oneofGroups_);
        }
        /**
         * @return {MingaPeopleInfo.StatFilter.StatNumberCase}
         */
        getStatNumberCase() {
            return /** @type {MingaPeopleInfo.StatFilter.StatNumberCase} */ (jspb.Message.computeOneofCase(this, MingaPeopleInfo.StatFilter.oneofGroups_[0]));
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return StatFilter.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!StatFilter} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                statTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
                statCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
                statPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
                statTotal: jspb.Message.getFieldWithDefault(msg, 4, 0),
                statChange: jspb.Message.getFieldWithDefault(msg, 5, 0),
                statChangeText: jspb.Message.getFieldWithDefault(msg, 6, ""),
                statSearchFilter: (f = msg.getStatSearchFilter()) && common_stream_pb.StreamFilter.toObject(includeInstance, f),
                monthly: jspb.Message.getFieldWithDefault(msg, 8, 0),
                weekly: jspb.Message.getFieldWithDefault(msg, 9, 0),
                daily: jspb.Message.getFieldWithDefault(msg, 10, 0),
                registered: jspb.Message.getFieldWithDefault(msg, 11, 0),
                statColor: jspb.Message.getFieldWithDefault(msg, 12, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!StatFilter}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new StatFilter;
            return StatFilter.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!MingaPeopleInfo.StatFilter} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!MingaPeopleInfo.StatFilter}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {string} */ (reader.readString());
                        msg.setStatTitle(value1);
                        break;
                    case 2:
                        var value2 = /** @type {number} */ (reader.readInt32());
                        msg.setStatCount(value2);
                        break;
                    case 3:
                        var value3 = /** @type {number} */ (reader.readFloat());
                        msg.setStatPercent(value3);
                        break;
                    case 4:
                        var value4 = /** @type {number} */ (reader.readInt32());
                        msg.setStatTotal(value4);
                        break;
                    case 5:
                        var value5 = /** @type {!MingaPeopleInfo.StatChange} */ (reader.readEnum());
                        msg.setStatChange(value5);
                        break;
                    case 6:
                        var value6 = /** @type {string} */ (reader.readString());
                        msg.setStatChangeText(value6);
                        break;
                    case 7:
                        var value7 = new common_stream_pb.StreamFilter;
                        reader.readMessage(value7, common_stream_pb.StreamFilter.deserializeBinaryFromReader);
                        msg.setStatSearchFilter(value7);
                        break;
                    case 8:
                        var value8 = /** @type {number} */ (reader.readInt32());
                        msg.setMonthly(value8);
                        break;
                    case 9:
                        var value9 = /** @type {number} */ (reader.readInt32());
                        msg.setWeekly(value9);
                        break;
                    case 10:
                        var value10 = /** @type {number} */ (reader.readInt32());
                        msg.setDaily(value10);
                        break;
                    case 11:
                        var value11 = /** @type {number} */ (reader.readInt32());
                        msg.setRegistered(value11);
                        break;
                    case 12:
                        var value12 = /** @type {string} */ (reader.readString());
                        msg.setStatColor(value12);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!MingaPeopleInfo.StatFilter} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getStatTitle();
            if (f.length > 0) {
                writer.writeString(1, f);
            }
            f = /** @type {number} */ (jspb.Message.getField(message, 2));
            if (f != null) {
                writer.writeInt32(2, f);
            }
            f = /** @type {number} */ (jspb.Message.getField(message, 3));
            if (f != null) {
                writer.writeFloat(3, f);
            }
            f = message.getStatTotal();
            if (f !== 0) {
                writer.writeInt32(4, f);
            }
            f = message.getStatChange();
            if (f !== 0.0) {
                writer.writeEnum(5, f);
            }
            f = message.getStatChangeText();
            if (f.length > 0) {
                writer.writeString(6, f);
            }
            f = message.getStatSearchFilter();
            if (f != null) {
                writer.writeMessage(7, f, common_stream_pb.StreamFilter.serializeBinaryToWriter);
            }
            f = message.getMonthly();
            if (f !== 0) {
                writer.writeInt32(8, f);
            }
            f = message.getWeekly();
            if (f !== 0) {
                writer.writeInt32(9, f);
            }
            f = message.getDaily();
            if (f !== 0) {
                writer.writeInt32(10, f);
            }
            f = message.getRegistered();
            if (f !== 0) {
                writer.writeInt32(11, f);
            }
            f = message.getStatColor();
            if (f.length > 0) {
                writer.writeString(12, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            MingaPeopleInfo.StatFilter.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional string stat_title = 1;
         * @return {string}
         */
        getStatTitle() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
        }
        ;
        /** @param {string} value */
        setStatTitle(value) {
            jspb.Message.setProto3StringField(this, 1, value);
        }
        /**
         * optional int32 stat_count = 2;
         * @return {number}
         */
        getStatCount() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
        }
        ;
        /** @param {number} value */
        setStatCount(value) {
            jspb.Message.setOneofField(this, 2, MingaPeopleInfo.StatFilter.oneofGroups_[0], value);
        }
        /**
         * Clears the field making it undefined.
         */
        clearStatCount() {
            jspb.Message.setOneofField(this, 2, MingaPeopleInfo.StatFilter.oneofGroups_[0], undefined);
        }
        /**
         * Returns whether this field is set.
         * @return {boolean}
         */
        hasStatCount() {
            return jspb.Message.getField(this, 2) != null;
        }
        /**
         * optional float stat_percent = 3;
         * @return {number}
         */
        getStatPercent() {
            return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
        }
        ;
        /** @param {number} value */
        setStatPercent(value) {
            jspb.Message.setOneofField(this, 3, MingaPeopleInfo.StatFilter.oneofGroups_[0], value);
        }
        /**
         * Clears the field making it undefined.
         */
        clearStatPercent() {
            jspb.Message.setOneofField(this, 3, MingaPeopleInfo.StatFilter.oneofGroups_[0], undefined);
        }
        /**
         * Returns whether this field is set.
         * @return {boolean}
         */
        hasStatPercent() {
            return jspb.Message.getField(this, 3) != null;
        }
        /**
         * optional int32 stat_total = 4;
         * @return {number}
         */
        getStatTotal() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
        }
        ;
        /** @param {number} value */
        setStatTotal(value) {
            jspb.Message.setProto3IntField(this, 4, value);
        }
        /**
         * optional StatChange stat_change = 5;
         * @return {!MingaPeopleInfo.StatChange}
         */
        getStatChange() {
            return /** @type {!MingaPeopleInfo.StatChange} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
        }
        ;
        /** @param {!MingaPeopleInfo.StatChange} value */
        setStatChange(value) {
            jspb.Message.setProto3EnumField(this, 5, value);
        }
        /**
         * optional string stat_change_text = 6;
         * @return {string}
         */
        getStatChangeText() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
        }
        ;
        /** @param {string} value */
        setStatChangeText(value) {
            jspb.Message.setProto3StringField(this, 6, value);
        }
        /**
         * optional StreamFilter stat_search_filter = 7;
         * @return {?StreamFilter}
         */
        getStatSearchFilter() {
            return /** @type{?StreamFilter} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamFilter, 7));
        }
        /** @param {?StreamFilter|undefined} value */
        setStatSearchFilter(value) {
            jspb.Message.setWrapperField(this, 7, value);
        }
        /**
         * Clears the message field making it undefined.
         */
        clearStatSearchFilter() {
            this.setStatSearchFilter(undefined);
        }
        /**
         * Returns whether this field is set.
         * @return {boolean}
         */
        hasStatSearchFilter() {
            return jspb.Message.getField(this, 7) != null;
        }
        /**
         * optional int32 monthly = 8;
         * @return {number}
         */
        getMonthly() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
        }
        ;
        /** @param {number} value */
        setMonthly(value) {
            jspb.Message.setProto3IntField(this, 8, value);
        }
        /**
         * optional int32 weekly = 9;
         * @return {number}
         */
        getWeekly() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
        }
        ;
        /** @param {number} value */
        setWeekly(value) {
            jspb.Message.setProto3IntField(this, 9, value);
        }
        /**
         * optional int32 daily = 10;
         * @return {number}
         */
        getDaily() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
        }
        ;
        /** @param {number} value */
        setDaily(value) {
            jspb.Message.setProto3IntField(this, 10, value);
        }
        /**
         * optional int32 registered = 11;
         * @return {number}
         */
        getRegistered() {
            return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
        }
        ;
        /** @param {number} value */
        setRegistered(value) {
            jspb.Message.setProto3IntField(this, 11, value);
        }
        /**
         * optional string stat_color = 12;
         * @return {string}
         */
        getStatColor() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
        }
        ;
        /** @param {string} value */
        setStatColor(value) {
            jspb.Message.setProto3StringField(this, 12, value);
        }
    }
    StatFilter.displayName = "MingaPeopleInfo.StatFilter";
    /**
     * Oneof group definitions for this message. Each group defines the field
     * numbers belonging to that group. When of these fields' value is set, all
     * other fields in the group are cleared. During deserialization, if multiple
     * fields are encountered for a group, only the last value seen will be kept.
     * @private {!Array<!Array<number>>}
     * @const
     */
    StatFilter.oneofGroups_ = [[2, 3]];
    MingaPeopleInfo.StatFilter = StatFilter;
})(MingaPeopleInfo = exports.MingaPeopleInfo || (exports.MingaPeopleInfo = {})); // namespace MingaPeopleInfo
(function (MingaPeopleInfo) {
    let StatFilter;
    (function (StatFilter) {
        let StatNumberCase;
        (function (StatNumberCase) {
            StatNumberCase[StatNumberCase["STAT_NUMBER_NOT_SET"] = 0] = "STAT_NUMBER_NOT_SET";
            StatNumberCase[StatNumberCase["STAT_COUNT"] = 2] = "STAT_COUNT";
            StatNumberCase[StatNumberCase["STAT_PERCENT"] = 3] = "STAT_PERCENT";
        })(StatNumberCase = StatFilter.StatNumberCase || (StatFilter.StatNumberCase = {}));
    })(StatFilter = MingaPeopleInfo.StatFilter || (MingaPeopleInfo.StatFilter = {})); // namespace MingaPeopleInfo
})(MingaPeopleInfo = exports.MingaPeopleInfo || (exports.MingaPeopleInfo = {})); // namespace StatFilter
class MingaPeopleSearchResult extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return MingaPeopleSearchResult.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!MingaPeopleSearchResult} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
            item: (f = msg.getItem()) && MingaPeopleSearchResult.Item.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!MingaPeopleSearchResult}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new MingaPeopleSearchResult;
        return MingaPeopleSearchResult.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!MingaPeopleSearchResult} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!MingaPeopleSearchResult}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_stream_pb.StreamItemMetadata;
                    reader.readMessage(value1, common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
                    msg.setItemMetadata(value1);
                    break;
                case 2:
                    var value2 = new MingaPeopleSearchResult.Item;
                    reader.readMessage(value2, MingaPeopleSearchResult.Item.deserializeBinaryFromReader);
                    msg.setItem(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!MingaPeopleSearchResult} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getItemMetadata();
        if (f != null) {
            writer.writeMessage(1, f, common_stream_pb.StreamItemMetadata.serializeBinaryToWriter);
        }
        f = message.getItem();
        if (f != null) {
            writer.writeMessage(2, f, MingaPeopleSearchResult.Item.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        MingaPeopleSearchResult.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StreamItemMetadata item_metadata = 1;
     * @return {?StreamItemMetadata}
     */
    getItemMetadata() {
        return /** @type{?StreamItemMetadata} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
    }
    /** @param {?StreamItemMetadata|undefined} value */
    setItemMetadata(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItemMetadata() {
        this.setItemMetadata(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItemMetadata() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional Item item = 2;
     * @return {?MingaPeopleSearchResult.Item}
     */
    getItem() {
        return /** @type{?MingaPeopleSearchResult.Item} */ (jspb.Message.getWrapperField(this, MingaPeopleSearchResult.Item, 2));
    }
    /** @param {?MingaPeopleSearchResult.Item|undefined} value */
    setItem(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItem() {
        this.setItem(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItem() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.MingaPeopleSearchResult = MingaPeopleSearchResult;
MingaPeopleSearchResult.displayName = "MingaPeopleSearchResult";
(function (MingaPeopleSearchResult) {
    class Item extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Item.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Item} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
                profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
                displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
                badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
                badgeIconColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
                badgeRoleName: jspb.Message.getFieldWithDefault(msg, 6, ""),
                archived: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
                roleInitial: jspb.Message.getFieldWithDefault(msg, 7, ""),
                lastLogin: (f = msg.getLastLogin()) && common_date_pb.Date.toObject(includeInstance, f),
                email: jspb.Message.getFieldWithDefault(msg, 9, ""),
                firstName: jspb.Message.getFieldWithDefault(msg, 11, ""),
                lastName: jspb.Message.getFieldWithDefault(msg, 12, ""),
                mingaHash: jspb.Message.getFieldWithDefault(msg, 13, ""),
                roleType: jspb.Message.getFieldWithDefault(msg, 14, ""),
                grade: jspb.Message.getFieldWithDefault(msg, 15, ""),
                studentId: jspb.Message.getFieldWithDefault(msg, 16, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Item}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Item;
            return Item.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!MingaPeopleSearchResult.Item} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!MingaPeopleSearchResult.Item}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {string} */ (reader.readString());
                        msg.setPersonHash(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setProfileImageUrl(value2);
                        break;
                    case 3:
                        var value3 = /** @type {string} */ (reader.readString());
                        msg.setDisplayName(value3);
                        break;
                    case 4:
                        var value4 = /** @type {string} */ (reader.readString());
                        msg.setBadgeIconUrl(value4);
                        break;
                    case 5:
                        var value5 = /** @type {string} */ (reader.readString());
                        msg.setBadgeIconColor(value5);
                        break;
                    case 6:
                        var value6 = /** @type {string} */ (reader.readString());
                        msg.setBadgeRoleName(value6);
                        break;
                    case 10:
                        var value10 = /** @type {boolean} */ (reader.readBool());
                        msg.setArchived(value10);
                        break;
                    case 7:
                        var value7 = /** @type {string} */ (reader.readString());
                        msg.setRoleInitial(value7);
                        break;
                    case 8:
                        var value8 = new common_date_pb.Date;
                        reader.readMessage(value8, common_date_pb.Date.deserializeBinaryFromReader);
                        msg.setLastLogin(value8);
                        break;
                    case 9:
                        var value9 = /** @type {string} */ (reader.readString());
                        msg.setEmail(value9);
                        break;
                    case 11:
                        var value11 = /** @type {string} */ (reader.readString());
                        msg.setFirstName(value11);
                        break;
                    case 12:
                        var value12 = /** @type {string} */ (reader.readString());
                        msg.setLastName(value12);
                        break;
                    case 13:
                        var value13 = /** @type {string} */ (reader.readString());
                        msg.setMingaHash(value13);
                        break;
                    case 14:
                        var value14 = /** @type {string} */ (reader.readString());
                        msg.setRoleType(value14);
                        break;
                    case 15:
                        var value15 = /** @type {string} */ (reader.readString());
                        msg.setGrade(value15);
                        break;
                    case 16:
                        var value16 = /** @type {string} */ (reader.readString());
                        msg.setStudentId(value16);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!MingaPeopleSearchResult.Item} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getPersonHash();
            if (f.length > 0) {
                writer.writeString(1, f);
            }
            f = message.getProfileImageUrl();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
            f = message.getDisplayName();
            if (f.length > 0) {
                writer.writeString(3, f);
            }
            f = message.getBadgeIconUrl();
            if (f.length > 0) {
                writer.writeString(4, f);
            }
            f = message.getBadgeIconColor();
            if (f.length > 0) {
                writer.writeString(5, f);
            }
            f = message.getBadgeRoleName();
            if (f.length > 0) {
                writer.writeString(6, f);
            }
            f = message.getArchived();
            if (f) {
                writer.writeBool(10, f);
            }
            f = message.getRoleInitial();
            if (f.length > 0) {
                writer.writeString(7, f);
            }
            f = message.getLastLogin();
            if (f != null) {
                writer.writeMessage(8, f, common_date_pb.Date.serializeBinaryToWriter);
            }
            f = message.getEmail();
            if (f.length > 0) {
                writer.writeString(9, f);
            }
            f = message.getFirstName();
            if (f.length > 0) {
                writer.writeString(11, f);
            }
            f = message.getLastName();
            if (f.length > 0) {
                writer.writeString(12, f);
            }
            f = message.getMingaHash();
            if (f.length > 0) {
                writer.writeString(13, f);
            }
            f = message.getRoleType();
            if (f.length > 0) {
                writer.writeString(14, f);
            }
            f = message.getGrade();
            if (f.length > 0) {
                writer.writeString(15, f);
            }
            f = message.getStudentId();
            if (f.length > 0) {
                writer.writeString(16, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            MingaPeopleSearchResult.Item.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional string person_hash = 1;
         * @return {string}
         */
        getPersonHash() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
        }
        ;
        /** @param {string} value */
        setPersonHash(value) {
            jspb.Message.setProto3StringField(this, 1, value);
        }
        /**
         * optional string profile_image_url = 2;
         * @return {string}
         */
        getProfileImageUrl() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setProfileImageUrl(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
        /**
         * optional string display_name = 3;
         * @return {string}
         */
        getDisplayName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
        }
        ;
        /** @param {string} value */
        setDisplayName(value) {
            jspb.Message.setProto3StringField(this, 3, value);
        }
        /**
         * optional string badge_icon_url = 4;
         * @return {string}
         */
        getBadgeIconUrl() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
        }
        ;
        /** @param {string} value */
        setBadgeIconUrl(value) {
            jspb.Message.setProto3StringField(this, 4, value);
        }
        /**
         * optional string badge_icon_color = 5;
         * @return {string}
         */
        getBadgeIconColor() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
        }
        ;
        /** @param {string} value */
        setBadgeIconColor(value) {
            jspb.Message.setProto3StringField(this, 5, value);
        }
        /**
         * optional string badge_role_name = 6;
         * @return {string}
         */
        getBadgeRoleName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
        }
        ;
        /** @param {string} value */
        setBadgeRoleName(value) {
            jspb.Message.setProto3StringField(this, 6, value);
        }
        /**
         * optional bool archived = 10;
         * @return {boolean}
         */
        getArchived() {
            return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
        }
        ;
        /** @param {boolean} value */
        setArchived(value) {
            jspb.Message.setProto3BooleanField(this, 10, value);
        }
        /**
         * optional string role_initial = 7;
         * @return {string}
         */
        getRoleInitial() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
        }
        ;
        /** @param {string} value */
        setRoleInitial(value) {
            jspb.Message.setProto3StringField(this, 7, value);
        }
        /**
         * optional minga.common.Date last_login = 8;
         * @return {?Date}
         */
        getLastLogin() {
            return /** @type{?Date} */ (jspb.Message.getWrapperField(this, common_date_pb.Date, 8));
        }
        /** @param {?Date|undefined} value */
        setLastLogin(value) {
            jspb.Message.setWrapperField(this, 8, value);
        }
        /**
         * Clears the message field making it undefined.
         */
        clearLastLogin() {
            this.setLastLogin(undefined);
        }
        /**
         * Returns whether this field is set.
         * @return {boolean}
         */
        hasLastLogin() {
            return jspb.Message.getField(this, 8) != null;
        }
        /**
         * optional string email = 9;
         * @return {string}
         */
        getEmail() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
        }
        ;
        /** @param {string} value */
        setEmail(value) {
            jspb.Message.setProto3StringField(this, 9, value);
        }
        /**
         * optional string first_name = 11;
         * @return {string}
         */
        getFirstName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
        }
        ;
        /** @param {string} value */
        setFirstName(value) {
            jspb.Message.setProto3StringField(this, 11, value);
        }
        /**
         * optional string last_name = 12;
         * @return {string}
         */
        getLastName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
        }
        ;
        /** @param {string} value */
        setLastName(value) {
            jspb.Message.setProto3StringField(this, 12, value);
        }
        /**
         * optional string minga_hash = 13;
         * @return {string}
         */
        getMingaHash() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
        }
        ;
        /** @param {string} value */
        setMingaHash(value) {
            jspb.Message.setProto3StringField(this, 13, value);
        }
        /**
         * optional string role_type = 14;
         * @return {string}
         */
        getRoleType() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
        }
        ;
        /** @param {string} value */
        setRoleType(value) {
            jspb.Message.setProto3StringField(this, 14, value);
        }
        /**
         * optional string grade = 15;
         * @return {string}
         */
        getGrade() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
        }
        ;
        /** @param {string} value */
        setGrade(value) {
            jspb.Message.setProto3StringField(this, 15, value);
        }
        /**
         * optional string student_id = 16;
         * @return {string}
         */
        getStudentId() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
        }
        ;
        /** @param {string} value */
        setStudentId(value) {
            jspb.Message.setProto3StringField(this, 16, value);
        }
    }
    Item.displayName = "MingaPeopleSearchResult.Item";
    MingaPeopleSearchResult.Item = Item;
})(MingaPeopleSearchResult = exports.MingaPeopleSearchResult || (exports.MingaPeopleSearchResult = {})); // namespace MingaPeopleSearchResult
class ExportPeopleRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, ExportPeopleRequest.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ExportPeopleRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ExportPeopleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            rolefilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            registered: jspb.Message.getFieldWithDefault(msg, 2, 0),
            roleTypeFilterList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
            gradeFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ExportPeopleRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ExportPeopleRequest;
        return ExportPeopleRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ExportPeopleRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ExportPeopleRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
                    msg.setRolefilterList(value1);
                    break;
                case 2:
                    var value2 = /** @type {!ExportPeopleRequest.FilterRegistered} */ (reader.readEnum());
                    msg.setRegistered(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.addRoleTypeFilter(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.addGradeFilter(value4);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ExportPeopleRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getRolefilterList();
        if (f.length > 0) {
            writer.writePackedInt32(1, f);
        }
        f = message.getRegistered();
        if (f !== 0.0) {
            writer.writeEnum(2, f);
        }
        f = message.getRoleTypeFilterList();
        if (f.length > 0) {
            writer.writeRepeatedString(3, f);
        }
        f = message.getGradeFilterList();
        if (f.length > 0) {
            writer.writeRepeatedString(4, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ExportPeopleRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated int32 roleFilter = 1;
     * @return {!Array<number>}
     */
    getRolefilterList() {
        return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
    }
    ;
    /** @param {!Array<number>} value */
    setRolefilterList(value) {
        jspb.Message.setField(this, 1, value || []);
    }
    /**
     * @param {number} value
     * @param {number=} opt_index
     */
    addRolefilter(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 1, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearRolefilterList() {
        this.setRolefilterList([]);
    }
    /**
     * optional FilterRegistered registered = 2;
     * @return {!ExportPeopleRequest.FilterRegistered}
     */
    getRegistered() {
        return /** @type {!ExportPeopleRequest.FilterRegistered} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {!ExportPeopleRequest.FilterRegistered} value */
    setRegistered(value) {
        jspb.Message.setProto3EnumField(this, 2, value);
    }
    /**
     * repeated string role_type_filter = 3;
     * @return {!Array<string>}
     */
    getRoleTypeFilterList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
    }
    ;
    /** @param {!Array<string>} value */
    setRoleTypeFilterList(value) {
        jspb.Message.setField(this, 3, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addRoleTypeFilter(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 3, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearRoleTypeFilterList() {
        this.setRoleTypeFilterList([]);
    }
    /**
     * repeated string grade_filter = 4;
     * @return {!Array<string>}
     */
    getGradeFilterList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
    }
    ;
    /** @param {!Array<string>} value */
    setGradeFilterList(value) {
        jspb.Message.setField(this, 4, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGradeFilter(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 4, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGradeFilterList() {
        this.setGradeFilterList([]);
    }
}
exports.ExportPeopleRequest = ExportPeopleRequest;
ExportPeopleRequest.displayName = "ExportPeopleRequest";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
ExportPeopleRequest.repeatedFields_ = [1, 3, 4];
(function (ExportPeopleRequest) {
    let FilterRegistered;
    (function (FilterRegistered) {
        FilterRegistered[FilterRegistered["NOT_SELECTED"] = 0] = "NOT_SELECTED";
        FilterRegistered[FilterRegistered["REGISTERED"] = 1] = "REGISTERED";
        FilterRegistered[FilterRegistered["NOT_REGISTERED"] = 2] = "NOT_REGISTERED";
    })(FilterRegistered = ExportPeopleRequest.FilterRegistered || (ExportPeopleRequest.FilterRegistered = {}));
})(ExportPeopleRequest = exports.ExportPeopleRequest || (exports.ExportPeopleRequest = {})); // namespace ExportPeopleRequest
class ExportPeopleChunk extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, ExportPeopleChunk.oneofGroups_);
    }
    /**
     * @return {ExportPeopleChunk.InfoCase}
     */
    getInfoCase() {
        return /** @type {ExportPeopleChunk.InfoCase} */ (jspb.Message.computeOneofCase(this, ExportPeopleChunk.oneofGroups_[0]));
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ExportPeopleChunk.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ExportPeopleChunk} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            chunk: msg.getChunk_asB64(),
            filename: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ExportPeopleChunk}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ExportPeopleChunk;
        return ExportPeopleChunk.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ExportPeopleChunk} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ExportPeopleChunk}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
                    msg.setChunk(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setFilename(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ExportPeopleChunk} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
        if (f != null) {
            writer.writeBytes(1, f);
        }
        f = /** @type {string} */ (jspb.Message.getField(message, 2));
        if (f != null) {
            writer.writeString(2, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ExportPeopleChunk.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bytes chunk = 1;
     * @return {!(string|Uint8Array)}
     */
    getChunk() {
        return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /**
     * optional bytes chunk = 1;
     * This is a type-conversion wrapper around `getChunk()`
     * @return {string}
     */
    getChunk_asB64() {
        return /** @type {string} */ (jspb.Message.bytesAsB64(this.getChunk()));
    }
    /**
     * optional bytes chunk = 1;
     * Note that Uint8Array is not supported on all browsers.
   * @see http://caniuse.com/Uint8Array
   * This is a type-conversion wrapper around `getChunk()`
     * @return {!Uint8Array}
     */
    getChunk_asU8() {
        return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getChunk()));
    }
    /** @param {!(string|Uint8Array)} value */
    setChunk(value) {
        jspb.Message.setOneofField(this, 1, ExportPeopleChunk.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearChunk() {
        jspb.Message.setOneofField(this, 1, ExportPeopleChunk.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasChunk() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional string filename = 2;
     * @return {string}
     */
    getFilename() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setFilename(value) {
        jspb.Message.setOneofField(this, 2, ExportPeopleChunk.oneofGroups_[0], value);
    }
    /**
     * Clears the field making it undefined.
     */
    clearFilename() {
        jspb.Message.setOneofField(this, 2, ExportPeopleChunk.oneofGroups_[0], undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasFilename() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.ExportPeopleChunk = ExportPeopleChunk;
ExportPeopleChunk.displayName = "ExportPeopleChunk";
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
ExportPeopleChunk.oneofGroups_ = [[1, 2]];
(function (ExportPeopleChunk) {
    let InfoCase;
    (function (InfoCase) {
        InfoCase[InfoCase["INFO_NOT_SET"] = 0] = "INFO_NOT_SET";
        InfoCase[InfoCase["CHUNK"] = 1] = "CHUNK";
        InfoCase[InfoCase["FILENAME"] = 2] = "FILENAME";
    })(InfoCase = ExportPeopleChunk.InfoCase || (ExportPeopleChunk.InfoCase = {}));
})(ExportPeopleChunk = exports.ExportPeopleChunk || (exports.ExportPeopleChunk = {})); // namespace ExportPeopleChunk