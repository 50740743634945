import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import * as banner_pb from 'minga/proto/gateway/banner_pb';
import { BannerType } from 'minga/libraries/domain';
import { BannerLibrary } from 'minga/proto/gateway/banner_ng_grpc_pb';
import { Banner } from 'minga/proto/gateway/banner_pb';
import { ImageInfo } from 'minga/proto/image/image_pb';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { MltImageEditMessage } from '../../constants';
import { MltImageEditData, MltImageEditResponse } from '../../types';

@Component({
  selector: 'mg-mlt-image-edit',
  templateUrl: './mlt-image-edit.component.html',
  styleUrls: ['./mlt-image-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MltImageEditComponent implements OnDestroy {
  /** Constants */
  public readonly MSG = MltImageEditMessage;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.PEOPLE_BLUE,
  };

  /** Misc Subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Misc */
  public selectedAssetPath = '';
  public selectedBannerHash = '';
  public selectedBannerImage?: ImageInfo;
  private _uploadStatus: 'PENDING' | 'VALID' = 'VALID';

  public readonly _imagePreviewSizes = [
    'blurloading1',
    ['studio', 'bannerlibpreview', 'cardbanner'],
  ];

  /** Image preset options */
  private readonly _presetImageListSubject = new BehaviorSubject<Banner[]>([]);
  public readonly presetImageList$ =
    this._presetImageListSubject.asObservable();

  /** Is Loading */
  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  public get canSubmit() {
    return this._uploadStatus !== 'PENDING';
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public data: MltImageEditData,
    private _modalOverlay: ModalOverlayRef<
      MltImageEditResponse,
      MltImageEditData
    >,
    private _bannerLibrary: BannerLibrary,
    private _systemSnack: SystemAlertSnackBarService,
  ) {
    this._fetchPresetImages();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._isLoadingSubject.complete();
    this._presetImageListSubject.complete();
  }

  public async submit(): Promise<void> {
    if (!this.canSubmit) return;
    const options: MltImageEditResponse = {
      assetPath: this.selectedAssetPath,
      bannerHash: this.selectedBannerHash,
      bannerImage: this.selectedBannerImage,
    };
    this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT, options);
  }

  public updateSelectedBanner(banner: Banner) {
    this.selectedBannerHash = banner.getBannerHash();
    this.selectedAssetPath = '';
    this.selectedBannerImage = banner.getImage();
  }

  public saveUploadedAsset(assetFile: File) {
    this.selectedBannerHash = '';
  }

  public onStatusChange(evt) {
    this._uploadStatus = evt;
  }

  private async _fetchPresetImages(): Promise<void> {
    if (this.data?.imagePreset === 'sticker') {
      const result = this._getStickerImageList();
      this._presetImageListSubject.next(await result);
    }
  }

  private async _getStickerImageList(): Promise<banner_pb.Banner[]> {
    try {
      const request = new banner_pb.BannerLibraryGetAllByType();
      request.setType(BannerType.STICKER);
      const response = await this._bannerLibrary.getAllByType(request);
      return response.getBannersList();
    } catch (error) {
      this._systemSnack.error(
        'Something went wrong trying to fetch sticker images',
      );
    }
  }
}
