import { Injectable } from '@angular/core';

import { $enum } from 'ts-enum-util';

import { StreamManager } from 'minga/app/src/app/minimal/services/StreamManager';
import { MingaRoleType } from 'minga/libraries/domain';
import { getRole } from 'minga/libraries/shared';
import { Date as DateMessage } from 'minga/proto/common/date_pb';
import {
  BoolDelta,
  DateDelta as DateMessageDelta,
  Int32Delta,
  RepeatedStringDelta,
  StringDelta,
} from 'minga/proto/common/delta_pb';
import {
  StatusCode,
  StatusReason,
  UniqueHash,
} from 'minga/proto/common/legacy_pb';
import {
  PeopleAdminManager,
  PeopleManager,
} from 'minga/proto/gateway/people_ng_grpc_pb';
import {
  Account,
  AccountDelta,
  ArchivePeopleRequest,
  CreatePeopleRequest,
  CreatePeopleResponse,
  DeletePeopleRequest,
  GetPersonsTeamsRequest,
  Identity,
  IdentityDelta,
  OpenMingaRequest,
  Person,
  PersonDelta,
  ReadPeopleRequest,
  ReadPeopleResponse,
  ResendPinEmailRequest,
  SelfArchivePersonRequest,
  UpdatePeopleRequest,
  UpdatePeopleResponse,
} from 'minga/proto/gateway/people_pb';
import { AuthService } from 'src/app/minimal/services/Auth';

export interface BasePersonFormFields {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  groups: Array<string>;
  fieldname: string | Array<string>;
  active: boolean;
  mingaHash?: string;
  pin: number;
  accountRoleType: string;
  displayName: string;
  birthdate: Date | null;
  studentId: string;
  grade: string;
  phoneNumber: string;
  customDisplayName: boolean;
  disabledDM: boolean;
  asbMember: boolean;
  idField1: string;
  idField2: string;
  parentEmail: string[];
  parentPhone: string[];
  enableParentNotification: boolean;
  sisInclude: boolean;
}

export interface PersonFormFields extends BasePersonFormFields {
  parentGroups: string[];
  idImageFilename: string;
}

export interface PersonUpdateFormFields extends Partial<BasePersonFormFields> {
  /**
   * Newly added parent groups
   */
  newParentGroups?: string[];

  /**
   * Parent groups being removed
   */
  removeParentGroups?: string[];
}

export namespace PersonFormFields {
  export function entries(fields: PersonFormFields) {
    return <[keyof PersonFormFields, any][]>Object.entries(fields);
  }

  export function defaultValue(): PersonFormFields {
    const defaultFormFields: PersonFormFields = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      active: true,
      groups: [],
      fieldname: '',
      pin: 0,
      accountRoleType: '',
      displayName: '',
      birthdate: null,
      studentId: '',
      grade: '',
      phoneNumber: '',
      customDisplayName: false,
      parentGroups: [],
      disabledDM: false,
      asbMember: false,
      idField1: '',
      idField2: '',
      parentEmail: [],
      parentPhone: [],
      idImageFilename: '',
      enableParentNotification: true,
      sisInclude: true,
    };

    return defaultFormFields;
  }
}

@Injectable({ providedIn: 'root' })
export class PeopleManagerService {
  constructor(
    private _peopleManager: PeopleManager,
    private _peopleAdmin: PeopleAdminManager,
    private _streamManager: StreamManager,
    private _authService: AuthService,
  ) {}

  async resendPinEmail(personHash: string, sendPinEmail: boolean) {
    const request = new ResendPinEmailRequest();
    request.setPersonHash(personHash);
    request.setSendPinEmails(sendPinEmail);
    await this._peopleAdmin.resendPinEmail(request);
  }

  async createPeople(
    userProperties: PersonFormFields,
    sendPinEmail: boolean = false,
  ) {
    const request = new CreatePeopleRequest();
    const person = new Person();
    const personAccount = new Account();
    const personIdentity = new Identity();

    request.setSendPinEmails(sendPinEmail);

    person.setActive(userProperties.active);
    person.setParentGroupList(userProperties.parentGroups);

    if (userProperties.mingaHash) {
      const mingaUniqueHash = new UniqueHash();
      mingaUniqueHash.setValue(userProperties.mingaHash);
      personAccount.setMingaHash(mingaUniqueHash);
    }

    personAccount.setDisplayName(userProperties.displayName);
    personAccount.setDisableParentNotification(
      !userProperties.enableParentNotification,
    );

    personAccount.setSisInclude(userProperties.sisInclude);

    personIdentity.setEmailAddress(userProperties.email);
    personIdentity.setFirstName(userProperties.firstname);
    personIdentity.setLastName(userProperties.lastname);
    personIdentity.setPin(userProperties.pin);
    personIdentity.setStudentId(userProperties.studentId);
    personAccount.setIdField1(userProperties.idField1);
    personAccount.setIdField2(userProperties.idField2);
    personAccount.setGrade(userProperties.grade);
    personIdentity.setPhoneNumber(userProperties.phoneNumber);

    // @TODO: Hash this value over the network
    personIdentity.setPassword(userProperties.password);

    person.setAccountRoleType(userProperties.accountRoleType);

    person.setAccount(personAccount);
    person.setIdentity(personIdentity);
    if (userProperties.birthdate) {
      const dateMsg = new DateMessage();
      dateMsg.setYear(userProperties.birthdate.getUTCFullYear());
      dateMsg.setMonth(userProperties.birthdate.getUTCMonth());
      dateMsg.setDay(userProperties.birthdate.getUTCDate());

      person.setBirthdate(dateMsg);
    }

    if (userProperties.parentEmail) {
      personAccount.setParentEmailList(userProperties.parentEmail);
    }
    if (userProperties.parentPhone) {
      personAccount.setParentPhoneList(userProperties.parentPhone);
    }

    request.setPeopleList([person]);
    return await this._peopleManager
      .createPeople(request)
      .then(res => {
        if (res.getStatus() === StatusCode.OK) {
          this._streamManager.restartStreamIfAvailable('AdminPeopleFeed');
        }
        return res;
      })
      .catch(err => {
        console.error('Create Person Error:', err);
        const status = StatusCode.ERROR;
        const reason = new StatusReason();
        const map = reason.getMessage();
        reason.setMessage(err.message);

        const result = new CreatePeopleResponse();
        result.setStatus(status);
        result.addReason(reason);

        return result;
      });
  }

  async readPeople(
    userHashes: Array<string> = [],
    hiResAvatar: boolean = false,
    makeMinimal: boolean = false,
  ) {
    const req: ReadPeopleRequest = new ReadPeopleRequest();
    let res: ReadPeopleResponse = new ReadPeopleResponse();

    req.setHashList(userHashes);
    req.setHiResAvatar(hiResAvatar);

    try {
      res = await this._peopleManager.readPeople(req);
      let people: any[] = res.getPeopleList();
      if (makeMinimal) {
        people = people.map(p => {
          const identity = p.getIdentity();
          const account = p.getAccount();
          const roleType = $enum(MingaRoleType).asValueOrThrow(
            p.getAccountRoleType(),
          );
          const role = getRole(roleType);

          return {
            personHash: identity?.getHash(),
            profileImageUrl: account?.getProfileImageUrl(),
            displayName: account?.getDisplayName(),
            badgeIconColor: role.iconColor,
            badgeRoleName: role.name,
            badgeIconUrl: role.iconUrl,
            archived: p.getArchived(),
            firstName: identity.getFirstName(),
            lastName: identity.getLastName(),
            studentId: identity.getStudentId(),
          };
        });
      }

      return people;
    } catch (err) {
      console.warn('getPeople error', err);
      return [];
    }
  }

  async updatePerson(
    updateProperties: PersonUpdateFormFields,
    personHash: UniqueHash,
  ) {
    const req: UpdatePeopleRequest = new UpdatePeopleRequest();
    let res: UpdatePeopleResponse; // = new UpdatePeopleResponse();

    const personDelta = new PersonDelta();
    const identityDelta = new IdentityDelta();
    const accountDelta = new AccountDelta();
    let dirtyIdentity = false;
    let dirtyAccount = false;

    personDelta.setHash(personHash.getValue());

    if (typeof updateProperties.email === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.email);
      identityDelta.setEmailAddress(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.password === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.password);
      identityDelta.setPassword(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.firstname === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.firstname);
      identityDelta.setFirstName(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.lastname === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.lastname);
      identityDelta.setLastName(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.active === 'boolean') {
      const delta = new BoolDelta();
      delta.setNewBool(updateProperties.active);
      personDelta.setActive(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.pin === 'number') {
      const delta = new Int32Delta();
      delta.setNewInt32(updateProperties.pin);
      identityDelta.setPin(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.studentId === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.studentId);
      identityDelta.setStudentId(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.grade === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.grade);
      accountDelta.setGrade(delta);
      dirtyAccount = true;
    }
    if (typeof updateProperties.phoneNumber === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.phoneNumber);
      identityDelta.setPhoneNumber(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.accountRoleType === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.accountRoleType);
      personDelta.setAccountRoleType(delta);
      dirtyIdentity = true;
    }
    if (typeof updateProperties.displayName === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.displayName);
      accountDelta.setDisplayName(delta);
      dirtyAccount = true;
    }
    if (typeof updateProperties.disabledDM === 'boolean') {
      const delta = new BoolDelta();
      delta.setNewBool(updateProperties.disabledDM);
      accountDelta.setDisabledDm(delta);
      dirtyAccount = true;
    }
    if (typeof updateProperties.asbMember === 'boolean') {
      const delta = new BoolDelta();
      delta.setNewBool(updateProperties.asbMember);
      personDelta.setAsbMember(delta);
    }
    if (typeof updateProperties.idField1 === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.idField1);
      accountDelta.setIdField1(delta);
      dirtyAccount = true;
    }
    if (typeof updateProperties.idField2 === 'string') {
      const delta = new StringDelta();
      delta.setNewString(updateProperties.idField2);
      accountDelta.setIdField2(delta);
      dirtyAccount = true;
    }
    if (typeof updateProperties.parentEmail === 'object') {
      if (updateProperties.parentEmail.length === 0)
        updateProperties.parentEmail = [''];
      for (const email of updateProperties.parentEmail) {
        const delta = new StringDelta();
        delta.setNewString(email);
        accountDelta.addParentEmail(delta);
      }
      dirtyAccount = true;
    }
    if (typeof updateProperties.parentPhone === 'object') {
      if (updateProperties.parentPhone.length === 0)
        updateProperties.parentPhone = [''];
      for (const phone of updateProperties.parentPhone) {
        const delta = new StringDelta();
        delta.setNewString(phone);
        accountDelta.addParentPhone(delta);
      }
      dirtyAccount = true;
    }

    if (typeof updateProperties.enableParentNotification === 'boolean') {
      const delta = new BoolDelta();
      delta.setNewBool(!updateProperties.enableParentNotification);
      accountDelta.setDisableParentNotification(delta);
      dirtyAccount = true;
    }

    if (typeof updateProperties.sisInclude === 'boolean') {
      const delta = new BoolDelta();
      delta.setNewBool(updateProperties.sisInclude);
      accountDelta.setSisInclude(delta);
      dirtyAccount = true;
    }

    if ('birthdate' in updateProperties) {
      const delta = new DateMessageDelta();
      if (typeof updateProperties.birthdate === 'number') {
        const date = new Date(updateProperties.birthdate);
        const dateMsg = new DateMessage();
        dateMsg.setYear(date.getUTCFullYear());
        dateMsg.setMonth(date.getUTCMonth());
        dateMsg.setDay(date.getUTCDate());

        delta.setNewDate(dateMsg);
      } else if (updateProperties.birthdate) {
        const dateMsg = new DateMessage();
        dateMsg.setYear(updateProperties.birthdate.getUTCFullYear());
        dateMsg.setMonth(updateProperties.birthdate.getUTCMonth() + 1);
        dateMsg.setDay(updateProperties.birthdate.getUTCDate());

        delta.setNewDate(dateMsg);
      } else {
        delta.clearNewDate();
      }

      personDelta.setBirthdate(delta);
    }

    const parentGroupDelta = new RepeatedStringDelta();
    let hasParentGroupChanges = false;
    const { newParentGroups, removeParentGroups } = updateProperties;
    if (newParentGroups && newParentGroups.length > 0) {
      for (const newParentGroup of newParentGroups) {
        const msg = new RepeatedStringDelta.InsertInstruction();
        msg.setInsertString(newParentGroup);
        parentGroupDelta.addInsertInstruction(msg);
      }
      hasParentGroupChanges = true;
    }
    if (removeParentGroups && removeParentGroups.length > 0) {
      for (const rmvParentGroup of removeParentGroups) {
        const msg = new RepeatedStringDelta.EraseInstruction();
        msg.setEraseString(rmvParentGroup);
        parentGroupDelta.addEraseInstruction(msg);
      }
      hasParentGroupChanges = true;
    }

    if (hasParentGroupChanges) {
      personDelta.setParentGroup(parentGroupDelta);
    }

    if (dirtyIdentity) {
      personDelta.setIdentity(identityDelta);
    }
    if (dirtyAccount) {
      personDelta.setAccount(accountDelta);
    }

    req.setPeopleList([personDelta]);

    return await this._peopleManager.updatePeople(req).then(response => {
      if (response.getStatus() === StatusCode.OK) {
        this._streamManager.restartStreamIfAvailable('AdminPeopleFeed');
      }
      return response;
    });
  }

  async openMinga(mingaHash: string) {
    const req: OpenMingaRequest = new OpenMingaRequest();
    console.log('[PeopleManagerService] openMinga() ', mingaHash);
    req.setMingaHash(mingaHash);

    return await this._peopleManager.openMinga(req);
  }

  async deletePerson(personHash: string) {
    const request = new DeletePeopleRequest();
    console.log(
      `[PeopleManagerService] deletePerson() personHash: ${personHash}`,
    );
    request.addHash(personHash);
    return await this._peopleManager.deletePeople(request);
  }

  async archivePerson(personHash: string) {
    const request = new ArchivePeopleRequest();
    request.addHash(personHash);
    const people = await this._peopleManager.archivePeople(request);
    return people;
  }

  async selfArchivePerson(personHash: string) {
    const request = new SelfArchivePersonRequest();
    await this._peopleManager.selfArchivePerson(request);
    await this._authService.logout();
  }

  async getMyTeams(personHash: string) {
    const request = new GetPersonsTeamsRequest();
    request.setPersonHash(personHash);
    const response = await this._peopleManager.getTeams(request);
    return response.getTeamsList();
  }
}
