import { ComponentType } from '@angular/cdk/portal';
import { Injectable, OnDestroy } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

import { EditableReportRecord } from 'minga/libraries/domain';
import { ColumnInfo, TemplateColumnKeys } from 'minga/libraries/shared';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { PaginatorComponent } from '@shared/components/paginator';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { ReportsService } from './report-service.service';

export interface ReportLinkInfo {
  filter: string;
  url: string;
}

export interface ColumnUpdate {
  table?: boolean;
  keys?: boolean;
}

type ColumnVisibilityStates = Record<string, boolean>;

/**
 * Abstract class to base stats report tables datasources on.
 */
@Injectable()
export abstract class ReportDatasourceService<T> implements OnDestroy {
  protected _destroyed$ = new ReplaySubject<void>(1);

  /** Constants */
  public TEMPLATE_KEYS = TemplateColumnKeys;
  private readonly _columnVisibilityKey: string;

  /** Observables for tracking if we are loading or not */
  private _infLoading = new BehaviorSubject<boolean>(false);
  infLoading$ = this._infLoading.asObservable();
  protected _tableLoading = new BehaviorSubject<boolean>(false);
  tableLoading$ = this._tableLoading.asObservable();

  /** Observable for tracking if we are changing columns */
  private _columnsChanged = new Subject<ColumnUpdate>();
  columnsChanged$ = this._columnsChanged.asObservable();

  /** The items to show in the report's table */
  protected _items: T[] = [];
  protected _itemsSubject = new BehaviorSubject<T[]>([]);
  /** Observable of items to show in the report's table */
  items$ = this._itemsSubject.asObservable();

  /** current offset for query  */
  private _offset = 0;
  /** current limit for query */
  private _limit = 100;

  /** for the generic table component, set by individual report services */
  private _displayColumnMap: Map<string, ColumnInfo>;

  //** Delete Data */
  protected _archiveFn: (item: T | T[]) => Promise<void>;
  protected _archiveHeading = 'Are you sure you want to archive this record?';
  protected _archiveMsg = '';
  protected _archiveConfirmBtn = 'Archive';
  protected _archiveCloseBtn = 'Cancel';

  public get displayColumns() {
    return [...this._displayColumnMap.values()];
  }

  get hasColumnVisibilityKey() {
    return !!this._columnVisibilityKey;
  }

  get hasColumnVisibilityState() {
    return (
      this.hasColumnVisibilityKey &&
      !!localStorage.getItem(this._columnVisibilityKey)
    );
  }

  /** for the generic table component, columns we want toggle-able */
  public toggleColumns: ColumnInfo[] = [];

  // Info for navigational functions
  summaryInfo?: ReportLinkInfo;
  historyInfo?: ReportLinkInfo;

  /** To track table sort */
  protected _currentSort: Sort;

  protected _filterSubscription: Subscription;

  public pageToken = 0;

  protected _editForm: ComponentType<CrudFormBase<T>>;

  constructor(
    protected _router?: Router,
    protected _reportsService?: ReportsService<any>,
    private _subClassServiceName?: string,
    protected _alertModal?: SystemAlertModalService,
    protected _snackBar?: SystemAlertSnackBarService,
    protected _modalOverlay?: ModalOverlayService<any, T>,
  ) {
    this._columnVisibilityKey = this._subClassServiceName;
    this._initialQuery();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setPaginator(paginator: PaginatorComponent) {
    paginator.matPaginator.page
      .pipe(takeUntil(this._destroyed$))
      .subscribe(async page => {
        if (page.pageSize !== this._limit) {
          this._limit = paginator.pageSize;
          await this._initialQuery();
        } else {
          this._offset = page.pageIndex * page.pageSize;
          await this.fetchReportData();
        }
      });
  }

  async fetchReportData() {
    // this gets triggered before the initial request has finished, so
    // ignore that.
    if (this._tableLoading.value) return;
    this._tableLoading.next(true);
    const { items } = await this.fetch(this._offset, this._limit);
    const new_rows = items;
    this._tableLoading.next(false);
    this._items = this._items.concat(new_rows);
    this._itemsSubject.next(new_rows);
  }

  private async _initialQuery() {
    if (!this._reportsService) {
      console.error("can't do initial query without a reports service");
      return;
    }
    if (this._reportsService.filter$) {
      this._filterSubscription = this._reportsService.filter$
        .pipe(
          takeUntil(this._destroyed$),
          debounceTime(100),
          tap(() => {
            this._tableLoading.next(true);
            // since this is a new filter situation, reset the offset to 0
            this._offset = 0;
          }),
          switchMap(() => {
            return this.fetch(this._offset, this._limit);
          }),
        )
        .subscribe(items => {
          this._items = items.items;
          this._itemsSubject.next(this._items);
          this._tableLoading.next(false);
          this.pageToken = items.pageToken;
        });
    } else {
      this._tableLoading.next(true);
      const { items, pageToken } = await this.fetch(this._offset, this._limit);
      this._items = items;
      this.pageToken = pageToken;
      this._itemsSubject.next(this._items);
      this._tableLoading.next(false);
    }
  }

  // Setters
  setSummaryInfo(info: ReportLinkInfo) {
    this.summaryInfo = info;
  }

  setHistoryInfo(info: ReportLinkInfo) {
    this.historyInfo = info;
  }

  setLimitAndOffset(limit?: number, offset?: number) {
    if (typeof limit == 'number') {
      this._limit = limit;
    }
    if (typeof offset == 'number') {
      this._offset = offset;
    }
  }

  /**
   * Set display columns for generic report table
   *
   * @param toggleCol - columns that can be toggled
   * @param constantCol - columns that are always shown, added to the end. optional
   */
  setDisplayColumns(toggleCol: ColumnInfo[], constantCol?: ColumnInfo[]) {
    this.toggleColumns = toggleCol;
    let col = constantCol ? [...toggleCol, ...constantCol] : toggleCol;
    col = this._initColumnVisibilityStates(col);

    this._displayColumnMap = new Map(col.map(c => [c.key, c]));
    this._columnsChanged.next({ table: true, keys: true });
    this._columnsChanged.next({ table: false, keys: false });
  }

  // Getters
  getDisplayColumns(): ColumnInfo[] {
    if (!this.displayColumns.length) {
      console.error('Display columns have not been set!');
    }
    const templates = Object.values(TemplateColumnKeys).filter(key =>
      isNaN(Number(key)),
    );
    return this.displayColumns.filter(
      col => !templates.includes(col.key as any),
    );
  }

  getTemplateColumns(): ColumnInfo[] {
    if (!this.displayColumns.length) {
      console.error('Display columns have not been set!');
    }
    const templates = Object.values(TemplateColumnKeys).filter(key =>
      isNaN(Number(key)),
    );
    return this.displayColumns.filter(col =>
      templates.includes(col.key as any),
    );
  }

  getDisplayKeys(): string[] {
    const shownColumns = this.displayColumns.filter(col => !col.hidden);
    return shownColumns.map(col => col.key);
  }

  // Table functions
  async viewSummary(item: T) {
    if (this.summaryInfo && this._reportsService && this._router) {
      this._reportsService.setAndApplyFilter(
        this.summaryInfo.filter,
        item,
        true,
      );
      this._router.navigateByUrl(this.summaryInfo.url);
    } else {
      console.error(
        'Cannot go to summary, missing a service or necessary info.',
      );
    }
  }

  async viewHistory(item: T, historyInfo?: ReportLinkInfo) {
    if (!historyInfo) historyInfo = this.historyInfo;
    if (historyInfo && this._reportsService && this._router) {
      this._reportsService.setAndApplyFilter(historyInfo.filter, item, true);
      this._router.navigateByUrl(historyInfo.url);
    } else {
      console.error(
        'Cannot go to history, missing a service or necessary info.',
      );
    }
  }

  async archiveItem(item: T, index: number) {
    if (this._archiveFn && this._alertModal && this._snackBar) {
      const modalRef = await this._alertModal.open({
        heading: this._archiveHeading,
        message: this._archiveMsg,
        confirmActionBtn: this._archiveConfirmBtn,
        modalType: SystemAlertModalType.WARNING,
        closeBtn: this._archiveCloseBtn,
      });
      const response = await modalRef.afterClosed().toPromise();
      if (response.type === SystemAlertCloseEvents.CONFIRM) {
        try {
          await this._archiveFn(item);
          // Only remove on frontend, so we can skip a server call
          this._tableLoading.next(true);
          this._items.splice(index, 1);
          this._itemsSubject.next(this._items);
          this.pageToken -= 1;
          this._tableLoading.next(false);
          this._snackBar.success('Record archived');
        } catch (e) {
          console.error(e);
          this._snackBar.error('Failed to archive record');
        }
      }
    } else {
      console.error('Cannot archive item, missing archive functionality.');
    }
  }

  async completeItem(item: T) {
    if (this._reportsService) {
      this._reportsService.completeItem(item);
    } else {
      console.error('Cannot complete item, missing report service.');
    }
  }

  async editItem(item: T & EditableReportRecord, index: number) {
    if (this._editForm && this._modalOverlay) {
      try {
        const modalRef = this._modalOverlay.open(this._editForm, {
          data: item,
          disposeOnNavigation: false,
        });
        const response = await modalRef.afterClosed.toPromise();
        if (response.type === ModalOverlayServiceCloseEventType.SUBMIT) {
          // Only update on frontend, so we can skip a server call
          this._tableLoading.next(true);
          this._items.splice(index, 1, response.data);
          this._itemsSubject.next(this._items);
          this._tableLoading.next(false);
          this._snackBar.success('Record updated');
        }
      } catch (err) {
        console.error(err);
        this._snackBar.error('Failed to update record');
      }
    } else {
      console.error('Cannot edit item, missing necessary services.');
    }
  }

  async sort(sort: Sort) {
    this._currentSort = sort.direction ? sort : undefined;
    this._filterSubscription.unsubscribe();
    await this._initialQuery();
  }

  toggleColumn(key: string) {
    const col = this._displayColumnMap.get(key);
    col.hidden = !col.hidden;
    this._displayColumnMap.set(key, col);
    this._setColumnVisibilityState(key, col.hidden);

    this._columnsChanged.next({ keys: true });
    this._columnsChanged.next({ keys: false });
  }

  /**
   * Fetch more items. Must be implemented by the subclass.
   *
   * @param offset
   * @param limit
   */
  abstract fetch(
    offset: number,
    limit: number,
  ): Promise<{ items: T[]; pageToken: number }>;

  /**
   * Internal report datasource functions
   */

  private _initColumnVisibilityStates(columns: ColumnInfo[]): ColumnInfo[] {
    if (this.hasColumnVisibilityKey) {
      let colVisibilityStates: ColumnVisibilityStates = {};
      try {
        colVisibilityStates = JSON.parse(
          localStorage.getItem(this._columnVisibilityKey),
        );
      } catch (e) {
        console.error('Column visibility state does not exist: ', e);
        return;
      }

      if (colVisibilityStates) {
        columns.forEach(column => {
          if (colVisibilityStates[column.key] !== undefined) {
            column.hidden = colVisibilityStates[column.key];
          }
        });
      }
    }

    return columns;
  }

  private _setColumnVisibilityState(key: string, hidden: boolean) {
    if (!this.hasColumnVisibilityKey) return;

    let colVisibilityStates: ColumnVisibilityStates = {};
    try {
      colVisibilityStates = this.hasColumnVisibilityState
        ? JSON.parse(localStorage.getItem(this._columnVisibilityKey))
        : {};

      colVisibilityStates[key] = hidden;
      localStorage.setItem(
        this._columnVisibilityKey,
        JSON.stringify(colVisibilityStates),
      );
    } catch (e) {
      console.error('Failed to save column visibility state: ', e);
      return;
    }
  }
}
