import { Injectable } from '@angular/core';

import { ISmsRecipientDetails } from 'minga/libraries/shared';
import { MingaSmsDetailsMapper } from 'minga/libraries/shared-grpc';
import { SmsAdmin as SmsAdminProto } from 'minga/proto/sms/sms_ng_grpc_pb';
import {
  SmsAdminAddMessageBlockRequest,
  SmsAdminGetDetailsRequest,
  SmsAdminSetMaxSendCountRequest,
} from 'minga/proto/sms/sms_pb';

@Injectable({ providedIn: 'root' })
export class SmsAdmin {
  constructor(private smsAdminProto: SmsAdminProto) {}

  async getDetails(mingaHash: string): Promise<ISmsRecipientDetails> {
    const request = new SmsAdminGetDetailsRequest();
    request.setMingaHash(mingaHash);
    const details = await this.smsAdminProto.getDetails(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }

  async setMaxSendCount(
    mingaHash: string,
    newMaxSendCount: number,
  ): Promise<ISmsRecipientDetails> {
    const request = new SmsAdminSetMaxSendCountRequest();
    request.setMingaHash(mingaHash);
    request.setNewMaxSendCount(newMaxSendCount);
    const details = await this.smsAdminProto.setMaxSendCount(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }

  async addMessageBlock(
    mingaHash: string,
    blockSize: number,
  ): Promise<ISmsRecipientDetails> {
    const request = new SmsAdminAddMessageBlockRequest();
    request.setMingaHash(mingaHash);
    request.setBlockSize(blockSize);
    const details = await this.smsAdminProto.addMessageBlock(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }
}
