import * as day from 'dayjs';

import { PmReportsFilter, PmReportsFilters } from 'minga/libraries/domain';
import { ReportTypes } from 'minga/libraries/domain';

import { SelectFieldsOption } from '@shared/components/select-fields';

import { PmReportDataState } from '../types';

export enum PmReportsMessages {
  REPORT_TITLE_POINTS_SUMMARY = 'Points Summary Report',
  REPORT_TITLE_TEAM_POINTS = 'Team Points Report',
  REPORT_TITLE_POINTS_HISTORY = 'Points History Report',
  REPORT_TITLE_REWARDS_REDEEMED = 'Rewards Redeemed Report',
  REPORT_TITLE_REDEMPTION_HISTORY = 'Redemption History Report',

  BUTTON_APPLY_FILTER = 'Apply filters',
  BUTTON_CLEAR_FILTER = 'Clear filters',
  BUTTON_EXPORT = 'Export list',

  FORM_LABEL_PEOPLE = 'Name, ID number or email',
  FORM_LABEL_GRADE = 'Grade',
  FORM_LABEL_TEAMS = 'Teams',
  FORM_LABEL_REWARDS = 'Rewards',

  SELECT_LABEL_USER_LIST = 'User list',

  SHOW_FILTERS = 'Show filters',
  HIDE_FILTERS = 'Hide filters',
}

export enum PmReportsTableMessages {
  EMPTY_STATE_TITLE = 'No results found',
  EMPTY_STATE_SUBTITLE = 'Try changing your search or filtering and try again.',

  COLUMN_LABEL_NAME = 'Name',
  COLUMN_LABEL_STUDENT_ID = 'ID Number',
  COLUMN_LABEL_GRADE = 'Grade',
  COLUMN_LABEL_REDEEMABLE_POINTS = 'Redeemable Points',
  COLUMN_LABEL_POINTS = 'Points',
  COLUMN_LABEL_ISSUED_TO = 'Issued to',
  COLUMN_LABEL_ISSUED_BY = 'Issued by',
  COLUMN_LABEL_REASON = 'Reason',
  COLUMN_LABEL_DATE = 'Date',

  BUTTON_LABEL_EXPAND_ROW = 'View more',
}

export enum PmReportsType {
  POINTS_HISTORY = 'POINTS',
  STUDENT_POINTS = 'POINTS_SUMMARY',
  TEAM_POINTS = 'TEAM_POINTS_SUMMARY',
  REWARDS_REDEEMED = 'REWARDS_REDEEMED',
}

export const PM_REPORTS_NON_ARRAY_TYPES = [
  PmReportsFilter.START_DATE,
  PmReportsFilter.END_DATE,
];

export const PM_REPORTS_PERSON_EMITTER_ACCESSORS = {
  [PmReportsFilter.ISSUED_TO]: { accessor: PmReportsFilter.ISSUED_TO },
};

export const PM_REPORTS_DEFAULT_REQUEST_LIMIT = 30;
export const PM_REPORTS_DEFAULT_REPORT = PmReportsType.POINTS_HISTORY;

export const PM_REPORT_SELECT_OPTIONS: SelectFieldsOption<ReportTypes>[] = [
  {
    label: PmReportsMessages.REPORT_TITLE_POINTS_HISTORY,
    value: ReportTypes.POINTS_HISTORY,
  },
  {
    label: PmReportsMessages.REPORT_TITLE_POINTS_SUMMARY,
    value: ReportTypes.POINTS_SUMMARY,
  },
  {
    label: PmReportsMessages.REPORT_TITLE_TEAM_POINTS,
    value: ReportTypes.POINTS_TEAM_SUMMARY,
  },
  {
    label: PmReportsMessages.REPORT_TITLE_REWARDS_REDEEMED,
    value: ReportTypes.POINTS_REWARDS_REDEEMED,
  },
  {
    label: PmReportsMessages.REPORT_TITLE_REDEMPTION_HISTORY,
    value: ReportTypes.POINTS_REDEEMED,
  },
];

export const PM_REPORTS_INITIAL_DATA_STATE: PmReportDataState = {
  loading: false,
  finished: false,
  offset: 0,
  data: [],
};

export const PM_REPORTS_FILTERS_INITIAL_STATE: PmReportsFilters = {
  issuedTo: [],
  teams: [],
  grades: [],
  rewards: [],
  startDate: day().subtract(7, 'd').startOf('day'),
  endDate: day().endOf('day'),
  userList: [],
};

export const PM_TABLE_COLUMNS_MAP: Record<PmReportsType, string[]> = {
  [PmReportsType.STUDENT_POINTS]: [
    'issuedTo.name',
    'issuedTo.studentId',
    'issuedTo.grade',
    'totalPoints',
    'redeemablePoints',
  ],
  [PmReportsType.TEAM_POINTS]: ['team.name', 'totalPoints'],
  [PmReportsType.POINTS_HISTORY]: [
    'issuedTo.displayName',
    'issuedTo.studentId',
    'issuedBy.displayName',
    'reason',
    'points',
    'date',
  ],
  [PmReportsType.REWARDS_REDEEMED]: [],
};
