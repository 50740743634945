"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const day_1 = require("minga/libraries/day/index");
const date_pb_1 = require("minga/proto/common/date_pb");
const minga_pb_1 = require("minga/proto/gateway/minga_pb");
const common_1 = require("minga/libraries/shared-grpc/common/index");
exports.toProto = (item, utcOffset) => {
    const msg = new minga_pb_1.sisSyncLog();
    const dateObj = item.updatedAt;
    if (dateObj) {
        const adjusted = day_1.day(dateObj.getTime()).utcOffset(utcOffset);
        const dateTime = new date_pb_1.DateTime();
        const seconds = adjusted.hour() * 3600 + adjusted.minute() * 60 + adjusted.second();
        dateTime.setYear(adjusted.year());
        dateTime.setDay(adjusted.date());
        dateTime.setMonth(adjusted.month() + 1);
        dateTime.setSeconds(seconds + 1);
        msg.setUpdatedat(dateTime);
    }
    msg.setNumupdated(item.numUpdated);
    msg.setNumcreated(item.numCreated);
    msg.setNumignored(item.numIgnored);
    msg.setNumarchived(item.numArchived);
    msg.setNumRestored(item.numRestored || 0);
    msg.setFilelink(item.fileLink);
    msg.setNumerrors(item.numErrors);
    msg.setType(item.type || '');
    return msg;
};
exports.fromProto = (msg) => {
    const log = {
        numUpdated: msg.getNumupdated(),
        numCreated: msg.getNumcreated(),
        numIgnored: msg.getNumignored(),
        numArchived: msg.getNumarchived(),
        numRestored: msg.getNumRestored(),
        fileLink: msg.getFilelink(),
        numErrors: msg.getNumerrors(),
        type: msg.getType(),
        updatedAt: common_1.DateProtoMapper.dateTimeMessageToDateObject(msg.getUpdatedat()),
    };
    return log;
};