import * as day from 'dayjs';

import { RealtimeHallPassMeta } from 'minga/libraries/domain';

/**
 * Given an ISO date string, return the number of seconds until
 * the pending hall pass expires and can no longer be used
 */
export const getSecondsTillPendingPassExpiry = (isoEndDate: string): number => {
  if (!isoEndDate) return 0;

  try {
    const expiryDate = day(isoEndDate);
    const currentDate = day();
    return Math.max(0, expiryDate.diff(currentDate, 'second'));
  } catch (e) {
    return 0;
  }
};

export const isCurrentUserRequester = (
  authPersonHash: string,
  hallPass: RealtimeHallPassMeta,
): boolean => {
  const { issuedTo } = hallPass;
  const isStudent = authPersonHash === issuedTo.hash;
  const isRequester = authPersonHash === hallPass.requestedBy?.hash;

  return isStudent || isRequester;
};

export const isStudentCreated = (hallPass: RealtimeHallPassMeta): boolean => {
  const { requestedBy, issuedTo } = hallPass;
  return issuedTo.hash === requestedBy.hash;
};

export const isRequesterStaff = (hallPass: RealtimeHallPassMeta): boolean => {
  const { requestedBy, issuedBy } = hallPass;

  if (!requestedBy) return false;
  return !isStudentCreated(hallPass) && requestedBy.hash !== issuedBy.hash;
};
