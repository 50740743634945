"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const shared_grpc_2 = require("minga/libraries/shared-grpc/index");
const minga_pb_1 = require("minga/proto/gateway/minga_pb");
const wrappers_pb_1 = require("minga/proto/google/protobuf/wrappers_pb");
const feature_toggle_1 = require("minga/libraries/shared-grpc/feature_toggle/index");
exports.toProto = (mingaPresentation, mapHealthStats = false) => {
    const infoMessage = new minga_pb_1.MingaDashboardInfo2();
    if (mingaPresentation.name)
        infoMessage.setName(mingaPresentation.name);
    if (mingaPresentation.hash)
        infoMessage.setHash(mingaPresentation.hash);
    if (mingaPresentation.imageUrl)
        infoMessage.setImageUrl(mingaPresentation.imageUrl);
    if (mingaPresentation.type)
        infoMessage.setType(ts_enum_util_1.$enum(minga_pb_1.MingaType).getValueOrThrow(mingaPresentation.type));
    if (mingaPresentation.status)
        infoMessage.setStatus(ts_enum_util_1.$enum(minga_pb_1.MingaStatus).getValueOrThrow(mingaPresentation.status));
    if (mingaPresentation.paused)
        infoMessage.setPaused(mingaPresentation.paused);
    if (mingaPresentation.registeredCount)
        infoMessage.setRegisteredCount(mingaPresentation.registeredCount);
    if (mingaPresentation.billableCount)
        infoMessage.setBillableCount(mingaPresentation.billableCount);
    if (mingaPresentation.totalCount)
        infoMessage.setTotalCount(mingaPresentation.totalCount);
    // infoMessage.setCreatedAt(dateObjectToDateMessage(model.createdAt));
    if (mingaPresentation.state)
        infoMessage.setState(mingaPresentation.state);
    if (mingaPresentation.city)
        infoMessage.setCity(mingaPresentation.city);
    if (mingaPresentation.address)
        infoMessage.setAddress(mingaPresentation.address);
    if (mingaPresentation.subscription) {
        const sub = mingaPresentation.subscription;
        const subProto = new minga_pb_1.MingaSubscription2();
        if (sub.name)
            subProto.setName(sub.name);
        if (sub.planId)
            subProto.setPlanId(sub.planId);
        if (sub.adminUserSize)
            subProto.setAdminUserSize(sub.adminUserSize);
        if (sub.groupSize)
            subProto.setGroupSize(sub.groupSize);
        if (sub.premiumPrograms)
            subProto.setPremiumPrograms(sub.premiumPrograms);
        if (sub.premiumStudioTemplates)
            subProto.setPremiumStudioTemplates(sub.premiumStudioTemplates);
        if (sub.pricedPerDefaultRole)
            subProto.setPricedPerDefaultRole(sub.pricedPerDefaultRole);
        infoMessage.setSubscription(subProto);
    }
    if (mingaPresentation.size) {
        infoMessage.setSize(mingaPresentation.size);
    }
    if (mingaPresentation.dailyActiveCount)
        infoMessage.setDailyActiveCount(mingaPresentation.dailyActiveCount);
    if (mingaPresentation.weeklyActiveCount)
        infoMessage.setWeeklyActiveCount(mingaPresentation.weeklyActiveCount);
    if (mingaPresentation.monthlyActiveCount)
        infoMessage.setMonthlyActiveCount(mingaPresentation.monthlyActiveCount);
    if (mingaPresentation.stickerCount)
        infoMessage.setStickerCount(mingaPresentation.stickerCount);
    if (mingaPresentation.modules) {
        infoMessage.setFeatureToggle(feature_toggle_1.MingaFeatureToggleMapper.toProto(mingaPresentation.modules));
    }
    if (mingaPresentation.partner) {
        infoMessage.setPartner(mingaPresentation.partner);
    }
    if (mingaPresentation.district) {
        infoMessage.setDistrict(mingaPresentation.district);
    }
    if (mingaPresentation.createdAt) {
        infoMessage.setCreatedAt(shared_grpc_2.dateObjectToDateMessage(mingaPresentation.createdAt));
    }
    if (mingaPresentation.subscriptionStartDate) {
        infoMessage.setSubscriptionStartDate(shared_grpc_2.dateObjectToDateMessage(mingaPresentation.subscriptionStartDate));
    }
    if (mingaPresentation.subscriptionEndDate) {
        infoMessage.setSubscriptionEndDate(shared_grpc_2.dateObjectToDateMessage(mingaPresentation.subscriptionEndDate));
    }
    if (mingaPresentation.registeredStudents) {
        infoMessage.setRegisteredStudents(mingaPresentation.registeredStudents);
    }
    if (mingaPresentation.unregisteredStudents) {
        infoMessage.setUnregisteredStudents(mingaPresentation.unregisteredStudents);
    }
    if (mingaPresentation.sisType) {
        infoMessage.setSisType(mingaPresentation.sisType);
    }
    if (mingaPresentation.rostering) {
        infoMessage.setRostering(mingaPresentation.rostering);
    }
    if (mingaPresentation.hubSpotId) {
        infoMessage.setHubSpotId(mingaPresentation.hubSpotId);
    }
    if (mapHealthStats) {
        if (mingaPresentation.mingaHealthCheckStats) {
            const map = [];
            for (const [key, value] of Object.entries(mingaPresentation.mingaHealthCheckStats)) {
                if (!value && typeof value !== 'number')
                    continue;
                const entry = new minga_pb_1.MingaHealthScoreMapEntry();
                entry.setKey(key);
                if (typeof value === 'number') {
                    entry.setIntValue(value);
                }
                else {
                    const scoreEntry = new minga_pb_1.MingaHealthScore();
                    scoreEntry.setScore(value.score);
                    scoreEntry.setQuality(value.quality);
                    entry.setScoreValue(scoreEntry);
                }
                map.push(entry);
            }
            if (map.length)
                infoMessage.setHealthScoreStatsEntriesList(map);
        }
    }
    if (mingaPresentation.smsCount) {
        infoMessage.setSmsCount(mingaPresentation.smsCount);
    }
    if (typeof mingaPresentation.smsRemaining === 'number') {
        const wrapper = new wrappers_pb_1.Int32Value();
        wrapper.setValue(mingaPresentation.smsRemaining);
        infoMessage.setSmsRemaining(wrapper);
    }
    if (mingaPresentation.sisActive) {
        infoMessage.setSisActive(mingaPresentation.sisActive);
    }
    if (mingaPresentation.sisListCount) {
        infoMessage.setSisListCount(mingaPresentation.sisListCount);
    }
    if (mingaPresentation.sisListActive) {
        infoMessage.setSisListActive(mingaPresentation.sisListActive);
    }
    if (mingaPresentation.districtId) {
        infoMessage.setDistrictId(mingaPresentation.districtId);
    }
    return infoMessage;
};
exports.fromProto = (message) => {
    var _a;
    const presentation = {
        id: 0,
        name: message.getName(),
        hash: message.getHash(),
        imageUrl: message.getImageUrl(),
        type: ts_enum_util_1.$enum(minga_pb_1.MingaType).getKeyOrThrow(message.getType()),
        status: ts_enum_util_1.$enum(minga_pb_1.MingaStatus).getKeyOrThrow(message.getStatus()),
        paused: message.getPaused(),
        registeredCount: message.getRegisteredCount(),
        billableCount: message.getBillableCount(),
        totalCount: message.getTotalCount(),
        state: message.getState(),
        city: message.getCity(),
        address: message.getAddress(),
        dailyActiveCount: message.getDailyActiveCount(),
        weeklyActiveCount: message.getWeeklyActiveCount(),
        monthlyActiveCount: message.getMonthlyActiveCount(),
        stickerCount: message.getStickerCount(),
        modules: feature_toggle_1.MingaFeatureToggleMapper.fromProto(message.getFeatureToggle()),
        partner: message.getPartner(),
        district: message.getDistrict(),
        createdAt: message.getCreatedAt()
            ? shared_grpc_1.dateMessageToDateObject(message.getCreatedAt())
            : undefined,
        subscriptionStartDate: message.getSubscriptionStartDate()
            ? shared_grpc_1.dateMessageToDateObject(message.getSubscriptionStartDate())
            : undefined,
        subscriptionEndDate: message.getSubscriptionEndDate()
            ? shared_grpc_1.dateMessageToDateObject(message.getSubscriptionEndDate())
            : undefined,
        registeredStudents: message.getRegisteredStudents(),
        unregisteredStudents: message.getUnregisteredStudents(),
        sisType: message.getSisType(),
        rostering: message.getRostering(),
        hubSpotId: message.getHubSpotId(),
        smsCount: message.getSmsCount(),
        sisActive: message.getSisActive(),
        sisListCount: message.getSisListCount(),
        sisListActive: message.getSisListActive(),
        districtId: message.getDistrictId(),
        smsRemaining: (_a = message.getSmsRemaining()) === null || _a === void 0 ? void 0 : _a.getValue(),
    };
    return presentation;
};