import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CommentListViewComponent } from 'minga/app/src/app/content-views/components/CommentListView';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { LikeService } from 'minga/app/src/app/minimal/services/Like';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { CommentHandlerService } from 'minga/app/src/app/services/CommentHandler';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import {
  IDeletedContextReasons,
  ReportService,
} from 'minga/app/src/app/services/Report';
import { numeral } from 'minga/app/src/app/util/numeral';
import { scrollIntoView } from 'minga/app/src/app/util/scroll-into-view';
import { day } from 'minga/libraries/day';
import { MingaPermission } from 'minga/libraries/domain';
import { FeedComment } from 'minga/proto/gateway/content_views_pb';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

import { ICommentDisplay } from '../CommentView/CommentView.component';
import { LongCardBaseClass } from './LongCardBaseClass';

@Component({
  selector: 'mg-long-card-base',
  templateUrl: './LongCardBase.component.html',
  styleUrls: ['./LongCardBase.component.scss'],
})
export class LongCardBaseComponent implements OnInit, OnDestroy {
  @Input()
  component?: LongCardBaseClass;

  deleting: boolean = false;

  likeClickAnimation: boolean = false;
  likeClicked: boolean = false;
  likeClickTimeout: any;
  likeSubscription: Subscription;

  currentCommentsPage: number = -1;
  comments: ICommentDisplay[] = [];
  commentCount: number = 0;
  contextDeleteData: IDeletedContextReasons = null;
  useFixedCommentInput: boolean = false;

  imagesContainerWidth: number = 0;

  mqAlias: string = '';
  mqAliasSubscription: Subscription;
  contentChangesSubscription?: Subscription;

  canComment$: Observable<boolean>;
  private _commentDataChanged: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );
  private _commentDataChanged$: Observable<boolean>;

  canViewLikes: boolean = false;

  @ViewChild('commentListViewEl', { static: false })
  commentListEl: CommentListViewComponent;

  get content() {
    return this.component.content;
  }

  get contextHash() {
    return this.component.contextHash;
  }

  get contentHash() {
    return this.component.contentHash;
  }

  get disableActions() {
    return (
      this.component.disableActions ||
      (this.content && this.content.disableActions) ||
      !!this.contextDeleteData
    );
  }

  constructor(
    // private commentManager: CommentManager,
    private contentEvents: ContentEvents,
    private contentState: ContentState,
    public media: MediaObserver,
    public element: ElementRef,
    private renderer2: Renderer2,
    protected permissions: PermissionsService,
    protected auth: AuthService,
    protected likeService: LikeService,
    protected reportService: ReportService,
    protected commentHandler: CommentHandlerService,
    protected authInfoService: AuthInfoService,
  ) {
    this.mqAliasSubscription = media.asObservable().subscribe(mediaChanges => {
      this.mqAlias = mediaChanges[0].mqAlias;
    });
    this._commentDataChanged$ = this._commentDataChanged.asObservable();
    this.canComment$ = this._commentDataChanged$.pipe(
      switchMap(() => {
        return this.commentHandler.canCurrentUserComment(
          this.content?.allowedCommentingRoleTypesList,
          this.content.ownerGroupHash,
        );
      }),
    );
  }

  getLikeCount() {
    let likeCount = 0;
    if (this.content) {
      likeCount = this.content.likeCount;
    }

    return this.likeService.likeCount(this.contextHash, likeCount);
  }

  ngAfterViewInit() {
    let focusedElsewhere = false;

    if (!focusedElsewhere) {
      const overlayNav = document.getElementsByClassName('overlay-wrap');
      if (overlayNav && overlayNav.length) {
        scrollIntoView(
          // @TODO: @types/scroll-into-view doesn't agree with the typings here
          <any>overlayNav[0],
          {
            time: 0, // default is half a second
          },
        );
      } else {
        // scrollIntoView(this.element.nativeElement);
      }
    }
  }

  ngOnInit() {
    this._initLikeChangedHandler();
    this._initContentChangeHandler();

    this.contextDeleteData = this.reportService.getDeletedReasons(
      this.contextHash,
    );

    this.setViewLikesPermission();

    this.initComments();
    if (this.content) {
      if (this.content.hasLiked) {
        this.likeService.setLike(this.contextHash);
      }
    }
  }

  initComments() {
    if (this.content) {
      this.commentCount = this.content.totalCommentCount;
      this.comments = this.content.commentList.map(obj => ({ obj }));
      this.currentCommentsPage = this.content.commentListPage;

      // update the short card, as fetchContent for long cards give latest data
      this.emitShortCardUpdate();

      const permission = this.permissions.hasPermission(
        MingaPermission.CONTENT_COMMENT_CREATE,
      );
    }
  }

  ngOnDestroy() {
    this.mqAliasSubscription.unsubscribe();
    if (this.likeSubscription) {
      this.likeSubscription.unsubscribe();
    }
    if (this.contentChangesSubscription) {
      this.contentChangesSubscription.unsubscribe();
    }
  }

  _initLikeChangedHandler() {
    if (this.likeSubscription) {
      this.likeSubscription.unsubscribe();
      this.likeSubscription = null;
    }

    const sub = this.likeService
      .onLikedChangedUnique(this.contextHash)
      .subscribe(liked => {
        if (liked) {
          this.content.likeCount += 1;
        } else {
          this.content.likeCount -= 1;
        }
      });

    this.likeSubscription = sub;
  }

  private _initContentChangeHandler() {
    if (this.contentChangesSubscription) {
      this.contentChangesSubscription.unsubscribe();
      this.contentChangesSubscription = undefined;
    }

    // update comments when a change in the content occurs
    this.contentChangesSubscription = this.component?.changesSubject.subscribe(
      () => {
        this.setViewLikesPermission();
        this.initComments();
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contextHash && !changes.contextHash.isFirstChange()) {
      this._initLikeChangedHandler();
    }
    if (changes.component) {
      this.setViewLikesPermission();
      this.initComments();

      if (!changes.component.isFirstChange()) {
        this._initContentChangeHandler();
      }
    }
    if (changes.content) {
      this._commentDataChanged.next(true);
    }

    this.mgCheckStyleChanges();
  }

  mgCheckStyleChanges() {
    if (this.element && this.component) {
      const component = this.component;

      if (component.content) {
        const content = component.content;

        this.renderer2.setStyle(
          this.element.nativeElement,
          'background-color',
          content.backgroundColor || '',
        );

        this.element.nativeElement.style.setProperty(
          '--mg-comment-background',
          content.backgroundColor || '',
        );

        if (this.content.lightContent) {
          this.renderer2.addClass(this.element.nativeElement, 'light-content');
        } else {
          this.renderer2.removeClass(
            this.element.nativeElement,
            'light-content',
          );
        }

        if (this.content.lightContent) {
          this.renderer2.addClass(
            this.element.nativeElement,
            'gradient-overlap',
          );
        } else {
          this.renderer2.removeClass(
            this.element.nativeElement,
            'gradient-overlap',
          );
        }
      }
    }
  }

  numberize(count: number | string) {
    let value = numeral(count).value();

    if (value > 1000) {
      let numberized: string = numeral(value).format('0a');
      return numberized;
    } else {
      return value;
    }
  }

  humanize(timestamp: number | string) {
    let now = day();

    if (typeof timestamp == 'string') {
      timestamp = parseInt(timestamp);
    }

    return day(timestamp).fromNow();
  }

  get isLiked(): boolean {
    if (!this.contextHash) return false;
    return this.likeService.isLiked(this.contextHash);
  }

  async like(e?: MouseEvent) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
    }

    this.likeClickAnimation = true;

    setTimeout(() => {
      this.likeClickAnimation = false;
    }, 600);

    await this.likeService.toggleLike(this.contextHash);
  }

  replyClick() {
    if (this.commentListEl) {
      this.commentListEl.replyClick();
    }
  }

  async setViewLikesPermission() {
    let isOwnContent: boolean = false;

    if (this.content) {
      if (this.content.authorPersonView) {
        isOwnContent =
          this.content.authorPersonView.personHash ==
          this.authInfoService.authPersonHash;
      } else if (this.content.birthdayPersonList) {
        for (let person of this.content.birthdayPersonList) {
          if (person.personHash == this.authInfoService.authPersonHash) {
            isOwnContent = true;
            break;
          }
        }
      }
    }

    if (isOwnContent) {
      this.canViewLikes = this.permissions.hasPermission(
        MingaPermission.CONTENT_LIKES_LIKE,
      );
    } else {
      this.canViewLikes = this.permissions.hasPermission(
        MingaPermission.CONTENT_LIKES_VIEW,
      );
    }
  }

  viewLikes() {
    this.contentEvents.emitOnSeePeopleLikes({
      content: this.content,
      contextHash: this.contextHash,
    });
  }

  onCommentCountUpdate(count: number) {
    this.commentCount = count;
  }

  protected emitShortCardUpdate() {
    let commentList: FeedComment.AsObject[] = [];
    for (let commentDisplay of this.comments) {
      let comment = commentDisplay.obj;
      commentList.push({
        body: comment.text,
        timestamp: comment.timestamp,
        personView: comment.authorPersonView,
        commentContextHash: comment.commentContextHash,
        likeCount: comment.likeCount,
        bodyMentionRangeList: comment.textMentionRangeList,
        displayName: null,
        hasLiked: false,
      });
    }
    this.contentEvents.emitShortCardViewUpdateEvent({
      contentContextHash: this.contextHash,
      commentList: commentList,
      totalCommentCount: this.content.totalCommentCount,
      commentCount: this.content.commentCount,
      likeCount: this.content.likeCount,
    });
  }
}
