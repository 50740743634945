/* eslint-disable @typescript-eslint/no-namespace */
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import * as Day from 'dayjs';

import { day } from 'minga/libraries/day';

import { PhoneNumber } from '../util/awesome-phonenumber';

// @NOTE: Try to keep all the exports of this file the `ValidatorFn` type or the
//        AsyncValidatorFn type.

export namespace MgValidators {
  // The regex pattern checks for letters, numbers, and all but symbols
  // check for at least one number and letter: (?=.*[0-9])(?=.*[a-zA-Z])
  // see no-symbol-regex.md for no symbol regex end portion
  export const NoSymbolsRegex =
    /^((?![\u{E000}-\u{F8FF}]|\u{D83C}[\u{DF00}-\u{DFFF}]|\u{D83D}[\u{DC00}-\u{DDFF}]|[\u{1D000}-\u{1F9C0}]).)*$/u;
  export const NoSymbolsOneLetterOneNumberRegex =
    /^(?=.*[0-9])(?=.*[a-zA-Z])((?![\u{E000}-\u{F8FF}]|\u{D83C}[\u{DF00}-\u{DFFF}]|\u{D83D}[\u{DC00}-\u{DDFF}]|[\u{1D000}-\u{1F9C0}]).)*$/u;
  export const AtLeastOneLetterOneNumberOneSymbolRegex =
    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'",.<>?/]).*$/u;
  // needs to have "youtu" for a youtube video url
  export const YoutubeUrlRegex = /[Yy][Oo][Uu][Tt][Uu]/iu;

  // accepts no dashes, dashes, dots or no tods, brackets around area code or
  // not, and optional leading number
  export const NorthAmericanPhoneNumberRegex =
    /^([0-9]( |-)?)?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  // grabbed from: https://www.regextester.com/93652
  export const WebsiteUrlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

  export const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

  export const KioskPinRegex = /^\d{6}$/;

  /* Validators */

  export const NoSymbolsOneLetterOneNumber: ValidatorFn = Validators.pattern(
    NoSymbolsOneLetterOneNumberRegex,
  );

  export const AtLeastOneLetterOneNumberOneSymbol: ValidatorFn =
    Validators.pattern(AtLeastOneLetterOneNumberOneSymbolRegex);

  export const NoSymbols: ValidatorFn = Validators.pattern(NoSymbolsRegex);

  export const Youtube: ValidatorFn = Validators.pattern(YoutubeUrlRegex);

  export const NorthAmericanPhoneNumber: ValidatorFn = Validators.pattern(
    NorthAmericanPhoneNumberRegex,
  );

  export const WebsiteUrl: ValidatorFn = Validators.pattern(WebsiteUrlRegex);

  export const Email: ValidatorFn = Validators.pattern(EmailRegex);

  export const KioskPinValidator: ValidatorFn =
    Validators.pattern(KioskPinRegex);

  export const PositiveIntegerValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      if (value >= 0) return null;

      return {
        positiveInteger: true,
      };
    };
  };

  export const DateNotInPastValidator = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const inputDate: Day.Dayjs | null = control.value;
    const today = Day();

    if (!inputDate) {
      return;
    }

    const isSameOrAfterToday = inputDate.isSameOrAfter(today, 'day');
    return isSameOrAfterToday ? null : { dateInPast: true };
  };

  export const TimeComparisonValidator = (
    startTimeControlName: string,
    endTimeControlName: string,
  ): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startTime = control.get(startTimeControlName)?.value;
      const endTime = control.get(endTimeControlName)?.value;

      if (startTime && endTime && startTime > endTime) {
        return { invalidTimeComparison: true };
      }

      return null;
    };
  };

  export const DateTimeComparisonValidator = (
    startDateControlName: string,
    endDateControlName: string,
    startTimeControlName: string,
    endTimeControlName: string,
  ): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startDateValue = control.get(startDateControlName)?.value;
      const endDateValue = control.get(endDateControlName)?.value;
      const startTime = control.get(startTimeControlName)?.value;
      const endTime = control.get(endTimeControlName)?.value;

      if (startDateValue && endDateValue && startTime && endTime) {
        const startDate = day(startDateValue);
        const endDate = day(endDateValue);
        const startTimes = startTime.split(':');
        const startDateTime = startDate
          .set('hour', startTimes[0])
          .set('minute', startTimes[1]);
        const endTimes = endTime.split(':');
        const endDateTime = endDate
          .set('hour', endTimes[0])
          .set('minute', endTimes[1]);

        if (startDateTime.isAfter(endDateTime)) {
          return { invalidTimeComparison: true };
        }
      }

      return null;
    };
  };

  export const DateTimeNotInPastValidator = (
    dateName: string,
    startTimeName: string,
    minDate: Day.Dayjs = Day(),
  ): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateValue: Day.Dayjs = control.get(dateName)?.value;
      const startTimeValue = control.get(startTimeName)?.value;

      if (!dateValue || !startTimeValue) return null;

      const [hours, minutes] = startTimeValue.split(':');

      if (!hours || !minutes) return null;

      const inputDate = dateValue
        .set('hour', Number(hours))
        .set('minute', Number(minutes));

      if (inputDate.isBefore(minDate, 'minute')) {
        return { invalidDateTimeInPast: true };
      }

      return null;
    };
  };

  // built-in email validator does not work for array-like objects
  export const allValuesAsEmailsValidator = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const emails = control.value;

    if (!emails) return null;
    if (!Array.isArray(emails)) return { invalidEmails: true };

    const invalidEmails = emails.some(email => !EmailRegex.test(email.trim()));

    return invalidEmails ? { invalidEmails: true } : null;
  };

  export const allValuesAsPhoneNumbersValidator = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const phoneNumbers = control.value;

    if (!phoneNumbers) return null;
    if (!Array.isArray(phoneNumbers)) return { invalidPhoneNumbers: true };

    const invalidPhoneNumbers = phoneNumbers.some(phoneNumber => {
      const parsedPhoneNumber = new PhoneNumber(phoneNumber.trim(), 'US');
      return !parsedPhoneNumber.isValid();
    });

    return invalidPhoneNumbers ? { invalidPhoneNumbers: true } : null;
  };

  export const templateValidator = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const template = control.value;

    if (!template) return null;
    const _isBalancedBraces = (str: string) => {
      const isOpening = c => /\{/.test(c);
      const isClosing = c => /\}/.test(c);
      const open = { '}': '{' };

      const stack = [];
      let index;
      const finished = Array.from(str).every((c: string, i) => {
        const temp = stack[stack.length - 1];
        if (isOpening(c)) {
          if (temp && temp.c === c) {
            temp.indices.push(i);
          } else {
            stack.push({ c, indices: [i] });
          }
          return true;
        }
        if (isClosing(c)) {
          if (temp && temp.c === open[c]) {
            temp.indices.pop();
            if (!temp.indices.length) stack.pop();
          } else {
            index = stack.length ? stack.pop().indices.pop() : i;
            return false;
          }
        }
        return true;
      });

      return finished && !stack.length;
    };
    if (!_isBalancedBraces(template)) return { invalidBraces: true };
    const re = /\$\{\s*((\w+))\s*\}/g;
    let reResult;
    const validFields = [
      'firstName',
      'lastName',
      'typeName',
      'mingaName',
      'note',
      'assigner',
      'points',
    ];
    do {
      reResult = re.exec(template);
      if (reResult) {
        if (!validFields.includes(reResult[1])) {
          return { invalidField: true };
        }
      }
    } while (reResult);
    return null;
  };

  export const restrictionInputValidator = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const value = control.value;
    const isArray = Array.isArray(value);

    if (!value || (isArray && value.length === 0)) return { required: true };

    return null;
  };
}
