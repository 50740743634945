"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const proto = require("minga/proto/stats_report/stats_report_pb");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const util_1 = require("minga/libraries/util/index");
exports.toProto = (report) => {
    var _a;
    const msg = new proto.ScheduledReportMinimal();
    if (report.id)
        msg.setId(report.id);
    if (report.name)
        msg.setName(report.name);
    if (report.reportType)
        msg.setReportType(report.reportType);
    if (report.frequency)
        msg.setFrequency(report.frequency);
    if (report.time)
        msg.setTime(report.time);
    if ((_a = report.deliveryType) === null || _a === void 0 ? void 0 : _a.length)
        msg.setDeliveryTypeList(report.deliveryType);
    if (report.createdBy)
        msg.setCreatedBy(report.createdBy);
    if (report.day)
        msg.setDay(report.day);
    if (report.createdById)
        msg.setCreatedById(report.createdById);
    if (report.createdAt)
        msg.setCreatedAt(shared_grpc_1.dateObjectToDateTimeMessage(report.createdAt));
    if (typeof report.active === 'boolean')
        msg.setActive(report.active);
    return msg;
};
exports.fromProto = (msg) => {
    const reportType = ts_enum_util_1.$enum(domain_1.ReportTypes).asValueOrThrow(msg.getReportType());
    if (reportType === domain_1.ReportTypes.EVENT_PEOPLE)
        throw new Error('Invalid report type for scheduled reports');
    const day = msg.getDay();
    return {
        id: msg.getId(),
        name: msg.getName(),
        reportType,
        frequency: msg.getFrequency(),
        time: msg.getTime(),
        deliveryType: msg.getDeliveryTypeList(),
        createdBy: msg.getCreatedBy(),
        day: day ? ts_enum_util_1.$enum(util_1.DayOfWeekEnum).asValueOrThrow(msg.getDay()) : undefined,
        active: msg.getActive(),
        createdById: msg.getCreatedById(),
        createdAt: shared_grpc_1.dateTimeMessageToDateObject(msg.getCreatedAt()),
    };
};