import {
  Directive,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HallPassStatusEnum, IHallPassType } from 'minga/libraries/domain';
import { PbisCategory } from 'minga/libraries/shared/pbis/constants';
import { mingaSettingTypes } from 'minga/libraries/util';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { BehaviorMsgCategory } from '@modules/behavior-manager/components/bm-types/constants';
import { HpmDashboardTableItem } from '@modules/hallpass-manager';
import { PeopleViewProfileRoute } from '@modules/people/components/people-view-profile/constants/people-view-profile.constants';
import { PeopleRoute } from '@modules/people/types';
import { ToolsRoutes } from '@modules/tools/tools.types';

import { Breadcrumb } from '@shared/components/navigation/components/breadcrumb/breadcrumb.types';
import { ViewIdService } from '@shared/components/view-id/services/view-id.service';
import { ALIAS_BREAKPOINT_MAP, MediaService } from '@shared/services/media';

import { ActionItem, AssignmentType } from '../types/tt-my-class.types';
import { MyClassHallPassService } from './my-class-hallpasses.service';
import { groupAndCount } from './my-class.utils';

type ActionItemWithCount = ActionItem & { count: number };

@Directive()
@Injectable()
export class StudentItemBaseDirective implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _pastAssignmentsSubject = new BehaviorSubject<ActionItem[]>([]);
  private _pastAssignments$ = this._pastAssignmentsSubject.asObservable();

  @Input() canViewProfile: boolean;
  @Input() selected: boolean;
  @Input() authHash: string;
  @Input() hidePicture: boolean;
  @Input() firstNameFirst: boolean;
  @Input() student: PersonViewMinimal.AsObject;
  @Input() showPulse: boolean;
  @Input() set pastAssignments(value: ActionItem[]) {
    this._pastAssignmentsSubject.next(value);
  }
  @Input() hallPass: HpmDashboardTableItem & { type: IHallPassType };
  @Output() toggleSelected = new EventEmitter<boolean>();
  @Output() hallPassChange = new EventEmitter<{
    action: 'end' | 'deny' | 'approve';
    hallPass: HpmDashboardTableItem & { type: IHallPassType };
    opts?: {
      skipConfirmation?: boolean;
    };
  }>();

  public pastPraises$: Observable<{
    totalCount: number;
    items: ActionItemWithCount[];
  }> = this._pastAssignments$.pipe(
    map(assignments => {
      const praises = assignments.filter(a => {
        const data = a.data as any;
        return (
          a.assignmentType === AssignmentType.BEHAVIOR &&
          data.categoryId === PbisCategory.PRAISE
        );
      });

      return {
        items: groupAndCount(praises),
        totalCount: praises.length,
      };
    }),
  );

  public pastPraiseConsequences$: Observable<{
    totalCount: number;
    items: ActionItemWithCount[];
  }> = this._pastAssignments$.pipe(
    map(assignments => {
      const consequences = assignments.filter(a => {
        const data = a.data as any;
        return (
          a.assignmentType === AssignmentType.CONSEQUENCE &&
          data.categoryId === BehaviorMsgCategory.PRAISE
        );
      });

      return {
        items: groupAndCount(consequences),
        totalCount: consequences.length,
      };
    }),
  );

  public pastGuidances$: Observable<{
    totalCount: number;
    items: ActionItemWithCount[];
  }> = this._pastAssignments$.pipe(
    map(assignments => {
      const guidances = assignments.filter(a => {
        const data = a.data as any;
        return (
          a.assignmentType === AssignmentType.BEHAVIOR &&
          data.categoryId === PbisCategory.GUIDANCE
        );
      });

      return {
        items: groupAndCount(guidances),
        totalCount: guidances.length,
      };
    }),
  );

  public pastConsequences$: Observable<{
    totalCount: number;
    items: ActionItemWithCount[];
  }> = this._pastAssignments$.pipe(
    map(assignments => {
      const consequences = assignments.filter(a => {
        const data = a.data as any;
        return (
          a.assignmentType === AssignmentType.CONSEQUENCE &&
          data.categoryId === BehaviorMsgCategory.GUIDANCE
        );
      });

      return {
        items: groupAndCount(consequences),
        totalCount: consequences.length,
      };
    }),
  );

  public PASS_STATUS_TO_TYPE = {
    [HallPassStatusEnum.ACTIVE]: 'default',
    [HallPassStatusEnum.PENDING_APPROVAL]: 'warning',
    [HallPassStatusEnum.OVERDUE]: 'error',
    [HallPassStatusEnum.ENDED]: 'muted',
  };

  public PASS_STATUS_TO_CARD_TYPE = {
    [HallPassStatusEnum.PENDING_APPROVAL]: 'pending',
    [HallPassStatusEnum.OVERDUE]: 'overdue',
  };

  public HallPassStatus = HallPassStatusEnum;

  public readonly recentlyEndedSetting$ =
    this._mingaSettings.getSettingValueObs(
      mingaSettingTypes.PASS_RECENTLY_ENDED_FILTER,
    );

  public manuallyEndedPassesSetting: boolean;
  public readonly manuallyEndedPassesSetting$ = this._mingaSettings
    .getSettingValueObs(mingaSettingTypes.PASS_MUST_MANUALLY_END)
    .pipe(
      tap(value => {
        this.manuallyEndedPassesSetting = value;
      }),
    );

  constructor(
    public mediaService: MediaService,
    private _viewId: ViewIdService,
    private _router: Router,
    private _mingaSettings: MingaSettingsService,
    public myClassHallPassService: MyClassHallPassService,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public get classes() {
    const classes = {
      selected: this.selected,
      'no-avatar': this.hidePicture,
      'first-name-first': this.firstNameFirst,
      'show-pulse': this.showPulse,
    };

    if (
      this.hallPass &&
      this.PASS_STATUS_TO_CARD_TYPE[this.hallPass.status.state]
    ) {
      const status = this.PASS_STATUS_TO_CARD_TYPE[this.hallPass.status.state];
      classes[status] = true;
    }

    return classes;
  }

  public toggle() {
    this.toggleSelected.emit(!this.selected);
  }

  public actionsBarClicked(event, breakpoint) {
    // on mobile we don't want the action bar to toggle the selected state
    if (
      breakpoint === ALIAS_BREAKPOINT_MAP.xs ||
      breakpoint === ALIAS_BREAKPOINT_MAP.sm
    ) {
      event.stopPropagation();
    }
  }

  public navigateToId(hash) {
    this._router.navigate([
      '',
      { outlets: { o: [this._viewId.getIdOverlayRoute(), hash] } },
    ]);
  }

  public navigateToProfile(hash) {
    const origin: Breadcrumb = {
      title: 'My Class',
      path: `/${ToolsRoutes.ROOT}/${ToolsRoutes.MY_CLASS}`,
    };

    this._router.navigate(
      [
        '',
        PeopleRoute.ROOT,
        PeopleViewProfileRoute.PROFILE,
        hash,
        PeopleViewProfileRoute.DASHBOARD,
      ],
      {
        state: {
          origin,
        },
      },
    );
  }

  public matMenuKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      return;
    }
    event.stopPropagation();
  }

  public showIcon(): boolean {
    return !(
      this.hallPass.type?.manuallyEndPass ?? this.manuallyEndedPassesSetting
    );
  }

  public async onHallPassActionClicked(
    action: 'end' | 'deny' | 'approve',
    opts?: {
      skipConfirmation?: boolean;
    },
  ) {
    this.hallPassChange.emit({ action, hallPass: this.hallPass, opts });
  }
}
