import * as $ngrx_entity_entity from '@ngrx/entity';
//TS2742
import * as $ngrx_entity_src_models from '@ngrx/entity/src/models';
import * as $ngrx_store_store from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
//TS2742
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

//TS2742
import { IPeopleCollectionPersonDetails } from 'minga/libraries/domain';

import * as peopleCollectionActions from '../actions';

export const peopleCollectionStoreKeyname = 'peopleCollection';

export function selectUserId(a: IPeopleCollectionPersonDetails): string {
  return a.personHash;
}

export interface IPeopleCollectionState
  extends EntityState<IPeopleCollectionPersonDetails> {}

export const adapter: EntityAdapter<IPeopleCollectionPersonDetails> =
  createEntityAdapter<IPeopleCollectionPersonDetails>({
    selectId: selectUserId,
  });

export const selectPeopleCollectionState =
  createFeatureSelector<IPeopleCollectionState>(peopleCollectionStoreKeyname);

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectPeopleCollectionState);

export const selectAllPeopleInCollection = selectAll;
export const selectPersonByHash = createSelector(
  selectAllPeopleInCollection,
  (
    people: IPeopleCollectionPersonDetails[],
    props: IGetPersonFromPeopleCollectionOptions,
  ) =>
    people.find(
      (person: IPeopleCollectionPersonDetails) =>
        person.personHash == props.hash,
    ),
);

export const selectPeopleByHash = createSelector(
  selectAllPeopleInCollection,
  (
    people: IPeopleCollectionPersonDetails[],
    props: IGetPeopleFromPeopleCollectionOptions,
  ) =>
    people.filter((person: IPeopleCollectionPersonDetails) =>
      props.hashes.includes(person.personHash),
    ),
);

export interface IGetPersonFromPeopleCollectionOptions {
  hash: string;
}
export interface IGetPeopleFromPeopleCollectionOptions {
  hashes: readonly string[];
}
export const initialState: IPeopleCollectionState = adapter.getInitialState();

const peopleCollectionReducer = createReducer(
  initialState,
  on(
    peopleCollectionActions.PeopleCollectionActions.addPersonToCollection,
    (state, { person }) => {
      return adapter.upsertOne(person, state);
    },
  ),
  on(
    peopleCollectionActions.PeopleCollectionActions.addPeopleToCollection,
    (state, { people }) => {
      return adapter.upsertMany(people, state);
    },
  ),
);

export function reducer(
  state: IPeopleCollectionState | undefined,
  action: Action,
) {
  return peopleCollectionReducer(state, action);
}
