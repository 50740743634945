import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import * as flex_time_pb from 'minga/proto/flex_time/flex_time_pb';
import { BannerType } from 'minga/libraries/domain';
import { Category } from 'minga/libraries/domain';
import { FlexTimeActivity } from 'minga/libraries/domain';
import { CategoryMapper } from 'minga/libraries/shared-grpc';
import { FlexTimeActivityMapper } from 'minga/libraries/shared-grpc';
import { FlexTimeManager } from 'minga/proto/flex_time/flex_time_ng_grpc_pb';
import { BannerLibrary } from 'minga/proto/gateway/banner_ng_grpc_pb';
import {
  Banner,
  BannerLibraryGetAllByType,
} from 'minga/proto/gateway/banner_pb';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ErrorHandlerService } from '@shared/services/error-handler';

import { FtmAtEditComponent } from '../components/ftm-at-edit/ftm-at-edit.component';
import { FtmActivityTemplatesMessages } from '../ftm-activity-templates.constants';
import {
  FlexEditModalData,
  FlexEditModalResponse,
} from '../ftm-activity-templates.types';

@Injectable({ providedIn: 'root' })
export class FtmActivityTemplatesService implements OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);
  private _activities = new BehaviorSubject<FlexTimeActivity[]>([]);

  public activities$ = this._activities.asObservable().pipe(shareReplay(1));

  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalService: ModalOverlayService<
      FlexEditModalData,
      FlexEditModalResponse
    >,
    private _bannerLibrary: BannerLibrary,
    private _flexTimeManager: FlexTimeManager,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public async fetchAll(personHash?: string) {
    const request = new flex_time_pb.ListFlexTimeActivitiesRequest();

    if (personHash) {
      request.setPersonHash(personHash);
    }

    const response = await this._flexTimeManager.listFlexTimeActivities(
      request,
    );
    const mapped = response
      .getFlexTimeActivitiesList()
      .map(FlexTimeActivityMapper.fromProto);

    this._activities.next(mapped);
  }

  public async fetch(id: number): Promise<FlexTimeActivity> {
    try {
      const request = new flex_time_pb.GetFlexTimeActivityRequest();
      request.setId(id);
      const response = await this._flexTimeManager.getFlexTimeActivity(request);
      return FlexTimeActivityMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch all flex time activities',
        error,
        true,
      );
    }
  }

  public async upsertActivity(
    data: Partial<FlexTimeActivity>,
  ): Promise<FlexTimeActivity> {
    const request = new flex_time_pb.UpsertFlexTimeActivityRequest();
    request.setFlexTimeActivity(FlexTimeActivityMapper.toProto(data as any));
    const response = await this._flexTimeManager.upsertFlexTimeActivity(
      request,
    );
    return FlexTimeActivityMapper.fromProto(response);
  }

  public async delete(id: number): Promise<void> {
    const request = new flex_time_pb.DeleteFlexTimeActivityRequest();
    request.setId(id);
    await this._flexTimeManager.deleteFlexTimeActivity(request);

    const activities = this._activities.getValue();
    this._activities.next(activities.filter(a => a.id !== id));
  }

  public async archive(id: number): Promise<void> {
    const request = new flex_time_pb.ArchiveFlexTimeActivityRequest();
    request.setId(id);
    await this._flexTimeManager.archiveFlexTimeActivity(request);

    const activities = this._activities.getValue();
    this._activities.next(activities.filter(a => a.id !== id));
  }

  public async openEditModal(activity: FlexTimeActivity) {
    const modalRef = this._modalService.open(FtmAtEditComponent, {
      data: activity || {},
      disposeOnNavigation: false,
    });
    const response = await modalRef.afterClosed.toPromise();
    if (!response) return;
    const { type } = response;
    switch (type) {
      case ModalOverlayServiceCloseEventType.CREATE: {
        return this._handleEditSuccess(
          FtmActivityTemplatesMessages.SNACKBAR_CREATE,
        );
      }
      case ModalOverlayServiceCloseEventType.SUBMIT: {
        return this._handleEditSuccess(
          FtmActivityTemplatesMessages.SNACKBAR_UPDATE,
        );
      }
      case ModalOverlayServiceCloseEventType.DELETE: {
        return this._handleEditSuccess(
          FtmActivityTemplatesMessages.SNACKBAR_DELETE,
        );
      }
      default: {
        return;
      }
    }
  }

  private _handleEditSuccess(message) {
    this._systemAlertSnackBar.open({
      type: 'success',
      message,
    });
  }

  public async getBannerList(): Promise<Banner.AsObject[]> {
    const request = new BannerLibraryGetAllByType();
    // TODO do we need a seperate type for these?
    request.setType(BannerType.EVENT);

    const response = await this._bannerLibrary.getAllByType(request);
    return response.getBannersList().map((b: Banner) => b.toObject());
  }

  public async getFlexTimeActivityTypes(): Promise<Category[]> {
    const request = new flex_time_pb.GetFlexTimeActivityTypesRequest();
    const response = await this._flexTimeManager.getFlexTimeActivityTypes(
      request,
    );
    const activityTypesProto = response.getActivityTypesList();
    return activityTypesProto.map(CategoryMapper.fromProto);
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
