import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EditableCheckin } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import { CheckinManagerReportsService } from '../../checkin-manager-reports.service';
import { CheckinManagerHistoryDatasourceService } from '../../services';
import { CheckinManagerHistoryActionService } from '../../services';
import { CheckinManagerHistoryMessages } from './checkin-manager-report-history.constants';

@Component({
  selector: 'mg-checkin-manager-report-history',
  templateUrl: './checkin-manager-report-history.component.html',
  styleUrls: ['./checkin-manager-report-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckinManagerHistoryDatasourceService,
    CheckinManagerHistoryActionService,
  ],
})
export class CheckinManagerReportHistoryComponent implements OnInit, OnDestroy {
  public readonly MESSAGES = CheckinManagerHistoryMessages;

  public readonly canMultiSelect$ = this._permissions.observePermission(
    MingaPermission.CHECKIN_TYPE_MANAGE,
  );

  private _destroyedSubject = new ReplaySubject<void>(1);
  private _selectableItems: EditableCheckin[] = [];

  constructor(
    public ds: CheckinManagerHistoryDatasourceService,
    public actionService: CheckinManagerHistoryActionService,
    private _permissions: PermissionsService,
    private _cmReportService: CheckinManagerReportsService,
  ) {}

  ngOnInit(): void {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.clearSelection();
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this._cmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => this.actionService.clearSelection());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
