"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadOnlyRole = void 0;
const domain_1 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const UserRole_1 = require("minga/libraries/shared/permissions/internal/UserRole");
class ReadOnlyRole extends UserRole_1.UserRole {
    constructor() {
        super(...arguments);
        this.roleType = domain_1.MingaRoleType.READ_ONLY;
        this.name = 'Read-Only';
        this.displayNameFormat = util_1.DisplayNameFormat.FIRST_LAST;
        this.iconUrl = 'assets/roles/read-only.svg';
        this.iconColor = '#c53635';
        this.capabilitiesDescription = ``;
        this.feedPermittable = false;
        this.galleryPermittable = false;
        this.roleFields = util_1.RoleFields.GRAD_STUDENT;
        this.groupFeedPermittable = false;
        this.programManagePermittable = false;
        this.videoUploadPermittable = false;
        this.groupCreatePermittable = false;
        this.admin = false;
        this.commentPermittable = false;
        this.requiredFeatures = [];
        this.sendToMailChimp = false;
        this.IDRole = UserRole_1.RoleCategory.STUDENT;
        this.permissions = [
            domain_1.MingaPermission.ACCOUNT_PROFILE_CHANGE,
            domain_1.MingaPermission.CONTENT_COMMENTS_VIEW,
            domain_1.MingaPermission.CONTENT_LIKES_LIKE,
            domain_1.MingaPermission.STUDENT_TOOLS,
            domain_1.MingaPermission.FLEX_TIME_VIEW_MY_ACTIVITY,
            domain_1.MingaPermission.STUDENT_SCHEDULE_VIEW,
            domain_1.MingaPermission.SELF_CHECKIN,
        ];
        this.overridablePermissions = [];
    }
}
exports.ReadOnlyRole = ReadOnlyRole;