"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserListMinimalsResponse = exports.GetUserListMinimalsRequest = exports.UserListMinimal = exports.DeleteUserListResponse = exports.DeleteUserListRequest = exports.UpdateUserListRequest = exports.CreateUserListRequest = exports.GetUserListRequest = exports.ListUserListsResponse = exports.ListUserListsRequest = exports.UserList = void 0;
const jspb = require("google-protobuf");
const common_date_pb = require("minga/proto/common/date_pb");
class UserList extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, UserList.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UserList.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UserList} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            createTime: (f = msg.getCreateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            updateTime: (f = msg.getUpdateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
            id: jspb.Message.getFieldWithDefault(msg, 3, 0),
            uuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
            title: jspb.Message.getFieldWithDefault(msg, 5, ""),
            description: jspb.Message.getFieldWithDefault(msg, 6, ""),
            pb_public: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            active: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            memberCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
            creator: (f = msg.getCreator()) && UserList.Creator.toObject(includeInstance, f),
            managerListId: jspb.Message.getFieldWithDefault(msg, 11, 0),
            managersList: jspb.Message.toObjectList(msg.getManagersList(), UserList.Manager.toObject, includeInstance),
            periodSourceIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
            termSourceIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UserList}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UserList;
        return UserList.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UserList} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UserList}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_date_pb.DateTime;
                    reader.readMessage(value1, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setCreateTime(value1);
                    break;
                case 2:
                    var value2 = new common_date_pb.DateTime;
                    reader.readMessage(value2, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setUpdateTime(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setUuid(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setTitle(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setDescription(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setPublic(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setActive(value8);
                    break;
                case 9:
                    var value9 = /** @type {number} */ (reader.readInt32());
                    msg.setMemberCount(value9);
                    break;
                case 10:
                    var value10 = new UserList.Creator;
                    reader.readMessage(value10, UserList.Creator.deserializeBinaryFromReader);
                    msg.setCreator(value10);
                    break;
                case 11:
                    var value11 = /** @type {number} */ (reader.readInt32());
                    msg.setManagerListId(value11);
                    break;
                case 12:
                    var value12 = new UserList.Manager;
                    reader.readMessage(value12, UserList.Manager.deserializeBinaryFromReader);
                    msg.addManagers(value12);
                    break;
                case 13:
                    var value13 = /** @type {string} */ (reader.readString());
                    msg.addPeriodSourceIds(value13);
                    break;
                case 14:
                    var value14 = /** @type {string} */ (reader.readString());
                    msg.addTermSourceIds(value14);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UserList} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getCreateTime();
        if (f != null) {
            writer.writeMessage(1, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getUpdateTime();
        if (f != null) {
            writer.writeMessage(2, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(3, f);
        }
        f = message.getUuid();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getTitle();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getDescription();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getPublic();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getActive();
        if (f) {
            writer.writeBool(8, f);
        }
        f = message.getMemberCount();
        if (f !== 0) {
            writer.writeInt32(9, f);
        }
        f = message.getCreator();
        if (f != null) {
            writer.writeMessage(10, f, UserList.Creator.serializeBinaryToWriter);
        }
        f = message.getManagerListId();
        if (f !== 0) {
            writer.writeInt32(11, f);
        }
        f = message.getManagersList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(12, f, UserList.Manager.serializeBinaryToWriter);
        }
        f = message.getPeriodSourceIdsList();
        if (f.length > 0) {
            writer.writeRepeatedString(13, f);
        }
        f = message.getTermSourceIdsList();
        if (f.length > 0) {
            writer.writeRepeatedString(14, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UserList.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional minga.common.DateTime create_time = 1;
     * @return {?DateTime}
     */
    getCreateTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 1));
    }
    /** @param {?DateTime|undefined} value */
    setCreateTime(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearCreateTime() {
        this.setCreateTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasCreateTime() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional minga.common.DateTime update_time = 2;
     * @return {?DateTime}
     */
    getUpdateTime() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
    }
    /** @param {?DateTime|undefined} value */
    setUpdateTime(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearUpdateTime() {
        this.setUpdateTime(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasUpdateTime() {
        return jspb.Message.getField(this, 2) != null;
    }
    /**
     * optional int32 id = 3;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 3, value);
    }
    /**
     * optional string uuid = 4;
     * @return {string}
     */
    getUuid() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setUuid(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional string title = 5;
     * @return {string}
     */
    getTitle() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setTitle(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional string description = 6;
     * @return {string}
     */
    getDescription() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setDescription(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional bool public = 7;
     * @return {boolean}
     */
    getPublic() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setPublic(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional bool active = 8;
     * @return {boolean}
     */
    getActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setActive(value) {
        jspb.Message.setProto3BooleanField(this, 8, value);
    }
    /**
     * optional int32 member_count = 9;
     * @return {number}
     */
    getMemberCount() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
    }
    ;
    /** @param {number} value */
    setMemberCount(value) {
        jspb.Message.setProto3IntField(this, 9, value);
    }
    /**
     * optional Creator creator = 10;
     * @return {?UserList.Creator}
     */
    getCreator() {
        return /** @type{?UserList.Creator} */ (jspb.Message.getWrapperField(this, UserList.Creator, 10));
    }
    /** @param {?UserList.Creator|undefined} value */
    setCreator(value) {
        jspb.Message.setWrapperField(this, 10, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearCreator() {
        this.setCreator(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasCreator() {
        return jspb.Message.getField(this, 10) != null;
    }
    /**
     * optional int32 manager_list_id = 11;
     * @return {number}
     */
    getManagerListId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
    }
    ;
    /** @param {number} value */
    setManagerListId(value) {
        jspb.Message.setProto3IntField(this, 11, value);
    }
    /**
     * repeated Manager managers = 12;
     * @return {!Array<!UserList.Manager>}
     */
    getManagersList() {
        return /** @type{!Array<!UserList.Manager>} */ (jspb.Message.getRepeatedWrapperField(this, UserList.Manager, 12));
    }
    /** @param {!Array<!UserList.Manager>} value */
    setManagersList(value) {
        jspb.Message.setRepeatedWrapperField(this, 12, value);
    }
    /**
     * @param {!UserList.Manager=} opt_value
     * @param {number=} opt_index
     * @return {!UserList.Manager}
     */
    addManagers(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, UserList.Manager, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearManagersList() {
        this.setManagersList([]);
    }
    /**
     * repeated string period_source_ids = 13;
     * @return {!Array<string>}
     */
    getPeriodSourceIdsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
    }
    ;
    /** @param {!Array<string>} value */
    setPeriodSourceIdsList(value) {
        jspb.Message.setField(this, 13, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addPeriodSourceIds(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 13, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearPeriodSourceIdsList() {
        this.setPeriodSourceIdsList([]);
    }
    /**
     * repeated string term_source_ids = 14;
     * @return {!Array<string>}
     */
    getTermSourceIdsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
    }
    ;
    /** @param {!Array<string>} value */
    setTermSourceIdsList(value) {
        jspb.Message.setField(this, 14, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addTermSourceIds(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 14, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearTermSourceIdsList() {
        this.setTermSourceIdsList([]);
    }
}
exports.UserList = UserList;
UserList.displayName = "UserList";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
UserList.repeatedFields_ = [12, 13, 14];
(function (UserList) {
    class Creator extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Creator.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Creator} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
                firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
                lastName: jspb.Message.getFieldWithDefault(msg, 3, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Creator}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Creator;
            return Creator.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!UserList.Creator} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!UserList.Creator}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {string} */ (reader.readString());
                        msg.setHash(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setFirstName(value2);
                        break;
                    case 3:
                        var value3 = /** @type {string} */ (reader.readString());
                        msg.setLastName(value3);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!UserList.Creator} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getHash();
            if (f.length > 0) {
                writer.writeString(1, f);
            }
            f = message.getFirstName();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
            f = message.getLastName();
            if (f.length > 0) {
                writer.writeString(3, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            UserList.Creator.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional string hash = 1;
         * @return {string}
         */
        getHash() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
        }
        ;
        /** @param {string} value */
        setHash(value) {
            jspb.Message.setProto3StringField(this, 1, value);
        }
        /**
         * optional string first_name = 2;
         * @return {string}
         */
        getFirstName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setFirstName(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
        /**
         * optional string last_name = 3;
         * @return {string}
         */
        getLastName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
        }
        ;
        /** @param {string} value */
        setLastName(value) {
            jspb.Message.setProto3StringField(this, 3, value);
        }
    }
    Creator.displayName = "UserList.Creator";
    UserList.Creator = Creator;
})(UserList = exports.UserList || (exports.UserList = {})); // namespace UserList
(function (UserList) {
    class Manager extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Manager.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Manager} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
                firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
                lastName: jspb.Message.getFieldWithDefault(msg, 3, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Manager}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Manager;
            return Manager.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!UserList.Manager} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!UserList.Manager}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {string} */ (reader.readString());
                        msg.setHash(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setFirstName(value2);
                        break;
                    case 3:
                        var value3 = /** @type {string} */ (reader.readString());
                        msg.setLastName(value3);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!UserList.Manager} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getHash();
            if (f.length > 0) {
                writer.writeString(1, f);
            }
            f = message.getFirstName();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
            f = message.getLastName();
            if (f.length > 0) {
                writer.writeString(3, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            UserList.Manager.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional string hash = 1;
         * @return {string}
         */
        getHash() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
        }
        ;
        /** @param {string} value */
        setHash(value) {
            jspb.Message.setProto3StringField(this, 1, value);
        }
        /**
         * optional string first_name = 2;
         * @return {string}
         */
        getFirstName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setFirstName(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
        /**
         * optional string last_name = 3;
         * @return {string}
         */
        getLastName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
        }
        ;
        /** @param {string} value */
        setLastName(value) {
            jspb.Message.setProto3StringField(this, 3, value);
        }
    }
    Manager.displayName = "UserList.Manager";
    UserList.Manager = Manager;
})(UserList = exports.UserList || (exports.UserList = {})); // namespace UserList
(function (UserList) {
    class Member extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Member.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Member} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
                studentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
                firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
                lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
                role: jspb.Message.getFieldWithDefault(msg, 5, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Member}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Member;
            return Member.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!UserList.Member} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!UserList.Member}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = /** @type {string} */ (reader.readString());
                        msg.setHash(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setStudentId(value2);
                        break;
                    case 3:
                        var value3 = /** @type {string} */ (reader.readString());
                        msg.setFirstName(value3);
                        break;
                    case 4:
                        var value4 = /** @type {string} */ (reader.readString());
                        msg.setLastName(value4);
                        break;
                    case 5:
                        var value5 = /** @type {string} */ (reader.readString());
                        msg.setRole(value5);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!UserList.Member} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getHash();
            if (f.length > 0) {
                writer.writeString(1, f);
            }
            f = message.getStudentId();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
            f = message.getFirstName();
            if (f.length > 0) {
                writer.writeString(3, f);
            }
            f = message.getLastName();
            if (f.length > 0) {
                writer.writeString(4, f);
            }
            f = message.getRole();
            if (f.length > 0) {
                writer.writeString(5, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            UserList.Member.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional string hash = 1;
         * @return {string}
         */
        getHash() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
        }
        ;
        /** @param {string} value */
        setHash(value) {
            jspb.Message.setProto3StringField(this, 1, value);
        }
        /**
         * optional string student_id = 2;
         * @return {string}
         */
        getStudentId() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setStudentId(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
        /**
         * optional string first_name = 3;
         * @return {string}
         */
        getFirstName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
        }
        ;
        /** @param {string} value */
        setFirstName(value) {
            jspb.Message.setProto3StringField(this, 3, value);
        }
        /**
         * optional string last_name = 4;
         * @return {string}
         */
        getLastName() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
        }
        ;
        /** @param {string} value */
        setLastName(value) {
            jspb.Message.setProto3StringField(this, 4, value);
        }
        /**
         * optional string role = 5;
         * @return {string}
         */
        getRole() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
        }
        ;
        /** @param {string} value */
        setRole(value) {
            jspb.Message.setProto3StringField(this, 5, value);
        }
    }
    Member.displayName = "UserList.Member";
    UserList.Member = Member;
})(UserList = exports.UserList || (exports.UserList = {})); // namespace UserList
class ListUserListsRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ListUserListsRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ListUserListsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
            pageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
            showInactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ListUserListsRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ListUserListsRequest;
        return ListUserListsRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ListUserListsRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ListUserListsRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setPageSize(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readInt32());
                    msg.setPageToken(value2);
                    break;
                case 3:
                    var value3 = /** @type {boolean} */ (reader.readBool());
                    msg.setShowInactive(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ListUserListsRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPageSize();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getPageToken();
        if (f !== 0) {
            writer.writeInt32(2, f);
        }
        f = message.getShowInactive();
        if (f) {
            writer.writeBool(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ListUserListsRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 page_size = 1;
     * @return {number}
     */
    getPageSize() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setPageSize(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional int32 page_token = 2;
     * @return {number}
     */
    getPageToken() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setPageToken(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
    /**
     * optional bool show_inactive = 3;
     * @return {boolean}
     */
    getShowInactive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
    }
    ;
    /** @param {boolean} value */
    setShowInactive(value) {
        jspb.Message.setProto3BooleanField(this, 3, value);
    }
}
exports.ListUserListsRequest = ListUserListsRequest;
ListUserListsRequest.displayName = "ListUserListsRequest";
class ListUserListsResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, ListUserListsResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return ListUserListsResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!ListUserListsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            userListsList: jspb.Message.toObjectList(msg.getUserListsList(), UserList.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!ListUserListsResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new ListUserListsResponse;
        return ListUserListsResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!ListUserListsResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!ListUserListsResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setNextPageToken(value1);
                    break;
                case 2:
                    var value2 = new UserList;
                    reader.readMessage(value2, UserList.deserializeBinaryFromReader);
                    msg.addUserLists(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!ListUserListsResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getNextPageToken();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getUserListsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(2, f, UserList.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        ListUserListsResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string next_page_token = 1;
     * @return {string}
     */
    getNextPageToken() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setNextPageToken(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * repeated UserList user_lists = 2;
     * @return {!Array<!UserList>}
     */
    getUserListsList() {
        return /** @type{!Array<!UserList>} */ (jspb.Message.getRepeatedWrapperField(this, UserList, 2));
    }
    /** @param {!Array<!UserList>} value */
    setUserListsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 2, value);
    }
    /**
     * @param {!UserList=} opt_value
     * @param {number=} opt_index
     * @return {!UserList}
     */
    addUserLists(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, UserList, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearUserListsList() {
        this.setUserListsList([]);
    }
}
exports.ListUserListsResponse = ListUserListsResponse;
ListUserListsResponse.displayName = "ListUserListsResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
ListUserListsResponse.repeatedFields_ = [2];
class GetUserListRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetUserListRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetUserListRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetUserListRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetUserListRequest;
        return GetUserListRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetUserListRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetUserListRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetUserListRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetUserListRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.GetUserListRequest = GetUserListRequest;
GetUserListRequest.displayName = "GetUserListRequest";
class CreateUserListRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return CreateUserListRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!CreateUserListRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            title: jspb.Message.getFieldWithDefault(msg, 1, ""),
            description: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pb_public: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            managerListId: jspb.Message.getFieldWithDefault(msg, 5, 0),
            uuid: jspb.Message.getFieldWithDefault(msg, 6, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!CreateUserListRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new CreateUserListRequest;
        return CreateUserListRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!CreateUserListRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!CreateUserListRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setTitle(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setDescription(value2);
                    break;
                case 3:
                    var value3 = /** @type {boolean} */ (reader.readBool());
                    msg.setPublic(value3);
                    break;
                case 4:
                    var value4 = /** @type {boolean} */ (reader.readBool());
                    msg.setActive(value4);
                    break;
                case 5:
                    var value5 = /** @type {number} */ (reader.readInt32());
                    msg.setManagerListId(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setUuid(value6);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!CreateUserListRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getTitle();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getDescription();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getPublic();
        if (f) {
            writer.writeBool(3, f);
        }
        f = message.getActive();
        if (f) {
            writer.writeBool(4, f);
        }
        f = message.getManagerListId();
        if (f !== 0) {
            writer.writeInt32(5, f);
        }
        f = message.getUuid();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        CreateUserListRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string title = 1;
     * @return {string}
     */
    getTitle() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setTitle(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string description = 2;
     * @return {string}
     */
    getDescription() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setDescription(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional bool public = 3;
     * @return {boolean}
     */
    getPublic() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
    }
    ;
    /** @param {boolean} value */
    setPublic(value) {
        jspb.Message.setProto3BooleanField(this, 3, value);
    }
    /**
     * optional bool active = 4;
     * @return {boolean}
     */
    getActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
    }
    ;
    /** @param {boolean} value */
    setActive(value) {
        jspb.Message.setProto3BooleanField(this, 4, value);
    }
    /**
     * optional int32 manager_list_id = 5;
     * @return {number}
     */
    getManagerListId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
    }
    ;
    /** @param {number} value */
    setManagerListId(value) {
        jspb.Message.setProto3IntField(this, 5, value);
    }
    /**
     * optional string uuid = 6;
     * @return {string}
     */
    getUuid() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setUuid(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
}
exports.CreateUserListRequest = CreateUserListRequest;
CreateUserListRequest.displayName = "CreateUserListRequest";
class UpdateUserListRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UpdateUserListRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UpdateUserListRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            list: (f = msg.getList()) && UserList.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UpdateUserListRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UpdateUserListRequest;
        return UpdateUserListRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UpdateUserListRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UpdateUserListRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new UserList;
                    reader.readMessage(value1, UserList.deserializeBinaryFromReader);
                    msg.setList(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UpdateUserListRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getList();
        if (f != null) {
            writer.writeMessage(1, f, UserList.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UpdateUserListRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional UserList list = 1;
     * @return {?UserList}
     */
    getList() {
        return /** @type{?UserList} */ (jspb.Message.getWrapperField(this, UserList, 1));
    }
    /** @param {?UserList|undefined} value */
    setList(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearList() {
        this.setList(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasList() {
        return jspb.Message.getField(this, 1) != null;
    }
}
exports.UpdateUserListRequest = UpdateUserListRequest;
UpdateUserListRequest.displayName = "UpdateUserListRequest";
class DeleteUserListRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteUserListRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteUserListRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteUserListRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteUserListRequest;
        return DeleteUserListRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteUserListRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteUserListRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteUserListRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteUserListRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
}
exports.DeleteUserListRequest = DeleteUserListRequest;
DeleteUserListRequest.displayName = "DeleteUserListRequest";
class DeleteUserListResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return DeleteUserListResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!DeleteUserListResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!DeleteUserListResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new DeleteUserListResponse;
        return DeleteUserListResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!DeleteUserListResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!DeleteUserListResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!DeleteUserListResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        DeleteUserListResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
}
exports.DeleteUserListResponse = DeleteUserListResponse;
DeleteUserListResponse.displayName = "DeleteUserListResponse";
class UserListMinimal extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, UserListMinimal.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return UserListMinimal.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!UserListMinimal} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            title: jspb.Message.getFieldWithDefault(msg, 2, ""),
            termId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            creatorHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
            currentTerm: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            termsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!UserListMinimal}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new UserListMinimal;
        return UserListMinimal.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!UserListMinimal} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!UserListMinimal}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setTitle(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setTermId(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setCreatorHash(value4);
                    break;
                case 5:
                    var value5 = /** @type {boolean} */ (reader.readBool());
                    msg.setCurrentTerm(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.addTerms(value6);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!UserListMinimal} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getTitle();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getTermId();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getCreatorHash();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getCurrentTerm();
        if (f) {
            writer.writeBool(5, f);
        }
        f = message.getTermsList();
        if (f.length > 0) {
            writer.writeRepeatedString(6, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        UserListMinimal.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string title = 2;
     * @return {string}
     */
    getTitle() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setTitle(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string term_id = 3;
     * @return {string}
     */
    getTermId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setTermId(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string creator_hash = 4;
     * @return {string}
     */
    getCreatorHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setCreatorHash(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional bool current_term = 5;
     * @return {boolean}
     */
    getCurrentTerm() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
    }
    ;
    /** @param {boolean} value */
    setCurrentTerm(value) {
        jspb.Message.setProto3BooleanField(this, 5, value);
    }
    /**
     * repeated string terms = 6;
     * @return {!Array<string>}
     */
    getTermsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
    }
    ;
    /** @param {!Array<string>} value */
    setTermsList(value) {
        jspb.Message.setField(this, 6, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addTerms(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 6, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearTermsList() {
        this.setTermsList([]);
    }
}
exports.UserListMinimal = UserListMinimal;
UserListMinimal.displayName = "UserListMinimal";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
UserListMinimal.repeatedFields_ = [6];
class GetUserListMinimalsRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetUserListMinimalsRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetUserListMinimalsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            onlyMyLists: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetUserListMinimalsRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetUserListMinimalsRequest;
        return GetUserListMinimalsRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetUserListMinimalsRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetUserListMinimalsRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {boolean} */ (reader.readBool());
                    msg.setOnlyMyLists(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetUserListMinimalsRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getOnlyMyLists();
        if (f) {
            writer.writeBool(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetUserListMinimalsRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional bool only_my_lists = 1;
     * @return {boolean}
     */
    getOnlyMyLists() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
    }
    ;
    /** @param {boolean} value */
    setOnlyMyLists(value) {
        jspb.Message.setProto3BooleanField(this, 1, value);
    }
}
exports.GetUserListMinimalsRequest = GetUserListMinimalsRequest;
GetUserListMinimalsRequest.displayName = "GetUserListMinimalsRequest";
class GetUserListMinimalsResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetUserListMinimalsResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetUserListMinimalsResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetUserListMinimalsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            userListsList: jspb.Message.toObjectList(msg.getUserListsList(), UserListMinimal.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetUserListMinimalsResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetUserListMinimalsResponse;
        return GetUserListMinimalsResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetUserListMinimalsResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetUserListMinimalsResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new UserListMinimal;
                    reader.readMessage(value1, UserListMinimal.deserializeBinaryFromReader);
                    msg.addUserLists(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetUserListMinimalsResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getUserListsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, UserListMinimal.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetUserListMinimalsResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated UserListMinimal user_lists = 1;
     * @return {!Array<!UserListMinimal>}
     */
    getUserListsList() {
        return /** @type{!Array<!UserListMinimal>} */ (jspb.Message.getRepeatedWrapperField(this, UserListMinimal, 1));
    }
    /** @param {!Array<!UserListMinimal>} value */
    setUserListsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!UserListMinimal=} opt_value
     * @param {number=} opt_index
     * @return {!UserListMinimal}
     */
    addUserLists(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, UserListMinimal, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearUserListsList() {
        this.setUserListsList([]);
    }
}
exports.GetUserListMinimalsResponse = GetUserListMinimalsResponse;
GetUserListMinimalsResponse.displayName = "GetUserListMinimalsResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetUserListMinimalsResponse.repeatedFields_ = [1];