import { Component, Input } from '@angular/core';

import { dateMessageObjectToDateObject } from 'minga/app/src/app/util/date';
import { PointHistoryType } from 'minga/libraries/domain';
import { PointsHistoryItem } from 'minga/proto/points/points_pb';

@Component({
  selector: 'mg-points-history-item',
  templateUrl: './PointsHistoryItem.component.html',
  styleUrls: ['./PointsHistoryItem.component.scss'],
})
export class PointsHistoryItemComponent {
  private _item?: PointsHistoryItem.AsObject;

  typeClass = 'custom';
  itemDate?: Date;

  @Input()
  set item(newItem: PointsHistoryItem.AsObject | undefined) {
    this._item = newItem;

    if (newItem?.date) {
      this.itemDate = dateMessageObjectToDateObject(newItem.date);
    }

    this.typeClass = this.getTypeClass();
  }
  get item(): PointsHistoryItem.AsObject | undefined {
    return this._item;
  }

  constructor() {}

  getTypeClass(): string {
    if (!this.item || !this.item.type) return 'custom';

    switch (this.item.type) {
      case PointHistoryType.EVENT_CHECKIN:
        return 'event';
      case PointHistoryType.TEXT_CHALLENGE_RESPONSE:
        return 'text-challenge';
      case PointHistoryType.POLL_RESPONSE:
        return 'poll';
      case PointHistoryType.IMAGE_CHALLENGE_RESPONSE:
        return 'image-challenge';
      case PointHistoryType.CUSTOM:
      default:
        return 'custom';
    }
  }
}
