"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IEventTicketProtoMapper = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const event_pb_1 = require("minga/proto/content/event_pb");
var IEventTicketProtoMapper;
(function (IEventTicketProtoMapper) {
    function fromEventTicketMessage(message) {
        const guests = message.getGuestsList();
        const guestList = guests.map(guest => {
            let checkin = guest.getCheckedIn();
            let checkout = guest.getCheckedOut();
            const item = {
                guestName: guest.getGuestName(),
                guestId: guest.getGuestId(),
            };
            if (checkin) {
                item.checkInTime = shared_grpc_1.dateTimeMessageToDateObject(checkin);
            }
            if (checkout) {
                item.checkOutTime = shared_grpc_1.dateTimeMessageToDateObject(checkout);
            }
            return item;
        });
        return {
            guests: guestList,
            personHash: message.getPersonHash(),
            studentNumber: message.getStudentNumber(),
            email: message.getEmail(),
        };
    }
    IEventTicketProtoMapper.fromEventTicketMessage = fromEventTicketMessage;
    function toEventTicketMessage(ticket) {
        const msg = new event_pb_1.EventTicket();
        if (ticket.personHash) {
            msg.setPersonHash(ticket.personHash);
        }
        if (ticket.guests) {
            for (const guest of ticket.guests) {
                const guestMsg = new event_pb_1.EventGuest();
                guestMsg.setGuestName(guest.guestName);
                if (guest.guestId) {
                    guestMsg.setGuestId(guest.guestId);
                }
                if (guest.checkInTime) {
                    guestMsg.setCheckedIn(shared_grpc_1.dateObjectToDateTimeMessage(guest.checkInTime));
                }
                if (guest.checkOutTime) {
                    guestMsg.setCheckedOut(shared_grpc_1.dateObjectToDateTimeMessage(guest.checkOutTime));
                }
                msg.addGuests(guestMsg);
            }
        }
        return msg;
    }
    IEventTicketProtoMapper.toEventTicketMessage = toEventTicketMessage;
})(IEventTicketProtoMapper = exports.IEventTicketProtoMapper || (exports.IEventTicketProtoMapper = {}));