<mg-bottom-sheet
  [title]="MESSAGES.USER_LIST_MOBILE_TITLE"
  [fullHeight]="true"
  (onEsc)="onEsc()">
  <mg-form-sheet-grouped-select
    [id]="data?.id"
    [value]="data?.initialOptions"
    [disabled]="data?.disabled"
    [options]="data?.options"
    [multiple]="data?.multiple"
    [lazyLoad]="true"
    [clearSelection]="clearSelectionEvent$"
    [commitChanges$]="submitFieldChangesEvent$"
    (commitChanges)="submitBottomSheet($event)"
    (categoryChange)="categoryChange($event)">
  </mg-form-sheet-grouped-select>
  <ng-template #actions>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-btn
        variant="text"
        (pressed)="unselectAll()">
        {{ MESSAGES.USER_LIST_MOBILE_UNSELECT_ALL }}
      </mg-btn>
      <mg-btn
        variant="filled"
        (pressed)="submit()">
        {{ MESSAGES.USER_LIST_MOBILE_SUBMIT }}
      </mg-btn>
    </div>
  </ng-template>
</mg-bottom-sheet>
