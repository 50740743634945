<mg-manager-overlay-reports-subpage
  [loading]="false"
  [scheduleReportsButton]="
    (SUPPPORTED_SCHEDULE_TYPES | includes: getReportType()) &&
    (canScheduleReport$ | async)
  "
  [dataService]="dataService$ | async"
  (scheduleReportsPressed)="onScheduleReport()"
  (exportListPressed)="handleOnClickExport()">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="
        (media.isMobileView$ | async) === false;
        else mobileHeaderTemplate
      ">
      <div class="filters-group">
        <div class="filters-group-row">
          <ng-container *ngTemplateOutlet="reportTypeFieldTemplate">
          </ng-container>
          <ng-container
            *ngIf="
              isReportType(REPORT_TYPES.CHECKIN);
              else dateRangePickerFieldTemplate
            "
            [ngTemplateOutlet]="datePickerFieldTemplate"></ng-container>
          <!-- Filters form -->
          <ng-container
            *ngIf="(media.isMobileView$ | async) === false"
            [ngTemplateOutlet]="filtersFormReference">
          </ng-container>
        </div>
        <div class="filters-group-row">
          <ng-container
            *ngIf="isReportType(REPORT_TYPES.CHECKIN)"
            [ngTemplateOutlet]="absenteeToggleFieldTemplate">
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Filters form reference -->
  <ng-template #filtersFormReference>
    <mg-filters-form
      [data]="filtersFormStructure$ | async"
      [loading]="loading$ | async"
      (state)="updateFilters($event)">
    </mg-filters-form>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Field Template -->
<ng-template #reportTypeFieldTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="285px">
    <mg-form-select
      placeholder="Report Type"
      labelBackground="white"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="getReportType()"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="REPORT_OPTIONS"
      [floatingLabel]="false"
      (selectionChanged)="handleOnChangeReportType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Field Template -->
<ng-template #datePickerFieldTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="250px">
    <mg-date-time-picker
      name="checkindate"
      [dateOnly]="true"
      [required]="true"
      [canReset]="true"
      [hideOptionalMarker]="true"
      [(ngModel)]="startDate"
      (ngModelChange)="handleOnChangeDate($event, true)">
    </mg-date-time-picker>
  </div>
</ng-template>

<!-- Date Picker Field Template -->
<ng-template #dateRangePickerFieldTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- Toggle Button Template -->
<ng-template #absenteeToggleFieldTemplate>
  <mat-button-toggle-group
    name="reportType"
    class="filter-toggle-group"
    [ngClass]="{ disabled: disableAbsentee | async }"
    [disabled]="disableAbsentee | async"
    [value]="type$ | async"
    (change)="handleOnChangeReasonType($event)">
    <mat-button-toggle [value]="REASON_TYPES.CHECKINS">
      <mg-text variant="body-sm-secondary"> Check ins </mg-text>
    </mat-button-toggle>
    <mat-button-toggle [value]="REASON_TYPES.ABSENTEES">
      <mg-text variant="body-sm-secondary"> Absentees </mg-text>
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>

<!-- Mobile Header Template -->
<ng-template #mobileHeaderTemplate>
  <div class="mobile-report-header">
    <ng-container *ngTemplateOutlet="reportTypeFieldTemplate"> </ng-container>
    <ng-container
      *ngIf="
        isReportType(REPORT_TYPES.CHECKIN);
        else dateRangePickerFieldTemplate
      "
      [ngTemplateOutlet]="datePickerFieldTemplate"></ng-container>
    <ng-container
      *ngIf="isReportType(REPORT_TYPES.CHECKIN)"
      [ngTemplateOutlet]="absenteeToggleFieldTemplate"></ng-container>
  </div>
</ng-template>
