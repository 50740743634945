import { Injectable } from '@angular/core';

import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { updateSettings } from 'minga/app/src/app/store/Minga/actions';
import {
  getAllSettings,
  getAllSettingsAsObject,
  getFeatureToggles,
  getModuleEnabled,
  getSettingValue,
} from 'minga/app/src/app/store/Minga/selectors';
import { MingaMinimalModel } from 'minga/libraries/domain';
import {
  IMingaFeatureToggle,
  IMingaFeatureToggleKeys,
} from 'minga/libraries/domain';
import { mingaSettingTypes } from 'minga/libraries/util';
import { MingaManagerService } from 'src/app/services/MingaManager';

import { MingaStoreFacadeService } from './MingaStoreFacade.service';

@Injectable({
  providedIn: 'root',
})
export class MingaSettingsService {
  constructor(
    private _store: Store<any>,
    private _mingaManager: MingaManagerService,
    private _mingaFacade: MingaStoreFacadeService,
  ) {}

  /**
   * Get observable of a setting.
   * @param setting
   */
  public getSettingValueObs(setting: mingaSettingTypes) {
    return this._store.select(getSettingValue(setting));
  }

  /**
   * Get observable of whether a module is enabled or not.
   * @param moduleName
   */
  public getModuleEnabledObs(moduleName: IMingaFeatureToggleKeys) {
    return this._store.select(getModuleEnabled(moduleName));
  }

  /**
   * Get current (synchronous) value of a setting
   * You should prefer to use the observable, because depending on when this is called
   * the value may not be accurate.
   * @param name
   */
  public async getSettingValue(name: mingaSettingTypes) {
    const settings = await this.getAllSettingsAsPromise();
    if (!settings) {
      return false;
    }
    const found = settings?.find(setting => {
      return setting.name === name;
    });

    return found?.value;
  }

  /**
   * Get current (synchronous) value of whether a module is enabled or not.
   * You should prefer to use the observable, because depending on when this is called
   * the value may not be accurate.
   * @param moduleName
   */
  public async getModuleEnabled(moduleName: IMingaFeatureToggleKeys) {
    const mods = await this.getAllFeatureTogglesAsPromise();
    if (!mods) {
      return false;
    }
    return mods[moduleName];
  }

  public getAllSettingsAsObject() {
    return this._store.select(getAllSettingsAsObject);
  }

  /**
   * Convenience Methods for seeing whether a module is enabled.
   */

  public isHallPassModuleEnabled() {
    return this.getModuleEnabledObs(IMingaFeatureToggleKeys.HALLPASS_ENABLED);
  }
  public isPbisModuleEnabled() {
    return this.getModuleEnabledObs(IMingaFeatureToggleKeys.TRACKING_ENABLED);
  }
  public isIdModuleEnabled() {
    return this.getModuleEnabledObs(IMingaFeatureToggleKeys.STUDENT_ID_ENABLED);
  }
  public isDmModuleEnabled() {
    return this.getModuleEnabledObs(IMingaFeatureToggleKeys.DM_ENABLED);
  }
  public isCheckinModuleEnabled() {
    return this.getModuleEnabledObs(IMingaFeatureToggleKeys.CHECKIN_ENABLED);
  }
  public isCommunityModuleEnabled() {
    return this.getModuleEnabledObs(IMingaFeatureToggleKeys.COMMUNITY_ENABLED);
  }
  public isFlexTimeModuleEnabled() {
    return this.getModuleEnabledObs(IMingaFeatureToggleKeys.FLEX_TIME_ENABLED);
  }
  public isPhotoGalleryModuleEnabled() {
    return this.getModuleEnabledObs(
      IMingaFeatureToggleKeys.PHOTO_GALLERY_ENABLED,
    );
  }
  /**
   * Update a minga setting for the current minga.
   */
  public async updateSetting(
    key: mingaSettingTypes,
    value: string | boolean | string[],
  ) {
    const result = await this._mingaManager.updateUserMingaSettings(
      key,
      JSON.stringify(value),
    );
    this.updateSettingStore(key, value);
  }
  /**
   * Update a minga setting in the minga ngrx store for the current minga.
   */
  public async updateSettingStore(
    key: mingaSettingTypes,
    value: string | boolean | string[],
  ) {
    const mingaInfo = await this._mingaFacade.getMingaAsPromise();
    if (!mingaInfo.settings) {
      return;
    }
    const settings = [...mingaInfo.settings];
    const findIndex = settings?.findIndex(setting => setting.name == key);
    if (findIndex < 0 && settings) {
      settings.push({ name: key, value });
    } else if (settings && findIndex) {
      settings[findIndex] = { name: key, value };
    }
    const update: Update<MingaMinimalModel> = {
      id: mingaInfo.hash,
      changes: { settings },
    };
    this._store.dispatch(updateSettings({ payload: update }));
  }

  /**
   * Update a feature toggle (module) for the current minga.
   */
  public async updateFeatureToggle(
    key: IMingaFeatureToggleKeys,
    value: boolean,
  ) {
    const mingaInfo = await this._mingaFacade.getMingaAsPromise();
    if (!mingaInfo.featureToggle) {
      return;
    }
    const featureToggle = mingaInfo.featureToggle;
    featureToggle[key] = value;
    const result = await this._mingaManager.updateUserMingaFeatureToggles(
      featureToggle,
    );
    this.updateFeatureToggleStore(featureToggle);
  }
  /**
   * Update a feature toggle (module) for the current minga in the ngrx store.
   */
  public async updateFeatureToggleStore(featureToggle: IMingaFeatureToggle) {
    const mingaInfo = await this._mingaFacade.getMingaAsPromise();

    const update: Update<MingaMinimalModel> = {
      id: mingaInfo.hash,
      changes: { featureToggle },
    };
    this._store.dispatch(updateSettings({ payload: update }));
  }

  /*****
   * Private methods.
   */

  /**
   * Get all the settings in the store as a promise.
   */
  private async getAllSettingsAsPromise() {
    return this._store
      .select(getAllSettings)
      .pipe(
        filter(minga => minga != null),
        take(1),
      )
      .toPromise();
  }

  /**
   * Get the feature toggles from the store as a promise.
   */
  private async getAllFeatureTogglesAsPromise() {
    return this._store
      .select(getFeatureToggles)
      .pipe(
        filter(minga => minga != null),
        take(1),
      )
      .toPromise();
  }
}
