import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UserListCategory } from '@shared/components/user-list-filter';
import { MediaService } from '@shared/services/media';

import {
  FORM_FIELDS,
  LOCKED_STATES,
  MyClassMessages,
} from '../../constants/tt-my-class.constants';
import { FormState } from '../../types/tt-my-class.types';

@Component({
  selector: 'mg-tt-filters',
  templateUrl: './tt-filters.component.html',
  styleUrls: ['./tt-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtFiltersComponent {
  @Input() form: FormGroup;
  @Input() searchingAllStudents = false;
  @Input() formState: FormState;
  @Input() totalNumberOfInClassStudents: number;
  @Input() selectedNumberOfStudents: number;

  @Output() userListChanged = new EventEmitter<number[] | null>();
  @Output() selectRandom = new EventEmitter();
  @Output() toggleSelectAll = new EventEmitter();

  public MESSAGES = MyClassMessages;
  public FORM_FIELDS = FORM_FIELDS;
  public UserListCategory = UserListCategory;
  public LOCKED_STATES: FormState[] = LOCKED_STATES;

  constructor(public mediaService: MediaService) {}

  public onUserListChange(val: number[] | null): void {
    this.userListChanged.emit(val);
  }

  public onSelectRandom(): void {
    this.selectRandom.emit();
  }

  public onToggleSelectAll(): void {
    this.toggleSelectAll.emit();
  }
}
