<mg-legacy-overlay-nav
  [component]="this"
  [showTopNav]="showTopNav">
</mg-legacy-overlay-nav>

<mg-long-card-base [component]="this">
  <div
    class="event-management-container"
    *ngIf="canManageEvent && contextHash && eventManagementCounts$"
    fxLayout="column">
    <div
      *ngIf="isCheckinEnabled$ | async"
      class="event-management-btns-container">
      <mg-text variant="body-md">{{ MESSAGES.EVENT_MANAGEMENT }}</mg-text>
      <div class="event-management-btns">
        <mg-btn (pressed)="launchCheckInSelector()">
          {{ MESSAGES.CHECK_IN }}
        </mg-btn>
        <mg-btn
          variant="outlined"
          (pressed)="launchCheckOutSelector()">
          {{ MESSAGES.CHECK_OUT }}
        </mg-btn>
      </div>
    </div>

    <div
      *ngIf="canAccessManagerList"
      class="event-manager-list">
      <mg-membership-list-table
        [hideHeader]="true"
        [autoCreate]="true"
        [lists]="lists"
        (listUpdated)="reloadLists()"></mg-membership-list-table>
    </div>

    <div
      gdGridAlign="center end"
      class="event-analytics-container"
      *ngIf="eventManagementCounts$ | async as counts">
      <div
        class="event-analytic invited-count"
        (click)="!isEventOver() && launchInvite()"
        [class.one-row]="(isCheckinEnabled$ | async) === false"
        [class.in-active]="isEventOver()">
        <mg-icon>mg-invite</mg-icon>
        <p
          class="analytic-count"
          *ngIf="counts.inviteCount === 0">
          0
        </p>
        <p
          class="analytic-title"
          *ngIf="counts.inviteCount === 0">
          {{ MESSAGES.INVITED }}
        </p>
        <p
          class="analytic-count"
          *ngIf="counts.inviteCount > 0">
          {{ counts.responseCount }} / {{ counts.inviteCount }}
        </p>
        <p
          class="analytic-title"
          *ngIf="counts.inviteCount > 0">
          {{ MESSAGES.RESPONDED }}
        </p>
      </div>
      <div
        class="event-analytic going-count"
        [class.one-row]="(isCheckinEnabled$ | async) === false"
        (click)="analyticsIconClicked(EventStatus.GOING)">
        <mg-icon>mg-user-list</mg-icon>
        <p class="analytic-count">{{ counts.goingCount }}</p>
        <p class="analytic-title">{{ MESSAGES.GOING }}</p>
      </div>
      <div
        class="event-analytic ticket-count"
        [class.one-row]="(isCheckinEnabled$ | async) === false"
        [class.in-active]="!content.hasTickets"
        (click)="content.hasTickets && launchEventTicketsList()">
        <mg-icon>mg-event-ticket</mg-icon>
        <p class="analytic-count">{{ counts.ticketCount }}</p>
        <p class="analytic-title">{{ MESSAGES.TICKETS }}</p>
      </div>
      <div
        *ngIf="isCheckinEnabled$ | async"
        class="event-analytic checkedin-count"
        (click)="analyticsIconClicked(EventStatus.CHECKED_IN)">
        <mg-icon>mg-check-in</mg-icon>
        <p class="analytic-count">{{ counts.checkinCount }}</p>
        <p class="analytic-title">{{ MESSAGES.CHECKED_IN }}</p>
      </div>
      <div
        *ngIf="isCheckinEnabled$ | async"
        class="event-analytic checkedout-count"
        (click)="analyticsIconClicked(EventStatus.CHECKED_OUT)">
        <mg-icon>mg-check-out</mg-icon>
        <p class="analytic-count">{{ counts.checkoutCount }}</p>
        <p class="analytic-title">{{ MESSAGES.CHECKED_OUT }}</p>
      </div>
      <div
        *ngIf="isCheckinEnabled$ | async"
        class="event-analytic manager-count"
        (click)="analyticsIconClicked(EventStatus.NOT_CHECKED_IN)">
        <mg-icon>mg-not-checked-in</mg-icon>
        <p class="analytic-count">{{ counts.notCheckedInCount }}</p>
        <p class="analytic-title">{{ MESSAGES.NOT_CHECKED_IN }}</p>
      </div>
    </div>
  </div>

  <ng-container *ngIf="eventStatus$">
    <div
      class="invited-banner"
      [class.expand]="(eventStatus$ | async) === 'INVITED'">
      <img src="assets/event/event-invited.svg" /> You’re Invited!
    </div>
  </ng-container>

  <div
    class="event-image-wrap"
    #eventImageWrap
    *ngIf="content.image">
    <div
      class="date-container"
      [class.cancelled-text]="content.cancelled">
      <ng-container *ngIf="!isEventOver(); else pastDate">
        <p class="date-day-week">
          {{ getStartDateComponent({ weekday: 'long' }) }}
        </p>
      </ng-container>
      <p class="date-month">{{ getStartDateComponent({ month: 'short' }) }}</p>
      <p class="date-day {{ longtime }}">{{ time }}</p>
      <div class="date-divider"></div>
      <ng-container *ngIf="!content.allDay; else allDay">
        <p class="date-time">{{ getTime(content.startTimestamp) }}</p>
      </ng-container>
    </div>
    <ng-template #pastDate>
      <p class="past-event-text">PAST EVENT</p>
    </ng-template>
    <ng-container *ngIf="content.points && content.points > 0">
      <mg-points-banner [points]="content.points"></mg-points-banner>
    </ng-container>
    <mg-image
      class="event-image"
      [srcs]="content?.image | mgImageUrls: bannerImageSizes">
    </mg-image>
  </div>

  <div
    *ngIf="isCheckinEnabled$ | async"
    class="event-info-container content-hpadding">
    <div class="title">
      <span [innerHTML]="content.title | mgSafeHtml"></span>
    </div>
    <div class="location-ticket-row">
      <div
        fxLayout="row"
        fxLayoutAlign="start center">
        <ng-container *ngIf="content.location">
          <mg-icon class="location-icon">location-icon</mg-icon>
          <p class="location">{{ content.location }}</p>
        </ng-container>
      </div>
      <div
        fxLayout="row"
        class="ticket-price-container">
        <ng-container *ngIf="content.ticketPrice">
          <p class="ticket-text">TICKETS</p>
          <div class="ticket-price-icon"></div>
          <p class="ticket-price">
            {{ content.ticketPrice | currency: 'USD' }}
          </p>
        </ng-container>
      </div>
    </div>
    <div class="time">
      <mat-icon>schedule</mat-icon>
      <ng-container *ngIf="!content.allDay; else allDay">
        <p>
          {{ getTime(content.startTimestamp) }} to
          {{ getTime(content.endTimestamp) }}
        </p>
      </ng-container>
      <ng-template #allDay><p class="all-day-text">All Day</p></ng-template>
    </div>
    <div
      class="hosted-by mg-minicopy"
      *ngIf="content.hostedBy"
      [innerHTML]="content.hostedBy | mgSafeHtml"></div>

    <ng-container
      *ngIf="content.allowGoing && !content.cancelled && contextHash">
      <div
        class="event-status"
        *ngIf="!content.cancelled; else cancelled"
        [class.event-over]="isEventOver()">
        <mg-event-going-state
          [content]="content"
          [context]="contextHash">
        </mg-event-going-state>
      </div>
    </ng-container>

    <div
      class="add-to-cal"
      [class.disabled]="addingEventToCalendaar"
      (click)="addEventToCalendar()">
      <span>Add to Calendar</span><mat-icon>east</mat-icon>
    </div>

    <ng-template #cancelled>
      <div class="cancelled-wrapper">
        <p class="cancelled">Event Cancelled</p>
      </div>
    </ng-template>
    <div
      class="group-link-container"
      (click)="groupLinkClick($event)"
      *ngIf="content.ownerGroupHash">
      <span class="group-icon"></span>
      <span class="group-link-text"
        >Posted in
        <span class="group-link">{{ (group$ | async)?.name }}</span>
      </span>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="8px"
    class="ticket-container"
    *ngIf="content.hasTickets"
    [class.linkable]="!!content.ticketUrl"
    (click)="openBuyTicketUrl()">
    <mg-text variant="header-sm">This event requires a ticket</mg-text>
    <ng-container *ngIf="content.ticketInfo">
      <mg-text
        variant="body-sm"
        [innerHTML]="
          content.ticketInfo | ugcLinksContent | mgSafeHtml
        "></mg-text>
    </ng-container>
    <mg-btn
      *ngIf="content.ticketUrl"
      size="small"
      iconSet="minga"
      icon="mg-event-ticket">
      Buy now
    </mg-btn>
  </div>

  <div
    *ngIf="content.authorPersonView"
    class="content-block byline event">
    <div
      class="author-avatar-wrap"
      [class.archived-true]="content.authorPersonView?.archived">
      <mg-avatar
        [src]="content?.authorPersonView?.profileImageUrl"
        [color]="content?.authorPersonView?.badgeIconColor"
        [personHash]="content?.authorPersonView?.personHash"></mg-avatar>
    </div>
    <div
      class="byline-info"
      fxFlex>
      <mg-person-minimal
        [person]="content.authorPersonView"></mg-person-minimal>
      <div
        class="timestamp"
        [class.archived-true]="content.authorPersonView?.archived">
        {{ humanize(content.timestamp) }}
      </div>
    </div>
    <div *ngIf="contextHash">
      <mg-ellipsis
        [mgContentContextMenuClick]="{
          contentContextHash: contextHash,
          overlay: true
        }"></mg-ellipsis>
    </div>
  </div>

  <div
    class="content-block body-content mg-bodycopy"
    [innerHTML]="content.fullBody | ugcLinksContent | mgSafeHtml"></div>

  <ng-container
    *ngIf="(isPhotoGalleryEnabled$ | async) && contentMinimal && contentHash">
    <div class="divider content-hpadding"></div>

    <p class="gallery-title mg-smallcopy">EVENT GALLERY</p>

    <mg-gallery-photo-event-stream
      class="content-hpadding"
      [contentHash]="contentHash"
      [contextHash]="contextHash"
      (galleryPhotoClick)="
        gotoEventGallery($event)
      "></mg-gallery-photo-event-stream>
  </ng-container>
</mg-long-card-base>
