import { EventStatus } from 'minga/libraries/domain';
import { EventReportFilters } from 'minga/libraries/domain';
import { EventMessages } from 'src/app/events';

export enum EventReportFiltersKeys {
  EVENT_CONTEXT = 'eventContext',
  PERSON_STATUS = 'personStatus',
}

export const EVENT_REPORT_FILTER_INITIAL_STATE: EventReportFilters = {
  eventContext: '',
  personStatus: EventStatus.GOING,
};

export const EVENT_REPORT_FILTER_NON_ARRAY_TYPES = [
  EventReportFiltersKeys.EVENT_CONTEXT,
  EventReportFiltersKeys.PERSON_STATUS,
];

export const EVENT_STATUS_OPTIONS = [
  { label: EventMessages.GOING, value: EventStatus.GOING },
  { label: EventMessages.INTERESTED, value: EventStatus.INTERESTED },
  { label: EventMessages.INVITED, value: EventStatus.INVITED },
  { label: EventMessages.CHECKED_IN, value: EventStatus.CHECKED_IN },
  { label: EventMessages.CHECKED_OUT, value: EventStatus.CHECKED_OUT },
  { label: EventMessages.CANT_GO, value: EventStatus.NOT_GOING },
  { label: EventMessages.TICKETED, value: EventStatus.TICKETED },
  { label: EventMessages.NOT_CHECKED_IN, value: EventStatus.NOT_CHECKED_IN },
];
