var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMingaType = void 0;
const util_1 = require("minga/libraries/util/index");
const MingaConferenceMingaType_1 = require("minga/libraries/shared/minga_type/internal/MingaConferenceMingaType");
const MingaSchoolMingaType_1 = require("minga/libraries/shared/minga_type/internal/MingaSchoolMingaType");
const mingaTypeMap = {
    [util_1.MingaTypeValue.CONFERENCE]: new MingaConferenceMingaType_1.MingaConferenceMingaType(),
    [util_1.MingaTypeValue.MINGA]: new MingaSchoolMingaType_1.MingaSchoolMingaType(),
};
function getMingaType(mingaType) {
    if (mingaTypeMap[mingaType]) {
        return mingaTypeMap[mingaType];
    }
    throw new Error(`Minga type '${mingaType}' does not exist`);
}
exports.getMingaType = getMingaType;
__exportStar(require("minga/libraries/shared/minga_type/interface"), exports);
__exportStar(require("minga/libraries/shared/minga_type/internal/index"), exports);