"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractScheduleInfo = void 0;
exports.extractScheduleInfo = (schedule) => {
    var _a, _b;
    const sisPeriod = {
        shortCode: '',
        startTime: '',
        endTime: '',
    };
    if (schedule) {
        // Regular expression to match 12-hour format with AM/PM or 24-hour format without AM/PM
        const timeMatch = schedule.match(/^(.*?)\s\((\d{1,2}:\d{2})(?:\s([APMapm]{2}))?\s*-\s*(\d{1,2}:\d{2})(?:\s([APMapm]{2}))?\)$/);
        const shortCodeMatch = schedule.match(/^(.*?)(?:-\d+[A-Za-z]?)?$/);
        if (timeMatch) {
            const shortCode = timeMatch[1];
            let startTime = timeMatch[2];
            const startPeriod = (_a = timeMatch[3]) === null || _a === void 0 ? void 0 : _a.toUpperCase();
            let endTime = timeMatch[4];
            const endPeriod = (_b = timeMatch[5]) === null || _b === void 0 ? void 0 : _b.toUpperCase();
            // Convert startTime and endTime to 24-hour format only if they are in 12-hour format
            if (startPeriod) {
                startTime = convertTo24HourFormat(startTime, startPeriod);
            }
            if (endPeriod) {
                endTime = convertTo24HourFormat(endTime, endPeriod);
            }
            sisPeriod.shortCode = shortCode;
            sisPeriod.startTime = startTime;
            sisPeriod.endTime = endTime;
        }
        else if (shortCodeMatch) {
            // Extract details when no time is present
            sisPeriod.shortCode = shortCodeMatch[0];
        }
        else {
            throw new Error('Invalid schedule format');
        }
    }
    return sisPeriod;
};
// Helper function to convert 12-hour format time to 24-hour format
const convertTo24HourFormat = (time, period) => {
    const splitTime = time.split(':').map(Number);
    let hours = splitTime[0];
    const minutes = splitTime[1];
    if (period === 'PM' && hours !== 12) {
        hours += 12;
    }
    else if (period === 'AM' && hours === 12) {
        hours = 0;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
};