"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MingaMinimalProtoMapper = void 0;
/* eslint-disable @typescript-eslint/no-namespace */
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const domain_2 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const minga_pb_1 = require("minga/proto/gateway/minga_pb");
const feature_toggle_1 = require("minga/libraries/shared-grpc/feature_toggle/index");
const sis_1 = require("minga/libraries/shared-grpc/sis/index");
var MingaMinimalProtoMapper;
(function (MingaMinimalProtoMapper) {
    function mingaMinimalToMingaMinimalProto(minga) {
        var _a;
        const minimal = new minga_pb_1.MingaInfoMinimal();
        minimal.setMingaHash(minga.hash);
        minimal.setMingaLogo(minga.logo || '');
        minimal.setMingaName(minga.name);
        minimal.setPaused(minga.paused);
        minimal.setMingaType(minga.mingaType);
        (_a = minga.settings) === null || _a === void 0 ? void 0 : _a.forEach(row => {
            const setting = new minga_pb_1.MingaSetting();
            setting.setKeyName(row.name);
            setting.setValue(JSON.stringify(row.value));
            minimal.addSettings(setting);
        });
        if (minga.featureToggle)
            minimal.setFeatureToggle(feature_toggle_1.MingaFeatureToggleMapper.toProto(minga.featureToggle));
        // @TODO: remove sis_type because it's redundant
        if (minga.sisType)
            minimal.setSisType(minga.sisType);
        if (minga.sisSettings) {
            minimal.setSisSettings(sis_1.SisSettingsMapper.toProto(minga.sisSettings));
        }
        if (minga.hubspotId) {
            minimal.setHubspotId(minga.hubspotId);
        }
        if (minga.districtId) {
            minimal.setDistrictId(minga.districtId);
        }
        return minimal;
    }
    MingaMinimalProtoMapper.mingaMinimalToMingaMinimalProto = mingaMinimalToMingaMinimalProto;
    function mingaMinimalProtoToMingaMinimal(minga) {
        const mingaType = util_1.stringToMingaTypeEnum(minga.getMingaType());
        const settingsList = minga.getSettingsList();
        const featureToggle = minga.getFeatureToggle();
        const hubspotId = minga.getHubspotId();
        const districtId = minga.getDistrictId();
        const settings = [];
        settingsList.forEach(setting => {
            try {
                settings.push({
                    name: setting.getKeyName(),
                    value: JSON.parse(setting.getValue()),
                });
            }
            catch (e) {
                console.log(e, setting);
            }
        });
        // @TODO: remove sis_type because it's redundant
        let sisType;
        if (minga.getSisType()) {
            sisType = ts_enum_util_1.$enum(domain_1.SisConfig.SisType).asValueOrDefault(minga.getSisType());
            if (!sisType)
                sisType = domain_2.RosteringMethod.SFTP;
        }
        let sisSettings;
        const sisSettingsProto = minga.getSisSettings();
        if (sisSettingsProto) {
            sisSettings = sis_1.SisSettingsMapper.fromProto(sisSettingsProto);
        }
        return {
            hash: minga.getMingaHash(),
            logo: minga.getMingaLogo(),
            name: minga.getMingaName(),
            paused: minga.getPaused(),
            mingaType,
            settings,
            featureToggle: featureToggle.toObject(),
            sisType,
            sisSettings,
            hubspotId,
            districtId,
        };
    }
    MingaMinimalProtoMapper.mingaMinimalProtoToMingaMinimal = mingaMinimalProtoToMingaMinimal;
    function MingaProtoToMingaMinimal(minga, settingsList, featureToggle) {
        const mingaType = util_1.stringToMingaTypeEnum(minga.getMingaType());
        const settings = [];
        settingsList.forEach(setting => {
            settings.push({
                name: setting.getKeyName(),
                value: JSON.parse(setting.getValue()),
            });
        });
        return {
            hash: minga.getHash().getValue(),
            logo: minga.getImageUrl(),
            name: minga.getName(),
            paused: minga.getPaused(),
            mingaType,
            settings,
            featureToggle: featureToggle === null || featureToggle === void 0 ? void 0 : featureToggle.toObject(),
        };
    }
    MingaMinimalProtoMapper.MingaProtoToMingaMinimal = MingaProtoToMingaMinimal;
})(MingaMinimalProtoMapper = exports.MingaMinimalProtoMapper || (exports.MingaMinimalProtoMapper = {}));