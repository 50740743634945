import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { get } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';

import { MingaHealthScoreType } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { MingaSubscriptionPlans } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import {
  MmDashboardTableColumn,
  MmDashboardTableColumnToMessage,
  MmDashboardTableMessages,
} from '../../constants';
import {
  MmDashboardFiltersService,
  MmDashboardService,
  MmDashboardTableService,
} from '../../services';
import {
  MmDashboardFilters,
  MmDashboardMingaInfo,
} from '../../types/mm-dashboard.types';

/**
 * Minga Manager Dashboard Table
 *
 * @link https://v9.material.angular.io/components/table/overview
 * @link https://v9.material.angular.io/components/sort/overview
 */
@Component({
  selector: 'mg-mm-dashboard-table',
  templateUrl: './mm-dashboard-table.component.html',
  styleUrls: ['./mm-dashboard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class MmDashboardTableComponent implements AfterViewInit, OnDestroy {
  /** Mat Table API */
  @ViewChild(MatTable) table: MatTable<MmDashboardMingaInfo>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  /** Constants */
  public readonly MESSAGES = MmDashboardTableMessages;
  public readonly TABLE_COLUMNS = MmDashboardTableColumn;
  public readonly COLUMNS_TO_MESSAGES = MmDashboardTableColumnToMessage;

  /** General Observables */
  private readonly _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;
  public readonly mediaSubscription$: Subscription;
  public readonly isSuperAdmin$ = this._permissionsService.observePermission(
    MingaPermission.SUPERADMIN,
  );

  /** Mat Table Data */
  public readonly dataSource = new MatTableDataSource<MmDashboardMingaInfo>([]);
  private readonly _filteredData$ = new BehaviorSubject<MmDashboardMingaInfo[]>(
    [],
  );
  public readonly filteredData$ = this._filteredData$
    .asObservable()
    .pipe(shareReplay());
  public columns: string[];
  get tableMinWidth() {
    const modCol = 8;
    const statCol = this.columns.length ? this.columns.length - modCol : 0;
    const minWidth = statCol * 140 + modCol * 80;
    return {
      'min-width': `${minWidth}px`,
    };
  }

  /** Expended Row */
  expandedRow;

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public tableService: MmDashboardTableService,
    public dashboardService: MmDashboardService,
    public _dashboardFilters: MmDashboardFiltersService,
    private _permissionsService: PermissionsService,
  ) {
    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );
    combineLatest([
      this._dashboardFilters.filters$,
      this.dashboardService.data$,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([filters, mingas]) => {
        this.dataSource.data = mingas;
        this._applyFilters(filters);
      });
    this.tableService.displayedColumn$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(x => (this.columns = x));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this._tableFilter;
    this.dataSource.sortingDataAccessor = get;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public trackById(index: number, item: MmDashboardMingaInfo) {
    if (!item) return index;
    return item.hash;
  }

  private _applyFilters(filters: MmDashboardFilters) {
    this.dataSource.filter = JSON.stringify({
      ...filters,
    });
    this._filteredData$.next(this.dataSource.filteredData);
  }

  private _tableFilter(minga: MmDashboardMingaInfo, filters: string) {
    const {
      searchQuery,
      module,
      district,
      partner,
      state: mingaState,
      status,
      subscription,
      paused,
    } = JSON.parse(filters) as MmDashboardFilters;

    const filterName = searchQuery.length
      ? minga?.name.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    let filterModules = false;
    if (module.length) {
      if (minga.featureToggle) {
        filterModules = true;
        for (const key of module) {
          if (!minga.featureToggle[key]) {
            filterModules = false;
            break;
          }
        }
      }
    } else {
      filterModules = true;
    }

    const filterDistricts = district.length
      ? district.includes(minga.district)
      : true;

    const filterPartners = partner.length
      ? partner.includes(minga.partner)
      : true;

    const filterState = mingaState.length
      ? mingaState.includes(minga.state)
      : true;

    const filterStatus = status.length
      ? status.includes(minga.status as any)
      : true;

    let filterPaused = true;
    if (paused.length) {
      filterPaused = paused.includes(minga.paused.toString());
    }

    let filterSubscription = true;
    if (subscription.length) {
      if (minga.subscription) {
        if (subscription.includes('paid')) {
          filterSubscription = subscription.includes(
            MingaSubscriptionPlans.SCHOOL_FREE,
          )
            ? true
            : minga.subscription.planId !== MingaSubscriptionPlans.SCHOOL_FREE;
        } else {
          filterSubscription = subscription.includes(minga.subscription.planId);
        }
      } else {
        filterSubscription = false;
      }
    }

    return [
      filterName,
      filterModules,
      filterDistricts,
      filterPartners,
      filterState,
      filterStatus,
      filterSubscription,
      filterPaused,
    ].every(v => v);
  }

  public async onClickViewMore(row): Promise<void> {
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  public getFilteredData() {
    return this.dataSource.filteredData;
  }

  public getColumns() {
    return this.columns;
  }

  public getScoreClass(score?: MingaHealthScoreType) {
    if (!score?.quality) return {};
    return { [`${score.quality.toLowerCase()}-score`]: true };
  }
}
