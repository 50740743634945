import { Validators } from '@angular/forms';

import { IBellSchedule } from 'minga/libraries/domain';

import * as formUtils from '@shared/components/crud-form-base/crud-form-base.utils';
import { FormConfig } from '@shared/components/crud-form-base/crud-form-base.types';

export enum SCHEDULES_MESSAGES {
  HEADER = 'Schedules',
  ADD_BTN = 'Create schedule',
  NO_TERMS = 'Create your first term to use your schedules',
  NO_TERMS_BUTTON = 'Go to term',
  NO_RESULTS_TITLE = 'No bell schedule found',
  NO_RESULTS_SUBTITLE = 'Your bell schedules will be displayed here',
  SCHEDULE_ERRORS = 'We detected some errors with your bell schedules.',
  SCHEDULE_ERRORS_BUTTON = 'View Errors',

  MODAL_TITLE_ADD = 'Create schedule',
  MODAL_TITLE_EDIT = 'Edit schedule',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_CREATING = 'Creating',
  BUTTON_LABEL_UPDATE = 'Save',
  BUTTON_LABEL_UPDATING = 'Saving',

  ERROR_GENERIC = 'Please complete the missing information',

  FIELD_LABEL_NAME = 'Bell schedule name',
  FIELD_LABEL_TERM = 'Term',
  FIELD_PERIOD_SHORT_CODE = 'Short Code',
  FIELD_PERIOD_NAME = 'Period Name',
  FIELD_PERIOD_START_DATE = 'Start Date',
  FIELD_PERIOD_END_DATE = 'End Date',
  FIELD_PERIOD_TOOLTIP = 'Must match your SIS',
  FIELD_SELECT_NOT_FOUND = 'No term created',

  FIELD_PERIOD_SHORT_CODE_ADD = 'Add',

  FIELDSET_DEFAULT_DAYS_TITLE = 'Default days',
  FIELDSET_DEFAULT_DAYS_DESC = 'Select which day(s) will be assigned this schedule',
  FIELDSET_DEFAULT_DAYS_TOOLTIP = 'Only one scheduled can be assigned per day',

  FIELDSET_TERMS_TITLE = 'Apply to terms',
  FIELDSET_TERMS_DESC = 'Select which term(s) will be using this schedule',
  FIELDSET_TERMS_TOOLTIP = 'Select which term(s) will be using this schedule',

  PERIOD_NAME_ERROR = 'Period name is required',
  SHORT_CODE_ERROR = 'Short code is required',

  ADD_PERIOD_BTN = 'Add Period',

  MOBILE_PERIOD_HEADER = 'Period short code must match your SIS',
  PERIOD_TABLE_SHORT_CODE = 'Short Code',
  PERIOD_TABLE_PERIOD = 'Period Name',
  PERIOD_TABLE_START_TIME = 'Start Time',
  PERIOD_TABLE_END_TIME = 'End Time',

  DAYS_ALREADY_USED_ERROR = 'One or more days are already being used in the term(s) applied',
  SELECT_DAY_ERROR = 'You must select at least 1 day',

  UPDATE_ERROR = 'There was an error updating the schedule',
  CREATE_ERROR = 'There was an error creating the schedule',
  DELETE_ERROR = 'There was an error deleting the schedule',
  GET_ERROR = 'There was an problem fetching the schedule',

  ERROR_LOADING_DATA = 'There was an issue loading schedules',
  ERROR_DUPLICATING_SCHEDULE = 'There was an issue duplicating schedule',

  DELETE_CONFIRM_TITLE = "Are you sure you want to delete this schedule, this can't be undone!",
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this schedule?',
  DELETE_CONFIRM_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',

  SNACKBAR_CREATE_SUCCESS = 'Schedule created',
  SNACKBAR_UPDATE_SUCCESS = 'Schedule updated',
  SNACKBAR_DELETE_SUCCESS = 'Schedule deleted',
  SNACKBAR_DUPLICATED_SUCCESS = 'Schedule duplicated',

  COL_NAME = 'Schedule Name',
  COL_DEFAULT_DAYS = 'Default Days',
  COL_TERMS = 'Terms',
  COL_DUPLICATE = 'Duplicate',
  COL_EDIT = 'Edit',
}

export enum SCHEDULE_FORM_FIELDS {
  NAME = 'name',
  TERMS = 'terms',
  PERIODS = 'periods',
  DAYS = 'days',
}

export enum SCHEDULE_PERIOD_FIELDS {
  ID = 'id',
  SHORT_CODE = 'shortCode',
  NAME = 'periodName',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

const FORM: FormConfig<
  // form helpers dont have support for nested form arrays yet
  Exclude<SCHEDULE_FORM_FIELDS, SCHEDULE_FORM_FIELDS.PERIODS>,
  IBellSchedule
> = {
  [SCHEDULE_FORM_FIELDS.NAME]: [null, [Validators.required]],
  [SCHEDULE_FORM_FIELDS.DAYS]: [[]],
  [SCHEDULE_FORM_FIELDS.TERMS]: {
    key: SCHEDULE_FORM_FIELDS.TERMS,
    formControl: [null, []],
    set: data => (data.terms || []).map(term => term.id),
  },
};

export const createForm = formUtils.createForm(FORM);
export const setForm = formUtils.setForm(FORM);
export const getData = formUtils.getData(FORM);

export enum ScheduleTableColumn {
  NAME = 'name',
  DEFAULT_DAYS = 'defaultDays',
  TERMS = 'terms',
  DUPLICATE = 'duplicate',
  EDIT = 'edit',
  MOBILE = 'mobile',
}

export const SCHEDULE_TABLE_COLUMNS: string[] = [
  ScheduleTableColumn.NAME,
  ScheduleTableColumn.DEFAULT_DAYS,
  ScheduleTableColumn.TERMS,
  ScheduleTableColumn.DUPLICATE,
  ScheduleTableColumn.EDIT,
];
