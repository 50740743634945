"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const checkin_pb_1 = require("minga/proto/checkin/checkin_pb");
exports.toProto = (filter, limit, offset) => {
    const filterMessage = new checkin_pb_1.CheckInReportFilters();
    filterMessage.setOffset(offset || 0);
    if (limit)
        filterMessage.setLimit(limit);
    if (filter.startDate)
        filterMessage.setStartDate(shared_grpc_1.dateObjectToDateTimeMessage(filter.startDate.toDate()));
    if (filter.endDate)
        filterMessage.setEndDate(shared_grpc_1.dateObjectToDateTimeMessage(filter.endDate.toDate()));
    if (filter.reason)
        filterMessage.setCheckinReasonIdsList(filter.reason);
    filterMessage.setGradeList(filter.grade);
    if (filter.issuedBy)
        filterMessage.setIssuedByList(filter.issuedBy);
    if (filter.issuedTo)
        filterMessage.setIssuedToList(filter.issuedTo);
    if (filter.role)
        filterMessage.setRolesList(filter.role);
    filterMessage.setBypassLenCheck(true);
    filterMessage.setUserListList(filter.userList);
    return filterMessage;
};
exports.fromProto = (proto) => {
    const startDateProto = proto.getStartDate();
    const endDateProto = proto.getEndDate();
    let startDate;
    let endDate;
    if (startDateProto)
        startDate = shared_grpc_1.dateTimeMessageToDayjsObject(startDateProto);
    if (endDateProto)
        endDate = shared_grpc_1.dateTimeMessageToDayjsObject(endDateProto);
    return {
        startDate,
        endDate,
        reason: proto.getCheckinReasonIdsList(),
        grade: proto.getGradeList(),
        issuedTo: proto.getIssuedToList(),
        issuedBy: proto.getIssuedByList(),
        role: proto.getRolesList(),
        limit: proto.getLimit(),
        offset: proto.getOffset(),
        bypassLenCheck: proto.getBypassLenCheck(),
        userList: proto.getUserListList(),
    };
};