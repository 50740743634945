import { Injectable } from '@angular/core';

import * as pbis_proto from 'minga/proto/pbis/pbis_pb';
import { UpdateConsequences, IConsequence } from 'minga/libraries/domain';
import {
  ConsequenceMapper,
  UpdateConsequenceMapper,
} from 'minga/libraries/shared-grpc';
import { PbisManager } from 'minga/proto/pbis/pbis_ng_grpc_pb';

@Injectable({ providedIn: 'root' })
export class ConsequenceService {
  constructor(private _pbisManager: PbisManager) {}

  public async createConsequences(
    consequences: UpdateConsequences,
    listId?: number,
  ): Promise<IConsequence[]> {
    const request = new pbis_proto.CreateConsequenceRequest();

    request.setConsequences(UpdateConsequenceMapper.toProto(consequences));
    request.setListId(listId);

    const result = await this._pbisManager.createConsequences(request);
    return result
      .getConsequenceList()
      .map(msg => ConsequenceMapper.fromProto(msg));
  }
}
