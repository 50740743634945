import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import * as day from 'dayjs';
import { ReplaySubject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { PbisNameCategory } from 'minga/libraries/domain';

import { SelectionAssignerService } from '@modules/selection-assigner/services';
import { PBIS_CHECKIN_TYPES } from '@modules/teacher-tools/constants/tt-view.constants';

import { PbisService } from '../../../../shared/services/pbis/Pbis.service';
import { BehaviorAssignmentLabels } from '../../constants/sa-behavior.constants';

@Component({
  selector: 'mg-sa-behavior',
  templateUrl: './sa-behavior.component.html',
  styleUrls: ['./sa-behavior.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaBehaviorComponent implements OnDestroy {
  /** Constants */
  public readonly ASSIGNMENT_LABELS = BehaviorAssignmentLabels;

  /** Form Controls */
  public readonly noteFC = new FormControl('', [
    Validators.minLength(1),
    Validators.maxLength(500),
  ]);
  public readonly dateFC = new FormControl(day().add(1, 'day'));
  public readonly behaviorTypeFC = this._saService.typeFormControl;

  /** Observables and Behavior Subjects */
  public activeSelector$ = this._saService.activeSelector$;
  private _destroyedSubject = new ReplaySubject<void>(1);
  public today = day();

  /** Component Constructor */
  constructor(
    private _saService: SelectionAssignerService,
    private _pbisService: PbisService,
  ) {
    combineLatest([
      this.noteFC.valueChanges.pipe(startWith('')),
      this.behaviorTypeFC.valueChanges,
      this.dateFC.valueChanges,
    ])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(([note, type, date]) => {
        const isValid =
          this.noteFC.valid && this.behaviorTypeFC.valid && this.dateFC.valid;

        this._saService.setFormValidity(isValid);
        if (isValid) {
          this._pbisService.setPbisTypeId(type.id);
          const activeSelector = this._saService.activeSelectorSubject.value;
          if (activeSelector === 'consequence') {
            let dueDate = type?.enableDueDate ? this.dateFC.value : null;
            if (day().date() === dueDate?.date())
              dueDate = dueDate.endOf('day');
            this._pbisService.setConsequenceCompleteDate(dueDate);
          }
          this._pbisService.setPbisMessage(note);
          this._pbisService.setPbisCategory(activeSelector as PbisNameCategory);
          this._saService.setPayload(activeSelector, {
            type: activeSelector as PBIS_CHECKIN_TYPES,
          });
        }
      });

    this.behaviorTypeFC.valueChanges
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(behaviorType => {
        this._updateDateValidity(behaviorType);
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private _updateDateValidity(behaviorType): void {
    const isRequired = !!behaviorType?.enableDueDate;
    if (isRequired) {
      this.dateFC.setValidators(Validators.required);
    } else {
      this.dateFC.clearValidators();
    }

    this.dateFC.updateValueAndValidity();
  }
}
