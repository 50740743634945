var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SisConfig = exports.OneRosterSettings = exports.OneRosterGetTermsForSchool = exports.OneRosterGetTeachersForSchool = exports.OneRosterGetTeachersForClass = exports.OneRosterGetStudentsForSchool = exports.OneRosterGetParentsForSchool = exports.OneRosterGetEnrollmentsForSchool = exports.OneRosterGetCoursesForSchool = exports.OneRosterGetClassesForTeacher = exports.OneRosterGetClasses = exports.OneRosterGetAllUsers = exports.OneRosterGetAllEnrollments = exports.OneRosterGetAllCourses = exports.OneRosterGetAllClasses = exports.OneRosterGetAllAcademicSessions = exports.CleverResult = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const CleverResultts = require("minga/libraries/domain/oneRoster/CleverResult");
exports.CleverResult = CleverResultts;
const OneRosterGetAllAcademicSessionsts = require("minga/libraries/domain/oneRoster/OneRosterGetAllAcademicSessions");
exports.OneRosterGetAllAcademicSessions = OneRosterGetAllAcademicSessionsts;
const OneRosterGetAllClassests = require("minga/libraries/domain/oneRoster/OneRosterGetAllClasses");
exports.OneRosterGetAllClasses = OneRosterGetAllClassests;
const OneRosterGetAllCoursests = require("minga/libraries/domain/oneRoster/OneRosterGetAllCourses");
exports.OneRosterGetAllCourses = OneRosterGetAllCoursests;
const OneRosterGetAllEnrollmentsts = require("minga/libraries/domain/oneRoster/OneRosterGetAllEnrollments");
exports.OneRosterGetAllEnrollments = OneRosterGetAllEnrollmentsts;
const OneRosterGetAllUsersts = require("minga/libraries/domain/oneRoster/OneRosterGetAllUsers");
exports.OneRosterGetAllUsers = OneRosterGetAllUsersts;
const OneRosterGetClassests = require("minga/libraries/domain/oneRoster/OneRosterGetClasses");
exports.OneRosterGetClasses = OneRosterGetClassests;
const OneRosterGetClassesForTeacherts = require("minga/libraries/domain/oneRoster/OneRosterGetClassesForTeacher");
exports.OneRosterGetClassesForTeacher = OneRosterGetClassesForTeacherts;
const OneRosterGetCoursesForSchoolts = require("minga/libraries/domain/oneRoster/OneRosterGetCoursesForSchool");
exports.OneRosterGetCoursesForSchool = OneRosterGetCoursesForSchoolts;
const OneRosterGetEnrollmentsForSchoolts = require("minga/libraries/domain/oneRoster/OneRosterGetEnrollmentsForSchool");
exports.OneRosterGetEnrollmentsForSchool = OneRosterGetEnrollmentsForSchoolts;
const OneRosterGetParentsForSchoolts = require("minga/libraries/domain/oneRoster/OneRosterGetParentsForSchool");
exports.OneRosterGetParentsForSchool = OneRosterGetParentsForSchoolts;
const OneRosterGetStudentsForSchoolts = require("minga/libraries/domain/oneRoster/OneRosterGetStudentsForSchool");
exports.OneRosterGetStudentsForSchool = OneRosterGetStudentsForSchoolts;
const OneRosterGetTeachersForClassts = require("minga/libraries/domain/oneRoster/OneRosterGetTeachersForClass");
exports.OneRosterGetTeachersForClass = OneRosterGetTeachersForClassts;
const OneRosterGetTeachersForSchoolts = require("minga/libraries/domain/oneRoster/OneRosterGetTeachersForSchool");
exports.OneRosterGetTeachersForSchool = OneRosterGetTeachersForSchoolts;
const OneRosterGetTermsForSchoolts = require("minga/libraries/domain/oneRoster/OneRosterGetTermsForSchool");
exports.OneRosterGetTermsForSchool = OneRosterGetTermsForSchoolts;
const OneRosterSettingsts = require("minga/libraries/domain/oneRoster/OneRosterSettings");
exports.OneRosterSettings = OneRosterSettingsts;
const SisConfigts = require("minga/libraries/domain/oneRoster/SisConfig");
exports.SisConfig = SisConfigts;
__exportStar(require("minga/libraries/domain/oneRoster/CleverResult"), exports);
__exportStar(require("minga/libraries/domain/oneRoster/SyncClassEnrollment"), exports);