import { UserListMinimal } from 'minga/libraries/domain';

import { BottomSheetEventType } from '../bottom-sheet';
import {
  CategoryItem,
  OptionItem,
  SelectGroupCategory,
} from '../form/components/form-grouped-select/form-grouped-select.types';

export enum UserListCategory {
  MY_LISTS = 'myLists',
  MY_LISTS_CURRENT_TERM = 'myListsCurrentTerm',
  ALL = 'all',
  ALL_CURRENT_TERM = 'allCurrentTerm',
}

export type UserListBottomSheetData = {
  id?: string;
  initialOptions:
    | OptionItem<number, UserListMinimal>[]
    | OptionItem<number, UserListMinimal>;
  disabled?: boolean;
  options: SelectGroupCategory<string, number, UserListMinimal>[];
  multiple?: boolean;
};

export type UserListBottomSheetResponse = {
  type: BottomSheetEventType;
  data: {
    selectedLists: OptionItem | OptionItem[] | null;
    lastSelectedCategory: CategoryItem;
  };
};
