"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProtoUnmapped = exports.fromProto = exports.toProtoUnmapped = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const proto = require("minga/proto/stats_report/stats_report_pb");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const util_1 = require("minga/libraries/util/index");
const report_filters_1 = require("minga/libraries/shared-grpc/report_filters/index");
exports.toProto = (report) => {
    const { msg, filterCase } = _toProtoShared(report);
    switch (filterCase) {
        case domain_1.ReportFilters.HALL_PASS: {
            const filterMsg = report_filters_1.HallPassFilterMapper.toProto(report.filters);
            msg.setHallPassFilters(filterMsg);
            break;
        }
        case domain_1.ReportFilters.POINTS: {
            const filterMsg = report_filters_1.PointFilterMapper.toProto(report.filters);
            msg.setPointsFilters(filterMsg);
            break;
        }
        case domain_1.ReportFilters.PBIS: {
            const filterMsg = report_filters_1.BehaviorFilterMapper.toProto(report.filters);
            msg.setPbisFilters(filterMsg);
            break;
        }
        case domain_1.ReportFilters.CHECK_IN: {
            const filterMsg = report_filters_1.CheckInFilterMapper.toProto(report.filters);
            msg.setCheckInFilters(filterMsg);
            break;
        }
        case domain_1.ReportFilters.FLEX: {
            const filterMsg = report_filters_1.FlexFilterMapper.toProto(report.filters);
            msg.setFlexFilters(filterMsg);
            break;
        }
    }
    return msg;
};
exports.toProtoUnmapped = (report) => {
    const { msg, filterCase } = _toProtoShared(report);
    const isHP = (x) => filterCase === domain_1.ReportFilters.HALL_PASS;
    const isP = (x) => filterCase === domain_1.ReportFilters.POINTS;
    const isBM = (x) => filterCase === domain_1.ReportFilters.PBIS;
    const isCI = (x) => filterCase === domain_1.ReportFilters.CHECK_IN;
    const isFT = (x) => filterCase === domain_1.ReportFilters.FLEX;
    const filterMsg = report.filters;
    if (isHP(filterMsg)) {
        msg.setHallPassFilters(filterMsg);
    }
    else if (isP(filterMsg)) {
        msg.setPointsFilters(filterMsg);
    }
    else if (isBM(filterMsg)) {
        msg.setPbisFilters(filterMsg);
    }
    else if (isCI(filterMsg)) {
        msg.setCheckInFilters(filterMsg);
    }
    else if (isFT(filterMsg)) {
        msg.setFlexFilters(filterMsg);
    }
    else {
        throw new Error('Invalid filter case');
    }
    return msg;
};
/**
 * Shared proto msg building for mapped and unmapped reports.
 *
 * @param report
 * @returns
 */
const _toProtoShared = (report) => {
    var _a, _b;
    const msg = new proto.ScheduledReport();
    if (report.id)
        msg.setId(report.id);
    if (report.name)
        msg.setName(report.name);
    if (report.reportType)
        msg.setReportType(report.reportType);
    if (report.frequency)
        msg.setFrequency(report.frequency);
    if (report.time)
        msg.setTime(report.time);
    if ((_a = report.deliveryType) === null || _a === void 0 ? void 0 : _a.length)
        msg.setDeliveryTypeList(report.deliveryType);
    if ((_b = report.email) === null || _b === void 0 ? void 0 : _b.length)
        msg.setEmailList(report.email);
    if (report.sftpHost)
        msg.setSftpHost(report.sftpHost);
    if (report.sftpUserName)
        msg.setSftpUserName(report.sftpUserName);
    if (report.sftpPassword)
        msg.setSftpPassword(report.sftpPassword);
    if (report.day)
        msg.setDay(report.day);
    if (typeof report.active === 'boolean')
        msg.setActive(report.active);
    if (report.createdBy)
        msg.setCreatedBy(report.createdBy);
    if (report.createdById)
        msg.setCreatedById(report.createdById);
    if (report.createdAt)
        msg.setCreatedAt(shared_grpc_1.dateObjectToDateTimeMessage(report.createdAt));
    const filterCase = domain_1.GetReportFilterCase(report.reportType);
    return { msg, filterCase };
};
exports.fromProto = (msg) => {
    const { report } = _fromProtoParts(msg);
    return report;
};
exports.fromProtoUnmapped = (msg) => {
    const { report, filterMessage } = _fromProtoParts(msg, false);
    if (!filterMessage)
        throw new Error('Failed to get filters for scheduled report');
    return Object.assign(Object.assign({}, report), { filters: filterMessage });
};
/**
 * Deconstruct proto msg into report and filter message. Assemble desired object
 * in one of the fromProto functions.
 *
 * @param msg
 * @param mapFilters
 * @returns
 */
const _fromProtoParts = (msg, mapFilters = true) => {
    const reportType = ts_enum_util_1.$enum(domain_1.ReportTypes).asValueOrThrow(msg.getReportType());
    if (reportType === domain_1.ReportTypes.EVENT_PEOPLE)
        throw new Error('Invalid report type for scheduled reports');
    let filters = {};
    let filterMessage;
    const filterCase = msg.getFiltersCase();
    switch (filterCase) {
        case domain_1.ReportFilters.HALL_PASS:
            filterMessage = msg.getHallPassFilters();
            if (mapFilters)
                filters = report_filters_1.HallPassFilterMapper.fromProto(filterMessage);
            break;
        case domain_1.ReportFilters.CHECK_IN:
            filterMessage = msg.getCheckInFilters();
            if (mapFilters)
                filters = report_filters_1.CheckInFilterMapper.fromProto(filterMessage);
            break;
        case domain_1.ReportFilters.PBIS:
            filterMessage = msg.getPbisFilters();
            if (mapFilters)
                filters = report_filters_1.BehaviorFilterMapper.fromProto(filterMessage);
            break;
        case domain_1.ReportFilters.POINTS:
            filterMessage = msg.getPointsFilters();
            if (mapFilters)
                filters = report_filters_1.PointFilterMapper.fromProto(filterMessage);
            break;
        case domain_1.ReportFilters.FLEX:
            filterMessage = msg.getFlexFilters();
            if (mapFilters)
                filters = report_filters_1.FlexFilterMapper.fromProto(filterMessage);
            break;
        case domain_1.ReportFilters.EVENT:
        default:
            throw new Error('Invalid filter case');
    }
    const day = msg.getDay();
    const report = {
        id: msg.getId(),
        name: msg.getName(),
        reportType,
        frequency: msg.getFrequency(),
        time: msg.getTime(),
        deliveryType: msg.getDeliveryTypeList(),
        email: msg.getEmailList(),
        sftpHost: msg.getSftpHost(),
        sftpUserName: msg.getSftpUserName(),
        sftpPassword: msg.getSftpPassword(),
        day: day ? ts_enum_util_1.$enum(util_1.DayOfWeekEnum).asValueOrThrow(msg.getDay()) : undefined,
        filters,
        active: msg.getActive(),
        createdBy: msg.getCreatedBy(),
        createdById: msg.getCreatedById(),
        createdAt: msg.getCreatedAt()
            ? shared_grpc_1.dateTimeMessageToDateObject(msg.getCreatedAt())
            : undefined,
    };
    return { report, filterMessage };
};