import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { ColumnInfo } from 'minga/libraries/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { ReportsService } from 'src/app/components/manager-report/services/report-service.service';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';

interface RtModalShellData {
  title?: string;
  subtitle?: string;
  data: any[];
  columnInfo: ColumnInfo[];
  actionInfo?: ColumnInfo;
  actionIcon?: string;
}

class RtModalShellReportService extends ReportsService<any> {
  constructor() {
    super(null);
  }

  protected _handleSetFilter(_filter: any, _value: any): void {}
  protected _mapFiltersToFilterMessage(_reportType: any): any {}
}

class RtModalShellDataService extends ReportDatasourceService<any> {
  /** To allow action columns we need to cache the non-action columns
   * so we don't get double definition errors w MatTable.
   */
  private _columnsToReturn: ColumnInfo[];

  constructor(rs: ReportsService<any>, columnInfo: ColumnInfo[], data: any[]) {
    super(null, rs);
    this.setDisplayColumns(columnInfo);
    this._columnsToReturn = columnInfo;
    this.fetch = async () => ({ items: data, pageToken: 0 });
  }

  public getDisplayColumns() {
    return this._columnsToReturn;
  }

  async fetch() {
    return { items: [], pageToken: 0 };
  }
}

/**
 * A simple shell modal to display a report table.
 *
 * Designed to be used with the `ReportTableModalTemplateComponent` and display a report table with
 * given data and column info.
 * NOT designed to use report table features like sorting, filtering, etc. (can do action columns)
 * If you need that, use the `ReportTableComponent` directly.
 */
@Component({
  selector: 'mg-rt-modal-table-shell',
  template: `
    <mg-overlay-primary
      [isLoading]="isLoading"
      [overlayTitle]="title">
      <ng-container>
        <ng-container *ngTemplateOutlet="mainContent"></ng-container>
      </ng-container>
      <ng-template #overlayFooter>
        <ng-container *ngTemplateOutlet="modalFooterTemplate"></ng-container>
      </ng-template>
    </mg-overlay-primary>

    <ng-template #mainContent>
      <div *ngIf="subtitle">{{ subtitle }}</div>
      <mg-report-table
        [showPaginator]="false"
        [dataSourceService]="ds"
        [sortable]="false"
        matSort>
        <mg-action-column
          [columnInfo]="actionInfo"
          [iconName]="actionIcon"
          (actionTriggered)="emitAction($event)"></mg-action-column>
      </mg-report-table>
    </ng-template>

    <ng-template #modalFooterTemplate>
      <div
        fxLayout="row"
        fxLayoutAlign="end center">
        <mg-btn (pressed)="close()"> Close </mg-btn>
      </div>
    </ng-template>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTableModalTableShellComponent {
  public ds: ReportDatasourceService<any>;
  public isLoading = true;
  public title = 'Details';
  public subtitle = '';
  public actionInfo: ColumnInfo;
  public actionIcon: string;
  private _rs: ReportsService<any>;

  constructor(
    @Inject(MODAL_OVERLAY_DATA) public dialogData: RtModalShellData,
    private _modalOverlayRef: ModalOverlayRef<any, any>,
  ) {
    if (dialogData.title) this.title = dialogData.title;
    if (dialogData.subtitle) this.subtitle = dialogData.subtitle;
    if (dialogData.actionInfo) this.actionInfo = dialogData.actionInfo;
    if (dialogData.actionIcon) this.actionIcon = dialogData.actionIcon;
    this._createShellService(dialogData.data, dialogData.columnInfo);
  }

  public close() {
    this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public emitAction(item: any) {
    this._modalOverlayRef.emitAction.next(item);
  }

  private _createShellService(data: any[], columnInfo: ColumnInfo[]) {
    this._rs = new RtModalShellReportService();
    this.ds = new RtModalShellDataService(this._rs, columnInfo, data);
    if (this.actionInfo) {
      this.ds.setDisplayColumns(columnInfo, [this.actionInfo]);
    }
    this.isLoading = false;
  }
}
