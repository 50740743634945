import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { $enum } from 'ts-enum-util';

import {
  FlexTimeActivity,
  FlexTimeActivityInstance,
} from 'minga/libraries/domain';

import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

import {
  ActivityCardFields,
  FtmSharedMessages,
} from '../../ftm-shared.constants';

type Item = {
  activity: FlexTimeActivity;
  instance?: FlexTimeActivityInstance;
};

export const normalizeActivity = (
  activity: FlexTimeActivityInstance | FlexTimeActivity,
): Item => {
  // if flex time period exists it's instances
  if ((activity as FlexTimeActivityInstance)?.flexTimePeriod) {
    const { flexTimeActivity, ...instance } =
      activity as FlexTimeActivityInstance;
    return {
      activity: flexTimeActivity,
      instance,
    };
  } else {
    return {
      activity: activity as FlexTimeActivity,
      instance: null,
    };
  }
};

@Component({
  selector: 'mg-ftm-activity-card',
  templateUrl: './ftm-activity-card.component.html',
  styleUrls: ['./ftm-activity-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivityCardComponent {
  public item: Item;
  @Input() set activity(activity: FlexTimeActivityInstance | FlexTimeActivity) {
    if (activity) {
      this.item = normalizeActivity(activity);
    }
  }
  @Input() visibleFields: ActivityCardFields[] = [
    ...$enum(ActivityCardFields).getValues(),
  ];
  @ContentChild('actions', { read: TemplateRef })
  actions: TemplateRef<any>;

  public FIELDS = ActivityCardFields;
  public MESSAGES = FtmSharedMessages;
  public isStaff = this._flexPermissions.isFlexTimeSelfManager();

  constructor(private _flexPermissions: FlexTimePermissionsService) {}
}
