"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const category_pb = require("minga/proto/misc/category_pb");
const domain_1 = require("minga/libraries/domain/index");
exports.toProto = (category) => {
    const message = new category_pb.Category();
    message.setId(category.id);
    message.setName(category.name);
    message.setType(category.type);
    message.setAutoParent(category.autoParent);
    return message;
};
exports.fromProto = (message) => {
    return {
        id: message.getId(),
        name: message.getName(),
        type: ts_enum_util_1.$enum(domain_1.CategoryType).asValueOrThrow(message.getType()),
        autoParent: message.getAutoParent(),
    };
};