import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import scrollIntoView from 'scroll-into-view';

import { IConsequence } from 'minga/libraries/domain';
import { MgValidators } from 'src/app/input/validators';
import { RootService } from 'src/app/minimal/services/RootService';

import { BehaviorManagerService } from '@modules/behavior-manager/services';

import * as formUtils from '@shared/components/crud-form-base/crud-form-base.utils';
import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { FormSelectOption } from '@shared/components/form';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';

import {
  BM_CONSEQUENCE_EDIT_FORMGROUP,
  BM_CONSEQUENCE_EDIT_FORM_FIELDS,
  BmReportsMessages,
} from '../../constants';
import { BmConsequenceEditType } from '../../types';

const createForm = formUtils.createForm(BM_CONSEQUENCE_EDIT_FORMGROUP);
const setForm = formUtils.setForm(BM_CONSEQUENCE_EDIT_FORMGROUP);
const getData = formUtils.getData(BM_CONSEQUENCE_EDIT_FORMGROUP);

@Component({
  selector: 'mg-bm-reports-history-edit',
  templateUrl: './bm-reports-cons-history-edit.component.html',
  styleUrls: ['./bm-reports-cons-history-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmReportsConsHistoryEditComponent
  extends CrudFormBase<BmConsequenceEditType>
  implements OnDestroy, OnInit
{
  private _destroyed = new ReplaySubject<void>(1);

  public readonly form = this._fb.group(createForm(), {
    validators: [
      MgValidators.DateTimeComparisonValidator(
        BM_CONSEQUENCE_EDIT_FORM_FIELDS.ASSIGN_DATE,
        BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETED_DATE,
        BM_CONSEQUENCE_EDIT_FORM_FIELDS.ASSIGN_TIME,
        BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETED_TIME,
      ),
    ],
  });
  public FORM_FIELDS = BM_CONSEQUENCE_EDIT_FORM_FIELDS;
  public readonly MESSAGES = BmReportsMessages;

  @Output() closeForm = new EventEmitter();

  public readonly consTypesOptions$ = this._bmService.getConsTypes().pipe(
    takeUntil(this._destroyed),
    map(types =>
      types.map(type => ({
        label: type.name,
        value: type.id,
      })),
    ),
  );

  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };

  @ViewChild('crudForm', { static: false })
  crudForm?: ElementRef<HTMLElement>;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    private _dialogData: IConsequence,
    private _fb: FormBuilder,
    public rootService: RootService,
    private _systemAlertModal: SystemAlertModalService,
    private _bmService: BehaviorManagerService,
    private _modalRef: ModalOverlayRef,
  ) {
    super({
      id: _dialogData?.id,
      get: async _data => _dialogData as IConsequence,
      create: async data => data as IConsequence,
      update: async data => {
        return this._bmService.updateConsequence(data as IConsequence);
      },
      delete: async data => data as IConsequence,
      onSetForm: data => {
        setForm(data, this.form);
      },
      onValidate: data => {
        formUtils.markNestedFormGroupAsDirty(this.form);
        return this.form.valid;
      },
      onSuccess: (type, data) => {
        this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, data);
      },
      onSubmit: data => {
        const mapped = getData(data, this.form);
        return mapped;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
      },
      onDelete: async () => true,
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.crudForm.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  updateTypeName(option: FormSelectOption) {
    this._dialogData.type.name = option.label;
  }
}
