import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { FlexRegistrationInfo } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import {
  ColumnInfo,
  FlexTimeReportColumns,
  TemplateColumnKeys,
} from 'minga/libraries/shared';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FtmReportsService } from './ftm-reports.service';

@Injectable()
export class FtmReportsRegistrationDatasourceService extends ReportDatasourceService<FlexRegistrationInfo> {
  constructor(
    _router: Router,
    private _reportService: FtmReportsService,
    private _permissions: PermissionsService,
    _alertModal: SystemAlertModalService,
    _snackBar: SystemAlertSnackBarService,
  ) {
    super(
      _router,
      _reportService,
      FtmReportsRegistrationDatasourceService.name,
      _alertModal,
      _snackBar,
    );
    this._archiveFn = this._reportService.archiveFlexRegistration.bind(
      this._reportService,
    );
    let displayCol: ColumnInfo[] = [];
    if (
      this._permissions.hasPermission(MingaPermission.FLEX_TIME_MANAGE) ||
      this._permissions.hasPermission(
        MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
      )
    )
      displayCol = [
        {
          header: '',
          key: TemplateColumnKeys.ARCHIVE,
          columnClasses: ['w-16'],
        },
      ];
    super.setDisplayColumns(FlexTimeReportColumns.REGISTRATION, displayCol);
  }

  async fetch(
    offset: number,
    limit: number,
  ): Promise<{ items: FlexRegistrationInfo[]; pageToken: number }> {
    return await this._reportService.getRegistrantsReport(
      offset,
      limit,
      this._currentSort,
    );
  }
}
