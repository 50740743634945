"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsequenceTypeMapper = void 0;
/* eslint-disable @typescript-eslint/no-namespace */
const pbis_pb = require("minga/proto/pbis/pbis_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const wrappers_pb_1 = require("minga/proto/google/protobuf/wrappers_pb");
const restriction_1 = require("minga/libraries/shared-grpc/restriction/index");
var ConsequenceTypeMapper;
(function (ConsequenceTypeMapper) {
    ConsequenceTypeMapper.toProto = (type) => {
        const msg = new pbis_pb.ConsequenceType();
        msg.setName(type.name);
        msg.setCategoryId(type.categoryId);
        msg.setAddNotes(type.addNotes);
        msg.setSendParentEmail(type.sendParentEmail);
        msg.setSendParentSms(type.sendParentSms);
        msg.setSendAdminEmail(type.sendAdminEmail);
        msg.setActive(type.active);
        msg.setDailyConsequenceCount(type.dailyConsequenceCount);
        if (type.points)
            msg.setPoints(type.points);
        if (type.description)
            msg.setDescription(type.description);
        if (type.sendAdminEmail && type.adminEmails)
            msg.setAdminEmailList(type.adminEmails);
        if (type.id) {
            msg.setId(type.id);
        }
        if (type.type) {
            msg.setTypeId(type.type);
        }
        if (type.stickerId) {
            msg.setStickerId(type.stickerId);
        }
        if (type.emailTemplate)
            msg.setEmailTemplate(shared_grpc_1.asObjectToMessage(type.emailTemplate, new pbis_pb.EmailTemplate()));
        if (type.smsTemplate)
            msg.setSmsTemplate(shared_grpc_1.asObjectToMessage(type.smsTemplate, new pbis_pb.SmsTemplate()));
        msg.setEnableDueDate(type.enableDueDate);
        if (type.iconType)
            msg.setIconType(type.iconType);
        if (type.iconColor)
            msg.setIconColor(type.iconColor);
        if (type.removeStickerId)
            msg.setRemoveStickerId(type.removeStickerId);
        if (type.overdueActionId)
            msg.setOverdueActionId(type.overdueActionId);
        if (type.overdueActionType)
            msg.setOverdueActionType(type.overdueActionType);
        if (type.overdueActionDueDate !== null) {
            const wrapper = new wrappers_pb_1.Int32Value();
            wrapper.setValue(type.overdueActionDueDate);
            msg.setOverdueActionDueDate(wrapper);
        }
        if (type.overdueAction) {
            msg.setOverdueAction(ConsequenceTypeMapper.toProto(type.overdueAction));
        }
        if (type.restriction) {
            const restrictionMsg = restriction_1.RestrictionMapper.toProto(type.restriction);
            msg.setRestriction(restrictionMsg);
        }
        return msg;
    };
    ConsequenceTypeMapper.fromProto = (msg) => {
        var _a, _b;
        let restriction;
        if (msg.getRestriction()) {
            restriction = restriction_1.RestrictionMapper.fromProto(msg.getRestriction());
        }
        const overdueAction = msg.getOverdueAction();
        const consequenceType = {
            name: msg.getName(),
            description: msg.getDescription(),
            categoryId: msg.getCategoryId(),
            addNotes: msg.getAddNotes(),
            sendParentEmail: msg.getSendParentEmail(),
            sendParentSms: msg.getSendParentSms(),
            sendAdminEmail: msg.getSendAdminEmail(),
            adminEmails: msg.getAdminEmailList(),
            active: msg.getActive(),
            dailyConsequenceCount: msg.getDailyConsequenceCount(),
            points: msg.getPoints(),
            type: msg.getTypeId(),
            stickerId: msg.getStickerId(),
            emailTemplate: (_a = msg.getEmailTemplate()) === null || _a === void 0 ? void 0 : _a.toObject(),
            smsTemplate: (_b = msg.getSmsTemplate()) === null || _b === void 0 ? void 0 : _b.toObject(),
            enableDueDate: msg.getEnableDueDate(),
            iconType: msg.getIconType(),
            iconColor: msg.getIconColor(),
            removeStickerId: msg.getRemoveStickerId(),
            restriction,
            overdueActionId: msg.getOverdueActionId(),
            overdueActionDueDate: msg.hasOverdueActionDueDate()
                ? msg.getOverdueActionDueDate().getValue()
                : null,
            overdueActionType: msg.getOverdueActionType(),
            overdueAction: overdueAction ? ConsequenceTypeMapper.fromProto(overdueAction) : null,
        };
        if (msg.getId()) {
            consequenceType['id'] = msg.getId();
        }
        return consequenceType;
    };
})(ConsequenceTypeMapper = exports.ConsequenceTypeMapper || (exports.ConsequenceTypeMapper = {}));