import { Validators } from '@angular/forms';

import { Checkin } from 'minga/libraries/domain';
import { ReportTypes } from 'minga/libraries/domain';
import { SelectOption } from 'src/app/components/manager-report/components';

import { FormConfig } from '@shared/components/crud-form-base/crud-form-base.types';

import { CheckinManagerReportType } from '../checkin-manager-reports.constants';
import { CheckinEditType } from '../checkin-manager-reports.types';

export enum ChReportMessages {
  HISTORY = 'Check in History Report',
  REASONS = 'Check in Reasons Report',
  RECORD_EDIT_LABEL = 'Edit Record',
  RECORD_EDIT_CHECK_IN = 'Check in',
  RECORD_EDIT_DATE = 'Date',
  RECORD_EDIT_TIME = 'Time',
  RECORD_EDIT_FORM_ERROR = 'Please fix the errors in the form to continue',
}

export enum ChReportReasonTypes {
  CHECKIN = 'CHECKIN',
  ABSENTEES = 'ABSENTEES',
}

export const CHECKIN_REPORT_TYPES: SelectOption[] = [
  { label: ChReportMessages.HISTORY, value: ReportTypes.CHECKIN_HISTORY },
  { label: ChReportMessages.REASONS, value: ReportTypes.CHECKIN },
];

export const CHECKIN_REASONS_REPORT_TYPES: SelectOption[] = [
  { label: 'Check ins', value: CheckinManagerReportType.CHECKINS },
  { label: 'Absentees', value: CheckinManagerReportType.ABSENTEES },
];

export enum CH_EDIT_FORM_FIELDS {
  TYPE = 'reasonId',
  DATE = 'checkinTime',
  TIME = 'time',
}

export const CH_EDIT_FORMGROUP: FormConfig<
  CH_EDIT_FORM_FIELDS,
  CheckinEditType
> = {
  [CH_EDIT_FORM_FIELDS.TYPE]: [null, [Validators.required]],
  [CH_EDIT_FORM_FIELDS.DATE]: {
    formControl: [null, [Validators.required]],
    key: CH_EDIT_FORM_FIELDS.DATE,
    get: (formData: CheckinEditType) => {
      const times = formData.time.split(':');
      formData.checkinTime = formData.checkinTime.set('hour', times[0]);
      formData.checkinTime = formData.checkinTime.set('minute', times[1]);
      return formData.checkinTime;
    },
  },
  [CH_EDIT_FORM_FIELDS.TIME]: {
    formControl: [null, [Validators.required]],
    key: CH_EDIT_FORM_FIELDS.TIME,
    set: (data: Checkin) => data.checkinTime.format('HH:mm'),
  },
};
