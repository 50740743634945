"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromExistingRegistrationProto = exports.toExistingRegistrationProto = exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const flex_time_pb = require("minga/proto/flex_time/flex_time_pb");
const flex_stats_pb = require("minga/proto/flex_time_stats/flex_time_stats_pb");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const person_1 = require("minga/libraries/shared-grpc/person/index");
exports.toProto = (info) => {
    const proto = new flex_stats_pb.FlexRegistrationInfo();
    proto.setId(info.id);
    proto.setActivityId(info.activityId);
    proto.setActivityInstanceId(info.activityInstanceId);
    proto.setActivityName(info.activityName);
    if (info.checkinDate)
        proto.setCheckInDate(shared_grpc_1.dayJsObjectToDateTimeMsg(info.checkinDate));
    if (info.registeredDate)
        proto.setRegisteredDate(shared_grpc_1.dayJsObjectToDateTimeMsg(info.registeredDate));
    if (info.student) {
        proto.setStudent(person_1.IReportPersonMapper.toProto(info.student));
    }
    if (info.teacher) {
        proto.setTeacherName(info.teacher.firstName);
        proto.setTeacherLastName(info.teacher.lastName);
    }
    if (info.checkinStatus)
        proto.setCheckinStatus(info.checkinStatus);
    if (info.period)
        proto.setPeriodName(info.period);
    if (info.periodDate)
        proto.setPeriodDate(info.periodDate);
    if (info.periodStartTime)
        proto.setPeriodStartTime(info.periodStartTime);
    if (info.periodEndTime)
        proto.setPeriodEndTime(info.periodEndTime);
    if (info.type)
        proto.setType(info.type);
    return proto;
};
exports.fromProto = (proto) => {
    const checkinDate = proto.getCheckInDate()
        ? shared_grpc_1.dateTimeMessageToDayjsObject(proto.getCheckInDate())
        : undefined;
    const registeredDate = proto.getRegisteredDate()
        ? shared_grpc_1.dateTimeMessageToDayjsObject(proto.getRegisteredDate())
        : undefined;
    return {
        id: proto.getId(),
        activityId: proto.getActivityId(),
        activityInstanceId: proto.getActivityInstanceId(),
        activityName: proto.getActivityName(),
        student: person_1.IReportPersonMapper.fromProto(proto.getStudent()),
        checkinDate,
        registeredDate,
        checkinStatus: ts_enum_util_1.$enum(domain_1.FlexCheckInStatuses).asValueOrThrow(proto.getCheckinStatus()),
        teacher: {
            firstName: proto.getTeacherName(),
            lastName: proto.getTeacherLastName(),
        },
        period: proto.getPeriodName(),
        periodDate: proto.getPeriodDate(),
        periodStartTime: proto.getPeriodStartTime(),
        periodEndTime: proto.getPeriodEndTime(),
        type: proto.getType(),
    };
};
exports.toExistingRegistrationProto = (existing) => {
    const message = new flex_time_pb.ExistingRegistration();
    if (existing.activityName)
        message.setActivityName(existing.activityName);
    if (existing.activityInstanceId)
        message.setActivityInstanceId(existing.activityInstanceId);
    if (existing.person) {
        const person = new flex_time_pb.Person();
        person.setHash(existing.person.hash);
        person.setName(existing.person.name);
        message.setPerson(person);
    }
    if (existing.cannotUnregister)
        message.setCannotUnregister(existing.cannotUnregister);
    if (existing.registeredBy) {
        const person = new flex_time_pb.Person();
        person.setHash(existing.registeredBy.hash);
        person.setName(existing.registeredBy.name);
        message.setRegisteredBy(person);
    }
    if (existing.activityLocation) {
        message.setActivityLocation(existing.activityLocation);
    }
    if (existing.activityTeacherName)
        message.setActivityTeacherName(existing.activityTeacherName);
    return message;
};
exports.fromExistingRegistrationProto = (message) => {
    var _a, _b, _c, _d, _e;
    let person = {
        hash: '',
        name: '',
    };
    if (message.getPerson()) {
        person = message.getPerson().toObject();
    }
    return {
        activityName: (_a = message.getActivityName()) !== null && _a !== void 0 ? _a : undefined,
        activityInstanceId: (_b = message.getActivityInstanceId()) !== null && _b !== void 0 ? _b : undefined,
        activityTeacherName: (_c = message.getActivityTeacherName()) !== null && _c !== void 0 ? _c : undefined,
        person,
        activityLocation: (_d = message.getActivityLocation()) !== null && _d !== void 0 ? _d : undefined,
        cannotUnregister: message.getCannotUnregister(),
        registeredBy: (_e = message.getRegisteredBy().toObject()) !== null && _e !== void 0 ? _e : {
            hash: '',
            name: '',
        },
    };
};