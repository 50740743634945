import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { PointsDialogService } from 'minga/app/src/app/dialog/PointsDialog';
import { isPointsNotification } from 'minga/libraries/shared';
import { Notification } from 'minga/proto/gateway/notification_pb';

import { NotificationActions } from '../../store/Notifications/actions/notifications.actions';
import { NotificationStoreModel } from '../../store/Notifications/model/notification.model';

/**
 * Handle activating a point notification.
 */
@Injectable({ providedIn: 'root' })
export class PointsNotificationHandlerService {
  constructor(
    private store: Store<any>,
    private pointsDialog: PointsDialogService,
  ) {}

  async handleDisplayPointNotification(msg: Notification.AsObject) {
    const notificationType = msg.notificationType;
    if (notificationType && isPointsNotification(notificationType)) {
      await this.showPointsDialogForNotification(
        msg.title,
        msg.body,
        msg.notificationId,
      );
    }
  }

  async showPointsDialogForNotification(
    title: string,
    body: string,
    notificationId: string,
  ) {
    // we have to track whether the dialog is finished or not.
    // we can't use dialogRef.afterClose() because this dialog closes
    // immediately, and then shows an animation that lasts a bit.
    const tracker$ = new BehaviorSubject<boolean>(false);
    const dialogRef = this.pointsDialog.open({
      data: {
        text: title,
        body: body,
        showConfetti: true,
        finished$: tracker$,
      },
    });
    const notification: NotificationStoreModel = { id: notificationId };
    this.store.dispatch(
      new NotificationActions.MarkNotificationAsReadAction(notification),
    );
    return await tracker$.toPromise();
  }

  isPointsNotification(type: string): boolean {
    return isPointsNotification(type);
  }
}
