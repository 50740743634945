var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProtoMinimal = exports.toProtoMinimal = exports.fromProto = exports.toProto = void 0;
const user_list_pb = require("minga/proto/user_list/user_list_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
exports.toProto = (list) => __awaiter(void 0, void 0, void 0, function* () {
    const message = new user_list_pb.UserList();
    if (list.createTime) {
        message.setCreateTime(shared_grpc_1.dayJsObjectToDateTimeMsg(list.createTime));
    }
    if (list.updateTime) {
        message.setUpdateTime(shared_grpc_1.dayJsObjectToDateTimeMsg(list.updateTime));
    }
    if (list.id)
        message.setId(list.id);
    if (list.uuid)
        message.setUuid(list.uuid);
    if (list.termSourceIds) {
        message.setTermSourceIdsList(list.termSourceIds);
    }
    if (list.periodSourceIds) {
        message.setPeriodSourceIdsList(list.periodSourceIds);
    }
    if (list.public)
        message.setPublic(list.public);
    if (list.active)
        message.setActive(list.active);
    if (list.title)
        message.setTitle(list.title);
    if (list.description)
        message.setDescription(list.description);
    if (list.memberCount)
        message.setMemberCount(list.memberCount);
    if (list.managerListId)
        message.setManagerListId(list.managerListId);
    if (list.creator) {
        message.setCreator(new user_list_pb.UserList.Creator([
            list.creator.hash,
            list.creator.firstName,
            list.creator.lastName,
        ]));
    }
    if (list.managers) {
        message.setManagersList(list.managers.map(({ hash, firstName, lastName }) => new user_list_pb.UserList.Manager([hash, firstName, lastName])));
    }
    return message;
});
exports.fromProto = (message) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const createTime = message.getCreateTime();
    const updatedTime = message.getUpdateTime();
    const creator = message.getCreator();
    const managers = yield Promise.all(message.getManagersList().map((managerMessage) => __awaiter(void 0, void 0, void 0, function* () {
        return ({
            hash: managerMessage.getHash(),
            firstName: managerMessage.getFirstName(),
            lastName: managerMessage.getLastName(),
        });
    })));
    return {
        createTime: createTime && shared_grpc_1.dateTimeMessageToDayjsObject(createTime),
        updateTime: updatedTime && shared_grpc_1.dateTimeMessageToDayjsObject(updatedTime),
        id: message.getId(),
        uuid: message.getUuid(),
        termSourceIds: message.getTermSourceIdsList(),
        periodSourceIds: message.getPeriodSourceIdsList(),
        public: message.getPublic(),
        active: message.getActive(),
        title: message.getTitle(),
        description: message.getDescription(),
        memberCount: message.getMemberCount(),
        creator: {
            hash: creator === null || creator === void 0 ? void 0 : creator.getHash(),
            firstName: (_a = creator === null || creator === void 0 ? void 0 : creator.getFirstName()) !== null && _a !== void 0 ? _a : '',
            lastName: (_b = creator === null || creator === void 0 ? void 0 : creator.getLastName()) !== null && _b !== void 0 ? _b : '',
        },
        managerListId: message.getManagerListId(),
        managers: managers !== null && managers !== void 0 ? managers : [],
    };
});
exports.toProtoMinimal = (list) => {
    const message = new user_list_pb.UserListMinimal();
    if (list.id)
        message.setId(list.id);
    if (list.title)
        message.setTitle(list.title);
    message.setCurrentTerm(list.currentTerm);
    return message;
};
exports.fromProtoMinimal = (message) => ({
    id: message.getId(),
    title: message.getTitle(),
    currentTerm: message.getCurrentTerm(),
});