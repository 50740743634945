import { ChartOptions } from 'chart.js';

import { formatMinutesIntoHours } from 'minga/libraries/domain';

export enum ManagerReportChartMessages {
  NO_DATA = "There's no data for this time period you've selected.",
}

export const BAR_CHART_OPTIONS: ChartOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: '#003366',
        font: {
          size: 16,
          family: 'Muli',
        },
      },
      grid: {
        display: true,
        lineWidth: 0,
        color: 'transparent',
        drawBorder: true,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          size: 16,
          family: 'Muli',
        },
        color: '#4BA0CD',
        maxTicksLimit: 3,
        callback: (val: number) => formatMinutesIntoHours(val),
      },
      grid: {
        display: true,
        color: 'transparent',
        drawBorder: true,
      },
      title: {
        display: true,
        text: 'Time',
        color: '#B2B2B2',
        font: {
          size: 14,
          weight: 'bold',
          family: 'Muli',
        },
      },
    },
  },
};
