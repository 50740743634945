import { Pipe, PipeTransform } from '@angular/core';

/**
 * Mimics the array join method.
 */
@Pipe({ name: 'join' })
export class ArrayJoinPipe implements PipeTransform {
  transform(array: any[], separator: string = ', ') {
    return (array || []).join(separator);
  }
}
