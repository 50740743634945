"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INotificationType = void 0;
var INotificationType;
(function (INotificationType) {
    INotificationType["POINTS_IMAGE_CHALLENGE"] = "POINTS_IMAGE_CHALLENGE";
    INotificationType["POINTS_EVENT_CHECKIN"] = "POINTS_EVENT_CHECKIN";
    INotificationType["POINTS_POLL"] = "POINTS_POLL";
    INotificationType["POINTS_TEXT_CHALLENGE"] = "POINTS_TEXT_CHALLENGE";
    INotificationType["POINTS_CUSTOM"] = "POINTS_CUSTOM";
    INotificationType["BADGE_CUSTOM"] = "BADGE_CUSTOM";
    INotificationType["BADGE_IMAGE_CHALLENGE"] = "BADGE_IMAGE_CHALLENGE";
    INotificationType["BADGE_TEXT_CHALLENGE"] = "BADGE_TEXT_CHALLENGE";
    INotificationType["EVENT_INVITE"] = "EVENT_INVITE";
    INotificationType["CHALLENGE_INVITE"] = "CHALLENGE_INVITE";
    INotificationType["CHALLENGE_RESPONSE_DENIED"] = "CHALLENGE_RESPONSE_DENIED";
    INotificationType["HALL_PASS_GRANTED"] = "HALL_PASS_GRANTED";
    INotificationType["HALL_PASS_NOTIFY"] = "HALL_PASS_NOTIFY";
    INotificationType["HALL_PASS_APPROVAL_REQUEST"] = "HALL_PASS_APPROVAL_REQUEST";
    INotificationType["HALL_PASS_APPROVAL_EXPIRED"] = "HALL_PASS_APPROVAL_EXPIRED";
    INotificationType["HALL_PASS_APPROVAL_CANCEL"] = "HALL_PASS_APPROVAL_CANCEL";
    INotificationType["HALL_PASS_APPROVED"] = "HALL_PASS_APPROVED";
    INotificationType["HALL_PASS_DENIED"] = "HALL_PASS_DENIED";
    INotificationType["HALL_PASS_ENDED"] = "HALL_PASS_ENDED";
    INotificationType["PBIS_BEHAVIOR"] = "PBIS_BEHAVIOR";
    INotificationType["ACCOUNT_ARCHIVED"] = "ACCOUNT_ARCHIVED";
    INotificationType["BM_PRAISE"] = "BM_PRAISE";
    INotificationType["BM_GUIDANCE"] = "BM_GUIDANCE";
    INotificationType["BM_CONSEQUENCE_PRAISE"] = "BM_CONSEQUENCE_PRAISE";
    INotificationType["BM_CONSEQUENCE_GUIDANCE"] = "BM_CONSEQUENCE_GUIDANCE";
    INotificationType["FLEX_TIME_ACTIVITY_ASSIGNED"] = "FLEX_TIME_ACTIVITY_ASSIGNED";
    INotificationType["FLEX_TIME_ACTIVITY_REGISTERED"] = "FLEX_TIME_ACTIVITY_REGISTERED";
    INotificationType["FLEX_TIME_ACTIVITY_UNASSIGNED"] = "FLEX_TIME_ACTIVITY_UNASSIGNED";
    INotificationType["FLEX_TIME_ACTIVITY_REMOVED"] = "FLEX_TIME_ACTIVITY_REMOVED";
    INotificationType["SIS_MASS_DELETE"] = "SIS_MASS_DELETE";
})(INotificationType = exports.INotificationType || (exports.INotificationType = {}));