"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProtoEditable = exports.fromProto = exports.toProtoEditable = exports.toProto = void 0;
const checkin_pb = require("minga/proto/checkin/checkin_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const shared_grpc_2 = require("minga/libraries/shared-grpc/index");
const CheckinPersonMapper = require("minga/libraries/shared-grpc/checkin/CheckinPerson.mapper");
const CheckinReasonMapper = require("minga/libraries/shared-grpc/checkin/CheckinReason.mapper");
exports.toProto = (checkin) => {
    var _a;
    const msg = new checkin_pb.Checkin();
    if (checkin.id) {
        msg.setId(checkin.id);
    }
    if (checkin.reasonId)
        msg.setReasonId(checkin.reasonId);
    if (checkin.person) {
        msg.setPerson(CheckinPersonMapper.toProto(checkin.person));
    }
    if (checkin.checkinTime) {
        const checkinTime = shared_grpc_2.dayJsObjectToDateTimeMsg(checkin.checkinTime);
        msg.setCheckinTime(checkinTime);
    }
    if (checkin.reason) {
        msg.setReason(CheckinReasonMapper.toProto(checkin.reason));
    }
    if (checkin.reasonName)
        msg.setReasonName(checkin.reasonName);
    if (checkin.checkinBy) {
        msg.setCheckinby(CheckinPersonMapper.toProto(checkin.checkinBy));
    }
    if (checkin.checkoutBy) {
        msg.setCheckoutBy(CheckinPersonMapper.toProto(checkin.checkoutBy));
    }
    if (checkin.checkoutTime) {
        msg.setCheckoutTime(shared_grpc_2.dayJsObjectToDateTimeMsg(checkin.checkoutTime));
    }
    if (checkin.note)
        msg.setNote(checkin.note);
    if (checkin.isKiosk)
        msg.setIsKiosk(checkin.isKiosk);
    if ((_a = checkin.currentClass) === null || _a === void 0 ? void 0 : _a.length) {
        const sections = checkin.currentClass.map(shared_grpc_1.StudentScheduleMapper.toProto);
        msg.setCurrentClassesList(sections);
        // deprecated but for b/c
        msg.setCurrentClass(sections[0]);
    }
    return msg;
};
exports.toProtoEditable = (checkin) => {
    const msg = exports.toProto(checkin);
    const editable = new checkin_pb.EditableCheckin();
    editable.setCheckin(msg);
    if (checkin.manuallyUpdatedAt) {
        editable.setManuallyUpdatedAt(shared_grpc_2.dateObjectToDateTimeMessage(checkin.manuallyUpdatedAt));
    }
    return editable;
};
exports.fromProto = (msg) => {
    var _a;
    const reasonProto = msg.getReason();
    const reason = reasonProto
        ? CheckinReasonMapper.fromProto(reasonProto)
        : undefined;
    const person = CheckinPersonMapper.fromProto(msg.getPerson());
    const checkinBy = CheckinPersonMapper.fromProto(msg.getCheckinby());
    const checkoutByProto = msg.getCheckoutBy();
    const checkoutBy = checkoutByProto
        ? CheckinPersonMapper.fromProto(checkoutByProto)
        : undefined;
    const checkinTime = shared_grpc_2.dateTimeMessageToDayjsObject(msg.getCheckinTime());
    const checkoutTimeProto = msg.getCheckoutTime();
    const checkoutTime = checkoutTimeProto
        ? shared_grpc_2.dateTimeMessageToDayjsObject(checkoutTimeProto)
        : undefined;
    const currentClassList = msg.getCurrentClassesList();
    return {
        id: msg.getId(),
        reasonId: msg.getReasonId(),
        reason,
        person,
        checkinBy,
        checkinTime,
        checkoutTime,
        checkoutBy,
        reasonName: msg.getReasonName(),
        note: (_a = msg.getNote()) !== null && _a !== void 0 ? _a : '',
        isKiosk: msg.getIsKiosk(),
        currentClass: currentClassList
            ? currentClassList.map(shared_grpc_1.StudentScheduleMapper.fromProto)
            : [],
    };
};
exports.fromProtoEditable = (msg) => {
    const checkin = exports.fromProto(msg.getCheckin());
    const manuallyUpdatedAt = msg.getManuallyUpdatedAt()
        ? shared_grpc_2.dateTimeMessageToDateObject(msg.getManuallyUpdatedAt())
        : undefined;
    return Object.assign(Object.assign({}, checkin), { manuallyUpdatedAt });
};