import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { MingaMinimalModel } from 'minga/libraries/domain';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

@Component({
  selector: 'mg-logo',
  styleUrls: ['./Logo.component.scss'],
  templateUrl: './Logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit, OnDestroy {
  private _onMingaUpdateSubscription?: Subscription;

  constructor(
    private _elementRef: ElementRef,
    private renderer2: Renderer2,
    private mingaStore: MingaStoreFacadeService,
  ) {}

  ngOnInit() {
    if (this._onMingaUpdateSubscription) {
      this._onMingaUpdateSubscription.unsubscribe();
    }

    this._onMingaUpdateSubscription = this.mingaStore
      .getMingaAsObservable()
      .subscribe(ev => this.setMingaLogo(ev));
  }

  ngOnDestroy() {
    if (this._onMingaUpdateSubscription) {
      this._onMingaUpdateSubscription.unsubscribe();
      delete this._onMingaUpdateSubscription;
    }
  }

  setMingaLogo(ev: MingaMinimalModel) {
    if (!ev) {
      return;
    }
    const mingaLogo = ev.logo;

    if (mingaLogo) {
      this.renderer2.setStyle(
        this._elementRef.nativeElement,
        'background-image',
        `url(${mingaLogo})`,
      );

      this._elementRef.nativeElement.classList.add('custom');
    } else {
      this.renderer2.setStyle(
        this._elementRef.nativeElement,
        'background-image',
        `url(/assets/Minga_icon.svg)`,
      );
    }
  }
}
