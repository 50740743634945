import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import * as mgUtil from 'minga/libraries/util';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { StreamManager } from 'minga/app/src/app/minimal/services/StreamManager';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { ReportService } from 'minga/app/src/app/services/Report';
import { MingaPermission } from 'minga/libraries/domain';
import { DetailedModerationResult } from 'minga/proto/gateway/moderation_pb';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  IReportContentFilter,
  IReportStatFilterInfo,
} from '../types/minga-report-feed-base.types';

export abstract class MingaReportFeedBaseClass {
  onOpenContent: (e: CustomEvent) => void;
  onOpenImages: (e: CustomEvent) => void;
  contentFilter: IReportContentFilter = null;
  globalContent = false;
  primaryStatFilters: IReportStatFilterInfo[] = [];
  showStats = false;
  stats: any = {};
  defaultNoResultText = `content`;
  mqAlias: string;

  private _baseUrl = '';
  private _mediaSubscription: Subscription;

  constructor(
    protected router: Router,
    protected reportService: ReportService,
    protected contentEvents: ContentEvents,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    protected route: ActivatedRoute,
    protected permissions: PermissionsService,
    protected contentState: ContentState,
    protected streamManager: StreamManager,
    protected media: MediaObserver,
  ) {
    this.onOpenContent = (e: CustomEvent) => this._onOpenContent(e);
    this.onOpenImages = (e: CustomEvent) => this._onOpenImages(e);

    this._mediaSubscription = this.media
      .asObservable()
      .subscribe(mediaChanges => {
        const mediaChange = mediaChanges[0];
        this.mqAlias = mediaChange.mqAlias;
      });
  }

  abstract initTabs(resetToFirst: boolean): Promise<void>;
  abstract updateContentFilter(reportFilter: mgUtil.ReportFilter): void;
  abstract get feedName(): string;

  get showLongFormatDateComment() {
    return this.mqAlias !== 'xs';
  }

  unsubscribeSubscriptions() {
    this._mediaSubscription.unsubscribe();
  }

  get noResultsText() {
    let defaultText = this.defaultNoResultText;
    const textOptions: string[] = [];

    for (const stat of this.primaryStatFilters) {
      if (stat.filterActive && stat.filterLinks.length) {
        for (const filterLink of stat.filterLinks) {
          textOptions.push(filterLink.text);
        }
        break;
      }
    }

    if (textOptions.length > 0) {
      if (textOptions.length === 1) {
        defaultText = textOptions[0];
      } else {
        const firstOptions = textOptions.slice(0, textOptions.length - 1);
        defaultText = firstOptions.join(', ');
        if (firstOptions.length > 0) {
          defaultText += ' or ' + textOptions[textOptions.length - 1];
        }
      }
    }

    return `There is currently no ${defaultText} in your minga.`;
  }

  protected async initSetup() {
    this.route.data.subscribe(async data => {
      const baseUrl = data.baseUrl;
      const globalContent = !!data.globalContent;

      if (!baseUrl) {
        console.error(`MingaReportFeed requires route data 'baseUrl'`);
      }

      if (!('globalContent' in data)) {
        console.error(`MingaReportFeed requires route data 'globalContent'`);
      }

      this._baseUrl = baseUrl || '';

      const globalPermission = this.permissions.hasPermission(
        MingaPermission.SUPERADMIN,
      );

      if (globalContent && globalPermission) {
        this.globalContent = true;
      }

      // initialize tabs after the showPassedAi permission was checked and
      // after any url changes
      // @TODO: Bazel - Was this supposed to be fire and forget?
      (() => {
        this.initTabs(true);
      })();
    });
  }

  initGlobalContent() {
    this.globalContent = true;
  }

  protected getTabStatText(
    reportStat: mgUtil.ReportFilter,
    asPercentage: boolean = false,
  ) {
    if (_.isEmpty(this.stats) || this.globalContent) {
      return;
    }
    if (this.stats[reportStat]) {
      if (asPercentage) {
        return this.stats[reportStat].toFixed(1) + '%';
      } else {
        return this.stats[reportStat].toFixed(0);
      }
    }
    return '--';
  }

  private _openByContextHash(contextHash: string) {
    if (contextHash) {
      this.router.navigate(['', { outlets: { o: ['view', contextHash] } }]);
    } else {
      this._systemAlertSnackBar.error(`unable to open this content`);
    }
  }

  private _onOpenContent(e: CustomEvent) {
    const result: DetailedModerationResult.AsObject = e.detail;

    if (result.galleryPhotoUuid) {
      this.reportService.openGalleryPhotoModerationLightbox(result);
    } else {
      this._openByContextHash(
        result.actionContextHash ||
          result.parentContextHash ||
          result.contentContextHash,
      );
    }
  }

  private _onOpenImages(e: CustomEvent) {
    const result: DetailedModerationResult.AsObject = e.detail.content;
    if (this.contentState) {
      this.contentState.setStateValue(
        result.contentContextHash,
        'imageFocusIndex',
        e.detail.imageIndex,
      );
    }

    this._openByContextHash(result.contentContextHash);
  }

  toggleFilter(stat: IReportStatFilterInfo) {
    const newValue = !stat.filterActive;
    this.clearActivePrimaryFilter();

    stat.filterActive = newValue;

    if (!stat.routerLink) {
      this.updateContentFilter(stat.searchFilter);
    }

    // if resetting and is same stat filter, refresh
    if (!newValue) {
      stat.filterActive = true;
      this.streamManager.restartStreamIfAvailable(this.feedName);
    } else if (stat.routerLink) {
      this.router.navigate(stat.routerLink);
    } else {
      this.router.navigate([this._baseUrl]);
    }
  }

  resetToFirstFilter() {
    this.clearActivePrimaryFilter();
    this.updateContentFilter(this.primaryStatFilters[0].searchFilter);

    this.router.navigate([this._baseUrl]);

    this.primaryStatFilters[0].filterActive = true;
  }

  clearActivePrimaryFilter() {
    this.primaryStatFilters.forEach(filter => {
      if ('filterActive' in filter) {
        filter.filterActive = false;
      }
    });
  }

  /**
   * Open up the long card matching the context hash of the report item.
   *
   * @param e click event data
   */
  itemClick(e: any) {
    const model = e.model as any;
    const item: DetailedModerationResult.AsObject = model.item.data;

    this._openByContextHash(item.contentContextHash);
  }
}
