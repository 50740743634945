import { Injectable } from '@angular/core';

import * as localforage from 'localforage';
import { select, Store } from '@ngrx/store';
import Fuse from 'fuse.js';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, first, map, tap } from 'rxjs/operators';

import {
  MingaSettingsService,
  MingaStoreFacadeService,
} from 'minga/app/src/app/store/Minga/services';
import { IPeopleCollectionPersonDetails } from 'minga/domain/person';
import { PeopleCollection } from 'minga/proto/people_collection/people_collection_ng_grpc_pb';
import {
  GetPeopleByRolesRequest,
  GetPersonRequest,
  PeopleCollectionPersonDetails,
  SearchPeopleCollectionRequest,
  SearchPeopleCollectionResponse,
} from 'minga/proto/people_collection/people_collection_pb';
import { IPeopleCollectionPersonDetailsProtoMapper } from 'minga/shared-grpc/person';
import { mingaSettingTypes } from 'minga/util';

import { PeopleMapper } from '../mappers';
import { Person } from '../models';
import { PeopleCollectionActions } from '../store/actions';
import {
  selectAllPeopleInCollection,
  selectPeopleByHash,
  selectPersonByHash,
} from '../store/state';

const peopleCollection = localforage.createInstance({
  name: 'PeopleCollection',
});
@Injectable({ providedIn: 'root' })
export class PeopleFacadeService {
  people$ = this.store.pipe(select(selectAllPeopleInCollection));
  peopleAutocomplete: Fuse<any>;
  private storageKey = '';

  private fetchQueue = new Map<string, string>();
  private fetchSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private peopleCollectionService: PeopleCollection,
    private store: Store<any>,
    private mingaFacade: MingaStoreFacadeService,
    private _settingService: MingaSettingsService,
  ) {
    const fuseOptions: Fuse.IFuseOptions<IPeopleCollectionPersonDetails> = {
      isCaseSensitive: false,
      keys: ['displayName', 'firstName', 'lastName', 'email', 'studentId'],
      shouldSort: true,
    };
    this.peopleAutocomplete = new Fuse([], fuseOptions);

    mingaFacade.getMingaAsPromise().then(minga => {
      this.storageKey = 'people_' + minga.hash;
      this.loadPeopleCollectionFromCache();
    });

    this.fetchSubject
      .asObservable()
      .pipe(
        // debounce updates so that we don't fire off a bunch
        // of requests. This should let the queue get filled up
        // before firing off the request.
        debounceTime(10),
      )
      .subscribe(forceUpdate => this.fetchPeopleFromBackend(forceUpdate));
  }

  private addToQueue(hashes: readonly string[] | string) {
    if (typeof hashes === 'string') {
      this.fetchQueue.set(hashes, hashes);
    } else if (hashes && hashes.length > 0) {
      hashes.forEach(hash => this.fetchQueue.set(hash, hash));
    }
  }

  /**
   * Get an observable of all PersonDetails in the store.
   */
  getAllPeopleDetails(): Observable<IPeopleCollectionPersonDetails[]> {
    return this.people$;
  }

  /**
   * Get an observable of the personDetails of a particular person.
   *
   * @param hash - hash of the person you want details for
   */
  getPersonDetails(
    hash: string,
  ): Observable<IPeopleCollectionPersonDetails | undefined> {
    return this.store.pipe(select(selectPersonByHash, { hash })).pipe(
      tap(person => {
        // if the person isn't the store already, add it to be requested from
        // the backend.
        if (!person) {
          this.requestFetchPeopleFromBackend([hash]);
        }
      }),
    );
  }
  /**
   * Get an observable of the personDetails of multiple people.
   *
   * @param hashes
   */
  getPeopleDetails(
    hashes: readonly string[],
  ): Observable<IPeopleCollectionPersonDetails[]> {
    return this.store.pipe(select(selectPeopleByHash, { hashes })).pipe(
      tap(people => {
        // if there are missing people, add it to be requested from
        // the backend.
        if (people.length != hashes.length) {
          this.requestFetchPeopleFromBackend(hashes);
        }
      }),
    );
  }

  /**
   * Get PersonDetail objects from store, mapped to Person objects.
   *
   * @param hashes
   */
  getPeople(hashes: readonly string[]): Observable<Person[]> {
    return this.mapPeopleCollectionAsPerson(this.getPeopleDetails(hashes));
  }

  /**
   * Add hashes to the queue and trigger a request to the backend.
   *
   * @param hashes
   */
  async requestFetchPeopleFromBackend(
    hashes: readonly string[],
    forceUpdate: boolean = false,
  ) {
    this.addToQueue(hashes);
    this.fetchSubject.next(forceUpdate);
  }

  /**
   * Fire a request to the backend grabbing any people who have been
   * added to the request queue.
   */
  async fetchPeopleFromBackend(forceUpdate: boolean = false) {
    const request = new GetPersonRequest();
    let needRequest = false;
    const hashes = this.fetchQueue.keys();
    for (const hash of hashes) {
      const person = await this.store
        .pipe(select(selectPersonByHash, { hash }), first())
        .toPromise();
      if (!person || forceUpdate) {
        request.addHash(hash);
        needRequest = true;
      }
      this.fetchQueue.delete(hash);
    }

    // if all the people are already in the store, don't get them again
    if (!needRequest) {
      return;
    }
    const response = await this.peopleCollectionService.getPerson(request);
    const peopleList = response.getPersonList();

    this._addPeopleToCollectionFromProto(peopleList);
  }

  private _addPeopleToCollectionFromProto(
    peopleList: PeopleCollectionPersonDetails[],
  ) {
    for (let i = 0; i <= peopleList.length; i++) {
      const person = peopleList[i];
      if (person) {
        const details =
          IPeopleCollectionPersonDetailsProtoMapper.fromProto(person);
        this.store.dispatch(
          PeopleCollectionActions.addPersonToCollection({ person: details }),
        );
      }
    }
    if (peopleList.length > 0) {
      this.savePeopleCollectionLocally();
    }
  }

  async fetchPeopleByRoleTypes(roleTypes: string[]) {
    const request = new GetPeopleByRolesRequest();
    request.setRoleList(roleTypes);

    const response = await this.peopleCollectionService.getPeopleByRoles(
      request,
    );
    const peopleList = response.getPersonList();

    this._addPeopleToCollectionFromProto(peopleList);
  }

  /**
   * Fire a search request to backend to search for users.
   *
   * @param query
   */
  async makePeopleSearchRequest(query: string) {
    const request = new SearchPeopleCollectionRequest();
    request.setFilter(query);
    this.peopleAutocomplete.setCollection([]);

    const obs: { close(): void } & Observable<SearchPeopleCollectionResponse> =
      this.peopleCollectionService.searchPeopleCollection(request);

    obs.subscribe(
      response => {
        if (response.hasPerson()) {
          const person = response.getPerson();
          const details =
            IPeopleCollectionPersonDetailsProtoMapper.fromProto(person);
          // remove if the person is already in there.
          this.peopleAutocomplete.remove(
            doc => doc && doc.personHash === details.personHash,
          );
          this.peopleAutocomplete.add(details);

          this.store.dispatch(
            PeopleCollectionActions.addPersonToCollection({ person: details }),
          );
        }
      },
      err => {
        console.error(err);
      },
      () => {
        this.savePeopleCollectionLocally();
      },
    );
  }

  /**
   * Save the store contents to local storage.
   */
  private async savePeopleCollectionLocally() {
    const allPeople = await this.people$.pipe(first()).toPromise();
    await peopleCollection.setItem(this.storageKey, allPeople);
  }

  getPeopleByRole(role: string): Observable<IPeopleCollectionPersonDetails[]> {
    return this.people$.pipe(
      map(people => {
        const rolePeople: IPeopleCollectionPersonDetails[] = [];
        return people.filter(person => person.roleType == role);
      }),
    );
  }

  /**
   * Load people collection from local storage and add them to store.
   */
  private async loadPeopleCollectionFromCache() {
    const allPeople: IPeopleCollectionPersonDetails[] =
      await peopleCollection.getItem(this.storageKey);
    if (allPeople && allPeople.length > 0) {
      this.store.dispatch(
        PeopleCollectionActions.addPeopleToCollection({ people: allPeople }),
      );
    }
  }

  /**
   * Return search results for query from people collection.
   * Will fire a request to backend to load new results.
   *
   * @param query
   */
  searchPeopleCollection(
    query: string,
  ): Observable<IPeopleCollectionPersonDetails[]> {
    if (query) {
      this.makePeopleSearchRequest(query);
    }
    // even though we don't actually use anything from this.people$, we use it
    // so that we can tell fuse to re-run the search when we have gotten new
    // data added to the store.
    return this.people$.pipe(
      map(() => this.peopleAutocomplete.search(query)),
      map(result => result.map(row => row.item)),
    );
  }

  /**
   * Search people collection, returning results as a Person[]
   *
   * @param query
   */
  searchPeople(query: string): Observable<Person[]> {
    return this.mapPeopleCollectionAsPerson(this.searchPeopleCollection(query));
  }

  /**
   * Map an observable personDetails[] to Person[]
   */
  mapPeopleCollectionAsPerson(
    people$: Observable<IPeopleCollectionPersonDetails[]>,
  ): Observable<Person[]> {
    const rolesCanMessage$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.DM_ENABLED_ROLES,
    );

    return combineLatest([people$, rolesCanMessage$]).pipe(
      map(([details, rolesSetting]) => {
        const roles: string[] = rolesSetting ? rolesSetting : [];

        return details.map(item => {
          let directMessagesMingaDisabled = false;
          if (!roles.includes(item.roleType)) {
            directMessagesMingaDisabled = true;
          }

          return PeopleMapper.fromIPeopleCollectionPersonDetails(
            item,
            directMessagesMingaDisabled,
          );
        });
      }),
    );
  }
}
