<!-- Actions -->
<div
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true">
  <div class="actions">
    <mg-btn
      variant="text"
      size="small"
      (pressed)="clear()">
      {{ MESSAGE.BUTTON_RESET_FILTERS }}
    </mg-btn>
    <mg-btn
      variant="filled"
      size="small"
      (pressed)="submit()">
      {{ MESSAGE.BUTTON_APPLY_FILTERS }}
    </mg-btn>
  </div>

  <!-- Main content -->
  <div
    *ngIf="data"
    class="main-content">
    <div
      *ngFor="let field of fields"
      class="control-container"
      [attr.data-field]="field">
      <ng-container
        *ngIf="
          data
            | checkForFieldCallback: field:draftFormData$
            | async
            | async as fieldData
        ">
        <!-- Switch between different input types -->
        <ng-container [ngSwitch]="fieldData?.type">
          <!-- Button group input -->
          <mat-button-toggle-group
            *ngSwitchCase="'toggle-group'"
            class="filter-toggle-group always-full-width"
            [ngClass]="{ disabled: fieldData?.disabled }"
            [name]="field"
            [multiple]="false"
            [disabled]="fieldData?.disabled"
            [value]="formGroup.get(field).value"
            (change)="setToggleGroupValue(field, $event)">
            <mat-button-toggle
              *ngFor="let option of $any(fieldData).options"
              [value]="option.value">
              <mg-text variant="body-sm-secondary">
                {{ option.label }}
              </mg-text>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <!-- Text input -->
          <mg-form-text-input
            #formTextInputComponent
            *ngSwitchCase="'text'"
            widthSize="large"
            [name]="field"
            [id]="fieldData?.id || field"
            [isDisabled]="fieldData.disabled"
            [label]="fieldData.label"
            [control]="formGroup.get(field)">
          </mg-form-text-input>
          <!-- Single select input -->
          <mg-form-select
            #formSingleSelectComponent
            *ngSwitchCase="'single-select'"
            [name]="field"
            [placeholder]="fieldData.label"
            [closeOnSelect]="true"
            [isClearable]="true"
            [multiple]="false"
            [id]="fieldData?.id || field"
            [searchable]="true"
            [isFullWidth]="true"
            [disabled]="fieldData.disabled"
            [value]="formGroup.get(field).value"
            [options]="$any(fieldData).options"
            (selectionChanged)="setSelect(field, $any($event))">
          </mg-form-select>
          <!-- Multi select input -->
          <mg-form-select
            #formSelectComponent
            *ngSwitchCase="'multi-select'"
            [name]="field"
            [placeholder]="fieldData.label"
            [closeOnSelect]="true"
            [multiple]="true"
            [id]="fieldData?.id || field"
            [searchable]="true"
            [isFullWidth]="true"
            [disabled]="fieldData.disabled"
            [value]="formGroup.get(field).value"
            [options]="$any(fieldData).options"
            (selectionChanged)="setSelect(field, $any($event))">
          </mg-form-select>
          <!-- User list -->
          <mg-user-list-filter
            *ngSwitchCase="'user-list'"
            [id]="fieldData?.id || field"
            [placeholder]="fieldData.label"
            [value]="formGroup.get(field).value"
            (selectChanged)="setUserList(field, $event)">
          </mg-user-list-filter>
          <!-- People search -->
          <mg-multi-person-search
            *ngSwitchCase="'people-search'"
            [id]="fieldData?.id || field"
            [defaultValue]="getPersonHashes(formGroup.get(field).value)"
            (selectionChanged)="setPerson(field, $event)">
          </mg-multi-person-search>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
