import { IMingaFeatureToggleKeys } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { MingaAppRoutes } from 'src/app/types';

import {
  ModerationComponent,
  ModerationDirectMessagesComponent,
  ModerationPostsComponent,
  ModerationReportsComponent,
} from '@shared/components/moderation';
import { AccessRestrictionsGuard, HasUserGuard } from '@shared/guards';

import { HomeChallengesComponent } from './components/home-challenges/home-challenges.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { HomeFeedComponent } from './components/home-feed/home-feed.component';
import { HomeFilesComponent } from './components/home-files/home-files.component';
import { HomeGalleryComponent } from './components/home-gallery/home-gallery.component';
import { GalleryDataResolver } from './components/home-gallery/services';
import { HomePollsComponent } from './components/home-polls/home-polls.component';
import { HomeRoute } from './constants';
import { HomeComponent } from './home.component';

const moderationAbsolutePath = (v: string) =>
  `/${HomeRoute.ROOT}/${HomeRoute.MODERATION}/${v}`;

export const HOME_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Home',
    },
    children: [
      // Minga feed
      {
        path: '',
        pathMatch: 'full',
        component: HomeFeedComponent,
        canActivate: [HasUserGuard],
        data: {
          title: 'Feed',
          navigationVisibility: true,
          searchScope: 'content',
        },
      },
      // Challenges
      {
        path: HomeRoute.CHALLENGES,
        component: HomeChallengesComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Challenges',
          navigationVisibility: true,
          searchScope: 'content',
          accessRestrictions: {
            featureFlag: {
              only: [IMingaFeatureToggleKeys.COMMUNITY_ENABLED],
            },
            permissions: {
              only: [MingaPermission.CONTENT_CHALLENGE_MANAGE],
            },
          },
        },
      },
      // Polls
      {
        path: HomeRoute.POLLS,
        component: HomePollsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Polls',
          navigationVisibility: true,
          searchScope: 'content',
          accessRestrictions: {
            featureFlag: {
              only: [IMingaFeatureToggleKeys.COMMUNITY_ENABLED],
            },
            permissions: {
              only: [MingaPermission.MINGA_POLLS_MANAGE],
            },
          },
        },
      },
      // My content
      {
        path: HomeRoute.MY_CONTENT,
        component: HomeContentComponent,
        data: {
          title: 'My Content',
          navigationVisibility: true,
          searchScope: 'content',
        },
      },
      // Moderation
      {
        path: HomeRoute.MODERATION,
        component: ModerationComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Moderation',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.CONTENT_REPORTS_MANAGE],
            },
          },
        },
        children: [
          {
            path: '',
            component: ModerationPostsComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'All posts',
              navigationVisibility: true,
              baseUrl: moderationAbsolutePath(''),
              globalContent: false,
              accessRestrictions: {
                permissions: {
                  atLeastOne: [MingaPermission.CONTENT_REPORTS_MANAGE],
                },
              },
            },
          },
          {
            path: HomeRoute.MODERATION_REPORTS,
            component: ModerationReportsComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'Reported content',
              navigationVisibility: true,
              baseUrl: moderationAbsolutePath(HomeRoute.MODERATION_REPORTS),
              globalContent: false,
              accessRestrictions: {
                permissions: {
                  atLeastOne: [MingaPermission.CONTENT_REPORTS_MANAGE],
                },
              },
            },
          },
          {
            path: HomeRoute.MODERATION_DIRECT_MESSAGES,
            component: ModerationDirectMessagesComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'Direct messages',
              navigationVisibility: true,
              searchScope: 'conversations',
              baseUrl: moderationAbsolutePath(
                HomeRoute.MODERATION_DIRECT_MESSAGES,
              ),
              accessRestrictions: {
                featureFlag: {
                  only: [IMingaFeatureToggleKeys.DM_ENABLED],
                },
                permissions: {
                  atLeastOne: [MingaPermission.CONTENT_REPORTS_MANAGE],
                },
              },
            },
          },
        ],
      },
      // Files
      {
        path: HomeRoute.FILES,
        component: HomeFilesComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Files',
          navigationVisibility: true,
          accessRestrictions: {
            featureFlag: {
              only: [IMingaFeatureToggleKeys.COMMUNITY_ENABLED],
            },
          },
        },
      },
      // Photo gallery
      {
        path: HomeRoute.GALLERY,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Photo Gallery',
          navigationVisibility: true,
          accessRestrictions: {
            featureFlag: {
              only: [IMingaFeatureToggleKeys.PHOTO_GALLERY_ENABLED],
            },
          },
        },
        children: [
          {
            path: '',
            resolve: { galleryData: GalleryDataResolver },
            component: HomeGalleryComponent,
            data: { title: 'Photo Gallery' },
          },
          {
            path: 'event/:eventContextHash',
            resolve: { galleryData: GalleryDataResolver },
            component: HomeGalleryComponent,
            data: { title: 'Event Photo Gallery' },
          },
          {
            path: 'event/:eventContextHash/:photoHash',
            resolve: { galleryData: GalleryDataResolver },
            component: HomeGalleryComponent,
            data: { title: 'Event Photo Gallery' },
          },
          {
            path: 'group/:groupHash',
            resolve: { galleryData: GalleryDataResolver },
            component: HomeGalleryComponent,
            data: { title: 'Group Photo Gallery' },
          },
        ],
      },
      {
        path: HomeRoute.PROGRAMS,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Programs',
          navigationVisibility: true,
          viewMode: 'default',
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_PROGRAMS_MANAGE],
            },
          },
        },
        loadChildren: () =>
          import('@modules/programs').then(m => m.ProgramModule),
      },
    ],
  },
];
