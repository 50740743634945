import { IMingaFeatureToggleKeys } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';

import {
  BehaviorManagerRoutes,
  BehaviorManagerMessages,
} from '@modules/behavior-manager/constants';
import {
  FlexTimeManagerMessages,
  FlexTimeManagerRoutes,
} from '@modules/flex-time-manager/constants';
import {
  PointsManagerMessages,
  PointsManagerRoutes,
} from '@modules/points-manager/constants';

import { ManagerOverlayRoutesPage } from './manager-overlay.types';

export const CommonManagerOverlayRoutes: ManagerOverlayRoutesPage[] = [
  {
    path: 'minga-manager',
    label: 'Minga Manager',
    permissions: [MingaPermission.MINGA_ACCOUNT_MANAGE],
  },
  {
    path: 'people-manager',
    label: 'People Manager',
    permissions: [MingaPermission.MINGA_PEOPLE_MANAGE],
  },
  {
    path: 'studentid',
    label: 'ID Manager',
    featureFlag: IMingaFeatureToggleKeys.STUDENT_ID_ENABLED,
    permissions: [
      MingaPermission.MINGA_PEOPLE_MANAGE,
      MingaPermission.ADMINISTER_STUDENT_ID,
      MingaPermission.VIEW_STUDENT_ID_DASHBOARD,
    ],
  },
  {
    path: 'hall-pass',
    label: 'Hall Pass Manager',
    featureFlag: IMingaFeatureToggleKeys.HALLPASS_ENABLED,
    permissions: [MingaPermission.HALL_PASS_MANAGE],
  },
  {
    path: 'checkin',
    label: 'Check In Manager',
    featureFlag: IMingaFeatureToggleKeys.CHECKIN_ENABLED,
    permissions: [MingaPermission.CHECKIN_TYPE_MANAGE],
  },
  {
    path: BehaviorManagerRoutes.ROOT,
    label: BehaviorManagerMessages.PAGE_TITLE,
    featureFlag: IMingaFeatureToggleKeys.TRACKING_ENABLED,
    permissions: [MingaPermission.PBIS_VIEW_REPORTS],
  },
  {
    path: PointsManagerRoutes.ROOT,
    label: PointsManagerMessages.PAGE_TITLE,
    featureFlag: IMingaFeatureToggleKeys.TRACKING_ENABLED,
    permissions: [MingaPermission.POINTS_VIEW_REPORTS],
  },
  {
    path: FlexTimeManagerRoutes.ROOT,
    label: FlexTimeManagerMessages.PAGE_TITLE,
    featureFlag: IMingaFeatureToggleKeys.FLEX_TIME_ENABLED,
    permissions: [
      MingaPermission.FLEX_TIME_MANAGE,
      MingaPermission.FLEX_TIME_VIEW_REPORTS,
      MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
    ],
  },
];

export enum ManagerOverlayColorTheme {
  ID_MANAGER = 'id',
  HALL_PASS_MANAGER = 'hall-pass',
  MINGA_MANGER = 'minga-manager',
  COMMUNITY = 'community',
  PBIS_MANAGER = 'pbis',
  CHECKIN_MANAGER = 'checkin',
  PEOPLE_MANAGER = 'people',
  FLEX_TIME_MANAGER = 'flex-time',
  TEACHER_TOOLS = 'teacher-tools',
  STUDENT_TOOLS = 'student-tools',
}
