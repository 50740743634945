import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IMingaFeatureToggleKeys } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { mingaSettingTypes } from 'minga/libraries/util';
import { MingaAppRoutes } from 'src/app/types';
import { UserPreferencesRouteComponent } from 'src/app/user-preferences/containers/UserPreferencesRoute';

import { TtMyClassComponent } from '@modules/teacher-tools/components/tt-my-class/tt-my-class.component';

import { AccessRestrictionsGuard } from '@shared/guards/access-restrictions.guard';

import { ToolsBehavioursComponent } from './components/tools-behaviours/tools-behaviours.component';
import { ToolsFlexTimeComponent } from './components/tools-flex-time/tools-flex-time.component';
import { ToolsMyToolsComponent } from './components/tools-my-tools/tools-my-tools.component';
import { ToolsNotificationsComponent } from './components/tools-notifications/tools-notifications.component';
import { ToolsLeaderboardComponent } from './components/tools-points/components/tools-leaderboard/tools-leaderboard.component';
import { ToolsRewardsComponent } from './components/tools-points/components/tools-rewards/tools-rewards.component';
import { ToolsYourPointsComponent } from './components/tools-points/components/tools-your-points/tools-your-points.component';
import { ToolsPointsComponent } from './components/tools-points/tools-points.component';
import { ToolsRootComponent } from './components/tools-root/tools-root.component';
import { ToolsScheduleComponent } from './components/tools-schedule/tools-schedule.component';
import { ToolsModule } from './tools.module';
import { ToolsRoutes } from './tools.types';

const routes: MingaAppRoutes = [
  {
    path: '',
    component: ToolsRootComponent,
    data: {
      title: 'My Tools',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ToolsMyToolsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Dashboard',
          navigationVisibility: true,
          enableAutoRedirect: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.STUDENT_TOOLS,
                MingaPermission.TEACHER_TOOLS,
                MingaPermission.ADMIN_TOOLS,
              ],
            },
          },
        },
      },
      {
        path: ToolsRoutes.MY_CLASS,
        component: TtMyClassComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'My Class',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.TEACHER_TOOLS,
                MingaPermission.ADMIN_TOOLS,
              ],
            },
          },
        },
      },
      {
        path: ToolsRoutes.SCHEDULE,
        component: ToolsScheduleComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'My Schedule',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.STUDENT_TOOLS],
            },
            configFlag: {
              only: ['studentScheduleEnabled'],
            },
          },
        },
      },
      // Points
      {
        path: ToolsRoutes.POINTS,
        component: ToolsPointsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Points',
          navigationVisibility: true,
          accessRestrictions: {
            featureFlag: {
              only: [IMingaFeatureToggleKeys.TRACKING_ENABLED],
            },
            permissions: {
              atLeastOne: [
                MingaPermission.STUDENT_TOOLS,
                MingaPermission.TEACHER_TOOLS,
              ],
            },
          },
        },
        children: [
          {
            path: '',
            redirectTo: ToolsRoutes.YOUR_POINTS,
            pathMatch: 'full',
          },
          {
            path: ToolsRoutes.YOUR_POINTS,
            component: ToolsYourPointsComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'My Points',
              enableAutoRedirect: true,
              accessRestrictions: {
                featureFlag: {
                  only: [IMingaFeatureToggleKeys.TRACKING_ENABLED],
                },
              },
            },
          },
          {
            path: ToolsRoutes.LEADERBOARD,
            component: ToolsLeaderboardComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'Leaderboard',
              accessRestrictions: {
                featureFlag: {
                  only: [IMingaFeatureToggleKeys.TRACKING_ENABLED],
                },
                settings: {
                  only: [mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD],
                },
              },
            },
          },
          {
            path: ToolsRoutes.REWARDS,
            component: ToolsRewardsComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'Rewards',
              accessRestrictions: {
                featureFlag: {
                  only: [IMingaFeatureToggleKeys.TRACKING_ENABLED],
                },
                settings: {
                  only: [mingaSettingTypes.PM_REWARDS_STORE],
                },
              },
            },
          },
        ],
      },
      {
        path: ToolsRoutes.NOTIFICATIONS,
        component: ToolsNotificationsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Notifications',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.STUDENT_TOOLS,
                MingaPermission.TEACHER_TOOLS,
                MingaPermission.IS_PARENT,
                MingaPermission.ADMIN_TOOLS,
              ],
            },
          },
        },
      },
      // FlexTime
      {
        path: ToolsRoutes.FLEX_TIME,
        component: ToolsFlexTimeComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'FlexTime',
          navigationVisibility: true,
          accessRestrictions: {
            featureFlag: {
              only: [IMingaFeatureToggleKeys.FLEX_TIME_ENABLED],
            },
            permissions: {
              atLeastOne: [MingaPermission.STUDENT_TOOLS],
            },
          },
        },
      },
      // Behaviors
      {
        path: ToolsRoutes.BEHAVIOURS,
        component: ToolsBehavioursComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Behaviors',
          navigationVisibility: true,
          accessRestrictions: {
            featureFlag: {
              only: [IMingaFeatureToggleKeys.TRACKING_ENABLED],
            },
            permissions: {
              atLeastOne: [MingaPermission.STUDENT_TOOLS],
            },
          },
        },
      },
      {
        path: ToolsRoutes.PREFERENCES,
        component: UserPreferencesRouteComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Preferences',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.STUDENT_TOOLS,
                MingaPermission.TEACHER_TOOLS,
                MingaPermission.IS_PARENT,
                MingaPermission.ADMIN_TOOLS,
              ],
            },
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [ToolsModule, RouterModule.forChild(routes)],
})
export class ToolsRoutesModule {}
