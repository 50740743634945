<mg-layout-legacy-content>
  <mg-stream-scroller
    [filter]="feedFilter"
    [service]="contentFeed"
    [mgTrimmed]="true"
    mgStreamControl="challengesMingaFeed"
    rpcStream="streamContentFeed"
    rpcControl="streamContentFeedControl">
    <ng-template
      let-item
      let-itemId="itemId"
      mgStreamItem>
      <mg-challenge-short-card
        [content]="item.shortChallengeCard"
        [context]="itemId"
        (challengeClick)="onChallengeClick($event)"
        (challengeResponseClick)="onChallengeResponseClick($event)">
      </mg-challenge-short-card>
    </ng-template>

    <mg-empty-state
      mg-stream-empty
      [title]="'emptyState.manageChallengesTitle' | translate"
      [subtitle]="
        canCreateChallenges
          ? ('emptyState.manageChallengesSubtitle' | translate)
          : ''
      "></mg-empty-state>
  </mg-stream-scroller>

  <ng-template #action>
    <mg-home-action-button></mg-home-action-button>
  </ng-template>
</mg-layout-legacy-content>
