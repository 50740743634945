import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { PendingInvitationsDialogService } from 'minga/app/src/app/dialog/PendingInvitationsDialog';
import { EventContentService } from 'minga/app/src/app/minimal/services/EventContent';
import { NotificationActions } from 'minga/app/src/app/store/Notifications/actions';
import { NotificationStoreModel } from 'minga/app/src/app/store/Notifications/model';
import { EventStatus } from 'minga/libraries/domain';
import { INotificationType } from 'minga/libraries/domain';
import { Notification } from 'minga/proto/gateway/notification_pb';

import { NotificationListenerBaseService } from '../NotificationListenerBase.service';

/**
 * Listen to incoming notifications to look for invite notifications
 */
@Injectable({ providedIn: 'root' })
export class InviteNotificationListenerService
  extends NotificationListenerBaseService
  implements OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(undefined);

  // track whether an invite notification has already been launched
  // recently, so we can avoid launching it multiple times if we got
  // invited to a few events.
  private inviteCount = 0;

  constructor(
    private store: Store<any>,
    private pendingInviteService: PendingInvitationsDialogService,
    private eventContentService: EventContentService,
  ) {
    super(store);

    this.notifications$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(notification => {
        this._handleNotification(notification);
      });
  }

  private async _handleNotification(msg: Notification.AsObject) {
    const notificationType = $enum(INotificationType).asValueOrThrow(
      msg.notificationType,
    );

    if (this.isEventInviteNotification(notificationType)) {
      const contextHash = msg.actionOpenContent?.contentContextHash;
      if (contextHash) {
        const currentStatus = this.eventContentService.getCheckIn(contextHash);
        if (!currentStatus) {
          // if this event isnt currently in the user's checkin list,
          // add them with status invited.
          this.eventContentService.setCheckIn(contextHash, EventStatus.INVITED);
        }
      }
      const notification: NotificationStoreModel = { id: msg.notificationId };
      this.store.dispatch(
        new NotificationActions.MarkNotificationAsReadAction(notification),
      );
      if (this.inviteCount == 0) {
        this.inviteCount = 1;

        // wait 5s because that should cover any invites we got in this batch
        setTimeout(() => {
          const dialogRef = this.pendingInviteService.open({
            data: { invitationCount: this.inviteCount },
          });
        }, 5000);
      } else {
        this.inviteCount++;
      }
    }
  }

  isEventInviteNotification(notificationType: INotificationType) {
    return notificationType == INotificationType.EVENT_INVITE;
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
