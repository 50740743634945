<!-- Modal Container -->
<div
  #assignerContainer
  class="container">
  <mg-overlay-primary
    size="full"
    [isLoading]="false"
    [overlayTitle]="modalConfig?.formTitle"
    [skipLoadCheck]="true"
    (closeModal)="close()">
    <ng-container [ngTemplateOutlet]="mainContent"></ng-container>
    <ng-template #overlayFooter>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </ng-template>
  </mg-overlay-primary>
</div>

<!-- Main Content Template -->
<ng-template #mainContent>
  <ng-container [ngTemplateOutlet]="buttonToggleGroupTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="filterFieldTemplate"></ng-container>
  <ng-container
    *ngIf="(saService.isLoading$ | async) === false; else loadingTemplate"
    [ngTemplateOutlet]="carouselTemplate"></ng-container>
  <ng-container
    *ngIf="(saService.isLoading$ | async) === false"
    [ngTemplateOutlet]="emptyStateFilterTemplate"></ng-container>
  <ng-container *ngIf="(carouselList$ | async).length > 0">
    <router-outlet #assignerForm></router-outlet>
  </ng-container>
</ng-template>

<!-- Footer Template -->
<ng-template #footerTemplate>
  <div
    class="footer-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="8px">
    <mg-btn
      variant="text"
      size="large"
      (pressed)="back()">
      Back
    </mg-btn>
    <mg-btn
      variant="filled"
      size="large"
      [disabled]="!saService.isValid"
      (pressed)="submit()">
      {{ submitButtonLabel }}
    </mg-btn>
  </div>
</ng-template>

<!-- Button Toggle Group Template -->
<ng-template #buttonToggleGroupTemplate>
  <div
    *ngIf="modalConfig && modalConfig.tabbedHeader"
    class="toggle-container">
    <mat-button-toggle-group
      class="toggle-group filter-toggle-group"
      [multiple]="false">
      <ng-container
        *ngFor="let tab of modalConfig.data | saTabPermissionCheck | async">
        <ng-container
          [ngTemplateOutlet]="buttonToggleTemplate"
          [ngTemplateOutletContext]="{ $implicit: tab }">
        </ng-container>
      </ng-container>
    </mat-button-toggle-group>
  </div>
</ng-template>

<!-- Button Toggle Template -->
<ng-template
  #buttonToggleTemplate
  let-tab>
  <mat-button-toggle
    class="toggle-button-wrapper"
    [class.toggle-selected]="
      tab.selector === (saService.activeSelector$ | async)
    "
    [style.border-radius.rem]="0"
    [checked]="tab.selector === (saService.activeSelector$ | async)"
    (click)="saService.activeSelectorSubject.next(tab.selector)">
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="4px"
      class="toggle-button-label"
      [style.--mg-generic-icon-color]="tab.color">
      <ng-container
        *ngIf="!['xs', 'sm'].includes(media$ | async); else toggleMobileLabel">
        <mg-icon>{{ tab.icon }}</mg-icon>
        <mg-text variant="body-sm-secondary">{{ tab.title }}</mg-text>
      </ng-container>
    </div>
    <ng-template #toggleMobileLabel>
      <mg-text
        variant="header-sm-secondary"
        textAlign="center"
        fontWeight="700"
        textTransform="capitalize">
        {{ tab.title }}
      </mg-text>
    </ng-template>
  </mat-button-toggle>
</ng-template>

<!-- Filter Field Template -->
<ng-template #filterFieldTemplate>
  <ng-container *ngIf="pageData?.filterField">
    <div class="filter-wrapper">
      <mg-form-select
        [placeholder]="filterMsgs.filterPlaceholder"
        [closeOnSelect]="true"
        [multiple]="false"
        [searchable]="true"
        [options]="saService.filterableCarouselData$ | async"
        (selectionChanged)="filterValueSelected($event)">
      </mg-form-select>
    </div>
  </ng-container>
</ng-template>

<!-- Carousel Template -->
<ng-template #carouselTemplate>
  <div
    *ngIf="carouselList$ | async as tiles; else loadingTemplate"
    class="carousel-container">
    <ng-container *ngIf="tiles?.length">
      <mg-tiles-carousel
        [showPagination]="true"
        [stylePreset]="presetCarouselLayout$ | async"
        [gutter]="0">
        <ng-container
          *ngFor="let tile of tiles"
          [ngTemplateOutlet]="carouselTileTemplate"
          [ngTemplateOutletContext]="{ $implicit: tile }">
        </ng-container>
      </mg-tiles-carousel>
    </ng-container>
  </div>
</ng-template>

<!-- Carousel Tile Template -->
<ng-template
  #carouselTileTemplate
  let-data>
  <mg-sa-tile
    [tile]="data"
    [selected]="data.id === typeControl.value?.id"
    (pressed)="typeControl.setValue(data)">
  </mg-sa-tile>
</ng-template>

<!-- Empty State Template -->
<ng-template #emptyStateFilterTemplate>
  <div
    *ngIf="(carouselList$ | async).length === 0"
    fxLayout="column"
    fxLayoutAlign="space-evenly center"
    class="filter-empty-state">
    <mg-text
      variant="header-md"
      textAlign="center"
      fontWeight="700"
      color="grey-500">
      {{ filterMsgs.filterEmptyHeading }}
    </mg-text>
    <mg-text
      variant="body-md"
      textAlign="center"
      fontWeight="400"
      color="grey-500">
      {{ filterMsgs.filterEmptyMsg }}
    </mg-text>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <mg-spinner
    class="spinner"
    [diameter]="50"></mg-spinner>
</ng-template>
