"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const hall_pass_stats_pb_1 = require("minga/proto/hall_pass_stats/hall_pass_stats_pb");
exports.toProto = (filter, limit, offset) => {
    var _a, _b, _c, _d;
    const filterProto = new hall_pass_stats_pb_1.HallPassReportFilters();
    filterProto.setStudentCreated(filter.createdBy);
    filterProto.setIssuedByList(filter.issuedBy);
    filterProto.setIssuedToList(filter.issuedTo);
    filterProto.setPassTypeList(filter.hallpassType);
    filterProto.setUserListList(filter.userList);
    if (filter.startDate)
        filterProto.setStartDate(shared_grpc_1.dateObjectToDateTimeMessage(filter.startDate.toDate()));
    if (filter.endDate)
        filterProto.setEndDate(shared_grpc_1.dateObjectToDateTimeMessage(filter.endDate.toDate()));
    if ((_a = filter.status) === null || _a === void 0 ? void 0 : _a.length)
        filterProto.setStatusList(filter.status);
    if ((_b = filter.deniedBy) === null || _b === void 0 ? void 0 : _b.length)
        filterProto.setDeniedByList(filter.deniedBy);
    if ((_c = filter.deniedByBlackout) === null || _c === void 0 ? void 0 : _c.length)
        filterProto.setDeniedByBlackoutList(filter.deniedByBlackout);
    if ((_d = filter.deniedByNoParty) === null || _d === void 0 ? void 0 : _d.length)
        filterProto.setDeniedByNoPartyList(filter.deniedByNoParty);
    if (limit)
        filterProto.setLimit(limit);
    if (offset)
        filterProto.setOffset(offset);
    return filterProto;
};
exports.fromProto = (proto) => {
    const startDateProto = proto.getStartDate();
    const endDateProto = proto.getEndDate();
    let startDate;
    let endDate;
    if (startDateProto)
        startDate = shared_grpc_1.dateTimeMessageToDayjsObject(startDateProto);
    if (endDateProto)
        endDate = shared_grpc_1.dateTimeMessageToDayjsObject(endDateProto);
    const statusList = proto.getStatusList();
    const status = [];
    if (statusList === null || statusList === void 0 ? void 0 : statusList.length) {
        statusList.forEach(statusProto => {
            status.push(ts_enum_util_1.$enum(domain_1.HallPassStatusEnum).asValueOrThrow(statusProto));
        });
    }
    return {
        startDate,
        endDate,
        createdBy: ts_enum_util_1.$enum(domain_1.HallPassStudentCreatedFilter).asValueOrThrow(proto.getStudentCreated()),
        issuedBy: proto.getIssuedByList(),
        issuedTo: proto.getIssuedToList(),
        hallpassType: proto.getPassTypeList(),
        limit: proto.getLimit(),
        offset: proto.getOffset(),
        userList: proto.getUserListList(),
        status,
        deniedBy: proto.getDeniedByList(),
        deniedByBlackout: proto.getDeniedByBlackoutList(),
        deniedByNoParty: proto.getDeniedByNoPartyList(),
    };
};