import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import { StreamManager } from 'minga/app/src/app/minimal/services/StreamManager';
import { ChallengeApprovalState } from 'minga/libraries/domain';
import { ChallengeResponse as ChallengeResponseProto } from 'minga/proto/content/challenge/challenge_response_ng_grpc_pb';
import {
  ApproveOrDeclineResponseRequest,
  ChallengeResponseCard,
} from 'minga/proto/content/challenge/challenge_response_pb';

@Component({
  selector: 'mg-challenge-approval-controls',
  templateUrl: './ChallengeResponseApprovalControls.component.html',
  styleUrls: ['./ChallengeResponseApprovalControls.component.scss'],
})
export class ChallengeResponseApprovalControlsComponent {
  @Input()
  currentState: ChallengeApprovalState = ChallengeApprovalState.PENDING;

  @Input()
  contextHash: string | null = null;

  @ViewChild('declineOverlay', { static: false })
  declineOverlay?: TemplateRef<any>;

  @Output()
  approved: EventEmitter<string> = new EventEmitter();

  @Output()
  declined: EventEmitter<string> = new EventEmitter();

  declineOverlayRef?: MatDialogRef<any, any>;
  declineResponseReason?: string;

  constructor(
    public responseProto: ChallengeResponseProto,
    private streamManager: StreamManager,
    private dialogManager: MatDialog,
    private rootService: RootService,
  ) {}

  isApproved(): boolean {
    return this.currentState == ChallengeApprovalState.APPROVED;
  }

  isDenied(): boolean {
    return this.currentState == ChallengeApprovalState.DENIED;
  }

  isPending(): boolean {
    return this.currentState == ChallengeApprovalState.PENDING;
  }

  async onApproveClicked(event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    if (this.contextHash) {
      await this.setApprovedValue(
        this.contextHash,
        ChallengeApprovalState.APPROVED,
      );
      this.approved.emit(this.contextHash);
    }
  }

  onDeclineClicked(event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    if (this.declineOverlay && this.contextHash) {
      this.declineOverlayRef = this.dialogManager.open(this.declineOverlay, {
        height: '410px',
        width: '560px',
      });
      this.declined.emit(this.contextHash);
    }
  }

  async setApprovedValue(
    contextHash: string,
    approved: ChallengeApprovalState,
  ) {
    const message = new ApproveOrDeclineResponseRequest();
    message.setContextHash(contextHash);
    message.setApproved(approved);
    if (this.declineResponseReason) {
      message.setReasonDeclined(this.declineResponseReason);
    }

    await this.rootService.addLoadingPromise(
      this.responseProto.approveOrDeclineResponse(message),
    );

    await this.streamManager.restartStreamIfAvailable(
      'challengesAdminResponsesFeed',
    );
  }

  async onSendDeclinedClicked() {
    if (!this.declineOverlayRef) {
      return;
    }

    this.declineOverlayRef.close();

    if (this.contextHash) {
      await this.setApprovedValue(
        this.contextHash,
        ChallengeApprovalState.DENIED,
      );
    }
  }
}
