"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ABSENTEES = exports.REASON_HISTORY = void 0;
const utils_1 = require("minga/libraries/shared/reports_columns/utils/index");
const columns_constants_1 = require("minga/libraries/shared/reports_columns/constants/columns.constants");
const common_constants_1 = require("minga/libraries/shared/reports_columns/constants/common.constants");
const reason = {
    header: 'Reason',
    key: 'checkinReasonName',
    tooltip: true,
    accessorFn: (row) => row.reasonName || '',
    type: 'text',
    width: 'flex-large',
};
exports.REASON_HISTORY = [
    reason,
    Object.assign(Object.assign({}, columns_constants_1.firstName), { accessorFn: (row) => utils_1.assignedToAccessorFn(row, 'first') }),
    Object.assign(Object.assign({}, columns_constants_1.lastName), { accessorFn: (row) => utils_1.assignedToAccessorFn(row, 'last') }),
    columns_constants_1.studentId,
    columns_constants_1.grade,
    columns_constants_1.role,
    columns_constants_1.note,
    columns_constants_1.currentClass,
    columns_constants_1.sectionId,
    columns_constants_1.periodId,
    columns_constants_1.termId,
    {
        header: 'Issued by',
        key: common_constants_1.NameColumnKeys.TEACHER_NAME,
        tooltip: true,
        accessorFn: (row, value) => {
            return utils_1.nameFinder(row.checkinBy, value) || 'Automation';
        },
        type: 'text',
        width: 'flex-medium',
    },
    {
        header: 'Date',
        key: common_constants_1.DateColumnKeys.CHECKIN_DATE,
        accessorFn: (row) => {
            return row.checkinTime;
        },
        sort: true,
        type: 'date',
        width: 'medium',
    },
    {
        header: 'Time',
        key: common_constants_1.TimeColumnKeys.TIME,
        accessorFn: (row) => {
            return row.checkinTime;
        },
        type: 'date',
        width: 'small',
    },
    // Commented out while we are not using the checkout feature.
    // {
    //   header: 'Checked Out By',
    //   key: NameColumnKeys.TEACHER_NAME_2,
    //   tooltip: true,
    //   accessorFn: (row: Checkin, name?: ExportNameType) => {
    //     return row.checkoutBy ? nameFinder(row.checkoutBy, name) || '' : '';
    //   },
    //   columnClasses: ['w-md-flex'],
    // },
    // {
    //   header: 'Check Out Date',
    //   key: 'checkOutDate',
    //   accessorFn: (row: Checkin) => {
    //     return getDate(row.checkoutTime);
    //   },
    //   columnClasses: ['w-sm-width'],
    // },
    // {
    //   header: 'Check Out Time',
    //   key: 'checkOutTime',
    //   accessorFn: (row: Checkin) => {
    //     return getTime(row.checkoutTime);
    //   },
    //   columnClasses: ['w-sm-width'],
    // },
    {
        header: 'Last Modified',
        key: common_constants_1.DateColumnKeys.MANUALLY_UPDATED_AT,
        type: 'date',
        width: 'medium',
    },
];
exports.ABSENTEES = [
    Object.assign(Object.assign({}, reason), { accessorFn: (row) => { var _a; return ((_a = row.reason) === null || _a === void 0 ? void 0 : _a.name) || ''; } }),
    columns_constants_1.firstName,
    columns_constants_1.lastName,
    columns_constants_1.studentId,
    columns_constants_1.grade,
    columns_constants_1.role,
];