<section>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="40px"
    fxLayoutGap.lt-md="20px">
    <div fxFlex="50">
      <ng-container *ngTemplateOutlet="settingsTemplate"></ng-container>
    </div>
    <div fxFlex="50">
      <ng-container *ngTemplateOutlet="modulesTemplate"></ng-container>
    </div>
  </div>
</section>

<!-- Modules Template -->
<ng-template #modulesTemplate>
  <div *ngIf="mingaDashboardInfo$ | async as mingaInfo">
    <mg-mm-dashboard-modules
      [mingaHash]="mingaInfo.hash"
      [canUpdateInstantly]="true">
    </mg-mm-dashboard-modules>
  </div>
</ng-template>

<!-- Settings Template -->
<ng-template #settingsTemplate>
  <div class="settings">
    <div *ngIf="settingsService.getAllSettingsAsObject() | async as settings">
      <div *ngIf="mingaDashboardInfo$ | async as mingaInfo">
        <!-- Expansion Panel: Posting Preferences -->
        <section class="section expansion">
          <mat-expansion-panel
            mgExpansionPanel
            *ngIf="featureToggles.communityEnabled">
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_POSTING }}
              </mg-text>
            </mat-expansion-panel-header>
            <div>
              Allow the following roles to post to the main feed:
              <mg-multi-select-role
                [roles]="availableRoles$ | async"
                [selectedRoles]="mainFeedRoles$ | async"
                (roleSelect)="selectMainFeedRole($event)"
                (roleDeselect)="deselectMainFeedRole($event)">
              </mg-multi-select-role>

              Allow the following roles to upload custom videos:
              <mg-multi-select-role
                [roles]="availableVideoUploadRoles$ | async"
                [selectedRoles]="videoUploadRoles$ | async"
                (roleSelect)="selectVideoUploadRole($event)"
                (roleDeselect)="deselectVideoUploadRole($event)">
              </mg-multi-select-role>

              Allow the following roles to comment by default:
              <mg-multi-select-role
                [roles]="availableCommentRoles$ | async"
                [selectedRoles]="commentRoles$ | async"
                (roleSelect)="selectCommentRole($event)"
                (roleDeselect)="deselectCommentRole($event)">
              </mg-multi-select-role>
            </div>
          </mat-expansion-panel>
        </section>

        <!-- Expansion panel: Photo Gallery Preferences-->
        <section class="section expansion">
          <mat-expansion-panel
            mgExpansionPanel
            *ngIf="featureToggles.communityEnabled">
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_GALLERY }}
              </mg-text>
            </mat-expansion-panel-header>
            <mat-slide-toggle
              [(ngModel)]="featureToggles.photoGalleryEnabled"
              (ngModelChange)="
                updateFeatureToggle(
                  FEATURE_TOGGLE_TYPES.PHOTO_GALLERY_ENABLED,
                  $event
                )
              ">
              Enable Photo Gallery
              <mg-tooltip
                label="{{ MESSAGES.TOOLTIP_PHOTO_GALLERY }}"></mg-tooltip>
            </mat-slide-toggle>
            Allow the following roles to add photos to the gallery:
            <mg-multi-select-role
              [roles]="availableGalleryRoles$ | async"
              [selectedRoles]="galleryRoles$ | async"
              (roleSelect)="selectGalleryFeedRole($event)"
              (roleDeselect)="deselectGalleryFeedRole($event)">
            </mg-multi-select-role>
          </mat-expansion-panel>
        </section>

        <!-- Expansion panel: Group Preferences -->
        <section class="section expansion">
          <mat-expansion-panel
            mgExpansionPanel
            *ngIf="featureToggles.communityEnabled">
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_GROUP }}
              </mg-text>
            </mat-expansion-panel-header>
            Allow the following roles to add groups to your Minga:
            <mg-multi-select-role
              [roles]="availableGroupCreateRoles$ | async"
              [selectedRoles]="groupCreateRoles$ | async"
              (roleSelect)="selectGroupCreateRole($event)"
              (roleDeselect)="deselectGroupCreateRole($event)">
            </mg-multi-select-role>
          </mat-expansion-panel>
        </section>

        <section class="section expansion">
          <mat-expansion-panel mgExpansionPanel>
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_DATE_PRESETS }}
              </mg-text>
            </mat-expansion-panel-header>
            <div fxLayoutAlign="space-between center">
              <span> Customize date range presets for date picker </span>
              <mg-btn
                variant="outlined"
                size="small"
                (pressed)="openPresets()">
                Manage
              </mg-btn>
            </div>
          </mat-expansion-panel>
        </section>

        <!-- Expansion panel: Direct Messages -->
        <section class="section expansion">
          <mat-expansion-panel
            mgExpansionPanel
            *ngIf="featureToggles.communityEnabled">
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_MESSAGING }}
              </mg-text>
            </mat-expansion-panel-header>
            <mat-slide-toggle
              [(ngModel)]="featureToggles.directMessagingEnabled"
              (ngModelChange)="
                updateFeatureToggle(FEATURE_TOGGLE_TYPES.DM_ENABLED, $event)
              ">
              Enable Direct Messaging
              <mg-tooltip
                label="{{ MESSAGES.TOOLTIP_DIRECT_MESSAGE }}"></mg-tooltip>
            </mat-slide-toggle>
            <ng-container *ngIf="featureToggles.directMessagingEnabled">
              <mat-slide-toggle
                [(ngModel)]="
                  settings[
                    mingaSettingTypes
                      .FEATURE_DIRECT_MESSAGING_PERSONAL_PREFERENCE
                  ]
                "
                (ngModelChange)="
                  updateSetting(
                    mingaSettingTypes.FEATURE_DIRECT_MESSAGING_PERSONAL_PREFERENCE,
                    $event
                  )
                ">
                Allow users to disable direct messaging in their preferences
              </mat-slide-toggle>
              <ng-container *ngFor="let dmSetting of dmSettings$ | async">
                <mat-slide-toggle
                  [ngModel]="dmSetting.enabled"
                  [disabled]="dmSetting.immutable"
                  (ngModelChange)="
                    updateMessagingSettings(dmSetting.roleTypeGroups, $event)
                  ">
                  <div translate>{{ dmSetting.localeTitle }}</div>
                </mat-slide-toggle>
              </ng-container>
            </ng-container>
          </mat-expansion-panel>
        </section>

        <!-- Expansion panel: Moderation -->
        <section class="section expansion">
          <mat-expansion-panel
            mgExpansionPanel
            *ngIf="featureToggles.communityEnabled">
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_MODERATION }}
              </mg-text>
            </mat-expansion-panel-header>
            <mat-slide-toggle
              [(ngModel)]="settings[mingaSettingTypes.MODERATION_ENABLED]"
              (ngModelChange)="
                updateSetting(mingaSettingTypes.MODERATION_ENABLED, $event)
              ">
              Enable Moderation
              <mg-tooltip
                label="{{ MESSAGES.TOOLTIP_MODERATION }}"></mg-tooltip>
            </mat-slide-toggle>
            <ng-container
              *ngIf="settings[mingaSettingTypes.MODERATION_ENABLED]">
              <mat-slide-toggle
                [(ngModel)]="settings[mingaSettingTypes.MODERATION_BLOCK]"
                (ngModelChange)="
                  updateSetting(mingaSettingTypes.MODERATION_BLOCK, $event)
                ">
                Enable Blocking Flagged Content
                <mg-tooltip
                  label="{{ MESSAGES.TOOLTIP_MODERATION_BLOCK }}"></mg-tooltip>
              </mat-slide-toggle>
            </ng-container>
          </mat-expansion-panel>
        </section>

        <!-- Expansion Panel: Minga Resets -->
        <section class="section expansion">
          <mat-expansion-panel mgExpansionPanel>
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_RESETS }}
              </mg-text>
            </mat-expansion-panel-header>
            <div
              fxLayout="column"
              fxLayoutGap="10px">
              <div
                *ngIf="featureToggles.trackingEnabled"
                fxLayoutAlign="space-between center">
                <div>
                  Leaderboard
                  <mg-tooltip
                    label="{{ MESSAGES.TOOLTIP_POINTS_RESET }}"></mg-tooltip>
                </div>
                <mg-btn
                  variant="outlined"
                  size="small"
                  (pressed)="resetPoints()">
                  {{ MESSAGES.BUTTON_RESET }}
                </mg-btn>
              </div>
              <div
                *ngIf="featureToggles.trackingEnabled"
                fxLayoutAlign="space-between center">
                <div>
                  Points and Leaderboard
                  <mg-tooltip
                    label="{{
                      MESSAGES.TOOLTIP_POINTS_HISTORY_RESET
                    }}"></mg-tooltip>
                </div>
                <mg-btn
                  variant="outlined"
                  size="small"
                  (pressed)="resetPointsHistory()">
                  {{ MESSAGES.BUTTON_RESET }}
                </mg-btn>
              </div>
              <div
                *ngIf="featureToggles.communityEnabled"
                fxLayoutAlign="space-between center">
                <div>
                  Groups and Members
                  <mg-tooltip
                    label="{{ MESSAGES.TOOLTIP_GROUPS }}"></mg-tooltip>
                </div>
                <mg-btn
                  variant="outlined"
                  size="small"
                  (pressed)="resetGroups()">
                  {{ MESSAGES.BUTTON_RESET }}
                </mg-btn>
              </div>
              <div
                *ngIf="featureToggles.communityEnabled"
                fxLayoutAlign="space-between center">
                <div>
                  Group Members Only
                  <mg-tooltip
                    label="{{ MESSAGES.TOOLTIP_GROUP_MEMBER }}"></mg-tooltip>
                </div>
                <mg-btn
                  variant="outlined"
                  size="small"
                  (pressed)="resetGroupsMembers()">
                  {{ MESSAGES.BUTTON_RESET }}
                </mg-btn>
              </div>

              <div fxLayoutAlign="space-between center">
                <div>
                  User Lists
                  <mg-tooltip
                    label="{{
                      MESSAGES.TOOLTIP_USER_LISTS_RESET
                    }}"></mg-tooltip>
                </div>
                <mg-btn
                  variant="outlined"
                  size="small"
                  (pressed)="resetUserLists()">
                  {{ MESSAGES.BUTTON_RESET }}
                </mg-btn>
              </div>

              <div
                *ngIf="featureToggles.communityEnabled"
                fxLayoutAlign="space-between center">
                <div>
                  Content
                  <mg-tooltip
                    label="{{ MESSAGES.TOOLTIP_CONTENT }}"></mg-tooltip>
                </div>
                <mg-btn
                  variant="outlined"
                  size="small"
                  (pressed)="resetMingaContent()">
                  {{ MESSAGES.BUTTON_RESET }}
                </mg-btn>
              </div>

              <div
                *ngIf="featureToggles.trackingEnabled"
                fxLayoutAlign="space-between center">
                <div>
                  Automation Counters
                  <mg-tooltip
                    label="{{
                      MESSAGES.TOOLTIP_AUTOMATION_RESET
                    }}"></mg-tooltip>
                </div>
                <mg-btn
                  variant="outlined"
                  size="small"
                  (pressed)="resetAutomationCounters()">
                  {{ MESSAGES.BUTTON_RESET }}
                </mg-btn>
              </div>
            </div>
          </mat-expansion-panel>
        </section>

        <!-- Expansion panel: Data Privacy Settings -->
        <section class="section expansion">
          <mat-expansion-panel mgExpansionPanel>
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_PRIVACY }}
              </mg-text>
            </mat-expansion-panel-header>
            <mat-slide-toggle
              [(ngModel)]="settings[mingaSettingTypes.HIDE_ID_NUM]"
              (ngModelChange)="
                updateSetting(mingaSettingTypes.HIDE_ID_NUM, $event)
              ">
              Hide ID Numbers from Teachers / Staff
              <mg-tooltip
                label="{{ MESSAGES.TOOLTIP_HIDE_ID_NUMBER }}"></mg-tooltip>
            </mat-slide-toggle>
            <mat-slide-toggle
              [(ngModel)]="settings[mingaSettingTypes.TEACHERS_VIEW_PROFILE]"
              (ngModelChange)="
                updateSetting(mingaSettingTypes.TEACHERS_VIEW_PROFILE, $event)
              ">
              {{ MESSAGES.SETTING_VIEW_STUDENT_PROFILE }}
              <mg-tooltip
                label="{{
                  MESSAGES.SETTING_VIEW_STUDENT_PROFILE_TOOLTIP
                }}"></mg-tooltip>
            </mat-slide-toggle>
            <mat-slide-toggle
              [(ngModel)]="
                settings[mingaSettingTypes.FEATURE_ALLOW_ACCOUNT_DELETION]
              "
              (ngModelChange)="
                updateSetting(
                  mingaSettingTypes.FEATURE_ALLOW_ACCOUNT_DELETION,
                  $event
                )
              ">
              Allow users to delete their own account
              <mg-tooltip
                label="{{ MESSAGES.TOOLTIP_DELETE_ACCOUNT }}"></mg-tooltip>
            </mat-slide-toggle>
            <mat-slide-toggle
              [(ngModel)]="
                settings[mingaSettingTypes.FEATURE_ALLOW_STUDENT_EMAIL_CHANGE]
              "
              (ngModelChange)="
                updateSetting(
                  mingaSettingTypes.FEATURE_ALLOW_STUDENT_EMAIL_CHANGE,
                  $event
                )
              ">
              Allow students to change their email address
              <mg-tooltip
                label="{{
                  MESSAGES.TOOLTIP_STUDENT_EMAIL_CHANGE
                }}"></mg-tooltip>
            </mat-slide-toggle>
          </mat-expansion-panel>
        </section>

        <!-- Expansion panel: SMS Settings -->
        <section class="section expansion">
          <mat-expansion-panel mgExpansionPanel>
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_SMS }}
              </mg-text>
            </mat-expansion-panel-header>
            <mg-setting-toggle
              labelColor="black"
              showWarning="onDisable"
              variant="body-sm"
              [setting]="mingaSettingTypes.ENABLE_SMS"
              [label]="MESSAGES.SETTING_ENABLE_SMS"
              [warningTitle]="MESSAGES.SETTING_ENABLE_SMS_TITLE_DISABLING"
              [warningBody]="MESSAGES.SETTING_ENABLE_SMS_BODY_DISABLING"
              (valueChange)="
                showSnackbarSuccess($event ? 'SMS enabled' : 'SMS disabled')
              ">
              {{ MESSAGES.SETTING_ENABLE_SMS }}
            </mg-setting-toggle>
          </mat-expansion-panel>
        </section>

        <!-- Expansion panel: Student Schedule Settings -->
        <section
          *ngIf="appConfig.getValue('studentScheduleEnabled')"
          class="section expansion">
          <mat-expansion-panel mgExpansionPanel>
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">
                {{ MESSAGES.SETTING_STUDENT_SCHEDULE }}
              </mg-text>
            </mat-expansion-panel-header>
            <div
              fxLayout="column"
              fxLayoutGap="10px"
              fxLayoutAlign="start stretch">
              <mg-setting-toggle
                labelColor="black"
                variant="body-sm"
                [setting]="mingaSettingTypes.STUDENT_SCHEDULE_ENABLE"
                [label]="MESSAGES.SETTING_STUDENT_SCHEDULE_ENABLE"
                (valueChange)="
                  showSnackbarSuccess(
                    $event
                      ? MESSAGES.SETTING_STUDENT_SCHEDULE_TOGGLE_ON
                      : MESSAGES.SETTING_STUDENT_SCHEDULE_TOGGLE_OFF
                  )
                ">
                {{ MESSAGES.SETTING_STUDENT_SCHEDULE_ENABLE }}
              </mg-setting-toggle>
              <ng-container *ngIf="appConfig.getValue('bellScheduleEnabled')">
                <mg-setting-toggle
                  *hasPermission="'SUPERADMIN'"
                  labelColor="black"
                  variant="body-sm"
                  [setting]="mingaSettingTypes.BELL_SCHEDULE_ENABLE"
                  [label]="MESSAGES.SETTING_BELL_SCHEDULE"
                  (valueChange)="onBellScheduleUpdate($event)">
                </mg-setting-toggle>
                <mg-setting-toggle
                  *ngIf="settings[mingaSettingTypes.BELL_SCHEDULE_ENABLE]"
                  labelColor="black"
                  variant="body-sm"
                  [setting]="
                    mingaSettingTypes.BELL_SCHEDULE_ENABLE_FOR_TEACHERS_STUDENTS
                  "
                  [label]="
                    MESSAGES.SETTING_BELL_SCHEDULE_ENABLE_FOR_TEACHERS_STUDENTS
                  "
                  (valueChange)="
                    showSnackbarSuccess('Setting updated successfully')
                  ">
                </mg-setting-toggle>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </section>

        <section
          *hasPermission="'SUPERADMIN'"
          class="section expansion">
          <mat-expansion-panel mgExpansionPanel>
            <mat-expansion-panel-header>
              <mg-text variant="header-md-secondary">{{
                MESSAGES.SETTING_FEATURE_ROLLOUT
              }}</mg-text>
            </mat-expansion-panel-header>
            <div
              fxLayout="column"
              fxLayoutGap="10px"
              fxLayoutAlign="start stretch">
              <mg-setting-toggle
                labelColor="black"
                variant="body-sm"
                [setting]="mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_BETA"
                [label]="MESSAGES.SETTING_FEATURE_BETA"
                (valueChange)="
                  onAppRolloutSettingChange('mingaIsBeta', $event)
                ">
              </mg-setting-toggle>
              <mg-setting-toggle
                labelColor="black"
                variant="body-sm"
                [setting]="
                  mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_CUSTOMER_COUNCIL
                "
                [label]="MESSAGES.SETTING_FEATURE_CUSTOMER_COUNCIL"
                (valueChange)="
                  onAppRolloutSettingChange('mingaIsCustomerCouncil', $event)
                ">
              </mg-setting-toggle>
            </div>
          </mat-expansion-panel>
        </section>
      </div>
    </div>
  </div>
</ng-template>
