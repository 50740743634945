import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mg-tt-pill',
  templateUrl: './tt-pill.component.html',
  styleUrls: ['./tt-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtPillComponent {
  @Input() public type:
    | 'default'
    | 'warning'
    | 'success'
    | 'error'
    | 'muted'
    | 'selected' = 'default';
  @Input() size: 'small' | 'medium' = 'small';
  @Input() text: number | string;
  @Input() icon: string;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  get classes() {
    return {
      [this.type]: true,
      [this.size]: true,
      'has-text': !!this.text,
    };
  }
}
