import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommonUIModules } from '@shared/modules';

import { BottomSheetModule } from '../bottom-sheet';
import { FormModule } from '../form';
import { UserListFilterMobileComponent } from './components/user-list-filter-mobile/user-list-filter-mobile.component';
import { UserListFilterComponent } from './user-list-filter.component';

@NgModule({
  declarations: [UserListFilterComponent, UserListFilterMobileComponent],
  exports: [UserListFilterComponent],

  imports: [CommonModule, FormModule, BottomSheetModule, CommonUIModules],
})
export class UserListFilterModule {}
