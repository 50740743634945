import { Pipe, PipeTransform } from '@angular/core';

import { FiltersFormDataOption } from '../types';

@Pipe({ name: 'getLabelsFromOptions' })
export class FiltersFormGetLabelsFromOptionsPipe implements PipeTransform {
  transform(
    array: FiltersFormDataOption[],
    values?: any[],
    prettify?: boolean,
    limit?: number,
  ): string[] | string {
    let result: string[] = [];
    if (values) {
      result = array
        .filter(item => values.includes(item.value))
        .reduce((acc, item) => {
          if (item.label) {
            acc.push(item.label);
          }
          return acc;
        }, [] as string[]);
    } else {
      result = array.reduce((acc, item) => {
        if (item.label) {
          acc.push(item.label);
        }
        return acc;
      }, [] as string[]);
    }
    if (prettify) {
      let s = result.slice(0, limit).join(', ');
      if (result.length > limit) {
        s += `, and ${result.length - limit} more`;
      }
      return s;
    }
    return result;
  }
}
