"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KioskRole = void 0;
const domain_1 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const UserRole_1 = require("minga/libraries/shared/permissions/internal/UserRole");
class KioskRole extends UserRole_1.UserRole {
    constructor() {
        super(...arguments);
        this.roleType = domain_1.MingaRoleType.KIOSK;
        this.name = 'Kiosk';
        this.displayNameFormat = util_1.DisplayNameFormat.F_LAST;
        this.iconUrl = 'assets/roles/kiosk.svg';
        this.iconColor = '#2465B9';
        this.capabilitiesDescription = `Kiosk role is a limited role to be used for kiosk devices.`;
        this.feedPermittable = false;
        this.galleryPermittable = false;
        this.groupFeedPermittable = false;
        this.programManagePermittable = false;
        this.videoUploadPermittable = false;
        this.commentPermittable = false;
        this.groupCreatePermittable = false;
        this.admin = false;
        this.requiredFeatures = [];
        this.sendToMailChimp = false;
        this.permissions = [domain_1.MingaPermission.KIOSK_MANAGE];
        this.overridablePermissions = [];
    }
}
exports.KioskRole = KioskRole;