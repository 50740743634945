"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PbisStatsPersonMapper = exports.PbisPersonStatsMapper = exports.PbisPersonInfoMapper = exports.PbisSummaryMapper = void 0;
const pbis_pb = require("minga/proto/pbis_stats/pbis_stats_pb");
const day_1 = require("minga/libraries/day/index");
const domain_1 = require("minga/libraries/domain/index");
/**
 * Hall Pass Statistics - Summary Mapper
 */
var PbisSummaryMapper;
(function (PbisSummaryMapper) {
    PbisSummaryMapper.toProto = (item) => {
        const msg = new pbis_pb.PbisSummary();
        if (item.date) {
            const date = day_1.day(item.date);
            msg.setDate(date.format('YYYY-MM-DD'));
        }
        if (item.typeId) {
            msg.setTypeId(item.typeId);
        }
        if (item.month) {
            msg.setMonth(item.month);
        }
        if (item.hour) {
            msg.setHour(item.hour);
        }
        if (item.week) {
            msg.setWeek(item.week);
        }
        if (item.total) {
            msg.setTotal(item.total);
        }
        if (item.typeName) {
            msg.setTypeName(item.typeName);
        }
        if (item.typeCategory) {
            msg.setTypeCategory(item.typeCategory);
        }
        if (item.year) {
            msg.setYear(item.year);
        }
        return msg;
    };
    PbisSummaryMapper.fromProto = (msg) => {
        const typeName = msg.getTypeName();
        const typeCategory = msg.getTypeCategory();
        const date = msg.getDate();
        const hour = msg.getHour();
        const month = msg.getMonth();
        const total = msg.getTotal();
        const typeId = msg.getTypeId();
        const week = msg.getWeek();
        const year = msg.getYear();
        return {
            typeName,
            typeCategory,
            date,
            hour,
            month,
            total,
            typeId,
            week,
            year,
        };
    };
    // For the PBIS Summary Report
    PbisSummaryMapper.adaptToSummaryTableData = (data, groupBy) => {
        if (!data)
            return [];
        return data.map(item => {
            let timePeriod = '';
            switch (groupBy) {
                case domain_1.GroupType.BY_DAY: {
                    timePeriod = item.date || '';
                    break;
                }
                case domain_1.GroupType.BY_WEEK: {
                    timePeriod = 'week: ' + item['week'];
                    break;
                }
                case domain_1.GroupType.BY_MONTH: {
                    if (item['month']) {
                        const month = day_1.day().month(item['month']).format('MMMM');
                        timePeriod = month;
                    }
                    break;
                }
                case domain_1.GroupType.BY_HOUR: {
                    if (item['hour']) {
                        timePeriod = item['hour'] + ':00';
                    }
                    break;
                }
            }
            return {
                total: item.total || 0,
                typeName: item.typeName,
                timePeriod: timePeriod,
            };
        });
    };
})(PbisSummaryMapper = exports.PbisSummaryMapper || (exports.PbisSummaryMapper = {}));
/**
 * Pbis Statistics - Person Info Mapper
 *
 */
var PbisPersonInfoMapper;
(function (PbisPersonInfoMapper) {
    PbisPersonInfoMapper.toProto = (val) => {
        const msg = new pbis_pb.PbisPersonInfo();
        if (val.grade) {
            msg.setGrade(val.grade);
        }
        if (val.id) {
            msg.setId(val.id);
        }
        if (val.role) {
            msg.setRole(val.role);
        }
        if (val.studentId) {
            msg.setStudentId(val.studentId);
        }
        if (val.badgeIconUrl) {
            msg.setBadgeIconUrl(val.badgeIconUrl);
        }
        if (val.personHash) {
            msg.setPersonHash(val.personHash);
        }
        if (val.firstName)
            msg.setFirstName(val.firstName);
        if (val.lastName)
            msg.setLastName(val.lastName);
        return msg;
    };
    PbisPersonInfoMapper.fromProto = (msg) => {
        const grade = msg.getGrade();
        const id = msg.getId();
        const role = msg.getRole();
        const studentId = msg.getStudentId();
        const badgeIconUrl = msg.getBadgeIconUrl();
        const personHash = msg.getPersonHash();
        const firstName = msg.getFirstName();
        const lastName = msg.getLastName();
        return {
            grade,
            id,
            role,
            studentId,
            badgeIconUrl,
            personHash,
            firstName,
            lastName,
        };
    };
})(PbisPersonInfoMapper = exports.PbisPersonInfoMapper || (exports.PbisPersonInfoMapper = {}));
/**
 * Pbis Statistics - Person Stats Mapper
 */
var PbisPersonStatsMapper;
(function (PbisPersonStatsMapper) {
    PbisPersonStatsMapper.toProto = (val) => {
        const msg = new pbis_pb.PbisPersonStats();
        if (val.date) {
            msg.setDate(val.date);
        }
        if (val.hour) {
            msg.setHour(val.hour);
        }
        if (val.issuedBy) {
            msg.setIssuedby(val.issuedBy.toString());
        }
        if (val.issuedTo) {
            msg.setIssuedto(val.issuedTo.toString());
        }
        if (val.month) {
            msg.setMonth(val.month);
        }
        if (val.totalGuidance) {
            msg.setTotalGuidance(val.totalGuidance);
        }
        if (val.totalPraise) {
            msg.setTotalPraise(val.totalPraise);
        }
        if (val.typeId) {
            msg.setTypeid(val.typeId);
        }
        if (val.week) {
            msg.setWeek(val.week);
        }
        if (val.totalPoints) {
            msg.setTotalPoints(val.totalPoints);
        }
        if (val.totalConsequence) {
            msg.setTotalConsequence(val.totalConsequence);
        }
        if (val.totalOverdueConsequence) {
            msg.setTotalOverdueConsequence(val.totalOverdueConsequence);
        }
        if (val.totalActiveConsequence)
            msg.setTotalActiveConsequence(val.totalActiveConsequence);
        if (val.totalCompleteConsequence)
            msg.setTotalCompleteConsequence(val.totalCompleteConsequence);
        if (val.totalEscalatedConsequence)
            msg.setTotalEscalatedConsequence(val.totalEscalatedConsequence);
        return msg;
    };
    PbisPersonStatsMapper.fromProto = (msg) => {
        const date = msg.getDate();
        const hour = msg.getHour();
        const issuedBy = +msg.getIssuedby();
        const issuedTo = +msg.getIssuedto();
        const month = msg.getMonth();
        const totalGuidance = msg.getTotalGuidance();
        const totalPraise = msg.getTotalPraise();
        const typeId = msg.getTypeid();
        const week = msg.getWeek();
        const totalPoints = msg.getTotalPoints();
        const totalConsequence = msg.getTotalConsequence();
        const totalOverdueConsequence = msg.getTotalOverdueConsequence();
        const totalActiveConsequence = msg.getTotalActiveConsequence();
        const totalCompleteConsequence = msg.getTotalCompleteConsequence();
        const totalEscalatedConsequence = msg.getTotalEscalatedConsequence();
        return {
            date,
            hour,
            issuedBy,
            issuedTo,
            month,
            totalGuidance,
            totalPraise,
            typeId,
            week,
            totalPoints,
            totalConsequence,
            totalOverdueConsequence,
            totalActiveConsequence,
            totalCompleteConsequence,
            totalEscalatedConsequence,
        };
    };
})(PbisPersonStatsMapper = exports.PbisPersonStatsMapper || (exports.PbisPersonStatsMapper = {}));
/**
 * Pbis Statistics - Person Mapper
 */
var PbisStatsPersonMapper;
(function (PbisStatsPersonMapper) {
    PbisStatsPersonMapper.toProto = (val) => {
        const msg = new pbis_pb.PbisPerson();
        if (val.person) {
            msg.setInfo(PbisPersonInfoMapper.toProto(val.person));
        }
        if (val.stats) {
            msg.setStats(PbisPersonStatsMapper.toProto(val.stats));
        }
        return msg;
    };
    PbisStatsPersonMapper.fromProto = (msg) => {
        const person = PbisPersonInfoMapper.fromProto(msg.getInfo());
        const stats = PbisPersonStatsMapper.fromProto(msg.getStats());
        return {
            person,
            stats,
        };
    };
})(PbisStatsPersonMapper = exports.PbisStatsPersonMapper || (exports.PbisStatsPersonMapper = {}));