"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengePresentationMapper = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const date_pb_1 = require("minga/proto/common/date_pb");
const content_views_pb_1 = require("minga/proto/gateway/content_views_pb");
const person_view_pb_1 = require("minga/proto/gateway/person_view_pb");
const image_pb_1 = require("minga/proto/image/image_pb");
const content_1 = require("minga/libraries/shared-grpc/content/index");
var ChallengePresentationMapper;
(function (ChallengePresentationMapper) {
    function fromPresentationToLongCardMessage(longCardData) {
        const longCard = new content_views_pb_1.LongChallengeCardView();
        longCard.setContentHash(longCardData.contentHash);
        longCard.setCommentCount(longCardData.commentCount);
        longCard.setTotalCommentCount(longCardData.commentCount);
        longCard.setBody(longCardData.body);
        longCard.setLikeCount(longCardData.likeCount);
        longCard.setPrivacy(longCardData.privacy);
        longCard.setTitle(longCardData.title);
        longCard.setChallengeType(longCardData.challengeType);
        longCard.setColor(longCardData.color);
        longCard.setPoints(longCardData.points);
        longCard.setAllowedRolesList(longCardData.allowedRolesList);
        longCard.setRequiresApproval(longCardData.requiresApproval);
        if (longCardData.dueDate) {
            longCard.setDueDate(shared_grpc_1.asObjectToMessage(longCardData.dueDate, new date_pb_1.Date()));
        }
        longCard.setHasResponded(longCardData.hasResponded);
        longCard.setRespondedPeopleCount(longCardData.respondedPeopleCount);
        longCard.setTimestamp(longCardData.timestamp);
        longCard.setResponsesSummaryList(longCardData.responsesSummaryList.map(item => {
            const msg = new content_views_pb_1.ChallengeResponseSummaryItem();
            msg.setBody(item.body);
            msg.setContentContextHash(item.contentContextHash);
            msg.setAuthorPersonView(shared_grpc_1.asObjectToMessage(item.authorPersonView, new person_view_pb_1.PersonViewMinimal()));
            const imgInfo = item.featuredImage;
            if (imgInfo) {
                const imageInfo = new image_pb_1.ImageInfo();
                let sizeMap = imageInfo.getSizeMap();
                for (let [key, value] of imgInfo.sizeMap) {
                    const imgSize = new image_pb_1.ImageSize();
                    imgSize.setPath(value.path);
                    imgSize.setWidth(value.width);
                    imgSize.setHeight(value.height);
                    sizeMap.set(key, imgSize);
                }
                msg.setFeaturedImage(imageInfo);
            }
            return msg;
        }));
        if (longCardData && longCardData.cloudflareUid) {
            longCard.setCloudflareVideoUrl(longCardData.cloudflareVideoUrl);
            longCard.setCloudflareUid(longCardData.cloudflareUid);
            longCard.setCloudflareReady(longCardData.cloudflareReady);
        }
        const imgInfo = longCardData === null || longCardData === void 0 ? void 0 : longCardData.featuredImage;
        if (imgInfo) {
            const imageInfo = new image_pb_1.ImageInfo();
            let sizeMap = imageInfo.getSizeMap();
            for (let [key, value] of imgInfo.sizeMap) {
                const imgSize = new image_pb_1.ImageSize();
                imgSize.setPath(value.path);
                imgSize.setWidth(value.width);
                imgSize.setHeight(value.height);
                sizeMap.set(key, imgSize);
            }
            longCard.setFeaturedImage(imageInfo);
        }
        const badgeInfo = longCardData === null || longCardData === void 0 ? void 0 : longCardData.badgeImage;
        if (badgeInfo) {
            const imageInfo = new image_pb_1.ImageInfo();
            let sizeMap = imageInfo.getSizeMap();
            for (let [key, value] of badgeInfo.sizeMap) {
                const imgSize = new image_pb_1.ImageSize();
                imgSize.setPath(value.path);
                imgSize.setWidth(value.width);
                imgSize.setHeight(value.height);
                sizeMap.set(key, imgSize);
            }
            longCard.setBadgeImage(imageInfo);
        }
        longCard.setAuthorPersonView(shared_grpc_1.asObjectToMessage(longCardData.authorPersonView, new person_view_pb_1.PersonViewMinimal()));
        if (longCardData === null || longCardData === void 0 ? void 0 : longCardData.allowedCommentingRoleTypesList) {
            longCard.setAllowedCommentingRoleTypesList(longCardData === null || longCardData === void 0 ? void 0 : longCardData.allowedCommentingRoleTypesList);
        }
        if (longCardData.ownerGroupHash) {
            longCard.setOwnerGroupHash(longCardData.ownerGroupHash);
        }
        const comments = content_1.CommentListPresentationMapper.fromPresentationToCommentContentMessage(longCardData.commentList);
        longCard.setCommentList(comments);
        return longCard;
    }
    ChallengePresentationMapper.fromPresentationToLongCardMessage = fromPresentationToLongCardMessage;
})(ChallengePresentationMapper = exports.ChallengePresentationMapper || (exports.ChallengePresentationMapper = {}));