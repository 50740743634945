var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PbisTypeCountMapper = exports.PbisTypeMapper = exports.PbisBehaviorMapper = exports.PbisAutomationCounterMapper = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const PbisAutomationCountermapperts = require("minga/libraries/shared-grpc/pbis/PbisAutomationCounter.mapper");
exports.PbisAutomationCounterMapper = PbisAutomationCountermapperts;
const PbisBehaviormapperts = require("minga/libraries/shared-grpc/pbis/PbisBehavior.mapper");
exports.PbisBehaviorMapper = PbisBehaviormapperts;
const PbisTypemapperts = require("minga/libraries/shared-grpc/pbis/PbisType.mapper");
exports.PbisTypeMapper = PbisTypemapperts;
const PbisTypeCountmapperts = require("minga/libraries/shared-grpc/pbis/PbisTypeCount.mapper");
exports.PbisTypeCountMapper = PbisTypeCountmapperts;
__exportStar(require("minga/libraries/shared-grpc/pbis/PbisStats.mapper.proto"), exports);