import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ReplaySubject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FlexRegistrationInfo } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import {
  FtmReportsRegistrationDatasourceService,
  FtmReportsService,
} from '../../services';
import { FtmReportsRegActionService } from '../../services/ftm-reports-reg-action.service';
import { FtmReportsRegMessages } from './ftm-reports-reg.constants';

@Component({
  selector: 'mg-ftm-reports-registration',
  templateUrl: './ftm-reports-reg.component.html',
  styleUrls: ['./ftm-reports-reg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FtmReportsRegistrationDatasourceService],
})
export class FtmReportsRegistrationComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly MESSAGES = FtmReportsRegMessages;

  /** General Observables and Subject Behaviors */
  private _destroyedSubject = new ReplaySubject<void>(1);
  private readonly _canAdminSelect$ = this._permissions.observePermission(
    MingaPermission.FLEX_TIME_MANAGE,
  );
  private readonly _canTeacherSelect$ = this._permissions.observePermission(
    MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
  );
  public readonly canMultiSelect$ = combineLatest([
    this._canAdminSelect$,
    this._canTeacherSelect$,
  ]).pipe(map(([admin, teacher]) => admin || teacher));

  /** Other */
  private _selectableItems: FlexRegistrationInfo[] = [];
  public readonly noResultMessage = 'Try selecting a different period.';

  constructor(
    public ds: FtmReportsRegistrationDatasourceService,
    public actionService: FtmReportsRegActionService,
    private _ftmReportService: FtmReportsService,
    private _permissions: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this._ftmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this.actionService.clearSelection();
      });
  }
  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
