"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SisUserAccountTypes = exports.SisType = void 0;
var SisType;
(function (SisType) {
    SisType["Q"] = "Q";
    //POWERSCHOOL = 'PowerSchool',
    SisType["CLASSLINK"] = "ClassLink";
    SisType["CLEVER"] = "Clever";
    SisType["AERIES"] = "Aeries";
    SisType["INFINITE_CAMPUS"] = "Infinite Campus";
    SisType["OTHER"] = "Other";
    //GENESIS = 'Genesis',
    SisType["SKYWARD"] = "Skyward";
    //SYNERGY = 'Synergy',
    SisType["ROSTERSTREAM"] = "MingaSync";
})(SisType = exports.SisType || (exports.SisType = {}));
var SisUserAccountTypes;
(function (SisUserAccountTypes) {
    SisUserAccountTypes["TEACHER"] = "Teacher";
    SisUserAccountTypes["STUDENT"] = "Student";
    SisUserAccountTypes["ADMIN"] = "Admin";
    SisUserAccountTypes["ADMINISTRATOR"] = "Administrator";
    SisUserAccountTypes["PROCTOR"] = "Proctor";
    SisUserAccountTypes["RELATIVE"] = "Relative";
    SisUserAccountTypes["STAFF"] = "Staff";
    SisUserAccountTypes["PARENT"] = "Parent";
})(SisUserAccountTypes = exports.SisUserAccountTypes || (exports.SisUserAccountTypes = {}));