<mg-manager-overlay-reports-subpage
  [scheduleReportsButton]="false"
  [dataService]="dataService$ | async"
  (exportListPressed)="exportReport()">
  <!-- Header -->
  <ng-template #header>
    <div
      class="filters-container"
      fxLayout="row wrap"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start stretch">
      <ng-container *ngTemplateOutlet="reportTypeFilterTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
      <!-- Filters form -->
      <ng-container
        *ngIf="(media.isMobileView$ | async) === false"
        [ngTemplateOutlet]="filtersFormReference">
      </ng-container>
    </div>
  </ng-template>

  <!-- Filters form reference -->
  <ng-template #filtersFormReference>
    <mg-filters-form
      [data]="filtersFormStructure$ | async"
      [loading]="loading$ | async"
      [showChips]="true"
      [enableQueryParams]="true"
      (state)="updateFilters($event)">
    </mg-filters-form>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Filter Template -->
<ng-template #reportTypeFilterTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="329px">
    <mg-form-select
      placeholder="Report Type"
      labelBackground="white"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="getReportType()"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="REPORT_OPTIONS"
      [floatingLabel]="false"
      (selectionChanged)="onChangeReportType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field full-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>
