"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Poll = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const grpc_web_1 = require("@improbable-eng/grpc-web");
const poll_pb_service_1 = require("minga/proto/content/poll_pb_service");
const i0 = require("@angular/core");
class Poll {
    constructor(_ngZone) {
        this._ngZone = _ngZone;
    }
    new(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(poll_pb_service_1.Poll.New, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    update(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(poll_pb_service_1.Poll.Update, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    getInfo(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(poll_pb_service_1.Poll.GetInfo, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    streamPolls(request, arg1, arg2, arg3, arg4) {
        let ret;
        let metadata;
        let onMessage;
        let onError;
        let onEnd;
        if (typeof arg1 === 'function') {
            onMessage = arg1;
            onError = arg2;
            onEnd = arg3;
        }
        else if (typeof arg2 === 'function') {
            metadata = arg1;
            onMessage = arg2;
            onError = arg3;
            onEnd = arg4;
        }
        else {
            metadata = arg1;
        }
        if (!onMessage) {
            let subject = new rxjs_1.Subject();
            ret = subject.asObservable();
            onMessage = (response) => {
                subject.next(response);
            };
            onError = (err) => {
                subject.error(err);
            };
            onEnd = (code, msg, metadata) => {
                subject.complete();
            };
        }
        else {
            if (!onError) {
                onError = (err) => console.error(err);
            }
            if (!onEnd) {
                onEnd = (code, msg, metadata) => { };
            }
        }
        let req = grpc_web_1.grpc.invoke(poll_pb_service_1.Poll.StreamPolls, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onMessage: response => this._ngZone.run(() => {
                onMessage(response);
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code == grpc_web_1.grpc.Code.OK) {
                    onEnd(code, msg, metadata);
                }
                else {
                    let err = new Error(code + ' ' + (msg || ''));
                    err.code = code;
                    onError(err);
                }
            })
        });
        ret.close = () => {
            console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
            return req.close();
        };
        ret.cancel = () => req.close();
        return ret;
    }
    streamPollsControl(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(poll_pb_service_1.Poll.StreamPollsControl, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
    streamPollsV2(request, arg1, arg2, arg3, arg4) {
        let ret;
        let metadata;
        let onMessage;
        let onError;
        let onEnd;
        if (typeof arg1 === 'function') {
            onMessage = arg1;
            onError = arg2;
            onEnd = arg3;
        }
        else if (typeof arg2 === 'function') {
            metadata = arg1;
            onMessage = arg2;
            onError = arg3;
            onEnd = arg4;
        }
        else {
            metadata = arg1;
        }
        if (!onMessage) {
            let subject = new rxjs_1.Subject();
            ret = subject.asObservable();
            onMessage = (response) => {
                subject.next(response);
            };
            onError = (err) => {
                subject.error(err);
            };
            onEnd = (code, msg, metadata) => {
                subject.complete();
            };
        }
        else {
            if (!onError) {
                onError = (err) => console.error(err);
            }
            if (!onEnd) {
                onEnd = (code, msg, metadata) => { };
            }
        }
        let req = grpc_web_1.grpc.invoke(poll_pb_service_1.Poll.StreamPollsV2, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onMessage: response => this._ngZone.run(() => {
                onMessage(response);
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code == grpc_web_1.grpc.Code.OK) {
                    onEnd(code, msg, metadata);
                }
                else {
                    let err = new Error(code + ' ' + (msg || ''));
                    err.code = code;
                    onError(err);
                }
            })
        });
        ret.close = () => {
            console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
            return req.close();
        };
        ret.cancel = () => req.close();
        return ret;
    }
    streamPollsV2Control(request, arg1, arg2) {
        let ret;
        let callback;
        let metadata;
        if (typeof arg1 === 'function') {
            callback = arg1;
        }
        else {
            metadata = arg1;
            callback = arg2;
        }
        if (!callback) {
            ret = new Promise((resolve, reject) => {
                callback = (err, response) => {
                    if (err)
                        reject(err);
                    else
                        resolve(response);
                };
            });
        }
        let responseMetadata = null;
        let req = grpc_web_1.grpc.invoke(poll_pb_service_1.Poll.StreamPollsV2Control, {
            request: request,
            host: window.DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
            metadata: metadata,
            onHeaders: headers => responseMetadata = headers,
            onMessage: response => this._ngZone.run(() => {
                callback(null, response, responseMetadata || new grpc_web_1.grpc.Metadata());
            }),
            onEnd: (code, msg, metadata) => this._ngZone.run(() => {
                if (code != grpc_web_1.grpc.Code.OK) {
                    let err = new Error(msg);
                    err.code = code;
                    callback(err);
                }
            })
        });
        ret.cancel = () => req.close();
        return ret;
    }
}
exports.Poll = Poll;
Poll.ɵfac = function Poll_Factory(t) { return new (t || Poll)(i0.ɵɵinject(i0.NgZone)); };
Poll.ɵprov = i0.ɵɵdefineInjectable({ token: Poll, factory: Poll.ɵfac, providedIn: 'root' });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Poll, [{
        type: core_1.Injectable,
        args: [{ providedIn: 'root' }]
    }], function () { return [{ type: i0.NgZone }]; }, null); })();