<mg-manager-overlay-reports-subpage
  [loading]="false"
  [scheduleReportsButton]="false"
  [dataService]="dataService$ | async"
  (exportListPressed)="exportReport()">
  <!-- Header -->
  <ng-template #header>
    <div
      class="tw-flex tw-flex-wrap tw-flex-column md:tw-flex-row tw-gap-2 md:tw-gap-3 tw-items-stretch md:tw-items-center">
      <!-- Report type filter -->
      <div class="tw-grow-0">
        <ng-container *ngTemplateOutlet="reportTypeFilterTemplate">
        </ng-container>
      </div>

      <!-- Date picker filter -->
      <div class="tw-grow-0">
        <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
      </div>

      <!-- Filters form -->
      <div
        *ngIf="(media.isMobileView$ | async) === false"
        class="tw-grow-0">
        <ng-container [ngTemplateOutlet]="filtersFormReference"> </ng-container>
      </div>
    </div>
  </ng-template>

  <!-- Filters form reference -->
  <ng-template #filtersFormReference>
    <mg-filters-form
      [data]="filtersFormStructure$ | async"
      [loading]="isFilterLoading$ | async"
      [showChips]="true"
      (state)="updateFilters($event)">
    </mg-filters-form>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Filter Template -->
<ng-template #reportTypeFilterTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="308px">
    <mg-form-select
      placeholder="Report Type"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="getReportType()"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="REPORT_OPTIONS"
      [floatingLabel]="false"
      (selectionChanged)="changeReportType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>
