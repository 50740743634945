import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { day } from 'minga/libraries/day';
import {
  FlexCheckInStatuses,
  MinimalFlexTimePeriod,
} from 'minga/libraries/domain';
import { ReportTypes } from 'minga/libraries/domain';
import { ReportBaseAbstract } from 'src/app/components/manager-report/services/report-base.util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { Person } from 'src/app/people';

import {
  FilterFormPeopleSearchType,
  FilterFormSelectType,
  FilterFormUserListType,
  FiltersFormData,
  FiltersFormService,
} from '@shared/components/filters-form';
import { FormSelectComponent, FormSelectOption } from '@shared/components/form';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimePeriodService } from '@shared/services/flex-time';
import { MediaService } from '@shared/services/media';

import {
  FlexTimeManagerMessages,
  FlexTimeManagerRoutes,
} from '../../constants';
import { FtmActivityTemplatesService } from '../ftm-activity-templates/services';
import {
  FTM_REPORTS_FILTER_INIT_STATE,
  FtmReportFilterType,
  FtmReportPageOptions,
  FtmReportsMessages,
} from './constants';
import { FtmReportsService } from './services';

@Component({
  selector: 'mg-ftm-reports',
  templateUrl: './ftm-reports.component.html',
  styleUrls: ['./ftm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmReportsComponent
  extends ReportBaseAbstract
  implements OnDestroy
{
  // Children

  @ViewChild(MultiPersonSearchComponent)
  private _peopleSearchComponent: MultiPersonSearchComponent;
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  // Constants

  public readonly FTM_MESSAGES = FlexTimeManagerMessages;
  public readonly MESSAGES = FtmReportsMessages;
  public readonly REPORT_SELECT_OPTIONS = FtmReportPageOptions;
  public readonly REPORT_PAGES = ReportTypes;

  // Table Data Service

  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  // Report Type

  protected _reportTypeSubject = new BehaviorSubject<ReportTypes>(
    ReportTypes.FLEX_PERIOD_REGISTERED,
  );

  // Periods

  private _periodsSubject = new BehaviorSubject<MinimalFlexTimePeriod[]>([]);

  // Filters

  public readonly activityOptions$: Observable<FormSelectOption<any>[]> =
    this._ftmActivities.activities$.pipe(
      takeUntil(this._destroyed),
      map(activities =>
        activities.map(activity => ({
          label: activity.name,
          value: activity.id,
        })),
      ),
    );
  public readonly periodOptions$: Observable<FormSelectOption<any>[]> =
    this._periodsSubject.asObservable().pipe(
      takeUntil(this._destroyed),
      map(periods =>
        periods.map(period => ({
          label: period.title,
          value: period.id,
        })),
      ),
    );
  public readonly checkinStatusOptions$: Observable<FormSelectOption[]> = of(
    $enum(FlexCheckInStatuses).map(value => ({
      label: value,
      value,
    })),
  );

  // Loading

  private readonly _loadingSubject = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loadingSubject.asObservable();

  public filtersFormStructure$: Observable<FiltersFormData> = combineLatest([
    this.activityOptions$,
    this.periodOptions$,
    this.checkinStatusOptions$,
    this.ftmReports.filter$,
    this._reportTypeSubject,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([activities, periods, checkinStatuses, filters, reportType]) => {
      const personFilter: Record<string, FilterFormPeopleSearchType> = {
        [FtmReportFilterType.REGISTRANT]: {
          type: 'people-search',
          label: 'Name, ID or email',
          multiple: true,
          value: filters.registrant,
        },
      };

      const activityFilter: Record<string, FilterFormSelectType> = {
        [FtmReportFilterType.ACTIVITY]: {
          type: 'multi-select',
          label: 'Activity',
          options: activities,
          value: filters.activities,
        },
      };

      const periodFilter: Record<string, FilterFormSelectType> = {
        [FtmReportFilterType.PERIOD]: {
          type:
            reportType === ReportTypes.FLEX_PERIOD_REGISTERED
              ? 'multi-select'
              : 'single-select',
          label: 'Period',
          options: periods,
          value:
            reportType === ReportTypes.FLEX_PERIOD_REGISTERED
              ? filters.periods
              : filters.periods[0],
        },
      };

      const checkInStatusFilter: Record<string, FilterFormSelectType> = {
        [FtmReportFilterType.CHECKIN_STATUS]: {
          type: 'single-select',
          label: 'Check in',
          options: checkinStatuses,
          value: filters.checkinStatus,
        },
      };

      const userListFilter: Record<string, FilterFormUserListType> = {
        [FtmReportFilterType.USER_LIST]: {
          type: 'user-list',
          label: 'User list',
          value: filters.userList,
        },
      };

      const makeFlexTimeHistoryReport = (): FiltersFormData => ({
        ...personFilter,
        ...activityFilter,
        ...periodFilter,
        ...checkInStatusFilter,
        ...userListFilter,
      });
      const makeFlexTimeUnregisteredReport = (): FiltersFormData => ({
        ...personFilter,
        ...periodFilter,
        ...userListFilter,
      });

      const getFormData = (): FiltersFormData => {
        if (reportType === ReportTypes.FLEX_PERIOD_REGISTERED) {
          return makeFlexTimeHistoryReport();
        } else {
          return makeFlexTimeUnregisteredReport();
        }
      };

      const formData = getFormData();
      return this._filtersForm.create(formData);
    }),
    tap(() => {
      this._loadingSubject.next(false);
      // }
    }),
  );

  // General Variables

  public readonly REPORT_OPTIONS = FtmReportPageOptions;

  // Component Constructor

  constructor(
    public media: MediaService,
    public ftmReports: FtmReportsService,
    private _ftmActivities: FtmActivityTemplatesService,
    private _ftPeriods: FlexTimePeriodService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _analytics: AnalyticsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _filtersForm: FiltersFormService,
  ) {
    super(ftmReports);
    // have to do this so that we are updated whenever the url
    // is changed.
    this._route.url.pipe(takeUntil(this._destroyed)).subscribe(() => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed))
          .subscribe(url => {
            if (url[0]) {
              const report = $enum(ReportTypes).asValueOrThrow(url[0].path);
              const possiblePaths = this.REPORT_OPTIONS.map(type => type.value);
              if (possiblePaths.includes(report)) {
                this._reportTypeSubject.next(report);
                this._cdr.markForCheck();
              }
            }
          });
      }
    });

    this._ftmActivities.fetchAll();

    this._initializeDates(
      FTM_REPORTS_FILTER_INIT_STATE,
      this._route.snapshot?.queryParams,
      this._destroyed,
      (range, fromChangeEvent) => {
        this.ftmReports.setFilter(FtmReportFilterType.START_DATE, range.start);
        this.ftmReports.setFilter(FtmReportFilterType.END_DATE, range.end);
        this.fetchAllPeriods(range.start, range.end);

        if (fromChangeEvent) {
          this.ftmReports.applyFilter();
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.ftmReports.clearFilter();
    this._destroyed.next();
    this._destroyed.complete();
    this._periodsSubject.complete();
    this._reportTypeSubject.complete();
  }

  async onChangeReportType(value: ReportTypes) {
    this._reportTypeSubject.next(value);
    await this._router.navigateByUrl(
      `/${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.REPORTS}/${value}`,
    );
    this._cdr.markForCheck();
    return;
  }

  onPersonSelected(people: Person[]) {
    this.ftmReports.setFilter(FtmReportFilterType.REGISTRANT, people);
  }

  onChangeActivity(event: FormSelectOption) {
    this.ftmReports.setFilter(FtmReportFilterType.ACTIVITY, event);
  }

  onChangePeriod(event: FormSelectOption) {
    this.ftmReports.setFilter(FtmReportFilterType.PERIOD, event);
  }

  onChangeCheckinStatus(event: FormSelectOption) {
    this.ftmReports.setFilter(FtmReportFilterType.CHECKIN_STATUS, event);
  }

  public changeUserList(list: number[]) {
    this.ftmReports.setFilter(FtmReportFilterType.USER_LIST, list);
  }

  public updateFilters(v: any) {
    this.onPersonSelected(v?.REGISTRANT);
    this.onChangePeriod(v?.PERIOD);
    this.changeUserList(v?.USER_LIST);

    if (this._reportTypeSubject.value === ReportTypes.FLEX_PERIOD_REGISTERED) {
      this.onChangeActivity(v?.ACTIVITY);
      this.onChangeCheckinStatus(v?.CHECKIN_STATUS);
    }
    this.applyFilter();
  }

  clearFilter() {
    this._peopleSearchComponent.setValue([]);
    this.ftmReports.clearFilter();

    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: FTM_REPORTS_FILTER_INIT_STATE.startDate,
      end: FTM_REPORTS_FILTER_INIT_STATE.endDate,
    });
  }

  async applyFilter() {
    if (
      this._reportTypeSubject.value === ReportTypes.FLEX_PERIOD_UNREGISTERED &&
      !this.ftmReports.filter.periods.length
    ) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmReportsMessages.UNREGISTERED_NO_PERIOD_ERROR,
      });
    }
    this.ftmReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'flexTime',
      reportType: this._reportTypeSubject.value,
    });
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }

  public async fetchAllPeriods(
    startDate?: day.Dayjs,
    endDate?: day.Dayjs,
  ): Promise<void> {
    try {
      const result = await this._ftPeriods.fetchAllMinimal(startDate, endDate);
      this._periodsSubject.next(result);
    } catch (err) {
      this._systemAlertSnackBar.error('failed to fetch all flex time periods');
    }
  }
}
