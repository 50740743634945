<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<div *ngIf="!loading">
  <div
    class="page-html-content"
    *ngIf="pageData && htmlContent"
    [innerHTML]="htmlContent"
    [style.margin]="margin"></div>
  <div
    class="page-html-content"
    *ngIf="!pageData"
    [style.margin]="margin">
    Not Found
  </div>
</div>
<div
  *ngIf="loading"
  class="loader">
  <mg-spinner></mg-spinner>
</div>
