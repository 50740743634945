import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
  of,
  timer,
} from 'rxjs';
import {
  debounce,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

import { Group } from 'minga/app/src/app/groups/models/Group';
import { IContentEventMinimal } from 'minga/libraries/domain';
import { MingaGallery } from 'minga/proto/gateway/gallery_ng_grpc_pb';
import {
  GalleryItem,
  MingaGalleryPhotoRequest,
  Tag,
} from 'minga/proto/gateway/gallery_pb';
import { DialogComponent } from 'src/app/components/Dialog';
import {
  GalleryLightboxComponent,
  IGalleryLightboxItem,
  IGalleryLightboxModerationEvent,
} from 'src/app/components/Lightbox/GalleryLightbox';
import { GroupsFacadeService } from 'src/app/groups/services';
import { MentionsService } from 'src/app/mentions';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { AuthService } from 'src/app/minimal/services/Auth';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { RootService } from 'src/app/minimal/services/RootService';
import { PermissionsService } from 'src/app/permissions';
import { IOpenItemEvent } from 'src/app/routes/gallery/GalleryFeed';
import { MingaSettingsService } from 'src/app/store/Minga/services';
import { IMgStreamControl } from 'src/app/util/stream';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  GalleryServiceAbstract,
  MingaGalleryLightboxDataSource,
  MingaGallerySingleGalleryLightboxDataSource,
  getGlobalIndex,
} from './utils';

@Component({
  selector: 'mg-home-gallery',
  templateUrl: './home-gallery.component.html',
  styleUrls: ['./home-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeGalleryComponent implements OnInit, OnDestroy {
  // Children
  @ViewChild('multipleItemGalleryLightBox', { static: true })
  public galleryLightbox: GalleryLightboxComponent;
  @ViewChild('singleItemGalleryLightBox', { static: true })
  public singleGalleryLightbox: GalleryLightboxComponent;
  @ViewChild('deletePhotoDialog', { static: true })
  public deletePhotoDialog!: DialogComponent;

  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  trackBy: TrackByFunction<GalleryItem.AsObject>;
  private _stream: IMgStreamControl<any> | null = null;
  private _searchText$ = new BehaviorSubject<string>('');

  private _routeProvidedGalleryService!: GalleryServiceAbstract;

  photoTotal$: Observable<number>;
  photoTotalLoading$: Observable<boolean>;
  searchText$: Observable<string>;
  searchTextDebounced$: Observable<string>;
  aspectRatioPerRow$: Observable<number>;
  summaryLoading$: Observable<boolean>;
  eventContent$!: Observable<IContentEventMinimal | undefined>;
  group$!: Observable<Group | null>;
  addPhotoRouterLink$!: Observable<any>;

  galleryDataSource: MingaGalleryLightboxDataSource | null = null;
  singleGalleryDataSource: MingaGallerySingleGalleryLightboxDataSource | null =
    null;
  searchText = '';
  private _searchDebounceTimeout: any;
  readonly isPhotoGalleryEnabled$ =
    this._mingaSettings.isPhotoGalleryModuleEnabled();

  /** Component constructor */
  constructor(
    public mingaGallery: MingaGallery,
    private _mingaSettings: MingaSettingsService,
    private _authService: AuthService,
    private _permissions: PermissionsService,
    private _rootService: RootService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _router: Router,
    private _mentionsService: MentionsService,
    private _groupsFacade: GroupsFacadeService,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    // private _search: SearchService,
    private _location: Location,
    private _analyticsService: AnalyticsService,
    private _authInfoService: AuthInfoService,
  ) {
    this.initRouteData();

    this.trackBy = (index: number, item: GalleryItem.AsObject) => {
      return index;
    };

    this.searchText$ = this._searchText$.asObservable();

    this.searchTextDebounced$ = this.searchText$.pipe(
      debounce(txt => (txt ? timer(300) : timer(0))),
      shareReplay(1),
      distinctUntilChanged(),
    );

    this.photoTotal$ = combineLatest(
      this._routeProvidedGalleryService.summary$,
      this.searchTextDebounced$,
    ).pipe(
      switchMap(([summary, searchText]) => {
        if (searchText) {
          return from(
            this._routeProvidedGalleryService.getSearchPhotoTotal(searchText),
          );
        } else {
          return of(summary.photoTotal);
        }
      }),
      shareReplay(1),
    );
    this.aspectRatioPerRow$ = this._routeProvidedGalleryService.summary$.pipe(
      map(summary => summary.photoAspectRatioPerRow),
    );
    this.summaryLoading$ = this._routeProvidedGalleryService.loading$;
    this.photoTotalLoading$ = this.summaryLoading$;
  }

  ngOnInit() {
    // For tags
    this._groupsFacade.dispatchLoadAll();
    this._routeProvidedGalleryService.fetchSummary();
    this.initSingleGalleryDataSource();
    this.setupRouterListener();

    // check for a search query passed by a tag click.
    if (window.history.state.search) {
      // this.search.setSearchQuery(window.history.state.search);
    }
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    // this.search.resetSearch();
    if (this.galleryLightbox) {
      this.galleryLightbox.close();
    }
    if (this.singleGalleryLightbox) {
      this.singleGalleryLightbox.close();
    }

    if (this.galleryDataSource) {
      this.galleryDataSource.dispose();
      this.galleryDataSource = null;
    }
    if (this.singleGalleryDataSource) {
      this.singleGalleryDataSource.dispose();
      this.singleGalleryDataSource = null;
    }
  }

  initRouteData() {
    const galleryData = this._route.snapshot.data?.galleryData;
    this._routeProvidedGalleryService = galleryData.galleryService;
    this.eventContent$ = galleryData.eventContent$;
    this.group$ = galleryData.group$;
    this.addPhotoRouterLink$ = combineLatest(
      this.eventContent$,
      this.group$,
    ).pipe(
      map(([eventContent, group]) => {
        const outletRouteArray = ['gallery', 'add'];
        if (eventContent) {
          outletRouteArray.push('event');
          outletRouteArray.push(eventContent.contextHash);
        } else if (group) {
          outletRouteArray.push('group');
          outletRouteArray.push(group.hash);
        }
        return ['', { outlets: { o: outletRouteArray } }];
      }),
    );
  }

  clearQueryParams() {
    this._router.navigate(['/home/gallery'], {});
  }

  onSearchText(searchText: string) {
    this._searchText$.next(searchText);
  }

  onReportItem(item: IGalleryLightboxItem) {
    const reportNav = { outlets: { o: ['report', '', item.id] } };
    this._router.navigate(['', reportNav]);
  }

  onDeleteItem(event: IGalleryLightboxModerationEvent) {
    this.deletePhotoDialog
      .open()
      .beforeClosed()
      .subscribe(value => {
        if (value === true) {
          this._rootService.addLoadingPromise(this._deleteItem(event.item));
          // when leaveGallery is true, the lightbox was opened directly from a
          // non-gallery page, go back to it
          if (event.leaveGallery) {
            this._location.back();
          }
        }
      });
  }

  async onResolveItem(event: IGalleryLightboxModerationEvent) {
    const uuid = `${event.item.id}`;
    await this._rootService.addLoadingPromise(
      this._routeProvidedGalleryService.resolvePhoto(uuid),
    );
    // when leaveGallery is true, the lightbox was opened directly from a
    // non-gallery page, go back to it
    if (event.leaveGallery) {
      this._location.back();
    }
  }

  onLightboxClose(leaveGallery: boolean) {
    // when leaveGallery is true, the lightbox was opened directly from a
    // non-gallery page, go back to it
    if (leaveGallery) {
      this._location.back();
    }
  }

  private async _deleteItem(item: IGalleryLightboxItem) {
    const galleryPhotoUuid = item.id;
    if (!galleryPhotoUuid || typeof galleryPhotoUuid !== 'string') {
      throw new Error(
        `Cannot delete gallery lightbox item with invalid id ${galleryPhotoUuid}`,
      );
    }

    let successfullyDeleted = false;

    try {
      successfullyDeleted = await this._routeProvidedGalleryService.deletePhoto(
        {
          galleryPhotoUuid,
        },
      );
    } catch (err) {
      console.error(err);
      successfullyDeleted = false;
    }

    if (successfullyDeleted) {
      this._systemAlertSnackBar.success('Successfully deleted photo');
      this.galleryLightbox?.close();
      this._stream?.restart();
    } else {
      this._systemAlertSnackBar.error(
        'Something went wrong while deleting your photo. Please try again later',
      );
    }
  }

  initSingleGalleryDataSource() {
    if (this.singleGalleryLightbox) {
      this.singleGalleryDataSource =
        new MingaGallerySingleGalleryLightboxDataSource(
          this,
          this._authService,
          this._permissions,
          this._router,
          this._mentionsService,
          this.singleGalleryLightbox,
          this._authInfoService,
        );
    } else {
      throw new Error(
        `could not initialize singleGalleryDataSource as singleGalleryLightbox isn't available`,
      );
    }
  }

  setupRouterListener() {
    this._route.params.subscribe(params => {
      if ('photoHash' in params) {
        if (this.singleGalleryLightbox) {
          const hash = params.photoHash;
          // find photo and open it
          this.retrieveAndOpenImage(hash);
        }
      }
      if ('eventContextHash' in params) {
        // update route data when the context hash updates
        this.initRouteData();
      }
    });
  }

  openSingleImage(galleryItem: GalleryItem.AsObject) {
    if (this.singleGalleryDataSource && this.singleGalleryLightbox) {
      this.singleGalleryDataSource.setGalleryItem(galleryItem);
      this.singleGalleryLightbox.setActiveByGlobalIndex(0);
      this.singleGalleryLightbox.slideToActive();

      this.singleGalleryLightbox.open();
    } else {
      throw new Error(
        `could not open singleGalleryLightbox as singleGalleryDataSource wasn't initialized`,
      );
    }
  }

  /**
   * Requests a Gallery Item from gateway and then displays the single Gallery
   * Item in a lightbox.
   */
  async retrieveAndOpenImage(galleryPhotoUuid: string) {
    const request = new MingaGalleryPhotoRequest();
    request.setGalleryPhotoUuid(galleryPhotoUuid);
    const response = await this._rootService.addLoadingPromise(
      this.mingaGallery.getPhoto(request),
    );
    const galleryItemMsg = response.getPhoto();

    if (galleryItemMsg) {
      const galleryItem = galleryItemMsg.toObject();
      this.openSingleImage(galleryItem);
    } else {
      this._systemAlertSnackBar.error(
        'Something went wrong while opening your photo. Please try again later',
      );
    }
  }

  openImage(ev: IOpenItemEvent) {
    if (!this.galleryLightbox || !this.galleryDataSource) {
      return;
    }

    const items = this._stream?._getItems() || [];
    const { itemIndex, photoIndex, tile } = ev;

    this.galleryLightbox.setActiveByGlobalIndex(
      getGlobalIndex(items, itemIndex, photoIndex),
    );
    this.galleryDataSource.setViewItemsAt(itemIndex, photoIndex);
    this.galleryLightbox.open();
  }

  getTileWidth(tile: GalleryItem.AsObject): number {
    const imageInfo = tile.imageInfo;
    const sizeMap = imageInfo?.sizeMap || [];
    return sizeMap[0][1]?.width || 0;
  }

  getTileHeight(tile: GalleryItem.AsObject): number {
    const imageInfo = tile.imageInfo;
    const sizeMap = imageInfo?.sizeMap || [];
    return sizeMap[0][1]?.height || 0;
  }

  onStreamControlCreated(streamControl: IMgStreamControl<any>) {
    this._stream = streamControl;

    if (this.galleryDataSource) {
      this.galleryDataSource.dispose();
    }

    if (!this.galleryLightbox) {
      throw new Error('Stream control create happened before ngOnInit');
    }

    this.galleryDataSource = new MingaGalleryLightboxDataSource(
      this,
      this.photoTotal$,
      streamControl,
      this._authService,
      this._permissions,
      this._router,
      this._mentionsService,
      this.galleryLightbox,
      this._authInfoService,
    );
  }

  onTagClick(ev: MouseEvent, tag: Tag.AsObject) {
    const searchString = (ev.target as Node)?.textContent || '';
    if (tag.contentTag) {
      this._analyticsService.sendGalleryHashTaggedClicked({ type: 'event' });
    } else if (tag.groupTag) {
      this._analyticsService.sendGalleryHashTaggedClicked({ type: 'group' });
    } else if (tag.personTag) {
      this._analyticsService.sendGalleryHashTaggedClicked({ type: 'person' });
    }

    if (searchString) {
      this.searchText = '"' + searchString + '"';
      // this.search.setSearchQuery(this.searchText);
      this.galleryLightbox?.close();
      this.singleGalleryLightbox?.close();
      this._cdr.markForCheck();
      // pass along the search query in case this tag is resolved
      // using a different gallery than the one we are currently on.
      // (mostly just used for if you click a person tag when on a group or
      // event gallery)
      this._router.navigateByUrl('/gallery', {
        state: { search: this.searchText },
      });
    }

    if (tag && tag.groupTag) {
      this._router.navigateByUrl('/gallery/group/' + tag.groupTag.groupHash);
    }
  }
}
