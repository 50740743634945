"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const points_pb_1 = require("minga/proto/points/points_pb");
exports.toProto = (item) => {
    const msg = new points_pb_1.PointsRedeemedItem();
    msg.setId(item.id);
    msg.setPoints(item.points);
    msg.setReward(item.reward);
    msg.setRewardId(item.rewardId);
    msg.setDate(shared_grpc_1.dayJsObjectToDateTimeMsg(item.date));
    const issuedTo = new points_pb_1.PointsHistoryItemPerson();
    issuedTo.setFirstName(item.issuedTo.firstName);
    issuedTo.setLastName(item.issuedTo.lastName);
    issuedTo.setStudentId(item.issuedTo.studentId);
    issuedTo.setGrade(item.issuedTo.grade);
    issuedTo.setBadgeIconUrl(item.issuedTo.badgeIconUrl);
    issuedTo.setBadgeRoleName(item.issuedTo.role);
    msg.setIssuedTo(issuedTo);
    return msg;
};
exports.fromProto = (msg) => ({
    id: msg.getId(),
    points: msg.getPoints(),
    reward: msg.getReward(),
    rewardId: msg.getRewardId(),
    date: shared_grpc_1.dateTimeMessageToDayjsObject(msg.getDate()),
    issuedTo: {
        firstName: msg.getIssuedTo().getFirstName(),
        lastName: msg.getIssuedTo().getLastName(),
        studentId: msg.getIssuedTo().getStudentId(),
        grade: msg.getIssuedTo().getGrade(),
        badgeIconUrl: msg.getIssuedTo().getBadgeIconUrl(),
        role: msg.getIssuedTo().getBadgeRoleName(),
    },
});