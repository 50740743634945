"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchProfileContentResponse = void 0;
const jspb = require("google-protobuf");
const common_stream_pb = require("minga/proto/common/stream_pb");
const gateway_feed_pb = require("minga/proto/gateway/feed_pb");
class SearchProfileContentResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return SearchProfileContentResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!SearchProfileContentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
            item: (f = msg.getItem()) && SearchProfileContentResponse.Item.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!SearchProfileContentResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new SearchProfileContentResponse;
        return SearchProfileContentResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!SearchProfileContentResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!SearchProfileContentResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new common_stream_pb.StreamItemMetadata;
                    reader.readMessage(value1, common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
                    msg.setItemMetadata(value1);
                    break;
                case 2:
                    var value2 = new SearchProfileContentResponse.Item;
                    reader.readMessage(value2, SearchProfileContentResponse.Item.deserializeBinaryFromReader);
                    msg.setItem(value2);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!SearchProfileContentResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getItemMetadata();
        if (f != null) {
            writer.writeMessage(1, f, common_stream_pb.StreamItemMetadata.serializeBinaryToWriter);
        }
        f = message.getItem();
        if (f != null) {
            writer.writeMessage(2, f, SearchProfileContentResponse.Item.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        SearchProfileContentResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional StreamItemMetadata item_metadata = 1;
     * @return {?StreamItemMetadata}
     */
    getItemMetadata() {
        return /** @type{?StreamItemMetadata} */ (jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
    }
    /** @param {?StreamItemMetadata|undefined} value */
    setItemMetadata(value) {
        jspb.Message.setWrapperField(this, 1, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItemMetadata() {
        this.setItemMetadata(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItemMetadata() {
        return jspb.Message.getField(this, 1) != null;
    }
    /**
     * optional Item item = 2;
     * @return {?SearchProfileContentResponse.Item}
     */
    getItem() {
        return /** @type{?SearchProfileContentResponse.Item} */ (jspb.Message.getWrapperField(this, SearchProfileContentResponse.Item, 2));
    }
    /** @param {?SearchProfileContentResponse.Item|undefined} value */
    setItem(value) {
        jspb.Message.setWrapperField(this, 2, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearItem() {
        this.setItem(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasItem() {
        return jspb.Message.getField(this, 2) != null;
    }
}
exports.SearchProfileContentResponse = SearchProfileContentResponse;
SearchProfileContentResponse.displayName = "SearchProfileContentResponse";
(function (SearchProfileContentResponse) {
    class Item extends jspb.Message {
        /**
         * Generated by JsPbCodeGenerator.
         * @param {Array=} opt_data Optional initial data array, typically from a
         * server response, or constructed directly in Javascript. The array is used
         * in place and becomes part of the constructed object. It is not cloned.
         * If no data is provided, the constructed object will be empty, but still
         * valid.
         * @extends {jspb.Message}
         * @constructor
         */
        constructor(opt_data) {
            super();
            jspb.Message.initialize(this, opt_data, 0, -1, null, null);
        }
        /**
         * Creates an object representation of this proto.
         * Field names that are reserved in JavaScript and will be renamed to pb_name.
         * Optional fields that are not set will be set to undefined.
         * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
         * For the list of reserved names please see:
         *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
         * @param {boolean=} opt_includeInstance Deprecated. whether to include the
         *     JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @return {!Object}
         */
        toObject(includeInstance) {
            return Item.toObject(includeInstance || false, this);
        }
        /**
         * Static version of the {@see toObject} method.
         * @param {boolean|undefined} includeInstance Deprecated. Whether to include
         *     the JSPB instance for transitional soy proto support:
         *     http://goto/soy-param-migration
         * @param {!Item} msg The msg instance to transform.
         * @return {!Object}
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static toObject(includeInstance, msg) {
            var f;
            var obj = {
                feedItemView: (f = msg.getFeedItemView()) && gateway_feed_pb.ContentFeedItemView.toObject(includeInstance, f),
                separatorText: jspb.Message.getFieldWithDefault(msg, 2, "")
            };
            if (includeInstance) {
                obj.$jspbMessageInstance = msg;
            }
            return obj;
        }
        /**
         * Deserializes binary data (in protobuf wire format).
         * @param {jspb.ByteSource} bytes The bytes to deserialize.
         * @return {!Item}
         */
        static deserializeBinary(bytes) {
            var reader = new jspb.BinaryReader(bytes);
            var msg = new Item;
            return Item.deserializeBinaryFromReader(msg, reader);
        }
        /**
         * Deserializes binary data (in protobuf wire format) from the
         * given reader into the given message object.
         * @param {!SearchProfileContentResponse.Item} msg The message object to deserialize into.
         * @param {!jspb.BinaryReader} reader The BinaryReader to use.
         * @return {!SearchProfileContentResponse.Item}
         */
        static deserializeBinaryFromReader(msg, reader) {
            while (reader.nextField()) {
                if (reader.isEndGroup()) {
                    break;
                }
                var field = reader.getFieldNumber();
                switch (field) {
                    case 1:
                        var value1 = new gateway_feed_pb.ContentFeedItemView;
                        reader.readMessage(value1, gateway_feed_pb.ContentFeedItemView.deserializeBinaryFromReader);
                        msg.setFeedItemView(value1);
                        break;
                    case 2:
                        var value2 = /** @type {string} */ (reader.readString());
                        msg.setSeparatorText(value2);
                        break;
                    default:
                        reader.skipField();
                        break;
                }
            }
            return msg;
        }
        /**
         * Serializes the given message to binary data (in protobuf wire
         * format), writing to the given BinaryWriter.
         * @param {!SearchProfileContentResponse.Item} message
         * @param {!jspb.BinaryWriter} writer
         * @suppress {unusedLocalVariables} f is only used for nested messages
         */
        static serializeBinaryToWriter(message, writer) {
            var f = undefined;
            f = message.getFeedItemView();
            if (f != null) {
                writer.writeMessage(1, f, gateway_feed_pb.ContentFeedItemView.serializeBinaryToWriter);
            }
            f = message.getSeparatorText();
            if (f.length > 0) {
                writer.writeString(2, f);
            }
        }
        /**
         * Serializes the message to binary data (in protobuf wire format).
         * @return {!Uint8Array}
         */
        serializeBinary() {
            var writer = new jspb.BinaryWriter();
            SearchProfileContentResponse.Item.serializeBinaryToWriter(this, writer);
            return writer.getResultBuffer();
        }
        /**
         * optional ContentFeedItemView feed_item_view = 1;
         * @return {?ContentFeedItemView}
         */
        getFeedItemView() {
            return /** @type{?ContentFeedItemView} */ (jspb.Message.getWrapperField(this, gateway_feed_pb.ContentFeedItemView, 1));
        }
        /** @param {?ContentFeedItemView|undefined} value */
        setFeedItemView(value) {
            jspb.Message.setWrapperField(this, 1, value);
        }
        /**
         * Clears the message field making it undefined.
         */
        clearFeedItemView() {
            this.setFeedItemView(undefined);
        }
        /**
         * Returns whether this field is set.
         * @return {boolean}
         */
        hasFeedItemView() {
            return jspb.Message.getField(this, 1) != null;
        }
        /**
         * optional string separator_text = 2;
         * @return {string}
         */
        getSeparatorText() {
            return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
        }
        ;
        /** @param {string} value */
        setSeparatorText(value) {
            jspb.Message.setProto3StringField(this, 2, value);
        }
    }
    Item.displayName = "SearchProfileContentResponse.Item";
    SearchProfileContentResponse.Item = Item;
})(SearchProfileContentResponse = exports.SearchProfileContentResponse || (exports.SearchProfileContentResponse = {})); // namespace SearchProfileContentResponse