import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IHallPass } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import { HpmReportsHistoryService, HpmReportsService } from '../../services';
import { HpmReportsHistoryActionService } from '../../services/hpm-reports-history-action.service';
import { HpmReportsHistoryMessages } from './hpm-reports-history.constants';

@Component({
  selector: 'mg-hpm-reports-history',
  templateUrl: './hpm-reports-history.component.html',
  styleUrls: [
    '../hpm-reports.styles.scss',
    './hpm-reports-history.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HpmReportsHistoryService, HpmReportsHistoryActionService],
})
export class HpmReportsHistoryComponent implements OnInit, OnDestroy {
  public readonly MESSAGES = HpmReportsHistoryMessages;

  public readonly canMultiSelect$ = this._permissions.observePermission(
    MingaPermission.HALL_PASS_TYPE_MANAGE,
  );

  private _destroyedSubject = new ReplaySubject<void>(1);
  private _selectableItems: IHallPass[] = [];

  constructor(
    public ds: HpmReportsHistoryService,
    public actionService: HpmReportsHistoryActionService,
    private _permissions: PermissionsService,
    private _hpmReportService: HpmReportsService,
  ) {}

  ngOnInit(): void {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.clearSelection();
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this._hpmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => this.actionService.clearSelection());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
