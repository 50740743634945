"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const minga_pb_1 = require("minga/proto/gateway/minga_pb");
exports.toProto = (item) => {
    const msg = new minga_pb_1.sisSyncLogTotal();
    msg.setTotalArchived(item.archived);
    msg.setTotalCreated(item.created);
    msg.setTotalErrors(item.errors);
    msg.setTotalIgnored(item.ignored);
    msg.setTotalRestored(item.restored);
    msg.setTotalUpdated(item.updated);
    return msg;
};
exports.fromProto = (msg) => {
    const role = {
        archived: msg.getTotalArchived(),
        created: msg.getTotalCreated(),
        errors: msg.getTotalErrors(),
        ignored: msg.getTotalIgnored(),
        restored: msg.getTotalRestored(),
        updated: msg.getTotalUpdated(),
    };
    return role;
};