import { Component, Input } from '@angular/core';

import {
  AspectRatio,
  BorderRadius,
  Height,
} from './placeholder-skeleton.types';

/**
 * The skeleton is the most basic building block in the placeholder component,
 * but it should seldom be used directly unless you are defining a new element.
 * It is essentially a glorified wrapper around a couple css values.
 *
 * The skeleton will always fill the entire width it is given, unless it has an
 * aspect ratio property set. If you want to set the width of a skeleton, the
 * parent should do that with a wrapping div.
 */
@Component({
  selector: 'mg-placeholder-skeleton',
  template: '<div [ngClass]="styleClasses"></div>',
})

//
export class PlaceholderSkeletonComponent {
  // Properties
  @Input() height: Height = '10';
  @Input() borderRadius: BorderRadius = 'small';
  @Input() aspectRatio: AspectRatio = 'auto';

  // Map input properties to style classes.
  get styleClasses(): string[] {
    return [
      'tw-block tw-bg-gray-400',
      this._getHeight(),
      this._getBorderRadius(),
      this._getAspectRatio(),
    ];
  }

  // Get the corresponding height style class for the given input.
  private _getHeight(): string {
    const lookup: Record<Height, string> = {
      '10': 'tw-h-2.5',
      '16': 'tw-h-4',
      '20': 'tw-h-5',
      '24': 'tw-h-6',
      '30': 'tw-h-7.5',
      '40': 'tw-h-10',
      '56': 'tw-h-14',
      '96': 'tw-h-24',
      '208': 'tw-h-52',
    };
    return lookup[this.height];
  }

  // Get the corresponding border radius style class for the given input.
  private _getBorderRadius(): string {
    const lookup: Record<BorderRadius, string> = {
      none: '',
      xsmall: 'tw-rounded-xs',
      small: 'tw-rounded-sm',
      medium: 'tw-rounded-md',
      large: 'tw-rounded-lg',
      xlarge: 'tw-rounded-xl',
      full: 'tw-rounded-full',
    };
    return lookup[this.borderRadius];
  }

  // Get the corresponding aspect ratio style class for the given input.
  private _getAspectRatio(): string {
    const lookup: Record<AspectRatio, string> = {
      auto: '',
      square: 'tw-aspect-square',
      video: 'tw-aspect-video',
    };
    return lookup[this.aspectRatio];
  }
}
