<span [ngClass]="classes">
  <span class="inner-content">
    <!-- Add on left -->
    <ng-container *ngIf="addOnLeft">
      <mg-text
        class="add-on-left"
        color="surface-alt"
        variant="body-sm-secondary">
        {{ addOnLeft }}
      </mg-text>
    </ng-container>
    <ng-content> </ng-content>
    <mat-icon
      *ngIf="type === 'button'"
      inline="true"
      aria-hidden="false"
      [style.color]="iconColor">
      chevron_right
    </mat-icon>
  </span>
</span>
