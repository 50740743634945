import { Pipe, PipeTransform } from '@angular/core';

import { FormSelectOption } from '@shared/components/form';

import { TableSchedule } from '../components/mm-bs-calendar/mm-bs-calendar.component';

interface OptionItem {
  id: string;
  label: string;
}

@Pipe({
  name: 'overrideOptions',
})
export class OverrideOptionsPipe implements PipeTransform {
  transform(
    options: FormSelectOption[],
    scheduleRow: TableSchedule,
  ): FormSelectOption[] {
    //remove no schedule option for a schedule has no default yet
    const removedNoScheduleOptions = options.filter(
      option => option.value !== 0,
    );

    return scheduleRow.state === 'none'
      ? removedNoScheduleOptions
      : [...options];
  }
}
