import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { PageComponent } from './Page.component';

@NgModule({
  declarations: [PageComponent],
  exports: [PageComponent],
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgOverlayToolsModule,

    // External dependencies
    CommonModule,
  ],
})
export class PageModule {}
