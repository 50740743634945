"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IBannerHeadlineOptionsMessageMapper = void 0;
const banner_pb_1 = require("minga/proto/gateway/banner_pb");
var IBannerHeadlineOptionsMessageMapper;
(function (IBannerHeadlineOptionsMessageMapper) {
    function toBannerLibraryMessage(options) {
        const headlineOptions = new banner_pb_1.Banner.HeadlineOptions();
        headlineOptions.setFontColor(options.fontColor);
        headlineOptions.setFontFamily(options.fontFamily);
        if (options.fontWeight) {
            headlineOptions.setFontWeight(options.fontWeight);
        }
        headlineOptions.setOffsetX(options.offsetX);
        headlineOptions.setOffsetY(options.offsetY);
        headlineOptions.setAlign(bannerHeadlineAlignSqlEnumToMsgEnum(options.align));
        headlineOptions.setAnchor(bannerHeadlineAnchornSqlEnumToMsgEnum(options.anchor));
        headlineOptions.setItalic(options.italic);
        headlineOptions.setMaxFontSize(options.maxFontSize || 3);
        headlineOptions.setMinFontSize(options.minFontSize || 3);
        if (options.lineHeight) {
            headlineOptions.setLineHeight(options.lineHeight);
        }
        else {
            headlineOptions.setLineHeight(0);
        }
        return headlineOptions;
    }
    IBannerHeadlineOptionsMessageMapper.toBannerLibraryMessage = toBannerLibraryMessage;
    function fromBannerLibraryMessage(options) {
        let offsetX = options.getOffsetX() / 100;
        let offsetY = options.getOffsetY() / 100;
        let align = bannerHeadlineAlignMsgEnumToSqlEnum(options.getAlign());
        let anchor = bannerHeadlineAnchorMsgEnumToSqlEnum(options.getAnchor());
        return {
            fontColor: options.getFontColor(),
            fontFamily: options.getFontFamily(),
            fontWeight: options.getFontWeight(),
            offsetX: offsetX,
            offsetY: offsetY,
            italic: options.getItalic(),
            maxFontSize: options.getMaxFontSize() || 3,
            minFontSize: options.getMinFontSize() || 3,
            align: align,
            anchor: anchor,
            lineHeight: options.getLineHeight() || 0,
        };
    }
    IBannerHeadlineOptionsMessageMapper.fromBannerLibraryMessage = fromBannerLibraryMessage;
    function bannerHeadlineAnchorMsgEnumToSqlEnum(anchorEnum) {
        switch (anchorEnum) {
            case banner_pb_1.Banner.HeadlineOptions.Anchor.TOP_LEFT:
                return 'TOP_LEFT';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.TOP_CENTER:
                return 'TOP_CENTER';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.TOP_RIGHT:
                return 'TOP_RIGHT';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.MID_LEFT:
                return 'MID_LEFT';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.MID_CENTER:
                return 'MID_CENTER';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.MID_RIGHT:
                return 'MID_RIGHT';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.BTM_LEFT:
                return 'BTM_LEFT';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.BTM_CENTER:
                return 'BTM_CENTER';
            case banner_pb_1.Banner.HeadlineOptions.Anchor.BTM_RIGHT:
                return 'BTM_RIGHT';
        }
        return 'TOP_LEFT';
    }
    IBannerHeadlineOptionsMessageMapper.bannerHeadlineAnchorMsgEnumToSqlEnum = bannerHeadlineAnchorMsgEnumToSqlEnum;
    function bannerHeadlineAlignMsgEnumToSqlEnum(alignEnum) {
        switch (alignEnum) {
            case banner_pb_1.Banner.HeadlineOptions.Align.CENTER:
                return 'CENTER';
            case banner_pb_1.Banner.HeadlineOptions.Align.LEFT:
                return 'LEFT';
            case banner_pb_1.Banner.HeadlineOptions.Align.RIGHT:
                return 'RIGHT';
        }
        return 'LEFT';
    }
    IBannerHeadlineOptionsMessageMapper.bannerHeadlineAlignMsgEnumToSqlEnum = bannerHeadlineAlignMsgEnumToSqlEnum;
    function bannerHeadlineAlignSqlEnumToMsgEnum(alignEnum) {
        switch (alignEnum) {
            case 'LEFT':
                return banner_pb_1.Banner.HeadlineOptions.Align.LEFT;
            case 'CENTER':
                return banner_pb_1.Banner.HeadlineOptions.Align.CENTER;
            case 'RIGHT':
                return banner_pb_1.Banner.HeadlineOptions.Align.RIGHT;
        }
        return banner_pb_1.Banner.HeadlineOptions.Align.LEFT;
    }
    IBannerHeadlineOptionsMessageMapper.bannerHeadlineAlignSqlEnumToMsgEnum = bannerHeadlineAlignSqlEnumToMsgEnum;
    function bannerHeadlineAnchornSqlEnumToMsgEnum(anchorEnum) {
        switch (anchorEnum) {
            case 'TOP_LEFT':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.TOP_LEFT;
            case 'TOP_CENTER':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.TOP_CENTER;
            case 'TOP_RIGHT':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.TOP_RIGHT;
            case 'MID_LEFT':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.MID_LEFT;
            case 'MID_CENTER':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.MID_CENTER;
            case 'MID_RIGHT':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.MID_RIGHT;
            case 'BTM_LEFT':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.BTM_LEFT;
            case 'BTM_CENTER':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.BTM_CENTER;
            case 'BTM_RIGHT':
                return banner_pb_1.Banner.HeadlineOptions.Anchor.BTM_RIGHT;
        }
        return banner_pb_1.Banner.HeadlineOptions.Anchor.TOP_LEFT;
    }
    IBannerHeadlineOptionsMessageMapper.bannerHeadlineAnchornSqlEnumToMsgEnum = bannerHeadlineAnchornSqlEnumToMsgEnum;
})(IBannerHeadlineOptionsMessageMapper = exports.IBannerHeadlineOptionsMessageMapper || (exports.IBannerHeadlineOptionsMessageMapper = {}));