<!-- Toggle expand state button -->
<button
  *ngIf="(media.isMobileView$ | async) === false"
  id="toggle-collapse-button"
  role="button"
  matRipple
  matRippleColor="rgba(53, 138, 181, 0.12)"
  (click)="layout.togglePrimaryNavigation()">
  <mg-icon
    [iconName]="
      (layout.isPrimaryNavigationOpen$ | async)
        ? 'mg-left-panel-close'
        : 'mg-left-panel-open'
    ">
  </mg-icon>
</button>

<!-- Container -->
<div
  class="wrapper"
  [class.is-collapsed]="(layout.isPrimaryNavigationOpen$ | async) === false">
  <!-- School name -->
  <section
    *ngIf="minga$ | async as minga"
    class="minga">
    <!-- Mobile close button -->
    <mg-btn
      *ngIf="media.isMobileView$ | async"
      variant="icon-secondary"
      iconSet="material"
      icon="close"
      [tabIndex]="-1"
      (pressed)="closeDrawer.emit()">
    </mg-btn>
    <!-- Minga button -->
    <div
      class="minga-button-container"
      [class.is-active]="isOnMingaProfile">
      <a
        class="minga-button"
        matRipple
        matRippleColor="rgba(53, 138, 181, 0.12)"
        [routerLink]="'/minga-profile'">
        <!-- Minga logo -->
        <div
          *ngIf="!!minga?.logo"
          class="minga-image"
          [style.backgroundImage]="'url(' + minga?.logo + ')'"></div>
        <!-- Minga default logo -->
        <mg-logo
          *ngIf="!minga?.logo"
          class="minga-image">
        </mg-logo>
        <!-- Minga name -->
        <span
          class="minga-name"
          [matTooltip]="minga.name">
          {{ minga.name }}
        </span>
      </a>
      <!-- Minga switcher -->
      <button
        *ngIf="(mingas$ | async)?.length > 1"
        class="minga-switcher"
        role="button"
        [class.is-collapsed]="
          (layout.isPrimaryNavigationOpen$ | async) === false
        ">
        <mg-icon
          class="switcher-icon"
          (click)="openMingaSwitcher()">
          minga-switcher
        </mg-icon>
      </button>
    </div>
  </section>
  <!-- Navigation -->
  <nav class="nav">
    <menu
      *ngIf="sortedNavigationItems$ | async as routes"
      class="nav-menu">
      <!-- Item -->
      <ng-container *ngFor="let route of routes">
        <ng-container
          *ngIf="
            (route.canAccess$ | async) === false &&
            ('MINGA_MANAGE' | hasPermission) &&
            route.hasPromotionalModal
          "
          [ngTemplateOutlet]="noLinkTemplate"
          [ngTemplateOutletContext]="{ route: route }">
        </ng-container>
        <li
          *ngIf="route.canAccess$ | async"
          class="nav-menu-item"
          matRipple
          matRippleColor="rgba(53, 138, 181, 0.12)">
          <!-- Link element -->
          <a
            routerLinkActive="active"
            [routerLink]="
              route.routerOutlet
                ? ['', { outlets: { o: [route.path] } }]
                : route.path
            "
            [routerLinkActiveOptions]="{ exact: false }"
            [title]="route.title"
            [attr.aria-label]="route.description"
            [attr.data-test]="'primary-nav-item'"
            #rla="routerLinkActive">
            <div class="icon-container">
              <!-- Menu icon -->
              <mg-icon
                *ngIf="route.path !== 'tools'; else avatarTemplate"
                [iconName]="
                  rla.isActive || isActive(route.path)
                    ? route?.iconActive
                    : route?.icon
                "
                [class.disabled]="(route.canAccess$ | async) === false">
              </mg-icon>
              <!-- Avatar template -->
              <ng-template #avatarTemplate>
                <mg-person-avatar
                  *ngIf="route.path === 'tools'"
                  [title]="route.title"
                  [noDisplayName]="true"
                  [borderless]="true">
                </mg-person-avatar>
              </ng-template>
            </div>
            <!-- Label -->
            <span [class.disabled]="(route.canAccess$ | async) === false">
              {{ route?.title }}
            </span>
            <mg-icon
              *ngIf="(route.canAccess$ | async) === false"
              class="lock-icon"
              iconName="mg-lock">
            </mg-icon>
          </a>
        </li>
      </ng-container>

      <li *ngIf="'SUPERADMIN' | hasPermission">
        <mg-form-select
          mingaSwitcher
          class="superadmin-select"
          appearance="primary"
          placeholder="Search For Minga"
          labelBackground="grey"
          [tabIndex]="-1"
          [searchable]="true"
          [multiple]="false"
          [isClearable]="true"
          [hideSelected]="true"
          [floatingLabel]="false">
        </mg-form-select>
      </li>
    </menu>
  </nav>
  <!-- Help section -->
  <section class="help">
    <button
      role="button"
      class="help-button"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="openHelpCenter()">
      <div class="help-button-icon">
        <mg-icon>mg-help-center</mg-icon>
      </div>
      <span class="help-button-label">
        {{ MSG.BUTTON_HELP_BUTTON }}
      </span>
    </button>
    <!-- Help action menu -->
    <nav class="nav">
      <menu
        class="nav-menu help-menu"
        [class.is-collapsed]="
          (layout.isPrimaryNavigationOpen$ | async) === false
        ">
        <!-- Feedback button -->
        <li
          *ngIf="(isStudent$ | async) === false"
          class="nav-menu-item">
          <a
            matRipple
            matRippleColor="rgba(53, 138, 181, 0.12)"
            [routerLink]="['', { outlets: { o: ['dialog', 'feedback'] } }]"
            [title]="MSG.BUTTON_FEEDBACK_DESCRIPTION">
            <div class="icon-container">
              <mg-icon iconName="mg-feedback"></mg-icon>
            </div>
          </a>
        </li>
        <!-- About minga -->
        <li
          class="nav-menu-item"
          matRipple
          matRippleColor="rgba(53, 138, 181, 0.12)">
          <a
            (click)="openAbout()"
            [title]="MSG.BUTTON_ABOUT_US_DESCRIPTION">
            <div class="icon-container">
              <mg-icon iconName="aboutminga-o"></mg-icon>
            </div>
          </a>
        </li>
        <!-- Logout -->
        <li
          class="nav-menu-item"
          matRipple
          matRippleColor="rgba(53, 138, 181, 0.12)">
          <a
            [title]="MSG.BUTTON_LOGOUT_DESCRIPTION"
            (click)="logout()">
            <div class="icon-container">
              <mg-icon iconName="mg-logout"></mg-icon>
            </div>
          </a>
        </li>
        <!-- Suicide prevention line -->
        <li
          *ngIf="
            (isStudent$ | async) &&
            (layout.isPrimaryNavigationOpen$ | async) === false
          "
          class="nav-menu-item"
          matRipple
          matRippleColor="rgba(53, 138, 181, 0.12)">
          <a
            [title]="MSG.BUTTON_SUICIDE_HOTLINE"
            [routerLink]="[
              '',
              { outlets: { o: ['suicidepreventionhotline'] } }
            ]">
            <div class="icon-container">
              <mg-icon iconName="phone-o"></mg-icon>
            </div>
          </a>
        </li>
      </menu>
    </nav>
    <div
      *ngIf="(isStudent$ | async) && (layout.isPrimaryNavigationOpen$ | async)"
      class="underlined-text"
      [routerLink]="['', { outlets: { o: ['suicidepreventionhotline'] } }]">
      {{ MSG.BUTTON_SUICIDE_HOTLINE }}
    </div>
  </section>
</div>
<!-- No link template -->
<ng-template
  #noLinkTemplate
  let-route="route">
  <li
    matRipple
    class="nav-menu-item"
    matRippleColor="rgba(53, 138, 181, 0.12)">
    <!-- Link element -->
    <a
      [title]="route.title"
      [attr.aria-label]="route.description"
      (click)="openPromotionalModal(route.title)">
      <div class="icon-container">
        <!-- Menu icon -->
        <mg-icon
          class="disabled"
          [iconName]="route?.icon">
        </mg-icon>
      </div>
      <!-- Label -->
      <span class="disabled">
        {{ route?.title }}
      </span>
      <mg-icon
        class="lock-icon"
        iconName="mg-lock">
      </mg-icon>
    </a>
  </li>
</ng-template>
