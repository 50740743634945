"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const proto = require("minga/proto/bell_schedule/bell_schedule_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const period_pb_1 = require("minga/proto/period/period_pb");
exports.toProto = (bellSchedule) => {
    var _a, _b;
    const msg = new proto.IBellSchedule();
    msg.setId(bellSchedule.id);
    msg.setName(bellSchedule.name);
    if (bellSchedule.days)
        msg.setDaysList(bellSchedule.days);
    if ((_a = bellSchedule.overrideDates) === null || _a === void 0 ? void 0 : _a.size) {
        const overrideDates = [];
        bellSchedule.overrideDates.forEach((dates, termId) => {
            const overrideDate = new proto.OverrideDates();
            overrideDate.setTermId(termId);
            overrideDate.setDatesList(dates.map(d => shared_grpc_1.dayJsObjectToDateTimeMsg(d)));
            overrideDates.push(overrideDate);
        });
        msg.setOverrideDatesList(overrideDates);
    }
    if (bellSchedule.periods) {
        const periodMsgs = [];
        bellSchedule.periods.forEach(period => {
            const periodMsg = new period_pb_1.Period();
            if (period.id)
                periodMsg.setId(period.id);
            periodMsg.setShortCode(period.shortCode);
            periodMsg.setPeriodName(period.periodName);
            periodMsg.setStartTime(period.startTime);
            periodMsg.setEndTime(period.endTime);
            periodMsgs.push(periodMsg);
        });
        msg.setPeriodsList(periodMsgs);
    }
    if ((_b = bellSchedule.terms) === null || _b === void 0 ? void 0 : _b.length) {
        const termMsgs = [];
        const termIds = [];
        bellSchedule.terms.forEach(term => {
            const termMsg = new proto.Term();
            termMsg.setId(term.id);
            termMsg.setTitle(term.title);
            termMsgs.push(termMsg);
            termIds.push(term.id);
        });
        msg.setTermsList(termMsgs);
        msg.setTermIdsList(termIds);
    }
    return msg;
};
exports.fromProto = (msg) => {
    var _a;
    const bellSchedule = {
        id: msg.getId(),
        name: msg.getName(),
        periods: [],
        days: [],
        terms: [],
        termIds: msg.getTermIdsList(),
    };
    if (msg.getPeriodsList()) {
        const periodList = [];
        if (msg.getPeriodsList().length > 0) {
            for (const period of msg.getPeriodsList()) {
                const periodD = {
                    id: period.getId(),
                    shortCode: period.getShortCode(),
                    periodName: period.getPeriodName(),
                    startTime: period.getStartTime(),
                    endTime: period.getEndTime(),
                };
                periodList.push(periodD);
            }
        }
        bellSchedule.periods = periodList;
    }
    if (msg.getDaysList())
        bellSchedule.days = msg.getDaysList();
    if (msg.getTermsList()) {
        const termList = [];
        if (msg.getTermsList().length > 0) {
            for (const term of msg.getTermsList()) {
                const termD = {
                    id: term.getId(),
                    title: term.getTitle(),
                };
                termList.push(termD);
            }
        }
        bellSchedule.terms = termList;
    }
    if ((_a = msg.getOverrideDatesList()) === null || _a === void 0 ? void 0 : _a.length) {
        const overrideDates = new Map();
        msg.getOverrideDatesList().forEach(dates => {
            overrideDates.set(dates.getTermId(), dates.getDatesList().map(d => shared_grpc_1.dateTimeMessageToDayjsObject(d)));
        });
        bellSchedule.overrideDates = overrideDates;
    }
    return bellSchedule;
};