"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveMingasFinder = void 0;
/**
 * Class for finding 'active' mingas. This is a buisness critieria 'active' not
 * whether or not it's 'enabled' or similar. To be considered active a minga has
 * to have a certain amount of activity during the `activeWithin` date range
 * found in `FindActiveMingaQuery`
 */
class ActiveMingasFinder {
}
exports.ActiveMingasFinder = ActiveMingasFinder;