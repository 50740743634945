"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultSisFields = exports.SisIdFieldKey = void 0;
const domain_1 = require("minga/libraries/domain/index");
var SisIdFieldKey;
(function (SisIdFieldKey) {
    SisIdFieldKey["IDENTIFIER"] = "identifier";
    SisIdFieldKey["SOURCE_ID"] = "sourcedId";
    SisIdFieldKey["USERNAME"] = "username";
    SisIdFieldKey["SIS_ID"] = "sis_id";
    SisIdFieldKey["STATE_ID"] = "state_id";
})(SisIdFieldKey = exports.SisIdFieldKey || (exports.SisIdFieldKey = {}));
exports.createDefaultSisFields = () => {
    return {
        url: '',
        clientId: '',
        clientSecret: '',
        authUrl: '',
        schoolId: '',
        studentUrl: '',
        teacherUrl: '',
        lastModified: false,
        accessToken: '',
        applicationId: '',
        emailList: [],
        sisType: '',
        updateExisting: false,
        archiveMissing: false,
        active: false,
        rosteringMethod: domain_1.RosteringMethod.ONE_ROSTER,
        idNumberFieldName: SisIdFieldKey.IDENTIFIER,
        studentRostering: false,
        teacherRostering: false,
        staffRostering: false,
        adminRostering: false,
        parentRostering: false,
        classListRostering: false,
        notificationEmails: [],
        updateExistingStudents: false,
        updateExistingStaff: false,
        updateExistingTeachers: false,
        updateExistingAdmins: false,
        archiveMissingStudents: false,
        archiveMissingStaff: false,
        archiveMissingTeachers: false,
        archiveMissingAdmins: false,
    };
};