<ng-container *ngIf="layout === 'in-bottom-sheet'">
  <mg-form-sheet-grouped-select
    [id]="id"
    [value]="initialOptions$ | async"
    [disabled]="disabled"
    [options]="options"
    [commitChanges$]="commitChanges$"
    [multiple]="multiple"
    [lazyLoad]="true"
    [clearSelection]="clearSelection"
    (selectChange)="selectChangeHandler($event)"
    (commitChanges)="onCommitChanges($event)">
  </mg-form-sheet-grouped-select>
</ng-container>

<ng-container *ngIf="layout === 'desktop' || layout === 'mobile'">
  <ng-container *ngIf="layout === 'mobile'">
    <button
      class="invisible-trigger btn-reset"
      type="button"
      (click)="openBottomSheet()"></button>
  </ng-container>

  <mg-form-grouped-select
    [ngClass]="'layout-' + layout"
    #formSelectComponent
    *ngIf="dataLoaded$ | async; else loadingTemplate"
    [appendTo]="appendTo"
    [id]="id"
    [value]="initialOptions$ | async"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [options]="options"
    [multiple]="multiple"
    [lazyLoad]="true"
    [disableWrapping]="disableWrapping"
    (selectChange)="selectChangeHandler($event)">
  </mg-form-grouped-select>

  <ng-template #loadingTemplate>
    <mg-form-grouped-select
      [disabled]="true"
      [loading]="true"
      [placeholder]="placeholder">
    </mg-form-grouped-select>
  </ng-template>
</ng-container>
