import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { concatMap, filter, mergeMap, tap } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { INotificationType } from 'minga/libraries/domain';
import { Notification } from 'minga/proto/gateway/notification_pb';

import { NotificationStoreModel } from '../store/Notifications/model';
import { NotificationsState } from '../store/Notifications/selectors';

export abstract class NotificationListenerBaseService {
  public readonly notifications$: Observable<Notification.AsObject>;

  private readonly _inNotifications$: Observable<NotificationStoreModel[]> =
    this._store.pipe(select(NotificationsState.selectAll));
  private _processedNotifications: string[] = [];

  constructor(private _store: Store<any>) {
    this.notifications$ = this._inNotifications$.pipe(
      mergeMap(notifications => notifications),
      // don't do it again if we've already processed it.
      // We need to do this because the observable emits
      // the whole array every time there was a change
      // so we may end up processing a notification
      // several times before it is removed from the store
      filter((notification: NotificationStoreModel) => {
        return (
          !this._processedNotifications.includes(notification.id || '') &&
          this._isValidNotification(notification)
        );
      }),
      tap(notification => this._addNotificationToProcessedList(notification)),
      // concatMap here allows us to perform _handleNotification
      // synchronously
      // if we did the handling in the subscribe function
      // then you'd get multiple notifications being handled concurrently
      // in this case it'd show multiple points dialogs at once.
      concatMap((notification: NotificationStoreModel) => {
        return of(notification.message);
      }),
    );
  }

  private _isValidNotification(msg: NotificationStoreModel): boolean {
    if (
      msg.id &&
      msg.message &&
      !msg.message.read &&
      $enum(INotificationType).isKey(msg.notificationType)
    ) {
      return true;
    }

    return false;
  }

  private _addNotificationToProcessedList(
    notification: NotificationStoreModel,
  ) {
    if (!this._processedNotifications.includes(notification.id)) {
      this._processedNotifications.push(notification.id);
    }
  }
}
