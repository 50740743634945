import { Injectable } from '@angular/core';

import * as report_proto from 'minga/proto/stats_report/stats_report_pb';
import { EventReportFilters } from 'minga/libraries/domain';
import { ReportTypes } from 'minga/libraries/domain';
import { EventPersonMapper } from 'minga/libraries/shared-grpc';
import { EventFilterMapper } from 'minga/libraries/shared-grpc';
import { StatsReportManager } from 'minga/proto/stats_report/stats_report_ng_grpc_pb';
import { RootService } from 'src/app/minimal/services/RootService';

import { ReportsService } from '../../manager-report/services/report-service.service';
import {
  EventReportFiltersKeys,
  EVENT_REPORT_FILTER_INITIAL_STATE,
  EVENT_REPORT_FILTER_NON_ARRAY_TYPES,
} from './EventPeople.constants';

@Injectable({ providedIn: 'root' })
export class EventPeopleReportService extends ReportsService<EventReportFilters> {
  constructor(_reportService: StatsReportManager, _rootService: RootService) {
    super(
      EVENT_REPORT_FILTER_INITIAL_STATE,
      EVENT_REPORT_FILTER_NON_ARRAY_TYPES,
      undefined,
      _reportService,
      _rootService,
    );
  }

  public async getEventPeople(offset: number = 0, limit: number = 10) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.EVENT_PEOPLE,
      offset,
      limit,
    );
    return {
      items: infos.map(info => {
        const person = info.getEventPerson();
        return EventPersonMapper.fromProto(person);
      }),
      pageToken,
    };
  }

  _handleSetFilter(filter: EventReportFiltersKeys, value: any) {
    switch (filter) {
      case EventReportFiltersKeys.EVENT_CONTEXT:
        this.filter.eventContext = value;
        break;
      case EventReportFiltersKeys.PERSON_STATUS:
        this.filter.personStatus = value;
        break;
      default:
        break;
    }
  }

  _mapFiltersToFilterMessage(
    reportType: ReportTypes,
    offset?: number,
    limit?: number,
  ) {
    const req = new report_proto.GetOrExportReportRequest();
    const filterMessage = EventFilterMapper.toProto(this.filter, limit, offset);
    req.setReportType(reportType);
    req.setEventFilters(filterMessage);
    return req;
  }
}
