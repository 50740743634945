<!-- Container -->
<div
  [fxHide]="dismissAlert"
  [ngClass]="classes">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px">
    <!-- Icon -->
    <div
      *ngIf="icon"
      fxFlex="nogrow"
      class="system-alert-snackbar-icon">
      <mg-icon [iconName]="icon"></mg-icon>
    </div>
    <!-- Message -->
    <div
      fxFlex
      class="system-alert-snackbar-message">
      {{ message }}
    </div>
    <!-- Dismiss Button -->
    <div
      *ngIf="dismissable"
      fxFlex="nogrow"
      fxLayoutAlign="end"
      class="system-alert-snackbar-dismiss">
      <mg-btn
        variant="text"
        size="small"
        [id]="id"
        (pressed)="close()">
        {{ buttonText || MSG.BUTTON_LABEL_ACKNOWLEDGE }}
      </mg-btn>
    </div>
  </div>
</div>
