import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { Checkin } from 'minga/libraries/domain';
import { CheckInReportColumns } from 'minga/libraries/shared';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';

import { CheckinManagerReportType } from '../checkin-manager-reports.constants';
import { CheckinManagerReportsService } from '../checkin-manager-reports.service';

@Injectable()
export class CheckinManagerReasonsDatasourceService extends ReportDatasourceService<Checkin> {
  // Variables for setting up the report.
  private _reportType = CheckinManagerReportType.CHECKINS;
  private _reportFn: (
    offset: number,
    limit: number,
    sortBy?: Sort,
  ) => Promise<any>;

  constructor(
    private _checkinService: CheckinManagerReportsService,
    _router: Router,
  ) {
    super(
      _router,
      _checkinService,
      CheckinManagerReasonsDatasourceService.name,
    );
    super.setDisplayColumns(CheckInReportColumns.REASON_HISTORY);
    // Need to bind, otherwise can't use _reportsService class fns.
    this._reportFn =
      this._checkinService.getCheckinReport.bind(_checkinService);
  }

  async fetch(offset: number, limit: number): Promise<any> {
    if (this._checkinService.filter.type !== this._reportType) {
      this._reportType = this._checkinService.filter.type;
      const checkin = this._reportType === CheckinManagerReportType.CHECKINS;
      this._reportFn = checkin
        ? this._checkinService.getCheckinReport.bind(this._checkinService)
        : this._checkinService.getAbsenteesReport.bind(this._checkinService);
      super.setDisplayColumns(
        checkin
          ? CheckInReportColumns.REASON_HISTORY
          : CheckInReportColumns.ABSENTEES,
      );
    }
    if (
      this._checkinService.filter.startDate.diff(
        this._checkinService.filter.endDate.startOf('day'),
      ) !== 0
    ) {
      this._checkinService.matchStartEndDate();
    }

    return await this._reportFn(offset, limit, this._currentSort);
  }
}
