"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioContentInfoMapper = void 0;
const designed_svg_pb_1 = require("minga/proto/designed_svg/designed_svg_pb");
const DesignedSvgMapper_1 = require("minga/libraries/shared-grpc/designed_svg/DesignedSvgMapper");
var StudioContentInfoMapper;
(function (StudioContentInfoMapper) {
    function toIStudioContentInfo(studioContentInfo) {
        const designedSvg = studioContentInfo.getDesignedSvg();
        return {
            designedSvg: DesignedSvgMapper_1.DesignedSvgMapper.toIDesignedSvg(designedSvg),
            templateId: studioContentInfo.getTemplateId(),
        };
    }
    StudioContentInfoMapper.toIStudioContentInfo = toIStudioContentInfo;
    function fromIStudioContentInfo(studioContentInfo) {
        const msg = new designed_svg_pb_1.StudioContentInfo();
        msg.setDesignedSvg(DesignedSvgMapper_1.DesignedSvgMapper.fromIDesignedSvg(studioContentInfo.designedSvg));
        msg.setTemplateId(studioContentInfo.templateId);
        return msg;
    }
    StudioContentInfoMapper.fromIStudioContentInfo = fromIStudioContentInfo;
})(StudioContentInfoMapper = exports.StudioContentInfoMapper || (exports.StudioContentInfoMapper = {}));