import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaPermission } from 'minga/libraries/domain';
import { PointReward } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import { PeopleSelectorService } from '@modules/people-selector';

import { PointsService } from '@shared/services/points';

import { PointsRewardsListMessages } from './points-rewards-list.constants';

@Component({
  selector: 'mg-points-rewards-list',
  templateUrl: './points-rewards-list.component.html',
  styleUrls: ['./points-rewards-list.component.scss'],
})
export class PointsRewardsListComponent {
  /** Messages */
  public readonly MESSAGES = PointsRewardsListMessages;

  /** Observables */
  private _rewardTypesList = new BehaviorSubject<PointReward[]>([]);
  public rewardTypesList$ = this._rewardTypesList.asObservable();
  public canRedeemPoints$: Observable<boolean>;

  constructor(
    private _route: ActivatedRoute,
    private _pointsService: PointsService,
    private _permissionsService: PermissionsService,
    private _peopleSelector: PeopleSelectorService,
    private _sanitizer: DomSanitizer,
  ) {
    this._fetchRewardsList();
    this.canRedeemPoints$ = combineLatest([
      this._permissionsService.observePermission(
        MingaPermission.MANAGE_REWARDS,
      ),
      this._permissionsService.observePermission(MingaPermission.POINTS_MANAGE),
      this._route.paramMap,
    ]).pipe(
      map(([hasAllocatePermission, hasManagePermission, urlParams]) => {
        // do not allow redeeming if in profile view
        if (urlParams.has('hash')) return false;

        return hasAllocatePermission || hasManagePermission;
      }),
    );
  }

  public async openPeopleSelector(title: string, id: number): Promise<void> {
    await this._peopleSelector.open('Points Reward', 'redeem', {
      title,
      data: {
        id,
      },
    });
  }

  private async _fetchRewardsList() {
    const pointsRewardList = await this._pointsService.fetchRewardTypes(true);

    const sanitizedList = pointsRewardList.map(rewardType => {
      rewardType.imagePath = this._sanitizer.sanitize(
        SecurityContext.URL,
        rewardType.imagePath,
      );
      return rewardType;
    });

    this._rewardTypesList.next(sanitizedList);
  }
}
