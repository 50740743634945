var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RealtimeNotificationSender = exports.RealtimeNotificationConsumer = void 0;
const operators_1 = require("rxjs/operators");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const REALTIME_NEW_NOTIFICATION_KEY = 'notification';
class RealtimeNotificationConsumer {
    constructor(_dataConsumer) {
        this.newNotification$ = _dataConsumer.messageNotice$.pipe(operators_1.map(payload => payload[REALTIME_NEW_NOTIFICATION_KEY]), operators_1.filter(newDm => typeof newDm !== 'undefined'), operators_1.map(msgBin => msgBin), operators_1.map(shared_grpc_1.INewNotificationMapper.fromBase64));
    }
}
exports.RealtimeNotificationConsumer = RealtimeNotificationConsumer;
class RealtimeNotificationSender {
    constructor(_dataSender) {
        this._dataSender = _dataSender;
    }
    sendNewMessageNotice(message) {
        return __awaiter(this, void 0, void 0, function* () {
            const payload = {
                [REALTIME_NEW_NOTIFICATION_KEY]: shared_grpc_1.INewNotificationMapper.toBase64(message),
            };
            yield this._dataSender.send(payload);
        });
    }
}
exports.RealtimeNotificationSender = RealtimeNotificationSender;