import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ScheduledReportFrequency,
  ScheduledReportMinimal,
} from 'minga/libraries/domain';

import { ModalOverlayServiceCloseEventType } from '@shared/components/modal-overlay';
import { PaginatorComponent } from '@shared/components/paginator';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ScheduledReportsService } from '@shared/services/scheduled-reports/scheduled-reports.service';

import {
  SCHEDULED_REPORT_TABLE_COLUMNS,
  ScheduledReportTableColumn,
  ScheduledReportTableMessages,
  TABLE_VIEWPORT_WIDTH_BREAKPOINT,
} from '../../constants';
import { MmScheduledReportsService } from '../../services';
import { ScheduledReportsStore } from '../../store/scheduled-reports.store';

@Component({
  selector: 'mg-mm-sr-reports-table',
  templateUrl: './mm-sr-reports-table.component.html',
  styleUrls: ['./mm-sr-reports-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmSrReportsTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(PaginatorComponent)
  paginator: PaginatorComponent;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private _destroyed$ = new ReplaySubject<void>(1);
  public isLoading$ = this._scheduledReportsStore.isLoading$;
  public readonly COLUMN_DEF = ScheduledReportTableColumn;
  public MESSAGES = ScheduledReportTableMessages;
  public FREQUENCY_TYPE = ScheduledReportFrequency;

  private _togglesInFlight = new BehaviorSubject<Set<number>>(new Set());
  public togglesInFlight$ = this._togglesInFlight.asObservable();

  public displayColumns = SCHEDULED_REPORT_TABLE_COLUMNS;
  public readonly dataSource = new MatTableDataSource<ScheduledReportMinimal>(
    [],
  );

  public mobileExpanded = {};

  private _displayedColumnsSubject = new BehaviorSubject<string[]>([]);
  public readonly displayedColumns$: Observable<string[]> =
    this._displayedColumnsSubject.asObservable();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this._getDisplayedColumns(event.target.innerWidth);
  }

  constructor(
    private _scheduledReportsStore: ScheduledReportsStore,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _cdr: ChangeDetectorRef,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _scheduledReportsService: ScheduledReportsService,
  ) {}

  ngOnInit(): void {
    this._scheduledReportsStore.getScheduledReports();
    this._scheduledReportsStore.scheduledReports$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(scheduledReports => {
        this.dataSource.data = scheduledReports;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator.matPaginator;
    this.dataSource.sort = this.sort;
    this.sort.sort({
      id: 'createdAt',
      start: 'desc',
      disableClear: false,
    });
    this._getDisplayedColumns(window.innerWidth);
  }

  public trackById(index: number, data: ScheduledReportMinimal) {
    if (!data) return index;
    return data.id;
  }

  public async onActiveToggle(active, id) {
    const inFlight = this._togglesInFlight.value;

    try {
      inFlight.add(id);
      this._togglesInFlight.next(inFlight);
      await this._scheduledReportsService.toggleActive(id);
      this._scheduledReportsStore.toggleActiveSchedule({ id, active });

      this._systemAlertSnackBar.open({
        type: 'default',
        message: active
          ? ScheduledReportTableMessages.SNACKBAR_TOOGLE_ACTIVE_SUCCESS
          : ScheduledReportTableMessages.SNACKBAR_TOOGLE_DEACTIVE_SUCCESS,
      });
    } finally {
      // underlying toggle method takes care of error we just need to remove from inFlight
      inFlight.delete(id);
      this._togglesInFlight.next(inFlight);
    }
  }

  public async onEditClick(id) {
    const modalRef = this._mmScheduledReportService.openModal({
      id,
    });

    modalRef.afterClosed.subscribe(async response => {
      const { type, data } = response;

      let message = '';

      if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
        this._scheduledReportsStore.updateScheduledReport(data.updated);
        message = ScheduledReportTableMessages.SNACKBAR_UPDATE_SUCCESS;
      }
      if (type === ModalOverlayServiceCloseEventType.DELETE) {
        this._scheduledReportsStore.removeScheduledReport(data.deleted);
        message = ScheduledReportTableMessages.SNACKBAR_DELETE_SUCCESS;
      }

      if (message) {
        this._systemAlertSnackBar.open({
          type: 'success',
          message,
        });
      }
    });
  }

  private _getDisplayedColumns(width: number) {
    // 1218px viewport --> 920px table width (when sidebar expanded)
    if (width < TABLE_VIEWPORT_WIDTH_BREAKPOINT) {
      this._displayedColumnsSubject.next([ScheduledReportTableColumn.MOBILE]);
    } else {
      this._displayedColumnsSubject.next(SCHEDULED_REPORT_TABLE_COLUMNS);
    }
  }
}
