<mg-manager-overlay-reports-subpage
  [loading]="false"
  [noBottomPadding]="isReportType(REPORT_TYPE.HALL_PASS_SUMMARY)"
  [columnSettingsButton]="!isReportType(REPORT_TYPE.HALL_PASS_SUMMARY)"
  [scheduleReportsButton]="
    (SUPPPORTED_SCHEDULE_TYPES | includes: getReportType()) &&
    (canScheduleReport$ | async)
  "
  (scheduleReportsPressed)="onScheduleReport()"
  [dataService]="dataService$ | async"
  (exportListPressed)="exportReport()">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="
        (media.isMobileView$ | async) === false;
        else mobileHeaderTemplate
      ">
      <div class="filters-group">
        <div class="filters-group-row">
          <ng-container *ngTemplateOutlet="reportTypeFilterTemplate">
          </ng-container>
          <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
          <!-- Filters form -->
          <ng-container
            *ngIf="(media.isMobileView$ | async) === false"
            [ngTemplateOutlet]="filtersFormReference">
          </ng-container>
        </div>
        <div class="filters-group-row">
          <ng-container *ngTemplateOutlet="createdByTemplate"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Filters form reference -->
  <ng-template #filtersFormReference>
    <mg-filters-form
      [data]="filtersFormStructure$ | async"
      [loading]="loading$ | async"
      [showChips]="true"
      (state)="updateFilters($event)">
    </mg-filters-form>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Filter Template -->
<ng-template #reportTypeFilterTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="305px">
    <mg-form-select
      placeholder="Report Type"
      labelBackground="grey"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="getReportType()"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="REPORT_OPTIONS"
      [floatingLabel]="false"
      (selectionChanged)="onChangeReportType($any($event))">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- Created By Template -->
<ng-template #createdByTemplate>
  <mat-button-toggle-group
    name="createdByFilter"
    class="filter-toggle-group"
    [value]="createdBy$ | async"
    (change)="onChangeCreatedBy($event)">
    <mat-button-toggle
      *ngFor="let option of CREATED_BY_OPTIONS"
      [attr.data-analytics]="option.id"
      [attr.data-test]="option.id"
      [value]="option.value">
      <mg-text variant="body-sm-secondary">
        {{ option.label }}
      </mg-text>
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>

<!-- Mobile Header Template -->
<ng-template #mobileHeaderTemplate>
  <div class="mobile-report-header">
    <ng-container *ngTemplateOutlet="reportTypeFilterTemplate"> </ng-container>
    <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="createdByTemplate"></ng-container>
  </div>
</ng-template>
