"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PollClient = exports.Poll = void 0;
const content_poll_pb = require("minga/proto/content/poll_pb");
const content_common_pb = require("minga/proto/content/common_pb");
const common_stream_pb = require("minga/proto/common/stream_pb");
const gateway_feed_pb = require("minga/proto/gateway/feed_pb");
class Poll {
}
exports.Poll = Poll;
Poll.serviceName = "minga.content.Poll";
Poll.New = {
    methodName: "New",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_pb.PollInfo,
    responseType: content_common_pb.ContentSaveResult,
};
Poll.Update = {
    methodName: "Update",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_pb.DeltaPollInfo,
    responseType: content_common_pb.ContentSaveResult,
};
Poll.GetInfo = {
    methodName: "GetInfo",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_poll_pb.PollInfo,
};
Poll.StreamPolls = {
    methodName: "StreamPolls",
    service: Poll,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_pb.StreamContentFeedResponse,
};
Poll.StreamPollsControl = {
    methodName: "StreamPollsControl",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
};
Poll.StreamPollsV2 = {
    methodName: "StreamPollsV2",
    service: Poll,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: content_poll_pb.StreamPollsV2Item,
};
Poll.StreamPollsV2Control = {
    methodName: "StreamPollsV2Control",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
};
class PollClient {
    new() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    update() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    getInfo() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    streamPolls() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    streamPollsControl() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    streamPollsV2() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
    streamPollsV2Control() {
        throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
    }
}
exports.PollClient = PollClient;