"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cloudflareUrlMetaDecode = exports.cloudflareUrlMetaEncode = void 0;
const path = require("path");
const btoa = globalThis.btoa ||
    ((str) => Buffer.from(str).toString('base64'));
const atob = globalThis.atob ||
    ((str) => Buffer.from(str, 'base64').toString());
function metaRawToMeta(metaRaw) {
    const meta = Object.assign({ country: metaRaw.c, environment: metaRaw.e, clientVersion: metaRaw.v }, metaRaw);
    delete meta.c;
    delete meta.e;
    delete meta.v;
    return meta;
}
function metaToMetaRaw(meta) {
    const metaRaw = Object.assign({ c: meta.country, e: meta.environment, v: meta.clientVersion }, meta);
    delete metaRaw.country;
    delete metaRaw.environment;
    delete metaRaw.clientVersion;
    return metaRaw;
}
const B64_START = '--';
/**
 * Encode 'meta' into name and return new name
 */
function cloudflareUrlMetaEncode(name, meta) {
    const extname = path.extname(name);
    const basename = path.basename(name, extname);
    const metaRaw = metaToMetaRaw(meta);
    const base64Json = btoa(JSON.stringify(metaRaw));
    const newBasename = basename + B64_START + base64Json + extname;
    return newBasename;
}
exports.cloudflareUrlMetaEncode = cloudflareUrlMetaEncode;
/**
 * Decode the `ICloudFlareUploadMeta` originally encoded by
 * `cloudflareUrlMetaEncode()`
 */
function cloudflareUrlMetaDecode(name) {
    const extname = path.extname(name);
    const basename = path.basename(name, extname);
    const metaStr = basename.substr(basename.lastIndexOf(B64_START) + B64_START.length);
    const metaRaw = JSON.parse(atob(metaStr));
    return metaRawToMeta(metaRaw);
}
exports.cloudflareUrlMetaDecode = cloudflareUrlMetaDecode;