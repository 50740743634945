"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RealtimeEventType = void 0;
// namespace these by domain
var RealtimeEventType;
(function (RealtimeEventType) {
    RealtimeEventType["HALL_PASS_GRANTED"] = "HALL_PASS_GRANTED";
    RealtimeEventType["HALL_PASS_NOTIFY"] = "HALL_PASS_NOTIFY";
    RealtimeEventType["HALL_PASS_APPROVAL_REQUEST"] = "HALL_PASS_APPROVAL_REQUEST";
    RealtimeEventType["HALL_PASS_APPROVAL_CANCEL"] = "HALL_PASS_APPROVAL_CANCEL";
    RealtimeEventType["HALL_PASS_APPROVED"] = "HALL_PASS_APPROVED";
    RealtimeEventType["HALL_PASS_DENIED"] = "HALL_PASS_DENIED";
    RealtimeEventType["HALL_PASS_ENDED"] = "HALL_PASS_ENDED";
    /**
     * Generic event for when a hall pass becomes active
     */
    RealtimeEventType["HALL_PASS_ACTIVE"] = "HALL_PASS_ACTIVE";
})(RealtimeEventType = exports.RealtimeEventType || (exports.RealtimeEventType = {}));