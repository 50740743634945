import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTooltipModule } from '@angular/material/tooltip';

import { GenericModule } from '../generic';
import { TextModule } from '../text';
import { BottomSheetComponent } from './bottom-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    TextModule,
    GenericModule,
    MatBottomSheetModule,
    MatTooltipModule,
  ],
  declarations: [BottomSheetComponent],
  exports: [BottomSheetComponent],
})
export class BottomSheetModule {}
