"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentListPresentationMapper = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const comment_pb_1 = require("minga/proto/gateway/comment_pb");
const person_view_pb_1 = require("minga/proto/gateway/person_view_pb");
var CommentListPresentationMapper;
(function (CommentListPresentationMapper) {
    function fromPresentationToCommentContentMessage(commentList, likesList) {
        const buildCommentMsg = (comment) => {
            const commentMsg = new comment_pb_1.CommentContent();
            commentMsg.setText(comment.text);
            commentMsg.setCommentContextHash(comment.commentContextHash);
            commentMsg.setTimestamp(comment.timestamp);
            commentMsg.setTimestamp(comment.timestamp);
            commentMsg.setLikeCount(comment.likeCount);
            commentMsg.setCommentCount(comment.commentCount);
            commentMsg.setDeleted(!!comment.deleted);
            commentMsg.setAuthorPersonView(shared_grpc_1.asObjectToMessage(comment.authorPersonView, new person_view_pb_1.PersonViewMinimal()));
            if (likesList) {
                commentMsg.setHasLiked(likesList.get(comment.commentContextHash) || false);
            }
            const commentList = [];
            for (const child of comment.commentList) {
                const childMsg = buildCommentMsg(child);
                commentList.push(childMsg);
            }
            commentMsg.setCommentList(commentList);
            return commentMsg;
        };
        const comments = commentList.map(comment => {
            return buildCommentMsg(comment);
        });
        return comments;
    }
    CommentListPresentationMapper.fromPresentationToCommentContentMessage = fromPresentationToCommentContentMessage;
})(CommentListPresentationMapper = exports.CommentListPresentationMapper || (exports.CommentListPresentationMapper = {}));