import { Validators } from '@angular/forms';

import { IMingaDistrict } from 'minga/libraries/domain';

import * as formUtils from '@shared/components/crud-form-base/crud-form-base.utils';
import { FormConfig } from '@shared/components/crud-form-base/crud-form-base.types';

export enum DistrictTableColumn {
  NAME = 'name',
  HUBSPOT_ID = 'hubspotId',
  EDIT = 'edit',
}

export const DISTRICT_TABLE_COLUMNS: string[] = [
  DistrictTableColumn.NAME,
  DistrictTableColumn.HUBSPOT_ID,
  DistrictTableColumn.EDIT,
];

export enum DISTRICT_MESSAGES {
  ADD_DISTRICT_BTN = 'Add district',
  EXPORT_BTN = 'Export list',
  NO_RESULTS_TITLE = 'No districts found',
  COL_NAME = 'Name',
  COL_HUBSPOT = 'Hubspot ID',
  COL_EDIT = 'Edit',

  MODAL_TITLE_ADD = 'Add district',
  MODAL_TITLE_EDIT = 'Edit district',
  FIELD_LABEL_NAME = 'District name',
  FIELD_LABEL_HUBSPOT = 'Hubspot ID',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_CREATING = 'Creating',
  BUTTON_LABEL_UPDATE = 'Save',
  BUTTON_LABEL_UPDATING = 'Saving',

  ERROR_INVALID_EMAILS = 'You must provide only valid email addresses',
  ERROR_INVALID_FILTERS = 'You must set at least 1 filter',
  ERROR_GENERIC = 'Please complete the missing information',

  UPDATE_ERROR = 'There was an error updating the district',
  CREATE_ERROR = 'There was an error creating the district',
  DELETE_ERROR = 'There was an error deleting the district',
  GET_ERROR = 'There was an problem fetching the district',

  DELETE_CONFIRM_TITLE = "Are you sure you want to delete this district, this can't be undone!",
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this district?',
  DELETE_CONFIRM_DESC = "Make sure this district isn't associated with any active mingas",
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',

  SNACKBAR_CREATE_SUCCESS = 'District created',
  SNACKBAR_UPDATE_SUCCESS = 'District updated',
  SNACKBAR_DELETE_SUCCESS = 'District deleted',
}

export interface MmDistrictEditData {
  id?: number;
}

export type MmDistrictEditResponse = {
  created?: IMingaDistrict;
  updated?: IMingaDistrict;
  deleted?: number;
};

export enum DISTRICT_FORM_FIELDS {
  NAME = 'name',
  HUBSPOT_ID = 'hubspotId',
}

const FORM: FormConfig<DISTRICT_FORM_FIELDS, IMingaDistrict> = {
  [DISTRICT_FORM_FIELDS.NAME]: [
    null,
    [Validators.required, Validators.minLength(3)],
  ],
  [DISTRICT_FORM_FIELDS.HUBSPOT_ID]: [null],
};

export const createForm = formUtils.createForm(FORM);
export const setForm = formUtils.setForm(FORM);
export const getData = formUtils.getData(FORM);
