<nav
  aria-label="Breadcrumb Navigation"
  *ngIf="routes?.length > 0">
  <ul>
    <li
      *ngFor="
        let route of routes;
        trackBy: trackBy;
        let lastItem = last;
        let firstItem = first
      ">
      <ng-container *ngIf="lastItem; else link">
        <mg-text variant="body-sm-secondary">
          {{ route.title }}
        </mg-text>
      </ng-container>

      <ng-template #link>
        <a
          [attr.aria-label]="route.title"
          [routerLink]="route.path"
          routerLinkActive="active">
          <ng-container *ngIf="firstItem">
            <mg-icon class="back-btn tw-mr-1">mg-arrow-left-alt</mg-icon>
          </ng-container>
          <mg-text variant="body-sm">
            {{ route.title }}
          </mg-text>
        </a>
        <mg-text variant="body-sm">/</mg-text>
      </ng-template>
    </li>
  </ul>
</nav>
