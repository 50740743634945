import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { INotificationType } from 'minga/libraries/domain';
import {
  isBadgeNotification,
  isChallengeResponseNotification,
  isPointsNotification,
} from 'minga/libraries/shared';
import { Notification } from 'minga/proto/gateway/notification_pb';

@Component({
  selector: 'mg-notification',
  templateUrl: './MgNotification.element.html',
  styleUrls: ['./MgNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgNotificationElement {
  @Input()
  notification?: Notification.AsObject;

  isBasicNotification() {
    return (
      this.notification &&
      this.notification.displayBasic &&
      !this.isPointsNotification() &&
      !this.isEventInviteNotification() &&
      !this.isBadgeNotification() &&
      !this.isChallengeInviteNotification() &&
      !this.isChallengeResponseNotification()
    );
  }

  isEventNotification() {
    return this.notification && this.notification.displayEvent;
  }

  isPersonNotification() {
    return this.notification && this.notification.displayPerson;
  }

  isPersonTitleNotification() {
    return this.notification && this.notification.displayPersonTitle;
  }

  isPointsNotification() {
    return (
      this.notification &&
      isPointsNotification(this.notification.notificationType) &&
      !this.isEventInviteNotification() &&
      !this.isChallengeInviteNotification()
    );
  }

  isEventInviteNotification() {
    return (
      this.notification &&
      this.notification.notificationType === INotificationType.EVENT_INVITE
    );
  }

  isBadgeNotification() {
    return (
      this.notification &&
      isBadgeNotification(this.notification.notificationType)
    );
  }

  isChallengeInviteNotification() {
    return (
      this.notification &&
      this.notification.notificationType === INotificationType.CHALLENGE_INVITE
    );
  }

  isChallengeResponseNotification() {
    return (
      this.notification &&
      isChallengeResponseNotification(this.notification.notificationType)
    );
  }
}
