import { Injectable, OnDestroy } from '@angular/core';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';

import { FlexTimePeriod, FlexTimePeriodPayload } from 'minga/libraries/domain';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  FlexTimeActivityInstanceService,
  FlexTimePeriodService,
  flexCloneData,
} from '@shared/services/flex-time';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

import { FtmPeriodsActivityEditComponent } from '../components/ftm-periods-activity-edit/ftm-periods-activity-edit.component';
import { FtmPeriodsEditComponent } from '../components/ftm-periods-edit/ftm-periods-edit.component';
import {
  FtmPeriodsActivityEditModalData,
  FtmPeriodsEditModalData,
} from '../types';

@Injectable()
export class FtmPeriodsService implements OnDestroy {
  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);

  /** Loading */
  private readonly _isFetchingPeriods = new BehaviorSubject(false);
  public readonly isLoadingPeriods$ = this._isFetchingPeriods
    .asObservable()
    .pipe(shareReplay());

  /** Data */
  private _periods = new BehaviorSubject<FlexTimePeriod[]>([]);
  public readonly periods$ = this._periods.asObservable().pipe(share());

  /** Service Constructor */
  constructor(
    private _modalOverlay: ModalOverlayService,
    private _ftPeriods: FlexTimePeriodService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _flexPermissionsService: FlexTimePermissionsService,
    private _ftActivityInstances: FlexTimeActivityInstanceService,
  ) {}

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._periods.complete();
  }

  public openModal(id?: number, isClone?: boolean) {
    const modalRef = this._modalOverlay.open<FtmPeriodsEditModalData>(
      FtmPeriodsEditComponent,
      {
        data: { id, isClone },
        disposeOnNavigation: false,
      },
    );
    modalRef.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._systemAlertSnackBar.open({
            type: 'success',
            message: 'Successfully created new FlexTime period',
          });
          return;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          this._systemAlertSnackBar.open({
            type: 'success',
            message: 'Successfully updated FlexTime period',
          });
          return;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this._systemAlertSnackBar.open({
            type: 'success',
            message: 'Successfully deleted FlexTime period',
          });
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  public openActivityEditModal(
    id: number = null,
    startDate: day.Dayjs,
    endDate: day.Dayjs,
    currentInstanceId?: number,
    myActivities?: boolean,
  ): void {
    const modalRef = this._modalOverlay.open<FtmPeriodsActivityEditModalData>(
      FtmPeriodsActivityEditComponent,
      {
        data: { id, currentInstanceId, myActivities },
        disposeOnNavigation: false,
      },
    );
    modalRef.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._systemAlertSnackBar.open({
            type: 'success',
            message: 'Successfully added a new activity',
          });
          this.fetchAll(startDate, endDate);
          break;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          this._systemAlertSnackBar.open({
            type: 'success',
            message: 'Successfully updated activity',
          });
          break;
        }

        // this is removing an instance from a period
        case ModalOverlayServiceCloseEventType.DELETE: {
          await this._ftActivityInstances.delete(currentInstanceId);
          this._systemAlertSnackBar.open({
            type: 'success',
            message: 'Successfully removed activity',
          });
          this.fetchAll(startDate, endDate);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  public async fetchAll(
    startDate?: day.Dayjs,
    endDate?: day.Dayjs,
  ): Promise<void> {
    this._isFetchingPeriods.next(true);
    await this._ftPeriods
      .fetchAll(startDate, endDate)
      .then(result => {
        this._periods.next(result);
      })
      .finally(() => {
        this._isFetchingPeriods.next(false);
      });
  }

  public async create(
    period: FlexTimePeriodPayload,
    cloneData?: flexCloneData,
  ): Promise<void> {
    await this._ftPeriods.create(period, cloneData).then(result => {
      this._periods.next([result, ...this._periods.getValue()]);
    });
  }

  public async update(period: FlexTimePeriodPayload): Promise<void> {
    await this._ftPeriods.update(period).then(result => {
      const state = [...this._periods.getValue()];
      const index = state.findIndex(p => p.id === result.id);
      result.activityInstances = state[index].activityInstances;
      state[index] = result;
      this._periods.next(state);
    });
  }

  public async delete(id: number): Promise<void> {
    await this._ftPeriods.delete(id).then(() => {
      const state = this._periods.getValue();
      this._periods.next(state.filter(p => p.id !== id));
    });
  }
}
