import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartDataset,
  LinearScale,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { IHallPassType } from 'minga/libraries/domain';
import { HpmReportsFilters } from 'minga/libraries/domain';
import { ReportTypes } from 'minga/libraries/domain';
import {
  formatMinutesIntoHours,
  getGroupRangeByDate,
} from 'minga/libraries/domain';
import { HallPassService } from 'src/app/services/HallPass';

import { HpmRoutes } from '@modules/hallpass-manager/hpm.constants';

import { HpmReportsSortBy } from '../../hpm-reports.constants';
import { HpmChartData, HpmSummaryData } from '../../hpm-reports.types';
import { generateGraphData, generateLabels } from '../../hpm-reports.utils';
import {
  HpmReportsCreatorsService,
  HpmReportsService,
  HpmReportsTypesService,
  HpmReportsUsersService,
} from '../../services';
import {
  BAR_CHART_PASS_Y,
  BAR_CHART_TIME_Y,
  HPM_CHART,
  HpmReportsSummaryMessages,
} from './hpm-reports-summary.constants';

Chart.register(CategoryScale, LinearScale, BarController, BarElement);
/**
 * Hall Pass Manager Reports Summary Component
 */
@Component({
  selector: 'mg-hpm-reports-summary',
  templateUrl: './hpm-reports-summary.component.html',
  styleUrls: ['./hpm-reports-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    HpmReportsCreatorsService,
    HpmReportsUsersService,
    HpmReportsTypesService,
  ],
})
export class HpmReportsSummaryComponent implements AfterViewChecked, OnDestroy {
  /** Element Refs */
  @ViewChild(BaseChartDirective)
  chartCanvas: BaseChartDirective | undefined;
  @ViewChild('chartContainerElement', { static: false })
  chartContainerElement: ElementRef;
  @ViewChild('chartElement', { static: false }) chartElement: ElementRef;

  public SORT_BY: typeof HpmReportsSortBy = HpmReportsSortBy;

  formatChartTime = formatMinutesIntoHours;

  /** Subscription Cleanup */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Messages */
  public readonly MESSAGES: typeof HpmReportsSummaryMessages =
    HpmReportsSummaryMessages;

  /** Chart */
  public readonly HPM_CHART: typeof HPM_CHART = HPM_CHART;
  public chartLabels: any[] = [];
  public chartDataSets: ChartDataset[] = [{ data: [], label: '', stack: '1' }];
  public chartSort: BehaviorSubject<HpmReportsSortBy> = new BehaviorSubject(
    HpmReportsSortBy.TIME,
  );

  /** Report Types */
  public readonly REPORT_TYPE: typeof ReportTypes = ReportTypes;

  /** Hall Pass Types */
  public readonly hallPassTypes$: Observable<IHallPassType[]>;

  /** Data */
  readonly summaryData$: Observable<HpmSummaryData>;
  public isLoading = true;

  private _isLoading$ = new BehaviorSubject(true);
  public isLoading$ = this._isLoading$.asObservable();

  /** Computing Functions */
  formatTotalTime = formatMinutesIntoHours;

  /** Component Constructor */
  constructor(
    public studentDataSource: HpmReportsUsersService,
    public creatorDataSource: HpmReportsCreatorsService,
    public typesDataSource: HpmReportsTypesService,
    private _hpm: HallPassService,
    private _hpmReports: HpmReportsService,
    private _router: Router,
  ) {
    this.summaryData$ = this._hpmReports.filter$.pipe(
      takeUntil(this._destroyed$),
      switchMap(() => {
        return this._hpmReports.getReportSummary();
      }),
    );

    this.hallPassTypes$ = this._hpm.getHallPassTypes();

    this.summaryData$
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => {
          this._isLoading$.next(false);
        }),
      )
      .subscribe();

    // Make Chart Data when filters, summary, and types are available
    combineLatest([
      this._hpmReports.filter$,
      this.summaryData$,
      this.hallPassTypes$,
      this.chartSort,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(d => this._makeChartData(d));

    this.studentDataSource.setLimitAndOffset(5, 0);
    this.creatorDataSource.setLimitAndOffset(5, 0);
    this.typesDataSource.setLimitAndOffset(5, 0);
  }

  // afterViewInit didn't work so user afterViewChecked instead
  ngAfterViewChecked(): void {
    const width = this.chartContainerElement?.nativeElement.offsetWidth;

    if (width && this.chartElement.nativeElement)
      this.chartElement.nativeElement.style.width = `${width}px`;
  }

  /** Service Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Make Chart Data
   */
  private async _makeChartData([filters, summary, types, sort]: [
    HpmReportsFilters,
    HpmSummaryData,
    IHallPassType[],
    HpmReportsSortBy,
  ]): Promise<void> {
    if (filters && summary && types) {
      const funcParams: HpmChartData = {
        endDate: filters.endDate,
        groupBy: getGroupRangeByDate(filters.startDate, filters.endDate),
        startDate: filters.startDate,
        summary: summary.summary,
        types,
        sort,
      };
      this.chartLabels = generateLabels(funcParams);
      this.chartDataSets = generateGraphData(funcParams, this.chartLabels);
      if (funcParams.sort === HpmReportsSortBy.PASSES) {
        this.HPM_CHART.options.scales.y = BAR_CHART_PASS_Y;
      } else {
        this.HPM_CHART.options.scales.y = BAR_CHART_TIME_Y;
      }
      this.chartCanvas?.ngOnInit();
    }
  }

  public isActive(value: HpmReportsSortBy) {
    return this.chartSort.value === value;
  }

  public async onChangeReportType(value: string): Promise<void> {
    await this._router.navigateByUrl(
      `/${HpmRoutes.ROOT}/${HpmRoutes.REPORTS}/${value}`,
    );
    return;
  }
}
