import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'minga/libraries/domain';

import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

@Pipe({ name: 'canEditActivityInstance' })
export class CanEditActivityInstancePipe implements PipeTransform {
  constructor(private _flexPermissions: FlexTimePermissionsService) {}

  transform(instance: FlexTimeActivityInstance): boolean {
    if (!instance || !instance.flexTimeActivity) return false;

    return this._flexPermissions.canEditActivityInstance(instance);
  }
}
