import { ColumnInfo } from 'minga/libraries/shared/reports_columns';

export enum SIS_STATS_COLUMNS {
  DATE = 'date',
  TOTAL_INCOMING = 'totalIncoming',
  TOTAL_ARCHIVED = 'totalArchived',
  TOTAL_STUDENTS = 'totalStudents',
  TOTAL_TEACHERS = 'totalTeachers',
  TOTAL_STAFF = 'totalStaff',
  TOTAL_TERMS = 'totalTerms',
  TOTAL_SECTIONS = 'totalSections',
  DETAILS = 'details',
  FILES = 'files',
}

export enum SIS_STATS_DETAILS_COLUMNS {
  TITLE = 'title',
  CREATED = 'created',
  UPDATED = 'updated',
  IGNORED = 'ignored',
  RESTORED = 'restored',
  ARCHIVED = 'archived',
  ERRORS = 'errors',
  TOTAL = 'total',
  FILES = 'files',
}

export const SIS_STATS_COLUMN_INFO: ColumnInfo[] = [
  {
    header: 'Date',
    key: SIS_STATS_COLUMNS.DATE,
    type: 'date',
    width: 'medium',
  },
  {
    header: 'Incoming',
    key: SIS_STATS_COLUMNS.TOTAL_INCOMING,
    type: 'number',
  },
  {
    header: 'Archived',
    key: SIS_STATS_COLUMNS.TOTAL_ARCHIVED,
    type: 'number',
  },
  { header: 'Students', key: SIS_STATS_COLUMNS.TOTAL_STUDENTS, type: 'number' },
  { header: 'Teachers', key: SIS_STATS_COLUMNS.TOTAL_TEACHERS, type: 'number' },
  { header: 'Staff', key: SIS_STATS_COLUMNS.TOTAL_STAFF, type: 'number' },
  { header: 'Terms', key: SIS_STATS_COLUMNS.TOTAL_TERMS, type: 'number' },
  { header: 'Sections', key: SIS_STATS_COLUMNS.TOTAL_SECTIONS, type: 'number' },
];

export const SIS_STATS_DETAILS_COLUMN_INFO = {
  header: 'Details',
  key: SIS_STATS_COLUMNS.DETAILS,
  columnClasses: ['w-16'],
};
export const SIS_STATS_FILES_COLUMN_INFO = {
  header: 'Files',
  key: SIS_STATS_COLUMNS.FILES,
  columnClasses: ['w-16'],
};

export const SIS_STATS_ACTION_COLUMN_INFO: ColumnInfo[] = [
  SIS_STATS_DETAILS_COLUMN_INFO,
];

export const SIS_STATS_MODAL_TABLE_COLUMN_INFO: ColumnInfo[] = [
  {
    header: 'Type',
    key: SIS_STATS_DETAILS_COLUMNS.TITLE,
    type: 'text',
    width: 'large',
  },
  {
    header: 'Total',
    key: SIS_STATS_DETAILS_COLUMNS.TOTAL,
    type: 'number',
    width: 'small',
  },
  { header: 'Created', key: SIS_STATS_DETAILS_COLUMNS.CREATED, type: 'number' },
  { header: 'Updated', key: SIS_STATS_DETAILS_COLUMNS.UPDATED, type: 'number' },
  { header: 'Ignored', key: SIS_STATS_DETAILS_COLUMNS.IGNORED, type: 'number' },
  {
    header: 'Restored',
    key: SIS_STATS_DETAILS_COLUMNS.RESTORED,
    type: 'number',
  },
  {
    header: 'Archived',
    key: SIS_STATS_DETAILS_COLUMNS.ARCHIVED,
    type: 'number',
  },
  { header: 'Errors', key: SIS_STATS_DETAILS_COLUMNS.ERRORS, type: 'number' },
];
