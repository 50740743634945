"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MingaSmsDetailsMapper = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const sms_pb_1 = require("minga/proto/sms/sms_pb");
var MingaSmsDetailsMapper;
(function (MingaSmsDetailsMapper) {
    function fromISmsRecipientDetails(details) {
        const msg = new sms_pb_1.MingaSmsDetails();
        shared_grpc_1.asObjectToMessage(details, msg, true);
        return msg;
    }
    MingaSmsDetailsMapper.fromISmsRecipientDetails = fromISmsRecipientDetails;
    function toISmsRecipientDetails(msg) {
        const details = Object.assign({}, msg.toObject());
        return details;
    }
    MingaSmsDetailsMapper.toISmsRecipientDetails = toISmsRecipientDetails;
})(MingaSmsDetailsMapper = exports.MingaSmsDetailsMapper || (exports.MingaSmsDetailsMapper = {}));