"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListTypePermissions = void 0;
const domain_1 = require("minga/libraries/domain/index");
const domain_2 = require("minga/libraries/domain/index");
exports.ListTypePermissions = {
    [domain_1.MembershipListType.STICKER]: [
        domain_2.MingaPermission.ADMINISTER_STUDENT_ID,
        domain_2.MingaPermission.CHECKIN_MANAGE,
    ],
    [domain_1.MembershipListType.NO_PARTY]: [domain_2.MingaPermission.HALL_PASS_TYPE_MANAGE],
    [domain_1.MembershipListType.NO_PASS]: [domain_2.MingaPermission.HALL_PASS_TYPE_MANAGE],
    [domain_1.MembershipListType.NO_GRANT]: [domain_2.MingaPermission.HALL_PASS_TYPE_MANAGE],
    [domain_1.MembershipListType.NO_TRACK]: [domain_2.MingaPermission.PBIS_TYPE_MANAGE],
    [domain_1.MembershipListType.TRACK]: [domain_2.MingaPermission.PBIS_TYPE_MANAGE],
    [domain_1.MembershipListType.SCHOOL_TEAM]: [
        domain_2.MingaPermission.POINTS_MANAGE,
        domain_2.MingaPermission.POINTS_VIEW_REPORTS,
    ],
    [domain_1.MembershipListType.NO_ACCESS]: [domain_2.MingaPermission.ADMINISTER_STUDENT_ID],
    [domain_1.MembershipListType.ONE_PASS]: [domain_2.MingaPermission.HALL_PASS_TYPE_MANAGE],
    [domain_1.MembershipListType.TWO_PASS]: [domain_2.MingaPermission.HALL_PASS_TYPE_MANAGE],
    [domain_1.MembershipListType.CONTENT_MANAGER]: [domain_2.MingaPermission.CONTENT_EVENT_CREATE],
    [domain_1.MembershipListType.ID_MANAGER]: [domain_2.MingaPermission.ADMINISTER_STUDENT_ID],
    [domain_1.MembershipListType.PASS_TYPE]: [domain_2.MingaPermission.HALL_PASS_TYPE_MANAGE],
    [domain_1.MembershipListType.POINTS_MANAGER]: [domain_2.MingaPermission.MINGA_PEOPLE_MANAGE],
    [domain_1.MembershipListType.BEHAVIOR_MANAGER]: [domain_2.MingaPermission.MINGA_PEOPLE_MANAGE],
    [domain_1.MembershipListType.CHECKIN_MANAGER]: [domain_2.MingaPermission.MINGA_PEOPLE_MANAGE],
    [domain_1.MembershipListType.BEHAVIOR_TYPE]: [domain_2.MingaPermission.PBIS_TYPE_MANAGE],
    [domain_1.MembershipListType.HP_REPORT_VIEWER]: [domain_2.MingaPermission.MINGA_PEOPLE_MANAGE],
    [domain_1.MembershipListType.BEHAVIOR_REPORT_VIEWER]: [
        domain_2.MingaPermission.MINGA_PEOPLE_MANAGE,
    ],
    [domain_1.MembershipListType.CHECKIN_REPORT_VIEWER]: [
        domain_2.MingaPermission.MINGA_PEOPLE_MANAGE,
    ],
    [domain_1.MembershipListType.POINTS_REPORT_VIEWER]: [
        domain_2.MingaPermission.MINGA_PEOPLE_MANAGE,
    ],
    [domain_1.MembershipListType.HALL_PASS_MANAGER]: [domain_2.MingaPermission.MINGA_PEOPLE_MANAGE],
    [domain_1.MembershipListType.HALL_PASS_TYPE]: [domain_2.MingaPermission.HALL_PASS_TYPE_MANAGE],
    [domain_1.MembershipListType.CHECKIN_RESTRICTION_LIST]: [
        domain_2.MingaPermission.MINGA_PEOPLE_MANAGE,
    ],
    [domain_1.MembershipListType.FLEX_RESTRICTION_LIST]: [
        domain_2.MingaPermission.MINGA_PEOPLE_MANAGE,
        domain_2.MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
    ],
    [domain_1.MembershipListType.FLEX_MANAGER]: [domain_2.MingaPermission.MINGA_PEOPLE_MANAGE],
    [domain_1.MembershipListType.FLEX_REPORT_VIEWER]: [
        domain_2.MingaPermission.MINGA_PEOPLE_MANAGE,
    ],
    [domain_1.MembershipListType.USER_LIST]: [domain_2.MingaPermission.USER_LIST_VIEW],
    [domain_1.MembershipListType.USER_LIST_MANAGER]: [domain_2.MingaPermission.USER_LIST_VIEW],
};