"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MingaConferenceMingaType = void 0;
const domain_1 = require("minga/libraries/domain/index");
const shared_1 = require("minga/libraries/shared/index");
const util_1 = require("minga/libraries/util/index");
const MingaType_1 = require("minga/libraries/shared/minga_type/internal/MingaType");
class MingaConferenceMingaType extends MingaType_1.MingaType {
    constructor() {
        super(...arguments);
        this.mingaType = util_1.MingaTypeValue.CONFERENCE;
        this.availableRoles = {
            [domain_1.MingaRoleType.SUPERADMIN]: { weight: 99 },
            [domain_1.MingaRoleType.OWNER]: { weight: 4 },
            [domain_1.MingaRoleType.MANAGER]: { weight: 3 },
            [domain_1.MingaRoleType.ATTENDEE]: { weight: 2 },
            [domain_1.MingaRoleType.READ_ONLY]: { weight: 1 },
        };
        this.defaultSettings = [
            {
                name: util_1.mingaSettingTypes.FEATURE_ENABLE_GROUPS,
                value: true,
            },
        ];
        this.canSelfManageAccountPlan = false;
        this.defaultVideoUploadRoleTypes = [
            domain_1.MingaRoleType.OWNER,
            domain_1.MingaRoleType.MANAGER,
        ];
        this.directMessagingRoleSettings = [];
    }
    getBillableRoles(isPricedPerDefaultRole = true) {
        return [
            domain_1.MingaRoleType.OWNER,
            domain_1.MingaRoleType.MANAGER,
            domain_1.MingaRoleType.ATTENDEE,
        ].map(shared_1.getRole);
    }
    getDefaultRole() {
        return shared_1.getRole(domain_1.MingaRoleType.ATTENDEE);
    }
    getCreatorRole() {
        return shared_1.getRole(domain_1.MingaRoleType.OWNER);
    }
}
exports.MingaConferenceMingaType = MingaConferenceMingaType;