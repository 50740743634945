var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HallPassTypeMapper = exports.HallPassTypeCountMapper = exports.HallPassMapper = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const HallPassMapperts = require("minga/libraries/shared-grpc/hall_pass/HallPassMapper");
exports.HallPassMapper = HallPassMapperts;
const HallPassTypeCountMapperts = require("minga/libraries/shared-grpc/hall_pass/HallPassTypeCountMapper");
exports.HallPassTypeCountMapper = HallPassTypeCountMapperts;
const HallPassTypeMapperts = require("minga/libraries/shared-grpc/hall_pass/HallPassTypeMapper");
exports.HallPassTypeMapper = HallPassTypeMapperts;
__exportStar(require("minga/libraries/shared-grpc/hall_pass/HallPassStatsMapper"), exports);
__exportStar(require("minga/libraries/shared-grpc/hall_pass/HallPassBlackOutMapper"), exports);