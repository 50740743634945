"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolManagerRole = void 0;
const domain_1 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const UserRole_1 = require("minga/libraries/shared/permissions/internal/UserRole");
/**
 * @deprecated no longer used
 */
class SchoolManagerRole extends UserRole_1.UserRole {
    constructor() {
        super(...arguments);
        this.roleType = domain_1.MingaRoleType.SCHOOL_MANAGER;
        this.name = 'School Manager';
        this.displayNameFormat = util_1.DisplayNameFormat.F_LAST;
        this.iconUrl = 'assets/roles/school-manager.svg';
        this.iconColor = '#FFBD2F';
        this.capabilitiesDescription = `School Managers will be allowed to share posts, pictures, videos, events, and polls`;
        this.feedPermittable = true;
        this.galleryPermittable = true;
        this.groupFeedPermittable = true;
        this.programManagePermittable = false;
        this.videoUploadPermittable = true;
        this.groupCreatePermittable = true;
        this.admin = true;
        this.commentPermittable = true;
        this.requiredFeatures = [util_1.mingaSettingTypes.FEATURE_DISTRICT_ENABLED];
        this.sendToMailChimp = true;
        this.permissions = [
            domain_1.MingaPermission.CONTENT_DELETE_OTHERS,
            domain_1.MingaPermission.ACCOUNT_DISPLAYNAME_CHANGE,
            domain_1.MingaPermission.ACCOUNT_PROFILE_CHANGE,
            domain_1.MingaPermission.ASSET_UPLOAD,
            domain_1.MingaPermission.CONTENT_COMMENT_CREATE,
            domain_1.MingaPermission.CONTENT_COMMENTS_VIEW,
            domain_1.MingaPermission.CONTENT_EVENT_CREATE,
            domain_1.MingaPermission.CONTENT_GROUP_CREATE,
            domain_1.MingaPermission.CONTENT_GROUP_VIEWALL,
            domain_1.MingaPermission.CONTENT_GROUP_ENABLED,
            domain_1.MingaPermission.CONTENT_HTML_INCLUDE,
            domain_1.MingaPermission.CONTENT_LIKES_LIKE,
            domain_1.MingaPermission.CONTENT_MODERATION_OVERRIDE,
            domain_1.MingaPermission.CONTENT_POST_CREATE,
            domain_1.MingaPermission.CONTENT_POST_SET_TITLE,
            domain_1.MingaPermission.CONTENT_PUBLISH_DATES_SET,
            domain_1.MingaPermission.CONTENT_REPORT_CREATE,
            domain_1.MingaPermission.CONTENT_REPORTS_MANAGE,
            domain_1.MingaPermission.CONTENT_VIDEO_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_EVENTS_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_POLL_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_POST_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
            domain_1.MingaPermission.MINGA_CONTENT_PIN,
            domain_1.MingaPermission.CONTENT_POLL_ANSWER,
            domain_1.MingaPermission.CONTENT_ANY_GET_DELETED,
            domain_1.MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
            domain_1.MingaPermission.GROUP_ALLOW_PARENT_GROUP,
            domain_1.MingaPermission.EMAIL_CONTENT_AS_GROUP_OWNER,
            domain_1.MingaPermission.GALLERY_DELETE_OTHERS_PHOTO,
            domain_1.MingaPermission.CONTENT_MANAGE_COMMENT_SETTINGS,
            domain_1.MingaPermission.MINGA_PEOPLE_MANAGE,
            domain_1.MingaPermission.MINGA_POLLS_MANAGE,
            domain_1.MingaPermission.CONTENT_CHALLENGE_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_CHALLENGE_CREATE,
            domain_1.MingaPermission.CHALLENGE_COMPLETE,
            domain_1.MingaPermission.CHECKIN_TYPE_MANAGE,
            domain_1.MingaPermission.CHECKIN_MANAGE,
            domain_1.MingaPermission.CHECKIN_VIEW_REPORTS,
            domain_1.MingaPermission.POINTS_VIEW_REPORTS,
            domain_1.MingaPermission.FLEX_TIME_MANAGE,
            domain_1.MingaPermission.SCHEDULED_REPORTS_MANAGE,
            domain_1.MingaPermission.SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT,
            domain_1.MingaPermission.STUDENT_SCHEDULE_VIEW,
            domain_1.MingaPermission.BELL_SCHEDULE_MANAGE,
            domain_1.MingaPermission.VIEW_PROFILE,
        ];
        this.overridablePermissions = [
            domain_1.MingaPermission.CONTENT_SMS_MESSAGE_CREATE,
        ];
    }
}
exports.SchoolManagerRole = SchoolManagerRole;