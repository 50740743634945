import { Injectable } from '@angular/core';

import { FiltersFormData } from '../types';

@Injectable({
  providedIn: 'root',
})
export class FiltersFormService {
  /** Service constructor */
  constructor() {}

  public create(data: FiltersFormData): FiltersFormData {
    return data;
  }
}
