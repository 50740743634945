"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckinsMapper = exports.CheckinReasonMapper = exports.CheckinPersonMapper = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const CheckinPersonmapperts = require("minga/libraries/shared-grpc/checkin/CheckinPerson.mapper");
exports.CheckinPersonMapper = CheckinPersonmapperts;
const CheckinReasonmapperts = require("minga/libraries/shared-grpc/checkin/CheckinReason.mapper");
exports.CheckinReasonMapper = CheckinReasonmapperts;
const Checkinsmapperts = require("minga/libraries/shared-grpc/checkin/Checkins.mapper");
exports.CheckinsMapper = Checkinsmapperts;