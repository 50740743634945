import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ColumnInfo } from 'minga/libraries/shared/reports_columns';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import { ReportTableModalTableShellComponent } from '../modal-shell-table/rt-modal-table-shell.component';
import { ReportTableActionTemplateComponent } from './rt-action-column.component';
import { ReportTableColumnTemplateComponent } from './rt-column-template.component';

@Component({
  selector: 'mg-modal-table-column',
  templateUrl: './rt-action-column.component.html',
  styles: ["[class*='w-'] {flex: initial; min-width: 0 !important }"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReportTableColumnTemplateComponent,
      useExisting: ReportTableModalTableTemplateComponent,
    },
  ],
})
export class ReportTableModalTableTemplateComponent
  extends ReportTableActionTemplateComponent
  implements OnDestroy
{
  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

  @Input() modalTitle: string;
  @Input() modalDataAccessor: string;
  @Input() modalColumnInfo: ColumnInfo[];
  @Input() modalActionInfo: ColumnInfo;
  @Input() modalActionIcon: string;

  /**
   * With modalSubtitle you can provide a string with variables in the form of {{variableName}}. If you do,
   * you must also provide a modalSubtitleVariables object with functions that take the row and return a string;
   */
  @Input() modalSubtitle: string;
  @Input() modalSubtitleVariables: { [key: string]: (row: any) => string };

  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(private _modalService: ModalOverlayService) {
    super();
  }

  triggerAction(row: any): void {
    const data = row[this.modalDataAccessor];
    if (!data) {
      throw new Error(
        'No data to display in modal, issue with data configuration',
      );
    }
    let subtitle = this.modalSubtitle;
    if (this.modalSubtitleVariables) subtitle = this.getFormattedSubtitle(row);
    const modal = this._modalService.open(ReportTableModalTableShellComponent, {
      data: {
        title: this.modalTitle,
        subtitle,
        data,
        columnInfo: this.modalColumnInfo,
        actionInfo: this.modalActionInfo,
        actionIcon: this.modalActionIcon,
      },
    });
    modal.emitAction.pipe(takeUntil(this._destroyed$)).subscribe(x => {
      this.actionTriggered.emit(x);
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getFormattedSubtitle(row: any): string {
    return this.modalSubtitle.replace(/\{\{(.*?)\}\}/g, (match, p1) => {
      return this.modalSubtitleVariables[p1.trim()](row) || '';
    });
  }
}
