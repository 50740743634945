import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MingaPermission } from 'minga/libraries/domain';
import { IPointsRedeemedResult } from 'minga/libraries/domain';
import {
  ColumnInfo,
  PointsReportColumns,
  TemplateColumnKeys,
} from 'minga/libraries/shared';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PmReportsService } from './pm-reports.service';

@Injectable()
export class PmReportsRedemptionHistoryDatasourceService extends ReportDatasourceService<IPointsRedeemedResult> {
  constructor(
    _router: Router,
    private _pmService: PmReportsService,
    private _permissions: PermissionsService,
    _alertModal: SystemAlertModalService,
    _snackBar: SystemAlertSnackBarService,
  ) {
    super(
      _router,
      _pmService,
      PmReportsRedemptionHistoryDatasourceService.name,
      _alertModal,
      _snackBar,
    );
    this._archiveFn = this._pmService.archivePointRedemption.bind(
      this._pmService,
    );
    let displayCol: ColumnInfo[] = [];
    if (this._permissions.hasPermission(MingaPermission.POINTS_MANAGE))
      displayCol = [
        {
          header: '',
          key: TemplateColumnKeys.ARCHIVE,
          columnClasses: ['w-16'],
        },
      ];
    super.setDisplayColumns(PointsReportColumns.REDEMPTION_HISTORY, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._pmService.fetchPointsRedeemed(
      offset,
      limit,
      this._currentSort,
    );
  }
}
