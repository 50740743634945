import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

import { ColumnInfo, TemplateColumnKeys } from 'minga/libraries/shared';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';

import { MingaColorsType } from '@shared/constants/mingaColors';

import { ReportTableColumnTemplateComponent } from './rt-column-template.component';

/**
 * Template for generic 'complete' action column.
 * Use to do some action on a table data row.
 */
@Component({
  selector: 'mg-complete-column',
  styles: ["[class*='w-'] {flex: initial; min-width: 0 !important }"],
  template: `<ng-container
    matColumnDef="{{ datasource.TEMPLATE_KEYS.COMPLETE }}">
    <mat-header-cell
      *matHeaderCellDef
      [mat-sort-header]="datasource.TEMPLATE_KEYS.COMPLETE"
      [disabled]="true"
      [ngClass]="completeColumn.columnClasses">
      {{ header }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      [ngClass]="completeColumn.columnClasses">
      <mg-generic-button
        [colorTheme]="colorTheme"
        [variant]="item.complete ? 'primary' : 'secondary'"
        [iconLeft]="item.complete ? 'check-mark' : ''"
        [isRounded]="false"
        size="small"
        (onClick)="datasource.completeItem(item)">
        {{ item.complete ? completedWord : completeWord }}
      </mg-generic-button>
    </mat-cell>
  </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReportTableColumnTemplateComponent,
      useExisting: ReportTableCompleteTemplateComponent,
    },
  ],
})
export class ReportTableCompleteTemplateComponent implements AfterContentInit {
  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

  public completeColumn: ColumnInfo;

  @Input()
  datasource: ReportDatasourceService<any>;

  @Input()
  header = 'Complete';

  @Input()
  completedWord = 'Completed';

  @Input()
  completeWord = 'Complete';

  @Input()
  colorTheme: MingaColorsType = 'green';

  constructor() {}

  ngAfterContentInit(): void {
    this.completeColumn = this.datasource
      .getTemplateColumns()
      .find(column => column.key === TemplateColumnKeys.COMPLETE);
  }
}
