"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isChallengeResponseNotification = void 0;
const domain_1 = require("minga/libraries/domain/index");
function isChallengeResponseNotification(type) {
    if (!type) {
        return false;
    }
    switch (type) {
        case domain_1.INotificationType.CHALLENGE_RESPONSE_DENIED:
            return true;
        default:
            break;
    }
    return false;
}
exports.isChallengeResponseNotification = isChallengeResponseNotification;