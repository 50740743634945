import { IPbisType } from 'minga/libraries/domain';

export enum BmTypesBehaviorMessages {
  TABLE_TITLE = 'Behaviors',

  COLUMN_TITLE_NAME = 'Name',
  COLUMN_TITLE_STATUS = 'Status',
  COLUMN_TITLE_EDIT = 'Edit',

  EMPTY_STATE_TITLE = 'No behaviors exist',
  EMPTY_STATE_SUBTITLE = 'Create a new behavior to get started',

  EMPTY_PRAISE_TITLE = 'No praises exist',
  EMPTY_PRAISE_DESC = 'Create a new praise to get started',
  EMPTY_GUIDANCE_TITLE = 'No guidances exist',
  EMPTY_GUIDANCE_DESC = 'Create a new guidance to get started',

  PRAISE_TITLE = 'Praise',
  GUIDANCE_TITLE = 'Guidance',

  TOOLTIP = 'Allows you to assign either a praise or guidance to a user.',

  BUTTON_CREATE = 'Create behavior',
}

export enum BmTypesBehaviorEditMessages {
  TABLE_TITLE_CONSEQUENCES = 'Consequences',
  TABLE_TITLE_AUTOMATIONS = 'Automations',

  MODAL_TITLE_NEW = 'Create A Behavior',
  MODAL_TITLE_EXISTING = 'Edit Behavior',

  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_TOGGLE_PRAISE = 'Praise',
  BUTTON_TOGGLE_GUIDANCE = 'Guidance',

  FIELD_LABEL_NAME = 'Behavior name',
  FIELD_LABEL_POINTS = 'Points (optional)',
  FIELD_LABEL_DESCRIPTION = 'Description',
  FIELD_LABEL_PARENT_EMAIL_SUBJECT = 'Email subject',
  FIELD_LABEL_PARENT_EMAIL_BODY = 'Email body',
  FIELD_LABEL_ADMIN_EMAIL = 'Admin email',
  FIELD_LABEL_PARENT_SMS = 'SMS message',
  FIELD_LABEL_HALL_PASS = 'Auto assign hall pass',
  FIELD_LABEL_MEMBERSHIP = 'Limit who can assign this behavior',

  PARENT_EMAIL_MSG = 'Send parents notification email',
  PARENT_SMS_MSG = 'Send parents notification SMS',
  PARENT_SMS_MSG_TOOLTIP = 'SMS needs to be enabled in your Minga settings',
  ADMIN_EMAIL_MSG = 'Send admin notification email',

  SELECT_LABEL_AUTO_HP = 'Auto assign hall pass',

  QUILL_EDITOR_INSTRUCTIONS = "${firstName} = Student's first name\n${lastName} = Student's last name\n${typeName} = Name of behavior assigned to student\n${mingaName} = School name\n${note} = Custom note from teacher to student\n${assigner} = The person who assigned the behavior\n${points} = How many points were awarded",
}

export enum BmTypesBehaviorEditFormFields {
  NAME = 'NAME',
  POINTS = 'POINTS',
  PARENT_EMAIL = 'PARENT_EMAIL',
  PARENT_EMAIL_SUBJECT = 'PARENT_EMAIL_SUBJECT',
  PARENT_EMAIL_BODY = 'PARENT_EMAIL_BODY',
  PARENT_SMS = 'PARENT_SMS',
  PARENT_SMS_TEMPLATE = 'PARENT_SMS_TEMPLATE',
  SEND_ADMIN_EMAIL = 'SEND_ADMIN_EMAIL',
  ADMIN_EMAIL = 'ADMIN_EMAIL',
  CATEGORY = 'CATEGORY',
  HALL_PASS_ID = 'HALL_PASS_ID',
  MEMBERSHIP_LIST_ID = 'MEMBERSHIP_LIST_ID',
}

export const BM_TYPES_BEHAVIOR_TABLE_COLUMNS: string[] = [
  'name',
  'active',
  'edit',
];

export const NEW_BEHAVIOR: Partial<IPbisType> = {
  name: 'Behavior Name',
  points: 0,
  active: false,
  categoryId: 0,
};
