<!-- Wrapper -->
<div class="wrapper">
  <!-- Search input -->
  <div
    *ngIf="enableSearch"
    id="search-input">
    <mg-form-text-input
      placeholder="Search"
      iconRight="mg-search"
      [isClearable]="true"
      [control]="filter.control">
    </mg-form-text-input>
  </div>

  <!-- Virtual scroll area -->
  <div class="viewport-container">
    <!-- Virtual scroller -->
    <cdk-virtual-scroll-viewport
      *ngIf="filter.hasFilteredData$ | async"
      itemSize="50"
      class="option-viewport">
      <button
        *cdkVirtualFor="let option of filter.filteredData$ | async"
        matRipple
        class="option"
        [class.checkbox]="multiple"
        [class.radio]="!multiple"
        [matRippleColor]="'rgba(0, 0, 0, 0.02)'"
        [matRippleDisabled]="option?.disabled"
        [disabled]="option?.disabled"
        (click)="selectOption(option)">
        <!-- Multiple selection -->
        <mg-form-checkbox
          *ngIf="multiple; else radioTemplate"
          [checked]="selection?.isSelected(option)"
          [disabled]="option?.disabled">
          <mg-text
            variant="body-md"
            [numberOfLines]="2">
            {{ option.label }}
          </mg-text>
        </mg-form-checkbox>
        <!-- Single selection -->
        <ng-template #radioTemplate>
          <mat-radio-button
            [value]="option.value"
            [checked]="selection?.isSelected(option)"
            [disabled]="option?.disabled">
            <mg-text
              variant="body-md"
              [numberOfLines]="2">
              {{ option.label }}
            </mg-text>
          </mat-radio-button>
        </ng-template>
      </button>
    </cdk-virtual-scroll-viewport>
    <!-- Empty state -->
    <ng-container
      *ngIf="(filter.hasFilteredData$ | async) === false"
      [ngTemplateOutlet]="emptyStateTemplate">
    </ng-container>
  </div>
</div>
<!-- Empty state template -->
<ng-template #emptyStateTemplate>
  <div class="empty-state">
    <mg-empty-state
      [title]="MESSAGE.EMPTY_STATE_TITLE"
      [subtitle]="MESSAGE.EMPTY_STATE_SUBTITLE">
    </mg-empty-state>
  </div>
</ng-template>
