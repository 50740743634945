import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CancelConfirmationDialogComponent } from 'minga/app/src/app/dialog';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { MingaPermission } from 'minga/libraries/domain';

import { UpdatePeopleListState } from '../../update-people-list-state';

@Component({
  selector: 'mg-update-people-additional-form-fields',
  templateUrl: './update-people-additional-form-fields.component.html',
  styleUrls: ['./update-people-additional-form-fields.component.scss'],
})
export class UpdatePeopleAdditionalFormFieldsComponent implements OnInit {
  public showPin = false;

  constructor(
    public listState: UpdatePeopleListState,
    private _dialog: MatDialog,
    private _permissions: PermissionsService,
  ) {}

  ngOnInit(): void {
    if (this._permissions.hasPermission(MingaPermission.SUPERADMIN)) {
      this.showPin = true;
    }
  }

  async onStateChange() {
    this.listState.stateChanged.next(this.listState);
  }

  async archiveDialog(event: boolean) {
    if (!this.listState.archiveUsers) {
      return;
    }

    const options = {
      data: {
        title: 'Archive Users',
        text: 'Are you sure you want to archive users missing from this list? This action cannot be undone!',
      },
    };

    const dialogRef = this._dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.listState.archiveUsers = false;
      }
      this.onStateChange();
    });
  }
}
