"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeResponsePresentationMapper = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const date_pb_1 = require("minga/proto/common/date_pb");
const challenge_response_pb_1 = require("minga/proto/content/challenge/challenge_response_pb");
const person_view_pb_1 = require("minga/proto/gateway/person_view_pb");
const image_pb_1 = require("minga/proto/image/image_pb");
const content_1 = require("minga/libraries/shared-grpc/content/index");
var ChallengeResponsePresentationMapper;
(function (ChallengeResponsePresentationMapper) {
    function fromPresentationToChallengeResponseCardMessage(cardData) {
        const msg = new challenge_response_pb_1.ChallengeResponseCard();
        msg.setAuthorPersonView(shared_grpc_1.asObjectToMessage(cardData.authorPersonView, new person_view_pb_1.PersonViewMinimal()));
        msg.setBackgroundBannerHash(cardData.backgroundBannerHash);
        msg.setBackgroundColor(cardData.backgroundColor);
        msg.setBody(cardData.body);
        msg.setCommentCount(cardData.commentCount);
        if (cardData.commentList) {
            const comments = content_1.CommentListPresentationMapper.fromPresentationToCommentContentMessage(cardData.commentList);
            msg.setCommentList(comments);
        }
        else {
            msg.setCommentList([]);
        }
        msg.setCommentListPage(cardData.commentListPage);
        msg.setContentHash(cardData.contentHash);
        msg.setContextHash(cardData.contextHash);
        msg.setLikeCount(cardData.likeCount);
        msg.setOwnerContextHash(cardData.ownerContextHash);
        msg.setPrivacy(cardData.privacy);
        msg.setPublishDate(shared_grpc_1.asObjectToMessage(cardData.publishDate, new date_pb_1.DateTime()));
        msg.setTotalCommentCount(cardData.totalCommentCount);
        msg.setType(cardData.type);
        msg.setApproved(cardData.approved);
        const imgInfo = cardData === null || cardData === void 0 ? void 0 : cardData.featuredImage;
        if (imgInfo) {
            const imageInfo = new image_pb_1.ImageInfo();
            let sizeMap = imageInfo.getSizeMap();
            for (let [key, value] of imgInfo.sizeMap) {
                const imgSize = new image_pb_1.ImageSize();
                imgSize.setPath(value.path);
                imgSize.setWidth(value.width);
                imgSize.setHeight(value.height);
                sizeMap.set(key, imgSize);
            }
            msg.setFeaturedImage(imageInfo);
        }
        const bgImgInfo = cardData === null || cardData === void 0 ? void 0 : cardData.backgroundImage;
        if (bgImgInfo) {
            const imageInfo = new image_pb_1.ImageInfo();
            let sizeMap = imageInfo.getSizeMap();
            for (let [key, value] of bgImgInfo.sizeMap) {
                const imgSize = new image_pb_1.ImageSize();
                imgSize.setPath(value.path);
                imgSize.setWidth(value.width);
                imgSize.setHeight(value.height);
                sizeMap.set(key, imgSize);
            }
            msg.setBackgroundImage(imageInfo);
        }
        // msg.setVideo(cardData.video);
        return msg;
    }
    ChallengeResponsePresentationMapper.fromPresentationToChallengeResponseCardMessage = fromPresentationToChallengeResponseCardMessage;
    function fromPresentationToChallengeResponseListItemMessage(cardData) {
        const msg = new challenge_response_pb_1.ChallengeResponseListItem();
        msg.setAuthorPersonView(shared_grpc_1.asObjectToMessage(cardData.authorPersonView, new person_view_pb_1.PersonViewMinimal()));
        msg.setBody(cardData.body);
        msg.setCommentCount(cardData.commentCount);
        msg.setContentHash(cardData.contentHash);
        msg.setContextHash(cardData.contextHash);
        msg.setLikeCount(cardData.likeCount);
        msg.setOwnerContextHash(cardData.ownerContextHash);
        msg.setPublishDate(shared_grpc_1.asObjectToMessage(cardData.publishDate, new date_pb_1.DateTime()));
        const imgInfo = cardData === null || cardData === void 0 ? void 0 : cardData.featuredImage;
        if (imgInfo) {
            const imageInfo = new image_pb_1.ImageInfo();
            let sizeMap = imageInfo.getSizeMap();
            for (let [key, value] of imgInfo.sizeMap) {
                const imgSize = new image_pb_1.ImageSize();
                imgSize.setPath(value.path);
                imgSize.setWidth(value.width);
                imgSize.setHeight(value.height);
                sizeMap.set(key, imgSize);
            }
            msg.setFeaturedImage(imageInfo);
        }
        msg.setApproved(cardData.approved);
        // msg.setVideo(cardData.video);
        return msg;
    }
    ChallengeResponsePresentationMapper.fromPresentationToChallengeResponseListItemMessage = fromPresentationToChallengeResponseListItemMessage;
})(ChallengeResponsePresentationMapper = exports.ChallengeResponsePresentationMapper || (exports.ChallengeResponsePresentationMapper = {}));