import { Injectable } from '@angular/core';

import { CacheService } from '@shared/services/cache/cache.service';
import { CacheKey } from '@shared/services/cache/cache.types';

@Injectable()
export class BellScheduleCacheService {
  constructor(private _cacheService: CacheService) {}

  /**
   * Clears any bell schedule related client caches
   */
  public async clearBellScheduleCache() {
    await this._cacheService.clearCacheByNamespace([
      // clear student schedules
      CacheKey.STUDENT_SCHEDULE,
      // schedules
      CacheKey.BELL_SCHEDULE_SCHEDULES_LIST,
      CacheKey.BELL_SCHEDULE_CALENDAR_LIST,
      // terms
      CacheKey.BELL_SCHEDULE_TERMS_LIST,
      // periods
      CacheKey.BELL_SCHEDULE_PERIODS_LIST,
    ]);
  }
}
