import { CacheItem, CacheKey, StorageAdapter } from '../cache.types';

const cache = new Map<string, any>();

export class InMemoryAdapter implements StorageAdapter {
  public async get<T = any>(key: string): Promise<CacheItem<T> | null> {
    return cache.has(key) ? cache.get(key) : null;
  }

  public async set<T = any>(key: string, value: CacheItem<T>): Promise<void> {
    cache.set(key, value);
  }

  public async delete(key: string): Promise<void> {
    cache.delete(key);
  }

  public async clearAll(): Promise<void> {
    cache.clear();
  }

  public async getKeysByNamespace(namespace: CacheKey): Promise<string[]> {
    return Array.from(cache.keys()).filter(key => key.startsWith(namespace));
  }
}
