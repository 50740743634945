"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DistrictManagerRole = void 0;
const domain_1 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
const UserRole_1 = require("minga/libraries/shared/permissions/internal/UserRole");
class DistrictManagerRole extends UserRole_1.UserRole {
    constructor() {
        super(...arguments);
        this.roleType = domain_1.MingaRoleType.DISTRICT_MANAGER;
        this.name = 'District Manager';
        this.displayNameFormat = util_1.DisplayNameFormat.F_LAST;
        this.iconUrl = 'assets/roles/district-manager.svg';
        this.iconColor = '#2465B9';
        this.capabilitiesDescription = `District Managers will be allowed to share announcements, posts, pictures, videos, events, and polls, and will be able to access all Mingas in their district.`;
        this.feedPermittable = true;
        this.galleryPermittable = true;
        this.groupFeedPermittable = false;
        this.programManagePermittable = true;
        this.videoUploadPermittable = true;
        this.commentPermittable = true;
        this.groupCreatePermittable = true;
        this.admin = true;
        this.requiredFeatures = [];
        this.sendToMailChimp = true;
        this.permissions = [
            domain_1.MingaPermission.CONTENT_DELETE_OTHERS,
            domain_1.MingaPermission.ACCOUNT_DISPLAYNAME_CHANGE,
            domain_1.MingaPermission.ACCOUNT_PROFILE_CHANGE,
            domain_1.MingaPermission.APP_BROADCAST_SEND,
            domain_1.MingaPermission.ASSET_UPLOAD,
            domain_1.MingaPermission.CONTENT_ANNOUNCEMENT_CREATE,
            domain_1.MingaPermission.CONTENT_COMMENT_CREATE,
            domain_1.MingaPermission.CONTENT_COMMENTS_VIEW,
            domain_1.MingaPermission.CONTENT_EVENT_CREATE,
            domain_1.MingaPermission.CONTENT_GROUP_CREATE,
            domain_1.MingaPermission.CONTENT_GROUP_VIEWALL,
            domain_1.MingaPermission.CONTENT_GROUP_ENABLED,
            domain_1.MingaPermission.CONTENT_GROUP_MANAGE,
            domain_1.MingaPermission.CONTENT_HTML_INCLUDE,
            domain_1.MingaPermission.CONTENT_LIKES_LIKE,
            domain_1.MingaPermission.CONTENT_MODERATION_OVERRIDE,
            domain_1.MingaPermission.CONTENT_POLL_CREATE,
            domain_1.MingaPermission.CONTENT_POLL_ANSWER,
            domain_1.MingaPermission.CONTENT_POST_CREATE,
            domain_1.MingaPermission.CONTENT_POST_SET_TITLE,
            domain_1.MingaPermission.CONTENT_PUBLISH_DATES_SET,
            domain_1.MingaPermission.CONTENT_REPORT_CREATE,
            domain_1.MingaPermission.CONTENT_REPORTS_MANAGE,
            domain_1.MingaPermission.CONTENT_VIDEO_CREATE,
            domain_1.MingaPermission.MINGA_ACCOUNT_MANAGE,
            domain_1.MingaPermission.GROUP_BROADCAST_SEND,
            domain_1.MingaPermission.GROUP_CONTENT_ANNOUNCEMENT_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_EVENTS_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_POLL_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_POST_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
            domain_1.MingaPermission.MINGA_CONTENT_PIN,
            domain_1.MingaPermission.MINGA_CONTENT_MAIN_FEED,
            domain_1.MingaPermission.MINGA_FILE_CREATE,
            domain_1.MingaPermission.MINGA_PROFILE_EDIT,
            domain_1.MingaPermission.MINGA_PEOPLE_MANAGE,
            domain_1.MingaPermission.MINGA_POLLS_MANAGE,
            domain_1.MingaPermission.MINGA_BLOCKORDER_UPLOAD,
            domain_1.MingaPermission.CONTENT_ANY_GET_DELETED,
            domain_1.MingaPermission.MINGA_PROGRAMS_MANAGE,
            domain_1.MingaPermission.GALLERY_ADD_PHOTO,
            domain_1.MingaPermission.GALLERY_DELETE_OTHERS_PHOTO,
            domain_1.MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
            domain_1.MingaPermission.GROUP_ALLOW_PARENT_GROUP,
            domain_1.MingaPermission.GROUP_ALLOW_ANY_PARENT_GROUP,
            domain_1.MingaPermission.EMAIL_CONTENT,
            domain_1.MingaPermission.EMAIL_CONTENT_AS_GROUP_OWNER,
            domain_1.MingaPermission.CONTENT_MANAGE_COMMENT_SETTINGS,
            domain_1.MingaPermission.MINGA_CONTENT_UPDATE_OTHERS,
            domain_1.MingaPermission.MODERATION_GET_REPORT_NOTIFICATIONS,
            domain_1.MingaPermission.EVENTS_MANAGE,
            domain_1.MingaPermission.CONTENT_CHALLENGE_CREATE,
            domain_1.MingaPermission.GROUP_CONTENT_CHALLENGE_CREATE,
            domain_1.MingaPermission.CONTENT_CHALLENGE_MANAGE,
            domain_1.MingaPermission.CHALLENGE_COMPLETE,
            domain_1.MingaPermission.POINTS_MANAGE,
            domain_1.MingaPermission.ADMINISTER_STUDENT_ID,
            domain_1.MingaPermission.ADMINISTER_STUDENT_ID_SETTINGS,
            domain_1.MingaPermission.HALL_PASS_TYPE_MANAGE,
            domain_1.MingaPermission.HALL_PASS_MANAGE,
            domain_1.MingaPermission.HALL_PASS_CREATE,
            /**
             * @deprecated can be removed once support for < 7.2.2 is no longer needed
             */
            domain_1.MingaPermission.TEACHER_TOOLS,
            domain_1.MingaPermission.PBIS_TYPE_MANAGE,
            domain_1.MingaPermission.PBIS_CREATE,
            domain_1.MingaPermission.PBIS_VIEW_REPORTS,
            domain_1.MingaPermission.CHECKIN_TYPE_MANAGE,
            domain_1.MingaPermission.CHECKIN_MANAGE,
            domain_1.MingaPermission.CHECKIN_VIEW_REPORTS,
            domain_1.MingaPermission.CONSEQUENCE_CREATE,
            domain_1.MingaPermission.POINTS_VIEW_REPORTS,
            domain_1.MingaPermission.MANAGE_REWARDS,
            domain_1.MingaPermission.FLEX_TIME_MANAGE,
            domain_1.MingaPermission.FLEX_TIME_VIEW_REPORTS,
            domain_1.MingaPermission.VIEW_MINGA_DASHBOARD,
            domain_1.MingaPermission.HALL_PASS_VIEW_REPORTS,
            domain_1.MingaPermission.POINTS_ALLOCATE_ALLOWED,
            domain_1.MingaPermission.SCHEDULED_REPORTS_MANAGE,
            domain_1.MingaPermission.SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT,
            domain_1.MingaPermission.USER_LIST_VIEW,
            domain_1.MingaPermission.USER_LIST_ADMIN,
            domain_1.MingaPermission.DM_CAN_MESSAGE,
            domain_1.MingaPermission.CONTENT_SMS_MESSAGE_CREATE,
            domain_1.MingaPermission.MINGA_MANAGE,
            domain_1.MingaPermission.DATE_RANGE_PRESETS_MANAGE,
            domain_1.MingaPermission.DATE_RANGE_PRESETS_VIEW,
            domain_1.MingaPermission.ID_CHANGE_OWN_PHOTO,
            domain_1.MingaPermission.ADMIN_TOOLS,
            domain_1.MingaPermission.STUDENT_SCHEDULE_VIEW,
            domain_1.MingaPermission.BELL_SCHEDULE_MANAGE,
            domain_1.MingaPermission.VIEW_PROFILE,
        ];
        this.overridablePermissions = [
            domain_1.MingaPermission.CONTENT_SMS_MESSAGE_CREATE,
        ];
    }
}
exports.DistrictManagerRole = DistrictManagerRole;