<div
  class="list-row tw-flex tw-items-center tw-gap-3"
  [ngClass]="classes">
  <button
    type="button"
    class="checkbox"
    [attr.aria-label]="student.firstName + ' ' + student.lastName"
    [attr.data-analytics]="'tools-myclass-click-student-card'"
    [attr.data-test]="'tools-myclass-click-student-card'"
    (click)="toggle()">
    <div class="card-checkbox">
      <mg-form-checkbox [checked]="selected"></mg-form-checkbox>
    </div>
  </button>
  <div
    *ngIf="hidePicture === false"
    class="list-avatar">
    <div
      *ngIf="student.filename"
      class="profile-picture"
      [mgStudentPicture]="student.filename"></div>
    <mg-avatar [src]="student.profileImageUrl"></mg-avatar>
  </div>
  <mg-text
    class="w-sm-flex"
    variant="body-md"
    [numberOfLines]="1">
    {{ student.firstName }}
  </mg-text>
  <mg-text
    class="w-sm-flex"
    variant="body-md"
    [numberOfLines]="1">
    {{ student.lastName }}
  </mg-text>

  <div class="tw-flex tw-flex-1 tw-items-center tw-gap-3">
    <div
      *ngIf="hallPass && PASS_STATUS_TO_TYPE[hallPass.status.state]"
      class="tw-flex tw-items-center tw-gap-1">
      <!-- Hall Pass Pill -->
      <ng-container
        *ngIf="
          hallPass
            | countdown
              : (recentlyEndedSetting$ | async)
              : (manuallyEndedPassesSetting$ | async)
              : myClassHallPassService.timeTicker$
              : hallPass.type?.manuallyEndPass
            | async as countdown;
          else loader
        ">
        <mg-tt-pill
          [attr.aria-label]="'Active Hall Passes'"
          [type]="
            selected && hallPass.status.state === HallPassStatus.ACTIVE
              ? 'selected'
              : PASS_STATUS_TO_TYPE[hallPass.status.state]
          "
          icon="mg-away"
          [text]="
            hallPass.status.state === HallPassStatus.ACTIVE ||
            hallPass.status.state === HallPassStatus.OVERDUE
              ? '' + countdown
              : ''
          "
          [id]="'tools-myclass-click-pill-hp'"
          [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="hallpass">
        </mg-tt-pill>
        <ng-container
          *ngIf="
            HallPassStatus.PENDING_APPROVAL === hallPass.status.state &&
            (('HALL_PASS_TYPE_MANAGE' | hasPermission) ||
              authHash === hallPass.authorPersonView?.personHash)
          ">
          <div
            class="tt-pill"
            [attr.data-analytics]="'tools-myclass-click-pill-deny'"
            [attr.data-test]="'tools-myclass-click-pill-deny'"
            (click)="
              $event.stopPropagation();
              onHallPassActionClicked('deny', { skipConfirmation: true })
            ">
            <mg-icon
              class="deny"
              iconName="mg-cross">
            </mg-icon>
          </div>
          <div
            class="tt-pill"
            [attr.data-analytics]="'tools-myclass-click-pill-approve'"
            [attr.data-test]="'tools-myclass-click-pill-approve'"
            (click)="
              $event.stopPropagation();
              onHallPassActionClicked('approve', { skipConfirmation: true })
            ">
            <mg-icon
              class="approve"
              iconName="mg-checkmark">
            </mg-icon>
          </div>
        </ng-container>
        <mg-btn
          *ngIf="
            HallPassStatus.ACTIVE === hallPass.status.state ||
            HallPassStatus.OVERDUE === hallPass.status.state
          "
          class="tw-flex"
          variant="text"
          size="xsmall"
          id="tools-myclass-click-pill-end"
          (pressed)="
            onHallPassActionClicked('end', { skipConfirmation: true })
          ">
          End
        </mg-btn>
        <mat-menu
          #hallpass="matMenu"
          yPosition="below"
          xPosition="after"
          [hasBackdrop]="true">
          <ng-template matMenuContent>
            <div
              class="popup-wrap large"
              fxLayout="column"
              fxLayoutGap="4px">
              <mg-text
                variant="label-md-secondary"
                element="p"
                >{{ hallPass.status.state }} Hall Pass</mg-text
              >
              <mg-text
                variant="label-md"
                element="p">
                {{ hallPass.type?.name }}
              </mg-text>

              <ng-container
                *ngIf="
                  hallPass.status.state === HallPassStatus.ENDED;
                  else timer
                ">
                <mg-text
                  variant="label-md"
                  element="p">
                  {{ hallPass.status.end | date: 'hh:mm a' }}
                </mg-text>
              </ng-container>
              <ng-template #timer>
                <ng-container *ngIf="countdown; else loader">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="2px">
                    <mg-icon
                      *ngIf="
                        showIcon() &&
                        hallPass.status.state === HallPassStatus.ACTIVE
                      "
                      class="auto-end-icon"
                      iconName="mg-auto-end">
                    </mg-icon>
                    <mg-text
                      *ngIf="countdown !== 'DISABLED'"
                      variant="label-md"
                      element="p">
                      {{ countdown }}
                    </mg-text>
                  </div>
                </ng-container>
              </ng-template>

              <div class="tw-flex tw-justify-center tw-items-center">
                <mg-btn
                  *ngIf="
                    HallPassStatus.ACTIVE === hallPass.status.state ||
                    HallPassStatus.OVERDUE === hallPass.status.state
                  "
                  variant="text"
                  id="tools-myclass-click-end"
                  (pressed)="onHallPassActionClicked('end')">
                  End
                </mg-btn>
                <ng-container
                  *ngIf="
                    HallPassStatus.PENDING_APPROVAL === hallPass.status.state &&
                    (('HALL_PASS_TYPE_MANAGE' | hasPermission) ||
                      authHash === hallPass.authorPersonView?.personHash)
                  ">
                  <mg-btn
                    variant="text"
                    id="tools-myclass-click-deny"
                    (pressed)="onHallPassActionClicked('deny')">
                    Deny
                  </mg-btn>
                  <mg-btn
                    variant="text"
                    id="tools-myclass-click-approve"
                    (pressed)="onHallPassActionClicked('approve')">
                    Approve
                  </mg-btn>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </mat-menu>
      </ng-container>
      <ng-template #loader>
        <div class="inline-spinner">
          <mg-spinner
            [diameter]="16"
            [thickness]="2">
          </mg-spinner>
        </div>
      </ng-template>
    </div>

    <!-- Hall Pass Info -->
    <ng-container
      *ngIf="
        hallPass?.status.state === HallPassStatus.ACTIVE ||
        hallPass?.status.state === HallPassStatus.OVERDUE ||
        hallPass?.status.state === HallPassStatus.PENDING_APPROVAL
      ">
      <div class="tw-flex tw-items-center tw-gap-1">
        <mg-icon
          matTooltipClass="mg-tooltip"
          [matTooltip]="hallPass.type?.name"
          [iconName]="hallPass.type?.bannerHash"
          [style.color]="hallPass.type?.color">
        </mg-icon>
        <mg-text
          variant="body-sm"
          matTooltipClass="mg-tooltip"
          [matTooltip]="hallPass.type?.name"
          [numberOfLines]="1">
          {{ hallPass.type?.name }}
        </mg-text>
      </div>
    </ng-container>

    <!-- Behavior Pill -->
    <mg-tt-pill
      *ngIf="
        ((pastPraises$ | async)?.totalCount > 0 ||
          (pastPraiseConsequences$ | async)?.totalCount > 0) &&
        !(
          hallPass?.status.state === HallPassStatus.ACTIVE ||
          hallPass?.status.state === HallPassStatus.OVERDUE ||
          hallPass?.status.state === HallPassStatus.PENDING_APPROVAL
        )
      "
      [attr.aria-label]="'Active Praises'"
      type="success"
      [text]="
        (pastPraises$ | async)?.totalCount +
        (pastPraiseConsequences$ | async)?.totalCount
      "
      [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
      [matMenuTriggerFor]="pastPraises"
      (click)="$event.stopPropagation()">
    </mg-tt-pill>
    <mat-menu
      #pastPraises="matMenu"
      yPosition="below"
      xPosition="after"
      [hasBackdrop]="true">
      <ng-template matMenuContent>
        <div
          class="popup-wrap"
          fxLayout="column"
          fxLayoutGap="4px">
          <div *ngIf="(pastPraises$ | async)?.totalCount > 0">
            <mg-text
              variant="label-md-secondary"
              element="p"
              >Praises</mg-text
            >
            <mg-text
              *ngFor="let praise of (pastPraises$ | async)?.items"
              variant="label-md"
              element="p">
              {{ praise.count }} {{ praise.label }}
            </mg-text>
          </div>
          <div *ngIf="(pastPraiseConsequences$ | async)?.totalCount > 0">
            <mg-text
              variant="label-md-secondary"
              element="p"
              >Consequences</mg-text
            >
            <mg-text
              *ngFor="
                let consequence of (pastPraiseConsequences$ | async)?.items
              "
              variant="label-md"
              element="p">
              {{ consequence.count }} {{ consequence.label }}
            </mg-text>
          </div>
        </div>
      </ng-template>
    </mat-menu>

    <!-- Guidance Pill -->
    <mg-tt-pill
      *ngIf="
        ((pastGuidances$ | async)?.totalCount > 0 ||
          (pastConsequences$ | async)?.totalCount > 0) &&
        !(
          hallPass?.status.state === HallPassStatus.ACTIVE ||
          hallPass?.status.state === HallPassStatus.OVERDUE ||
          hallPass?.status.state === HallPassStatus.PENDING_APPROVAL
        )
      "
      [attr.aria-label]="'Active Guidances'"
      type="error"
      [text]="
        (pastGuidances$ | async)?.totalCount +
        (pastConsequences$ | async)?.totalCount
      "
      [id]="'tools-myclass-click-pill-guid'"
      [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="pastGuidances">
    </mg-tt-pill>
    <mat-menu
      #pastGuidances="matMenu"
      yPosition="below"
      xPosition="after"
      [hasBackdrop]="true">
      <ng-template matMenuContent>
        <div
          class="popup-wrap"
          fxLayout="column"
          fxLayoutGap="4px">
          <div *ngIf="(pastGuidances$ | async)?.totalCount > 0">
            <mg-text
              variant="label-md-secondary"
              element="p"
              >Guidances</mg-text
            >
            <mg-text
              *ngFor="
                let guidance of (pastGuidances$ | async)?.items;
                let i = index
              "
              variant="label-md"
              element="p">
              {{ guidance.count }} {{ guidance.label }}
            </mg-text>
          </div>
          <div *ngIf="(pastConsequences$ | async)?.totalCount > 0">
            <mg-text
              variant="label-md-secondary"
              element="p"
              >Consequences</mg-text
            >
            <mg-text
              *ngFor="
                let consequence of (pastConsequences$ | async)?.items;
                let i = index
              "
              variant="label-md"
              element="p">
              {{ consequence.count }} {{ consequence.label }}
            </mg-text>
          </div>
        </div>
      </ng-template>
    </mat-menu>
  </div>

  <mg-btn
    *ngIf="canViewProfile; else idTemplate"
    class="card-student-id"
    size="small"
    variant="icon-secondary"
    icon="mg-role"
    iconSet="minga"
    id="tools-myclass-click-view-profile"
    [attr.aria-label]="'Nagivate to student id'"
    (pressed)="navigateToProfile(student.personHash)">
  </mg-btn>
  <ng-template #idTemplate>
    <mg-btn
      [attr.aria-label]="'Nagivate to student id'"
      class="card-student-id"
      size="small"
      variant="icon-secondary"
      icon="mg-id-menu-o"
      iconSet="minga"
      (pressed)="navigateToId(student.personHash)">
    </mg-btn>
  </ng-template>
</div>
