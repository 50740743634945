import { Component, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { ReportService } from 'minga/app/src/app/services/Report';
import { MingaSettingsService } from 'minga/app/src/app/store/Minga/services';
import { MingaPermission } from 'minga/libraries/domain';

import { LayoutService } from '@modules/layout/services';

import { ModerationPostsComponent } from './components/moderation-posts/moderation-posts.component';
import { ModerationReportsComponent } from './components/moderation-reports/moderation-reports.component';

@Component({
  selector: 'mg-moderation',
  templateUrl: './moderation.component.html',
  styleUrls: ['./moderation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModerationComponent implements OnInit, OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  globalContent = false;
  showStats = false;
  stats: any = {};

  private _reportUpdateSub?: Subscription;
  private _deletionSub?: Subscription;
  private _creationSub?: Subscription;
  private _mediaSubscription: Subscription;

  private _activeReportsFeedComponent: ModerationReportsComponent;
  private _activeModerationFeedComponent: ModerationPostsComponent;

  readonly shouldShowDirectMessages$: Observable<boolean>;

  /** Component constructor */
  constructor(
    public layout: LayoutService,
    public reportService: ReportService,
    public contentEvents: ContentEvents,
    public permissions: PermissionsService,
    public route: ActivatedRoute,
    private _settingsService: MingaSettingsService,
  ) {
    this.shouldShowDirectMessages$ = this._settingsService.isDmModuleEnabled();
  }

  ngOnInit() {
    this.initSetup();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    if (this._reportUpdateSub) {
      this._reportUpdateSub.unsubscribe();
    }
    if (this._deletionSub) {
      this._deletionSub.unsubscribe();
    }
    if (this._creationSub) {
      this._creationSub.unsubscribe();
    }
  }

  async initSetup() {
    // only enable showing passed ai moderation items if have the permission
    this.showStats = this.permissions.hasPermission(
      MingaPermission.CONTENT_REPORTS_MANAGE,
    );

    this.route.data.subscribe(async data => {
      const globalContent = !!data.globalContent;
      const globalPermission = this.permissions.hasPermission(
        MingaPermission.SUPERADMIN,
      );

      if (globalContent && globalPermission) {
        this.globalContent = true;
      } else {
        this.initMingaReports();
      }

      if (this.showStats) {
        // when report are updates (such as from new report content), the stats
        // and tabs should be re-initialized with latest data
        this._reportUpdateSub = this.reportService.onReportUpdate.subscribe(
          ev => {
            this.initStats();
          },
        );

        this._deletionSub = this.contentEvents.onContentDeleted.subscribe(
          ev => {
            this.refreshStreams();
          },
        );

        this._creationSub = this.contentEvents.onPostCreateSuccess.subscribe(
          ev => {
            this.refreshStreams();
          },
        );

        await this.initStats();
      }
    });
  }

  async initStats() {
    // get the current stats
    if (this.showStats) {
      this.stats = await this.reportService.retrieveStats(this.globalContent);

      if (this._activeReportsFeedComponent) {
        this._activeReportsFeedComponent.stats = this.stats;
        await this._activeReportsFeedComponent.initTabs(false);
      }
      if (this._activeModerationFeedComponent) {
        this._activeModerationFeedComponent.stats = this.stats;
        await this._activeModerationFeedComponent.initTabs(false);
      }
    }
  }

  refreshStreams() {
    this.reportService.restartReportRelatedStreams();
  }

  initMingaReports() {
    this.reportService.retrieveReportedUgc();
  }

  tabClick(e) {
    if (e && e.srcElement && e.srcElement.classList.contains('active')) {
      this.refreshStreams();
      this.initStats();
    }
  }

  onOverlayActivate(comp: any) {
    const setConfig = (
      component: ModerationReportsComponent | ModerationPostsComponent,
    ) => {
      component.stats = this.stats;
      // component.globalContent = this.globalContent;
    };
    if (comp instanceof ModerationReportsComponent) {
      this._activeReportsFeedComponent = comp;
      setConfig(comp);
    } else if (comp instanceof ModerationPostsComponent) {
      this._activeModerationFeedComponent = comp;
      setConfig(comp);
    }
  }

  onOverlayDeactivate(comp: any) {
    delete this._activeReportsFeedComponent;
  }
}
