import { HallPassStatusEnum } from 'minga/libraries/domain';

import { ReportTableStatusMap } from '../types/report-table.types';

export enum ReportTableMessage {
  NO_RESULTS_TITLE = 'No results found',
  NO_RESULTS_SUBTITLE = 'Try changing your search or filtering and try again.',
}

export const STATUS_REFERENCE_MAP: ReportTableStatusMap = {
  flex: {
    Absent: 'red',
    'Checked in': 'green',
  },
  behavior: {
    Overdue: 'red',
    Completed: 'green',
    'Not completed': 'yellow',
  },
  hallPass: {
    [HallPassStatusEnum.APPROVED]: 'green',
    [HallPassStatusEnum.PENDING_APPROVAL]: 'yellow',
    [HallPassStatusEnum.EXPIRED]: 'yellow',
    [HallPassStatusEnum.OVERRIDDEN]: 'yellow',
    [HallPassStatusEnum.DENIED]: 'red',
    [HallPassStatusEnum.RESTRICTED]: 'red',
  },
};
