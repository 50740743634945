"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const proto = require("minga/proto/section/section_pb");
const dateAndTime_1 = require("minga/libraries/shared-grpc/util/dateAndTime");
exports.toProto = (section) => {
    const msg = new proto.Section();
    if (section.id)
        msg.setId(section.id);
    msg.setSourcedId(section.sourcedId);
    msg.setTitle(section.title);
    msg.setCourseSourcedId(section.courseSourcedId);
    if (section.status)
        msg.setStatus(section.status);
    msg.setSchoolSourcedId(section.schoolSourcedId);
    if (section.classCode)
        msg.setClassCode(section.classCode);
    if (section.classType)
        msg.setClassType(section.classType);
    msg.setDateLastModified(dateAndTime_1.dateObjectToDateTimeMessage(section.dateLastModified));
    if (section.subjects)
        msg.setSubjectsList(section.subjects);
    if (section.location)
        msg.setLocation(section.location);
    if (section.periods)
        msg.setPeriodsList(section.periods);
    if (section.grades)
        msg.setGradesList(section.grades);
    if (section.termSourcedIds)
        msg.setTermSourcedIdsList(section.termSourcedIds);
    if (section.resourceSourcedIds)
        msg.setResourceSourcedIdsList(section.resourceSourcedIds);
    return msg;
};
exports.fromProto = (msg) => {
    const section = {
        id: msg.getId(),
        sourcedId: msg.getSourcedId(),
        title: msg.getTitle(),
        courseSourcedId: msg.getCourseSourcedId(),
        status: msg.getStatus(),
        schoolSourcedId: msg.getSchoolSourcedId(),
        classCode: msg.getClassCode(),
        classType: msg.getClassType(),
        dateLastModified: dateAndTime_1.dateTimeMessageToDateObject(msg.getDateLastModified()),
        subjects: msg.getSubjectsList(),
        location: msg.getLocation(),
        periods: msg.getPeriodsList(),
        grades: msg.getGradesList(),
        termSourcedIds: msg.getTermSourcedIdsList(),
        resourceSourcedIds: msg.getResourceSourcedIdsList(),
    };
    return section;
};