"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const wrappers_pb_1 = require("minga/proto/google/protobuf/wrappers_pb");
const hall_pass_pb_1 = require("minga/proto/hall_pass/hall_pass_pb");
const membership_list_1 = require("minga/libraries/shared-grpc/membership_list/index");
const restriction_1 = require("minga/libraries/shared-grpc/restriction/index");
exports.toProto = (type) => {
    const msg = new hall_pass_pb_1.HallPassType();
    if (type.id) {
        msg.setId(type.id);
    }
    if (type.name) {
        msg.setName(type.name);
    }
    if (type.asset) {
        msg.setAsset(type.asset);
    }
    if (type.bannerHash) {
        msg.setBannerHash(type.bannerHash);
    }
    if (type.color) {
        msg.setColor(type.color);
    }
    if (type.createdBy) {
        msg.setCreatedBy(type.createdBy);
    }
    if (type.mingaId) {
        msg.setMingaId(type.mingaId);
    }
    if (type.active) {
        msg.setActive(type.active);
    }
    if (type.selfIssue) {
        msg.setSelfIssue(type.selfIssue);
    }
    if (typeof type.priority == 'number') {
        msg.setPriority(type.priority);
    }
    if (typeof type.defaultHallPassTime == 'number') {
        msg.setDefaultHallPassTime(type.defaultHallPassTime);
    }
    if (type.ignoreBlackout) {
        msg.setIgnoreBlackout(type.ignoreBlackout);
    }
    if (type.ignoreLimits) {
        msg.setIgnoreLimits(type.ignoreLimits);
    }
    if (typeof type.maxSimultaneousPasses == 'number') {
        msg.setMaxSimultaneousPasses(type.maxSimultaneousPasses);
    }
    if (type.membershipListId) {
        msg.setMembershipListId(type.membershipListId);
    }
    if (type.membershipList) {
        const membershipList = membership_list_1.MembershipListMapper.toProto(type.membershipList);
        msg.setMembershipList(membershipList);
    }
    if (type.requireTeacherApproval) {
        msg.setRequireTeacherApproval(type.requireTeacherApproval);
    }
    if (type.restriction) {
        const restrictionMsg = restriction_1.RestrictionMapper.toProto(type.restriction);
        msg.setRestriction(restrictionMsg);
    }
    if (type.dailyPassLimit) {
        msg.setDailyPassLimit(type.dailyPassLimit);
    }
    // if the function is called from the frontend it will come as a boolean value
    // if the function is called from the backend it will come as a number value since our db saves as 0/1
    if (typeof type.studentCanEndPass == 'boolean' ||
        typeof type.studentCanEndPass == 'number') {
        const wrapper = new wrappers_pb_1.BoolValue();
        wrapper.setValue(type.studentCanEndPass);
        msg.setStudentCanEndPass(wrapper);
    }
    if (typeof type.canEndOnMobile == 'boolean' ||
        typeof type.canEndOnMobile == 'number') {
        const wrapper = new wrappers_pb_1.BoolValue();
        wrapper.setValue(type.canEndOnMobile);
        msg.setCanEndOnMobile(wrapper);
    }
    if (typeof type.manuallyEndPass == 'boolean' ||
        typeof type.manuallyEndPass == 'number') {
        const wrapper = new wrappers_pb_1.BoolValue();
        wrapper.setValue(type.manuallyEndPass);
        msg.setManuallyEndPass(wrapper);
    }
    if (type.availableInKiosk) {
        msg.setAvailableInKiosk(type.availableInKiosk);
    }
    return msg;
};
exports.fromProto = (msg) => {
    let restriction;
    if (msg.getRestriction()) {
        restriction = restriction_1.RestrictionMapper.fromProto(msg.getRestriction());
    }
    const studentCanEndPass = msg.hasStudentCanEndPass()
        ? msg.getStudentCanEndPass().getValue()
        : undefined;
    const canEndOnMobile = msg.hasCanEndOnMobile()
        ? msg.getCanEndOnMobile().getValue()
        : undefined;
    const manuallyEndPass = msg.hasManuallyEndPass()
        ? msg.getManuallyEndPass().getValue()
        : undefined;
    const id = msg.getId();
    const name = msg.getName();
    const bannerHash = msg.getBannerHash();
    const bannerReference = msg.getBannerReference();
    const asset = msg.getAsset();
    const color = msg.getColor();
    const createdBy = msg.getCreatedBy();
    const mingaId = msg.getMingaId();
    const active = msg.getActive();
    const selfIssue = msg.getSelfIssue();
    const priority = msg.getPriority();
    const defaultHallPassTime = msg.getDefaultHallPassTime();
    const membershipListId = msg.getMembershipListId();
    const membershipList = msg.getMembershipList()
        ? membership_list_1.MembershipListMapper.fromProto(msg.getMembershipList())
        : undefined;
    const maxSimultaneousPasses = msg.getMaxSimultaneousPasses();
    const ignoreLimits = msg.getIgnoreLimits();
    const ignoreBlackout = msg.getIgnoreBlackout();
    const requireTeacherApproval = msg.getRequireTeacherApproval();
    const dailyPassLimit = msg.getDailyPassLimit();
    const availableInKiosk = msg.getAvailableInKiosk();
    return {
        id,
        name,
        asset,
        bannerHash,
        bannerReference,
        color,
        createdBy,
        mingaId,
        active,
        selfIssue,
        priority,
        defaultHallPassTime,
        membershipListId,
        maxSimultaneousPasses,
        ignoreLimits,
        ignoreBlackout,
        membershipList,
        requireTeacherApproval,
        restriction,
        dailyPassLimit,
        manuallyEndPass,
        studentCanEndPass,
        canEndOnMobile,
        availableInKiosk,
    };
};