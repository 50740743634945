import { Injectable } from '@angular/core';

import * as automation_pb from 'minga/proto/automation/automation_pb';
import {
  AutomationPayload,
  IActionThresholdAutomation,
} from 'minga/libraries/domain';
import { IPbisAutomationCounterResult } from 'minga/libraries/domain';
import { AutomationMapper } from 'minga/libraries/shared-grpc';
import { AutomationManager } from 'minga/proto/automation/automation_ng_grpc_pb';
import { PbisManager } from 'minga/proto/pbis/pbis_ng_grpc_pb';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable({ providedIn: 'root' })
export class AutomationService {
  private _autoId: number;
  /** Service Constructor */
  constructor(
    private _pbisManager: PbisManager,
    private _automationManager: AutomationManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  public async fetchAutomations(
    active = false,
  ): Promise<IActionThresholdAutomation[]> {
    try {
      const result = await this._listAutomations(active);
      return result;
    } catch (error) {
      console.error(error);
      this._systemAlertSnackBar.error(`Failed to fetch automations`);
    }
  }

  public async fetchAutomation(
    id: number,
  ): Promise<IActionThresholdAutomation> {
    try {
      const result = await this._getAutomation(id);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        `Failed to fetch automation with id: ${id}`,
      );
    }
  }

  public async createAutomation(
    automation: AutomationPayload,
  ): Promise<IActionThresholdAutomation> {
    try {
      const result = await this._createAutomation(automation);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(`Failed to create new automation`);
    }
  }

  public async updateAutomation(
    automation: AutomationPayload,
  ): Promise<IActionThresholdAutomation> {
    try {
      const result = await this._updateAutomation(automation);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        `Failed to update automation with id: ${automation?.id}`,
      );
    }
  }

  public async deleteAutomation(id: number): Promise<void> {
    try {
      await this._deleteAutomation(id);
    } catch (error) {
      this._systemAlertSnackBar.error(
        `Failed to delete automation with id: ${id}`,
      );
    }
  }

  public setAutoId(id: number) {
    this._autoId = id;
  }

  public async resetAutomationCounts(hashes?: string[], automationId?: number) {
    const req = new automation_pb.ResetAutomationCountRequest();
    req.setId(this._autoId || automationId);
    if (hashes) {
      req.setHashesList(hashes);
    }
    await this._automationManager.resetAutomationCount(req);
  }

  public async resetReportAutomations(
    automationCounters: IPbisAutomationCounterResult[],
    count: number,
  ) {
    const automationMap = new Map<number, string[]>();
    automationCounters.forEach(autoCounter => {
      const automationId = autoCounter.automationGroupId;
      if (!automationMap.has(automationId)) {
        automationMap.set(automationId, [autoCounter.person.personHash]);
        return;
      }
      const existingAutoCounter = automationMap.get(automationId);
      existingAutoCounter.push(autoCounter.person.personHash);
      automationMap.set(automationId, existingAutoCounter);
    });

    for (const autoId of automationMap.keys()) {
      const hashes = automationMap.get(autoId);
      await this.resetMultipleAutomationCounts([autoId], count, hashes);
    }
  }

  public async resetMultipleAutomationCounts(
    automationIds: number[],
    count: number,
    hashes: string[],
    resetEveryone = false,
  ): Promise<void> {
    const request = new automation_pb.AllAutomationsResetRequest();
    request.setAutomationIdsList(automationIds);
    request.setCounterValue(count);
    if (!resetEveryone) {
      request.setPersonHashesList(hashes);
      await this._automationManager.resetAllAutomationsForPeople(request);
    } else await this._automationManager.resetAllAutomations(request);
  }

  private async _listAutomations(
    active = false,
  ): Promise<IActionThresholdAutomation[]> {
    const req = new automation_pb.ListAutomationsRequest();
    req.setActive(active);
    const response = await this._automationManager.listAutomations(req);
    return response.getAutomationsList().map(AutomationMapper.fromProto);
  }

  private async _getAutomation(
    id: number,
  ): Promise<IActionThresholdAutomation> {
    const req = new automation_pb.GetAutomationRequest();
    req.setId(id);
    return AutomationMapper.fromProto(
      await this._automationManager.getAutomation(req),
    );
  }

  private async _createAutomation(
    automation: AutomationPayload,
  ): Promise<IActionThresholdAutomation> {
    const req = new automation_pb.CreateAutomationRequest();
    req.setAutomation(AutomationMapper.toProto(automation));
    return AutomationMapper.fromProto(
      await this._automationManager.createAutomation(req),
    );
  }

  private async _updateAutomation(
    automation: AutomationPayload,
  ): Promise<IActionThresholdAutomation> {
    const req = new automation_pb.UpdateAutomationRequest();
    req.setAutomation(AutomationMapper.toProto(automation));
    return AutomationMapper.fromProto(
      await this._automationManager.updateAutomation(req),
    );
  }

  private async _deleteAutomation(id: number): Promise<void> {
    const req = new automation_pb.DeleteAutomationRequest();
    req.setId(id);
    await this._automationManager.deleteAutomation(req);
  }
}
