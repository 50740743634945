"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUserPreferencesKey = exports.userPreferencesMetadata = void 0;
exports.userPreferencesMetadata = {
    directMessaging: {
        name: 'Allow Direct Messaging',
        description: '',
        defaultValue: true,
        category: 'Direct Messages',
    },
};
function isUserPreferencesKey(value) {
    return Object.keys(exports.userPreferencesMetadata).includes(value);
}
exports.isUserPreferencesKey = isUserPreferencesKey;