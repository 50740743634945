"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const flex_time_pb = require("minga/proto/flex_time/flex_time_pb");
const _1 = require("minga/libraries/shared-grpc/flex_time/index");
exports.toProto = (activityInstance) => {
    const message = new flex_time_pb.FlexTimeActivityInstance();
    if (activityInstance.mingaId)
        message.setMingaId(activityInstance.mingaId);
    if (activityInstance.id)
        message.setId(activityInstance.id);
    if (activityInstance.checkinReasonId)
        message.setCheckinReasonId(activityInstance.checkinReasonId);
    if (activityInstance.flexTimeActivityId)
        message.setActivityId(activityInstance.flexTimeActivityId);
    if (activityInstance.flexTimePeriodId)
        message.setPeriodId(activityInstance.flexTimePeriodId);
    if (activityInstance.flexTimeActivity) {
        message.setActivity(_1.FlexTimeActivityMapper.toProto(activityInstance.flexTimeActivity));
    }
    if (activityInstance.flexTimePeriod) {
        message.setPeriod(_1.FlexTimePeriodMapper.toProto(activityInstance.flexTimePeriod));
    }
    if (activityInstance.createdBy)
        message.setCreatedBy(activityInstance.createdBy);
    if (activityInstance.registered)
        message.setRegistered(activityInstance.registered);
    if (activityInstance.spaces)
        message.setSpaces(activityInstance.spaces);
    if (activityInstance.absentees)
        message.setAbsentees(activityInstance.absentees);
    if (activityInstance.checkinCount)
        message.setCheckinCount(activityInstance.checkinCount);
    return message;
};
exports.fromProto = (message) => {
    var _a, _b, _c, _d;
    return {
        mingaId: message.getMingaId(),
        id: (_a = message.getId()) !== null && _a !== void 0 ? _a : undefined,
        checkinReasonId: (_b = message.getCheckinReasonId()) !== null && _b !== void 0 ? _b : undefined,
        flexTimeActivityId: (_c = message.getActivityId()) !== null && _c !== void 0 ? _c : undefined,
        flexTimePeriodId: (_d = message.getPeriodId()) !== null && _d !== void 0 ? _d : undefined,
        flexTimeActivity: message.getActivity()
            ? _1.FlexTimeActivityMapper.fromProto(message.getActivity())
            : undefined,
        flexTimePeriod: message.getPeriod()
            ? _1.FlexTimePeriodMapper.fromProto(message.getPeriod())
            : undefined,
        createdBy: message.getCreatedBy(),
        spaces: message.getSpaces(),
        registered: message.getRegistered(),
        absentees: message.getAbsentees(),
        checkinCount: message.getCheckinCount(),
    };
};