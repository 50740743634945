"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const minga_pb_1 = require("minga/proto/gateway/minga_pb");
exports.toProto = (district) => {
    const message = new minga_pb_1.MingaDistrict();
    if (district.id)
        message.setId(district.id);
    if (district.name)
        message.setName(district.name);
    if (district.hubspotId)
        message.setHubspotId(district.hubspotId);
    if (district.createdAt)
        message.setCreatedAt(shared_grpc_1.dateObjectToDateTimeMessage(district.createdAt));
    return message;
};
exports.fromProto = (message) => {
    return {
        id: message.getId(),
        name: message.getName(),
        hubspotId: message.getHubspotId(),
        createdAt: message.getCreatedAt()
            ? shared_grpc_1.dateTimeMessageToDateObject(message.getCreatedAt())
            : undefined,
    };
};