"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultCheckinReasons = void 0;
const domain_1 = require("minga/libraries/domain/index");
const domain_2 = require("minga/libraries/domain/index");
exports.defaultCheckinReasons = [
    {
        name: 'Start Of School',
        active: true,
        pointReward: 0,
        icon: 'checkin-8',
        showAbsentees: true,
        selfCheckIn: false,
        roles: [domain_2.MingaRoleType.STUDENT, domain_2.MingaRoleType.STUDENT_LEADER],
        checkinReasonType: domain_1.CheckinReasonType.CHECKIN,
        enableStudentPhoto: false,
    },
    {
        name: 'Flex Period',
        active: true,
        pointReward: 0,
        icon: 'checkin-2',
        showAbsentees: true,
        selfCheckIn: false,
        roles: [domain_2.MingaRoleType.STUDENT, domain_2.MingaRoleType.STUDENT_LEADER],
        checkinReasonType: domain_1.CheckinReasonType.CHECKIN,
        enableStudentPhoto: false,
    },
    {
        name: 'Off Campus Lunch',
        active: true,
        pointReward: 0,
        icon: 'checkin-6',
        showAbsentees: true,
        selfCheckIn: false,
        roles: [domain_2.MingaRoleType.STUDENT, domain_2.MingaRoleType.STUDENT_LEADER],
        checkinReasonType: domain_1.CheckinReasonType.CHECKIN,
        enableStudentPhoto: false,
    },
    {
        name: 'Return From Off Campus Lunch',
        active: true,
        pointReward: 0,
        icon: 'checkin-5',
        showAbsentees: true,
        selfCheckIn: false,
        roles: [domain_2.MingaRoleType.STUDENT, domain_2.MingaRoleType.STUDENT_LEADER],
        checkinReasonType: domain_1.CheckinReasonType.CHECKIN,
        enableStudentPhoto: false,
    },
    {
        name: 'End Of School',
        active: true,
        pointReward: 0,
        icon: 'checkin-9',
        showAbsentees: true,
        selfCheckIn: false,
        roles: [domain_2.MingaRoleType.STUDENT, domain_2.MingaRoleType.STUDENT_LEADER],
        checkinReasonType: domain_1.CheckinReasonType.CHECKIN,
        enableStudentPhoto: false,
    },
];