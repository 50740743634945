"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPreferencesService = void 0;
const domain_1 = require("minga/libraries/domain/index");
class UserPreferencesService {
    /**
     * Get the default user preferences
     */
    getDefault() {
        return {
            directMessaging: domain_1.userPreferencesMetadata.directMessaging.defaultValue,
        };
    }
}
exports.UserPreferencesService = UserPreferencesService;