import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';

import { MingaPermission } from 'minga/libraries/domain';
import { PermissionsService } from 'src/app/permissions';

import { MingaManagerRoutes } from '../constants';

/**
 * Guard to ensure that the user is a superadmin.
 */
@Injectable()
export class MingaDashboardGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionService: PermissionsService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (
      !this.permissionService.hasPermission(
        MingaPermission.VIEW_MINGA_DASHBOARD,
      )
    ) {
      if (window.MINGA_APP_BROWSER) {
        this.router.navigate(['minga-manager', MingaManagerRoutes.REPORTS]);
      } else {
        this.router.navigate(['minga-manager', MingaManagerRoutes.SETTINGS]);
      }
    }
    return true;
  }
}
