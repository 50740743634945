var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagingSettingsService = void 0;
class MessagingSettingsService {
    /**
     * Enables the ability to message between groups of roles. Effectively calling
     * `enableMessagingBetweenRoles` for each group of roles
     */
    enableMessagingBetweenRoleGroups(mingaId, roleGroups) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const roles of roleGroups) {
                yield this.enableMessagingBetweenRoles(mingaId, roles);
            }
        });
    }
    /**
     * Disables the ability to message between groups of roles. Effectively
     * calling `disableMessagingBetweenRoles` for each group of roles
     */
    disableMessagingBetweenRoleGroups(mingaId, roleGroups) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const roles of roleGroups) {
                yield this.disableMessagingBetweenRoles(mingaId, roles);
            }
        });
    }
    /**
     * Checks if all the groups of roles in `roleGroups` can message each other.
     * Effectively calling `isMessagingEnabledBetweenRoles` for each group of
     * roles.
     */
    isMessagingEnabledBetweenRoleGroups(mingaId, roleGroups) {
        return __awaiter(this, void 0, void 0, function* () {
            let enabled = false;
            for (const roles of roleGroups) {
                enabled = yield this.isMessagingEnabledBetweenRoles(mingaId, roles);
                if (!enabled)
                    break;
            }
            return enabled;
        });
    }
}
exports.MessagingSettingsService = MessagingSettingsService;