"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProtoEditable = exports.fromProto = exports.toHallPassWithTypeMessage = exports.toProtoEditable = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const shared_grpc_2 = require("minga/libraries/shared-grpc/index");
const person_view_pb_1 = require("minga/proto/gateway/person_view_pb");
const hall_pass_pb_1 = require("minga/proto/hall_pass/hall_pass_pb");
exports.toProto = (pass) => {
    var _a;
    const msg = new hall_pass_pb_1.HallPass();
    msg.setId(pass.id || 0);
    msg.setStartDateTime(shared_grpc_2.dateObjectToDateTimeMessage(pass.startDate));
    msg.setTypeId(pass.hallPassTypeId);
    if (pass.recipientPersonViewMinimal) {
        msg.setRecipientPersonView(shared_grpc_2.asObjectToMessage(pass.recipientPersonViewMinimal, new person_view_pb_1.PersonViewMinimal()));
    }
    if (pass.authorPersonViewMinimal) {
        msg.setAuthorPersonView(shared_grpc_2.asObjectToMessage(pass.authorPersonViewMinimal, new person_view_pb_1.PersonViewMinimal()));
    }
    if (pass.approvedByPersonViewMinimal) {
        msg.setApprovedByPersonView(shared_grpc_2.asObjectToMessage(pass.approvedByPersonViewMinimal, new person_view_pb_1.PersonViewMinimal()));
    }
    if (pass.endedByPersonViewMinimal) {
        msg.setEndedByPersonView(shared_grpc_2.asObjectToMessage(pass.endedByPersonViewMinimal, new person_view_pb_1.PersonViewMinimal()));
    }
    if (pass.requestedByPersonViewMinimal) {
        msg.setRequestedByPersonView(shared_grpc_2.asObjectToMessage(pass.requestedByPersonViewMinimal, new person_view_pb_1.PersonViewMinimal()));
    }
    if (pass.endDate)
        msg.setEndedTime(shared_grpc_2.dateObjectToDateTimeMessage(pass.endDate));
    if (pass.hallPassType) {
        msg.setTypeInfo(shared_grpc_1.HallPassTypeMapper.toProto(pass.hallPassType));
    }
    if (pass.expiredDate) {
        msg.setExpiredDateTime(shared_grpc_2.dateObjectToDateTimeMessage(pass.expiredDate));
    }
    if (pass.note) {
        msg.setNote(pass.note);
    }
    if (pass.noteToRequester) {
        msg.setNoteToRequester(pass.noteToRequester);
    }
    if (pass.approvalDate) {
        msg.setApprovalDate(shared_grpc_2.dateObjectToDateTimeMessage(pass.approvalDate));
    }
    if (pass.startDateString)
        msg.setStartDateString(pass.startDateString);
    if (pass.endDateString)
        msg.setEndDateString(pass.endDateString);
    if (pass.studentCreated)
        msg.setStudentCreated(pass.studentCreated);
    if (pass.deniedAt) {
        msg.setDeniedTime(shared_grpc_2.dateObjectToDateTimeMessage(pass.deniedAt));
    }
    if (pass.deniedByPerson)
        msg.setDeniedByPerson(pass.deniedByPerson);
    if (pass.deniedByReason)
        msg.setDeniedByReason(pass.deniedByReason);
    if (pass.deniedByError)
        msg.setDeniedByError(pass.deniedByError);
    if (pass.isKiosk)
        msg.setIsKiosk(pass.isKiosk);
    if (pass.status)
        msg.setStatus(pass.status);
    if (pass.createdBy)
        msg.setCreatedBy(pass.createdBy);
    if ((_a = pass.currentClass) === null || _a === void 0 ? void 0 : _a.length) {
        const sections = pass.currentClass.map(shared_grpc_1.StudentScheduleMapper.toProto);
        msg.setCurrentClassesList(sections);
        // deprecated but for b/c
        msg.setCurrentClass(sections[0]);
    }
    return msg;
};
exports.toProtoEditable = (pass) => {
    const msg = exports.toProto(pass);
    const editable = new hall_pass_pb_1.EditableHallPass();
    editable.setHallPass(msg);
    if (pass.manuallyUpdatedAt) {
        editable.setManuallyUpdatedAt(shared_grpc_2.dateObjectToDateTimeMessage(pass.manuallyUpdatedAt));
    }
    return editable;
};
exports.toHallPassWithTypeMessage = (hallPass) => {
    var _a, _b;
    const item = new hall_pass_pb_1.HallPassWithType();
    const hallPassMessage = exports.toProto(hallPass);
    item.setHallPass(hallPassMessage);
    if (hallPass.hallPassType) {
        const hallPassTypeMessage = shared_grpc_1.HallPassTypeMapper.toProto(hallPass.hallPassType);
        item.setHallPassType(hallPassTypeMessage);
    }
    // add student id info to specific fields for this message
    if (hallPass.recipientPersonId) {
        item.setRecipientStudentId((_a = hallPassMessage.getRecipientPersonView()) === null || _a === void 0 ? void 0 : _a.getStudentId());
        item.setRecipientStudentIdFilename((_b = hallPassMessage.getRecipientPersonView()) === null || _b === void 0 ? void 0 : _b.getProfileImageUrl());
    }
    return item;
};
exports.fromProto = (msg) => {
    const id = msg.getId();
    const hallPassTypeId = msg.getTypeId();
    const startDate = shared_grpc_2.dateTimeMessageToDateObject(msg.getStartDateTime());
    const hallPass = {
        id,
        hallPassTypeId,
        startDate,
        studentCreated: msg.getStudentCreated(),
        startDateString: msg.getStartDateString(),
        endDateString: msg.getEndDateString(),
        isKiosk: msg.getIsKiosk(),
    };
    const currentClassList = msg.getCurrentClassesList();
    if (currentClassList === null || currentClassList === void 0 ? void 0 : currentClassList.length) {
        hallPass.currentClass = currentClassList.map(shared_grpc_1.StudentScheduleMapper.fromProto);
    }
    const recipientPerson = msg.getRecipientPersonView();
    if (recipientPerson) {
        hallPass.recipientPersonViewMinimal = recipientPerson.toObject();
    }
    const authorPerson = msg.getAuthorPersonView();
    if (authorPerson) {
        hallPass.authorPersonViewMinimal = authorPerson.toObject();
    }
    const approvedByPerson = msg.getApprovedByPersonView();
    if (approvedByPerson) {
        hallPass.approvedByPersonViewMinimal = approvedByPerson.toObject();
    }
    const endedByPerson = msg.getEndedByPersonView();
    if (endedByPerson) {
        hallPass.endedByPersonViewMinimal = endedByPerson.toObject();
    }
    const requestedByPerson = msg.getRequestedByPersonView();
    if (requestedByPerson) {
        hallPass.requestedByPersonViewMinimal = requestedByPerson.toObject();
    }
    const endDate = msg.getEndedTime();
    if (endDate) {
        hallPass.endDate = shared_grpc_2.dateTimeMessageToDateObject(endDate);
    }
    const expDate = msg.getExpiredDateTime();
    if (expDate) {
        hallPass.expiredDate = shared_grpc_2.dateTimeMessageToDateObject(expDate);
    }
    const deniedAt = msg.getDeniedTime();
    if (deniedAt) {
        hallPass.deniedAt = shared_grpc_2.dateTimeMessageToDateObject(deniedAt);
    }
    const deniedByPerson = msg.getDeniedByPerson();
    if (deniedByPerson) {
        hallPass.deniedByPerson = deniedByPerson;
    }
    const deniedByReason = msg.getDeniedByReason();
    if (deniedByReason) {
        hallPass.deniedByReason = deniedByReason;
    }
    const deniedByError = msg.getDeniedByError();
    if (deniedByError) {
        hallPass.deniedByError =
            ts_enum_util_1.$enum(domain_1.IHallPassErrorType).asValueOrThrow(deniedByError);
    }
    const passType = msg.getTypeInfo();
    if (passType) {
        hallPass.hallPassType = shared_grpc_1.HallPassTypeMapper.fromProto(passType);
    }
    const note = msg.getNote();
    if (note) {
        hallPass.note = note;
    }
    const noteToRequester = msg.getNoteToRequester();
    if (noteToRequester) {
        hallPass.noteToRequester = noteToRequester;
    }
    const approvalDate = msg.getApprovalDate();
    if (approvalDate) {
        hallPass.approvalDate = shared_grpc_2.dateTimeMessageToDateObject(approvalDate);
    }
    const status = msg.getStatus();
    if (status) {
        hallPass.status = ts_enum_util_1.$enum(domain_1.HallPassStatusEnum).asValueOrThrow(status);
    }
    const createdBy = msg.getCreatedBy();
    if (createdBy)
        hallPass.createdBy = createdBy;
    return hallPass;
};
exports.fromProtoEditable = (msg) => {
    const hallPass = exports.fromProto(msg.getHallPass());
    const updatedAtProto = msg.getManuallyUpdatedAt();
    return Object.assign(Object.assign({}, hallPass), { manuallyUpdatedAt: updatedAtProto
            ? shared_grpc_2.dateTimeMessageToDateObject(updatedAtProto)
            : undefined });
};