"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MingaSchoolMingaType = void 0;
const domain_1 = require("minga/libraries/domain/index");
const shared_1 = require("minga/libraries/shared/index");
const util_1 = require("minga/libraries/util/index");
const MingaType_1 = require("minga/libraries/shared/minga_type/internal/MingaType");
class MingaSchoolMingaType extends MingaType_1.MingaType {
    constructor() {
        super(...arguments);
        this.mingaType = util_1.MingaTypeValue.MINGA;
        this.availableRoles = {
            [domain_1.MingaRoleType.SUPERADMIN]: { weight: 99 },
            [domain_1.MingaRoleType.DISTRICT_MANAGER]: { weight: 11 },
            [domain_1.MingaRoleType.OWNER]: { weight: 10 },
            [domain_1.MingaRoleType.MANAGER]: { weight: 9 },
            [domain_1.MingaRoleType.DISTRICT_LEADER]: { weight: 8 },
            [domain_1.MingaRoleType.SCHOOL_MANAGER]: { weight: 7 },
            [domain_1.MingaRoleType.TEACHER]: { weight: 6 },
            [domain_1.MingaRoleType.STAFF]: { weight: 5 },
            [domain_1.MingaRoleType.STUDENT_LEADER]: { weight: 4 },
            [domain_1.MingaRoleType.STUDENT]: { weight: 3 },
            [domain_1.MingaRoleType.PARENT]: { weight: 2 },
            [domain_1.MingaRoleType.READ_ONLY]: { weight: 1 },
        };
        this.defaultSettings = [
            {
                name: util_1.mingaSettingTypes.FEATURE_ENABLE_GROUPS,
                value: true,
            },
            {
                name: util_1.mingaSettingTypes.FEATURE_MONTHLY_DIGEST_EMAIL,
                value: false,
            },
        ];
        this.canSelfManageAccountPlan = false;
        this.defaultVideoUploadRoleTypes = [
            domain_1.MingaRoleType.DISTRICT_MANAGER,
            domain_1.MingaRoleType.OWNER,
            domain_1.MingaRoleType.MANAGER,
            domain_1.MingaRoleType.SCHOOL_MANAGER,
            domain_1.MingaRoleType.TEACHER,
            domain_1.MingaRoleType.STAFF,
            domain_1.MingaRoleType.DISTRICT_LEADER,
        ];
        /* NOTE: if we add in new roles, make sure to
        change onAfterCreate in the Minga model to ensure
        they get added in the default settings for a newly
         created minga. */
        this.directMessagingRoleSettings = [
            {
                localeTitleKey: 'messagingSettings.teachersStaffOwnersManagers',
                immutable: true,
                roleGroups: [
                    [domain_1.MingaRoleType.OWNER, domain_1.MingaRoleType.OWNER],
                    [domain_1.MingaRoleType.TEACHER, domain_1.MingaRoleType.TEACHER],
                    [domain_1.MingaRoleType.MANAGER, domain_1.MingaRoleType.MANAGER],
                    [domain_1.MingaRoleType.STAFF, domain_1.MingaRoleType.STAFF],
                    [domain_1.MingaRoleType.SCHOOL_MANAGER, domain_1.MingaRoleType.SCHOOL_MANAGER],
                    [domain_1.MingaRoleType.DISTRICT_LEADER, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.OWNER, domain_1.MingaRoleType.TEACHER],
                    [domain_1.MingaRoleType.OWNER, domain_1.MingaRoleType.MANAGER],
                    [domain_1.MingaRoleType.OWNER, domain_1.MingaRoleType.STAFF],
                    [domain_1.MingaRoleType.OWNER, domain_1.MingaRoleType.SCHOOL_MANAGER],
                    [domain_1.MingaRoleType.OWNER, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.TEACHER, domain_1.MingaRoleType.MANAGER],
                    [domain_1.MingaRoleType.TEACHER, domain_1.MingaRoleType.SCHOOL_MANAGER],
                    [domain_1.MingaRoleType.TEACHER, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.TEACHER, domain_1.MingaRoleType.STAFF],
                    [domain_1.MingaRoleType.MANAGER, domain_1.MingaRoleType.STAFF],
                    [domain_1.MingaRoleType.MANAGER, domain_1.MingaRoleType.SCHOOL_MANAGER],
                    [domain_1.MingaRoleType.MANAGER, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.SCHOOL_MANAGER, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.DISTRICT_MANAGER, domain_1.MingaRoleType.OWNER],
                    [domain_1.MingaRoleType.DISTRICT_MANAGER, domain_1.MingaRoleType.MANAGER],
                    [domain_1.MingaRoleType.DISTRICT_MANAGER, domain_1.MingaRoleType.TEACHER],
                    [domain_1.MingaRoleType.DISTRICT_MANAGER, domain_1.MingaRoleType.STAFF],
                ],
            },
            {
                localeTitleKey: 'messagingSettings.studentsTeachersOwnersManagers',
                immutable: false,
                roleGroups: [
                    [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.TEACHER],
                    [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.STAFF],
                    [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.OWNER],
                    [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.MANAGER],
                    [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.SCHOOL_MANAGER],
                    [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.DISTRICT_MANAGER],
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.TEACHER],
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.STAFF],
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.OWNER],
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.MANAGER],
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.SCHOOL_MANAGER],
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.DISTRICT_MANAGER],
                ],
            },
            {
                localeTitleKey: 'messagingSettings.parentsTeachersOwnersManagers',
                immutable: false,
                roleGroups: [
                    [domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.TEACHER],
                    [domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.STAFF],
                    [domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.OWNER],
                    [domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.MANAGER],
                    [domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.SCHOOL_MANAGER],
                    [domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.DISTRICT_LEADER],
                    [domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.DISTRICT_MANAGER],
                ],
            },
            {
                localeTitleKey: 'messagingSettings.studentsAndStudentLeaders',
                immutable: false,
                roleGroups: [[domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.STUDENT_LEADER]],
            },
            {
                localeTitleKey: 'messagingSettings.studentLeaders',
                immutable: false,
                roleGroups: [
                    [domain_1.MingaRoleType.STUDENT_LEADER, domain_1.MingaRoleType.STUDENT_LEADER],
                ],
            },
            {
                localeTitleKey: 'messagingSettings.students',
                immutable: false,
                roleGroups: [[domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.STUDENT]],
            },
            {
                localeTitleKey: 'messagingSettings.parents',
                immutable: false,
                roleGroups: [[domain_1.MingaRoleType.PARENT, domain_1.MingaRoleType.PARENT]],
            },
        ];
    }
    getBillableRoles(isPricedPerDefaultRole = true) {
        if (isPricedPerDefaultRole) {
            return [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.STUDENT_LEADER].map(shared_1.getRole);
        }
        else {
            return [
                domain_1.MingaRoleType.DISTRICT_MANAGER,
                domain_1.MingaRoleType.OWNER,
                domain_1.MingaRoleType.MANAGER,
                domain_1.MingaRoleType.STUDENT,
                domain_1.MingaRoleType.TEACHER,
                domain_1.MingaRoleType.PARENT,
                domain_1.MingaRoleType.READ_ONLY,
                domain_1.MingaRoleType.STAFF,
                domain_1.MingaRoleType.STUDENT_LEADER,
                domain_1.MingaRoleType.SCHOOL_MANAGER,
                domain_1.MingaRoleType.DISTRICT_LEADER,
            ].map(shared_1.getRole);
        }
    }
    getDefaultRole() {
        return shared_1.getRole(domain_1.MingaRoleType.STUDENT);
    }
    getCreatorRole() {
        return shared_1.getRole(domain_1.MingaRoleType.OWNER);
    }
    getAllowedToJoinRoles() {
        return [domain_1.MingaRoleType.STUDENT, domain_1.MingaRoleType.PARENT].map(shared_1.getRole);
    }
    getInviteRole() {
        return shared_1.getRole(domain_1.MingaRoleType.TEACHER);
    }
    /**
     * Return display versions of School and District subscriptions, as the
     * database subscriptions are for internal business purposes, not for
     * customers.
     */
    getDisplaySubscriptions() {
        return [
            {
                planId: 'MingaSchool',
                name: 'School',
                planUrl: 'https://minga.io/minga-school-order/',
                annualFee: '$1499/year',
                mingaSize: null,
                groupSize: null,
                adminUserSize: null,
                premiumStudioTemplates: true,
                premiumPrograms: true,
                id: 10,
                pricedPerDefaultRole: true,
                annualSmsMessageQuota: 0,
            },
            {
                planId: 'MingaDistrict',
                name: 'District',
                planUrl: 'https://minga.io/minga-school-order/',
                annualFee: '$2.50/student/year',
                mingaSize: null,
                groupSize: null,
                adminUserSize: null,
                premiumStudioTemplates: true,
                premiumPrograms: true,
                id: 11,
                pricedPerDefaultRole: true,
                annualSmsMessageQuota: 0,
            },
        ];
    }
}
exports.MingaSchoolMingaType = MingaSchoolMingaType;