import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { grpc } from '@improbable-eng/grpc-web';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  interval,
  timer,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import { scrollIntoView } from 'minga/app/src/app/util/scroll-into-view';
import { IHallPassType } from 'minga/domain/hallPass';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';
import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';
import { PbisCategory } from 'minga/shared/pbis/constants';
import { mingaSettingTypes } from 'minga/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { ListMembershipService } from 'src/app/services/ListMembership';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { HpmDashboardTableItem } from '@modules/hallpass-manager';
import { PeopleUserListsEditModalData } from '@modules/people/components/people-userlists';
import { PeopleUserListsEditComponent } from '@modules/people/components/people-userlists/component/people-userslists-edit/people-userlists-edit.component';
import { PeopleUserListsPageStore } from '@modules/people/components/people-userlists/services';
import { PeopleRoute } from '@modules/people/types';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserListFilterService } from '@shared/components/user-list-filter/services/user-list-filter.service';
import { UserListCategory } from '@shared/components/user-list-filter/user-list.types';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';
import { MediaService } from '@shared/services/media';

import {
  ERROR_MESSAGES,
  FORM,
  FORM_FIELDS,
  LOCKED_STATES,
  MyClassMessages,
} from './constants/tt-my-class.constants';
import { MyClassActionsService } from './services/my-class-actions.service';
import { MyClassHallPassService } from './services/my-class-hallpasses.service';
import { MyClassPreferencesService } from './services/my-class-preferences.service';
import { mapActionGroupsToItems } from './services/my-class.utils';
import {
  AssignmentType,
  FormState,
  HallPassFormData,
} from './types/tt-my-class.types';

@Component({
  selector: 'mg-tt-my-class',
  templateUrl: './tt-my-class.component.html',
  styleUrls: ['./tt-my-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MyClassPreferencesService,
    MyClassHallPassService,
    PeopleUserListsPageStore,
  ],
})
export class TtMyClassComponent implements OnInit, OnDestroy {
  @ViewChildren('studentCard') studentCards!: QueryList<ElementRef>;

  public UserListCategory = UserListCategory;

  private _formState = new BehaviorSubject<FormState>('idle');
  public formState$ = this._formState.asObservable();

  public LOCKED_STATES: FormState[] = LOCKED_STATES;

  private _formErrorsSubject = new BehaviorSubject<string[]>([]);
  public formErrors$ = this._formErrorsSubject.asObservable();

  private _randomizerSubject = new BehaviorSubject<string>(null);
  public randomizer$ = this._randomizerSubject.asObservable();

  private _destroyedSubject = new ReplaySubject<void>(1);

  private _fetchingStudentsSubject = new BehaviorSubject<boolean>(false);
  public fetchingStudents$ = this._fetchingStudentsSubject.asObservable();

  private _mobileProgress = new BehaviorSubject<'select' | 'assign'>('select');
  public mobileProgress$ = this._mobileProgress.asObservable();

  private _assignSuccessSubject = new Subject<void>();
  public assignSuccess$ = this._assignSuccessSubject.asObservable();

  public PEOPLE_ROUTE = PeopleRoute;

  public form = this._fb.group(FORM());
  public FORM_FIELDS = FORM_FIELDS;
  public ASSIGNMENT_TYPE = AssignmentType;

  public MESSAGES = MyClassMessages;

  public actionGroups$ = this._actionsService.actionGroups$;

  public pastAssignments$ = this._actionsService.pastAssignments$;

  public authHash$ = this._authInfo.authPersonHash$;

  public hallPassesByStudent$ = combineLatest([
    this._myClassHallPassService.hallPassesByStudent$,
    this.actionGroups$,
  ]).pipe(
    map(([hallPassesByStudent, actionGroups]) => {
      const hallpassesWithType = new Map<
        string,
        HpmDashboardTableItem & { type: IHallPassType }
      >();
      hallPassesByStudent.forEach((value, key) => {
        const actions = mapActionGroupsToItems(actionGroups);
        const action = actions.find(
          a =>
            a.value === value.typeId &&
            a.assignmentType === AssignmentType.HALLPASS,
        );

        hallpassesWithType.set(key, {
          ...value,
          type: action?.data as any,
        });
      });

      return hallpassesWithType;
    }),
  );

  public listOptions$ = from(this._userListFilterService.getMyLists());

  private _debouncedSearchText$ = this.form
    .get(FORM_FIELDS.SEARCH_TEXT_FILTER)
    .valueChanges.pipe(
      map((searchText: string) => searchText.trim()),
      debounceTime(300),
      startWith(''),
      shareReplay(1),
      distinctUntilChanged(),
    );

  private _isMobile: boolean;

  private _studentList$: Observable<PersonViewMinimal.AsObject[]> = this.form
    .get(FORM_FIELDS.LIST_FILTER)
    .valueChanges.pipe(
      switchMap(async listId => {
        if (!listId) return [];

        this._fetchingStudentsSubject.next(true);

        this.form.get(FORM_FIELDS.SEARCH_TEXT_FILTER).setValue('');
        this.form.get(FORM_FIELDS.SELECTED_STUDENTS).setValue([]);

        try {
          const members = await this._listService.getMembersOfList(listId);
          return members;
        } catch (error) {
          if (error.code === grpc.Code.NotFound) {
            //if it's data not found error then reset the list filter
            this.form.get(FORM_FIELDS.LIST_FILTER).setValue(null);
          } else {
            //if it's system error then throw generic error
            this._systemAlertSnackBar.error(
              MyClassMessages.FETCH_STUDENTS_ERROR,
            );
          }
          return [];
        } finally {
          this._fetchingStudentsSubject.next(false);
        }
      }),
    );

  public filteredStudentList$: Observable<PersonViewMinimal.AsObject[]> =
    combineLatest([this._studentList$, this._debouncedSearchText$]).pipe(
      map(([studentList, searchText]) => {
        const currentlySelected = this.form.get(
          FORM_FIELDS.SELECTED_STUDENTS,
        ).value;
        const filteredList = searchText
          ? studentList.filter(student => {
              // if a student is currently selected always show them
              if (currentlySelected.includes(student.personHash)) {
                return true;
              }

              const searchVal = searchText.toLowerCase();
              const includesFirstName = student.firstName
                .toLowerCase()
                .includes(searchVal);

              const includesLastName = student.lastName
                .toLowerCase()
                .includes(searchVal);

              const includesStudentId = student.studentId
                .toLowerCase()
                .includes(searchVal);

              return includesFirstName || includesLastName || includesStudentId;
            })
          : [...studentList];

        return filteredList;
      }),
    );

  constructor(
    private _actionsService: MyClassActionsService,
    private _fb: FormBuilder,
    public mediaService: MediaService,
    private _listService: ListMembershipService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    public router: Router,
    private _preferencesService: MyClassPreferencesService,
    private _myClassHallPassService: MyClassHallPassService,
    private _systemSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService,
    private _viewContainerRef: ViewContainerRef,
    private _injector: Injector,
    private _authInfo: AuthInfoService,
    private _hallPassActions: HallPassActionsService,
    private _settingService: MingaSettingsService,
    private _userListFilterService: UserListFilterService,
  ) {}

  ngOnInit(): void {
    this._formState.next('loading');
    this._actionsService.fetchMyActions();
    this._myClassHallPassService.initFetchPolling();
    this._getPreferences();
    this._onLayoutTypeChange();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public toggleSelectAll(students: PersonViewMinimal.AsObject[]) {
    const control = this.form.get(FORM_FIELDS.SELECTED_STUDENTS);
    const hashes =
      control.value.length === students.length
        ? []
        : students.map(s => s.personHash);
    control.setValue(hashes);
  }

  public selectRandom(students: PersonViewMinimal.AsObject[]) {
    this.form.get(FORM_FIELDS.SELECTED_STUDENTS).setValue([]);
    const DURATION_MS = 2500;
    const INTERVAL_MS = 250;
    const prefersReducedMotion =
      window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    const hashes = students.map(s => s.personHash);
    const getRandomHash = () => {
      const index = Math.floor(Math.random() * hashes.length);
      return hashes[index];
    };

    const setRandomizer = () => {
      const hash = getRandomHash();
      this._randomizerSubject.next(hash);
    };

    const finishedRandomizing = () => {
      this._randomizerSubject.next(null);
      const hash = getRandomHash();
      this.form.get(FORM_FIELDS.SELECTED_STUDENTS).setValue([hash]);
      this._formState.next('idle');

      const selectedCard = this.studentCards
        .toArray()
        .find(el => el.nativeElement.getAttribute('data-hash') === hash);

      if (selectedCard) {
        scrollIntoView(selectedCard.nativeElement, {
          align: { top: 0, topOffset: this._isMobile ? 0 : 120 },
        });
      }
    };

    this._formState.next('randomizing');

    if (prefersReducedMotion) {
      return finishedRandomizing();
    }

    const stopPolling$ = new Subject();

    setRandomizer();

    interval(INTERVAL_MS)
      .pipe(takeUntil(stopPolling$))
      .subscribe(() => {
        setRandomizer();
      });

    timer(DURATION_MS)
      .pipe(takeUntil(stopPolling$))
      .subscribe(() => {
        stopPolling$.next();
        stopPolling$.complete();
        finishedRandomizing();
      });
  }

  public trackByFn(index: number, item: any) {
    return item.personHash || index;
  }

  public toggleSelected($event, student: PersonViewMinimal.AsObject) {
    const control = this.form.get(FORM_FIELDS.SELECTED_STUDENTS);

    if ($event) {
      control.setValue([...control.value, student.personHash]);
    } else {
      control.setValue(
        control.value.filter(hash => hash !== student.personHash),
      );
    }
  }

  public setMobileProgress(progress: 'select' | 'assign') {
    this._mobileProgress.next(progress);
  }

  public async assign() {
    const state = this._formState.value;
    if (state === 'loading' || state === 'submitting') return;

    this._formErrorsSubject.next([]);

    if (!this.form.valid) {
      this._setInvalidFields();
      this._formState.next('error');
      return;
    }

    this._formState.next('submitting');

    try {
      const formData = this.form.value;
      const response = await this._actionsService.assign(formData);
      this._actionsService.saveAssignment(formData);

      const type = formData.selectedAction.assignmentType;

      let successMessageType = '';

      if (type === AssignmentType.HALLPASS) {
        successMessageType = 'Hall pass';
        this._myClassHallPassService.addPasses(
          response as HallPassWithType.AsObject[],
        );

        const subGroup = formData[AssignmentType.HALLPASS] as HallPassFormData;
        const approveBy = subGroup.approvedBy;

        if (approveBy) {
          const hallPassName = formData.selectedAction.data as IHallPassType;
          this._openPendingDialog(
            approveBy,
            hallPassName.name,
            response as HallPassWithType.AsObject[],
          );
        }
      }

      if (type === AssignmentType.BEHAVIOR) {
        successMessageType =
          formData.selectedAction.data.categoryId === PbisCategory.PRAISE
            ? 'Praise'
            : 'Behavior';
      }

      if (type === AssignmentType.CONSEQUENCE) {
        successMessageType = 'Consequence';
      }

      this._resetAfterAssignment();

      this._assignSuccessSubject.next();

      this._systemSnackBar.open({
        type: 'success',
        message: `${successMessageType} assigned`,
      });
    } catch (error) {
      // the underlying hall pass service handles it's own error messages so users dont need to be shown a generic error
      if (error.name === AssignmentType.HALLPASS) {
        return;
      }

      this._systemSnackBar.open({
        type: 'error',
        message: MyClassMessages.ASSIGNING_ACTION_ERROR,
      });
    } finally {
      this._formState.next('idle');
    }
  }

  public async createUserList() {
    const modalRef = this._modalOverlay.open<PeopleUserListsEditModalData>(
      PeopleUserListsEditComponent,
      {
        data: {
          id: null,
          injector: this._injector,
        },
        disposeOnNavigation: false,
      },
      this._viewContainerRef,
    );
    const response = await modalRef.afterClosed.toPromise();

    if (response.data.userList) {
      // lets make the list active
      const listControl = this.form.get(FORM_FIELDS.LIST_FILTER);
      listControl.setValue(response.data.userList.id);
      listControl.updateValueAndValidity();
    }
  }

  public userListChanged(val?: number | number[]) {
    this.form.get(FORM_FIELDS.LIST_FILTER).setValue((val as number) || null);
  }

  private _resetAfterAssignment() {
    this.form.get(FORM_FIELDS.SELECTED_STUDENTS).setValue([]);
    this.setMobileProgress('select');
  }

  private _setInvalidFields() {
    const invalidFields = [];
    const controls = this.form.controls;
    for (const fieldName in controls) {
      if (controls[fieldName].invalid) {
        const error = ERROR_MESSAGES[fieldName] || 'Required field missing';
        invalidFields.push(error);
      }
    }

    this._formErrorsSubject.next(invalidFields);
  }

  private _getPreferences() {
    combineLatest([
      this.actionGroups$,
      this._actionsService.actionsGroupsLoading$,
    ])
      .pipe(
        takeUntil(this._destroyedSubject),
        debounceTime(300),
        filter(([actionGroups, isLoading]) => !isLoading),
        take(1),
      )
      .subscribe(async ([actionGroups]) => {
        this._formState.next('idle');
        const savedPreferences = await this._preferencesService.get();
        this._listenForPreferenceChanges();
        const actions = mapActionGroupsToItems(actionGroups);
        this._preferencesService.apply({
          actions,
          preferences: savedPreferences,
          form: this.form,
        });
      });
  }

  private _listenForPreferenceChanges() {
    this.form.valueChanges
      .pipe(takeUntil(this._destroyedSubject), debounceTime(300))
      .subscribe(values => {
        this._preferencesService.save(values);
      });
  }

  private _onLayoutTypeChange() {
    this.mediaService.isMobileView$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(isMobile => {
        this._isMobile = isMobile;
        this.setMobileProgress('select');
      });
  }

  private async _openPendingDialog(
    teacher: {
      name: string;
      hash: string;
    },
    hallPassName: string,
    passes: HallPassWithType.AsObject[],
  ) {
    const timeoutMins = parseInt(
      await this._settingService.getSettingValue(
        mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION_STAFF,
      ),
      10,
    );

    const timeInSecs = timeoutMins * 60;

    const recipients = passes.map(pass => {
      const { hallPass } = pass;
      const passId = hallPass.id;
      return {
        name: `${hallPass.recipientPersonView.firstName} ${hallPass.recipientPersonView.lastName}`,
        passId,
      };
    });

    await this._hallPassActions.showPendingCancellationDialog(
      recipients,
      hallPassName,
      teacher.name,
      timeInSecs,
      {
        onCancel: passId => {
          this._myClassHallPassService.removePass(passId);
        },
      },
    );
  }
}
