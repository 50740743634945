export enum FiltersFormMessage {
  BUTTON_LABEL_FILTERS = 'Filters',
}

export const FILTERS_FORM_FIELD_TYPE = {
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  TOGGLE_GROUP: 'toggle-group',
  TEXT: 'text',
  USER_LIST: 'user-list',
  PEOPLE_SEARCH: 'people-search',
} as const;
