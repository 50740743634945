<button
  matRipple
  class="pill"
  type="button"
  [attr.data-analytics]="id"
  [attr.data-test]="id"
  [ngClass]="classes">
  <mg-icon
    *ngIf="icon"
    class="pill-icon"
    [iconName]="icon">
  </mg-icon>
  <mg-text
    *ngIf="text"
    class="pill-text"
    variant="label-md-secondary">
    <span>{{ text }}</span>
  </mg-text>
</button>
