import {
  RestrictionErrorMinimal,
  RESTRICTION_ERRORS,
} from 'minga/libraries/domain';

import {
  PsFormName,
  PsSummaryErrorsData,
} from '@modules/people-selector/types';

import { CloseTimeoutDuration } from '@shared/components/system-alert-modal';

import { PeopleSelectorFormService } from '../ps-form.service';

type CheckType = 'in' | 'out';

export abstract class PsCheckInAbstractService<
  T extends PsFormName = any,
> extends PeopleSelectorFormService<T> {
  protected async _handleError({
    successPeopleHashes,
    errors,
    reasonName,
    preventBypass = false,
    checkType = 'in',
    showProfilePicture = false,
  }: {
    successPeopleHashes: string[];
    errors: RestrictionErrorMinimal[];
    reasonName: string;
    preventBypass?: boolean;
    checkType?: CheckType;
    showProfilePicture?: boolean;
  }): Promise<string[]> {
    if (errors.length === 1 && successPeopleHashes.length === 0) {
      const { personHash, code } = errors[0];
      const person = this.selection.getSelected(personHash);
      const title = person.displayName;
      const profilePicture = showProfilePicture && person?.profilePicture;
      const result = await this.peopleSelector.openDialog({
        type: 'error',
        audioAlerts: true,
        title,
        subTitle: RESTRICTION_ERRORS[code].title || `Check ${checkType} denied`,
        message: reasonName,
        closeTimeout: CloseTimeoutDuration.LONG,
        canContinue: !preventBypass,
        extendedMessage:
          RESTRICTION_ERRORS[code].message ||
          'Some unknown error has occured while trying to process your request',
        profilePicture,
      });

      if (result?.action === 'continue') {
        return [personHash];
      } else {
        this.cleanup();
        return [];
      }
    } else {
      const errorsData = this._makeErrorData(successPeopleHashes, errors);
      const hashes = await this.peopleSelector.openSummary({
        message: `could not be checked ${checkType}`,
        title: `Check ${checkType} summary`,
        subMessage: preventBypass
          ? ''
          : `Select the people you want to “Check ${checkType}”`,
        submitLabel: `Check ${checkType}`,
        errorsData,
        displayOnly: preventBypass,
      });
      if (!hashes.length) return [];
      return hashes;
    }
  }

  protected _makeErrorData(
    successPersonHashes: string[],
    errorPersonHashes?: RestrictionErrorMinimal[],
    checkType: CheckType = 'in',
  ): PsSummaryErrorsData[] {
    const success = successPersonHashes.map(hash => {
      const person = this.selection.getSelected(hash);
      return {
        hash: person.personHash,
        name: person.displayName,
        status: 'success',
        reasons: [`Checked ${checkType}`],
      } as PsSummaryErrorsData;
    });
    const errors = errorPersonHashes.map(({ personHash, code }) => {
      const person = this.selection.getSelected(personHash);
      return {
        hash: person.personHash,
        name: person.displayName,
        status: 'error',
        reasons: [RESTRICTION_ERRORS[code].title],
      } as PsSummaryErrorsData;
    });
    return [...success, ...errors];
  }

  protected async _showSuccessDialog({
    reasonName,
    selectionHashes,
    checkType = 'in',
    showProfilePicture = false,
  }: {
    selectionHashes: string[];
    reasonName: string;
    checkType?: CheckType;
    showProfilePicture?: boolean;
  }): Promise<void> {
    const hasManyPeople = selectionHashes.length > 1;
    const firstPerson = this.selection.getSelected(selectionHashes[0]);
    const profilePicture = showProfilePicture && firstPerson?.profilePicture;
    await this.peopleSelector.openDialog({
      type: 'success',
      audioAlerts: true,
      title: hasManyPeople ? undefined : firstPerson?.displayName || '',
      subTitle: hasManyPeople
        ? `${selectionHashes.length} people checked ${checkType}`
        : `Checked ${checkType}`,
      message: reasonName,
      closeTimeout: profilePicture
        ? CloseTimeoutDuration.MEDIUM
        : CloseTimeoutDuration.SHORT,
      profilePicture,
    });
  }
}
