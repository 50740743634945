"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const flex_time_stats_pb_1 = require("minga/proto/flex_time_stats/flex_time_stats_pb");
exports.toProto = (filter, limit, offset, onlyOnePeriod) => {
    const filterMessage = new flex_time_stats_pb_1.FlexReportFilters();
    filterMessage.setOffset(offset || 0);
    if (limit)
        filterMessage.setLimit(limit);
    if (filter.startDate)
        filterMessage.setStartDate(shared_grpc_1.dateObjectToDateTimeMessage(filter.startDate.toDate()));
    if (filter.endDate)
        filterMessage.setEndDate(shared_grpc_1.dateObjectToDateTimeMessage(filter.endDate.toDate()));
    if (filter.registrant)
        filterMessage.setRegistrantHashList(filter.registrant);
    if (filter.periods)
        filterMessage.setPeriodIdList(onlyOnePeriod ? filter.periods.slice(0, 1) : filter.periods);
    if (filter.activities)
        filterMessage.setActivityIdList(filter.activities);
    if (filter.checkinStatus)
        filterMessage.setCheckinStatus(filter.checkinStatus);
    filterMessage.setActivityIdList(filter.activities);
    filterMessage.setUserListList(filter.userList);
    return filterMessage;
};
exports.fromProto = (proto) => {
    const checkinStatusProto = proto.getCheckinStatus();
    let checkinStatus;
    if (checkinStatusProto)
        checkinStatus =
            ts_enum_util_1.$enum(domain_1.FlexCheckInStatuses).asValueOrThrow(checkinStatusProto);
    const startDateProto = proto.getStartDate();
    const endDateProto = proto.getEndDate();
    let startDate;
    let endDate;
    if (startDateProto)
        startDate = shared_grpc_1.dateTimeMessageToDayjsObject(startDateProto);
    if (endDateProto)
        endDate = shared_grpc_1.dateTimeMessageToDayjsObject(endDateProto);
    return {
        startDate,
        endDate,
        registrant: proto.getRegistrantHashList(),
        periods: proto.getPeriodIdList(),
        activities: proto.getActivityIdList(),
        checkinStatus,
        limit: proto.getLimit(),
        offset: proto.getOffset(),
        userList: proto.getUserListList(),
    };
};