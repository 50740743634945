import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { MINGA_APP_COLORS } from '@shared/constants';

export const PREFINED_COLORS = MINGA_APP_COLORS.filter(c => c);
export type PredefinedColors = typeof PREFINED_COLORS[number];

@Component({
  selector: 'mg-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericChipComponent implements OnDestroy {
  @ViewChild('button') button: ElementRef;

  /** General Subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Inputs */
  @Input() subTitle: string;
  @Input() icon: string;
  @Input() iconRight: string;
  @Input() iconColor: PredefinedColors;
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Input() showCheckmark = true;
  @Input() addOnLeft: string;
  @Input() size: 'medium' | 'large' = 'medium';
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  /** Outputs */
  @Output() pressed = new EventEmitter<void>();

  @HostListener('click') onClick() {
    if (this.disabled) return;
    this.pressed.emit();
    this.button.nativeElement.blur();
  }

  /** Component Constructor */
  constructor() {}

  get classes() {
    const classes = [];

    classes.push(this.size || 'medium');

    if (this.subTitle) {
      classes.push('has-sub');
    }
    if (this.active) {
      classes.push('active');
    }
    if (this.disabled) {
      classes.push('disabled');
    }

    return classes.join(' ');
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
