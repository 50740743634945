import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import {
  BehaviorSubject,
  ReplaySubject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  skip,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  FlexTimeActivityInstance,
  FlexTimePeriod,
} from 'minga/libraries/domain';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

import { FlexTimeManagerService } from '@modules/flex-time-manager/services';

import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { PaginatorComponent } from '@shared/components/paginator';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';
import { MediaService } from '@shared/services/media';

import {
  FlexTimeManagerMessages,
  FlexTimeManagerRoutes,
} from '../../constants';
import {
  FTM_PERIODS_DISPLAY_COLUMNS,
  FtmPeriodsMessage as FtmPeriodsEditMessages,
  FtmPeriodsMessages,
  MOBILE_BREAKPOINTS,
  PeriodLockingIconSizes,
  PeriodLockingIcons,
} from './constants';
import { FtmPeriodsService } from './services';
import { FtmPeriodsDateFilter } from './types';

const DEFAULT_DATE_RANGE = {
  startDate: day(),
  endDate: day().add(1, 'month'),
};

@Component({
  selector: 'mg-ftm-periods',
  templateUrl: './ftm-periods.component.html',
  styleUrls: ['./ftm-periods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmPeriodsComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatTable) matTable: MatTable<FlexTimePeriod>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(PaginatorComponent)
  paginator: PaginatorComponent;

  /** Constants */
  public readonly FTM_MSG = FlexTimeManagerMessages;
  public readonly MSG = FtmPeriodsMessages;
  public readonly EDIT_MSG = FtmPeriodsEditMessages;
  public readonly PERIOD_LOCKS = PeriodLockingIcons;

  /** General Observables */
  private readonly _destroyed = new ReplaySubject<void>(1);

  /** Loading */
  private readonly _isLoading = new BehaviorSubject(true);
  public readonly isLoading$ = this._isLoading
    .asObservable()
    .pipe(shareReplay());

  /** Can Edit */
  private readonly _canEdit = new BehaviorSubject(false);
  public readonly canEdit$ = this._canEdit
    .asObservable()
    .pipe(takeUntil(this._destroyed), shareReplay());

  /** Search Filter */
  public readonly searchControl = this._fb.control('');
  private _hasSearchValue = false;
  private readonly _debouncedSearchValue = this.searchControl.valueChanges.pipe(
    takeUntil(this._destroyed),
    debounceTime(300),
    shareReplay(1),
    distinctUntilChanged(),
    tap(value => (this._hasSearchValue = value.length > 0)),
  );

  /** Date Range Filter */
  public range = initializeRange({
    start: {
      value: DEFAULT_DATE_RANGE.startDate,
    },
    end: {
      value: DEFAULT_DATE_RANGE.endDate,
    },
  });

  /** FlexTime Periods Data */
  private _periods = new BehaviorSubject<FlexTimePeriod[]>([]);
  public readonly periods$ = this._periods.asObservable().pipe(shareReplay());

  /** Table Data */
  public readonly dataSource = new MatTableDataSource<FlexTimePeriod>([]);
  private _dataSub: Subscription;
  public readonly displayedColumns$ = combineLatest([
    this.media.breakpoint$,
    this.canEdit$,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([breakpoint, canEdit]) => {
      let columns = [...FTM_PERIODS_DISPLAY_COLUMNS];
      if (canEdit) columns = [...columns, 'totalRegistered', 'clone', 'edit'];
      const displayedColumns = MOBILE_BREAKPOINTS.includes(breakpoint)
        ? ['mobile']
        : columns || [];

      return displayedColumns;
    }),
  );

  /** Empty State */
  get showEmptyState() {
    return this._hasSearchValue
      ? !this.dataSource?.filteredData?.length
      : !this.dataSource?.data?.length;
  }

  /** Other */
  public lockIconSize$ = this.media.breakpoint$.pipe(
    map(breakpoint => {
      return MOBILE_BREAKPOINTS.includes(breakpoint)
        ? PeriodLockingIconSizes.LARGE
        : PeriodLockingIconSizes.MEDIUM;
    }),
  );

  /** Component Constructor */
  constructor(
    public flexPeriods: FtmPeriodsService,
    public media: MediaService,
    public ftManager: FlexTimeManagerService,
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _flexPermissionsService: FlexTimePermissionsService,
    private _router: Router,
    protected authInfo: AuthInfoService,
  ) {
    this._canEdit.next(this._flexPermissionsService.isFlexTimeAdmin());
    this._initialLoad();
    this._dataSub = this.flexPeriods.periods$
      .pipe(takeUntil(this._destroyed))
      .subscribe(periods => {
        this.dataSource.data = periods;
        this._cdr.markForCheck();
      });
    this._debouncedSearchValue.subscribe(value => {
      this.dataSource.filter = value.trim().toLowerCase() || '';
      this._cdr.markForCheck();
    });

    this.range.valueChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(async range => {
        await this.flexPeriods.fetchAll(range.start, range.end);
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator.matPaginator;
    this.dataSource.sort = this.sort;
    this.sort.sort({
      id: 'date',
      start: 'asc',
      disableClear: false,
    });
    this.dataSource.filterPredicate = this._tableFilter;
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._canEdit.complete();
    this._dataSub.unsubscribe();
  }

  public clone(id: number): void {
    this.flexPeriods.openModal(id, true);
  }

  public goToActivities(period: FlexTimePeriod): void {
    const { start, end } = this.range.value;

    this.ftManager.setPeriodFilter(period.id);

    this._router.navigate(
      [`/${FlexTimeManagerRoutes.ROOT}`, FlexTimeManagerRoutes.ACTIVITIES],
      {
        state: {
          startDate: start.format('YYYY-MM-DD'),
          endDate: end.format('YYYY-MM-DD'),
        },
      },
    );
  }

  public openMyActivityEdit(period: FlexTimePeriod): void {
    const { id } = period;
    const { start, end } = this.range.value;

    const myInstance = period.activityInstances.find(
      a =>
        a.flexTimeActivity?.createdByPerson.hash ===
        this.authInfo.authPersonHash,
    );
    const currentInstanceId = myInstance ? myInstance.id : null;
    this.flexPeriods.openActivityEditModal(
      id,
      start,
      end,
      currentInstanceId,
      true,
    );
  }

  public trackById(index: number, item: FlexTimePeriod) {
    return item ? item.id : index;
  }

  public getMyActivity(activities: FlexTimeActivityInstance[]): string {
    const activity = activities.find(
      a =>
        a.flexTimeActivity?.createdByPerson.hash ===
        this.authInfo.authPersonHash,
    );
    return activity?.flexTimeActivity?.name || '';
  }

  public isInPast(data: FlexTimePeriod): boolean {
    const date = day(data.date).format('MMM DD YYYY');
    const parsed = `${date} ${data.endTime}`;

    const periodDate = day(parsed, 'MMM DD YYYY HH:mm:ss');

    return periodDate.isBefore(day(), 'minute');
  }

  private _tableFilter(period: FlexTimePeriod, titleString: string) {
    if (!titleString.length) return true;
    if (!titleString || period.title.toLowerCase().includes(titleString))
      return true;
    return false;
  }

  private async _initialLoad(): Promise<void> {
    this._isLoading.next(true);
    const { start, end } = this.range.value;
    await this.flexPeriods.fetchAll(start, end);
    this._isLoading.next(false);
  }
}
