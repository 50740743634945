import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MingaPermission } from 'minga/libraries/domain';
import { ReportTypes } from 'minga/libraries/domain';
import { mingaSettingTypes } from 'minga/libraries/util';
import { MingaAppRoutes } from 'src/app/types';

import { AccessRestrictionsGuard } from '@shared/guards';

import {
  PmReportsHistoryComponent,
  PmReportsRewardsComponent,
  PmReportsStudentComponent,
  PmReportsTeamComponent,
} from './components/pm-reports/components';
import { PmReportsRedemptionHistoryComponent } from './components/pm-reports/components/pm-reports-points-redeemed/pm-reports-redemption-history.component';
import { PmReportsComponent } from './components/pm-reports/pm-reports.component';
import { PmReportsModule } from './components/pm-reports/pm-reports.module';
import { PmRewardsComponent } from './components/pm-rewards/pm-rewards.component';
import { PmRewardsModule } from './components/pm-rewards/pm-rewards.module';
import { PmSettingsComponent } from './components/pm-settings/pm-settings.component';
import { PmSettingsModule } from './components/pm-settings/pm-settings.module';
import { PmTeamsComponent } from './components/pm-teams/pm-teams.component';
import { PmTeamsModule } from './components/pm-teams/pm-teams.module';
import { PointsManagerRoutes } from './constants';
import { PointsManagerComponent } from './points-manager.component';
import { PointsManagerModule } from './points-manager.module';

export const POINTS_MANAGER_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: PointsManagerComponent,
    data: {
      title: 'Points',
    },
    children: [
      {
        path: '',
        component: PmTeamsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Teams',
          description: 'View and manage school teams',
          enableAutoRedirect: true,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.POINTS_MANAGE],
            },
          },
        },
      },
      {
        path: PointsManagerRoutes.REPORTS,
        component: PmReportsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Reports',
          description: 'View and manage points reports',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.POINTS_VIEW_REPORTS],
            },
          },
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: ReportTypes.POINTS_HISTORY,
          },
          {
            path: ReportTypes.POINTS_HISTORY,
            component: PmReportsHistoryComponent,
            data: {
              title: 'History',
            },
          },
          {
            path: ReportTypes.POINTS_SUMMARY,
            component: PmReportsStudentComponent,
            data: {
              title: 'Summary',
            },
          },
          {
            path: ReportTypes.POINTS_TEAM_SUMMARY,
            component: PmReportsTeamComponent,
            data: {
              title: 'Team summary',
            },
          },
          {
            path: ReportTypes.POINTS_REWARDS_REDEEMED,
            component: PmReportsRewardsComponent,
            data: {
              title: 'Rewards redeemed',
            },
          },
          {
            path: ReportTypes.POINTS_REDEEMED,
            component: PmReportsRedemptionHistoryComponent,
            data: {
              title: 'Points redeemed',
            },
          },
        ],
      },
      {
        path: PointsManagerRoutes.REWARDS,
        canActivate: [AccessRestrictionsGuard],
        component: PmRewardsComponent,
        data: {
          title: 'Rewards',
          description: 'View and manage rewards',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.POINTS_MANAGE],
            },
            settings: {
              only: [mingaSettingTypes.PM_REWARDS_STORE],
            },
          },
        },
      },
      {
        path: PointsManagerRoutes.SETTINGS,
        canActivate: [AccessRestrictionsGuard],
        component: PmSettingsComponent,
        data: {
          title: 'Settings',
          description: 'View and manage settings',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    PointsManagerModule,
    PmReportsModule,
    PmRewardsModule,
    PmTeamsModule,
    PmSettingsModule,
    RouterModule.forChild(POINTS_MANAGER_ROUTES),
  ],
})
export class PointsManagerRoutesModule {}
