import { Directive, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { MingaAffiliationType } from 'minga/libraries/domain';
import { MingaManagerService } from 'src/app/services/MingaManager';

import { FormSelectOption } from '@shared/components/form';

import { FormSelectComponent } from '../components/form-select/form-select.component';

/**
 * Minga Partner Search
 * Directive is to be used with form-select component
 */
@Directive({
  selector: '[mingaPartnerSearch]',
  exportAs: 'mingaPartnerSearch',
})
export class MingaPartnerSearchDirective {
  private _isLoading$: BehaviorSubject<boolean>;

  @Input()
  mingaPartnerSearch: MingaAffiliationType;

  constructor(
    private _host: FormSelectComponent,
    private _mingaService: MingaManagerService,
  ) {
    this._isLoading$ = this._host.isLoading$;
  }

  ngOnInit(): void {
    this._isLoading$.next(true);
    this._initList();
  }

  private async _initList() {
    const list =
      this.mingaPartnerSearch === MingaAffiliationType.PARTNER
        ? await this._mingaService.fetchMingaPartners()
        : await this._mingaService.fetchMingaDistricts();

    const selectOptions: FormSelectOption<number>[] = list.map(result => {
      return {
        label: result.name,
        value: result.id,
      };
    });

    this._host.options = selectOptions;
    this._isLoading$.next(false);
    this._host.refreshSelectMenu();
  }
}
