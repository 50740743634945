"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.fromMinimalProto = exports.toMinimalProto = exports.toProto = void 0;
const flex_time_pb = require("minga/proto/flex_time/flex_time_pb");
const _1 = require("minga/libraries/shared-grpc/flex_time/index");
const common_1 = require("minga/libraries/shared-grpc/common/index");
exports.toProto = (period, useUTC = true) => {
    var _a;
    const message = new flex_time_pb.FlexTimePeriod();
    if (period.mingaId)
        message.setMingaId(period.mingaId);
    if (period.id)
        message.setId(period.id);
    if (period.title)
        message.setTitle(period.title);
    if (period.date)
        message.setDate(common_1.DateTimeMapper.toProto(period.date, useUTC));
    if (period.startTime)
        message.setStartTime(period.startTime);
    if (period.endTime)
        message.setEndTime(period.endTime);
    if (period.totalSpaces)
        message.setTotalSpaces(period.totalSpaces);
    message.setEnableActivityManagement(period.enableActivityManagement);
    message.setEnableStudentManagement(period.enableStudentManagement);
    message.setEnableStudentSelfManagement(period.enableStudentSelfManagement);
    if ((_a = period.activityInstances) === null || _a === void 0 ? void 0 : _a.length) {
        message.setActivityInstancesList(period.activityInstances.map(_1.FlexTimeActivityInstanceMapper.toProto));
    }
    return message;
};
exports.toMinimalProto = (period, useUTC = true) => {
    const message = new flex_time_pb.MinimalFlexTimePeriod();
    if (period.id)
        message.setId(period.id);
    if (period.title)
        message.setTitle(period.title);
    if (period.date)
        message.setDate(common_1.DateTimeMapper.toProto(period.date, useUTC));
    if (period.startTime)
        message.setStartTime(period.startTime);
    if (period.endTime)
        message.setEndTime(period.endTime);
    message.setEnableActivityManagement(period.enableActivityManagement);
    message.setEnableStudentManagement(period.enableStudentManagement);
    message.setEnableStudentSelfManagement(period.enableStudentSelfManagement);
    return message;
};
exports.fromMinimalProto = (message, useUTC = true) => {
    return {
        id: message.getId(),
        title: message.getTitle(),
        date: common_1.DateTimeMapper.fromProto(message.getDate(), useUTC),
        startTime: message.getStartTime(),
        endTime: message.getEndTime(),
        enableActivityManagement: message.getEnableActivityManagement(),
        enableStudentManagement: message.getEnableStudentManagement(),
        enableStudentSelfManagement: message.getEnableStudentSelfManagement(),
    };
};
exports.fromProto = (message, useUTC = true) => {
    return {
        mingaId: message.getMingaId(),
        id: message.getId(),
        title: message.getTitle(),
        date: common_1.DateTimeMapper.fromProto(message.getDate(), useUTC),
        startTime: message.getStartTime(),
        endTime: message.getEndTime(),
        totalSpaces: message.getTotalSpaces(),
        enableActivityManagement: message.getEnableActivityManagement(),
        enableStudentManagement: message.getEnableStudentManagement(),
        enableStudentSelfManagement: message.getEnableStudentSelfManagement(),
        activityInstances: message
            .getActivityInstancesList()
            .map(_1.FlexTimeActivityInstanceMapper.fromProto),
    };
};