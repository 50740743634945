"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestrictionError = void 0;
const RestrictionError_constants_1 = require("minga/libraries/domain/restrictions/RestrictionError.constants");
class RestrictionError extends Error {
    constructor(type, code, person) {
        const { message } = RestrictionError_constants_1.RESTRICTION_ERRORS[code];
        super(message);
        this.name = 'RestrictionError';
        this.type = type;
        this.code = code;
        this.person = person;
    }
}
exports.RestrictionError = RestrictionError;