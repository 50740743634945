import { Injectable, OnDestroy } from '@angular/core';

import * as day from 'dayjs';
import { BehaviorSubject, NEVER, ReplaySubject, Subject, timer } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

import {
  FlexTimeActivityInstance,
  FlexTimePeriod,
} from 'minga/libraries/domain';
import { UserStorage } from 'src/app/services/UserStorage';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimePeriodService } from '@shared/services/flex-time';

import {
  AUTO_REFRESH_STATE,
  FtmDashboardMessages,
  POLLING_INTERVAL,
} from '../constants';

export interface FlexInstanceTile {
  id: number;
  registered: number;
  spaces: number;
  name: string;
  flexTimeActivityId: number;
  flexTimePeriodId: number;
  absentees?: number;
  icon?: string;
  color?: string;
  location?: string;
  teacherName?: string;
  teacherHash?: string;
  startTime?: string;
  endTime?: string;
}

@Injectable()
export class FtmDashboardService implements OnDestroy {
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  private readonly _dashboardRefreshSubj = new Subject<void>();

  private _isLoadingSubj = new BehaviorSubject(null);
  public isLoading$ = this._isLoadingSubj.asObservable();

  private _flexPeriodsSubj = new BehaviorSubject<FlexTimePeriod[]>([]);
  public flexPeriods$ = this._flexPeriodsSubj.asObservable();

  private readonly _periodFilterSubj = new BehaviorSubject<number>(null);
  public readonly periodFilter$ = this._periodFilterSubj
    .asObservable()
    .pipe(distinctUntilChanged());

  private readonly _pollStateSubj = new BehaviorSubject<boolean>(false);
  public readonly pollState$ = this._pollStateSubj.asObservable();
  private _dateFilter = day();

  constructor(
    private _flexPeriods: FlexTimePeriodService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _userStorage: UserStorage,
  ) {
    this._initPolling();

    this._dashboardRefreshSubj
      .asObservable()
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(() => {
        this.fetchData(true);
      });

    this.pollState$
      .pipe(
        takeUntil(this._destroyedSubj),
        switchMap(isEnabled => {
          return isEnabled ? timer(0, POLLING_INTERVAL) : NEVER;
        }),
      )
      .subscribe(() => {
        this._dashboardRefreshSubj.next();
      });
  }

  public async fetchData(isRefresh = false, date?: day.Dayjs) {
    try {
      this._isLoadingSubj.next(true);
      if (date) this._dateFilter = date;
      const start = this._dateFilter;
      const end = this._dateFilter;

      const periods = await this._flexPeriods.fetchAll(start, end, false, true);

      this._flexPeriodsSubj.next(periods);
      const initialPeriod =
        this._flexPeriods.getCurrentlyInProgressPeriod(periods);

      if (initialPeriod && !isRefresh) {
        this.setPeriodFilter(initialPeriod.id);
      }
    } catch (e) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmDashboardMessages.FETCHING_PERIODS_ERROR,
      });
    } finally {
      this._isLoadingSubj.next(false);
    }
  }

  public mapInstanceToTile(
    instance: FlexTimeActivityInstance,
    period: FlexTimePeriod,
  ): FlexInstanceTile {
    return {
      id: instance.id,
      registered: instance.registered || 0,
      spaces: instance.spaces || 0,
      name: instance.flexTimeActivity?.name || 'Flex Activity',
      absentees: instance.absentees || 0,
      flexTimeActivityId: instance.flexTimeActivityId,
      flexTimePeriodId: instance.flexTimePeriodId,
      location: instance.flexTimeActivity?.location,
      teacherName: instance.flexTimeActivity.createdByPerson?.name,
      teacherHash: instance.flexTimeActivity.createdByPerson?.hash,
      startTime: period.startTime,
      endTime: period.endTime,
    };
  }

  public setPeriodFilter(id) {
    this._periodFilterSubj.next(id);
  }

  public refreshDashboard() {
    this._dashboardRefreshSubj.next();
  }

  public async togglePollingState() {
    try {
      const newPollingState = !this._pollStateSubj.value;
      await this._userStorage.setItem(AUTO_REFRESH_STATE, newPollingState);
      this._pollStateSubj.next(newPollingState);

      this._systemAlertSnackBar.open({
        type: 'success',
        message: `Auto refresh ${newPollingState ? 'enabled' : 'disabled'}`,
      });
    } catch (e) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmDashboardMessages.AUTO_REFRESH_FAILED,
      });
    }
  }

  private async _initPolling() {
    const isPollingEnabled: boolean = await this._userStorage.getItem(
      AUTO_REFRESH_STATE,
    );
    this._pollStateSubj.next(isPollingEnabled);
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }
}
