"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MembershipListRoleRestrictions = exports.MembershipListType = void 0;
const permissions_1 = require("minga/libraries/domain/permissions/index");
var MembershipListType;
(function (MembershipListType) {
    MembershipListType["STICKER"] = "STICKER";
    MembershipListType["NO_PARTY"] = "NO_PARTY";
    MembershipListType["NO_PASS"] = "NO_PASS";
    MembershipListType["NO_GRANT"] = "NO_GRANT";
    MembershipListType["TRACK"] = "TRACK";
    MembershipListType["NO_TRACK"] = "NO_TRACK";
    MembershipListType["SCHOOL_TEAM"] = "SCHOOL_TEAM";
    MembershipListType["NO_ACCESS"] = "NO_ACCESS";
    MembershipListType["ONE_PASS"] = "ONE_PASS";
    MembershipListType["TWO_PASS"] = "TWO_PASS";
    MembershipListType["CONTENT_MANAGER"] = "CONTENT_MANAGER";
    MembershipListType["ID_MANAGER"] = "ID_MANAGER";
    MembershipListType["PASS_TYPE"] = "PASS_TYPE";
    MembershipListType["POINTS_MANAGER"] = "POINTS_MANAGER";
    MembershipListType["CHECKIN_MANAGER"] = "CHECKIN_MANAGER";
    MembershipListType["BEHAVIOR_MANAGER"] = "BEHAVIOR_MANAGER";
    MembershipListType["BEHAVIOR_TYPE"] = "BEHAVIOR_TYPE";
    MembershipListType["BEHAVIOR_REPORT_VIEWER"] = "BEHAVIOR_REPORT_VIEWER";
    MembershipListType["CHECKIN_REPORT_VIEWER"] = "CHECKIN_REPORT_VIEWER";
    MembershipListType["POINTS_REPORT_VIEWER"] = "POINTS_REPORT_VIEWER";
    MembershipListType["HP_REPORT_VIEWER"] = "HP_REPORT_VIEWER";
    MembershipListType["HALL_PASS_MANAGER"] = "HALL_PASS_MANAGER";
    MembershipListType["HALL_PASS_TYPE"] = "HALL_PASS_TYPE";
    MembershipListType["CHECKIN_RESTRICTION_LIST"] = "CHECKIN_RESTRICTION_LIST";
    MembershipListType["FLEX_RESTRICTION_LIST"] = "FLEX_RESTRICTION_LIST";
    MembershipListType["FLEX_MANAGER"] = "FLEX_MANAGER";
    MembershipListType["FLEX_REPORT_VIEWER"] = "FLEX_REPORT_VIEWER";
    MembershipListType["USER_LIST"] = "USER_LIST";
    MembershipListType["USER_LIST_MANAGER"] = "USER_LIST_MANAGER";
})(MembershipListType = exports.MembershipListType || (exports.MembershipListType = {}));
exports.MembershipListRoleRestrictions = {
    [MembershipListType.STICKER]: [],
    [MembershipListType.NO_PARTY]: [],
    [MembershipListType.NO_PASS]: [],
    [MembershipListType.NO_GRANT]: [],
    [MembershipListType.TRACK]: [],
    [MembershipListType.NO_TRACK]: [],
    [MembershipListType.SCHOOL_TEAM]: [],
    [MembershipListType.NO_ACCESS]: [],
    [MembershipListType.ONE_PASS]: [],
    [MembershipListType.TWO_PASS]: [],
    [MembershipListType.CONTENT_MANAGER]: [],
    [MembershipListType.ID_MANAGER]: [],
    [MembershipListType.PASS_TYPE]: [],
    [MembershipListType.POINTS_MANAGER]: [],
    [MembershipListType.CHECKIN_MANAGER]: [],
    [MembershipListType.BEHAVIOR_MANAGER]: [],
    [MembershipListType.BEHAVIOR_TYPE]: [],
    [MembershipListType.BEHAVIOR_REPORT_VIEWER]: [],
    [MembershipListType.CHECKIN_REPORT_VIEWER]: [],
    [MembershipListType.POINTS_REPORT_VIEWER]: [],
    [MembershipListType.HP_REPORT_VIEWER]: [],
    [MembershipListType.HALL_PASS_MANAGER]: [],
    [MembershipListType.HALL_PASS_TYPE]: [],
    [MembershipListType.CHECKIN_RESTRICTION_LIST]: [],
    [MembershipListType.FLEX_RESTRICTION_LIST]: [],
    [MembershipListType.FLEX_MANAGER]: [],
    [MembershipListType.FLEX_REPORT_VIEWER]: [],
    [MembershipListType.USER_LIST]: [],
    [MembershipListType.USER_LIST_MANAGER]: [
        permissions_1.MingaRoleType.SUPERADMIN,
        permissions_1.MingaRoleType.DISTRICT_MANAGER,
        permissions_1.MingaRoleType.OWNER,
        permissions_1.MingaRoleType.MANAGER,
        permissions_1.MingaRoleType.TEACHER,
        permissions_1.MingaRoleType.STAFF,
    ],
};