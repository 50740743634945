"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IAuthPersonMapper = void 0;
var IAuthPersonMapper;
(function (IAuthPersonMapper) {
    function fromPersonMinimalProto(personView) {
        const badgeIconUrl = personView.getBadgeIconUrl();
        const badgeIconColor = personView.getBadgeIconColor();
        const badgeRoleName = personView.getBadgeRoleName();
        const firstName = personView.getFirstName();
        const lastName = personView.getLastName();
        const displayName = personView.getDisplayName();
        const hash = personView.getPersonHash();
        const avatarUrl = personView.getProfileImageUrl();
        const studentId = personView.getStudentId();
        const grade = personView.getGrade();
        const filename = personView.getFilename();
        return {
            badgeIconColor,
            badgeIconUrl,
            badgeRoleName,
            firstName,
            lastName,
            displayName,
            hash,
            avatarUrl,
            studentId,
            grade,
            filename,
        };
    }
    IAuthPersonMapper.fromPersonMinimalProto = fromPersonMinimalProto;
    function fromPersonMinimalAsObject(person) {
        return {
            displayName: person.displayName,
            avatarUrl: person.profileImageUrl,
            hash: person.personHash,
            badgeIconColor: person.badgeIconColor,
            badgeIconUrl: person.badgeIconUrl,
            badgeRoleName: person.badgeRoleName,
            firstName: person.firstName,
            lastName: person.lastName,
            filename: person.filename,
        };
    }
    IAuthPersonMapper.fromPersonMinimalAsObject = fromPersonMinimalAsObject;
})(IAuthPersonMapper = exports.IAuthPersonMapper || (exports.IAuthPersonMapper = {}));