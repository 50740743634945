<div
  #container
  [fxHide]="(showPass$ | async) === false"
  class="container">
  <div class="top-content">
    <mg-icon [iconName]="activePass.hallPassType.bannerHash"></mg-icon>
    <mg-text
      class="pass-name dynamic-font-color"
      variant="header-md-secondary"
      [numberOfLines]="1">
      {{ activePass.hallPassType.name }}
    </mg-text>
    <mg-text
      variant="header-xl"
      class="dynamic-font-color">
      {{ remainingTime$ | async | hallPassTimeFormat }}
    </mg-text>
  </div>
  <div class="bottom-content">
    <div class="approved-info">
      <mg-text
        *ngIf="
          activePass.authorPersonViewMinimal.personHash &&
          activePass.authorPersonViewMinimal.personHash !== hash
        "
        class="dynamic-font-color"
        [variant]="(media.isMobileView$ | async) ? 'body-xs' : 'body-sm'"
        [numberOfLines]="1">
        Approved by:
        {{
          activePass.authorPersonViewMinimal.firstName +
            ' ' +
            activePass.authorPersonViewMinimal.lastName
        }}
      </mg-text>
      <mg-text
        *ngIf="activePass.note"
        class="dynamic-font-color"
        [variant]="(media.isMobileView$ | async) ? 'body-xs' : 'body-sm'"
        [numberOfLines]="1">
        <i> Note: {{ activePass.note }} </i>
      </mg-text>
    </div>

    <ng-container *ngIf="endPassPermissions$ | async as endPassPermissions">
      <mg-btn
        *ngIf="endPassPermissions.canEndPass"
        variant="filled"
        [size]="(media.isMobileView$ | async) ? 'small' : 'large'"
        [ngClass]="{ 'hide-on-mobile': !endPassPermissions.canEndPassOnMobile }"
        (pressed)="endPass()">
        End
      </mg-btn>
    </ng-container>
  </div>
</div>
