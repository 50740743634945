"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHECKIN_ICONS = exports.CheckinReasonIcons = exports.CheckinType = void 0;
const hallPass_1 = require("minga/libraries/domain/hallPass/index");
var CheckinType;
(function (CheckinType) {
    CheckinType["CHECKIN"] = "CHECKIN";
    CheckinType["CHECKOUT"] = "CHECKOUT";
    CheckinType["FLEX_TIME_CHECKIN"] = "FLEX_TIME_CHECKIN";
    CheckinType["EVENT_CHECKIN"] = "EVENT_CHECKIN";
})(CheckinType = exports.CheckinType || (exports.CheckinType = {}));
var CheckinReasonIcons;
(function (CheckinReasonIcons) {
    CheckinReasonIcons["CHECKIN_1"] = "checkin-1";
    CheckinReasonIcons["CHECKIN_2"] = "checkin-2";
    CheckinReasonIcons["CHECKIN_3"] = "checkin-3";
    CheckinReasonIcons["CHECKIN_4"] = "checkin-4";
    CheckinReasonIcons["CHECKIN_5"] = "checkin-5";
    CheckinReasonIcons["CHECKIN_6"] = "checkin-6";
    CheckinReasonIcons["CHECKIN_7"] = "checkin-7";
    CheckinReasonIcons["CHECKIN_8"] = "checkin-8";
    CheckinReasonIcons["CHECKIN_9"] = "checkin-9";
})(CheckinReasonIcons = exports.CheckinReasonIcons || (exports.CheckinReasonIcons = {}));
exports.CHECKIN_ICONS = [
    ...Object.values(CheckinReasonIcons),
    ...Object.values(hallPass_1.DefaultHallPassTypes).map(type => 'hp-' + type),
];