"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignedToLast = exports.assignedTo = exports.noteAndRequesterNote = exports.termId = exports.sectionId = exports.periodId = exports.currentClass = exports.note = exports.role = exports.grade = exports.studentId = exports.lastName = exports.firstName = void 0;
const formatTime_1 = require("minga/libraries/util/time/formatTime");
const utils_1 = require("minga/libraries/shared/reports_columns/utils/index");
const common_constants_1 = require("minga/libraries/shared/reports_columns/constants/common.constants");
exports.firstName = {
    header: 'First Name',
    key: common_constants_1.NameColumnKeys.FIRST_NAME,
    tooltip: true,
    accessorFn: (item) => {
        if (item.person)
            return utils_1.nameFinder(item.person, 'first');
        if (item.issuedToPerson)
            return utils_1.nameFinder(item.issuedToPerson, 'first');
        if (item.issuedTo)
            return utils_1.nameFinder(item.issuedTo, 'first');
        if (item.issuedBy)
            return utils_1.nameFinder(item.issuedBy, 'first');
        return '';
    },
    type: 'text',
    width: 'flex-medium',
    sort: true,
};
/** Note: using lastName will add an email column to the exported report */
exports.lastName = {
    header: 'Last Name',
    key: common_constants_1.NameColumnKeys.LAST_NAME,
    tooltip: true,
    accessorFn: (item) => {
        if (item.person)
            return utils_1.nameFinder(item.person, 'last');
        if (item.issuedToPerson)
            return utils_1.nameFinder(item.issuedToPerson, 'last');
        if (item.issuedTo)
            return utils_1.nameFinder(item.issuedTo, 'last');
        if (item.issuedBy)
            return utils_1.nameFinder(item.issuedBy, 'last');
        return '';
    },
    type: 'text',
    width: 'flex-medium',
    sort: true,
};
exports.studentId = {
    header: 'ID Number',
    key: 'studentId',
    tooltip: true,
    accessorFn: (item) => {
        if (item === null || item === void 0 ? void 0 : item.person)
            return utils_1.studentIdFinder(item.person);
        if (item === null || item === void 0 ? void 0 : item.issuedToPerson)
            return utils_1.studentIdFinder(item.issuedToPerson);
        if (item === null || item === void 0 ? void 0 : item.issuedTo)
            return utils_1.studentIdFinder(item.issuedTo);
        if (item === null || item === void 0 ? void 0 : item.recipientPersonViewMinimal) {
            return utils_1.studentIdFinder(item.recipientPersonViewMinimal);
        }
        return '';
    },
    type: 'text',
    width: 'large',
};
exports.grade = {
    header: 'Grade',
    key: 'grade',
    accessorFn: (item) => {
        if (item.person)
            return utils_1.gradeFinder(item.person);
        if (item.issuedToPerson)
            return utils_1.gradeFinder(item.issuedToPerson);
        if (item.issuedTo)
            return utils_1.gradeFinder(item.issuedTo);
        return '';
    },
    type: 'text',
    width: 'small',
};
exports.role = {
    header: 'Role',
    key: common_constants_1.TemplateColumnKeys.ROLE,
    tooltip: true,
    accessorFn: (item, value) => {
        if (item.person)
            return utils_1.roleFinder(item.person, value);
        if (item.issuedToPerson)
            return utils_1.roleFinder(item.issuedToPerson, value);
        if (item.issuedTo)
            return utils_1.roleFinder(item.issuedTo, value);
        return '';
    },
    type: 'custom',
};
exports.note = {
    header: 'Note',
    key: 'note',
    tooltip: true,
    type: 'text',
    width: 'small',
    accessorFn: (item) => item.note || '',
};
exports.currentClass = {
    header: 'Class',
    key: 'currentClass',
    tooltip: true,
    type: 'text',
    width: 'small',
    accessorFn: (item) => {
        const { currentClass } = item;
        if (!(currentClass === null || currentClass === void 0 ? void 0 : currentClass.length))
            return '';
        let tooltip = '';
        for (const classItem of currentClass) {
            if (tooltip.length)
                tooltip += '\n';
            tooltip += `Class: ${classItem.name || ''}`;
            if (classItem === null || classItem === void 0 ? void 0 : classItem.startTime) {
                tooltip += `\nStart time: ${formatTime_1.formatTime(classItem === null || classItem === void 0 ? void 0 : classItem.startTime)}`;
            }
            if (classItem === null || classItem === void 0 ? void 0 : classItem.endTime) {
                tooltip += `\nEnd time: ${formatTime_1.formatTime(classItem === null || classItem === void 0 ? void 0 : classItem.endTime)}`;
            }
            tooltip += '\n';
        }
        return tooltip;
    },
};
exports.periodId = {
    header: 'Period ID',
    key: 'periodId',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
    accessorFn: (item) => {
        const { currentClass } = item;
        if (!(currentClass === null || currentClass === void 0 ? void 0 : currentClass.length))
            return '';
        let tooltip = '';
        for (const classItem of currentClass) {
            if (tooltip.length)
                tooltip += '\n';
            tooltip += `${classItem.periodId || ''}`;
            tooltip += '\n';
        }
        return tooltip;
    },
};
exports.sectionId = {
    header: 'Section ID',
    key: 'sectionId',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
    accessorFn: (item) => {
        const { currentClass } = item;
        if (!(currentClass === null || currentClass === void 0 ? void 0 : currentClass.length))
            return '';
        let tooltip = '';
        for (const classItem of currentClass) {
            if (tooltip.length)
                tooltip += '\n';
            tooltip += `${classItem.sectionId || ''}`;
            tooltip += '\n';
        }
        return tooltip;
    },
};
exports.termId = {
    header: 'Term ID',
    key: 'termId',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
    accessorFn: (item) => {
        const { currentClass } = item;
        if (!(currentClass === null || currentClass === void 0 ? void 0 : currentClass.length))
            return '';
        let tooltip = '';
        for (const classItem of currentClass) {
            if (tooltip.length)
                tooltip += '\n';
            tooltip += `${classItem.termId || ''}`;
            tooltip += '\n';
        }
        return tooltip;
    },
};
exports.noteAndRequesterNote = {
    header: 'Note',
    key: 'note',
    tooltip: true,
    type: 'text',
    width: 'small',
    accessorFn: (item) => utils_1.getHallPassNote(item.note, item.noteToRequester),
};
exports.assignedTo = {
    header: 'Assigned to First Name',
    key: common_constants_1.NameColumnKeys.FIRST_NAME,
    tooltip: true,
    accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'first'),
    type: 'text',
    width: 'flex-medium',
    sort: true,
};
exports.assignedToLast = {
    header: 'Assigned to Last Name',
    key: common_constants_1.NameColumnKeys.LAST_NAME,
    tooltip: true,
    accessorFn: (item) => utils_1.assignedToAccessorFn(item, 'last'),
    type: 'text',
    width: 'flex-medium',
    sort: true,
};