"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const proto = require("minga/proto/stats_report/stats_report_pb");
const domain_1 = require("minga/libraries/domain/index");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
exports.toProto = (report) => {
    const msg = new proto.PastReport();
    if (report.id)
        msg.setId(report.id);
    if (report.name)
        msg.setName(report.name);
    if (report.status)
        msg.setStatus(ts_enum_util_1.$enum(domain_1.PastReportStatus).asValueOrThrow(report.status));
    if (report.date)
        msg.setDate(shared_grpc_1.dateObjectToDateTimeMessage(report.date));
    if (report.scheduledReportId)
        msg.setScheduledReportId(report.scheduledReportId);
    return msg;
};
exports.fromProto = (msg) => {
    return {
        id: msg.getId(),
        name: msg.getName(),
        status: ts_enum_util_1.$enum(domain_1.PastReportStatus).asValueOrThrow(msg.getStatus()),
        date: shared_grpc_1.dateTimeMessageToDateObject(msg.getDate()),
        scheduledReportId: msg.getScheduledReportId(),
    };
};