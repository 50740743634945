"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailedModerationResultWrapper = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const domain_2 = require("minga/libraries/domain/index");
const util_1 = require("minga/libraries/util/index");
function pairsToObjArray(pairs) {
    return pairs.map(tag => {
        return {
            key: tag.getKey(),
            value: tag.getValue(),
        };
    });
}
class DetailedModerationResultWrapper {
    constructor(message) {
        this._message = message;
    }
    _messageDetailsToProviderDetails(details) {
        return {
            tags: pairsToObjArray(details.getTagsList()),
            fileUrl: details.getFileurl(),
            index: details.getIndex(),
            imageTags: pairsToObjArray(details.getImageTagsList()),
            reasonIndex: details.getReasonIndex(),
        };
    }
    _messageHistoryToModerationHistory(history) {
        const details = history.getDetailsList().map(detail => {
            return this._messageDetailsToProviderDetails(detail);
        });
        const status = ts_enum_util_1.$enum(util_1.ContentStatus).getValueOrThrow(history.getStatus());
        return {
            personId: history.getPersonid(),
            timestamp: history.getTimestamp(),
            personDisplayName: history.getPersonDisplayName(),
            reason: history.getReasonindex(),
            status,
            details,
        };
    }
    get title() {
        return this._message.getTitle();
    }
    get parentContextHash() {
        return this._message.getParentContextHash();
    }
    get contentContextHash() {
        return this._message.getContentContextHash();
    }
    get mingaHash() {
        return this._message.getMingaHash();
    }
    get mingaName() {
        return this._message.getMingaName();
    }
    get contentAuthorHash() {
        return this._message.getContentAuthorHash();
    }
    get timestamp() {
        return this._message.getTimestamp();
    }
    get contentAuthorFirstName() {
        return this._message.getContentAuthorFirstName();
    }
    get contentAuthorLastName() {
        return this._message.getContentAuthorLastName();
    }
    get type() {
        const contentType = ts_enum_util_1.$enum(domain_1.ContentType).getValueOrDefault(this._message.getContentType());
        const modType = ts_enum_util_1.$enum(domain_2.ModerationNonContentType).getValueOrDefault(this._message.getContentType());
        return contentType || modType || domain_1.ContentType.POST;
    }
    get contentHistory() {
        return this._message.getContentHistoryList().map(history => {
            return this._messageHistoryToModerationHistory(history);
        });
    }
    get status() {
        return ts_enum_util_1.$enum(util_1.ContentStatus).getValueOrThrow(this._message.getStatus());
    }
    get actionContextHash() {
        return this._message.getActionContextHash();
    }
    get galleryPhotoUuid() {
        return this._message.getGalleryPhotoUuid();
    }
}
exports.DetailedModerationResultWrapper = DetailedModerationResultWrapper;