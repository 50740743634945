import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'minga/libraries/domain';

import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';

@Pipe({ name: 'totalRegistered' })
export class TotalRegisteredPipe implements PipeTransform {
  constructor(private _flexInstancesService: FlexTimeActivityInstanceService) {}

  transform(instances: FlexTimeActivityInstance[]): string {
    const { totalRegistered, totalSeats } =
      this._flexInstancesService.getTotalCounts(instances);
    return `${totalRegistered} / ${totalSeats}`;
  }
}
