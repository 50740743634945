"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlexTimeUnregisteredInfoMapper = exports.FlexTimeRegistrationMapper = exports.FlexTimePeriodMapper = exports.FlexTimeActivityInstanceMapper = exports.FlexTimeActivityMapper = void 0;
/**
 * @file Automatically generated by barrelsby.
 */
const FlexTimeActivitymapperts = require("minga/libraries/shared-grpc/flex_time/FlexTimeActivity.mapper");
exports.FlexTimeActivityMapper = FlexTimeActivitymapperts;
const FlexTimeActivityInstancemapperts = require("minga/libraries/shared-grpc/flex_time/FlexTimeActivityInstance.mapper");
exports.FlexTimeActivityInstanceMapper = FlexTimeActivityInstancemapperts;
const FlexTimePeriodmapperts = require("minga/libraries/shared-grpc/flex_time/FlexTimePeriod.mapper");
exports.FlexTimePeriodMapper = FlexTimePeriodmapperts;
const FlexTimeRegistrationmapperts = require("minga/libraries/shared-grpc/flex_time/FlexTimeRegistration.mapper");
exports.FlexTimeRegistrationMapper = FlexTimeRegistrationmapperts;
const FlexTimeUnregisteredInfomapperts = require("minga/libraries/shared-grpc/flex_time/FlexTimeUnregisteredInfo.mapper");
exports.FlexTimeUnregisteredInfoMapper = FlexTimeUnregisteredInfomapperts;