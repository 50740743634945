<ng-container *ngIf="getReportType() as reportType">
  <mg-manager-overlay-reports-subpage
    [loading]="false"
    [noBottomPadding]="reportType === 'pbis_summary'"
    [columnSettingsButton]="reportType !== 'pbis_summary'"
    [scheduleReportsButton]="
      (SUPPPORTED_SCHEDULE_TYPES | includes: reportType) &&
      (canScheduleReport$ | async)
    "
    [dataService]="dataService$ | async"
    (scheduleReportsPressed)="onScheduleReport()"
    (exportListPressed)="onClickExport()">
    <!-- Header -->
    <ng-template #header>
      <div
        class="filters-container"
        fxLayout="row wrap"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start stretch">
        <!-- Report Type field -->
        <ng-container *ngTemplateOutlet="reportTypeFieldTemplate">
        </ng-container>
        <!-- Date Picker field -->
        <ng-container
          *ngIf="!noDateFilterTypes.includes(reportType)"
          [ngTemplateOutlet]="datePickerFieldTemplate">
        </ng-container>
        <!-- Filters form button -->
        <ng-container
          *ngIf="(media.isMobileView$ | async) === false"
          [ngTemplateOutlet]="filtersFormReference">
        </ng-container>
      </div>
    </ng-template>

    <!-- Filters form reference -->
    <ng-template #filtersFormReference>
      <mg-filters-form
        [data]="filtersFormStructure$ | async"
        [loading]="loading$ | async"
        (state)="updateFilters($event)">
      </mg-filters-form>
    </ng-template>

    <!-- Main Content -->
    <ng-template #content>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </ng-template>
  </mg-manager-overlay-reports-subpage>

  <!-- Report Type Field Template -->
  <ng-template #reportTypeFieldTemplate>
    <div
      class="filters-field half-width"
      fxFlex.gt-sm="335px">
      <mg-form-select
        placeholder="Report Type"
        appearance="tertiary"
        [id]="'report-report-view-click-filter-type'"
        [value]="reportType"
        [closeOnSelect]="true"
        [multiple]="false"
        [searchable]="false"
        [options]="reportOptions"
        [floatingLabel]="false"
        (selectionChanged)="onChangeReportType($any($event))">
      </mg-form-select>
    </div>
  </ng-template>

  <!-- Date Picker Field Template -->
  <ng-template #datePickerFieldTemplate>
    <div class="filters-field half-width date-range">
      <mg-form-date-range
        [id]="'report-report-click-filter-date'"
        [range]="range">
      </mg-form-date-range>
    </div>
  </ng-template>
</ng-container>
