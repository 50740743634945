import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, from, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import * as hp_pb from 'minga/proto/hall_pass/hall_pass_pb';
import * as pbis_pb from 'minga/proto/pbis/pbis_pb';
import {
  AutomationPayload,
  IActionThresholdAutomation,
} from 'minga/libraries/domain';
import {
  EditableConsequence,
  ExistingConsequenceType,
  IConsequenceType,
} from 'minga/libraries/domain';
import { IHallPassType } from 'minga/libraries/domain';
import { EditablePbisBehavior, IPbisType } from 'minga/libraries/domain';
import {
  ConsequenceMapper,
  ConsequenceTypeMapper,
} from 'minga/libraries/shared-grpc';
import { HallPassTypeMapper } from 'minga/libraries/shared-grpc';
import {
  PbisBehaviorMapper,
  PbisTypeMapper,
} from 'minga/libraries/shared-grpc';
import { HallPassManager } from 'minga/proto/hall_pass/hall_pass_ng_grpc_pb';
import { PbisManager } from 'minga/proto/pbis/pbis_ng_grpc_pb';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { AutomationService } from '@shared/services/automation';

import { ICON_CONFIG_DEFAULTS } from '../components/bm-types/components/bm-types-behavior-edit/bm-types-behavior-edit.constants';
import { NEW_BEHAVIOR } from '../components/bm-types/constants';

export type ImportBehaviorObservable = {
  cancel(): void;
  close(): void;
} & Observable<pbis_pb.ImportBehaviorsResponse>;

@Injectable({ providedIn: 'root' })
export class BehaviorManagerService implements OnDestroy {
  /** Behavior Types */
  private readonly _isLoadingTypesData = new BehaviorSubject(true);
  public readonly isLoadingTypesData$ = this._isLoadingTypesData
    .asObservable()
    .pipe(shareReplay());
  private readonly _types = new BehaviorSubject<IPbisType[]>([]);
  public readonly types$ = this._types.asObservable().pipe(shareReplay());

  /** Consequences */
  private readonly _isLoadingConsequenceTypesData = new BehaviorSubject(true);
  public readonly isLoadingConsequenceTypesData$ =
    this._isLoadingConsequenceTypesData.asObservable().pipe(shareReplay());
  private readonly _consequenceTypes = new BehaviorSubject<
    ExistingConsequenceType[]
  >([]);
  public readonly consequenceTypes$ = this._consequenceTypes
    .asObservable()
    .pipe(shareReplay());

  /** Automations */
  private readonly _isLoadingAutomationsData = new BehaviorSubject(true);
  public readonly isLoadingAutomationsData$ = this._isLoadingAutomationsData
    .asObservable()
    .pipe(shareReplay());
  private readonly _automations = new BehaviorSubject<
    IActionThresholdAutomation[]
  >([]);
  public readonly automations$ = this._automations
    .asObservable()
    .pipe(shareReplay());

  /** Hall Pass Types */
  private readonly _isLoadingHallPassTypes = new BehaviorSubject(false);
  public readonly isLoadingHallPasstypes$ = this._isLoadingHallPassTypes
    .asObservable()
    .pipe(shareReplay());
  private readonly _hallPassTypes = new BehaviorSubject<IHallPassType[]>([]);
  public readonly hallPassTypes$ = this._hallPassTypes
    .asObservable()
    .pipe(shareReplay());

  /** Service Constructor */
  constructor(
    private _pbisManager: PbisManager,
    private _automationService: AutomationService,
    private _hpManager: HallPassManager,
    private _systemSnackbar: SystemAlertSnackBarService,
  ) {}

  ngOnDestroy() {
    this._isLoadingTypesData.complete();
    this._isLoadingConsequenceTypesData.complete();
    this._isLoadingAutomationsData.complete();
    this._types.complete();
    this._consequenceTypes.complete();
    this._automations.complete();
  }

  public importBehaviors(
    rows: any[],
    type: string,
    id: number,
  ): ImportBehaviorObservable {
    const request = new pbis_pb.ImportBehaviorsRequest();
    const peopleDetails: pbis_pb.BulkBehaviorPerson[] = [];

    rows.forEach(row => {
      const person = new pbis_pb.BulkBehaviorPerson();
      person.setEmail(row.email);
      let count = row.count;
      if (!count) {
        count = 1;
      }
      person.setCount(count);
      person.setStudentId(row.studentId);
      peopleDetails.push(person);
    });

    request.setPeopleList(peopleDetails);
    if (type !== 'consequence') {
      request.setBehaviorId(id);
    } else {
      request.setConsequenceId(id);
    }
    return this._pbisManager.importBehaviors(request);
  }

  public async createType(newType?: Partial<IPbisType>): Promise<IPbisType> {
    try {
      const result = await this._updateType({ ...NEW_BEHAVIOR, ...newType });
      this._types.next([result, ...this._types.getValue()]);
      return result;
    } catch (error) {
      this._systemSnackbar.error('Failed to create new behavior type');
    }
  }

  public async updateType(type: IPbisType): Promise<IPbisType> {
    try {
      const result = await this._updateType(type);
      return result;
    } catch (error) {
      this._systemSnackbar.error('Failed to update type');
    }
  }

  public async fetchType(id: number): Promise<IPbisType> {
    try {
      const type = await this._getType(id);
      if (!type.iconColor) {
        type.iconColor = ICON_CONFIG_DEFAULTS[type.categoryId].color;
      }
      if (!type.iconType) {
        type.iconType = ICON_CONFIG_DEFAULTS[type.categoryId].icon;
      }
      return type;
    } catch (error) {
      this._systemSnackbar.error(`Failed to fetch type with id: ${id}`);
    }
  }

  public async deleteType(id: number): Promise<void> {
    try {
      await this._deleteType(id);
      const types = [...this._types.getValue()];
      this._types.next(types.filter(t => t.id !== id));
    } catch (error) {
      this._systemSnackbar.error(`Failed to delete type with id: ${id}`);
    }
  }

  public async fetchTypes(getActiveOnly?: boolean): Promise<void> {
    this._isLoadingTypesData.next(true);
    try {
      const types = await this._listTypes(getActiveOnly);
      const sortedTypes = types.sort((a, b) => {
        return (b.dailyBehaviorCount ?? 0) - (a.dailyBehaviorCount ?? 0);
      });
      this._types.next(sortedTypes);
    } catch (error) {
      this._systemSnackbar.error(error);
    } finally {
      this._isLoadingTypesData.next(false);
    }
  }

  public async createConsequenceType(
    type: IConsequenceType,
  ): Promise<ExistingConsequenceType> {
    try {
      const newType = await this._updateConsequenceType(type);
      const types = [...this._consequenceTypes.getValue()];
      types.unshift(newType);
      this._consequenceTypes.next(types);
      return newType;
    } catch (error) {
      this._systemSnackbar.error(
        `failed to create consequence type: ${error?.messsage}`,
      );
    }
  }

  public async updateConsequenceType(
    type: ExistingConsequenceType,
  ): Promise<ExistingConsequenceType> {
    try {
      const result = await this._updateConsequenceType(type);
      return result;
    } catch (error) {
      this._systemSnackbar.error(
        `failed to update consequence type: ${error?.messsage}`,
      );
    }
  }

  public async fetchConsequenceTypes(activeOnly?: boolean): Promise<void> {
    this._isLoadingConsequenceTypesData.next(true);
    try {
      const consequences = await this._listConsequenceTypes(activeOnly);
      const sortedConsequences = consequences.sort((a, b) => {
        return (b.dailyConsequenceCount ?? 0) - (a.dailyConsequenceCount ?? 0);
      });
      this._consequenceTypes.next(sortedConsequences);
    } catch (error) {
      this._systemSnackbar.error(
        `failed to fetch consequence types: ${error?.messsage}`,
      );
    } finally {
      this._isLoadingConsequenceTypesData.next(false);
    }
  }

  public getBehaviorTypes(activeOnly?: boolean) {
    return from(this._listTypes(activeOnly));
  }

  public getConsTypes(
    activeOnly?: boolean,
  ): Observable<ExistingConsequenceType[]> {
    return from(this._listConsequenceTypes(activeOnly));
  }

  public async deleteConsequenceType(id: number): Promise<void> {
    try {
      await this._deleteConsequenceType(id);
      const types = [...this._consequenceTypes.getValue()];
      this._consequenceTypes.next(types.filter(type => id !== type.id));
    } catch (error) {
      this._systemSnackbar.error(
        `failed to delete consequence type: ${error?.messsage}`,
      );
    }
  }

  public async fetchHallPassTypes(): Promise<void> {
    this._isLoadingHallPassTypes.next(true);
    try {
      const result = await this._listHallPassTypes();
      this._hallPassTypes.next(result);
    } catch (error) {
      this._systemSnackbar.error(error);
    } finally {
      this._isLoadingHallPassTypes.next(false);
    }
  }

  public async fetchConsequnceAutomations(): Promise<void> {
    this._isLoadingAutomationsData.next(true);
    try {
      const automations = await this._automationService.fetchAutomations();
      this._automations.next([...automations]);
    } catch (error) {
      this._systemSnackbar.error('Failed to fetch consequence automations');
    } finally {
      this._isLoadingAutomationsData.next(false);
    }
  }

  public async fetchConsequnceAutomation(
    id,
  ): Promise<IActionThresholdAutomation> {
    try {
      const result = await this._automationService.fetchAutomation(id);
      return result;
    } catch (error) {
      this._systemSnackbar.error(
        `Failed to fetch consequence automation with id: ${id}`,
      );
    }
  }
  public async createConsequnceAutomation(
    automation: IActionThresholdAutomation | AutomationPayload,
  ): Promise<IActionThresholdAutomation> {
    try {
      const result = await this._automationService.createAutomation(automation);
      this._automations.next([result, ...this._automations.getValue()]);
      return result;
    } catch (error) {
      this._systemSnackbar.error('Failed to create consequence automation.');
    }
  }

  public async updateConsequnceAutomation(
    automation: IActionThresholdAutomation,
  ): Promise<IActionThresholdAutomation> {
    try {
      const result = await this._automationService.updateAutomation(automation);
      const automations = [...this._automations.getValue()];
      const index = automations.findIndex(({ id }) => id === result.id);
      if (index >= 0) automations[index] = result;
      this._automations.next([...automations]);
      return result;
    } catch (error) {
      this._systemSnackbar.error('Failed to update consequence automation');
    }
  }

  public async deleteConsequnceAutomation(id?: number): Promise<void> {
    try {
      await this._automationService.deleteAutomation(id);
      const automations = this._automations.getValue();
      this._automations.next(
        automations.filter(automation => id !== automation.id),
      );
    } catch (error) {
      this._systemSnackbar.error('Failed to update consequence automation');
    }
  }

  public async updateBehavior(behavior: EditablePbisBehavior) {
    const req = new pbis_pb.UpdateBehaviorRequest();
    req.setBehavior(PbisBehaviorMapper.toProtoEditable(behavior));
    const response = await this._pbisManager.updateBehavior(req);
    behavior = PbisBehaviorMapper.fromProtoEditable(response.getBehavior());
    return behavior;
  }

  public async updateConsequence(consequence: EditableConsequence) {
    const req = new pbis_pb.UpdateConsequenceRequest();
    req.setConsequence(ConsequenceMapper.toProtoEditable(consequence));
    const response = await this._pbisManager.updateConsequence(req);
    consequence = ConsequenceMapper.fromProtoEditable(
      response.getConsequence(),
    );
    return consequence;
  }

  private async _getType(id: number): Promise<IPbisType> {
    const req = new pbis_pb.GetTypeRequest();
    req.setId(id);
    const res = await this._pbisManager.getType(req);
    const type = res.getType();
    return PbisTypeMapper.fromProto(type);
  }

  private async _listTypes(getActiveOnly?: boolean): Promise<IPbisType[]> {
    const req = new pbis_pb.GetTypesRequest();
    if (getActiveOnly) req.setGetActiveOnly(getActiveOnly);
    const res = await this._pbisManager.getTypes(req);
    return res.getTypesList().map(value => {
      return PbisTypeMapper.fromProto(value);
    });
  }

  private async _updateType(type: IPbisType) {
    const req = new pbis_pb.UpdateTypeRequest();
    req.setType(PbisTypeMapper.toProto(type));
    const res = await this._pbisManager.updateType(req);
    return PbisTypeMapper.fromProto(res.getType());
  }

  private async _deleteType(id: number): Promise<void> {
    const req = new pbis_pb.DeleteTypeRequest();
    req.setId(id);
    await this._pbisManager.deleteType(req);
  }

  public async _updateConsequenceType(
    type: IConsequenceType | ExistingConsequenceType,
  ): Promise<ExistingConsequenceType> {
    const request = new pbis_pb.UpdateConsequenceTypeRequest();
    const typeMsg = ConsequenceTypeMapper.toProto(type as any);
    request.setConsequenceType(typeMsg);
    const response = await this._pbisManager.updateConsequenceType(request);
    return ConsequenceTypeMapper.fromProto(
      response.getConsequenceType(),
    ) as ExistingConsequenceType;
  }

  private async _listConsequenceTypes(
    activeOnly?: boolean,
  ): Promise<ExistingConsequenceType[]> {
    const request = new pbis_pb.GetConsequenceTypesRequest();
    if (activeOnly) request.setActiveOnly(activeOnly);
    const response = await this._pbisManager.getConsequenceTypes(request);
    const consequenceTypesMsg = response.getConsequenceTypeList();
    return consequenceTypesMsg.map(
      msg => ConsequenceTypeMapper.fromProto(msg) as ExistingConsequenceType,
    );
  }

  private async _deleteConsequenceType(id: number): Promise<void> {
    const request = new pbis_pb.DeleteConsequenceTypeRequest();
    request.setId(id);
    await this._pbisManager.deleteConsequenceType(request);
    return;
  }

  private async _listHallPassTypes(): Promise<IHallPassType[]> {
    const request = new hp_pb.GetMingaHallPassTypesRequest();
    request.setGetActiveOnly(true);
    const response = await this._hpManager.getMingaHallPassTypes(request);
    const result = response.getHallPassTypeList();
    return result.map(passProto => HallPassTypeMapper.fromProto(passProto));
  }
}
