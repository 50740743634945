import { Injectable } from '@angular/core';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { Observable, from } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';

import {
  ScheduledReport,
  ScheduledReportMinimal,
} from 'minga/libraries/domain';

import { ScheduledReportsService } from '@shared/services/scheduled-reports/scheduled-reports.service';

// import { ScheduledReport } from '../types';

export interface ScheduledReportsState
  extends EntityState<ScheduledReportMinimal> {
  isLoading: boolean;
  error: string | null;
}

const initialState = {
  isLoading: false,
  error: null,
};

// use entity adapter for managing crud state
const adapter = createEntityAdapter<ScheduledReportMinimal>({
  selectId: (scheduledReport: ScheduledReportMinimal) => scheduledReport.id,
});

const { selectAll } = adapter.getSelectors();

@Injectable()
export class ScheduledReportsStore extends ComponentStore<ScheduledReportsState> {
  // reducers
  private _setIsLoading = this.updater(state => ({
    ...state,
    isLoading: true,
  }));
  private _setError = this.updater((state, error: string) => ({
    ...state,
    isLoading: false,
    error,
  }));
  private _setScheduledReports = this.updater(
    (state, scheduledReports: ScheduledReportMinimal[]) =>
      adapter.setAll(scheduledReports, {
        ...state,
        isLoading: false,
        error: null,
      }),
  );
  public updateScheduledReport = this.updater(
    (state, scheduledReport: ScheduledReport) =>
      adapter.setOne(scheduledReport, state),
  );
  public addScheduledReport = this.updater(
    (state, scheduledReport: ScheduledReport) => {
      return adapter.addOne(scheduledReport, state);
    },
  );
  public removeScheduledReport = this.updater((state, id: number) =>
    adapter.removeOne(id, state),
  );
  public toggleActiveSchedule = this.updater(
    (state, { id, active }: { id: number; active: boolean }) => {
      const update: Update<ScheduledReportMinimal> = {
        id,
        changes: { active },
      };

      return adapter.updateOne(update, state);
    },
  );

  // selectors
  public isLoading$: Observable<boolean> = this.select(
    state => state.isLoading,
  );
  public error$ = this.select(state => state.error);
  public scheduledReports$ = this.select(state => selectAll(state));

  // effects
  public getScheduledReports = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this._setIsLoading();
      }),
      exhaustMap(() => {
        return from(this._scheduledReportsService.getAll()).pipe(
          tapResponse(
            scheduledReports => this._setScheduledReports(scheduledReports),
            (error: any) => this._setError(error.message),
          ),
        );
      }),
    );
  });

  constructor(private _scheduledReportsService: ScheduledReportsService) {
    super(adapter.getInitialState(initialState));
  }
}
