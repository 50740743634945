import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';

import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { SelectElementModule } from '@shared/components/select-element';
import { UserListFilterModule } from '@shared/components/user-list-filter';
import {
  CommonFormModules,
  CommonPipesModule,
  CommonUIModules,
} from '@shared/modules';
import { ArrayPipesModule } from '@shared/pipes';

import { FiltersFormPersonSearchComponent } from './components/filters-form-person-search/filters-form-person-search.component';
import { FiltersFormPopoverComponent } from './components/filters-form-popover/filters-form-popover.component';
import { FiltersFormSheetComponent } from './components/filters-form-sheet/filters-form-sheet.component';
import { FiltersFormComponent } from './filters-form.component';
import {
  FiltersFormCheckChipVisibilityPipe,
  FiltersFormCheckForFieldsCallbackPipe,
  FiltersFormGetLabelsFromOptionsPipe,
  FiltersFormGetUserListLabelsFromIdsPipe,
} from './pipes';
import { FiltersFormGetPeopleNameLabelsPipe } from './pipes/filters-form-get-people-name-labels.pipe';

@NgModule({
  imports: [
    // Minga dependencies
    UserListFilterModule,
    MultiPersonSearchModule,
    SelectElementModule,
    ArrayPipesModule,

    // Common dependencies
    CommonUIModules,
    CommonFormModules,
    CommonPipesModule,

    // External dependencies
    OverlayModule,
    A11yModule,
  ],
  exports: [FiltersFormComponent],
  declarations: [
    // Components
    FiltersFormComponent,
    FiltersFormPopoverComponent,
    FiltersFormSheetComponent,
    FiltersFormPersonSearchComponent,

    // Pipes
    FiltersFormGetLabelsFromOptionsPipe,
    FiltersFormCheckChipVisibilityPipe,
    FiltersFormGetUserListLabelsFromIdsPipe,
    FiltersFormGetPeopleNameLabelsPipe,
    FiltersFormCheckForFieldsCallbackPipe,
  ],
})
export class FiltersFormModule {}
