"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IBadgeProtoMapper = void 0;
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const badge_pb_1 = require("minga/proto/badge/badge_pb");
const image_pb_1 = require("minga/proto/image/image_pb");
var IBadgeProtoMapper;
(function (IBadgeProtoMapper) {
    /**
     * Map a badge proto message to an IBadge.
     * @param message
     */
    function fromBadgeMessage(message) {
        const image = message.getBanner() || message.getAsset();
        const assetPaths = {};
        if (image) {
            const imageInfo = image.toObject();
            imageInfo.sizeMap.forEach(entry => {
                assetPaths[entry[0]] = entry[1];
            });
        }
        const date = shared_grpc_1.dateTimeMessageToDateObject(message.getDate());
        return {
            id: message.getId(),
            name: message.getName() || '',
            description: message.getDescription() || '',
            image: assetPaths,
            date,
            contentContextHashes: message.getContentContextHashList(),
        };
    }
    IBadgeProtoMapper.fromBadgeMessage = fromBadgeMessage;
    /**
     * Map an IBadge to a badge proto message.
     * @param badge
     */
    function toBadgeMessage(badge) {
        const message = new badge_pb_1.Badge();
        message.setId(badge.id);
        message.setName(badge.name);
        message.setDescription(badge.description);
        message.setDate(shared_grpc_1.dateObjectToDateTimeMessage(badge.date));
        const imageInfo = new image_pb_1.ImageInfo();
        const sizeMap = imageInfo.getSizeMap();
        for (let sizeKey in badge.image) {
            const imgSize = new image_pb_1.ImageSize();
            const sizePath = badge.image[sizeKey];
            imgSize.setPath(sizePath.path);
            imgSize.setWidth(sizePath.width);
            imgSize.setHeight(sizePath.height);
            sizeMap.set(sizeKey, imgSize);
        }
        // setting as banner, but could be set as asset if we were wishing to know
        // it was a custom badge image or stock banner
        message.setBanner(imageInfo);
        if (badge.contentContextHashes) {
            message.setContentContextHashList(badge.contentContextHashes);
        }
        return message;
    }
    IBadgeProtoMapper.toBadgeMessage = toBadgeMessage;
})(IBadgeProtoMapper = exports.IBadgeProtoMapper || (exports.IBadgeProtoMapper = {}));