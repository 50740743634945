import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import { Subject } from 'rxjs';

import { BottomSheetEventType } from '@shared/components/bottom-sheet';
import { CategoryItem } from '@shared/components/form/components/form-grouped-select/form-grouped-select.types';

import { UserListFilterMessages } from '../../user-list.constants';
import {
  UserListBottomSheetData,
  UserListBottomSheetResponse,
} from '../../user-list.types';

@Component({
  selector: 'mg-user-list-filter-mobile',
  templateUrl: './user-list-filter-mobile.component.html',
  styleUrls: ['./user-list-filter-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListFilterMobileComponent {
  private _lastSelectedCategory: CategoryItem;

  private readonly _clearSelectionEventSubject = new Subject<void>();
  public readonly clearSelectionEvent$ =
    this._clearSelectionEventSubject.asObservable();

  private readonly _submitFieldChangesEvent = new Subject<void>();
  public readonly submitFieldChangesEvent$ =
    this._submitFieldChangesEvent.asObservable();
  public MESSAGES = UserListFilterMessages;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: UserListBottomSheetData,
    private _bottomSheetRef: MatBottomSheetRef,
  ) {}

  public unselectAll(): void {
    this._clearSelectionEventSubject.next();
  }

  public submit(): void {
    this._submitFieldChangesEvent.next();
  }

  public submitBottomSheet(selectedLists) {
    const response: UserListBottomSheetResponse = {
      type: BottomSheetEventType.SUBMIT,
      data: {
        selectedLists,
        lastSelectedCategory: this._lastSelectedCategory,
      },
    };
    this._bottomSheetRef.dismiss(response);
  }

  public onEsc() {
    const response = {
      type: BottomSheetEventType.ESC,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public categoryChange(event) {
    this._lastSelectedCategory = event;
  }
}
