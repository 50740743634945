import { Injectable } from '@angular/core';

import { FlexRegistrationInfo } from 'minga/libraries/domain';

import { PeopleSelectorService } from '@modules/people-selector';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ReportActionService } from '@shared/services/reports';

import { FtmReportsRegMessages } from '../components/ftm-reports-reg/ftm-reports-reg.constants';
import { FtmReportsRegistrationDatasourceService } from './ftm-reports-reg-data.service';
import { FtmReportsService } from './ftm-reports.service';

@Injectable()
export class FtmReportsRegActionService extends ReportActionService<FlexRegistrationInfo> {
  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _ftmReportsService: FtmReportsService,
    private _ftmDataSourceService: FtmReportsRegistrationDatasourceService,
    public _peopleSelector: PeopleSelectorService,
  ) {
    super(snackbar, alertModal);
  }

  /** Abstract methods */
  public setTotalSelectable(total: number): void {
    this.totalSelectable = total;
  }

  /** Action methods */
  public getFlexTimeSelectionMessage(): string {
    const data = this.getSelection() as FlexRegistrationInfo[];
    return `${data.length} selected`;
  }

  public async archiveFlexRegistration(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    try {
      const confirmed = await this.openAlertModal(
        FtmReportsRegMessages.ARCHIVE_HEADING,
        '',
        FtmReportsRegMessages.ARCHIVE_LABEL,
      );

      if (confirmed) {
        const data = this.getSelection() as FlexRegistrationInfo[];
        await this._ftmReportsService.archiveFlexRegistration(data);
      }
      this._clearSelectionAndRefetch();
      return true;
    } catch (e) {
      this.openSnackBar(`Unable to archive consequence(s): ${e}`, 'error');
      return false;
    }
  }

  private async _clearSelectionAndRefetch() {
    this._ftmReportsService.applyFilter();
    this.clearSelection();
  }
}
