<div class="person-container">
  <div class="person-avatar">
    <div
      *ngIf="profileInfo.filename"
      class="profile-picture"
      [mgStudentPicture]="profileInfo.filename"></div>
    <mg-avatar
      *ngIf="!profileInfo.filename"
      [src]="profileInfo.profileImageUrl"></mg-avatar>
  </div>

  <div class="person-info">
    <mg-text
      variant="header-lg-secondary"
      [numberOfLines]="2">
      {{ profileInfo.firstName + ' ' + profileInfo.lastName }}
    </mg-text>
    <mg-text
      variant="header-sm"
      color="surface-alt"
      [numberOfLines]="1">
      {{ profileInfo.badgeRoleName }}
    </mg-text>
  </div>
</div>
