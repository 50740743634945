import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import { BehaviorSubject, interval, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HallPassStatusEnum, IHallPass } from 'minga/libraries/domain';
import { RealtimeEventType } from 'minga/libraries/domain';
import { mingaSettingTypes } from 'minga/libraries/util';
import { RealtimeEvents } from 'src/app/realtime-event/RealtimeEvents';
import { EventSubscription } from 'src/app/realtime-event/RealtimeEvents.types';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import {
  HpmDashboardPassStatus,
  makePassStatusDetails,
} from '@modules/hallpass-manager';
import { PeopleViewProfilePerson } from '@modules/people/components/people-view-profile/types';

import { ViewIdService } from '@shared/components/view-id/services/view-id.service';
import { getAppColor } from '@shared/constants';

@Component({
  selector: 'mg-tools-actions-header',
  templateUrl: './tools-actions-header.component.html',
  styleUrls: ['./tools-actions-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsActionsHeaderComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly HALL_PASS_STATUS_ENUM = HallPassStatusEnum;

  private readonly _destroyedSubject = new ReplaySubject<void>(1);
  private _realtimeSubscription: Subscription;

  public readonly timer$ = interval(1000).pipe(
    takeUntil(this._destroyedSubject),
  );
  public recentlyEndedSetting$ = this._mingaSettings.getSettingValueObs(
    mingaSettingTypes.PASS_SHOW_RECENTLY_ENDED,
  );
  public manuallyEndedSetting$ = this._mingaSettings.getSettingValueObs(
    mingaSettingTypes.PASS_MUST_MANUALLY_END,
  );

  private _activeHallPass = new BehaviorSubject<
    IHallPass & { status: HpmDashboardPassStatus } & {
      passName: string;
      passIcon: string;
      passColor: string;
    }
  >(null);
  public activeHallPass$ = this._activeHallPass.asObservable();

  @Input() person: PeopleViewProfilePerson;
  @Input() set activeHallPass(activeHallPass: IHallPass) {
    if (activeHallPass) {
      this._handleHallPass(activeHallPass);
    }
  }
  @Input() onOwnProfile: boolean;

  constructor(
    public viewId: ViewIdService,
    private _router: Router,
    private _realtimeEvents: RealtimeEvents,
    private _mingaSettings: MingaSettingsService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();

    if (this._realtimeSubscription) {
      this._realtimeSubscription.unsubscribe();
    }
  }

  public openId() {
    if (this.person) {
      this._router.navigate(
        [
          '',
          {
            outlets: { o: [this.viewId.getIdOverlayRoute(), this.person.hash] },
          },
        ],
        {},
      );
    }
  }

  private _handleHallPass(hallPass) {
    const { hallPassType } = hallPass;

    const start = hallPass?.startDate;
    const expire = hallPass?.expiredDate;
    const end = hallPass?.endDate;
    const denied = hallPass?.deniedAt;
    const now = day();

    const status = makePassStatusDetails(
      now,
      start,
      expire,
      end,
      false, // were only showing active passes atm
      denied,
    );

    this._activeHallPass.next({
      ...hallPass,
      status,
      passName: hallPassType ? hallPassType.name : '',
      passIcon: hallPassType ? hallPassType.bannerHash : '',
      passColor: hallPassType ? hallPassType.color : getAppColor('surface'),
    });

    this._handleRealtimeEvent(hallPass.recipientPersonViewMinimal?.personHash);
  }

  private _handleRealtimeEvent(userHash: string) {
    if (!userHash) return;

    if (this.onOwnProfile) {
      this._realtimeSubscription = this._realtimeEvents
        .observe([RealtimeEventType.HALL_PASS_ENDED])
        .pipe(takeUntil(this._destroyedSubject))
        .subscribe(data => this._handlePassEndEvent(data));
    } else {
      this._realtimeSubscription = this._realtimeEvents
        .observeUser(userHash, [RealtimeEventType.HALL_PASS_ENDED])
        .pipe(takeUntil(this._destroyedSubject))
        .subscribe(data => this._handlePassEndEvent(data));
    }
  }

  private _handlePassEndEvent(
    data: EventSubscription<RealtimeEventType.HALL_PASS_ENDED>,
  ) {
    const id = data.payload.notification?.metadata?.hallPass?.id;

    const activeHallPass = this._activeHallPass.getValue();

    if (id === activeHallPass?.id) {
      this._activeHallPass.next(null);
    }
  }
}
