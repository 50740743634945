import Fuse from 'fuse.js';

import { FormSheetSelectData, FormSheetSelectOption } from '../types';

export enum FormSheetSelectMessage {
  LABEL_UNSELECT_ALL = 'Unselect all',
  LABEL_UNSELECT = 'Unselect',
  LABEL_CANCEL = 'Cancel',
  LABEL_DONE = 'Done',

  EMPTY_STATE_TITLE = 'No results found',
  EMPTY_STATE_SUBTITLE = 'Try a different search',
}

export const DEFAULT_MOBILE_SELECT_DATA: FormSheetSelectData = {
  title: 'Select',
  enableSearch: true,
  multiple: false,
  options: [],
};

export const FORM_SHEET_SELECT_FUSE_OPTIONS: Fuse.IFuseOptions<FormSheetSelectOption> =
  {
    isCaseSensitive: false,
    shouldSort: true,
    keys: ['label', 'value'],
    threshold: 0.2,
  };

export const FORM_SHEET_SELECT_SEARCH_DEBOUNCE = 300;
