<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px">
  <mg-tools-actions-header
    *ngIf="person$ | async; else headerLoader"
    [person]="person$ | async"
    [onOwnProfile]="true">
    <ng-container *ngIf="hasAssignActions$ | async">
      <mg-btn
        class="dropdown-btn"
        [matMenuTriggerFor]="assignDropdown"
        variant="outlined"
        iconSvg="/assets/teacher-tools/assign.svg"
        iconSet="minga"
        iconRight="mg-drop-down-arrow">
        {{ MESSAGES.ASSIGN_BTN }}
      </mg-btn>
      <mat-menu #assignDropdown="matMenu">
        <ng-container *ngFor="let option of assignActions">
          <button
            *ngIf="option.hasPermission$ | async"
            mat-menu-item
            class="action-dropdown"
            variant="outlined"
            [attr.data-analytics]="option.id"
            [attr.data-test]="option.id"
            (click)="option.onPress()">
            <img
              [src]="option.icon"
              [alt]="option.label" />
            <mg-text variant="body-md">{{ option.label }} </mg-text>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
    <ng-container *ngIf="hasPointsActions$ | async">
      <mg-btn
        class="dropdown-btn"
        [matMenuTriggerFor]="pointsDropdown"
        variant="outlined"
        iconSvg="/assets/teacher-tools/points_img.svg"
        iconSet="minga"
        iconRight="mg-drop-down-arrow">
        {{ MESSAGES.POINTS_BTN }}
      </mg-btn>
      <mat-menu #pointsDropdown="matMenu">
        <ng-container *ngFor="let option of pointsActions">
          <button
            *ngIf="option.hasPermission$ | async"
            mat-menu-item
            class="action-dropdown"
            variant="outlined"
            (click)="option.onPress()">
            <img
              [src]="option.icon"
              [alt]="option.label" />
            <mg-text variant="body-md">{{ option.label }} </mg-text>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>

    <mg-btn
      *ngIf="kioskAction?.hasPermission$ | async"
      variant="outlined"
      iconSvg="/assets/teacher-tools/kiosk-img.svg"
      [id]="'tools-dashboard-click-kiosk'"
      (pressed)="kioskAction.onPress()">
      {{ kioskAction.label }}
    </mg-btn>
  </mg-tools-actions-header>

  <section class="filters">
    <mg-text variant="header-md-secondary">
      {{ MESSAGES.USAGE_STATS }}
    </mg-text>
    <mg-form-select
      [disabled]="loadingStats$ | async"
      [floatingLabel]="false"
      [condensed]="true"
      [options]="dateRangeOptions"
      [control]="dateRangeControl"
      (selectionChanged)="handleDateRangeChange($event)">
    </mg-form-select>
  </section>

  <div
    *ngIf="
      (loadingStats$ | async) === false && mingaStats$ | async as stats;
      else loadingTemplate
    "
    class="metric-cards">
    <mg-tools-metrics-card
      [disabled]="('MINGA_ACCOUNT_MANAGE' | hasPermission) === false"
      [metric]="'active-users'"
      [id]="'tools-dashboard-click-metric-active-users'"
      [value]="stats?.activeUserCount"
      (pressed)="
        handleStatNavigate([
          MINGA_MANAGER_ROUTES.ROOT,
          MINGA_MANAGER_ROUTES.REPORTS
        ])
      ">
    </mg-tools-metrics-card>
    <mg-tools-metrics-card
      [metric]="'hall-pass'"
      [id]="'tools-dashboard-click-metric-hallpass'"
      [value]="stats?.hallPassCount"
      [moduleEnabled]="isHallpassEnabled$ | async"
      (pressed)="
        handleStatNavigate([
          HPM_ROUTES.ROOT,
          HPM_ROUTES.REPORTS,
          REPORT_TYPES.HALL_PASS_HISTORY
        ])
      ">
    </mg-tools-metrics-card>
    <mg-tools-metrics-card
      [metric]="'check-in'"
      [id]="'tools-dashboard-click-metric-checkin'"
      [value]="stats?.checkinCount"
      [moduleEnabled]="isCheckinEnabled$ | async"
      (pressed)="
        handleStatNavigate([
          CHECKIN_MANAGER_ROUTES.ROOT,
          CHECKIN_MANAGER_ROUTES.REPORTS,
          REPORT_TYPES.CHECKIN_HISTORY
        ])
      ">
    </mg-tools-metrics-card>
    <mg-tools-metrics-card
      [metric]="'points'"
      [id]="'tools-dashboard-click-metric-points'"
      [value]="stats?.pointCount"
      [moduleEnabled]="isBehaviorEnabled$ | async"
      (pressed)="
        handleStatNavigate([
          POINTS_MANAGER_ROUTES.ROOT,
          POINTS_MANAGER_ROUTES.REPORTS,
          REPORT_TYPES.POINTS_HISTORY
        ])
      ">
    </mg-tools-metrics-card>
    <mg-tools-metrics-card
      [metric]="'behavior'"
      [id]="'tools-dashboard-click-metric-behaviors'"
      [value]="stats?.behaviorCount"
      [moduleEnabled]="isBehaviorEnabled$ | async"
      (pressed)="
        handleStatNavigate([
          BEHAVIOR_MANAGER_ROUTES.ROOT,
          BEHAVIOR_MANAGER_ROUTES.REPORTS,
          REPORT_TYPES.PBIS_HISTORY
        ])
      ">
    </mg-tools-metrics-card>
  </div>

  <div class="stickers-groups">
    <mg-tools-stickers [personHash]="(person$ | async)?.hash">
    </mg-tools-stickers>
    <mg-tools-groups
      *ngIf="isGroupsEnabled$ | async"
      [personHash]="(person$ | async)?.hash">
    </mg-tools-groups>
  </div>
</section>

<!-- Loading template -->
<ng-template #loadingTemplate>
  <mg-tools-stats-placeholder
    [quantity]="totalStatsCount$ | async"></mg-tools-stats-placeholder>
</ng-template>

<ng-template #headerLoader>
  <mg-placeholder-card>
    <div class="tw-grid tw-gap-2.5">
      <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <mg-placeholder-avatar size="medium"></mg-placeholder-avatar>
        <mg-placeholder-header
          class="tw-w-2/3"
          size="medium"></mg-placeholder-header>
      </div>
      <mg-placeholder-button class="tw-w-1/3"></mg-placeholder-button>
    </div>
  </mg-placeholder-card>
</ng-template>
