import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import * as mgUtil from 'minga/libraries/util';
import { SuccessDialog } from 'minga/app/src/app/dialog';
import { SaveCancelDialog } from 'minga/app/src/app/dialog/SaveCancel';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { StreamManager } from 'minga/app/src/app/minimal/services/StreamManager';
import { IModerationImages } from 'minga/app/src/app/moderation';
import { UserStorage } from 'minga/app/src/app/services/UserStorage';
import { getCurrentMinga } from 'minga/app/src/app/store/Minga';
import { MingaMinimalModel } from 'minga/libraries/domain';
import { ModerationResultMapper } from 'minga/libraries/shared-grpc';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { ReportContent as ReportContentService } from 'minga/proto/gateway/content_report_ng_grpc_pb';
import {
  OverrideModerationRequest,
  ReportContentRequest,
  ReportedUgcRequest,
  ReportedUgcResponse,
  ReportStatsRequest,
  UnReportContentRequest,
} from 'minga/proto/gateway/content_report_pb';
import {
  DetailedModerationResult,
  ModerationResult,
} from 'minga/proto/gateway/moderation_pb';
import { ImageInfo } from 'minga/proto/image/image_pb';
import { RootService } from 'src/app/minimal/services/RootService';
import {
  ModerationFailedAction,
  ModerationOverrideSuccess,
} from 'src/app/store/root/rootActions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export const DEFAULT_CUSTOM_REPORT_OPTION = 99;

export interface IReportContextStatus {
  resolved: boolean;
}

export interface IDeletedContextReasons {
  reasons: string[];
}

export interface ITagsByImage {
  tags: { [key: string]: number };
  key: string;
}

export interface IModerationImageDetails {
  tagsByImage?: ITagsByImage[];
}
export interface IModerationActionOptions {
  contextHash?: string;
  galleryPhotoUuid?: string;
}

export interface IModerationOverrideAiParams extends IModerationActionOptions {
  contentHash?: string;
  reasonList: string[];
}

/**
 * Handle submitting, resolving and checking if a context hash has been
 * reported. Reporting refers to a user marking content as something
 * they find offensive or not appropriate and thus shoud not be in the app,
 * when reported an email and notifications are sent to school administrators.
 */
@Injectable({ providedIn: 'root' })
export class ReportService {
  private _reportedContextHashes: Map<string, IReportContextStatus>;
  private _reportedGalleryPhotoUuids: Map<string, IReportContextStatus>;
  private _deletedContextHashes: Map<string, IDeletedContextReasons>;
  private _deletedGalleryPhotoUuids: Map<string, IDeletedContextReasons>;
  private _localReportedContextStorageKey = '';
  private _localReportedGalleryPhotoStorageKey = '';
  private _localDeletedStorageKey = '';
  private _localDeletedPhotoStorageKey = '';
  private _moderationImageList: ImageInfo.AsObject[];
  private _moderationImageDetails: IModerationImageDetails;
  // event to update report page info
  private _onReportUpdate: Subject<any>;
  private _mingaInfo$: Observable<MingaMinimalModel> = this._store.pipe(
    select(getCurrentMinga),
  );
  private _currentGalleryModerationLightboxUuid = '';

  constructor(
    private _reportManager: ReportContentService,
    private _dialog: MatDialog,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _streamManager: StreamManager,
    private _localStorage: UserStorage,
    private _router: Router,
    private _store: Store<any>,
    private _analytics: AnalyticsService,
    private _location: Location,
    private _rootService: RootService,
  ) {
    this._reportedContextHashes = new Map();
    this._reportedGalleryPhotoUuids = new Map();
    this._deletedContextHashes = new Map();
    this._deletedGalleryPhotoUuids = new Map();

    this._onReportUpdate = new Subject();
    this._mingaInfo$.subscribe(minga => {
      if (minga) {
        this.initFromLocalStorage(minga.name);
      }
    });
  }

  get contentImageList() {
    return this._moderationImageList;
  }

  get contentDetailsList() {
    return this._moderationImageDetails;
  }

  get onReportUpdate(): Observable<any> {
    return this._onReportUpdate.asObservable();
  }

  emitReportUpdate() {
    this._onReportUpdate.next();
  }

  /**
   * Get the tags for an image from the additional details passed by moderation
   * engines so that we can display the info on the front end.
   *
   * @param filePath - raw file path of image that was moderated
   */
  public getTagsForImageByFilePath(filePath: string): string | null {
    if (
      (this._moderationImageDetails as IModerationImageDetails).tagsByImage !==
      undefined
    ) {
      const tagsByImage = this._moderationImageDetails.tagsByImage;
      const image = tagsByImage.find(e => e.key === filePath);
      if (image && image.tags) {
        const tags = [];
        for (const key in image.tags) {
          if (image.tags.hasOwnProperty(key)) {
            const score = image.tags[key];
            const text = key + ' (' + Math.round(score * 100) / 100 + ')';
            tags.push(text);
          }
        }
        return tags.join(', ');
      }
      return null;
    }
  }

  async initFromLocalStorage(mingaName: string) {
    if (mingaName) {
      this._localReportedContextStorageKey = `reportedContextHashes_${mingaName}`;
      this._localReportedGalleryPhotoStorageKey = `reportedGalleryPhotoUuids_${mingaName}`;
      this._localDeletedStorageKey = `deletedContextHashes_${mingaName}`;
      this._localDeletedPhotoStorageKey = `deletedPhotoGalleryUuids_${mingaName}`;
      const storedReportedContextMap = await this._localStorage.getItem(
        this._localReportedContextStorageKey,
      );
      const storedReportedGalleryPhotoMap = await this._localStorage.getItem(
        this._localReportedGalleryPhotoStorageKey,
      );
      const storedDeletedMap = await this._localStorage.getItem(
        this._localDeletedStorageKey,
      );
      if (storedReportedContextMap && storedReportedContextMap instanceof Map) {
        this._reportedContextHashes = storedReportedContextMap;
      }
      if (
        storedReportedGalleryPhotoMap &&
        storedReportedGalleryPhotoMap instanceof Map
      ) {
        this._reportedGalleryPhotoUuids = storedReportedGalleryPhotoMap;
      }
      if (storedDeletedMap && storedDeletedMap instanceof Map) {
        this._deletedContextHashes = storedDeletedMap;
      }
    } else {
      console.warn("minga info not loaded yet, can't init from local storage");
    }
  }
  /**
   * Open the 'view images' display to see the moderation status of each image.
   * The data to be viewed is sent via an event from the reports screen.
   *
   * @param result - data sent via an event from reports page
   */
  async openImageList(result: IModerationImages) {
    const images: ImageInfo.AsObject[] = result.images;
    this._moderationImageDetails = this._getModerationImageDetails(
      result.moderationDetails,
    );

    if (!images || !images.length) {
      throw new Error(`Cannot open ImageList without any images`);
    }

    this._moderationImageList = images;
    await this._router.navigate([
      '',
      { outlets: { o: ['view', 'content', 'images'] } },
    ]);
  }
  /**
   * Moderation engines can pass additional details about the item
   * that can be used on the front end. This sets the details up
   * to be used.
   *
   * @param result
   */
  private _getModerationImageDetails(
    result: DetailedModerationResult.ProviderDetails.AsObject[],
  ) {
    const details: IModerationImageDetails = {};
    console.log(`@TODO: getModerationImageDetails`);
    // result.forEach(element => {
    //   const itemDetails = element.details;
    //   if(itemDetails) {
    //     details = Object.assign(details, (JSON.parse(itemDetails)));
    //   }
    // });
    return details;
  }

  /**
   * Submit a new report on a piece of content.
   *
   * @param contextHash context hash of the content to be deleted
   * @param reasonIndex index of ReportReason
   * @param comment optional custom report reason
   * @returns bool whether the report was sent successfully or not.
   */
  async submitNew(
    options: IModerationActionOptions,
    reasonIndex: number,
    comment: string = '',
  ) {
    const request = new ReportContentRequest();
    request.setContextHash(options.contextHash || '');
    request.setGalleryPhotoUuid(options.galleryPhotoUuid || '');

    const reason =
      reasonIndex === DEFAULT_CUSTOM_REPORT_OPTION
        ? mgUtil.ReportReason.CUSTOM.toString()
        : mgUtil.ReportReason.toString(
            mgUtil.ReportReason[mgUtil.ReportReason[reasonIndex]],
          );

    request.setReason(reason);

    // index will start at 1, 0 is other/custom
    if (reasonIndex !== DEFAULT_CUSTOM_REPORT_OPTION) {
      request.setReasonIndex(reasonIndex + 1);
    } else {
      request.setReasonIndex(mgUtil.ReportReason.CUSTOM);
    }
    request.setComment(comment);

    const handleError = err => {
      console.error(`[Report] submitNew() error occurred`, err);

      // return false so that submitting component knows an error occured
      return false;
    };
    try {
      const response = await this._reportManager.report(request);
      const status = response.getStatus();

      if (status === StatusCode.OK) {
        this.restartReportRelatedStreams();
        // mark this context as reported
        if (options.contextHash) {
          this;
        }
        this.setReportedUgc(options, false);

        return true;
      } else {
        handleError(response.getReason());
      }
    } catch (err) {
      handleError(err);
    }
  }

  restartReportRelatedStreams() {
    this._streamManager.restartStreamIfAvailable('HomeFeed');
    this._streamManager.restartStreamIfAvailable('GalleryFeed');
    //  restart reports pages' streams
    this._streamManager.restartStreamIfAvailable('MingaReportFeed');
    this._streamManager.restartStreamIfAvailable('MingaGlobalReportFeed');
    this._streamManager.restartStreamIfAvailable('MingaModerationFeed');
    this._streamManager.restartStreamIfAvailable('MingaGlobalModerationFeed');
    // refresh reports info
    this.emitReportUpdate();
  }

  /**
   * Unreport a piece of content per a given minga, using the context hash.
   *
   * @param options the content's context hash or photo uuid that is to be
   * un-reported/resolved
   * @returns boolean if was successful
   */
  async resolve(options: IModerationActionOptions) {
    const request = new UnReportContentRequest();
    request.setContextHash(options.contextHash || '');
    request.setGalleryPhotoUuid(options.galleryPhotoUuid || '');
    const response = await this._reportManager.resolve(request);
    const status = response.getStatus();

    if (status === StatusCode.OK) {
      const dialog = this._dialog.open(SuccessDialog, {
        data: { text: 'Content Resolved!' },
      });
      this.restartReportRelatedStreams();

      this.setReportedUgc(options, true);

      return true;
    } else {
      this._systemAlertSnackBar.error(
        `an error occurred while resolving, please try again.`,
      );
      return false;
    }
  }

  private async _confirmOverride(blockedReasons: string[] = []) {
    return new Promise((resolve, reject) => {
      let bodyParams = '';
      for (let i = 0; i < blockedReasons.length; i++) {
        bodyParams += blockedReasons[i];
        if (i + 2 < blockedReasons.length) {
          bodyParams += ',';
        } else if (i + 2 === blockedReasons.length) {
          bodyParams += ' and ';
        }
      }
      if (!blockedReasons.length) {
        bodyParams = 'deleted';
      }

      const options = {
        data: {
          text: 'dialog.override.title',
          body: 'dialog.override.body',
          bodyParams,
          saveButtonLocale: 'button.override',
        },
      };
      const dialog = this._dialog.open(SaveCancelDialog, options);
      dialog.afterClosed().subscribe(result => {
        const response = result === true ? true : false;
        resolve(response);
      });
    });
  }

  /**
   * Unblock a piece of content that had been blocked by ai engine(s), using
   * the content hash.
   *
   * @returns boolean if was successful
   */
  async overrideAI(params: IModerationOverrideAiParams) {
    const proceed = await this._confirmOverride(params.reasonList);
    if (!proceed) return;

    const request = new OverrideModerationRequest();
    request.setContentHash(params.contentHash || '');
    request.setContextHash(params.contextHash || '');
    request.setGalleryPhotoUuid(params.galleryPhotoUuid || '');

    const response = await this._rootService.addLoadingPromise(
      this._reportManager.override(request),
    );
    const status = response.getStatus();
    if (status === StatusCode.OK) {
      const dialog = this._dialog.open(SuccessDialog, {
        data: { text: 'Content Published!' },
      });
      this.restartReportRelatedStreams();
      this._store.dispatch(new ModerationOverrideSuccess());
      this._analytics.sendOverrideBlockEvent();
      return true;
    } else {
      this._systemAlertSnackBar.error(`an error occurred, please try again.`);
      return false;
    }
  }

  private _getReportStatusFromActionOptions(
    options: IModerationActionOptions,
  ): IReportContextStatus | undefined {
    let element: IReportContextStatus | undefined;
    if (options.contextHash) {
      element = this._reportedContextHashes.get(options.contextHash);
    } else if (options.galleryPhotoUuid) {
      element = this._reportedGalleryPhotoUuids.get(options.galleryPhotoUuid);
    }
    return element;
  }

  /**
   * Check against local stored reported content if a given
   * ugc is reported.
   *
   * @returns bool if the context hash has been reported (on local storage)
   */
  isReported(options: IModerationActionOptions) {
    const element = this._getReportStatusFromActionOptions(options);

    if (!element) {
      return false;
    } else {
      return !element.resolved;
    }
  }

  /**
   * Check against local stored reported content context hashes if a given
   * context hash is resolved.
   *
   * @returns bool if the context hash has been resolved (on local storage)
   */
  isResolved(options: IModerationActionOptions) {
    const element = this._getReportStatusFromActionOptions(options);

    if (!element) {
      return false;
    } else {
      return element.resolved;
    }
  }

  getDeletedReasons(contextHash: string): IDeletedContextReasons {
    return this._deletedContextHashes.get(contextHash);
  }

  hasBeenDeleted(contextHash: string): boolean {
    return this._deletedContextHashes.has(contextHash);
  }

  getDeletedPhotoReasons(uuid: string): IDeletedContextReasons {
    return this._deletedGalleryPhotoUuids.get(uuid);
  }

  hasGalleryPhotoBeenDeleted(uuid: string): boolean {
    return this._deletedGalleryPhotoUuids.has(uuid);
  }

  /**
   * Opens gallery lightbox for a given photo, stores the uuid to be consumed.
   *
   * @param photo detailed moderation result of a gallery photo
   */
  openGalleryPhotoModerationLightbox(photo: DetailedModerationResult.AsObject) {
    const uuid = photo.galleryPhotoUuid;
    if (!uuid) {
      throw new Error(
        `openGalleryPhotoModerationLightbox() missing uuid for gallery photo`,
      );
    }

    if (
      photo.status === mgUtil.ContentStatus.DELETED ||
      photo.status === mgUtil.ContentStatus.BLOCKED
    ) {
      this.setDeletedGalleryPhoto(photo);
    }
    if (photo.status === mgUtil.ContentStatus.REPORTED) {
      this.setReportedUgc({ galleryPhotoUuid: uuid });
    }
    // store the uuid to be consumed later
    this._currentGalleryModerationLightboxUuid = uuid;
    this._router.navigate(['', { outlets: { o: ['gallery', uuid] } }]);
  }

  /**
   * If there is a current gallery moderation item, use/reset and return it.
   */
  consumeGalleryPhotoModerationLightbox(): string {
    const uuid = this._currentGalleryModerationLightboxUuid;

    // reset moderation gallery photo item
    this._currentGalleryModerationLightboxUuid = '';

    return uuid;
  }

  /**
   * Go back in location and then after routing has changed, open report dialog.
   *
   * @param uuid gallery photo uuid
   */
  async backAndOpenReportOverlay(uuid: string) {
    // go back
    this._location.back();
    // once navigation has ended open report overlay
    const routerSub = this._router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        routerSub.unsubscribe();
        setTimeout(() => {
          const reportNav = { outlets: { o: ['report', '', uuid] } };
          this._router.navigate(['', reportNav]);
        });
      }
    });
  }

  /**
   * Check if a given user generated content is in local storage.
   *
   * @param options contexthash or gallery photo key
   * @returns bool if the context hash has been stored locally.
   */
  isStoredLocally(options: IModerationActionOptions) {
    return !!this._getReportStatusFromActionOptions(options);
  }

  /**
   * Set a piece of content locally as resolved, defaults to false
   *
   * @param options the context hash/ gallery photo uuid to set the reported
   *   status of (default:
   * false)
   * @param resolved bool - set to true to mark as resolved
   */
  setReportedUgc(options: IModerationActionOptions, resolved: boolean = false) {
    // update localstorage
    if (options.contextHash) {
      this._reportedContextHashes.set(options.contextHash, { resolved });
      this._localStorage.setItem(
        this._localReportedContextStorageKey,
        this._reportedContextHashes,
      );
    } else if (options.galleryPhotoUuid) {
      this._reportedGalleryPhotoUuids.set(options.galleryPhotoUuid, {
        resolved,
      });
      this._localStorage.setItem(
        this._localReportedGalleryPhotoStorageKey,
        this._reportedGalleryPhotoUuids,
      );
    }
  }

  private _getReasonListFromModerationResult(
    input: DetailedModerationResult.AsObject,
  ): string[] {
    const reasons: string[] = [];
    for (const historyItem of input.contentHistoryList) {
      for (const detailItem of historyItem.detailsList) {
        for (const tag of detailItem.tagsList) {
          reasons.push(tag.key);
        }
      }
    }
    return reasons;
  }

  /**
   * Set a piece of content locally as deleted, extracts the reasons from the
   * content.
   *
   * @param contextHash the context hash to set the deleted status of (default:
   * false)
   */
  setDeletedContent(content: DetailedModerationResult.AsObject) {
    const hasDeletedStatus =
      content.status === mgUtil.ContentStatus.DELETED ||
      content.status === mgUtil.ContentStatus.BLOCKED;
    const contextHash = content.contentContextHash;
    if (!hasDeletedStatus) {
      if (!!this.getDeletedReasons(contextHash)) {
        // if content isn't deleted but is stored as so, update the storage
        this.removeDeletedContextHash(contextHash);
      }
      return;
    }

    const reasons: string[] = this._getReasonListFromModerationResult(content);

    this.setDeletedContextHash(contextHash, reasons);
  }

  /**
   * Set a piece of content locally as deleted, with reasons array
   *
   * @param contextHash the context hash to set the deleted reasons
   * @param reasons string[]  arrau of reasons for blocking/deleting
   */
  setDeletedContextHash(contextHash: string, reasons: string[]) {
    this._deletedContextHashes.set(contextHash, { reasons });
    // update localstorage
    this._localStorage.setItem(
      this._localDeletedStorageKey,
      this._deletedContextHashes,
    );
  }

  removeDeletedContent(content: DetailedModerationResult.AsObject) {
    this.removeDeletedContextHash(content.contentContextHash);
  }

  setDeletedGalleryPhoto(photo: DetailedModerationResult.AsObject) {
    const hasDeletedStatus =
      photo.status === mgUtil.ContentStatus.DELETED ||
      photo.status === mgUtil.ContentStatus.BLOCKED;
    const uuid = photo.galleryPhotoUuid;

    if (!hasDeletedStatus) {
      if (!!this.getDeletedPhotoReasons(uuid)) {
        // if photo isn't deleted but is stored as so, update the storage
        this.removeDeletedGalleryPhotoUuid(uuid);
      }
      return;
    }

    const reasons: string[] = this._getReasonListFromModerationResult(photo);

    this.setDeletedGalleryPhotoUuid(uuid, reasons);
  }

  setDeletedGalleryPhotoUuid(uuid: string, reasons: string[]) {
    this._deletedGalleryPhotoUuids.set(uuid, { reasons });

    // update localstorage
    this._localStorage.setItem(
      this._localDeletedPhotoStorageKey,
      this._deletedGalleryPhotoUuids,
    );
  }

  removeDeletedGalleryPhotoUuid(uuid: string) {
    this._deletedGalleryPhotoUuids.delete(uuid);

    // update localstorage
    this._localStorage.setItem(
      this._localDeletedPhotoStorageKey,
      this._deletedGalleryPhotoUuids,
    );
  }

  removeDeletedContextHash(contextHash: string) {
    this._deletedContextHashes.delete(contextHash);
    // update localstorage
    this._localStorage.setItem(
      this._localDeletedStorageKey,
      this._deletedContextHashes,
    );
  }

  /**
   * Request all the context hashes that are in reported status from the server.
   */
  async retrieveReportedUgc() {
    const response: ReportedUgcResponse =
      await this._reportManager.retrieveReportedUgc(new ReportedUgcRequest());

    const ugcList = response.getUgcList();
    // reset local stored context hashes, as we're getting the latest
    // word of god (server) data
    this._reportedContextHashes.clear();
    this._reportedGalleryPhotoUuids.clear();
    for (const ugc of ugcList) {
      const contextHash = ugc.getContextHash();
      const galleryPhotoUuid = ugc.getGalleryPhotoUuid();
      this.setReportedUgc({ contextHash, galleryPhotoUuid }, false);
    }
  }

  /**
   * Request all the stats of filters applicable per user permissions.
   */
  async retrieveStats(global: boolean = false) {
    const request = new ReportStatsRequest();
    request.setGlobal(global);

    const response = await this._reportManager.retrieveStats(request);

    if (response.getStatus() === StatusCode.OK) {
      const responseObj = response.toObject();
      const stats = responseObj.statList;
      const statsObj: any = {};
      for (const stat of stats) {
        statsObj[stat.reportStat] = stat.value;
      }

      return statsObj;
    }
    return {};
  }

  /**
   * Check ModerationResult of a given piece of content, matching a given
   * context hash. If failed fire a failed moderation event and store the
   * context hash in the deleted context hashes for overriding the content's
   * moderation.
   *
   * @return boolean false if failed moderation
   * @param modResult
   * @param contextHash
   */
  async handleContentModerationResult(
    modResult: ModerationResult,
    contextHash: string,
  ): Promise<boolean> {
    const moderation = ModerationResultMapper.toIModerationResult(modResult);

    if (!moderation.allPassed) {
      this._store.dispatch(
        new ModerationFailedAction({
          contextHash,
          moderation,
          emailContentSend: null,
        }),
      );
      // add to deleted photos as did not pass ai moderation
      this.setDeletedContextHash(contextHash, []);

      return false;
    }
    return true;
  }

  /**
   * Check ModerationResult of multiple gallery photos, matching given photo
   * uuids. If failed fire a failed moderation event and store the photo uuid in
   * the deleted pho uuids for overriding the gallery photo's moderation.
   *
   * @return boolean false if failed moderation
   * @param modResults
   * @param galleryPhotoUuids
   */
  async handlePhotoModerationResults(
    modResults: ModerationResult[],
    galleryPhotoUuids: string[],
  ): Promise<boolean> {
    if (galleryPhotoUuids && galleryPhotoUuids.length !== modResults.length) {
      throw new Error(
        `handleModerationResults missing galleryPhotoUuids for moderation results`,
      );
    }

    for (let i = 0; i < modResults.length; i++) {
      const moderation = ModerationResultMapper.toIModerationResult(
        modResults[i],
      );
      const galleryPhotoUuid = galleryPhotoUuids[i];

      if (!moderation.allPassed && galleryPhotoUuid) {
        this._store.dispatch(
          new ModerationFailedAction({
            galleryPhotoUuid,
            moderation,
            emailContentSend: null,
          }),
        );
        // add to deleted photos as did not pass ai moderation
        this.setDeletedGalleryPhotoUuid(galleryPhotoUuid, []);

        return false;
      }
    }

    return true;
  }
}
