"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.totalTimeAccessorFn = exports.assignedToAccessorFn = void 0;
const domain_1 = require("minga/libraries/domain/index");
const common_utils_1 = require("minga/libraries/shared/reports_columns/utils/common.utils");
exports.assignedToAccessorFn = (item, name) => {
    if (item === null || item === void 0 ? void 0 : item.person)
        return common_utils_1.nameFinder(item.person, name);
    if (item === null || item === void 0 ? void 0 : item.recipientPersonViewMinimal)
        return common_utils_1.nameFinder(item.recipientPersonViewMinimal, name);
    return '';
};
exports.totalTimeAccessorFn = (item) => {
    var _a, _b;
    return (domain_1.formatMinutesIntoHours((item === null || item === void 0 ? void 0 : item.totalTime) || ((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.totalTime) || ((_b = item === null || item === void 0 ? void 0 : item.expiredStats) === null || _b === void 0 ? void 0 : _b.totalExpiredTime)) || '0');
};