import { IMingaFeatureToggleKeys } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { MingaAppMainRoutes } from 'src/app/types';

import { BehaviorManagerRoutes } from '@modules/behavior-manager';
import { CheckinManagerRoutes } from '@modules/checkin-manager';
import { EventManagerRoutes } from '@modules/event-manager';
import { FlexTimeManagerRoutes } from '@modules/flex-time-manager';
import { HpmRoutes } from '@modules/hallpass-manager';
import { IdRoute } from '@modules/id';
import { MingaManagerRoutes } from '@modules/minga-manager';
import { PeopleRoute } from '@modules/people/types';
import { PointsManagerRoutes } from '@modules/points-manager';

import { AccessRestrictionsGuard, AuthGuard } from '@shared/guards';

export const MANAGER_ROUTES: MingaAppMainRoutes = [
  // People
  {
    path: PeopleRoute.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'People',
      description: 'People',
      navigationVisibility: true,
      icon: {
        default: 'mg-people-menu-o',
        active: 'mg-people-menu',
      },
      accessRestrictions: {
        permissions: {
          atLeastOne: [
            MingaPermission.MINGA_PEOPLE_MANAGE,
            MingaPermission.USER_LIST_VIEW,
            MingaPermission.VIEW_PROFILE,
          ],
        },
      },
    },
    loadChildren: () => import('@modules/people').then(m => m.PeopleModule),
  },
  // Check in
  {
    path: CheckinManagerRoutes.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'Check in',
      description: 'Manage check in, view reports and change settings',
      navigationVisibility: true,
      icon: {
        default: 'mg-checkin-menu-o',
        active: 'mg-checkin-menu',
      },
      accessRestrictions: {
        featureFlag: {
          only: [IMingaFeatureToggleKeys.CHECKIN_ENABLED],
        },
        permissions: {
          atLeastOne: [
            MingaPermission.CHECKIN_TYPE_MANAGE,
            MingaPermission.CHECKIN_VIEW_REPORTS,
          ],
        },
      },
      hasPromotionalModal: true,
    },
    loadChildren: () =>
      import('@modules/checkin-manager').then(m => m.CheckinRoutesModule),
  },
  // Hall pass
  {
    path: HpmRoutes.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'Hall Pass',
      description: 'Manage your hall passes, view reports and change settings',
      navigationVisibility: true,
      icon: {
        default: 'mg-hallpass-menu-o',
        active: 'mg-hallpass-menu',
      },
      accessRestrictions: {
        featureFlag: {
          only: [IMingaFeatureToggleKeys.HALLPASS_ENABLED],
        },
        permissions: {
          atLeastOne: [
            MingaPermission.HALL_PASS_MANAGE,
            MingaPermission.HALL_PASS_VIEW_LIMITED_REPORTS,
            MingaPermission.HALL_PASS_VIEW_REPORTS,
          ],
        },
      },
      hasPromotionalModal: true,
    },
    loadChildren: () =>
      import('@modules/hallpass-manager').then(m => m.HpmRoutesModule),
  },
  // Minga
  {
    path: MingaManagerRoutes.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'Settings',
      description: 'Manage your minga, view reports and change settings',
      navigationVisibility: true,
      icon: {
        default: 'mg-minga-menu-o',
        active: 'mg-minga-menu',
      },
      accessRestrictions: {
        permissions: {
          atLeastOne: [MingaPermission.MINGA_MANAGE],
        },
      },
    },
    loadChildren: () =>
      import('@modules/minga-manager').then(m => m.MingaManagerRoutesModule),
  },
  // Behavior
  {
    path: BehaviorManagerRoutes.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'Behaviors',
      navigationVisibility: true,
      description: 'Manage behavior points, rewards, and accounts',
      icon: {
        default: 'mg-behavior-menu-o',
        active: 'mg-behavior-menu',
      },
      accessRestrictions: {
        featureFlag: {
          only: [IMingaFeatureToggleKeys.TRACKING_ENABLED],
        },
        permissions: {
          atLeastOne: [
            MingaPermission.PBIS_VIEW_REPORTS,
            MingaPermission.PBIS_TYPE_MANAGE,
          ],
        },
      },
      hasPromotionalModal: true,
    },
    loadChildren: () =>
      import('@modules/behavior-manager').then(
        m => m.BehaviorManagerRoutesModule,
      ),
  },
  // FlexTime
  {
    path: FlexTimeManagerRoutes.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'FlexTime',
      description: 'Manage flex time activities and view reports',
      navigationVisibility: true,
      icon: {
        active: 'mg-flextime-menu',
        default: 'mg-flextime-menu-o',
      },
      accessRestrictions: {
        featureFlag: {
          only: [IMingaFeatureToggleKeys.FLEX_TIME_ENABLED],
        },
        permissions: {
          atLeastOne: [
            MingaPermission.FLEX_TIME_MANAGE,
            MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
            MingaPermission.FLEX_TIME_VIEW_REPORTS,
          ],
        },
      },
      hasPromotionalModal: true,
    },
    loadChildren: () =>
      import('@modules/flex-time-manager').then(
        m => m.FlexTimeManagerRoutesModule,
      ),
  },
  // Events
  {
    path: EventManagerRoutes.ROOT,
    canActivate: [AuthGuard],
    data: {
      title: 'Events',
      description: 'Events',
      navigationVisibility: true,
      icon: {
        default: 'mg-event-menu-o',
        active: 'mg-event-menu',
      },
    },
    loadChildren: () =>
      import('@modules/event-manager').then(m => m.EventManagerRoutesModule),
  },
  // ID
  {
    path: IdRoute.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'ID',
      description: 'Student ID',
      navigationVisibility: true,
      icon: {
        default: 'mg-id-menu-o',
        active: 'mg-id-menu',
      },
      accessRestrictions: {
        featureFlag: {
          only: [IMingaFeatureToggleKeys.STUDENT_ID_ENABLED],
        },
        permissions: {
          atLeastOne: [
            MingaPermission.MINGA_PEOPLE_MANAGE,
            MingaPermission.ADMINISTER_STUDENT_ID,
            MingaPermission.VIEW_STUDENT_ID_DASHBOARD,
          ],
        },
      },
    },
    loadChildren: () => import('@modules/id').then(m => m.IdModule),
  },
  // Points
  {
    path: PointsManagerRoutes.ROOT,
    canActivate: [AuthGuard, AccessRestrictionsGuard],
    data: {
      title: 'Points',
      description: 'Points',
      navigationVisibility: true,
      icon: {
        default: 'mg-points-menu-o',
        active: 'mg-points-menu',
      },
      accessRestrictions: {
        featureFlag: {
          only: [IMingaFeatureToggleKeys.TRACKING_ENABLED],
        },
        permissions: {
          atLeastOne: [
            MingaPermission.POINTS_MANAGE,
            MingaPermission.MINGA_ACCOUNT_MANAGE,
            MingaPermission.POINTS_VIEW_REPORTS,
          ],
        },
      },
      hasPromotionalModal: true,
    },
    loadChildren: () =>
      import('@modules/points-manager').then(m => m.PointsManagerRoutesModule),
  },
];
