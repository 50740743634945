import { Pipe, PipeTransform } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FiltersFormData } from '../types';

@Pipe({ name: 'checkForFieldCallback' })
export class FiltersFormCheckForFieldsCallbackPipe implements PipeTransform {
  constructor() {}
  async transform(
    formData: FiltersFormData,
    fieldKey: string | unknown,
    formState: Observable<any>,
  ) {
    const fieldObject = formData[fieldKey as string];
    return typeof fieldObject === 'function'
      ? formState.pipe(map(v => fieldObject(v, false)))
      : of(fieldObject);
  }
}
