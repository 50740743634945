import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, zip } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MingaPermission, MingaRoleType } from 'minga/libraries/domain';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

@Injectable({ providedIn: 'root' })

/**
 * PermissionsService
 *
 * Permissions and roles must be set before they can be checked. See the test
 * suite for examples of how to use.
 */
export class PermissionsService {
  private _permissions: Map<MingaPermission, BehaviorSubject<boolean>>;

  /** Role type */
  private _mingaRoleTypeValue = new BehaviorSubject<string | MingaRoleType>('');
  public readonly mingaRoleType$ = this._mingaRoleTypeValue.pipe(
    filter(roleType => !!roleType),
  );

  /** Auth info is no longer used? */
  constructor(_authInfo: AuthInfoService) {
    this._permissions = new Map();
  }

  public setMingaRoleType(roleTypeValue: string) {
    this._mingaRoleTypeValue.next(roleTypeValue);
  }

  public hasRoleType(roleToCheckAgainst: string): boolean {
    return this._mingaRoleTypeValue.getValue() === roleToCheckAgainst;
  }

  public getRoleType() {
    return this._mingaRoleTypeValue.getValue();
  }

  public addPermission(perms: MingaPermission[]) {
    perms.forEach(p => this._ensurePermSubj(p, true));
  }

  /**
   * Unsets all existing permissions and completeing their observables
   */
  flushPermissions() {
    for (const subj of this._permissions.values()) {
      subj.next(false);
      subj.complete();
    }

    this._permissions = new Map();
  }

  /**
   * Unsets all existing permissions while maintaining their observables
   */
  clearPermissions() {
    for (const subj of this._permissions.values()) {
      subj.next(false);
    }
  }

  /**
   * Check if all permissions provided are set
   */
  hasPermission(arg: MingaPermission | MingaPermission[], all = true): boolean {
    if (Array.isArray(arg)) {
      for (const perm of arg) {
        if (all && !this.hasPermission(perm)) {
          return false;
        } else if (!all && this.hasPermission(perm)) {
          return true;
        }
      }

      return all;
    } else {
      const subj = this._ensurePermSubj(arg);
      return subj.getValue();
    }
  }

  /**
   * Similar to `hasPermission`, but changes are piped through the observable
   */
  observePermission(
    arg: MingaPermission | MingaPermission[],
  ): Observable<boolean> {
    if (Array.isArray(arg)) {
      const subjs = arg.map(p => this._ensurePermSubj(p));
      return zip(...subjs).pipe(
        map(perms => {
          for (const perm of perms) {
            if (!perm) {
              return false;
            }
          }
          return arg.length > 0;
        }),
      );
    } else {
      const subj = this._ensurePermSubj(arg);
      return subj.asObservable();
    }
  }

  private _ensurePermSubj(
    perm: MingaPermission,
    defaultValue?: boolean,
  ): BehaviorSubject<boolean> {
    let subj = this._permissions.get(perm);
    if (!subj) {
      subj = new BehaviorSubject<boolean>(defaultValue || false);
      this._permissions.set(perm, subj);
    } else if (typeof defaultValue === 'boolean') {
      subj.next(defaultValue);
    }
    return subj;
  }
}
