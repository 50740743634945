import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
} from '@angular/core';

import * as _ from 'lodash';

import { FitGridTileDirective } from 'minga/app/src/app/components/FitGrid/FitGridTile.directive';
import { fitGridRows } from 'minga/libraries/util';

import { IFitGridTile } from './interface';

/**
 * A grid of variously sized tiles with a fixed height for each row. The body of
 * the fit grid must contain elements denoted with `mgFitGridTile`. All of the
 * `mgFitGridTile` elements get organized (in order) as a grid. You can control
 * how they fit with the various inputs defined in this component. See the
 * individual inputs for how they work.
 *
 * @example
 * ```html
 * <mg-fit-grid maxAspectRatio="2" minAspectRatio="2">
 *   <div mgFitGridTile tileWidth="20" tileHeight="10"
 *     style="background-color: red"></div>
 *   <div mgFitGridTile tileWidth="25" tileHeight="5"
 *     style="background-color: green"></div>
 *   <div mgFitGridTile tileWidth="35" tileHeight="35"
 *     style="background-color: blue"></div>
 * </mg-fit-grid>
 * ```
 */
@Component({
  selector: 'mg-fit-grid',
  templateUrl: './FitGrid.component.html',
  styleUrls: ['./FitGrid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FitGridComponent {
  /** @internal */
  private _rowHeight: number = 0;

  /** @internal */
  private _maxAspectRatio: number = 0;

  /** @internal */
  private _minAspectRatio: number = 0;

  @ContentChildren(FitGridTileDirective)
  private _tiles?: QueryList<FitGridTileDirective>;

  /**
   * The height of a row
   */
  @Input()
  set rowHeight(value: number) {
    this._rowHeight = coerceNumberProperty(value);
  }

  get rowHeight() {
    return this._rowHeight;
  }

  /**
   * Max number of tiles aspect ratio in a row. If the tiles dont fit nicely
   * with `rowHeight` the tiles will be slightly stretched beyond their aspect
   * ratio.
   */
  @Input()
  set maxAspectRatio(value: number) {
    this._maxAspectRatio = coerceNumberProperty(value);
  }

  get maxAspectRatio(): number {
    return this._maxAspectRatio;
  }

  /**
   * Minimum number of tiles aspect ratio in a row. If a row has less than this
   * value and it is the last row the items will not stretch.
   */
  @Input()
  set minAspectRatio(value: number) {
    this._minAspectRatio = coerceNumberProperty(value);
  }

  get minAspectRatio(): number {
    return this._minAspectRatio;
  }

  constructor(private renderer2: Renderer2, private elementRef: ElementRef) {}

  /** @internal */
  private _getTiles(): IFitGridTile[] {
    return this._tiles?.toArray() || [];
  }

  /** @internal */
  private _getChunkedTiles(): IFitGridTile[][] {
    const tiles = this._getTiles();
    const minAspectRatio = this._minAspectRatio;
    const maxAspectRatio = this._maxAspectRatio;

    return fitGridRows(tiles, {
      maxAspectRatio,
      minAspectRatio,
    });
  }

  ngAfterContentInit() {
    if (!this._tiles) {
      throw new Error('FitGrid tile query list unset');
    }

    this.calcGridHeight();
    this.calcTileSizes();

    this._tiles.changes.subscribe(changes => {
      this.calcGridHeight();
      this.calcTileSizes();
    });
  }

  calcGridHeight() {
    const rowCount = this._getChunkedTiles().length;
    const height = rowCount * this.rowHeight;

    this.renderer2.setStyle(
      this.elementRef.nativeElement,
      'min-height',
      `${height}px`,
    );
  }

  calcTileSizes() {
    const rows = this._getChunkedTiles();
    const minRowTiles = this._minAspectRatio;

    for (const row of rows) {
      const nonFlexRow = row.length < minRowTiles;
      let totalAspectRatio: number;

      if (row.length < minRowTiles) {
        totalAspectRatio = this._maxAspectRatio;
      } else {
        totalAspectRatio = row.reduce(
          (totalAspectRatio, tile) => totalAspectRatio + tile.tileAspectRatio,
          0,
        );
      }

      for (const tile of row) {
        const { tileAspectRatio, elementRef } = tile;
        const width = (tileAspectRatio / totalAspectRatio) * 100;

        if (nonFlexRow) {
          this.renderer2.addClass(
            elementRef.nativeElement,
            'mg-fit-grid-tile-no-flex',
          );
        } else {
          this.renderer2.removeClass(
            elementRef.nativeElement,
            'mg-fit-grid-tile-no-flex',
          );
        }

        this.renderer2.setStyle(
          elementRef.nativeElement,
          'flex-basis',
          `${width}%`,
        );
        this.renderer2.setStyle(
          elementRef.nativeElement,
          'height',
          `${this._rowHeight}px`,
        );
      }
    }
  }
}
