import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';

import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FormLabelBackground } from '../../types';
import { FormGroupedSelectBaseDirective } from './form-grouped-select-base.util';
import { OptionItem } from './form-grouped-select.types';

@Component({
  selector: 'mg-form-grouped-select',
  templateUrl: './form-grouped-select.component.html',
  styleUrls: ['./form-grouped-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupedSelectComponent extends FormGroupedSelectBaseDirective {
  @ViewChild('select') selectComponent: NgSelectComponent;

  @Input() set value(value: OptionItem[] | OptionItem | null) {
    if (!value || (Array.isArray(value) && !value.length)) {
      this.reset(true);
      return;
    }

    this.selectedValue = value;
    this.onSelect(this.selectedValue, false);
  }

  @Input() labelBackground: FormLabelBackground = 'white';
  @Input() floatingLabel = true;
  @Input() placeholder: string;

  private readonly _isOpenSubj = new BehaviorSubject<boolean>(false);
  public readonly isOpen$ = this._isOpenSubj.asObservable();

  constructor(
    public cdr: ChangeDetectorRef,
    public snackBar: SystemAlertSnackBarService,
  ) {
    super(cdr, snackBar, null);
  }

  get showLabel() {
    if (!this.floatingLabel) return false;
    if (this._isOpenSubj.value) return true;

    return this._hasValue(this.selectedValue);
  }

  public groupByFn(item) {
    return item?.category?.value;
  }

  public groupValueFn(groupKey: string, children: OptionItem[]) {
    return {
      label: children?.[0].category.label,
    };
  }

  public focus() {
    this.selectComponent.focus();
  }

  public onBlur(event) {
    this.reset();
  }

  public async setOpenState(val: boolean) {
    this._isOpenSubj.next(val);

    if (this._categoryOpenByDefault) {
      this.setCategory(this._categoryOpenByDefault, false);
    }
  }

  public onSelect(event: OptionItem | OptionItem[] | null, emitChange = true) {
    if (emitChange) {
      this.selectChange.emit(event);
    }
    this.reset();
  }

  public compareWithFn(o1: OptionItem, o2: OptionItem) {
    return o1?.value === o2?.value;
  }
}
