import { day } from 'minga/libraries/day';
import { CheckinManagerReportFilters } from 'minga/libraries/domain';
import { filterPersonEmitterTriggers } from 'src/app/components/manager-report/services/report-filter.service';

export enum CheckinManagerReportsMessages {
  PAGE_TITLE = 'Check In History',
  BUTTON_LABEL_EXPORT = 'Export list',

  NO_RESULTS_TITLE = 'No results found',
  NO_RESULTS_SUBTITLE = 'Try changing your search or filtering and try again.',
  BUTTON_APPLY_FILTER = 'Apply filters',
  BUTTON_CLEAR_FILTER = 'Clear filters',
  SCHEDULED_REPORT = 'Schedule report',

  SHOW_FILTERS = 'Show filters',
  HIDE_FILTERS = 'Hide filters',

  SELECT_LABEL_USER_LIST = 'User list',
}

export enum CheckinManagerReportType {
  CHECKINS = 'checkins',
  ABSENTEES = 'absentees',
}

export enum CheckinManagerReportFilterType {
  TYPE = 'type',
  ISSUED_TO = 'issuedTo',
  ISSUED_BY = 'issuedBy',
  REASON = 'search_type',
  GRADE = 'grade',
  ROLE = 'role',
  END_DATE = 'end_date',
  START_DATE = 'start_date',
  USER_LIST = 'user_list',
}

export const CHECKIN_REPORT_FILTER_INITIAL_STATE: CheckinManagerReportFilters =
  {
    type: CheckinManagerReportType.CHECKINS,
    reason: [],
    grade: [],
    role: [],
    issuedTo: [],
    issuedBy: [],
    startDate: day().subtract(7, 'd').startOf('day'),
    endDate: day().endOf('day'),
    userList: [],
  };

export const CHECKIN_REPORT_FILTER_NON_ARRAY_TYPES = [
  CheckinManagerReportFilterType.TYPE,
  CheckinManagerReportFilterType.END_DATE,
  CheckinManagerReportFilterType.START_DATE,
];

export const CHECKIN_REPORT_FILTER_PERSON_EMITTERS: Record<
  string,
  filterPersonEmitterTriggers
> = {
  [CheckinManagerReportFilterType.ISSUED_TO]: {
    accessor: CheckinManagerReportFilterType.ISSUED_TO,
    fieldsToReset: [CheckinManagerReportFilterType.ISSUED_BY],
  },
};
