import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute } from '@angular/router';

import * as day from 'dayjs';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { getUnitsByDateRange } from 'minga/app/src/app/shared/components/bar-chart';
import { MingaMinimalModel } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { ReportBaseAbstract } from 'src/app/components/manager-report/services/report-base.util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { PermissionsService } from 'src/app/permissions';
import { MingaManagerService } from 'src/app/services/MingaManager';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

import { DatePickerDefaultPresetKey } from '@shared/components/form/components/form-date-range/form-date-range.constants';
import { MediaService } from '@shared/services/media';

import { MingaManagerMessages } from '../../constants';
import { MmDistrictsService } from '../mm-districts/services/mm-districts.service';
import {
  MM_REPORTS_FILTERS_INITIAL_STATE,
  MM_REPORTS_STATS_TILES,
  MmReportsFilter,
  MmReportsMessages,
  tileColorMap,
} from './constants';
import { MmReportsService } from './services';
import { MmReportsFiltersService } from './services/mm-filters.services';

@Component({
  selector: 'mg-mm-reports',
  templateUrl: './mm-reports.component.html',
  styleUrls: ['./mm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MmReportsFiltersService, MmReportsService],
})
export class MmReportsComponent
  extends ReportBaseAbstract
  implements OnDestroy
{
  /** Constants */
  public readonly MESSAGES = MmReportsMessages;
  public readonly MM_MESSAGES = MingaManagerMessages;
  public readonly FILTERS = MmReportsFilter;
  public curMinga = { label: '', value: '' };

  // yesterday
  public maxDate = day().subtract(1, 'day').endOf('day');
  public customPresets = [
    DatePickerDefaultPresetKey.LAST_MONTH,
    DatePickerDefaultPresetKey.LAST_WEEK,
    DatePickerDefaultPresetKey.YESTERDAY,
    DatePickerDefaultPresetKey.CURRENT_SCHOOL_YEAR,
  ];

  /** Stat Tiles */
  public readonly statsTiles$ = of(MM_REPORTS_STATS_TILES);

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<string>;

  public readonly graphData$: Observable<any[]>;
  public readonly timeUnit$: Observable<'day' | 'week' | 'month'>;
  public readonly tileSelectedTitle$: Observable<string>;

  private readonly _startDateSubject = new BehaviorSubject<day.Dayjs>(
    MM_REPORTS_FILTERS_INITIAL_STATE.startDate,
  );
  public readonly startDate$ = this._startDateSubject.asObservable();

  private readonly _endDateSubject = new BehaviorSubject<day.Dayjs>(
    MM_REPORTS_FILTERS_INITIAL_STATE.endDate,
  );
  public readonly endDate$ = this._endDateSubject.asObservable();

  public readonly tileData$;
  public isSuperAdmin = this.permissions.hasPermission(
    MingaPermission.SUPERADMIN,
  );

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public media: MediaService,
    public reports: MmReportsService,
    public reportsFilters: MmReportsFiltersService,
    public mingaManager: MingaManagerService,
    public permissions: PermissionsService,
    private _mingaStore: MingaStoreFacadeService,
    private _cdr: ChangeDetectorRef,
    private _analytics: AnalyticsService,
    private _mmDistrictService: MmDistrictsService,
    private _route: ActivatedRoute,
  ) {
    super();
    // lets initial set reports data to users current minga
    this._mingaStore
      .getMingaAsObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(minga => {
        this._getInitData(minga);
      });

    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    this.timeUnit$ = this.reportsFilters.filters$.pipe(
      map(filters => {
        const startDate = filters.startDate;
        const endDate = filters.endDate;
        return getUnitsByDateRange(startDate.toDate(), endDate.toDate());
      }),
    );

    this.graphData$ = combineLatest([
      this.reportsFilters.filters$,
      this.reports.graphData$,
    ]).pipe(
      map(([filters, data]) => {
        const chartValues = data.map(point => {
          return { x: new Date(point.timeValue), y: point.value };
        });
        const tileColor = tileColorMap[filters.type];
        return [
          {
            data: chartValues,
            label: '',
            backgroundColor: tileColor,
            hoverBackgroundColor: tileColor,
          },
        ];
      }),
    );

    this.tileData$ = combineLatest([
      this.statsTiles$,
      this.reports.totalsData$,
    ]).pipe(
      map(([tiles, totals]) => {
        return tiles.map(tile => {
          return {
            ...tile,
            total: totals[tile.type] || 0,
          };
        });
      }),
    );

    this._initializeDates(
      MM_REPORTS_FILTERS_INITIAL_STATE,
      this._route.snapshot?.queryParams,
      this._destroyed$,
      (range, fromChangeEvent) => {
        this._startDateSubject.next(range.start);
        this._endDateSubject.next(range.end);
        this.reportsFilters.setFilter(this.FILTERS.START_DATE, range.start);
        this.reportsFilters.setFilter(this.FILTERS.END_DATE, range.end);

        if (fromChangeEvent) {
          this.reports.getReportsData();
        }
      },
    );

    this._mmDistrictService.fetchAll();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onNameChange(hash) {
    this.reportsFilters.setFilter(this.FILTERS.HASH, hash);
  }

  onTileClick(tile) {
    this.reportsFilters.setFilter(this.FILTERS.TYPE, tile.type);
    this.reports.getGraphData();
  }

  async clearFilters() {
    await this.reportsFilters.clearFilters();
    await this.reports.getReportsData();
  }

  private _getInitData(minga: MingaMinimalModel) {
    this.reportsFilters.setFilter(this.FILTERS.HASH, minga.hash);
    this.reports.getReportsData();
    this.curMinga = { label: minga.name, value: minga.hash };
    this._cdr.markForCheck();
  }

  public applyFilter() {
    this.reports.getReportsData();
    this._analytics.sendManagerReport({ managerType: 'minga' });
  }
}
