import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { ReportTypes } from 'minga/domain/reportTypes';
import { day } from 'minga/shared/day';
import { MingaPermission, MingaRoleType } from 'minga/util';
import { ReportBaseAbstract } from 'src/app/components/manager-report/services/report-base.util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { Person } from 'src/app/people';
import { PermissionsService } from 'src/app/permissions';
import { RolesService } from 'src/app/roles/services';
import { GradesService } from 'src/app/services/Grades';

import { CheckinManagerRoutes } from '@modules/checkin-manager/checkin-manager.constants';
import { MmScheduledReportsService } from '@modules/minga-manager/components/mm-scheduled-reports/services';
import { SUPPORTED_SCHEDULE_TYPES } from '@modules/minga-manager/components/mm-scheduled-reports/types';

import {
  FiltersFormData,
  FiltersFormService,
} from '@shared/components/filters-form';
import { CheckinService } from '@shared/services/checkin';
import { MediaService } from '@shared/services/media';

import {
  CHECKIN_REPORT_FILTER_INITIAL_STATE,
  CheckinManagerReportFilterType,
  CheckinManagerReportsMessages,
  CheckinManagerReportType,
} from './checkin-manager-reports.constants';
import { CheckinManagerReportsService } from './checkin-manager-reports.service';
import { CHECKIN_REPORT_TYPES } from './constants';

/**
 * Checkin Reports
 */
@Component({
  selector: 'mg-checkin-manager-reports',
  templateUrl: './checkin-manager-reports.component.html',
  styleUrls: ['./checkin-manager-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinManagerReportsComponent extends ReportBaseAbstract {
  /** Table Data Service */
  private _dataServiceSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    {},
  );
  public readonly dataService$: Observable<any> =
    this._dataServiceSubject.asObservable();

  public readonly MESSAGES: typeof CheckinManagerReportsMessages =
    CheckinManagerReportsMessages;

  /** data */
  public data$: Observable<any>;
  public infLoading$: Observable<boolean>;
  public tableLoading$: Observable<boolean>;

  /** Filters */
  readonly serviceFilter$: Observable<any> =
    this._checkInReportsService.filter$;

  private readonly _gradeOptions$: Observable<any> =
    this._gradesService.grades$.pipe(
      tap(() => this._gradesService.fetchIfNeeded()),
      takeUntil(this._destroyed),
      map(grades =>
        grades.map(grade => ({
          label: grade,
          value: grade,
        })),
      ),
    );

  private readonly _roleOptions$: Observable<any> =
    this._rolesService.roles$.pipe(
      tap(() => this._rolesService.fetchIfNeeded()),
      takeUntil(this._destroyed),
      map(roles =>
        roles
          .filter(
            role =>
              ![MingaRoleType.SUPERADMIN].includes(
                role.roleType as MingaRoleType,
              ),
          )
          .map(role => ({
            value: role.roleType as MingaRoleType,
            label: role.name,
          })),
      ),
    );

  private readonly _reasonOptions$: Observable<any> = this._checkinService
    .getReasonsObs(false)
    .pipe(
      tap(() => this._checkinService.getReasonsObs()),
      takeUntil(this._destroyed),
      map(reasons =>
        reasons.map(reason => ({
          label: reason.name,
          value: reason.id,
        })),
      ),
    );

  public readonly reason$: Observable<number[]>;
  public readonly type$: Observable<CheckinManagerReportType> =
    this.serviceFilter$.pipe(map(filters => filters.type));
  public startDate: day.Dayjs = day().startOf('day');
  public disableAbsentee: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public SUPPPORTED_SCHEDULE_TYPES: any = SUPPORTED_SCHEDULE_TYPES;
  public readonly canScheduleReport$ = this._permissions.observePermission(
    MingaPermission.CHECKIN_TYPE_MANAGE,
  );

  /** General Variables */
  protected _reportTypeSubject = new BehaviorSubject<ReportTypes>(
    ReportTypes.CHECKIN_HISTORY,
  );
  public readonly REPORT_OPTIONS = CHECKIN_REPORT_TYPES;
  public readonly REPORT_TYPES = ReportTypes;
  public readonly REASON_TYPES = CheckinManagerReportType;

  private readonly _loadingSubject = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loadingSubject.asObservable();

  public filtersFormStructure$ = combineLatest([
    this._roleOptions$,
    this._gradeOptions$,
    this._reasonOptions$,
    this._checkInReportsService.filter$,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([roleOptions, gradeOptions, reasonOptions, filters]) => {
      const issuedTo = filters.issuedTo || [];
      const issuedBy = filters.issuedBy || [];
      const formData: FiltersFormData = {
        person: {
          type: 'people-search',
          label: 'Name, ID or email',
          multiple: true,
          value: [...issuedTo, ...issuedBy],
        },
        grade: {
          type: 'multi-select',
          label: 'Grade',
          options: gradeOptions,
          value: filters.grade,
        },
        'checkin-reason': {
          type: 'multi-select',
          label: 'Reason type',
          options: reasonOptions,
          value: filters.reason,
        },
        role: {
          type: 'multi-select',
          label: 'Role',
          options: roleOptions,
          value: filters.role,
        },
        'user-list': {
          type: 'user-list',
          label: 'User list',
          value: filters.userList,
        },
      };

      return this._filtersForm.create(formData);
    }),
    tap(filters => {
      if ((filters as any).role.options.length > 0) {
        this._loadingSubject.next(false);
      }
    }),
  );

  /** Component Constructor */
  constructor(
    public media: MediaService,
    private _checkInReportsService: CheckinManagerReportsService,
    private _checkinService: CheckinService,
    private _gradesService: GradesService,
    private _rolesService: RolesService,
    private _analytics: AnalyticsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _permissions: PermissionsService,
    private _filtersForm: FiltersFormService,
  ) {
    super(_checkInReportsService);
    this.serviceFilter$ = this._checkInReportsService.filter$;
    this.reason$ = this.serviceFilter$.pipe(map(filters => filters.reason));

    /**
     * Route changed, set the active (selected) report from the new url.
     *
     * TODO: this is virtually the same as other report components routing,
     * consider generalizing the actions to have a reusable function across
     * all. IN: until, this.route, reportType.options, action. OUT: void
     */
    this._route.url.pipe(takeUntil(this._destroyed)).subscribe(x => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path as ReportTypes;
              const possiblePaths = this.REPORT_OPTIONS.map(type => type.value);
              if (possiblePaths.includes(report)) {
                this._reportTypeSubject.next(report);
              }
            }
          });
      }
    });

    this.reason$.pipe(takeUntil(this._destroyed)).subscribe(reason => {
      this.disableAbsentee.next(!reason.length);
    });

    this._initializeDates(
      CHECKIN_REPORT_FILTER_INITIAL_STATE,
      this._route.snapshot?.queryParams,
      this._destroyed,
      (range, fromChangeEvent) => {
        this._checkInReportsService.setFilter(
          CheckinManagerReportFilterType.START_DATE,
          range.start,
        );
        this._checkInReportsService.setFilter(
          CheckinManagerReportFilterType.END_DATE,
          range.end,
        );

        if (fromChangeEvent) {
          this._checkInReportsService.applyFilter();
        }
      },
    );
  }

  async handleOnChangeReportType(value: string) {
    if (value === ReportTypes.CHECKIN_HISTORY) {
      this.handleOnChangeDate(this.range.get('start').value);
      this._checkInReportsService.setFilter(
        CheckinManagerReportFilterType.END_DATE,
        this.range.get('end').value,
      );
    } else {
      this.handleOnChangeDate(this.startDate);
    }
    await this._router.navigateByUrl(
      `/${CheckinManagerRoutes.ROOT}/${CheckinManagerRoutes.REPORTS}/${value}`,
    );
  }

  /**
   * Handle On Change Reason Type
   */
  handleOnChangeReasonType(event: MatButtonToggleChange | string) {
    let value: string;

    if (typeof event === 'string') {
      value = event;
    } else {
      value = event.value;
    }

    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.TYPE,
      value,
    );
    this._checkInReportsService.applyFilter();
  }

  /**
   * Handle On Change Date
   */
  handleOnChangeDate(value: any, apply = false) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.START_DATE,
      day(value),
    );
    if (apply) {
      this._checkInReportsService.applyFilter();
    }
  }

  /**
   * Handle On Click Export
   */
  async handleOnClickExport(): Promise<void> {
    const reportType =
      this._checkInReportsService.filter.type ===
      CheckinManagerReportType.ABSENTEES
        ? ReportTypes.CHECKIN_ABSENTEES
        : ReportTypes.CHECKIN;
    this._checkInReportsService.exportReport(reportType);
  }

  /**
   * Handle On Person Selected
   */
  handleOnPersonSelected(people: Person[]) {
    const issuedTo: Partial<Person>[] = [];
    const issuedBy: Partial<Person>[] = [];
    for (const person of people) {
      if (
        person.roleType === MingaRoleType.STUDENT ||
        person.roleType === MingaRoleType.STUDENT_LEADER
      ) {
        issuedTo.push(person);
      } else {
        issuedBy.push(person);
      }
    }
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.ISSUED_BY,
      issuedBy,
    );

    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.ISSUED_TO,
      issuedTo,
    );
  }

  public updateFilters(formData: any) {
    this.handleOnPersonSelected(formData?.person || []);
    this.handleOnChangeRoleFilter(formData?.role || []);
    this.handleOnChangeGradeFilter(formData?.grade || []);
    this.handleOnChangeReasonFilter(formData?.['checkin-reason'] || []);
    this.changeUserList(formData?.['user-list'] || []);

    // Apply Filter
    this._checkInReportsService.applyFilter();
    const reportType = this._reportTypeSubject.value;
    this._analytics.sendManagerReport({
      managerType: 'checkin',
      reportType,
    });
  }

  handleOnChangeGradeFilter(value) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.GRADE,
      value,
    );
  }

  handleOnChangeReasonFilter(value) {
    if (!value.length) {
      this._checkInReportsService.setFilter(
        CheckinManagerReportFilterType.TYPE,
        this.REASON_TYPES.CHECKINS,
      );
      this.disableAbsentee.next(true);
    } else {
      this.disableAbsentee.next(false);
    }
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.REASON,
      value,
    );
  }

  handleOnChangeRoleFilter(value) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.ROLE,
      value,
    );
  }

  changeUserList(list: number[]) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.USER_LIST,
      list,
    );
  }

  /** Used by the view. */
  public async onScheduleReport() {
    const filters = await this._checkInReportsService.filter$
      .pipe(take(1))
      .toPromise();

    await this._mmScheduledReportService.scheduleReport(
      this._reportTypeSubject.value,
      filters,
    );
  }

  /** Used by the view. */
  public onActivate(componentRef: any) {
    this._dataServiceSubject.next(componentRef.ds);
  }
}
