"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INewNotificationMapper = void 0;
const google_protobuf_1 = require("google-protobuf");
const realtime_pb_1 = require("minga/proto/messaging/realtime_pb");
const message_1 = require("minga/libraries/shared-grpc/util/message");
var INewNotificationMapper;
(function (INewNotificationMapper) {
    function fromBase64(b64) {
        const proto = realtime_pb_1.NewNotification.deserializeBinary(b64);
        return fromProto(proto);
    }
    INewNotificationMapper.fromBase64 = fromBase64;
    function toBase64(newDm) {
        const binaryWriter = new google_protobuf_1.BinaryWriter();
        realtime_pb_1.NewNotification.serializeBinaryToWriter(toProto(newDm), binaryWriter);
        return binaryWriter.getResultBase64String();
    }
    INewNotificationMapper.toBase64 = toBase64;
    function fromProto(msg) {
        return Object.assign({}, msg.toObject());
    }
    INewNotificationMapper.fromProto = fromProto;
    function toProto(newDm) {
        const msg = new realtime_pb_1.NewNotification();
        return message_1.asObjectToMessage(newDm, msg);
    }
    INewNotificationMapper.toProto = toProto;
})(INewNotificationMapper = exports.INewNotificationMapper || (exports.INewNotificationMapper = {}));