import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';

import { GenericButtonModule } from '@shared/components/button';
import { CollapsibleModule } from '@shared/components/collapsible';
import { FiltersFormModule } from '@shared/components/filters-form';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { ReportTableModule } from '@shared/components/report-table';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';

import { FtmActivityTemplatesService } from '../ftm-activity-templates/services';
import { FtmPeriodsService } from '../ftm-periods/services';
import { FtmReportsRegistrationComponent } from './components/ftm-reports-reg/ftm-reports-reg.component';
import { FtmReportsUnregisteredComponent } from './components/ftm-reports-unreg/ftm-reports-unreg.component';
import { FtmReportsComponent } from './ftm-reports.component';
import {
  FtmReportsRegistrationDatasourceService,
  FtmReportsService,
} from './services';
import { FtmReportActionService } from './services/ftm-reports-action.service';
import { FtmReportsRegActionService } from './services/ftm-reports-reg-action.service';
import { FtmReportsUnregisteredDatasourceService } from './services/ftm-reports-unreg-data.service';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    FormModule,
    MultiPersonSearchModule,
    GenericButtonModule,
    CollapsibleModule,
    GenericModule,
    ReportTableModule,
    UserListFilterModule,
    FiltersFormModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatSortModule,
  ],
  declarations: [
    FtmReportsComponent,
    FtmReportsRegistrationComponent,
    FtmReportsUnregisteredComponent,
  ],
  exports: [FtmReportsComponent],
  providers: [
    FtmReportsService,
    FtmActivityTemplatesService,
    FtmPeriodsService,
    FlexTimeActivityInstanceService,
    FtmReportsRegistrationDatasourceService,
    FtmReportsUnregisteredDatasourceService,
    FtmReportActionService,
    FtmReportsRegActionService,
  ],
})
export class FtmReportsModule {}
