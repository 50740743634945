import * as day from 'dayjs';

import { GroupType } from 'minga/libraries/domain';
import { MingaReportType } from 'minga/proto/gateway/minga_pb';

import { MingaManagerReportStatTile, MmReportsFilters } from '../types';

export enum MmReportsMessages {
  PAGE_TITLE = 'Reports',

  BUTTON_LABEL_EXPORT = 'Export list',
  BUTTON_APPLY_FILTERS = 'Apply filters',
  BUTTON_CLEAR_FILTERS = 'Clear filters',

  EMPTY_STATE_TITLE = 'No data available',
  EMPTY_STATE_SUBTITLE = 'Try resetting your search filters',

  HIDE_FILTERS = 'Hide filters',
  SHOW_FILTERS = 'Show filters',
}

export enum MmReportsFilter {
  HASH = 'hash',
  MODULE = 'module',
  DISTRICT = 'district',
  STATE = 'state',
  STATUS = 'status',
  PARTNER = 'partner',
  SUBSCRIPTION = 'subscription',
  TYPE = 'type',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  GROUP_BY = 'groupBy',
}

export const MM_REPORTS_FILTERS_INITIAL_STATE: MmReportsFilters = {
  hash: '',
  district: null,
  module: null,
  partner: null,
  state: null,
  status: [],
  subscription: [],
  type: MingaReportType.ACTIVE_USER,
  startDate: day().subtract(7, 'd').startOf('day'),
  endDate: day().endOf('day'),
  groupBy: GroupType.BY_WEEK,
};

export const MM_REPORTS_STATS_TILES: MingaManagerReportStatTile[] = [
  {
    type: MingaReportType.ACTIVE_USER,
    color: '#D9AB64',
    title: 'Active Users',
    icon: 'checkin-6',
  },
  {
    type: MingaReportType.ACTIVE_STUDENT,
    color: '#85639F',
    title: 'Active Students',
    icon: 'checkin-6',
  },
  {
    type: MingaReportType.POST,
    color: '#1D47B9',
    title: 'Posts',
    icon: 'checkin-6',
  },
  {
    type: MingaReportType.HALL_PASS,
    color: '#D7809E',
    title: 'Hall Passes',
    icon: 'hall-pass-o',
  },
  {
    type: MingaReportType.CHECKIN,
    color: '#1D9FB9',
    title: 'Check Ins',
    icon: 'tracking-o',
  },
  {
    type: MingaReportType.BEHAVIOR,
    color: '#90BBAC',
    title: 'Behaviors',
    icon: 'praise-1',
  },
];

export const tileColorMap: Record<MingaReportType, string> = {
  [MingaReportType.ACTIVE_USER]: '#D9AB64',
  [MingaReportType.ACTIVE_STUDENT]: '#85639F',
  [MingaReportType.POST]: '#1D47B9',
  [MingaReportType.HALL_PASS]: '#D7809E',
  [MingaReportType.CHECKIN]: '#1D9FB9',
  [MingaReportType.BEHAVIOR]: '#90BBAC',
};
