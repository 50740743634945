<ng-container matColumnDef="{{ columnInfo.key }}">
  <mat-header-cell
    *matHeaderCellDef
    [mat-sort-header]="columnInfo.key"
    [disabled]="true"
    [ngClass]="columnInfo.columnClasses">
    {{ columnInfo.header }}
  </mat-header-cell>
  <mat-cell
    *matCellDef="let item"
    class="button-container"
    [ngClass]="columnInfo.columnClasses">
    <mg-btn
      variant="icon"
      iconSet="minga"
      icon="{{ iconName }}"
      (pressed)="triggerAction(item)">
    </mg-btn>
  </mat-cell>
</ng-container>
