import { NgModule } from '@angular/core';

import {
  ArrayPipesModule,
  NumberPipesModule,
  TypeCheckingPipesModule,
} from '@shared/pipes';

@NgModule({
  exports: [
    // Minga dependencies
    ArrayPipesModule,
    TypeCheckingPipesModule,
    NumberPipesModule,
  ],
})
export class CommonPipesModule {}
