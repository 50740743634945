"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IBannerDetailsMessageMapper = void 0;
const ts_enum_util_1 = require("ts-enum-util");
const domain_1 = require("minga/libraries/domain/index");
const banner_pb_1 = require("minga/proto/gateway/banner_pb");
const image_pb_1 = require("minga/proto/image/image_pb");
const _1 = require("minga/libraries/shared-grpc/banner/index");
var IBannerDetailsMessageMapper;
(function (IBannerDetailsMessageMapper) {
    function toBannerLibraryMessage(bannerDetails) {
        const banner = new banner_pb_1.Banner();
        if (bannerDetails.hash) {
            banner.setBannerHash(bannerDetails.hash);
        }
        banner.setArchived(bannerDetails.archived);
        if (bannerDetails.headlineOptions) {
            banner.setHeadlineOptions(_1.IBannerHeadlineOptionsMessageMapper.toBannerLibraryMessage(bannerDetails.headlineOptions));
        }
        if (bannerDetails.keywords) {
            banner.setKeywordList(bannerDetails.keywords);
        }
        if (bannerDetails.categories) {
            const catMsgs = [];
            for (let category of bannerDetails.categories) {
                catMsgs.push(_1.IBannerCategoryMapper.bannerCategorySqlEnumToMsgEnum(category));
            }
            banner.setCategoryList(catMsgs);
        }
        if (bannerDetails.asset) {
            const imageInfo = new image_pb_1.ImageInfo();
            const sizeMap = imageInfo.getSizeMap();
            const paths = bannerDetails.asset.sizeMap;
            for (let sizeKey in paths) {
                const imgSize = new image_pb_1.ImageSize();
                const sizePath = paths[sizeKey];
                imgSize.setPath(sizePath.path);
                imgSize.setWidth(sizePath.width);
                imgSize.setHeight(sizePath.height);
                sizeMap.set(sizeKey, imgSize);
            }
            banner.setImage(imageInfo);
        }
        if (bannerDetails.type) {
            banner.setType(bannerDetails.type);
        }
        return banner;
    }
    IBannerDetailsMessageMapper.toBannerLibraryMessage = toBannerLibraryMessage;
    function fromBannerLibraryAdd(bannerMsg) {
        const categoryList = bannerMsg.getCategoryList().map(category => {
            return _1.IBannerCategoryMapper.bannerCategoryMsgEnumToSqlEnum(category);
        });
        const keywordList = bannerMsg.getKeywordList();
        const asset = bannerMsg.getAsset();
        let headlineOptions = null;
        if (bannerMsg.getHeadlineOptions()) {
            headlineOptions =
                _1.IBannerHeadlineOptionsMessageMapper.fromBannerLibraryMessage(bannerMsg.getHeadlineOptions());
        }
        let filename = asset.replace('raw/', '');
        const type = bannerMsg.getType();
        const bannerType = ts_enum_util_1.$enum(domain_1.BannerType).asValueOrDefault(type, domain_1.BannerType.EVENT);
        const reference = bannerMsg.getReference();
        return {
            keywords: keywordList,
            categories: categoryList,
            headlineOptions: headlineOptions,
            archived: false,
            type: bannerType,
            asset: {
                filename: filename,
                sizeMap: {
                    raw: {
                        path: asset,
                        width: 100,
                        height: 100,
                    },
                },
            },
            reference,
        };
    }
    IBannerDetailsMessageMapper.fromBannerLibraryAdd = fromBannerLibraryAdd;
    function fromBannerLibraryUpdate(bannerMsg, previousBanner) {
        var _a;
        const categoryList = bannerMsg.getCategoryList().map(category => {
            return _1.IBannerCategoryMapper.bannerCategoryMsgEnumToSqlEnum(category);
        });
        previousBanner.categories = categoryList;
        const keywordList = bannerMsg.getKeywordList();
        previousBanner.keywords = keywordList;
        const type = bannerMsg.getType();
        const bannerType = ts_enum_util_1.$enum(domain_1.BannerType).asValueOrDefault(type, domain_1.BannerType.EVENT);
        previousBanner.type = bannerType;
        const asset = bannerMsg.getNewAsset().getValue();
        if (asset != ((_a = previousBanner.asset) === null || _a === void 0 ? void 0 : _a.filename)) {
            let filename = asset.replace('raw/', '');
            previousBanner.asset = {
                filename: filename,
                sizeMap: {
                    raw: {
                        path: asset,
                        width: 100,
                        height: 100,
                    },
                },
            };
        }
        const headlineOptions = bannerMsg.getHeadlineOptions();
        if (headlineOptions) {
            previousBanner.headlineOptions =
                _1.IBannerHeadlineOptionsMessageMapper.fromBannerLibraryMessage(headlineOptions);
        }
        return previousBanner;
    }
    IBannerDetailsMessageMapper.fromBannerLibraryUpdate = fromBannerLibraryUpdate;
})(IBannerDetailsMessageMapper = exports.IBannerDetailsMessageMapper || (exports.IBannerDetailsMessageMapper = {}));