"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProtoEditable = exports.fromProto = exports.toProtoEditable = exports.toProto = void 0;
const pbis_pb = require("minga/proto/pbis/pbis_pb");
const shared_grpc_1 = require("minga/libraries/shared-grpc/index");
const shared_grpc_2 = require("minga/libraries/shared-grpc/index");
const person_view_pb_1 = require("minga/proto/gateway/person_view_pb");
const PbisTypeMapper = require("minga/libraries/shared-grpc/pbis/PbisType.mapper");
exports.toProto = (behavior) => {
    var _a;
    const msg = new pbis_pb.PbisBehavior();
    if (behavior.id)
        msg.setId(behavior.id);
    if (behavior.mingaId)
        msg.setMingaId(behavior.mingaId);
    if (behavior.message)
        msg.setMessage(behavior.message);
    if (behavior.pbisTypeId)
        msg.setPbisTypeId(behavior.pbisTypeId);
    if (behavior.type)
        msg.setType(PbisTypeMapper.toProto(behavior.type));
    if (behavior.recipientId)
        msg.setRecipientId(behavior.recipientId);
    if (behavior.recipient) {
        msg.setRecipient(shared_grpc_2.asObjectToMessage(behavior.recipient, new person_view_pb_1.PersonViewMinimal()));
    }
    if (behavior.createdBy)
        msg.setCreatedBy(behavior.createdBy);
    if (behavior.creator) {
        msg.setCreator(shared_grpc_2.asObjectToMessage(behavior.creator, new person_view_pb_1.PersonViewMinimal()));
    }
    if (behavior.createdAt) {
        msg.setCreatedAt(shared_grpc_2.dateObjectToDateTimeMessage(behavior.createdAt));
    }
    if (behavior.points)
        msg.setPoints(behavior.points);
    if (behavior.imported)
        msg.setImported(behavior.imported);
    if ((_a = behavior.currentClass) === null || _a === void 0 ? void 0 : _a.length) {
        const sections = behavior.currentClass.map(shared_grpc_1.StudentScheduleMapper.toProto);
        msg.setCurrentClassesList(sections);
        // deprecated but for b/c
        msg.setCurrentClass(sections[0]);
    }
    return msg;
};
exports.toProtoEditable = (behavior) => {
    const msg = exports.toProto(behavior);
    const editable = new pbis_pb.EditablePbisBehavior();
    editable.setBehavior(msg);
    if (behavior.manuallyUpdatedAt) {
        editable.setManuallyUpdatedAt(shared_grpc_2.dateObjectToDateTimeMessage(behavior.manuallyUpdatedAt));
    }
    return editable;
};
exports.fromProto = (behavior) => {
    var _a, _b;
    const currentClassList = behavior.getCurrentClassesList();
    return {
        id: behavior.getId(),
        mingaId: behavior.getMingaId(),
        message: behavior.getMessage(),
        pbisTypeId: behavior.getPbisTypeId(),
        type: PbisTypeMapper.fromProto(behavior.getType()),
        recipientId: behavior.getRecipientId(),
        recipient: (_a = behavior.getRecipient()) === null || _a === void 0 ? void 0 : _a.toObject(),
        createdBy: behavior.getCreatedBy(),
        creator: (_b = behavior.getCreator()) === null || _b === void 0 ? void 0 : _b.toObject(),
        createdAt: shared_grpc_2.dateTimeMessageToDateObject(behavior.getCreatedAt()),
        points: behavior.getPoints(),
        imported: behavior.getImported(),
        currentClass: (currentClassList === null || currentClassList === void 0 ? void 0 : currentClassList.length) ? currentClassList.map(shared_grpc_1.StudentScheduleMapper.fromProto)
            : undefined,
    };
};
exports.fromProtoEditable = (behavior) => {
    const updatedAtProto = behavior.getManuallyUpdatedAt();
    return Object.assign(Object.assign({}, exports.fromProto(behavior.getBehavior())), { manuallyUpdatedAt: updatedAtProto
            ? shared_grpc_2.dateTimeMessageToDateObject(updatedAtProto)
            : undefined });
};