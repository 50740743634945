import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroIfNegative',
})
export class NumberZeroIfNegativePipe implements PipeTransform {
  transform(value: number): number {
    if (typeof value !== 'number') {
      console.warn(`NumberZeroIfNegativePipe: ${value} is not a number`);
      return value;
    }
    return Math.max(value, 0);
  }
}
