import { Pipe, PipeTransform } from '@angular/core';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserListFilterService } from '@shared/components/user-list-filter/services/user-list-filter.service';

@Pipe({ name: 'getUserListLabelsFromIds' })
export class FiltersFormGetUserListLabelsFromIdsPipe implements PipeTransform {
  constructor(
    private _userListFilter: UserListFilterService,
    public systemAlertService: SystemAlertSnackBarService,
  ) {}
  async transform(ids: number[]) {
    try {
      const allLists = await this._userListFilter.getAllLists();
      const labels = allLists.reduce((acc, list) => {
        if (ids.includes(list.value)) acc.push(list.label);
        return acc;
      }, []);
      return labels;
    } catch (error) {
      this.systemAlertService.error('Error fetching user lists');
    }
  }
}
