import { Coercer, Environment, IEnvironmentConfig } from 'src/environment';

import { firebase } from 'minga/app/src/firebase';

const getString = (key: RemoteConfigKey): string =>
  firebase.remoteConfig().getString(key);
const getNumber = (key: RemoteConfigKey): number =>
  firebase.remoteConfig().getNumber(key);
const getBoolean = (key: RemoteConfigKey): boolean =>
  firebase.remoteConfig().getBoolean(key);
const getJson = <T>(key: RemoteConfigKey): T => {
  const value = JSON.parse(getString(key));
  return value;
};

export type RemoteConfig = Pick<
  IEnvironmentConfig,
  | 'mgsrvGatewayHost'
  | 'environment'
  | 'websiteAnalyticsId'
  | 'version'
  | 'joinCodeCollection'
  | 'assetUrlPrefix'
  | 'cleverEnableLogin'
  | 'minimumVersion'
  | 'idBucket'
  | 'suggestionUrl'
  | 'notificationsReminderDuration'
  | 'moduleUpgradeHubspotUrl'
  | 'kioskEnabled'
  | 'kioskLogoutRequiresPin'
  | 'countryAppUrls'
  | 'REMOTE_DEBUG'
  | 'datePickerCustomPresetsEnabled'
  | 'studentScheduleEnabled'
  | 'iosAppStoreUrl'
  | 'androidAppStoreUrl'
>;

type CoercedRemoteConfig = Coercer<RemoteConfig>;

/**
 * We need to coerce the values coming from remote config to the correct type.
 */
export const REMOTE_CONFIG_COERCED_MAPPER: CoercedRemoteConfig = {
  mgsrvGatewayHost: () => getString('mgsrvGatewayHost'),
  environment: () => getString('environment') as Environment,
  websiteAnalyticsId: () => getString('websiteAnalyticsId'),
  countryAppUrls: () => getJson('countryAppUrls'),
  version: () => getString('version'),
  joinCodeCollection: () => getString('joinCodeCollection'),
  assetUrlPrefix: () => getString('assetUrlPrefix'),
  cleverEnableLogin: () => getBoolean('cleverEnableLogin'),
  minimumVersion: () => getString('minimumVersion'),
  idBucket: () => getString('idBucket'),
  suggestionUrl: () => getString('suggestionUrl'),
  notificationsReminderDuration: () =>
    getNumber('notificationsReminderDuration'),
  moduleUpgradeHubspotUrl: () => getString('moduleUpgradeHubspotUrl'),
  kioskEnabled: () => getBoolean('kioskEnabled'),
  kioskLogoutRequiresPin: () => getBoolean('kioskLogoutRequiresPin'),
  REMOTE_DEBUG: () => getString('REMOTE_DEBUG'),
  datePickerCustomPresetsEnabled: () =>
    getBoolean('datePickerCustomPresetsEnabled'),
  studentScheduleEnabled: () => getBoolean('studentScheduleEnabled'),
  iosAppStoreUrl: () => getString('iosAppStoreUrl'),
  androidAppStoreUrl: () => getString('androidAppStoreUrl'),
};

type RemoteConfigKey = keyof RemoteConfig;

export function IEnvironmentToRemoteConfig(
  config: IEnvironmentConfig,
): RemoteConfig & {
  // any json fields need to be stringified to be set as default config in remote config
  countryAppUrls: string;
} {
  const countryConfig = config.countryAppUrls;
  const string = JSON.stringify(countryConfig);
  const newConfig = Object.assign({}, config, { countryAppUrls: string });
  delete newConfig.firebaseConfig;
  return newConfig;
}
