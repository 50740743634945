export const SIS_STATS_COLUMN_ACCESSORS = [
  'type',
  'updatedAt',
  'numUpdated',
  'numCreated',
  'numIgnored',
  'numArchived',
  'numErrors',
  'fileLink',
];

export const SIS_STATS_COLUMN_TITLES = [
  'Type',
  'Updated At',
  'Updated',
  'Created',
  'Ignored',
  'Archived',
  'Errors',
  'Files',
];
