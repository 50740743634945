import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgIconModule } from 'src/app/icon';
import { MgSpinnerModule } from 'src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';
import { CollapsibleModule } from '@shared/components/collapsible';
import { FiltersFormModule } from '@shared/components/filters-form';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { ReportTableModule } from '@shared/components/report-table';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';

import {
  PmReportsHistoryComponent,
  PmReportsRewardsComponent,
  PmReportsStudentComponent,
  PmReportsTeamComponent,
} from './components';
import { PmReportsRedemptionHistoryComponent } from './components/pm-reports-points-redeemed/pm-reports-redemption-history.component';
import { PmReportsComponent } from './pm-reports.component';
import { PmReportsService } from './services';
import { PmReportsHistoryActionService } from './services/pm-reports-history-action.service';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    MultiPersonSearchModule,
    FormModule,
    GenericButtonModule,
    CollapsibleModule,
    MgIconModule,
    MgSpinnerModule,
    MgEmptyStateModule,
    MatSortModule,
    GenericModule,
    ReportTableModule,
    UserListFilterModule,
    FiltersFormModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
  ],
  declarations: [
    PmReportsComponent,
    PmReportsTeamComponent,
    PmReportsStudentComponent,
    PmReportsHistoryComponent,
    PmReportsRewardsComponent,
    PmReportsRedemptionHistoryComponent,
  ],
  exports: [PmReportsComponent],
  providers: [PmReportsService, PmReportsHistoryActionService],
})
export class PmReportsModule {}
