"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromProto = exports.toProto = void 0;
const reg_pb = require("minga/proto/registration/registration_pb");
const domain_1 = require("minga/libraries/domain/index");
const flex_time_1 = require("minga/libraries/shared-grpc/flex_time/index");
exports.toProto = (registration) => {
    const message = new reg_pb.Registration();
    if (registration.id)
        message.setId(registration.id);
    if (registration.type === domain_1.RegistrationTypes.FLEX_TIME) {
        message.setFlex(flex_time_1.FlexTimeActivityInstanceMapper.toProto(registration.registration));
    }
    if (registration === null || registration === void 0 ? void 0 : registration.personHash)
        message.setPersonHash(registration.personHash);
    if (registration.cannotUnregister)
        message.setCannotUnregister(registration.cannotUnregister);
    return message;
};
exports.fromProto = (message) => {
    var _a, _b;
    let registration;
    let type;
    if (message.getFlex()) {
        registration = flex_time_1.FlexTimeActivityInstanceMapper.fromProto(message.getFlex());
        type = domain_1.RegistrationTypes.FLEX_TIME;
    }
    return {
        personHash: message.getPersonHash(),
        id: (_a = message.getId()) !== null && _a !== void 0 ? _a : undefined,
        registration,
        type,
        cannotUnregister: (_b = message.getCannotUnregister()) !== null && _b !== void 0 ? _b : undefined,
    };
};