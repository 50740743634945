import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { GenericTagColor, GenericTagSize, GenericTagType } from '../tag/types';
import { getNumberOfElementsToShow } from './tag-collection.utils';

// Updates to show more button copy will mean tweaking this
const RESERVED_SPACE = 50;
const GAP = 4;

@Component({
  selector: 'mg-tag-collection',
  templateUrl: './tag-collection.component.html',
  styleUrls: ['./tag-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCollectionComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container')
  containerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('visibleContainer')
  visibleContainerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('showMore') showMoreTemplate!: TemplateRef<any>;

  private _tags: string[] = [];
  private _viewInitialized = false;

  private _visibleTagsSubject = new BehaviorSubject<string[]>([]);
  public visibleTags$ = this._visibleTagsSubject.asObservable();

  private _hiddenTagsSubject = new BehaviorSubject<string[]>([]);
  public hiddenTags$ = this._hiddenTagsSubject.asObservable();

  private _destroyedSubject = new ReplaySubject<void>(1);
  private _resizeSubject: Subject<void> = new Subject<void>();

  public SHOW_MORE_TRUNCATED = 'more...'; // updating this means double checking RESERVED_SPACE value
  public SHOW_MORE_ITEMS = 'items'; // updating this means double checking RESERVED_SPACE value
  public FLEX_GAP = GAP;

  @Input() type: GenericTagType = 'tag';
  @Input() showMoreType: GenericTagType = 'tag';
  @Input() color: GenericTagColor = 'blue';
  @Input() size: GenericTagSize = 'large';
  /**
   * Max number of characters per tag before truncating
   */
  @Input() maxTagChars: number;
  /**
   * Max number of lines the tags can wrap onto
   */
  @Input() maxLines = 2;
  @Input()
  set tags(value: string[]) {
    if (!Array.isArray(value)) return;

    this._tags = value;
    if (value.length > 0) {
      if (this._viewInitialized) {
        // need to wait for dom updates to finish
        requestAnimationFrame(() => this._calculateVisibleItems());
      }
    }
  }
  get tags(): string[] {
    return this._tags;
  }

  @Output() pressed: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this._resizeSubject.next();
  }

  constructor(private _elRef: ElementRef, private _cdr: ChangeDetectorRef) {
    this._resizeSubject
      .pipe(takeUntil(this._destroyedSubject), debounceTime(50))
      .subscribe(() => {
        this._calculateVisibleItems();
      });
  }

  ngAfterViewInit(): void {
    this._viewInitialized = true;
    // need to wait for dom updates to finish
    requestAnimationFrame(() => this._calculateVisibleItems());
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._visibleTagsSubject.complete();
    this._hiddenTagsSubject.complete();
    this._resizeSubject.complete();
  }

  public onTagPressed(tag: string): void {
    this.pressed.emit(tag);
  }

  private _calculateVisibleItems() {
    const containerWidth = this._elRef.nativeElement.offsetWidth;
    const children = this.containerRef?.nativeElement.children
      ? Array.from(this.containerRef?.nativeElement.children)
      : [];

    if (containerWidth === 0 || children.length === 0) {
      return;
    }

    const visibleCount = getNumberOfElementsToShow(children, {
      containerWidth,
      gap: GAP,
      reservedSpace: RESERVED_SPACE,
      lines: this.maxLines,
    });

    const tags = children.map((child: HTMLElement) => {
      return child.textContent;
    });

    const visible = tags.slice(0, visibleCount);
    const invisible = tags.slice(visibleCount, tags.length);

    this._visibleTagsSubject.next(visible);
    this._hiddenTagsSubject.next(invisible);
  }
}
