import { of } from 'rxjs';

import { PrimaryNavigationRoute } from '@shared/services/navigation';

export const LAYOUT_NAVIGATION_DRAWER_TOGGLE_DEBOUNCE_TIME = 500;

export const LAYOUT_NAVIGATION_SORT_ORDER = [
  'My Tools',
  'Home',
  'Events',
  'Groups',
  'People',
  'ID',
  'Hall Pass',
  'Behaviors',
  'Points',
  'Check in',
  'FlexTime',
  'Settings',
  'Super Admin',
] as const;

export const MOBILE_LAYOUT_ADMIN_SORT_ORDER = [
  'My Tools',
  'Home',
  'Hall Pass',
  'Behaviors',
  'Events',
  'Groups',
  'People',
  'ID',
  'Points',
  'Check in',
  'FlexTime',
  'Settings',
  'Super Admin',
] as const;

export const LEGACY_VIEW_ID_ROUTE: PrimaryNavigationRoute = {
  canAccess$: of(true),
  path: 'student-id',
  title: 'View ID',
  icon: 'mg-id-menu-o',
  iconActive: 'mg-id-menu',
  description: 'View your ID',
  routerOutlet: 'o',
};

export const VIEW_ID_ROUTE: PrimaryNavigationRoute = {
  canAccess$: of(true),
  path: 'view-id',
  title: 'View ID',
  icon: 'mg-id-menu-o',
  iconActive: 'mg-id-menu',
  description: 'View your ID',
  routerOutlet: 'o',
};
