<!-- Modal body -->
<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading'"
  [overlayTitle]="
    (isNew$ | async) === true
      ? MESSAGES.MODAL_TITLE_CREATE
      : MESSAGES.MODAL_TITLE_EDIT
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main template -->
<ng-template #mainTemplate>
  <main
    class="modal-form"
    #formElement>
    <form
      id="automations-edit-group-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <section *ngIf="(formState$ | async) === 'invalid'">
          <fieldset>
            <div class="form-error">
              <mg-system-alert
                type="error"
                [message]="MESSAGES.INVALID_FORM_MESSAGE"></mg-system-alert>
            </div>
          </fieldset>
        </section>
        <section *ngIf="disabledBehaviorsWarning$ | async">
          <mg-system-alert
            type="warning"
            message="{{ MESSAGES.BEHAVIORS_WARNING_MESSAGE }}">
          </mg-system-alert>
        </section>

        <section>
          <fieldset>
            <div
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="12px">
              <mg-form-text-input
                [label]="MESSAGES.FIELD_GROUP_NAME"
                [control]="form.get(FORM_FIELDS.GROUP_NAME)">
              </mg-form-text-input>
              <mg-form-text-input
                [label]="MESSAGES.FIELD_INTERNAL_DESC"
                [control]="form.get(FORM_FIELDS.INTERNAL_DESC)">
              </mg-form-text-input>
            </div>
          </fieldset>
        </section>
        <section
          class="fieldset"
          role="group">
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_BEHAVIOR_TRIGGERING }}
            </mg-text>
          </legend>
          <mg-form-select
            appendTo="body"
            [placeholder]="MESSAGES.FIELD_BEHAVIOR"
            [options]="behaviorsOptions$ | async"
            [multiple]="true"
            [closeOnSelect]="true"
            [searchable]="true"
            [control]="form.get(FORM_FIELDS.BEHAVIOR)"
            [enableMobileSheet]="true">
          </mg-form-select>
        </section>
        <section>
          <fieldset>
            <div
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="16px">
              <div
                class="auto-reset-toggle"
                fxLayout="row"
                fxLayoutAlign="space-between center">
                <div>
                  <mg-text variant="body-md">
                    {{ MESSAGES.FIELDSET_FREQUENCY_RESET }}
                  </mg-text>
                  <mg-text
                    variant="body-xs"
                    color="surface-alt">
                    {{ MESSAGES.FIELDSET_FREQUENCY_RESET_DESC }}
                  </mg-text>
                </div>
                <mg-form-toggle
                  [id]="'pbis-auto-grp-edit-click-reset'"
                  [control]="form.get(FORM_FIELDS.ENABLE_AUTO_RESET)">
                </mg-form-toggle>
              </div>
              <div
                *ngIf="form.get(FORM_FIELDS.ENABLE_AUTO_RESET).value === true"
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="12px">
                <div class="small-input">
                  <mg-form-select
                    appendTo="body"
                    [placeholder]="MESSAGES.FIELD_AUTO_RESET_FREQUENCY"
                    [options]="FREQUENCY_OPTIONS"
                    [control]="form.get(FORM_FIELDS.AUTO_RESET_FREQUENCY)"
                    [value]="form.get(FORM_FIELDS.AUTO_RESET_FREQUENCY).value">
                  </mg-form-select>
                </div>
                <mg-text
                  *ngIf="
                    form.get(FORM_FIELDS.AUTO_RESET_FREQUENCY).value ===
                    RESET_FREQUENCY.DAILY
                  "
                  variant="body-xs"
                  color="surface-alt">
                  {{ MESSAGES.FIELD_AUTO_RESET_DAILY_DESC }}
                </mg-text>
                <mg-text
                  *ngIf="
                    form.get(FORM_FIELDS.AUTO_RESET_FREQUENCY).value ===
                    RESET_FREQUENCY.WEEKLY
                  "
                  variant="body-xs"
                  color="surface-alt">
                  {{ MESSAGES.FIELD_AUTO_RESET_WEEKLY_DESC }}
                </mg-text>
                <mg-text
                  *ngIf="
                    form.get(FORM_FIELDS.AUTO_RESET_FREQUENCY).value ===
                    RESET_FREQUENCY.MONTHLY
                  "
                  variant="body-xs"
                  color="surface-alt">
                  {{ MESSAGES.FIELD_AUTO_RESET_MONTHLY_DESC }}
                </mg-text>
                <ng-container
                  *ngIf="
                    form.get(FORM_FIELDS.AUTO_RESET_FREQUENCY).value ===
                    RESET_FREQUENCY.CUSTOM
                  ">
                  <div>
                    <mg-form-multi-date
                      [placeholder]="MESSAGES.FIELD_AUTO_RESET_CUSTOM_DATES"
                      [control]="form.get(FORM_FIELDS.AUTOR_RESET_CUSTOM_DATES)"
                      [allowPastDate]="false"></mg-form-multi-date>
                  </div>
                  <div class="small-input">
                    <mg-form-text-input
                      inputType="time"
                      [step]="60"
                      [label]="MESSAGES.FIELD_AUTO_RESET_TIME"
                      [control]="form.get(FORM_FIELDS.AUTO_RESET_TIME)">
                    </mg-form-text-input>
                  </div>
                </ng-container>
              </div>
            </div>
          </fieldset>
        </section>
        <!-- Manual automation counter reset -->
        <section
          *ngIf="(canResetCounters$ | async) === true"
          class="fieldset"
          role="group">
          <legend>
            <mg-text
              variant="header-sm-secondary"
              spacing="1">
              {{ MESSAGES.FIELDSET_MANUAL_COUNTER_RESET }}
            </mg-text>
            <mg-text variant="body-xs">
              {{ MESSAGES.FIELDSET_MANUAL_COUNTER_RESET_DESC }}
            </mg-text>
          </legend>
          <mg-btn
            variant="outlined"
            iconSet="material"
            [matMenuTriggerFor]="templateVariablseMenu"
            iconRight="keyboard_arrow_down">
            {{ MESSAGES.MANUAL_COUNTER_RESET_BTN }}
          </mg-btn>
          <mat-menu #templateVariablseMenu="matMenu">
            <button
              mat-menu-item
              (click)="openResetModal(true)">
              {{ MESSAGES.MANUAL_COUNTER_ALL_STUDENT_BTN }}
            </button>
            <button
              mat-menu-item
              (click)="openResetModal()">
              {{ MESSAGES.MANUAL_COUNTER_STUDENT_BTN }}
            </button>
          </mat-menu>
        </section>
      </div>
    </form>
  </main>
</ng-template>

<!-- Footer template -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{ deleteLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="automations-edit-group-form"
        variant="filled"
        [disabled]="!canSubmit">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
