import { Injectable } from '@angular/core';

import { IPbisType } from 'minga/libraries/domain';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { BehaviorManagerService } from '../../../services';
import { BmTypesBehaviorEditComponent } from '../components/bm-types-behavior-edit/bm-types-behavior-edit.component';
import {
  BmTypesBehaviorEditModalData,
  BmTypesBehaviorEditModalResponse,
} from '../types';

@Injectable()
export class BmTypesBehaviorService {
  /** Service Constructor */
  constructor(
    private _systemSnackbar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService<
      BmTypesBehaviorEditModalResponse,
      BmTypesBehaviorEditModalData
    >,
    private _bmService: BehaviorManagerService,
  ) {}

  public async openEditModal(id?: number): Promise<void> {
    const editModal = this._modalOverlay.open(BmTypesBehaviorEditComponent, {
      data: { id },
      disposeOnNavigation: false,
    });
    editModal.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._systemSnackbar.success(
            'Successfully created new behavior type',
          );
          return;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          await this._bmService.fetchTypes();
          this._systemSnackbar.success('Successfully updated behavior type');
          return;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this._systemSnackbar.success('Successfully deleted behavior type');
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  public async changeStatus(
    behaviorType: IPbisType,
    status: boolean,
  ): Promise<void> {
    try {
      const type = { ...behaviorType };
      type.active = status;

      // restrictions are unaffected so there is no need to pass them along and upsert them
      type.restriction = undefined;

      await this._bmService.updateType(type);
      this._systemSnackbar.success(
        `Successfully set ${type.name} to ${status ? 'active' : 'inactive'}`,
      );
    } catch (error) {
      this._systemSnackbar.error(`Failed to update behavior status`);
    }
  }
}
