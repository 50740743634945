"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromSearchPresentation = void 0;
exports.fromSearchPresentation = (searchData) => {
    // if the search data does not have dmEnabled in it
    // we default to it being true.
    if (searchData.dmEnabled == null) {
        searchData.dmEnabled = true;
    }
    return {
        personHash: searchData.hash,
        displayName: searchData.displayName,
        profileImageUrl: searchData.profileImageUrl,
        firstName: searchData.firstName,
        lastName: searchData.lastName,
        badgeIconColor: searchData.badgeIconColor,
        badgeRoleName: searchData.badgeRoleName,
        badgeIconUrl: searchData.badgeIconUrl,
        roleType: searchData.roleType,
        dmEnabled: searchData.dmEnabled,
        email: searchData.email,
        studentId: searchData.studentId,
    };
};