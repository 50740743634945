import { Injectable } from '@angular/core';

import * as class_types_pb from 'minga/proto/class_types/class_types_pb';
import { IConsequenceClassTypeCount } from 'minga/libraries/domain';
import { IHallPassClassTypeCount } from 'minga/libraries/domain';
import { IPbisClassTypeCount } from 'minga/libraries/domain';
import { ConsequenceTypeCountMapper } from 'minga/libraries/shared-grpc';
import { HallPassTypeCountMapper } from 'minga/libraries/shared-grpc';
import { PbisTypeCountMapper } from 'minga/libraries/shared-grpc';
import { ClassTypesService } from 'minga/proto/class_types/class_types_ng_grpc_pb';

import { AssignmentType } from '@modules/teacher-tools/components/tt-my-class/types/tt-my-class.types';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import { createCacheCollection } from '../cache/cache.utils';

export type MostUsedByList = {
  [AssignmentType.CONSEQUENCE]: Record<string, number>;
  [AssignmentType.BEHAVIOR]: Record<string, number>;
  [AssignmentType.HALLPASS]: Record<string, number>;
};

export type Actions = {
  [AssignmentType.CONSEQUENCE]: IConsequenceClassTypeCount[];
  [AssignmentType.BEHAVIOR]: IPbisClassTypeCount[];
  [AssignmentType.HALLPASS]: IHallPassClassTypeCount[];
};

@Injectable({ providedIn: 'root' })
export class MyActionsService {
  private _cachedUserListItems = createCacheCollection(key =>
    this._cacheService.create<MostUsedByList>(
      `${CacheKey.MY_CLASS_MOST_USED_BY_LIST}:${key}`,
      async listId => {
        return await this._fetchMostUsedByList(listId);
      },
      {
        ttl: 60,
      },
    ),
  );

  private _cachedActions = this._cacheService.create<Actions>(
    CacheKey.MY_CLASS_ACTIONS,
    async () => {
      return await this._fetchAll();
    },
    {
      ttl: 60,
    },
  );

  constructor(
    private _classTypesService: ClassTypesService,
    private _cacheService: CacheService,
  ) {}

  public async _fetchAll(): Promise<Actions> {
    const request = new class_types_pb.GetClassTypesRequest();
    const response = await this._classTypesService.getClassTypes(request);

    const consequences = (response.getConsequenceTypeCountsList() || []).map(
      ConsequenceTypeCountMapper.fromProto,
    );
    const behaviors = (response.getPbisTypeCountsList() || []).map(
      PbisTypeCountMapper.fromProto,
    );
    const hallPasses = (response.getHallpassTypeCountsList() || []).map(
      HallPassTypeCountMapper.fromProto,
    );

    return {
      [AssignmentType.CONSEQUENCE]: consequences,
      [AssignmentType.BEHAVIOR]: behaviors,
      [AssignmentType.HALLPASS]: hallPasses,
    };
  }

  public async fetchAll(): Promise<Actions> {
    return await this._cachedActions.get().toPromise();
  }

  public async fetchMostUsedByList(
    listId: number,
    opts?: {
      revalidate?: boolean;
    },
  ): Promise<MostUsedByList> {
    const cacheItem = this._cachedUserListItems.get(listId);
    return await cacheItem.get(listId, opts).toPromise();
  }

  private async _fetchMostUsedByList(listId: number): Promise<MostUsedByList> {
    const request = new class_types_pb.GetListTypeCountsRequest();
    request.setListId(listId);
    const response = await this._classTypesService.getListTypeCounts(request);

    const typeCountMapperToDict = (msgs: class_types_pb.TypeCounts[]) =>
      (msgs || []).reduce((acc, msg) => {
        acc[msg.getId()] = msg.getCount();
        return acc;
      }, {});

    const consequences = typeCountMapperToDict(
      response.getConsequenceCountsList(),
    );
    const behaviors = typeCountMapperToDict(response.getPbisCountsList());
    const hallPasses = typeCountMapperToDict(response.getHallpassCountsList());

    return {
      [AssignmentType.CONSEQUENCE]: consequences,
      [AssignmentType.BEHAVIOR]: behaviors,
      [AssignmentType.HALLPASS]: hallPasses,
    };
  }
}
