"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPersonBadgesResponse = exports.GetPersonBadgesRequest = exports.Badge = void 0;
const jspb = require("google-protobuf");
const common_date_pb = require("minga/proto/common/date_pb");
const image_image_pb = require("minga/proto/image/image_pb");
class Badge extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, Badge.repeatedFields_, Badge.oneofGroups_);
    }
    /**
     * @return {Badge.ImageCase}
     */
    getImageCase() {
        return /** @type {Badge.ImageCase} */ (jspb.Message.computeOneofCase(this, Badge.oneofGroups_[0]));
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return Badge.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!Badge} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            description: jspb.Message.getFieldWithDefault(msg, 3, ""),
            asset: (f = msg.getAsset()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
            banner: (f = msg.getBanner()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
            date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
            contentContextHashList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!Badge}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new Badge;
        return Badge.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!Badge} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!Badge}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {number} */ (reader.readInt32());
                    msg.setId(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setName(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setDescription(value3);
                    break;
                case 4:
                    var value4 = new image_image_pb.ImageInfo;
                    reader.readMessage(value4, image_image_pb.ImageInfo.deserializeBinaryFromReader);
                    msg.setAsset(value4);
                    break;
                case 5:
                    var value5 = new image_image_pb.ImageInfo;
                    reader.readMessage(value5, image_image_pb.ImageInfo.deserializeBinaryFromReader);
                    msg.setBanner(value5);
                    break;
                case 6:
                    var value6 = new common_date_pb.DateTime;
                    reader.readMessage(value6, common_date_pb.DateTime.deserializeBinaryFromReader);
                    msg.setDate(value6);
                    break;
                case 20:
                    var value20 = /** @type {string} */ (reader.readString());
                    msg.addContentContextHash(value20);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!Badge} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getId();
        if (f !== 0) {
            writer.writeInt32(1, f);
        }
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDescription();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getAsset();
        if (f != null) {
            writer.writeMessage(4, f, image_image_pb.ImageInfo.serializeBinaryToWriter);
        }
        f = message.getBanner();
        if (f != null) {
            writer.writeMessage(5, f, image_image_pb.ImageInfo.serializeBinaryToWriter);
        }
        f = message.getDate();
        if (f != null) {
            writer.writeMessage(6, f, common_date_pb.DateTime.serializeBinaryToWriter);
        }
        f = message.getContentContextHashList();
        if (f.length > 0) {
            writer.writeRepeatedString(20, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        Badge.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional int32 id = 1;
     * @return {number}
     */
    getId() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
    }
    ;
    /** @param {number} value */
    setId(value) {
        jspb.Message.setProto3IntField(this, 1, value);
    }
    /**
     * optional string name = 2;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string description = 3;
     * @return {string}
     */
    getDescription() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setDescription(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional ImageInfo asset = 4;
     * @return {?ImageInfo}
     */
    getAsset() {
        return /** @type{?ImageInfo} */ (jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
    }
    /** @param {?ImageInfo|undefined} value */
    setAsset(value) {
        jspb.Message.setOneofWrapperField(this, 4, Badge.oneofGroups_[0], value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearAsset() {
        this.setAsset(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasAsset() {
        return jspb.Message.getField(this, 4) != null;
    }
    /**
     * optional ImageInfo banner = 5;
     * @return {?ImageInfo}
     */
    getBanner() {
        return /** @type{?ImageInfo} */ (jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 5));
    }
    /** @param {?ImageInfo|undefined} value */
    setBanner(value) {
        jspb.Message.setOneofWrapperField(this, 5, Badge.oneofGroups_[0], value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearBanner() {
        this.setBanner(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasBanner() {
        return jspb.Message.getField(this, 5) != null;
    }
    /**
     * optional minga.common.DateTime date = 6;
     * @return {?DateTime}
     */
    getDate() {
        return /** @type{?DateTime} */ (jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
    }
    /** @param {?DateTime|undefined} value */
    setDate(value) {
        jspb.Message.setWrapperField(this, 6, value);
    }
    /**
     * Clears the message field making it undefined.
     */
    clearDate() {
        this.setDate(undefined);
    }
    /**
     * Returns whether this field is set.
     * @return {boolean}
     */
    hasDate() {
        return jspb.Message.getField(this, 6) != null;
    }
    /**
     * repeated string content_context_hash = 20;
     * @return {!Array<string>}
     */
    getContentContextHashList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
    }
    ;
    /** @param {!Array<string>} value */
    setContentContextHashList(value) {
        jspb.Message.setField(this, 20, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addContentContextHash(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 20, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearContentContextHashList() {
        this.setContentContextHashList([]);
    }
}
exports.Badge = Badge;
Badge.displayName = "Badge";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
Badge.repeatedFields_ = [20];
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
Badge.oneofGroups_ = [[4, 5]];
(function (Badge) {
    let ImageCase;
    (function (ImageCase) {
        ImageCase[ImageCase["IMAGE_NOT_SET"] = 0] = "IMAGE_NOT_SET";
        ImageCase[ImageCase["ASSET"] = 4] = "ASSET";
        ImageCase[ImageCase["BANNER"] = 5] = "BANNER";
    })(ImageCase = Badge.ImageCase || (Badge.ImageCase = {}));
})(Badge = exports.Badge || (exports.Badge = {})); // namespace Badge
class GetPersonBadgesRequest extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonBadgesRequest.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonBadgesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonBadgesRequest}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonBadgesRequest;
        return GetPersonBadgesRequest.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonBadgesRequest} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonBadgesRequest}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonBadgesRequest} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonBadgesRequest.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
}
exports.GetPersonBadgesRequest = GetPersonBadgesRequest;
GetPersonBadgesRequest.displayName = "GetPersonBadgesRequest";
class GetPersonBadgesResponse extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, GetPersonBadgesResponse.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GetPersonBadgesResponse.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GetPersonBadgesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            badgeList: jspb.Message.toObjectList(msg.getBadgeList(), Badge.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GetPersonBadgesResponse}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GetPersonBadgesResponse;
        return GetPersonBadgesResponse.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GetPersonBadgesResponse} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GetPersonBadgesResponse}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = new Badge;
                    reader.readMessage(value1, Badge.deserializeBinaryFromReader);
                    msg.addBadge(value1);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GetPersonBadgesResponse} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getBadgeList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(1, f, Badge.serializeBinaryToWriter);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GetPersonBadgesResponse.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * repeated Badge badge = 1;
     * @return {!Array<!Badge>}
     */
    getBadgeList() {
        return /** @type{!Array<!Badge>} */ (jspb.Message.getRepeatedWrapperField(this, Badge, 1));
    }
    /** @param {!Array<!Badge>} value */
    setBadgeList(value) {
        jspb.Message.setRepeatedWrapperField(this, 1, value);
    }
    /**
     * @param {!Badge=} opt_value
     * @param {number=} opt_index
     * @return {!Badge}
     */
    addBadge(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Badge, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearBadgeList() {
        this.setBadgeList([]);
    }
}
exports.GetPersonBadgesResponse = GetPersonBadgesResponse;
GetPersonBadgesResponse.displayName = "GetPersonBadgesResponse";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
GetPersonBadgesResponse.repeatedFields_ = [1];