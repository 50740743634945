"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHallPassNote = exports.roleFinder = exports.gradeFinder = exports.studentIdFinder = exports.nameFinder = void 0;
const shared_1 = require("minga/libraries/shared/index");
const shared_2 = require("minga/libraries/shared/index");
exports.nameFinder = (item, name) => {
    if (name === 'first') {
        return item === null || item === void 0 ? void 0 : item.firstName;
    }
    else if (name === 'last') {
        return item === null || item === void 0 ? void 0 : item.lastName;
    }
    else {
        return (item === null || item === void 0 ? void 0 : item.firstName) ? (item === null || item === void 0 ? void 0 : item.firstName) + ' ' + (item === null || item === void 0 ? void 0 : item.lastName) : '';
    }
};
exports.studentIdFinder = (item) => (item === null || item === void 0 ? void 0 : item.studentId) || '';
exports.gradeFinder = (item) => (item === null || item === void 0 ? void 0 : item.grade) || '';
exports.roleFinder = (item, role) => {
    if (item === null || item === void 0 ? void 0 : item.roleType) {
        const roleObj = shared_2.getRole(item.roleType);
        item.role = roleObj === null || roleObj === void 0 ? void 0 : roleObj.name;
        item.badgeIconUrl = shared_1.roleTypeToIconUrl(item.roleType);
    }
    else if (item === null || item === void 0 ? void 0 : item.badgeRoleName) {
        item.badgeIconUrl = shared_1.badgeRoleNameToIconUrl(item.badgeRoleName);
    }
    return (role ? (item === null || item === void 0 ? void 0 : item.role) || (item === null || item === void 0 ? void 0 : item.badgeRoleName) : item === null || item === void 0 ? void 0 : item.badgeIconUrl) || '';
};
exports.getHallPassNote = (note, noteToRequester) => {
    let message = '';
    if (note) {
        // if there's also a note to requester we need a label
        message = noteToRequester ? `Note: ${note}` : note;
    }
    if (noteToRequester) {
        message += `\nNote to requester: ${noteToRequester}`;
    }
    return message;
};