import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import * as localforage from 'localforage';

import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';
import 'minga/app/src/globals';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { PageService } from 'minga/proto/gateway/page_ng_grpc_pb';
import { Page, PageRequest } from 'minga/proto/gateway/page_pb';

@Component({
  selector: 'mg-page',
  templateUrl: './Page.component.html',
  styleUrls: ['./Page.component.scss'],
})
export class PageComponent implements OnChanges, OnDestroy {
  @Input() path: string;
  @Input() useCache = true;
  @Input() margin = 32;

  pageData: Page.AsObject | null = null;

  private _loading = false;
  htmlContent?: SafeHtml;

  linkEls: HTMLAnchorElement[] = [];

  get loading() {
    return this._loading;
  }

  constructor(
    private _route: ActivatedRoute,
    private _pageService: PageService,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _linkOpener: LinkOpenerService,
    private _element: ElementRef,
  ) {
    _route.params.subscribe(params => {
      if (params.path) {
        const prevPath = this.path;
        this.path = params.path;
        this._pagePathChanged(params.path, prevPath);
      }
    });
  }

  async loadPageFast() {
    const cachedPage = await localforage.getItem('page:' + this.path);
    if (cachedPage) {
    }
  }

  async loadPage() {
    this._loading = true;
    const pageRequest = new PageRequest();
    pageRequest.setPagePath(this.path);

    const response = await this._pageService.readPage(pageRequest);
    const status = response.getStatus();

    if (status === StatusCode.OK) {
      if (response.hasPage()) {
        const pageData = response.getPage().toObject();
        this.pageData = pageData;

        this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(
          pageData.htmlContent,
        );
        await localforage.setItem('page:' + pageData.path, pageData);
        this.initLinkListeners();
        // @HACK: shouldn't need to wait a digest loop for html to populate...
        setTimeout(() => {
          this.initLinkListeners();
        }, 30);
      }
    }

    this._loading = false;
  }

  initLinkListeners() {
    const linkEls = document.getElementsByClassName('router-link');
    const anchorEls = this._element.nativeElement.getElementsByTagName('a');
    if (linkEls) {
      for (let i = 0; i < linkEls.length; ++i) {
        const linkElement: HTMLAnchorElement = <HTMLAnchorElement>linkEls[i];
        if (
          linkElement.classList.contains('router-link') ||
          (linkElement.dataset && linkElement.dataset.routerLinkJson)
        ) {
          linkElement.addEventListener(
            'click',
            this.onRouterLinkClick.bind(this),
          );
          this.linkEls.push(linkElement);
        }
      }
    }
    if (anchorEls && !window.MINGA_APP_BROWSER) {
      for (let i = 0; i < anchorEls.length; ++i) {
        const anchorElement: HTMLAnchorElement = <HTMLAnchorElement>(
          anchorEls[i]
        );
        if (
          !anchorElement.href.includes('mailto:') ||
          anchorElement.href.includes('tel:')
        ) {
          anchorElement.addEventListener(
            'click',
            this.onAnchorClick.bind(this),
          );
        }
      }
    }
  }

  onAnchorClick(e: any) {
    e.preventDefault();
    e.stopImmediatePropagation();

    this._linkOpener.open(e.target.href);
  }

  onRouterLinkClick(e: any) {
    if (e && e.target.dataset.routerLinkJson) {
      try {
        const routerLink = JSON.parse(e.target.dataset.routerLinkJson) as any;
        const path = routerLink.path ? routerLink.path : '';
        const outlets = routerLink.outlets ? routerLink.outlets : null;
        this._router.navigate([path, { outlets: { o: outlets } }]);
      } catch (err) {
        console.error(
          'onRouterLinkClick invalid JSON with value: ' +
            e.target.dataset.routerLinkJson,
          err,
        );
      }
    }
  }

  private async _pagePathChanged(currPath: string, prevPath: string) {
    await this.loadPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.path) {
      this._pagePathChanged(
        changes.path.currentValue,
        changes.path.previousValue,
      );
    }
  }

  ngOnDestroy() {
    this.linkEls.forEach(linkEl => {
      linkEl.removeEventListener('click', this.onRouterLinkClick);
    });
  }
}
