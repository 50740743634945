"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPersonView = exports.PersonView = exports.GuestCheckIn = exports.PersonViewEvent = exports.PersonViewMinimal = void 0;
const jspb = require("google-protobuf");
class PersonViewMinimal extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PersonViewMinimal.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PersonViewMinimal} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            badgeIconColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            badgeRoleName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            archived: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            firstName: jspb.Message.getFieldWithDefault(msg, 8, ""),
            lastName: jspb.Message.getFieldWithDefault(msg, 9, ""),
            studentId: jspb.Message.getFieldWithDefault(msg, 10, ""),
            grade: jspb.Message.getFieldWithDefault(msg, 11, ""),
            filename: jspb.Message.getFieldWithDefault(msg, 12, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PersonViewMinimal}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PersonViewMinimal;
        return PersonViewMinimal.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PersonViewMinimal} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PersonViewMinimal}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setProfileImageUrl(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setDisplayName(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconUrl(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconColor(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setBadgeRoleName(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setArchived(value7);
                    break;
                case 8:
                    var value8 = /** @type {string} */ (reader.readString());
                    msg.setFirstName(value8);
                    break;
                case 9:
                    var value9 = /** @type {string} */ (reader.readString());
                    msg.setLastName(value9);
                    break;
                case 10:
                    var value10 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setGrade(value11);
                    break;
                case 12:
                    var value12 = /** @type {string} */ (reader.readString());
                    msg.setFilename(value12);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PersonViewMinimal} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getProfileImageUrl();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDisplayName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getBadgeIconUrl();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getBadgeIconColor();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getBadgeRoleName();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getArchived();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getFirstName();
        if (f.length > 0) {
            writer.writeString(8, f);
        }
        f = message.getLastName();
        if (f.length > 0) {
            writer.writeString(9, f);
        }
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(10, f);
        }
        f = message.getGrade();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getFilename();
        if (f.length > 0) {
            writer.writeString(12, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PersonViewMinimal.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string profile_image_url = 2;
     * @return {string}
     */
    getProfileImageUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setProfileImageUrl(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string display_name = 3;
     * @return {string}
     */
    getDisplayName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setDisplayName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string badge_icon_url = 4;
     * @return {string}
     */
    getBadgeIconUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconUrl(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional string badge_icon_color = 5;
     * @return {string}
     */
    getBadgeIconColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconColor(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional string badge_role_name = 6;
     * @return {string}
     */
    getBadgeRoleName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setBadgeRoleName(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional bool archived = 7;
     * @return {boolean}
     */
    getArchived() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setArchived(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional string first_name = 8;
     * @return {string}
     */
    getFirstName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
    }
    ;
    /** @param {string} value */
    setFirstName(value) {
        jspb.Message.setProto3StringField(this, 8, value);
    }
    /**
     * optional string last_name = 9;
     * @return {string}
     */
    getLastName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
    }
    ;
    /** @param {string} value */
    setLastName(value) {
        jspb.Message.setProto3StringField(this, 9, value);
    }
    /**
     * optional string student_id = 10;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 10, value);
    }
    /**
     * optional string grade = 11;
     * @return {string}
     */
    getGrade() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setGrade(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional string filename = 12;
     * @return {string}
     */
    getFilename() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
    }
    ;
    /** @param {string} value */
    setFilename(value) {
        jspb.Message.setProto3StringField(this, 12, value);
    }
}
exports.PersonViewMinimal = PersonViewMinimal;
PersonViewMinimal.displayName = "PersonViewMinimal";
class PersonViewEvent extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, PersonViewEvent.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PersonViewEvent.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PersonViewEvent} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            badgeIconColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            badgeRoleName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            going: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            checkedIn: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            checkedInTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
            checkedOutTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
            currentStatus: jspb.Message.getFieldWithDefault(msg, 11, ""),
            ticketCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
            guestsList: jspb.Message.toObjectList(msg.getGuestsList(), GuestCheckIn.toObject, includeInstance),
            personEmail: jspb.Message.getFieldWithDefault(msg, 14, ""),
            studentId: jspb.Message.getFieldWithDefault(msg, 15, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PersonViewEvent}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PersonViewEvent;
        return PersonViewEvent.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PersonViewEvent} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PersonViewEvent}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setProfileImageUrl(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setDisplayName(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconUrl(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconColor(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setBadgeRoleName(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setGoing(value7);
                    break;
                case 8:
                    var value8 = /** @type {boolean} */ (reader.readBool());
                    msg.setCheckedIn(value8);
                    break;
                case 9:
                    var value9 = /** @type {number} */ (reader.readUint64());
                    msg.setCheckedInTime(value9);
                    break;
                case 10:
                    var value10 = /** @type {number} */ (reader.readUint64());
                    msg.setCheckedOutTime(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setCurrentStatus(value11);
                    break;
                case 12:
                    var value12 = /** @type {number} */ (reader.readInt32());
                    msg.setTicketCount(value12);
                    break;
                case 13:
                    var value13 = new GuestCheckIn;
                    reader.readMessage(value13, GuestCheckIn.deserializeBinaryFromReader);
                    msg.addGuests(value13);
                    break;
                case 14:
                    var value14 = /** @type {string} */ (reader.readString());
                    msg.setPersonEmail(value14);
                    break;
                case 15:
                    var value15 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value15);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PersonViewEvent} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getProfileImageUrl();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDisplayName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getBadgeIconUrl();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getBadgeIconColor();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getBadgeRoleName();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getGoing();
        if (f) {
            writer.writeBool(7, f);
        }
        f = message.getCheckedIn();
        if (f) {
            writer.writeBool(8, f);
        }
        f = message.getCheckedInTime();
        if (f !== 0) {
            writer.writeUint64(9, f);
        }
        f = message.getCheckedOutTime();
        if (f !== 0) {
            writer.writeUint64(10, f);
        }
        f = message.getCurrentStatus();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getTicketCount();
        if (f !== 0) {
            writer.writeInt32(12, f);
        }
        f = message.getGuestsList();
        if (f.length > 0) {
            writer.writeRepeatedMessage(13, f, GuestCheckIn.serializeBinaryToWriter);
        }
        f = message.getPersonEmail();
        if (f.length > 0) {
            writer.writeString(14, f);
        }
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(15, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PersonViewEvent.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string profile_image_url = 2;
     * @return {string}
     */
    getProfileImageUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setProfileImageUrl(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string display_name = 3;
     * @return {string}
     */
    getDisplayName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setDisplayName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string badge_icon_url = 4;
     * @return {string}
     */
    getBadgeIconUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconUrl(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional string badge_icon_color = 5;
     * @return {string}
     */
    getBadgeIconColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconColor(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional string badge_role_name = 6;
     * @return {string}
     */
    getBadgeRoleName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setBadgeRoleName(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional bool going = 7;
     * @return {boolean}
     */
    getGoing() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setGoing(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
    /**
     * optional bool checked_in = 8;
     * @return {boolean}
     */
    getCheckedIn() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
    }
    ;
    /** @param {boolean} value */
    setCheckedIn(value) {
        jspb.Message.setProto3BooleanField(this, 8, value);
    }
    /**
     * optional uint64 checked_in_time = 9;
     * @return {number}
     */
    getCheckedInTime() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
    }
    ;
    /** @param {number} value */
    setCheckedInTime(value) {
        jspb.Message.setProto3IntField(this, 9, value);
    }
    /**
     * optional uint64 checked_out_time = 10;
     * @return {number}
     */
    getCheckedOutTime() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
    }
    ;
    /** @param {number} value */
    setCheckedOutTime(value) {
        jspb.Message.setProto3IntField(this, 10, value);
    }
    /**
     * optional string current_status = 11;
     * @return {string}
     */
    getCurrentStatus() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setCurrentStatus(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional int32 ticket_count = 12;
     * @return {number}
     */
    getTicketCount() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
    }
    ;
    /** @param {number} value */
    setTicketCount(value) {
        jspb.Message.setProto3IntField(this, 12, value);
    }
    /**
     * repeated GuestCheckIn guests = 13;
     * @return {!Array<!GuestCheckIn>}
     */
    getGuestsList() {
        return /** @type{!Array<!GuestCheckIn>} */ (jspb.Message.getRepeatedWrapperField(this, GuestCheckIn, 13));
    }
    /** @param {!Array<!GuestCheckIn>} value */
    setGuestsList(value) {
        jspb.Message.setRepeatedWrapperField(this, 13, value);
    }
    /**
     * @param {!GuestCheckIn=} opt_value
     * @param {number=} opt_index
     * @return {!GuestCheckIn}
     */
    addGuests(opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, GuestCheckIn, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGuestsList() {
        this.setGuestsList([]);
    }
    /**
     * optional string person_email = 14;
     * @return {string}
     */
    getPersonEmail() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
    }
    ;
    /** @param {string} value */
    setPersonEmail(value) {
        jspb.Message.setProto3StringField(this, 14, value);
    }
    /**
     * optional string student_id = 15;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 15, value);
    }
}
exports.PersonViewEvent = PersonViewEvent;
PersonViewEvent.displayName = "PersonViewEvent";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
PersonViewEvent.repeatedFields_ = [13];
class GuestCheckIn extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return GuestCheckIn.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!GuestCheckIn} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            checkedInTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
            checkedOutTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!GuestCheckIn}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new GuestCheckIn;
        return GuestCheckIn.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!GuestCheckIn} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!GuestCheckIn}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setName(value1);
                    break;
                case 2:
                    var value2 = /** @type {number} */ (reader.readUint64());
                    msg.setCheckedInTime(value2);
                    break;
                case 3:
                    var value3 = /** @type {number} */ (reader.readUint64());
                    msg.setCheckedOutTime(value3);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!GuestCheckIn} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getName();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getCheckedInTime();
        if (f !== 0) {
            writer.writeUint64(2, f);
        }
        f = message.getCheckedOutTime();
        if (f !== 0) {
            writer.writeUint64(3, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        GuestCheckIn.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string name = 1;
     * @return {string}
     */
    getName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setName(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional uint64 checked_in_time = 2;
     * @return {number}
     */
    getCheckedInTime() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
    }
    ;
    /** @param {number} value */
    setCheckedInTime(value) {
        jspb.Message.setProto3IntField(this, 2, value);
    }
    /**
     * optional uint64 checked_out_time = 3;
     * @return {number}
     */
    getCheckedOutTime() {
        return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
    }
    ;
    /** @param {number} value */
    setCheckedOutTime(value) {
        jspb.Message.setProto3IntField(this, 3, value);
    }
}
exports.GuestCheckIn = GuestCheckIn;
GuestCheckIn.displayName = "GuestCheckIn";
class PersonView extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, null, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return PersonView.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!PersonView} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            badgeIconColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            badgeRoleName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            archived: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!PersonView}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new PersonView;
        return PersonView.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!PersonView} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!PersonView}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setProfileImageUrl(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setDisplayName(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconUrl(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconColor(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setBadgeRoleName(value6);
                    break;
                case 7:
                    var value7 = /** @type {boolean} */ (reader.readBool());
                    msg.setArchived(value7);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!PersonView} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getProfileImageUrl();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDisplayName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getBadgeIconUrl();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getBadgeIconColor();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getBadgeRoleName();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getArchived();
        if (f) {
            writer.writeBool(7, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        PersonView.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string profile_image_url = 2;
     * @return {string}
     */
    getProfileImageUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setProfileImageUrl(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string display_name = 3;
     * @return {string}
     */
    getDisplayName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setDisplayName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string badge_icon_url = 4;
     * @return {string}
     */
    getBadgeIconUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconUrl(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional string badge_icon_color = 5;
     * @return {string}
     */
    getBadgeIconColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconColor(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional string badge_role_name = 6;
     * @return {string}
     */
    getBadgeRoleName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setBadgeRoleName(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional bool archived = 7;
     * @return {boolean}
     */
    getArchived() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
    }
    ;
    /** @param {boolean} value */
    setArchived(value) {
        jspb.Message.setProto3BooleanField(this, 7, value);
    }
}
exports.PersonView = PersonView;
PersonView.displayName = "PersonView";
class AdminPersonView extends jspb.Message {
    /**
     * Generated by JsPbCodeGenerator.
     * @param {Array=} opt_data Optional initial data array, typically from a
     * server response, or constructed directly in Javascript. The array is used
     * in place and becomes part of the constructed object. It is not cloned.
     * If no data is provided, the constructed object will be empty, but still
     * valid.
     * @extends {jspb.Message}
     * @constructor
     */
    constructor(opt_data) {
        super();
        jspb.Message.initialize(this, opt_data, 0, -1, AdminPersonView.repeatedFields_, null);
    }
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    toObject(includeInstance) {
        return AdminPersonView.toObject(includeInstance || false, this);
    }
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!AdminPersonView} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static toObject(includeInstance, msg) {
        var f;
        var obj = {
            personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
            profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            badgeIconColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            badgeRoleName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            mingaHash: jspb.Message.getFieldWithDefault(msg, 8, ""),
            firstName: jspb.Message.getFieldWithDefault(msg, 9, ""),
            lastName: jspb.Message.getFieldWithDefault(msg, 10, ""),
            pin: jspb.Message.getFieldWithDefault(msg, 11, ""),
            emailAddress: jspb.Message.getFieldWithDefault(msg, 12, ""),
            active: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
            studentId: jspb.Message.getFieldWithDefault(msg, 14, ""),
            grade: jspb.Message.getFieldWithDefault(msg, 15, ""),
            groupHashesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
            userListsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    }
    /**
     * Deserializes binary data (in protobuf wire format).
     * @param {jspb.ByteSource} bytes The bytes to deserialize.
     * @return {!AdminPersonView}
     */
    static deserializeBinary(bytes) {
        var reader = new jspb.BinaryReader(bytes);
        var msg = new AdminPersonView;
        return AdminPersonView.deserializeBinaryFromReader(msg, reader);
    }
    /**
     * Deserializes binary data (in protobuf wire format) from the
     * given reader into the given message object.
     * @param {!AdminPersonView} msg The message object to deserialize into.
     * @param {!jspb.BinaryReader} reader The BinaryReader to use.
     * @return {!AdminPersonView}
     */
    static deserializeBinaryFromReader(msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break;
            }
            var field = reader.getFieldNumber();
            switch (field) {
                case 1:
                    var value1 = /** @type {string} */ (reader.readString());
                    msg.setPersonHash(value1);
                    break;
                case 2:
                    var value2 = /** @type {string} */ (reader.readString());
                    msg.setProfileImageUrl(value2);
                    break;
                case 3:
                    var value3 = /** @type {string} */ (reader.readString());
                    msg.setDisplayName(value3);
                    break;
                case 4:
                    var value4 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconUrl(value4);
                    break;
                case 5:
                    var value5 = /** @type {string} */ (reader.readString());
                    msg.setBadgeIconColor(value5);
                    break;
                case 6:
                    var value6 = /** @type {string} */ (reader.readString());
                    msg.setBadgeRoleName(value6);
                    break;
                case 8:
                    var value8 = /** @type {string} */ (reader.readString());
                    msg.setMingaHash(value8);
                    break;
                case 9:
                    var value9 = /** @type {string} */ (reader.readString());
                    msg.setFirstName(value9);
                    break;
                case 10:
                    var value10 = /** @type {string} */ (reader.readString());
                    msg.setLastName(value10);
                    break;
                case 11:
                    var value11 = /** @type {string} */ (reader.readString());
                    msg.setPin(value11);
                    break;
                case 12:
                    var value12 = /** @type {string} */ (reader.readString());
                    msg.setEmailAddress(value12);
                    break;
                case 13:
                    var value13 = /** @type {boolean} */ (reader.readBool());
                    msg.setActive(value13);
                    break;
                case 14:
                    var value14 = /** @type {string} */ (reader.readString());
                    msg.setStudentId(value14);
                    break;
                case 15:
                    var value15 = /** @type {string} */ (reader.readString());
                    msg.setGrade(value15);
                    break;
                case 16:
                    var value16 = /** @type {string} */ (reader.readString());
                    msg.addGroupHashes(value16);
                    break;
                case 17:
                    var value17 = /** @type {string} */ (reader.readString());
                    msg.addUserLists(value17);
                    break;
                default:
                    reader.skipField();
                    break;
            }
        }
        return msg;
    }
    /**
     * Serializes the given message to binary data (in protobuf wire
     * format), writing to the given BinaryWriter.
     * @param {!AdminPersonView} message
     * @param {!jspb.BinaryWriter} writer
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    static serializeBinaryToWriter(message, writer) {
        var f = undefined;
        f = message.getPersonHash();
        if (f.length > 0) {
            writer.writeString(1, f);
        }
        f = message.getProfileImageUrl();
        if (f.length > 0) {
            writer.writeString(2, f);
        }
        f = message.getDisplayName();
        if (f.length > 0) {
            writer.writeString(3, f);
        }
        f = message.getBadgeIconUrl();
        if (f.length > 0) {
            writer.writeString(4, f);
        }
        f = message.getBadgeIconColor();
        if (f.length > 0) {
            writer.writeString(5, f);
        }
        f = message.getBadgeRoleName();
        if (f.length > 0) {
            writer.writeString(6, f);
        }
        f = message.getMingaHash();
        if (f.length > 0) {
            writer.writeString(8, f);
        }
        f = message.getFirstName();
        if (f.length > 0) {
            writer.writeString(9, f);
        }
        f = message.getLastName();
        if (f.length > 0) {
            writer.writeString(10, f);
        }
        f = message.getPin();
        if (f.length > 0) {
            writer.writeString(11, f);
        }
        f = message.getEmailAddress();
        if (f.length > 0) {
            writer.writeString(12, f);
        }
        f = message.getActive();
        if (f) {
            writer.writeBool(13, f);
        }
        f = message.getStudentId();
        if (f.length > 0) {
            writer.writeString(14, f);
        }
        f = message.getGrade();
        if (f.length > 0) {
            writer.writeString(15, f);
        }
        f = message.getGroupHashesList();
        if (f.length > 0) {
            writer.writeRepeatedString(16, f);
        }
        f = message.getUserListsList();
        if (f.length > 0) {
            writer.writeRepeatedString(17, f);
        }
    }
    /**
     * Serializes the message to binary data (in protobuf wire format).
     * @return {!Uint8Array}
     */
    serializeBinary() {
        var writer = new jspb.BinaryWriter();
        AdminPersonView.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
    }
    /**
     * optional string person_hash = 1;
     * @return {string}
     */
    getPersonHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
    }
    ;
    /** @param {string} value */
    setPersonHash(value) {
        jspb.Message.setProto3StringField(this, 1, value);
    }
    /**
     * optional string profile_image_url = 2;
     * @return {string}
     */
    getProfileImageUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
    }
    ;
    /** @param {string} value */
    setProfileImageUrl(value) {
        jspb.Message.setProto3StringField(this, 2, value);
    }
    /**
     * optional string display_name = 3;
     * @return {string}
     */
    getDisplayName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
    }
    ;
    /** @param {string} value */
    setDisplayName(value) {
        jspb.Message.setProto3StringField(this, 3, value);
    }
    /**
     * optional string badge_icon_url = 4;
     * @return {string}
     */
    getBadgeIconUrl() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconUrl(value) {
        jspb.Message.setProto3StringField(this, 4, value);
    }
    /**
     * optional string badge_icon_color = 5;
     * @return {string}
     */
    getBadgeIconColor() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
    }
    ;
    /** @param {string} value */
    setBadgeIconColor(value) {
        jspb.Message.setProto3StringField(this, 5, value);
    }
    /**
     * optional string badge_role_name = 6;
     * @return {string}
     */
    getBadgeRoleName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
    }
    ;
    /** @param {string} value */
    setBadgeRoleName(value) {
        jspb.Message.setProto3StringField(this, 6, value);
    }
    /**
     * optional string minga_hash = 8;
     * @return {string}
     */
    getMingaHash() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
    }
    ;
    /** @param {string} value */
    setMingaHash(value) {
        jspb.Message.setProto3StringField(this, 8, value);
    }
    /**
     * optional string first_name = 9;
     * @return {string}
     */
    getFirstName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
    }
    ;
    /** @param {string} value */
    setFirstName(value) {
        jspb.Message.setProto3StringField(this, 9, value);
    }
    /**
     * optional string last_name = 10;
     * @return {string}
     */
    getLastName() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
    }
    ;
    /** @param {string} value */
    setLastName(value) {
        jspb.Message.setProto3StringField(this, 10, value);
    }
    /**
     * optional string pin = 11;
     * @return {string}
     */
    getPin() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
    }
    ;
    /** @param {string} value */
    setPin(value) {
        jspb.Message.setProto3StringField(this, 11, value);
    }
    /**
     * optional string email_address = 12;
     * @return {string}
     */
    getEmailAddress() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
    }
    ;
    /** @param {string} value */
    setEmailAddress(value) {
        jspb.Message.setProto3StringField(this, 12, value);
    }
    /**
     * optional bool active = 13;
     * @return {boolean}
     */
    getActive() {
        return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
    }
    ;
    /** @param {boolean} value */
    setActive(value) {
        jspb.Message.setProto3BooleanField(this, 13, value);
    }
    /**
     * optional string student_id = 14;
     * @return {string}
     */
    getStudentId() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
    }
    ;
    /** @param {string} value */
    setStudentId(value) {
        jspb.Message.setProto3StringField(this, 14, value);
    }
    /**
     * optional string grade = 15;
     * @return {string}
     */
    getGrade() {
        return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
    }
    ;
    /** @param {string} value */
    setGrade(value) {
        jspb.Message.setProto3StringField(this, 15, value);
    }
    /**
     * repeated string group_hashes = 16;
     * @return {!Array<string>}
     */
    getGroupHashesList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
    }
    ;
    /** @param {!Array<string>} value */
    setGroupHashesList(value) {
        jspb.Message.setField(this, 16, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addGroupHashes(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 16, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearGroupHashesList() {
        this.setGroupHashesList([]);
    }
    /**
     * repeated string user_lists = 17;
     * @return {!Array<string>}
     */
    getUserListsList() {
        return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
    }
    ;
    /** @param {!Array<string>} value */
    setUserListsList(value) {
        jspb.Message.setField(this, 17, value || []);
    }
    /**
     * @param {string} value
     * @param {number=} opt_index
     */
    addUserLists(value, opt_index) {
        jspb.Message.addToRepeatedField(this, 17, value, opt_index);
    }
    /**
     * Clears the list making it empty but non-null.
     */
    clearUserListsList() {
        this.setUserListsList([]);
    }
}
exports.AdminPersonView = AdminPersonView;
AdminPersonView.displayName = "AdminPersonView";
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
AdminPersonView.repeatedFields_ = [16, 17];